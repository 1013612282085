import { HttpErrorResponse } from '@angular/common/http';
import {
  ElementRef,
  EventEmitter,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { ShiftPreferenceService } from 'src/app/Services/Applications/ShiftPreference/shift-preference.service';
import { DateService } from 'src/app/Services/Date/date.service';
import { RuleEngineService } from 'src/app/Services/Ruleengine/rule-engine.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';

@Component({
  selector: 'app-edit-shift-preference',
  templateUrl: './edit-shift-preference.component.html',
  styleUrls: ['./edit-shift-preference.component.css'],
})
export class EditShiftPreferenceComponent implements OnInit {
  @Input() selectedEmp: any;
  empSelected: any;
  CurrentShift: any;

  @Input() isEdit: boolean = false;
  @Input() title: any;
  @Input() LastUpdatedBy: string = '';
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;
  employeeForm!: FormGroup;
  @Output() clean = new EventEmitter();
  @Output() isSucess = new EventEmitter();

  statusClosed: boolean = false;
  invalidStatus: boolean = false;
  AreaField: boolean = false;
  controlEnabled: boolean = true;

  ValidateSpecialMessage: boolean = false;
  PlantSeniorityValidate: boolean = false;
  SpecialOverride: boolean = false;

  medicalRestrictionList: string[] = [];
  statusCodes: string[] = [];

  ShiftRequestType: any[] = [
    {
      data: [],
      default: 'Choose One',
    },
  ];
  shifts: any[] = [
    {
      data: [],
      default: 'Choose One',
    },
  ];
  areas: any[] = [
    {
      data: [],
      default: 'Select an area',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private _shiftPreferenceService: ShiftPreferenceService,
    private _sweetAlertService: SweetAlertService,
    private _commonApplicationService: CommonService,
    private _ruleEngineService: RuleEngineService,
    private _dateService: DateService
  ) {}

  ngOnInit() {
    this.getShifts();
    this.getAreas();
    this.getShiftRequestTypes();
    this.getEmpStatusCodes();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.createEmpForm();
    this.loadEmployeeData(changes);
    //this.onChangeShiftRequestType();
    this.onChangeShiftType();
  }

  loadEmployeeData(changes: SimpleChanges) {
    this.empSelected =
      (changes.selectedEmp && changes.selectedEmp.currentValue) || '';
    if (this.empSelected === '') {
      this.empSelected =
        (changes.selectedEmp && changes.selectedEmp.previousValue) || '';
    }
    this.employeeForm.get('AppId')?.patchValue(this.empSelected.appId);
    this.employeeForm
      .get('fullname')
      ?.patchValue(
        this.empSelected.lastName +
          ', ' +
          this.empSelected.firstName +
          ' ' +
          this.empSelected.middleName
      );
    this.employeeForm.get('JobCodeId')?.patchValue(this.empSelected.jobCodeId);
    this.employeeForm
      .get('ATID')
      ?.patchValue(
        this._commonApplicationService.stringToUpperCase(this.empSelected.atid)
      );
    this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.empSelected.employeeId);
    this.employeeForm
      .get('AppStatusCode')
      ?.patchValue(
        this._commonApplicationService.removePendingFromEdit(
          this._commonApplicationService.removeAfterChar(
            this.empSelected.appStatusCode
          )
        )
      );
    this.employeeForm.get('ShiftId')?.patchValue(this.empSelected.shiftId);
    this.employeeForm.get('SpecialSP')?.patchValue(this.empSelected.specialSP);
    this.employeeForm
      .get('EmpSignDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(this.empSelected.empSignDate)
      );
    this.employeeForm
      .get('AppAreaId')
      ?.patchValue(this.empSelected.areaId ? this.empSelected.areaId : '0');
    this.employeeForm
      .get('AppStatusDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.empSelected.appStatusDate
        )
      );
    this.employeeForm
      .get('classification')
      ?.patchValue(
        this.empSelected.jobCodeId + '-' + this.empSelected.jobCodeDescription
      );
    this.employeeForm
      .get('currentPlant')
      ?.patchValue(
        this.empSelected.plantId + '-' + this.empSelected.plantDescription
      );
    this.employeeForm
      .get('currentShift')
      ?.patchValue(this.empSelected.employeeShiftDescription);
    this.employeeForm
      .get('currentShiftID')
      ?.patchValue(this.empSelected.employeeShiftId);
    this.employeeForm.get('currentDept')?.patchValue(this.empSelected.deptId);
    this.onCheckCurrentJobCodeId(
      this._commonApplicationService.stringToUpperCase(
        this.empSelected.jobCodeId
      )
    );
    this.GetMedicalRestrictions(this.empSelected.employeeId);
    this.CurrentShift = this.empSelected.employeeShiftId;
    this.statusClosed = false;
    this.invalidStatus = false;
    this.SpecialOverride = false;
    this.ValidateSpecialMessage = false;
  }

  DistinctiveAreaField: any = [];
  DistinctiveAreaFieldAccess: boolean = false;

  onCheckCurrentJobCodeId(value: string) {
    if (value != null && value != '') {
      if (
        value.includes('223J') ||
        value.includes('429J') ||
        value.includes('581J')
      ) {
        this.AreaField = true;
        this.DistinctiveAreaFieldAccess = false;
      } else if (value.includes('447J') || value.includes('447L')) {
        this.AreaField = false;
        this.DistinctiveAreaFieldAccess = true;
      } else {
        this.AreaField = false;
        this.DistinctiveAreaFieldAccess = false;
        this.employeeForm.get('AppAreaId')?.patchValue('0');
      }
    }
    return this.AreaField;
  }

  createEmpForm() {
    this.employeeForm = this.fb.group(
      {
        fullname: [''],
        currentPlant: [''],
        currentShift: [''],
        currentShiftID: [''],
        currentDept: [''],
        AppId: ['', [Validators.required]],
        ATIN: [''],
        ATID: [''],
        EmployeeId: [''],
        AppType: ['SP'],
        classification: [''],
        AppStatusCode: ['', [Validators.required]],
        ShiftId: [''],
        SpecialSP: ['', [Validators.required]],
        EmpSignDate: ['', [Validators.required]],
        AppStatusDate: ['', [Validators.required]],
        AppAreaId: ['', [Validators.required]],
        LastUpdatedBy: [this.LastUpdatedBy.toString().replace('@alsn.com', '')],
        SpecialOverride: [false],
        SeniorityOverrideFlag: [false],
      },
      {
        validator: this._commonApplicationService.dateLessThan(
          'EmpSignDate',
          'AppStatusDate'
        ),
      }
    );
    this.isEdit = false;
  }

  GetMedicalRestrictions(employeeId: string) {
    this._ruleEngineService
      .getMedicalRestrictions(employeeId)
      .subscribe((data) => {
        this.medicalRestrictionList = data;
      });
  }

  getEmpStatusCodes() {
    this._ruleEngineService.getEmpStatusCode().subscribe((data) => {
      this.statusCodes = data;
    });
  }

  clear() {
    this.clean.emit('');
    this.closeModal.nativeElement.click();
    this.DataReset();
    this.loadApplicationDataAfterReset();
  }

  addEmployee() {
    const currentShiftID: string =
      this.employeeForm.controls['currentShiftID'].value;
    const shiftIdAppliedForId: string =
      this.employeeForm.controls['ShiftId'].value;
    const specialspCodeAppliedForId: string =
      this.employeeForm.controls['SpecialSP'].value;
    const speicalOverirideCheck: boolean =
      this.employeeForm.controls['SpecialOverride'].value;
    // if ((this._commonApplicationService.formatDate(this.empSelected.empSignDate) != this._commonApplicationService.formatDate(this.employeeForm.get('EmpSignDate')?.value)) ||
    //   (this._commonApplicationService.formatDate(this.empSelected.appStatusDate) != this._commonApplicationService.formatDate(this.employeeForm.get('AppStatusDate')?.value))) {
    //   var dt = this.employeeForm.get('EmpSignDate')?.value;
    //   var empDt = new Date(dt);
    //   var empSignDtInUTC = this._dateService.GetUTCDate(empDt);

    //   var appDt = this.employeeForm.get('AppStatusDate')?.value;
    //   var appStsDt = new Date(appDt);
    //   var appStatusDateInUTC = this._dateService.GetUTCDate(appStsDt);

    //   this.employeeForm.patchValue({ EmpSignDate: empSignDtInUTC });
    //   this.employeeForm.patchValue({ AppStatusDate: appStatusDateInUTC });
    // }
    this._shiftPreferenceService
      .ShiftPreferenceSPCorrect(this.employeeForm.value)
      .subscribe(
        (_data) => {
          this.clear();
          this._sweetAlertService.successAlert(
            'center',
            'success',
            ' The Application has been Updated Successfully.',
            false,
            1500
          );
          this.isSucess.emit(true);
        },
        (_error: HttpErrorResponse) => {
          if (_error.status === 406) {
            if (
              currentShiftID === shiftIdAppliedForId &&
              specialspCodeAppliedForId != '1'
            ) {
              this._sweetAlertService.validationError(
                'error',
                'Cannot create application',
                'You cannot apply for your current shift unless Shift Request Type is Special.'
              );
            } else if (
              currentShiftID !== shiftIdAppliedForId &&
              specialspCodeAppliedForId === '1' &&
              speicalOverirideCheck === false
            ) {
              this._sweetAlertService.validationError(
                'error',
                'Cannot create application',
                'You must apply for your current shift if Shift Request Type is Special or apply the override..'
              );
            } else {
              specialspCodeAppliedForId.toString() === '1'
                ? this._sweetAlertService.validationError(
                    'error',
                    'Cannot create application',
                    'The employee already has a open Special Shift Preference application.'
                  )
                : this._sweetAlertService.validationError(
                    'error',
                    'Cannot create application',
                    'The employee already has a open Regular Shift Preference application.'
                  );
            }
          } else if (_error.status === 400) {
            this._sweetAlertService.validationError(
              'error',
              'Cannot Correct application',
              'You must provide an override due to less than 90 days plant seniority.'
            );
            this.PlantSeniorityValidate = true;
          } else {
            this._sweetAlertService.InternalServerError500(
              'error',
              'ERROR',
              'An Error occured while processing.Please try again.'
            );
          }
        }
      );
  }

  checkStatus(e: any) {
    const statuscode = this.employeeForm.get('AppStatusCode');

    statuscode?.valueChanges.subscribe(() => {
      statuscode?.patchValue(statuscode.value.toUpperCase(), {
        emitEvent: false,
      });
    });

    var statusEntered = e.target.value;
    if (statusEntered.toUpperCase().includes('CANCELLED')) {
      this.statusClosed = false;
      this.invalidStatus = false;
    } else if (statusEntered.toUpperCase().includes('CLOSED')) {
      this.statusClosed = true;
      this.invalidStatus = false;
      if (statusEntered.toUpperCase().includes('CLOSED -')) {
        //let appStatusCode = this.employeeForm.get('AppStatusCode')?.value;
        let statusCode = statusEntered.split('-')[2].trim();
        for (let i = 0; i < this.statusCodes.length; i++) {
          if (statusCode === this.statusCodes[i]) {
            this.statusClosed = false;
            this.invalidStatus = false;
            break;
          } else {
            this.statusClosed = true;
          }
        }
      }
    } else if (
      !statusEntered.toUpperCase().includes('CLOSED') ||
      !statusEntered.toUpperCase().includes('OPEN') ||
      !statusEntered.toUpperCase().includes('CANCELLED')
    ) {
      this.statusClosed = false;
      this.invalidStatus = true;
    }
    if (statusEntered.toUpperCase().includes('OPEN')) {
      this.statusClosed = false;
      this.invalidStatus = false;
    }
  }

  loadApplicationDataAfterReset() {
    this.employeeForm.get('AppId')?.patchValue(this.empSelected.appId);
    this.employeeForm
      .get('fullname')
      ?.patchValue(
        this.empSelected.firstName + ' ' + this.empSelected.lastName
      );
    this.employeeForm.get('JobCodeId')?.patchValue(this.empSelected.jobCodeId);
    this.employeeForm
      .get('ATID')
      ?.patchValue(
        this._commonApplicationService.stringToUpperCase(this.empSelected.atid)
      );
    this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.empSelected.employeeId);
    this.employeeForm
      .get('AppStatusCode')
      ?.patchValue(
        this._commonApplicationService.removeAfterChar(
          this.empSelected.appStatusCode
        )
      );
    this.employeeForm.get('ShiftId')?.patchValue(this.empSelected.shiftId);
    this.employeeForm.get('SpecialSP')?.patchValue(this.empSelected.specialSP);
    this.employeeForm
      .get('EmpSignDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(this.empSelected.empSignDate)
      );
    this.employeeForm
      .get('AppAreaId')
      ?.patchValue(this.empSelected.areaId ? this.empSelected.areaId : '0');
    this.employeeForm
      .get('AppStatusDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.empSelected.appStatusDate
        )
      );
    this.employeeForm
      .get('classification')
      ?.patchValue(
        this.empSelected.jobCodeId + '-' + this.empSelected.jobCodeDescription
      );
    this.employeeForm
      .get('currentPlant')
      ?.patchValue(
        this.empSelected.plantId + '-' + this.empSelected.plantDescription
      );
    this.employeeForm
      .get('currentShift')
      ?.patchValue(this.empSelected.employeeShiftDescription);
    this.employeeForm
      .get('currentShiftID')
      ?.patchValue(this.empSelected.employeeShiftId);
    this.employeeForm.get('currentDept')?.patchValue(this.empSelected.deptId);
    this.onCheckCurrentJobCodeId(
      this._commonApplicationService.stringToUpperCase(
        this.empSelected.jobCodeId
      )
    );
    this.statusClosed = false;
    this.invalidStatus = false;
    this.SpecialOverride = false;
    this.ValidateSpecialMessage = false;
    this.isSucess.emit(true);
  }

  DataReset() {
    this.isSucess.emit(true);
  }

  getAreas() {
    const areaObject = {
      areaId: 0,
      areaDescription: 'Any',
    };
    this._commonApplicationService.getAreasList().subscribe((data: any) => {
      this.areas[this.areas.length - 1].data = data;
      this.areas[this.areas.length - 1].data.splice(0, 2);
      this.areas[this.areas.length - 1].data.push(areaObject);
      this.DistinctiveAreaField.length = 0;
      var a = JSON.stringify(this.areas[0].data);
      this.DistinctiveAreaField = JSON.parse(a);
      let index = this.DistinctiveAreaField.map((o: any) => o.areaId).indexOf(
        1
      );
      this.DistinctiveAreaField.splice(index, 1);
    });
  }

  getShifts() {
    this._commonApplicationService.getShiftsList().subscribe((data) => {
      this.shifts[0].data = data;
      let index = this.shifts[0].data.map((o: any) => o.shiftId).indexOf('A');
      this.shifts[0].data.splice(index, 1);
    });
  }

  getShiftRequestTypes() {
    this._shiftPreferenceService
      .ShiftRequestType()
      .subscribe((shiftRequestTypes) => {
        this.ShiftRequestType[0].data = shiftRequestTypes;
      });
  }

  // onChangeShiftPreference() {
  //   this.employeeForm.get('shiftId')?.valueChanges.subscribe((inputValue: any) => {
  //     this.ShiftAppliyingFor = inputValue;
  //   });
  // }

  // onChangeShiftRequestType() {
  //   this.employeeForm.get('SpecialSP')?.valueChanges.subscribe((inputValue: any) => {
  //     if (inputValue === '1'
  //       //&& this.ShiftAppliyingFor !== this.CurrentShift
  //     ) {
  //       this.SpecialOverride = true;
  //       this.ValidateSpecialMessage = true;
  //     }
  //     else {
  //       this.SpecialOverride = false;
  //       this.ValidateSpecialMessage = false;
  //     }
  //   });
  // }

  onChangeShiftType() {
    this.employeeForm.get('ShiftId')?.valueChanges.subscribe((shiftIdValue) => {
      if (shiftIdValue === this.CurrentShift) {
        //this.ValidateSpecialMessage = true;
        // this.SpecialOverride = true
        this.employeeForm.get('SpecialSP')?.patchValue(1);
        //this.employeeForm.get('SpecialSP')?.disable()
      } else {
        //this.ValidateSpecialMessage = false;
        // this.SpecialOverride = false;
        this.employeeForm.get('SpecialSP')?.patchValue(0);
        //this.employeeForm.get('SpecialSP')?.disable()
      }
    });
  }
}
