import { HttpErrorResponse } from '@angular/common/http';
import {
  ElementRef,
  EventEmitter,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToolAndDiePlantService } from 'src/app/Services/Applications/ToolAndDie/tool-and-die-plant.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UserInfo } from 'src/models/userinfo';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { RuleEngineService } from 'src/app/Services/Ruleengine/rule-engine.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';

@Component({
  selector: 'add-emp',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css'],
})
export class AddComponent implements OnInit {
  employeeForm!: FormGroup;
  empSelected: any;
  shifts: any[] = [
    {
      data: [],
      default: 'Select a shift',
    },
  ];
  plants: any[] = [
    {
      data: [],
      default: 'Select a plant',
    },
  ];
  deptbyplantid: any[] = [
    {
      data: [],
      default: 'Select a Department',
    },
  ];
  selectedPlantId: any;
  atid: any;
  employeeId: any;
  currentDate = new Date();
  @Input() selectedEmp: any;
  @Input() isAdd: boolean = false;
  @Input() title: any;
  @Output() clean = new EventEmitter();
  @Output() isSucess = new EventEmitter();
  @Input() AppType: string = '';
  appType: string = '150TD';
  @ViewChild('closeAddModel') closeAddModal!: ElementRef;

  userInformation!: UserInfo;

  StatusCode: boolean = true;
  ruleMessages!: any[];
  errorlength: number = 0;
  constructor(
    private fb: FormBuilder,
    private td: ToolAndDiePlantService,
    private _ruleEngine: RuleEngineService,
    private _commonApplicationService: CommonService,
    private _sweetAlertService: SweetAlertService
  ) {}

  ngOnInit(): void {
    this.getShifts();
    this.getplants();
    this.getDepartments();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createEmpForm();
    this.loadEmployeeData(changes);
  }

  loadEmployeeData(changes: SimpleChanges) {
    this.empSelected =
      (changes.selectedEmp && changes.selectedEmp.currentValue) || '';
    if (this.empSelected === '') {
      this.empSelected =
        (changes.selectedEmp && changes.selectedEmp.previousValue) || '';
    }
    this.employeeForm
      .get('employeeName')
      ?.patchValue(
        this.empSelected.lastName +
          ', ' +
          this.empSelected.firstName +
          ' ' +
          this.empSelected.middleName
      );
    this.employeeForm.get('firstName')?.patchValue(this.empSelected.firstName);
    this.employeeForm.get('lastName')?.patchValue(this.empSelected.lastName);
    this.selectedPlantId = this.employeeForm
      .get('appPlantId')
      ?.patchValue(this.empSelected.plantid);
    this.employeeForm
      .get('currentPlant')
      ?.patchValue(this.empSelected.plantDescription);
    this.employeeForm
      .get('currentShift')
      ?.patchValue(this.empSelected.shiftDescription);
    this.atid = this.employeeForm
      .get('ATID')
      ?.patchValue(this.empSelected.atid);
    this.employeeId = this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.empSelected.employeeId);
    this.getRuleEngineValidations(this.empSelected.employeeId);
  }

  createEmpForm() {
    this.employeeForm = this.fb.group({
      employeeName: [''],
      firstName: [''],
      lastName: [''],
      currentPlant: [''],
      currentShift: [''],
      ATID: [this.atid],
      EmployeeId: [this.employeeId],
      AppNo: ['0'],
      AppStatusCode: ['OPEN'],
      Comments: [null, []],
      SpecialSP: [null, []],
      App150PlantGroupId: ['0'],
      AppType: ['150'],
      DateEntered: [this.currentDate],
      EnteredBy: ['USER'],
      appStatusDate: [this.currentDate],
      empSignDate: [this.currentDate],
      plantId: ['', [Validators.required]],
      shiftId: ['', [Validators.required]],
      departmentId: ['', [Validators.required]],
      StatusOverrideFlag: [null, []],
      AppAreaId: [null],
    });
    this.isAdd = false;
  }

  getRuleEngineValidations(employeeId: string) {
    this._ruleEngine
      .addTD150ApplicationRule(employeeId)
      .subscribe((data: any) => {
        this.StatusCode = data['status'];
        this.ruleMessages = data['errors'];
        this.errorlength = data['errors'].length;
      });
  }
  clear() {
    this.clean.emit('');
    this.closeAddModal.nativeElement.click();
  }

  clearForm() {
    this.employeeForm.reset;
  }

  //Method to add an employee
  addEmployee() {
    this.td.Add150Application(this.employeeForm.value).subscribe(
      (data: any) => {
        this.clear();
        this.isSucess.emit(true);
        //this.successAlert();
        this._sweetAlertService.successAlert(
          'center',
          'success',
          'The Application has been Added Successfully.',
          false,
          1500
        );
      },
      (_error: HttpErrorResponse) => {
        //this.badRequestAlert();
        if (_error.status === 406) {
          //this.badRequestAlert();
          this._sweetAlertService.validationError(
            'error',
            'ERROR',
            `${_error.error
              .toString()
              .replace(
                'Error: ToolAndDieMaker150Add - InvalidDataException:',
                ''
              )}`
          );
        } else {
          this._sweetAlertService.InternalServerError500(
            'error',
            'ERROR',
            'An Error occured while processing.Please try again.'
          );
        }
      }
    );
  }

  validationError() {
    Swal.fire({
      title: 'Cannot create application',
      text: 'User has an OPEN application and/or not eligible to create a new application',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
  //method to get shifts
  getShifts() {
    this._commonApplicationService.getShiftsList().subscribe((data: any) => {
      this.shifts[0].data = data;
    });
  }

  //Method to get plants
  getplants() {
    this._commonApplicationService.GetPlants(this.appType).subscribe((data) => {
      // this.plants = data;
      this.plants[0].data = data;
    });
  }

  //Method to get department by plant id

  getDepartments() {
    const DeptObject = {
      departmentId: '0000',
      departmentDescription: 'Any',
    };

    this._commonApplicationService
      .GetDepartment(this.appType)
      .subscribe((res) => {
        this.deptbyplantid[0].data = res;
        this.deptbyplantid[0].data.splice(7, '00');
        this.deptbyplantid[0].data.push(DeptObject);
      });
  }
}
