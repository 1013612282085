import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class SweetAlertService {
  constructor() { }

  simpleAlert(icon: any, title: any, text: any) {
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
    });
  }

  invalidSelection(icon: any, title: any, text: any) {
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
    });
  }

  InternalServerError500(icon: any, title: any, text: any) {
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
    });
  }
  validationError(icon: any, title: any, text: any) {
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
    });
  }
  successAlert(position: any,icon: any,title: any,showConfirmButton: boolean,timer : any)
  {
    Swal.fire({
      position: position,
      icon: icon,
      title: title,
      showConfirmButton: showConfirmButton,
      timer: timer,
    });
  }

  SearchFieldMissingAlert(icon:any,title:any,html:any){
    Swal.fire({
      icon: icon,
      title: title,
      html: html,
    })
  }

  validationErrorMessage( title: any,icon:any,  showCancelButton: boolean,  confirmButtonColor: any,  confirmButtonText: any){
    Swal.fire({
      title: title,
      icon: icon,
      showCancelButton: showCancelButton,
      confirmButtonColor:confirmButtonColor,
      confirmButtonText:confirmButtonText
    })
  }
}
