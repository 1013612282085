<div class="container-fluid app-box">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Table Maintenance</li>
        <li class="breadcrumb-item brd-li brd-li-child">Holiday Data</li>
      </ol>
    </nav>
  </div>

  <div>
    <div class="row content-container mt-2 mb-2">
      <div class="pt-2 div-color">
        <div class="search-divider-div filtercss">
          <p class="search-divder-p"></p>
          <hr class="search-divider-hr" />
          <div class="jumbotron jumb-new jumb-newcontrols">
            <div class="row" display="float">
              <div class="col">
                <app-filter
                  [filterConfig]="filterConfig"
                  (filterValue)="getFilterValue($event)"
                >
                </app-filter>
              </div>
              <div class="col-md-1 mr-5">
                <label for="" style="color: white">&nbsp;</label>
                <a
                  class="btn btn-lg search-button"
                  (click)="searchHolidays('SEARCH')"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="info-label divcrudbtns">
              <p style="text-align: left" class="p-margin"></p>
              <div *ngIf="views[0]?.allowAdd">
                <button
                  data-toggle="modal"
                  class="btn btn-successclass buttoncolours crudbuttons pull-right my-2 my-sm-0"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                  (click)="showAddModal()"
                >
                  Add
                </button>
              </div>
              <div *ngIf="views[0]?.allowUpdate">
                <button
                  data-toggle="modal"
                  *ngIf="showButton"
                  class="btn btn-successclass buttoncolours crudbuttons pull-right my-2 my-sm-0"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                  (click)="showEditModal()"
                >
                  Edit
                </button>
              </div>
              <div *ngIf="views[0]?.allowDelete">
                <button
                  data-toggle="modal"
                  *ngIf="showButton"
                  class="btn btn-successclass buttoncolours crudbuttons pull-right my-2 my-sm-0"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                  (click)="showDeleteModal()"
                >
                  Delete
                </button>
              </div>

              <div
                *ngIf="
                  holidaySearchData !== null && holidaySearchData.length > 0
                "
              >
                <button
                  class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
                  (click)="exportElmToExcel()"
                >
                  Export to Excel
                </button>
              </div>
            </div>

            <table
              class="table table-striped"
              *ngIf="
                !isDataExportableView &&
                holidaySearchData !== null &&
                holidaySearchData.length > 0
              "
              #holidaydataTablemaintenanceExportTable
            >
              <thead class="table-header">
                <tr>
                  <!-- <th style="text-align:center;" scope="col"><input class="form-textbox" type="checkbox" id="select" name="application" [(ngModel)]="isSelectAllHoliday" (click)="selectAllHoliday()"></th> -->
                  <!-- <th style="text-align:center;" scope="col">Holiday</th>
                                    <th style="text-align:center;" scope="col">Holiday Description</th> -->
                  <th scope="col">
                    <span (click)="onSortClick($event, 'holiday', 'string')"
                      >Holiday
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'holiday'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'holiday'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'holiday'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'description', 'string')"
                      >Holiday Description
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'description'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'description'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'description'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class=""
                  *ngFor="let data of holidaySearchData; let i = index"
                >
                  <!-- <td style="text-align:center;" class='tab-value'><input [(ngModel)]="data.ischecked" class="form-textbox" type="checkbox" id="select" name="application" (change)="onChange(data, $event)"></td> -->
                  <td style="text-align: center" class="tab-value">
                    {{ data.holiday | date: 'MM/dd/yyyy' }}
                  </td>
                  <td class="left-tab-value">{{ data.description }}</td>
                </tr>
              </tbody>
            </table>

            <table
              class="table table-striped"
              *ngIf="holidaySearchData !== null && holidaySearchData.length > 0"
            >
              <thead class="table-header">
                <tr>
                  <th style="text-align: center" scope="col">
                    <input
                      class="form-textbox"
                      type="checkbox"
                      id="select"
                      name="application"
                      [(ngModel)]="isSelectAllHoliday"
                      (click)="selectAllHoliday()"
                    />
                  </th>
                  <!-- <th style="text-align:center;" scope="col">Holiday</th>
                                    <th style="text-align:center;" scope="col">Holiday Description</th> -->

                  <th scope="col">
                    <span (click)="onSortClick($event, 'holiday', 'string')"
                      >Holiday
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'holiday'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'holiday'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'holiday'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'description', 'string')"
                      >Holiday Description
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'description'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'description'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'description'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class=""
                  *ngFor="
                    let data of holidaySearchData
                      | paginate
                        : {
                            id: 'joblist',
                            itemsPerPage: 20,
                            currentPage: Applicationpage,
                            totalItems: TotalApplicationCount
                          };
                    let i = index
                  "
                >
                  <td style="text-align: center" class="tab-value">
                    <input
                      [(ngModel)]="data.ischecked"
                      class="form-textbox"
                      type="checkbox"
                      id="select"
                      name="application"
                      (change)="onChange(data, $event)"
                    />
                  </td>
                  <td style="text-align: center" class="tab-value">
                    {{ data.holiday | date: 'MM/dd/yyyy' }}
                  </td>
                  <td class="left-tab-value">{{ data.description }}</td>
                </tr>
              </tbody>
            </table>
            <div
              class="single-wrap d-flex justify-content-center"
              *ngIf="holidaySearchData !== null && holidaySearchData.length > 0"
            >
              <nav aria-label="Page navigation example">
                <pagination-controls
                  class="my-pagination"
                  id="joblist"
                  (pageChange)="Applicationpage = $event"
                  previousLabel=""
                  nextLabel=""
                >
                </pagination-controls>
              </nav>
            </div>
            <div *ngIf="isApplicationDataEmpty">
              <p *ngIf="isApplicationDataEmpty">No existing records Found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Edit Modal -->
  <app-add-holiday-data
    *ngIf="showAdd"
    [userName]="this.userInformation.userName"
    (isSucess)="CheckAdd($event)"
  >
  </app-add-holiday-data>

  <!-- [userName]="this.userInformation.userName"  /Add Modal-->

  <app-edit-holiday-data
    *ngIf="showEdit"
    [userName]="this.userInformation.userName"
    [selectedHoliday]="selectedHoliday"
    (isSucess)="CheckEdit($event)"
  >
  </app-edit-holiday-data>

  <!--Delete Modal-->
  <app-delete-holiday-data
    *ngIf="showDelete"
    [selectedHoliday]="selectedHoliday"
    (isSucess)="CheckDelete($event)"
  >
  </app-delete-holiday-data>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
