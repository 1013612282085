import { HttpErrorResponse } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { RepresentationGroupDataService } from 'src/app/Services/TableMaintenance/RepresentationGroupData/representation-group-data.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { UserInfo } from 'src/models/userinfo';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-representation-group-data',
  templateUrl: './representation-group-data.component.html',
  styleUrls: ['./representation-group-data.component.css'],
})
export class RepresentationGroupDataComponent implements OnInit {
  public loading = false;
  showAdd: boolean = false;
  showEdit: boolean = false;
  showDelete: boolean = false;
  TotalApplicationCount: any;
  UserPage: number = 1;
  Applicationpage: number = 1;
  modalId: string = '';
  filterData: any = {};
  repGroupSearchData: any = [];
  selectedRepGroup: any;
  showButton: boolean = false;
  userInformation!: UserInfo;
  isApplicationDataEmpty: boolean = false;
  views: any[] = [];

  filterConfig: any = [
    {
      label: 'Department',
      type: 'input',
      data: [],
      default: 'All',
      placeholder: 'Enter department',
      controlName: 'department',
      runClassification: false,
    },
    {
      label: 'Classification',
      type: 'input',
      data: [],
      default: 'All',
      placeholder: 'Enter classification',
      controlName: 'classification',
      runClassification: false,
    },
    {
      label: 'Shift',
      type: 'dropdown',
      data: [],
      default: 'Any',
      controlName: 'shift',
      runClassification: false,
    },
  ];

  constructor(
    private rds: RepresentationGroupDataService,
    private _user: UsergroupService,
    private _commonApplicationService: CommonService
  ) {
    this.getUserInfo();
  }

  ngOnInit(): void {
    this.getShifts();
  }

  getShifts() {
    this.rds.getShifts().subscribe((data: any) => {
      const elements = data || [];
      const mapElement = elements.map(
        (element: { shiftId: any; shiftDescription: any }) => ({
          id: element.shiftId,
          description: element.shiftDescription,
        })
      );

      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Shift') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'TABLEMAINTENANCE',
        'REPRESENTATIONGROUPDATA'
      );
    });
  }

  selectRepGroup(representationGroupId: string) {
    for (let index = 0; index < this.repGroupSearchData.length; index++) {
      if (
        this.repGroupSearchData[index].representationGroupId ===
        representationGroupId
      ) {
        this.selectedRepGroup = this.repGroupSearchData[index];
        this.showButton = true;
      }
    }
  }

  showAddModal() {
    this.showAdd = true;
    this.modalId = '#addEmployee';
  }

  showEditModal(representationGroupId: string) {
    this.showEdit = true;
    this.modalId = '#editEmp';
    this.selectRepGroup(representationGroupId);
  }

  showDeleteModal(representationGroupId: string) {
    this.showDelete = true;
    this.modalId = '#deleteApplicationModal';
    this.selectRepGroup(representationGroupId);
  }

  CheckAdd(e: any) {
    this.repGroupSearchData = null;
  }

  clearFilterData(filterData: any) {
    Object.keys(filterData).forEach((k) => delete filterData[k]);
  }

  CheckEdit(e: any) {
    this.searchRepGroupData();
    this.showButton = false;
  }

  CheckDelete(e: any) {
    this.searchRepGroupData();
    this.showButton = false;
  }

  getFilterValue(event: any) {
    this.filterData = event;
  }

  searchRepGroupData() {
    this.loading = true;
    let queryParams = this.filterData;
    // if (Object.keys(this.filterData).length === 0) {
    //   queryParams.jobCodeId = '';
    //   queryParams.jobCodeDescription = '';
    // }
    //Object.keys(queryParams).forEach((k) => !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]);
    this.rds.searchRepGroupData(queryParams).subscribe(
      (res) => {
        this.repGroupSearchData = res;
        this.isApplicationDataEmpty =
          this.repGroupSearchData.length === 0 ? true : false;
        this.showButton = false;
        this.Applicationpage = 1;
        this.TotalApplicationCount = res.length;
        this.loading = false;
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 500) {
          this.loading = false;
          this.badRequestAlert(_error.error);
        } else {
          this.loading = false;
          this.validationError(_error.error);
        }
      }
    );
  }

  getbyenter(e: any) {
    this.rds.searchRepGroupData(e).subscribe((res: any) => {
      this.repGroupSearchData = res;
      this.isApplicationDataEmpty =
        this.repGroupSearchData.length === 0 ? true : false;
      this.showButton = false;
      this.Applicationpage = 1;
      this.TotalApplicationCount = res.length;
    });
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'error',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }

  @ViewChild('representationgroupdataTablemaintenanceExportTable', {
    read: ElementRef,
    static: false,
  })
  representationgroupdataTablemaintenanceExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.representationgroupdataTablemaintenanceExportTable,
        `${ApplicationEnum.REPRESENTATIONGROUPDATATABLEMAINTENANCE}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.repGroupSearchData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.repGroupSearchData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
