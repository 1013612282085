<div class="modal fade" id="addEmployee" tabindex="-1" role="dialog" aria-labelledby="addEmployeeLabel" aria-hidden="true">
    <div class="modal-dialog add-form-alignment" role="document" style="
  padding-left: 80px;
  padding-right: 80px;
">
        <div class="modal-content" style="position: fixed; width: 945px">
            <div class="modal-header" style="padding-left: 80px; padding-right: 80px">
                <h5 class="modal-title" id="addEmployeeLabel">Add Application</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
            </div>

            <div class="modal-body" style="padding-left: 80px; padding-right: 80px">
                <form [formGroup]="employeeForm">
                    <div class="text-danger" *ngIf="errorlength > 0">
                        <h6 *ngFor="let error of ruleMessages">*{{ error }}</h6>
                    </div>
                    <br />

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Employee Name:
            </label>
                        <div class="col-sm-8">
                            <input readonly type="text" formControlName="employeeName" placeholder="Employee Name" class="form-control" />
                        </div>
                    </div>
                    <!-- <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">First Name</label>
                        <div class="col-sm-8">
                            <input readonly type="text" formControlName="firstName" placeholder="First Name"
                                class="form-control">

                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Last Name</label>
                        <div class="col-sm-8">
                            <input readonly type="text" formControlName="lastName" placeholder="Last Name"
                                class="form-control">

                        </div>
                    </div> -->

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Current Plant:</label>
                        <div class="col-sm-8">
                            <input readonly formControlName="currentPlant" type="text" class="form-control" />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Current Shift:</label>
                        <div class="col-sm-8">
                            <input readonly formControlName="currentShift" type="text" class="form-control" />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Plant *:</label>
                        <div class="col-sm-8">
                            <select formControlName="plantId" class="form-control" id="exampleFormControlSelect1">
                <!-- (change)="getDeptListByPlantId($event) -->
                <option *ngIf="plants[0].default" selected value="">
                  {{ plants[0].default }}
                </option>
                <option *ngFor="let plant of plants[0].data" value="{{ plant.plantId }}">
                  {{ plant.plantId }} - {{ plant.plantDescription }}
                </option>
              </select>
                            <div *ngIf="
                  employeeForm.controls['plantId'].invalid &&
                  employeeForm.controls['plantId'].touched
                ">
                                <small class="text-danger" *ngIf="employeeForm.controls['plantId'].errors?.required">
                  Plant is required.
                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Department *:</label>
                        <div class="col-sm-8">
                            <select formControlName="departmentId" class="form-control" id="exampleFormControlSelect2">
                <option *ngIf="deptbyplantid[0].default" selected value="">
                  {{ deptbyplantid[0].default }}
                </option>
                <option *ngFor="let dept of deptbyplantid[0].data" value="{{ dept.departmentId }}">
                  {{ dept.departmentId }}
                </option>
              </select>
                            <div *ngIf="
                  employeeForm.controls['departmentId'].invalid &&
                  employeeForm.controls['departmentId'].touched
                ">
                                <small class="text-danger" *ngIf="employeeForm.controls['departmentId'].errors?.required">
                  Department is required.
                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Shift *:</label>
                        <div class="col-sm-8">
                            <select formControlName="shiftId" class="form-control" id="exampleFormControlSelect3">
                <option *ngIf="shifts[0].default" selected value="">
                  {{ shifts[0].default }}
                </option>
                <option *ngFor="let shift of shifts[0].data" value="{{ shift.shiftId }}">
                  {{ shift.shiftDescription }}
                </option>
              </select>
                            <div *ngIf="
                  employeeForm.controls['shiftId'].invalid &&
                  employeeForm.controls['shiftId'].touched
                ">
                                <small class="text-danger" *ngIf="employeeForm.controls['shiftId'].errors?.required">
                  Shift is required.
                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer" style="padding-right: 80px; ">
                <button style="
            height: 40px;
            width: 150px;
            border-radius: 10px;
          " type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" #closeAddModel (click)="clear()">
          Cancel
        </button>
                <button style="height: 40px; width: 150px; border-radius: 10px" type="button" class="btn btn-primary" [disabled]="employeeForm.invalid || !StatusCode" (click)="addEmployee()">
          Add
        </button>
            </div>
        </div>
    </div>
</div>