<div
  class="modal fade"
  id="editEmp"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addEmployeeLabel"
  aria-hidden="true"
>
  <div class="modal-dialog add-form-alignment" role="document">
    <div class="modal-content" style="position: fixed; width: 945px">
      <div class="modal-header">
        <h5 class="modal-title" id="addEmployeeLabel">Edit Application</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>
      <div class="modal-body" style="padding-left: 80px; padding-right: 80px">
        <form [formGroup]="employeeForm">
          <div class="form-row">
            <div class="form-group col-md-12">
              <input
                hidden
                readonly
                type="email"
                formControlName="AppId"
                placeholder="AppId"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Employee Name:</label>
            <div class="col-sm-8">
              <input
                readonly
                type="email"
                formControlName="employeeName"
                placeholder="Employee Name"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Current Classification:</label
            >
            <div class="col-sm-8">
              <input
                readonly
                formControlName="currentClassification"
                type="email"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Classification Applying For *:</label
            >
            <div class="col-sm-8">
              <select
                formControlName="jobCodeId"
                class="form-control"
                id="exampleFormControlSelect1"
              >
                <option
                  *ngFor="let class of classifications"
                  value="{{ class.jobCodeId }}"
                >
                  {{ class.jobCodeId }} - {{ class.jobCodeDescription }}
                </option>
              </select>
              <small
                *ngIf="
                  employeeForm.controls['jobCodeId'].errors
                    ?.leaderClassificationError
                "
                class="text-danger"
                >The applied for classification cannot be a leader
                classification</small
              >
              <small
                *ngIf="
                  employeeForm.controls['jobCodeId'].errors
                    ?.apprenticeClassificationError
                "
                class="text-danger"
                >The applied for classification cannot be an apprentice
                classification</small
              >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Employee Signature Date *:</label
            >
            <div class="col-sm-8">
              <input
                formControlName="EmpSignDate"
                type="date"
                class="form-control"
                max="{{ CalenderBlockedAfterTodayDate }}"
              />
            </div>
            <small
              *ngIf="
                employeeForm.controls['EmpSignDate'].errors?.empSignDateError
              "
              class="text-danger"
              >Employee sign date cannot be greater than today's date</small
            >
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label"
              >You must validate the employee's experience *:</label
            >
            <div class="col-sm-8">
              <input
                formControlName="ExpValidateFlag"
                type="checkbox"
                value="1"
                class="form-control"
              />
            </div>
            <div
              *ngIf="
                employeeForm.controls['ExpValidateFlag'].invalid &&
                employeeForm.controls['ExpValidateFlag'].touched
              "
            >
              <small
                class="text-danger"
                *ngIf="
                  employeeForm.controls['ExpValidateFlag'].errors?.required
                "
              >
                You must validate the employee's experience.
              </small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Status *:</label>
            <div class="col-sm-8">
              <input
                (change)="checkStatus($event)"
                (keyup)="checkStatus($event)"
                [class.is-invalid]="
                  employeeForm.get('AppStatusCode')?.invalid &&
                  employeeForm.get('AppStatusCode')?.touched
                "
                formControlName="AppStatusCode"
                class="form-control"
                id="exampleFormControlSelect2"
              />
              <small class="text-danger" *ngIf="statusCancelled"
                >You cannot enter a status of 'CANCELLED'<br />
                If you wish to cancel this application, please go to the
                'Cancel' page. <br />
                If the status was 'Cancelled', you cannot modify the application
                until the status is changed to 'OPEN' or 'CLOSED'.</small
              >
              <small class="text-danger" *ngIf="statusClosed"
                >You must enter a valid status to close the application ('CLOSED
                - REASON - XXX').</small
              >
              <small class="text-danger" *ngIf="invalidStatus"
                >You must enter a valid status ('OPEN' or
                'CLOSED-REASON-XXX').</small
              >
              <div
                *ngIf="
                  employeeForm.controls['AppStatusCode'].invalid &&
                  employeeForm.controls['AppStatusCode'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="
                    employeeForm.controls['AppStatusCode'].errors?.required
                  "
                >
                  Status is required.
                </small>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Date of Status*</label>
            <div class="col-sm-8">
              <input
                formControlName="AppStatusDate"
                type="date"
                class="form-control"
                max="{{ CalenderBlockedAfterTodayDate }}"
              />
            </div>
            <small
              *ngIf="
                employeeForm.controls['AppStatusDate'].errors?.empSignDateError
              "
              class="text-danger"
              >Status date cannot be greater than today's date</small
            >
            <div
              *ngIf="
                employeeForm.controls['AppStatusDate'].invalid &&
                employeeForm.controls['AppStatusDate'].touched
              "
            >
              <small
                class="text-danger"
                *ngIf="employeeForm.controls['AppStatusDate'].errors?.required"
              >
                Status date is required.
              </small>
            </div>
            <label class="alert alert-danger" *ngIf="employeeForm.errors">{{
              employeeForm.errors.dates
            }}</label>
          </div>

          <div class="form-group row">
            <div class="col-sm-4">
              Entered By:
              {{
                applicationSelected.enteredBy
                  ? (applicationSelected.enteredBy | uppercase)
                  : '-'
              }}
            </div>
            <div class="col-sm-4">
              Date Entered :
              {{
                applicationSelected.dateEntered
                  ? (applicationSelected.dateEntered | date: 'MM/dd/yyyy')
                  : '-'
              }}
            </div>
            <div class="col-sm-4">
              Last Update By:
              {{
                applicationSelected.lastUpdateBy
                  ? (applicationSelected.lastUpdateBy | uppercase)
                  : '-'
              }}
            </div>
          </div>

          <div class="text-danger" *ngIf="medicalRestrictionList.length > 0">
            <h6>WARNING: This employee has a current medical restriction</h6>
            <ul>
              <li *ngFor="let medicalRestriction of medicalRestrictionList">
                {{ medicalRestriction }}
              </li>
            </ul>
          </div>
        </form>
      </div>
      <div class="modal-footer" style="padding-right: 80px">
        <button
          style="height: 40px; width: 150px; border-radius: 10px"
          type="button"
          class="btn btn-secondary popupbuttonscancel"
          data-dismiss="modal"
          #closeCorrectModel
          (click)="clear()"
        >
          Cancel
        </button>
        <button
          style="height: 40px; width: 150px; border-radius: 10px"
          type="button"
          class="btn btn-primary"
          [disabled]="
            employeeForm.errors ||
            statusCancelled ||
            statusClosed ||
            employeeForm.invalid ||
            invalidStatus
          "
          (click)="updateApplication()"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>
