export enum ApplicationEnum {
    RECALLRIGHTS = "RecallRights",
    TRANSFERAGREEMENT = "TransferAgreement",
    SHIFTPREFERENCE = "ShiftPreference",
    FACILITIESSERVICETRANSFER = "FacilitiesServiceTransfer",
    PARAGRAPH19 = "Paragraph19",
    PRODUCTIONTOSKILLED = "ProductionToSkilled",
    TOOLANDDIE = "ToolAndDie",
    SKILLEDTOSKILLED = 'SkilledToSkilled',
    OVERTIME ='Overtime',
    OVERTIMEREASSIGNHISTORY ='OvertimeReassignHistory',
    ACTIONCODECHANGEHISTORY ='ActionCodeChangeHistory',
    OVERTIMEDETAILS ='OvertimeDetails',
    ANNOUNCEMENT = 'Announcement',
    DEMOGRAPHICSVIEW = 'DemographicView',
    MEDICALRESTRICTIONSVIEW ='MedicalRestrictionView',
    OVERTIMEVIEW = 'OvertimeView',
    SENIORITYVIEW = 'SeniorityView',
    SHIFTPREFERENCEVIEW ='ShiftPreferenceView',
    TRANSFERAPPLICATIONVIEW = 'TransferApplicationView',
    TRANSFERAGREEMENTNVIEW = 'TransferAgreementView',
    VIEWALLRECALLRIGHTSVIEW ='ViewAllRecallRightsView',
    VIEWALL150APPLICATIONS = 'ViewAll150ApplicationsView',
    ABSENCEREASONDATATABLEMAINTENANCE = "absencereasondata_tablemaintenance",
    APPLICATIONTYPETABLEMAINTENANCE = "applicationtype_tablemaintenance",
    CLASSIFICATIONDATATABLEMAINTENANCE = "classificationdata_tablemaintenance",
    DEMOGRAPHICDATATABLEMAINTENANCE = "demographicdata_tablemaintenance",
    DEPARTMENTDATATABLEMAINTENANCE = "demographicdata_tablemaintenance",
    DISTRICTDATATABLEMAINTENANCE = "districtdata_tablemaintenance",
    HOLIDAYDATATABLEMAINTENANCE = "holidaydata_tablemaintenance",
    NIOGROUPDATATABLEMAINTENANCE = "niogroupdata_tablemaintenance",
    OVERTIMEGROUPDATATABLEMAINTENANCE = "overtimegroupdata_tablemaintenance",
    OVERTIMEREASONDATATABLEMAINTENANCE = "overtimereasondata_tablemaintenance",
    OVERTIMETYPEDATATABLEMAINTENANCE = "overtimetypedata_tablemaintenance",
    PLANTDATATABLEMAINTENANCE = "plantdata_tablemaintenance",
    REGIONDATATABLEMAINTENANCE = "regiondata_tablemaintenance",
    REPRESENTATIONGROUPDATATABLEMAINTENANCE = "representationgroupdata_tablemaintenance",
    SHIFTDATATABLEMAINTENANCE = "shiftdata_tablemaintenance",
    SHIFTPREFERENCEDATATABLEMAINTENANCE = "shiftpreferencedata_tablemaintenance",
    ZONEDATATABLEMAINTENANCE = "zonedata_tablemaintenance",
  }