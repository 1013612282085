<div class="container-fluid app-box">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Table Maintenance</li>
        <li class="breadcrumb-item brd-li brd-li-child">Regions Data</li>
      </ol>
    </nav>
    <div class="align-self-end ml-auto">
      <button
        *ngIf="views[0]?.allowAdd"
        class="btn btn-success newbtn"
        data-toggle="modal"
        [attr.data-target]="modalId"
        data-backdrop="false"
        (click)="showAddModal()"
      >
        Add Region Data
      </button>
    </div>

    <div *ngIf="regionData !== null && regionData.length > 0">
      <button
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        style="margin-right: 50px"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
    </div>
  </div>

  <div class="row content-container mt-2 mb-2">
    <div class="div-color">
      <div class="pt-5" style="padding-top: 0px !important">
        <div>
          <table
            *ngIf="!isDataExportableView && regionData"
            class="table table-striped"
            #regiondataTablemaintenanceExportTable
          >
            <thead class="table-header transfer-header">
              <tr>
                <!-- <th scope="col">Region ID</th>
                                <th scope="col">Region Description</th>
                                <th scope="col">Status</th> -->
                <th scope="col">
                  <span (click)="onSortClick($event, 'regionId', 'string')"
                    >Region Id
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'regionId'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'regionId'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'regionId'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'regionDescription', 'string')"
                    >Region Description
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'regionDescription'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'regionDescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'regionDescription'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'status', 'string')"
                    >Status
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'status'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'status'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <!-- <th scope="col">Edit</th>
                                <th scope="col">Delete</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of regionData; let i = index" class="">
                <td class="tab-value">{{ data.regionId }}</td>
                <td class="left-tab-value">{{ data.regionDescription }}</td>
                <td class="tab-value">
                  {{ data.status === 'A' ? 'Active' : 'Inactive' }}
                </td>
                <!-- <td class="tab-value">
                                    <button *ngIf='views[0]?.allowUpdate' data-toggle="modal" [attr.data-target]="modalId"
                                    data-backdrop="false" class="cursor" (click)="showEditModal(data.regionId)"><img
                                            src="../../../../../assets/edit-regular.svg"></button>
                                </td>
                                <td class="tab-value"> <button *ngIf='views[0]?.allowDelete' data-toggle="modal" [attr.data-target]="modalId"
                                  data-backdrop="false" class="cursor" (click)="showDeleteModal(data.regionId)"><img
                                            src="../../../../../assets/delete.png"></button></td> -->
              </tr>
            </tbody>
          </table>

          <table class="table table-striped">
            <thead class="table-header transfer-header">
              <tr>
                <!-- <th scope="col">Region ID</th>
                                <th scope="col">Region Description</th>
                                <th scope="col">Status</th> -->
                <th scope="col">
                  <span (click)="onSortClick($event, 'regionId', 'string')"
                    >Region Id
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'regionId'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'regionId'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'regionId'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'regionDescription', 'string')"
                    >Region Description
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'regionDescription'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'regionDescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'regionDescription'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'status', 'string')"
                    >Status
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'status'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'status'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let data of regionData
                    | paginate
                      : {
                          id: 'joblist',
                          itemsPerPage: 20,
                          currentPage: Applicationpage,
                          totalItems: TotalApplicationCount
                        };
                  let i = index
                "
                class=""
              >
                <td class="tab-value">{{ data.regionId }}</td>
                <td class="left-tab-value">{{ data.regionDescription }}</td>
                <td class="tab-value">
                  {{ data.status === 'A' ? 'Active' : 'Inactive' }}
                </td>
                <td class="tab-value">
                  <button
                    *ngIf="views[0]?.allowUpdate"
                    data-toggle="modal"
                    [attr.data-target]="modalId"
                    data-backdrop="false"
                    class="cursor"
                    (click)="showEditModal(data.regionId)"
                  >
                    <img src="../../../../../assets/edit-regular.svg" />
                  </button>
                </td>
                <td class="tab-value">
                  <button
                    *ngIf="views[0]?.allowDelete"
                    data-toggle="modal"
                    [attr.data-target]="modalId"
                    data-backdrop="false"
                    class="cursor"
                    (click)="showDeleteModal(data.regionId)"
                  >
                    <img src="../../../../../assets/delete.png" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="single-wrap d-flex justify-content-center"
            *ngIf="regionData !== null && regionData.length > 0"
          >
            <nav aria-label="Page navigation example">
              <pagination-controls
                class="my-pagination"
                id="joblist"
                (pageChange)="Applicationpage = $event"
                previousLabel=""
                nextLabel=""
              >
              </pagination-controls>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-edit-region-data
    *ngIf="showEdit"
    [userName]="this.userInformation.userName"
    [selectedRegion]="selectedRegion"
    (isSucess)="checkEdit($event)"
  ></app-edit-region-data>
  <app-add-region-data
    *ngIf="showAdd"
    [userName]="this.userInformation.userName"
    (isSucess)="checkAdd($event)"
  >
  </app-add-region-data>
  <app-delete-region-data
    [selectedRegion]="selectedRegion"
    (isSucess)="checkDelete($event)"
  ></app-delete-region-data>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
