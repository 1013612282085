<div class="container-fluid app-box">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Table Maintenance</li>
        <li class="breadcrumb-item brd-li brd-li-child">Classification Data</li>
      </ol>
    </nav>
    <div class="align-self-end ml-auto">
      <button class="btn btn-success newbtn" (click)="viewAllClassifications()">
        View All
      </button>
    </div>
  </div>

  <div>
    <div class="row content-container mt-2 mb-2">
      <div class="pt-2 div-color">
        <div class="search-divider-div filtercss">
          <p class="search-divder-p"></p>
          <hr class="search-divider-hr" />
          <div class="jumbotron jumb-new jumb-newcontrols">
            <div class="row" display="float">
              <div class="col">
                <app-filter
                  [filterConfig]="filterConfig"
                  (filterValue)="getFilterValue($event)"
                  (formcontrolobj)="getbyenter($event)"
                >
                </app-filter>
              </div>
              <div class="col-md-1 mr-5">
                <label for="" style="color: white">&nbsp;</label>
                <a
                  class="btn btn-lg search-button"
                  (click)="searchClassifications()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="info-label divcrudbtns">
              <p style="text-align: left" class="p-margin"></p>
              <div *ngIf="views[0]?.allowAdd">
                <button
                  data-toggle="modal"
                  class="btn btn-successclass buttoncolours crudbuttons pull-right"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                  (click)="showAddModal()"
                >
                  Add
                </button>
              </div>
              <div *ngIf="views[0]?.allowUpdate">
                <button
                  data-toggle="modal"
                  *ngIf="showButton"
                  class="btn btn-successclass buttoncolours crudbuttons pull-right my-2 my-sm-0"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                  (click)="showEditModal()"
                >
                  Edit
                </button>
              </div>
              <div *ngIf="views[0]?.allowDelete">
                <button
                  data-toggle="modal"
                  *ngIf="showButton"
                  class="btn btn-successclass buttoncolours crudbuttons pull-right my-2 my-sm-0"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                  (click)="showDeleteModal()"
                >
                  Delete
                </button>
              </div>
              <div
                *ngIf="
                  classificationSearchData !== null &&
                  classificationSearchData.length > 0
                "
              >
                <button
                  class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
                  (click)="exportElmToExcel()"
                >
                  Export to Excel
                </button>
              </div>
            </div>
            <table
              class="table table-striped"
              *ngIf="
                !isDataExportableView &&
                classificationSearchData !== null &&
                classificationSearchData.length > 0
              "
              #classificationdataTablemaintenanceExportTable
            >
              <thead class="table-header">
                <tr>
                  <!-- <th class="left-header" scope="col">Select</th> -->
                  <!-- <th class="left-header" scope="col">Job code</th>
                                    <th class="left-header" scope="col">Job code description</th>
                                    <th class="left-header" scope="col">Status</th> -->
                  <th scope="col">
                    <span (click)="onSortClick($event, 'jobcodeid', 'string')"
                      >Job code
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'jobcodeid'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'jobcodeid'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'jobcodeid'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'jobcodedescription', 'string')
                      "
                      >Job code description
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'jobcodedescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'jobcodedescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'jobcodedescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'status', 'string')"
                      >Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'status'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'status'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class=""
                  *ngFor="let data of classificationSearchData; let i = index"
                >
                  <!-- <td class="tab-value"><input type="radio" id="select" name="application" (click)="selectClassification(i, data.jobcodeid)"></td> -->
                  <td class="tab-value">{{ data.jobcodeid }}</td>
                  <td class="left-tab-value">{{ data.jobcodedescription }}</td>
                  <td class="tab-value">
                    {{ data.status === 'A' ? 'Active' : 'Inactive' }}
                  </td>
                </tr>
              </tbody>
            </table>

            <table
              class="table table-striped"
              *ngIf="
                classificationSearchData !== null &&
                classificationSearchData.length > 0
              "
            >
              <thead class="table-header">
                <tr>
                  <th class="left-header" scope="col">Select</th>
                  <!-- <th class="left-header" scope="col">Job code</th>
                                    <th class="left-header" scope="col">Job code description</th>
                                    <th class="left-header" scope="col">Status</th> -->
                  <th scope="col">
                    <span (click)="onSortClick($event, 'jobcodeid', 'string')"
                      >Job code
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'jobcodeid'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'jobcodeid'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'jobcodeid'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'jobcodedescription', 'string')
                      "
                      >Job code description
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'jobcodedescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'jobcodedescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'jobcodedescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'status', 'string')"
                      >Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'status'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'status'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class=""
                  *ngFor="
                    let data of classificationSearchData
                      | paginate
                        : {
                            id: 'joblist',
                            itemsPerPage: 20,
                            currentPage: Applicationpage,
                            totalItems: TotalApplicationCount
                          };
                    let i = index
                  "
                >
                  <td class="tab-value">
                    <input
                      type="radio"
                      id="select"
                      name="application"
                      (click)="selectClassification(i, data.jobcodeid)"
                    />
                  </td>
                  <td class="tab-value">{{ data.jobcodeid }}</td>
                  <td class="left-tab-value">{{ data.jobcodedescription }}</td>
                  <td class="tab-value">
                    {{ data.status === 'A' ? 'Active' : 'Inactive' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="single-wrap d-flex justify-content-center"
              *ngIf="
                classificationSearchData !== null &&
                classificationSearchData.length > 0
              "
            >
              <nav aria-label="Page navigation example">
                <pagination-controls
                  class="my-pagination"
                  id="joblist"
                  (pageChange)="Applicationpage = $event"
                  previousLabel=""
                  nextLabel=""
                >
                </pagination-controls>
              </nav>
            </div>
            <div *ngIf="isApplicationDataEmpty">
              <p *ngIf="isApplicationDataEmpty">No existing records Found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Edit Modal -->
  <app-add-classification
    *ngIf="showAdd"
    [userName]="this.userInformation.userName"
    (isSucess)="CheckAdd($event)"
  >
  </app-add-classification>

  <!--Add Modal-->
  <app-edit-classification
    *ngIf="showEdit"
    [userName]="this.userInformation.userName"
    [selectedClassification]="selectedClassification"
    (isSucess)="CheckEdit($event)"
  >
  </app-edit-classification>

  <!--Delete Modal-->
  <app-delete-classification
    *ngIf="showDelete"
    [selectedClassification]="selectedClassification"
    (isSucess)="CheckDelete($event)"
  >
  </app-delete-classification>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
