import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DistrictDataService } from 'src/app/Services/TableMaintenance/DistrictData/district-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-delete-district-data',
  templateUrl: './delete-district-data.component.html',
  styleUrls: ['./delete-district-data.component.css'],
})
export class DeleteDistrictDataComponent {
  @Input() selectedDistrict: any;
  districtid: string = '';
  @Output() isSucess = new EventEmitter();
  constructor(private districtDataService: DistrictDataService) {}

  clear() {
    //TODO
  }
  confirmDelete() {
    if (this.selectedDistrict) {
      this.districtid = this.selectedDistrict.districtid;
      this.districtDataService.deleteDistrictData(this.districtid).subscribe(
        (res) => {
          this.successAlert();
          this.isSucess.emit(true);
        },
        (_error: HttpErrorResponse) => {
          if (_error.status === 500) {
            this.badRequestAlert(_error.error);
          } else {
            this.badRequestAlert(_error.error);
          }
        }
      );
    }
  }
  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'warning',
      title: `${errorMessage}`,
    });
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Record previously selected has been removed successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }
}
