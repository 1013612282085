<div
  *ngIf="selectedEmp"
  class="modal fade"
  id="cancelApplicationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="cancelApplicationModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog add-form-alignment modal-dialog-alignment"
    style="margin: 0px auto"
    role="document"
  >
    <div class="modal-content" style="position: fixed; width: 945px">
      <div class="modal-header">
        <h5 class="modal-title" id="cancelApplicationModalLabel">
          Cancel Application
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>

      <div
        class="modal-body"
        style="padding-left: 80px; height: 450px; padding-right: 80px"
      >
        <form [formGroup]="dateForm">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Employee Name:</label>
            <div class="col-sm-8">
              <div>
                {{
                  selectedEmp.lastName ? selectedEmp.lastName : 'N/A'
                }},&nbsp;{{
                  selectedEmp.firstName ? selectedEmp.firstName : 'N/A'
                }}&nbsp;{{
                  selectedEmp.middleName ? selectedEmp.middleName : ''
                }}
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Current Classification:</label
            >
            <div class="col-sm-8">
              <div>{{ JobCodeClassification }}</div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Current Plant:</label>
            <div class="col-sm-8">
              <div>{{ Plant }}</div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Current Department:</label>
            <div class="col-sm-8">
              <div>{{ Department }}</div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Current Shift:</label>
            <div class="col-sm-8">
              <div>{{ Shift }}</div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Application Number:</label>
            <div class="col-sm-8">
              <div>{{ selectedEmp.appNo ? selectedEmp.appNo : 'N/A' }}</div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Classification Applied For:</label
            >
            <div class="col-sm-8">
              <div>
                {{ selectedEmp.jobCodeId ? selectedEmp.jobCodeId : 'N/A' }}-{{
                  selectedEmp.jobCodeDescription
                    ? selectedEmp.jobCodeDescription
                    : 'N/A'
                }}
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Plant Applied For:</label>
            <div class="col-sm-8">
              <div>
                {{ selectedEmp.plantId ? selectedEmp.plantId : 'N/A' }}-{{
                  selectedEmp.plantDescription
                    ? selectedEmp.plantDescription
                    : 'N/A'
                }}
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Department Applied For:</label
            >
            <div class="col-sm-8">
              <div>
                {{ selectedEmp.deptId ? selectedEmp.deptId : 'N/A' }}-{{
                  selectedEmp.deptDescription
                    ? selectedEmp.deptDescription
                    : 'N/A'
                }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Shift Applied For:</label>
            <div class="col-sm-8">
              <div>
                {{
                  selectedEmp.shiftDescription
                    ? selectedEmp.shiftDescription
                    : 'N/A'
                }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Employee Signature Date:</label
            >
            <div class="col-sm-8">
              <div>
                {{
                  selectedEmp.empSignDate
                    ? (selectedEmp.empSignDate | date: 'MM/dd/yyyy')
                    : 'N/A'
                }}
              </div>
            </div>
          </div>
          <div *ngIf="isLoggedInUserAdmin">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Date of Cancel *:</label>
              <div class="col-sm-8">
                <div>
                  <input
                    type="date"
                    class="form-control"
                    formControlName="AppStatusDate"
                    max="{{ maxCancelDate }}"
                  />
                  <div
                    *ngIf="
                      dateForm.controls['AppStatusDate'].invalid &&
                      dateForm.controls['AppStatusDate'].touched
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="
                        dateForm.controls['AppStatusDate'].errors?.required
                      "
                    >
                      Cancel date is required.
                    </small>
                  </div>
                  <label class="alert alert-danger" *ngIf="dateForm.errors"
                    >{{ dateForm.errors.dates }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!isLoggedInUserAdmin">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Date of Cancel:</label>
              <div class="col-sm-8">
                <div>
                  <input
                    hidden
                    type="date"
                    class="form-control"
                    formControlName="AppStatusDate"
                    value="{{ currentDate | date: 'yyyy-MM-dd' }}"
                  />
                  <label>{{ currentDate | date: 'MM/dd/yyyy' }}</label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer" style="padding-right: 80px">
        <button
          style="height: 40px; width: 150px; border-radius: 10px"
          type="button"
          class="btn btn-secondary popupbuttonscancel"
          data-dismiss="modal"
          (click)="clear()"
          #closeCancelModal
        >
          Cancel
        </button>
        <button
          style="height: 40px; width: 150px; border-radius: 10px"
          type="button"
          class="btn btn-primary"
          (click)="CancelApplication()"
        >
          Cancel Application
        </button>
      </div>
      <!-- <div class="modal-footer" style="padding-right: 80px">
                <button style="
                height: 40px;
                width: 150px;
                border-radius: 10px;
              " type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" (click)="clear()" #closeCancelModal>Cancel</button>
                <button style="height: 50px; width: 175px; border-radius: 10px" type="button" class="btn btn-primary"
                [disabled]="dateForm.errors || dateForm.invalid"
                (click)="CancelApplication()">Cancel
                    Application</button>
            </div> -->
    </div>
  </div>
</div>
