import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root',
})
export class TransferAgreementService {
  commonUtility = new CommonUtility();
  private readonly api_getClassificationByType = '/GetClassificationsByType';
  private readonly api_TransferAgreementSearch = '/TransferAgreementSearch';
  private readonly api_GetPlants = '/GetPlants?type=';
  constructor(private http: HttpClient, private appCom: MSALserviceService) {}

  // getProductionClassifications(): Observable<any> {
  //   const headers = this.commonUtility.getHttpHeaderWithBearerToken(
  //     this.appCom.getAuthToken()
  //   );
  //   return this.http.get<any>(
  //     `${environment.applicationsURL}` + this.api_getClassificationByType+"/P",
  //     { headers }
  //   );
  // }

  getProductionClassifications(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this.http.get<any>(
      `${environment.applicationsURL}` + this.api_getClassificationByType,
      { headers }
    );
  }

  getActivePlants(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this.http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_GetPlants + 'A',
      { headers }
    );
  }
  searchTransferAgreement(formData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this.http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_TransferAgreementSearch,
      formData,
      { headers }
    );
  }
}
