import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
})
export class FilterComponent implements OnInit {
  @Input() filterConfig: any = [];
  @Input() filterConfigAdd: any = [];
  @Output() filterValue = new EventEmitter();
  @Output() formcontrolobj = new EventEmitter();

  filterForm: FormGroup | any;
  classificationType: string = '';
  filteredGroups: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private _commonApplicationService: CommonService
  ) {}

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({});
    this.renderForm();
    this.formEvent();
    this.renderForm2();
    this.filterForm
      .get('SEARCH_MM')
      ?.patchValue(this._commonApplicationService.formatDate(new Date()));
  }

  renderForm() {
    for (let index = 0; index < this.filterConfig.length; index++) {
      this.filterForm.addControl(
        this.filterConfig[index]['controlName'],
        this.formBuilder.control('')
      );
    }
  }
  renderForm2() {
    for (let index = 0; index < this.filterConfigAdd.length; index++) {
      this.filterForm.addControl(
        this.filterConfigAdd[index]['controlName'],
        this.formBuilder.control('')
      );
    }
  }

  formEvent() {
    this.filterForm.valueChanges.subscribe((inputValue: any) => {
      let valueToUse = null;
      if (!!inputValue.ClassGroup) {
        valueToUse = inputValue.ClassGroup;
      } else if (!!inputValue.jobCodeId) {
        valueToUse = inputValue.jobCodeId;
      }
      this.getClassificationType(valueToUse);
      this.filterValue.emit(inputValue);
    });
  }

  patchValue(event: any, controlName: any) {
    this.filterForm.get(controlName).patchValue(event.target.value);
  }

  getuserentry(e: any, controlname: any) {
    let formObj = {
      [controlname]: e.target.value,
    };
    this.formcontrolobj.emit(formObj);
  }

  private getClassificationType(value: string) {
    this.filterConfig.filter((element: any) => {
      if (element.runClassification) {
        if (
          element.controlName.toLowerCase() === 'classgroup' ||
          element.controlName.toLowerCase() === 'jobcodeid'
        ) {
          element.data.filter((element: any) => {
            if (element.id === value) {
              this.classificationType = element.classificationType;
              this.getShiftPreferenceGroupForSkilled(this.classificationType);
            }
          });
        }
      }
    });
  }

  private getShiftPreferenceGroupForSkilled(classificationType: string) {
    const excludedProductionIds = ['7', '8', '9'];
    const excludedSkilledIds = ['5'];
    const groups = this.filterConfig.filter(
      (element: any) =>
        element.controlName === 'SPgroup_Search' ||
        element.controlName === 'plantid'
    );
    if (classificationType === 'P') {
      const filteredGroupsProduction = groups[0].data.filter(
        (element: any) => !excludedSkilledIds.includes(element.id)
      );

      this.filteredGroups = filteredGroupsProduction;
    } else {
      const filteredGroupsSkilled = groups[0].data.filter(
        (element: any) => !excludedProductionIds.includes(element.id)
      );
      this.filteredGroups = filteredGroupsSkilled;
    }
  }
}
