<div class="modal fade" id="addEmployee" tabindex="-1" role="dialog" aria-labelledby="addEmployeeLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addEmployeeLabel">Add Department</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="deptForm">
                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Department ID*</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="DeptId" class="form-control">
                            <div *ngIf="deptForm.controls['DeptId'].invalid && deptForm.controls['DeptId'].touched">
                                <small class="text-danger" *ngIf="deptForm.controls['DeptId'].errors?.required">
                                    You must specify department code to be entered
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Department description*</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="DeptDescription" class="form-control">
                            <div *ngIf="deptForm.controls['DeptDescription'].invalid && deptForm.controls['DeptDescription'].touched">
                                <small class="text-danger" *ngIf="deptForm.controls['DeptDescription'].errors?.required">
                                    You must specify the department description
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Status*</label>
                        <div class="col-sm-8">
                            <select formControlName="Status" class="form-control">
                                <option *ngIf="Status[Status.length-1].default" selected value="">
                                    {{Status[Status.length-1].default}}</option>
                                <option *ngFor="let class of Status[Status.length-1].data" value={{class.statusType}}>
                                    {{class.statusName}}
                                </option>
                            </select>
                            <div *ngIf="deptForm.controls['Status'].invalid && deptForm.controls['Status'].touched">
                                <small class="text-danger" *ngIf="deptForm.controls['Status'].errors?.required">
                                    You must specify the status
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Speed code*</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="SpeedCode" class="form-control">
                            <div *ngIf="deptForm.controls['SpeedCode'].invalid && deptForm.controls['SpeedCode'].touched">
                                <small class="text-danger" *ngIf="deptForm.controls['SpeedCode'].errors?.required">
                                    You must specify speed code
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Plant*</label>
                        <div class="col-sm-8">
                            <select formControlName="PlantId" class="form-control">
                                <option *ngIf="Plants[Plants.length-1].default" selected value="">
                                    {{Plants[Plants.length-1].default}}</option>
                                <option *ngFor="let class of Plants[Plants.length-1].data" value={{class.plantid}}>
                                    {{class.plantid}}-{{class.plantdescription}}
                                </option>
                            </select>
                            <div *ngIf="deptForm.controls['PlantId'].invalid && deptForm.controls['PlantId'].touched">
                                <small class="text-danger" *ngIf="deptForm.controls['PlantId'].errors?.required">
                                    You must specify a plant for this department
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Region*</label>
                        <div class="col-sm-8">
                            <select formControlName="RegionId" class="form-control">
                                <option *ngIf="Regions[Regions.length-1].default" selected value="">
                                    {{Regions[Regions.length-1].default}}</option>
                                <option *ngFor="let class of Regions[Regions.length-1].data" value={{class.regionId}}>
                                    {{class.regionId}}-{{class.regionDescription}}
                                </option>
                            </select>
                            <div *ngIf="deptForm.controls['RegionId'].invalid && deptForm.controls['RegionId'].touched">
                                <small class="text-danger" *ngIf="deptForm.controls['RegionId'].errors?.required">
                                    You must specify a region for this department
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" #closeAddModel (click)="clear()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]="deptForm.errors || deptForm.invalid" (click)="submitDepartment()">Add </button>
            </div>
        </div>
    </div>
</div>