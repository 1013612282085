import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

  private readonly _GetGroups = '/GetGroups';
  private readonly _AnnouncementSearch = '/AnnouncementSearch';
  private readonly _AnnouncementAdd = '/AnnouncementAdd';
  private readonly _AnnouncementDelete = '/AnnoucementDelete/';
  private readonly _AnnouncementCorrect = '/AnnouncementCorrect';
  private readonly _getCurrentGroupAnnouncements = '/getCurrentGroupAnnouncements';

  commonUtility = new CommonUtility();
  
  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  GetGroups(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.adminURL}` + this._GetGroups,
      { headers }
    );
  }
  AnnouncementSearch(queryParams:any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.adminURL}` + this._AnnouncementSearch
      ,queryParams,
      { headers }
    );
  }
  AnnouncementAdd(queryParams:any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.adminURL}` + this._AnnouncementAdd,queryParams,
      { headers }
    );
  }
  AnnouncementCorrect(queryParams:any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.adminURL}` + this._AnnouncementCorrect,queryParams,
      { headers }
    );
  }
  AnnouncementDelete(AnnouncementId: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.delete<any>(
      `${environment.adminURL}` + this._AnnouncementDelete +'/'+ AnnouncementId,
      { headers }
    );
  }
  getCurrentGroupAnnouncement(queryParam : any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.adminURL}` + this._getCurrentGroupAnnouncements , queryParam,
      { headers }
    );
  }
}
