<div class="search-divider-div filtercss">

</div>

<div [formGroup]="InputSearchForm" class="form-row">
    <div class="form-group col-md-2" *ngIf="SectionDepartmentbool">
        <gyansys-input ngDefaultControl formControlName="DepartmentControl" [txtBoxValue]="DefaultDeptVal" [SectionName]="SectionDepartment" (EventEmitToParent)="TextBoxEventHandler($event, SectionDepartment)">
        </gyansys-input>
        <div *ngIf="f.DepartmentControl.invalid" class="alert alert-danger">
            <div *ngIf="f.DepartmentControl.errors?.maxlength">
                {{ SectionDepartment }} max Length is 4 characters.
            </div>
            <div *ngIf="f.DepartmentControl.errors?.required">
                {{ SectionDepartment }} is required.
            </div>
        </div>
    </div>

    <div class="form-group col-md-2" *ngIf="SectionClassificationbool">
        <gyansys-input ngDefaultControl formControlName="ClassificationControl" [SectionName]="SectionClassification" [txtBoxValue]="DefaultClassVal" (EventEmitToParent)="TextBoxEventHandler($event, SectionClassification)">
        </gyansys-input>
        <div *ngIf="f.ClassificationControl.invalid" class="alert alert-danger">
            <div *ngIf="f.ClassificationControl.errors?.maxlength">
                {{ SectionClassification }} max Length is 15 characters.
            </div>
        </div>
    </div>

    <div class="form-group col-md-2" *ngIf="SectionLastNamebool">
        <gyansys-input ngDefaultControl formControlName="LastNameControl" [SectionName]="SectionLastName" [txtBoxValue]="DefaultIndLastNameVal" (EventEmitToParent)="TextBoxEventHandler($event, SectionLastName)">
        </gyansys-input>
    </div>

    <div class="form-group col-md-2" *ngIf="SectionFirstNamebool">
        <gyansys-input ngDefaultControl formControlName="FirstNameControl" [SectionName]="SectionFirstName" [txtBoxValue]="DefaultIndFirstNameVal" (EventEmitToParent)="TextBoxEventHandler($event, SectionFirstName)">
        </gyansys-input>
    </div>

    <div class="form-group col-md-2" *ngIf="SectionATIDbool">
        <gyansys-input ngDefaultControl formControlName="AtidControl" [SectionName]="SectionEmployeeId" [txtBoxValue]="DefaultIndEmpIdVal" (EventEmitToParent)="TextBoxEventHandler($event,SectionEmployeeId)">
        </gyansys-input>
    </div>

    <div class="form-group col-md-2" *ngIf="SectionShiftBool">
        <gyansys-dropdown ngDefaultControl formControlName="ShiftControl" [SectionName]="SectionShift" [selectedDevice]="DefaultShiftVal" [SourceData]="ShiftsData" (EventDropDown)="DropDownEventHandler($event, SectionShift)"></gyansys-dropdown>
    </div>

    <div class="form-group col-md-2" *ngIf="SectionOverTimeTypeBool && !isAllocationView">
        <gyansys-dropdown ngDefaultControl [SectionName]="SectionOverTimeType" [SourceData]="OverTimeTypeData" [selectedDevice]="DefaultOverTimeTypeVal" formControlName="OverTimeType" (EventDropDown)="DropDownEventHandler($event, SectionOverTimeType)">
        </gyansys-dropdown>
    </div>

    <div class="form-group col-md-2" *ngIf="SectionOverTimeStatusbool">
        <gyansys-dropdown ngDefaultControl [SectionName]="SectionOverTimeStatus" [SourceData]="StatusData" [selectedDevice]="DefaultOverTimeStatusVal" formControlName="OverTimeStatus" (EventDropDown)="DropDownEventHandler($event, SectionOverTimeStatus)">
        </gyansys-dropdown>
    </div>

    <div class="form-group col-md-3" *ngIf="SectionAsOfDatebool">
        <gyansys-date ngDefaultControl formControlName="AsOfDateControl" [SectionName]="SectionAsOfDate" [dateValue]="DefaultAsOfDateVal" (EventEmitToParent)="DateEventHandler($event, SectionAsOfDate)">
        </gyansys-date>
    </div>

    <div class="form-group col-md-3" *ngIf="SectionDateRangebool">
        <gyansys-date ngDefaultControl formControlName="StartDate" [SectionName]="SectionStartDate" [dateValue]="DefaultStartDateVal" (EventEmitToParent)="DateEventHandler($event, SectionStartDate)"></gyansys-date>
    </div>

    <div class="form-group col-md-3" *ngIf="SectionDateRangebool" >
        <gyansys-date ngDefaultControl formControlName="EndDate" [SectionName]="SectionEndDate" [dateValue]="DefaultEndDateVal" (EventEmitToParent)="DateEventHandler($event, SectionEndDate)"></gyansys-date>
    </div>
</div>