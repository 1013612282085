<div class="container-fluid app-box" style="background-color: #ffffff">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Reports</li>
        <li class="breadcrumb-item brd-li brd-li-child">
          Overtime Accepted vs Rejected Hours
        </li>
      </ol>
    </nav>
  </div>
  <div>
    <div class="row content-container mt-2 mb-2">
      <div class="search-divider-div filtercss">
        <div
          class="jumbotron jumb-new jumb-newcontrols"
          style="margin-bottom: 0px !important; padding: 0.7rem 2.5rem"
          *ngIf="showFilter"
        >
          <div class="row" display="float">
            <div class="col">
              <app-filter
                [filterConfig]="filterConfig"
                (filterValue)="getFilterValue($event)"
              >
              </app-filter>
              <!-- <hr class="my-4"> -->
            </div>
            <div class="col-md-1 mr-5" style="padding-right: 80px">
              <a
                class="btn btn-lg search-button"
                (click)="Generate()"
                role="button"
                >Search
                <img
                  src="./assets/search-solid.svg"
                  class="img-fluid user-icon ml-1 search-icon"
                />
              </a>
            </div>
          </div>
        </div>
        <!-- filter ends -->
        <div style="margin-top: 5px; text-align: center; width: 100%">
          <h4
            *ngIf="
              !responseDataExists &&
              responseData !== null &&
              responseData.length > 0 &&
              !isLoading
            "
          >
            Paid hours report from
            {{ FilterConfigLog.fromDate | date: 'MM/dd/yyyy' }} to
            {{ FilterConfigLog.toDate | date: 'MM/dd/yyyy' }} for Overtime
            Group: {{ overtimegroupname ? overtimegroupname : 'All' }}
          </h4>
          <!-- <h3 *ngIf="!responseDataExists && (responseData !==null && responseData.length > 0) && !isLoading" [innerHTML]="tableData"> </h3> -->
          <h3 *ngIf="responseDataExists && !isLoading">
            No Data for the particular search was found.
          </h3>
          <div
            class="chart-container"
            chart-click="onDetails($event)"
            style="height: 400px; width: 400px; margin: auto"
          >
            <canvas id="canvas"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-loading [show]="isLoading" [config]="{ backdropBorderRadius: '0' }">
    <!--[show]="loading"  -->
  </ngx-loading>
</div>
