<div class="container-fluid app-box">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Table Maintenance</li>
        <li class="breadcrumb-item brd-li brd-li-child">AbsenceReasonData</li>
      </ol>
    </nav>
  </div>

  <div>
    <div class="row content-container mt-2 mb-2">
      <!-- global search filter starts -->
      <div class="pt-2 div-color">
        <div class="pt-5">
          <!--For application-->
          <div>
            <div class="info-label ml-3 mb-2">
              <p style="text-align: left">
                <button type="button" class="btn btn-primary add-btn mr-3">
                  Add
                </button>
                <button type="button" class="btn btn-primary disable-btn mr-3">
                  Edit
                </button>
                <button type="button" class="btn btn-primary disable-btn mr-3">
                  Delete
                </button>
              </p>
            </div>
            <table class="table table-striped">
              <thead class="table-header">
                <th scope="col"></th>
                <th scope="col">Id and Description</th>
                <th scope="col">Hours Required</th>
                <th scope="col">Comment Required</th>
                <th scope="col">Status</th>
              </thead>
              <tbody>
                <tr class="">
                  <td><input type="radio" id="select" name="application" /></td>
                  <td class="">$- Full Day Absence - FMLA</td>
                  <td class="">Yes</td>
                  <td class="">No</td>
                  <td class="">Active</td>
                </tr>
                <tr class="">
                  <td>
                    <input type="radio" id="select1" name="application" />
                  </td>
                  <td class="">$- Full Day Absence - FMLA</td>
                  <td class="">Yes</td>
                  <td class="">No</td>
                  <td class="">Active</td>
                </tr>
                <tr class="">
                  <td>
                    <input type="radio" id="select2" name="application" />
                  </td>
                  <td class="">$- Full Day Absence - FMLA</td>
                  <td class="">Yes</td>
                  <td class="">No</td>
                  <td class="">Active</td>
                </tr>
                <tr class="">
                  <td>
                    <input type="radio" id="select3" name="application" />
                  </td>
                  <td class="">$- Full Day Absence - FMLA</td>
                  <td class="">Yes</td>
                  <td class="">No</td>
                  <td class="">Active</td>
                </tr>
              </tbody>
            </table>
            <!-- <div *ngIf="!isApplication">
                            <p *ngIf="applicationData!==null &&  applicationData.length === 0"> Your requested
                                application will display here. <br> Note: You can not edit your application.
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Add / Edit Modal -->

  <!-- <app-edit [selectedEmp]="selectedEmp" [isEdit]="isEdit" (clean)="cleanEmp($event)" (isSucess)="CheckEdit($event)"
        [title]="modalTitle" *ngIf="isEdit"></app-edit>

    <add-emp [selectedEmp]="selectedEmp" [isAdd]="isAdd" (clean)="cleanEmp($event)" (isSucess)="CheckAdd($event)"
        *ngIf="isAdd"></add-emp> -->

  <!-- Delete Modal -->

  <!-- <app-delete [selectedEmp]="selectedEmp" [employeeForm]="employeeForm" (isSucess)="CheckDelete($event)"></app-delete>

    Cancel Modal
    <app-cancel [isLoggedInUserAdmin]="isLoggedInUserAdmin" [selectedEmp]="selectedEmp" (isSucess)="CheckCancel($event)" [employeeForm]="employeeForm" [userInformation]="userInformation"></app-cancel> -->
</div>
