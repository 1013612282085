import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { OverTimeMaintanceService } from 'src/app/Services/Applications/OvertimeMaintanence/overtime.maintanence.service';
import { LocalStorageService } from 'src/app/Services/LocalStorageService/locastorage.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';

@Component({
  selector: 'app-archive-overtime-detail',
  templateUrl: './archive-overtime-detail.component.html',
  styleUrls: ['./archive-overtime-detail.component.css'],
})
export class ArchiveOvertimeDetailComponent {
  @Input() selectedEmp: any;
  @Input() isArchive: boolean = false;
  @Input() title: any;
  @Input() loggedInUserATID: any;
  @Output() clean = new EventEmitter();
  @Output() isSuccess = new EventEmitter();
  @ViewChild('closeArchiveModal') closeArchiveModal!: ElementRef;

  userData: any[] = [];
  employeeid: any;
  accHours: any;
  archiveAllData: any = [];
  modifiedarchiveAllData: any = [];
  currentDate = new Date();
  datearchived: string = '';
  dateArchived1MonthAgoLaterCheck: boolean = false;
  isDateOfferedLessThanDateArchived: boolean = false;
  demographicData: any;
  checkboxOverride: boolean = false;
  checkAllRecords: boolean = false;
  isOvertimeGroupNameChecked: boolean = true;

  constructor(
    private fb: FormBuilder,
    private _sweetAlertService: SweetAlertService,
    private ot: OverTimeMaintanceService,
    private sweetAlert: SweetAlertService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private _commonApplicationService: CommonService
  ) {
    this.employeeid = this.localStorageService.getItem('ovtAtid');
    this.accHours = this.localStorageService.getItem('ovtAtidAccHours');
    this.ot.NavigateToDetailByUser(this.employeeid).subscribe((data) => {
      this.demographicData = data;
      //console.log('demographicData', this.demographicData);
    });
    this.navigateToArchiveAll(this.employeeid);
  }

  clear() {
    this.clean.emit('');
    this.closeArchiveModal.nativeElement.click();
    this.resetForm();
  }

  resetForm() {
    this.datearchived = this._commonApplicationService.formatDate(
      this.currentDate
    );
    this.checkAllRecords = false;
    //this.selectAllEmployee();
    this.isSuccess.emit(true);
  }

  // /*check box functions*/
  // selectAllEmployee() {
  //   // for (var i = 0; i < this.archiveAllData.length; i++) {
  //   //   if (this.checkAllRecords) {
  //   //     this.userData[i].isselected = this.checkAllRecords;
  //   //   }
  //   //   else {
  //   //     this.userData[i].isselected = this.checkAllRecords;
  //   //   }
  //   // }
  // }

  MainNavigate() {
    this.closeArchiveModal.nativeElement.click();
    this.router.navigate(['Overtime']);
  }

  //IterableSet: any[] = [];

  //overtimeGroupList: any[] = [];
  overtimeGroupList: any = [];
  navigateToArchiveAll(employeeid: string) {
    this.userData.length = 0;
    this.ot.NavigateToArchiveAll(employeeid).subscribe((data) => {
      this.userData = data;
      for (var i = 0; i < this.userData.length; i++) {
        this.userData[i].isselected = false;
      }

      // //console.log("isUser--", data);
      // //console.log("isUser--", this.userData);
      //this.IterableSet.push(this.groupBy(this.userData, 'overtimegroupname'))
      ////console.log('new new ', this.IterableSet);
      //this.populateData();
      // const distinctThings = this.userData.filter((thing:any, i:any, arr:any) => {
      //   return arr.indexOf(arr.find(t:any => t.id === thing.id)) === i;
      // });

      this.overtimeGroupList = Array.from(
        new Set(this.userData.map((item: any) => item.overtimegroupname))
      );
      //console.log('gp n ', this.overtimeGroupList);
    });
    this.datearchived = this._commonApplicationService.formatDate(
      this.currentDate
    );
  }

  filterOvertimeRecordsByGroup(otGroupName: any) {
    ////console.log('param ', otGroupName);
    return this.userData.filter((x) => x.overtimegroupname === otGroupName);
  }

  // groupBy(array: any, key: any) {
  //   // Return the end result
  //   return array.reduce((result: any, currentValue: any) => {
  //     // If an array already present for key, push it to the array. Else create an array and push the object
  //     (result[currentValue[key]] = result[currentValue[key]] || []).push(
  //       currentValue
  //     );
  //     // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
  //     return result;
  //   }, {}); // empty object is the initial value for result object
  // }

  // populateData() {
  //   for (var i = 0; i < this.userData.length; i++) {
  //     this.userData[i].isselected = false;
  //   }
  //   // for (var i = 0; i < this.userData.length; i++) {
  //   //   var obj = {
  //   //     recordid: this.userData[i].recordid,
  //   //     datearchived: this.datearchived,
  //   //     updatedby: this.loggedInUserATID,
  //   //     isselected: false
  //   //   }
  //   //   this.archiveAllData.push(obj);
  //   //   this.userData[i].isselected = false;
  //   // }
  // }

  // onRecordSelect(idx: number) {
  //   this.userData[idx]
  // }

  archiveAll() {
    this.validationCheckEarlierOlderDate();
    //this.validateOvertimeGroup();
    this.archiveAllData.legth = 0;

    for (var i = 0; i < this.userData.length; i++) {
      if (this.userData[i].isselected === true) {
        var obj = {
          recordid: this.userData[i].recordid,
          datearchived: this.datearchived,
          updatedby: this.loggedInUserATID,
          isselected: false,
        };
        this.archiveAllData.push(obj);
      }
    }

    if (this.archiveAllData.length === 0) {
      this._sweetAlertService.validationError(
        'error',
        'Update Unsuccessful',
        'No Group selected to Archive'
      );
    } else {
      //console.log('passed', this.archiveAllData);
      if (
        !this.dateArchived1MonthAgoLaterCheck &&
        !this.isDateOfferedLessThanDateArchived &&
        this.isOvertimeGroupNameChecked
      ) {
        this.ot
          .OveritmeArchiveAll(JSON.stringify(this.archiveAllData))
          .subscribe((data: any) => {
            let returnCount = data.length;
            if (returnCount > 0) {
              this._sweetAlertService.validationError(
                'error',
                'Update Unsuccessful',
                'Could not archive records'
              );
            } else {
              this.clear();
              this._sweetAlertService.successAlert(
                'top-end',
                'success',
                'The overtime records for the groups that you have selected have been archived successfully.',
                false,
                2000
              );
              this.navigateToArchiveAll(this.employeeid);
              this.isSuccess.emit(true);
            }
          }),
          (_error: HttpErrorResponse) => {
            //console.error(_error.status);
            this._sweetAlertService.InternalServerError500(
              'error',
              'ERROR',
              'An Error occured while processing. Please try again.'
            );
          };
      }
    }
  }

  onChangeArchiveDate(e: any) {
    for (var i = 0; i < this.archiveAllData.length; i++) {
      this.archiveAllData[i].datearchived = e.target.value;
    }
  }

  validationCheckEarlierOlderDate() {
    this.dateArchived1MonthAgoLaterCheck = false;
    this.isDateOfferedLessThanDateArchived = false;
    var Date1MonthLaterFromToday =
      this._commonApplicationService.getDateFromToday(1);
    var Date1MonthAgoFromToday =
      this._commonApplicationService.getDateEarlierFromToday(1);

    if (
      (new Date(this.userData[0].datearchived) >
        new Date(Date1MonthLaterFromToday) ||
        new Date(this.userData[0].datearchived) <
          new Date(Date1MonthAgoFromToday)) &&
      !this.checkboxOverride
    ) {
      this.dateArchived1MonthAgoLaterCheck = true;
    }
    for (var i = 0; i < this.userData.length; i++) {
      if (
        new Date(this.userData[i].dateoffered) >
        new Date(this.userData[0].datearchived)
      ) {
        this.isDateOfferedLessThanDateArchived = true;
        break;
      }
    }
  }

  checkthisGroupRecords(otGroupName: any) {
    //console.log('param 1', this.userData);
    for (var i = 0; i < this.userData.length; i++) {
      if (this.userData[i].overtimegroupname === otGroupName) {
        if (this.userData[i].isselected === true) {
          this.userData[i].isselected = false;
        } else {
          this.userData[i].isselected = true;
        }
      }
    }
    //console.log('param 2', this.userData);
  }

  // validateOvertimeGroup() {
  //   this.isOvertimeGroupNameChecked = false;
  //   if (this.checkAllRecords) {  //if not checked
  //     this.isOvertimeGroupNameChecked = true;
  //   }
  //   else {
  //     this.isOvertimeGroupNameChecked = false;
  //   }
  // }
}
