import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { ToolAndDiePlantService } from 'src/app/Services/Applications/ToolAndDie/tool-and-die-plant.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';
import { UserRoles } from 'src/enum/userroles';
import { userData } from 'src/models/userdata';
import { UserInfo } from 'src/models/userinfo';

@Component({
  selector: 'app-tool-and-die-plant',
  templateUrl: './tool-and-die-plant.component.html',
  styleUrls: ['./tool-and-die-plant.component.css'],
})
export class ToolAndDiePlantComponent implements OnInit {
  public loading: boolean = false;
  emloyeeData: any = [];
  userData: any = [];
  modalPopTitle = {
    title: '',
    type: '',
  };
  employeeForm!: FormGroup;
  selectedEmp: any;
  isEditButton: boolean = false;
  isAddButton: boolean = false;
  isEdit: boolean = false;
  isCancel: boolean = false;
  modalTitle: any = '';
  isAdd: boolean = false;
  AppType: string = '150TD';
  modalId: string | any;
  typeSelected: any;
  screenType: any = [];
  seniordateforOpen: any = [];
  isApplication: boolean = false;
  searchKey: string = '';
  isDeleted: boolean = false;
  showFilter: boolean = true;
  applicationData: any = [];
  isApplicationDataExists: boolean = true;
  userInformation!: UserInfo; // Model for UserInfo
  usersData!: userData; // Model for UAW user details
  isUserDataExists: boolean = true;
  disablededit = false;
  disabledcancel = false;
  disableddelete = false;
  facilityServicePlantClassification: any[] = [
    '153J',
    '153L',
    '180J',
    '223J',
    '226J',
    '387J',
    '411J',
    '429J',
    '447J',
    '447L',
    '493J',
    '493L',
    '509J',
    '541J',
    '581J',
  ];
  userRole: UserRoles = UserRoles.SKILLEDUSER; // Enum for userrole
  returnstatus!: boolean; // stores cancelled status if any
  returnOpenStatus!: boolean; // stores open status if any
  TotalUserCount!: number;
  Userpage: number = 1;
  isLoggedInUserAdmin: boolean = false;
  TotalApplicationCount: any;
  Applicationpage: number = 1;
  filterText: string = 'normal';
  filterConfig: any = [
    {
      label: 'Employee Id',
      type: 'input',
      data: [],
      placeholder: 'Employee Id',
      default: 'All',
      controlName: 'EmployeeId',
      width: '150px',
      runClassification: false,
    },
    {
      label: 'Last Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'lastName',
      width: '150px',
      runClassification: false,
    },
    {
      label: 'First Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'firstName',
      width: '150px',
      runClassification: false,
    },
    {
      label: 'Classification',
      type: 'dropdown',
      data: ['option 1', 'option 2'],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'jobCodeId',
      width: '200px',
      runClassification: false,
    },
    {
      label: 'Status',
      type: 'dropdown',
      data: ['option 1', 'option 2'],
      default: 'All',
      controlName: 'appStatusCode',
      width: '150px',
      runClassification: false,
    },
    {
      label: 'Plant',
      type: 'dropdown',
      data: [],
      default: 'All',
      controlName: 'plantId',
      width: '190px',
      runClassification: false,
    },
    {
      label: 'Department',
      type: 'dropdown',
      data: ['option 1', 'option 2'],
      default: 'All',
      controlName: 'departmentId',
      width: '150px',
      runClassification: false,
    },
    {
      label: 'Shift',
      type: 'dropdown',
      data: ['Day', 'Night'],
      default: 'All',
      controlName: 'shiftId',
      width: '150px',
      runClassification: false,
    },
    {
      label: 'From',
      type: 'date',
      data: ['option 1', 'option 2'],
      default: 'All',
      controlName: 'fromDate',
      width: '145px',
      runClassification: false,
    },
    {
      label: 'To',
      type: 'date',
      data: ['option 1', 'option 2'],
      default: 'All',
      controlName: 'toDate',
      width: '145px',
      runClassification: false,
    },
  ];
  filterConfigAdd: any = [
    {
      label: 'Last Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'lastName',
      width: '120px',
    },
    {
      label: 'First Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'firstName',
      width: '120px',
    },
    {
      label: 'Employee Id',
      type: 'input',
      data: [],
      placeholder: 'Employee Id',
      default: 'All',
      controlName: 'EmployeeId',
      width: '120px',
    },
  ];
  filterData: any = {};
  filterDataUser: any = {};
  EditButtonDisabled!: boolean;
  CancelButtonDisabled!: boolean;
  EditButtonDisabled1!: boolean;
  CancelButtonDisabled1!: boolean;
  DeleteButtonDisabled1!: boolean;
  views: any[] = [];
  isAppFSPT: boolean = false;

  constructor(
    private fb: FormBuilder,
    private td: ToolAndDiePlantService,
    private sweetAlert: SweetAlertService,
    public _userInfo: UsergroupService,
    private _commonApplicationService: CommonService
  ) {}

  ngOnInit(): void {
    this.screenType = [
      { Id: 1, Name: 'Application' },
      { Id: 2, Name: 'User' },
    ];
    this.typeSelected = 1;
    this.getShifts();
    this.getPlantsTD();
    this.getStatus();
    this.getClassifications();
    this.getDepartments();
    this.getUserRoleDescreption();
    this.isAdmin();
    this.EditButtonDisabled1 = true;
    this.CancelButtonDisabled1 = true;
    this.CancelButtonDisabled = true;
    this.DeleteButtonDisabled1 = true;
  }

  //apis
  //Function to get logged in user's role and details
  getUserRoleDescreption() {
    this._userInfo.getUserInfo().subscribe((data: any) => {
      // this.userRole = data["groupDescription"] === UserRoles.HRPLUS ? UserRoles.HRPLUS : UserRoles.SKILLEDUSER;
      if (
        data['groupDescription'] === UserRoles.HRPLUS ||
        data['groupDescription'] === UserRoles.COMMITTEE
      ) {
        this.userRole = UserRoles.HRPLUS;
      } else {
        this.userRole = UserRoles.SKILLEDUSER;
      }
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'APPLICATIONS',
        'TOOLSANDDIEMAKER'
      );
      this.td.atid.next(this.userInformation.userName);
      if (this.userRole === UserRoles.SKILLEDUSER) {
        this.isAppFSPT = this.facilityServicePlantClassification.includes(
          this.userInformation.jobCode
        );
        this.searchApplication();
        this.td
          .searchFilterApplicationUser({
            employeeId: this.userInformation.employeeId,
          })
          .subscribe((res) => {
            this.userData = res;
            this.usersData = res[0];
            if (this.userData != undefined && this.userData.length > 0) {
              this.selectedEmp = this.userData[0];
              this.selectedEmp.selected = true;
            }
          });
      }
    });
  }

  // crudPermissions(module : any){
  //   for(var i =0;i<module.length;i++){
  //     if(module[i].moduelCode === 'APPLICATIONS'){
  //        for(var j =0;j<module[i].views.length;j++){
  //          if(module[i].views[j].viewCode === "TOOLSANDDIEMAKER"){
  //             this.views.push(module[i].views[j]);
  //           }
  //        }
  //      }
  //   }
  // }

  //Checks if logged in user is Admin
  isAdmin(): boolean {
    if (this.userRole === UserRoles.HRPLUS) {
      this.isLoggedInUserAdmin = true;
    } else {
      this.isLoggedInUserAdmin = false;
    }
    return this.userRole === UserRoles.HRPLUS;
  }
  getClassifications() {
    const elements: any = [
      {
        Id: '512J',
        Description: 'Tool & Die Maker Leader',
      },
      {
        Id: '512L',
        Description: 'Tool & Die Maker',
      },
      {
        Id: '512T',
        Description: 'Tool & Die Maker',
      },
    ];
    const mapElement = elements.map(
      (element: { Id: string; Description: string }) => ({
        id: element.Id,
        description: element.Description,
      })
    );
    for (let index = 0; index < this.filterConfig.length; index++) {
      if (this.filterConfig[index].label === 'Classification') {
        this.filterConfig[index].data = mapElement;
      }
    }
  }
  getDepartments() {
    this._commonApplicationService
      .GetDepartment(this.AppType)
      .subscribe((res) => {
        const elements = res || [];
        const mapElement = elements.map(
          (element: { departmentId: any; departmentDescription: string }) => ({
            id: element.departmentId,
            description: element.departmentDescription,
          })
        );
        for (let index = 0; index < this.filterConfig.length; index++) {
          if (this.filterConfig[index].label === 'Department') {
            this.filterConfig[index].data = mapElement;
          }
        }
      });
  }

  getStatus() {
    const elements: any = [
      {
        statusId: 'OPEN',
        statusDescription: 'OPEN',
      },
      {
        statusId: 'CLOSED',
        statusDescription: 'CLOSED',
      },
      {
        statusId: 'CANCELLED',
        statusDescription: 'CANCELLED',
      },
    ];
    const mapElement = elements.map(
      (element: { statusId: string; statusDescription: string }) => ({
        id: element.statusId,
        description: element.statusDescription,
      })
    );
    for (let index = 0; index < this.filterConfig.length; index++) {
      if (this.filterConfig[index].label === 'Status') {
        this.filterConfig[index].data = mapElement;
      }
    }
  }

  getShifts() {
    this.td.getShiftsList().subscribe((res) => {
      const elements = res || [];
      const mapElement = elements.map(
        (element: { shiftId: any; shiftDescription: any }) => ({
          id: element.shiftId,
          description: element.shiftDescription,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Shift') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  getPlantsTD() {
    this._commonApplicationService.GetPlants(this.AppType).subscribe((res) => {
      const elements = res || [];
      const mapElement = elements.map(
        (element: { plantId: any; plantDescription: any }) => ({
          id: element.plantId,
          description: element.plantDescription,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Plant') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  //For application - HRPLUS
  selectEmp(idx: number) {
    // this.disablededit = false;
    //this.disabledcancel = false;
    //this.disableddelete = false;
    this.emloyeeData[idx].selected = true;
    for (let index = 0; index < this.emloyeeData.length; index++) {
      this.emloyeeData[index].selected = false;
    }
    this.emloyeeData[idx].selected = true;
    this.selectedEmp = this.emloyeeData[idx];

    //checking if the appstatus of the selected record is cancelled

    if (this.selectedEmp['appStatusCode'].includes('CLOSED')) {
      this.CancelButtonDisabled = true;
      this.disablededit = false;
      this.disableddelete = false;
    } else if (this.selectedEmp['appStatusCode'].includes('CANCELLED')) {
      this.CancelButtonDisabled = true;
      this.EditButtonDisabled = true;
      this.disableddelete = false;
    } else {
      this.disablededit = false;
      this.disabledcancel = false;
      this.disableddelete = false;
    }
  }

  //For User - UAW
  selectUser(idx: number) {
    this.userData[idx].selected = true;
    for (let index = 0; index < this.userData.length; index++) {
      this.userData[index].selected = false;
    }
    this.userData[idx].selected = true;
    this.selectedEmp = this.userData[idx];
  }
  //Function searches users
  SearchByUser(e: any) {
    if (e && e.target && e.target.value) {
      this.searchKey = (e && e.target && e.target.value) || '';
    } else {
      this.searchKey = e;
    }
    if (this.isApplication) {
      this.td.SearchUser(this.searchKey).subscribe((res) => {
        this.userData = res;

        this.isAddButton = true;
        if (this.userData.length === 0) {
          this.isAddButton = false;
          this.sweetAlert.simpleAlert(
            'error',
            'Oops',
            'No result found for this search.'
          );
        }
      });
    } else {
      this.td.SearchApplicationByUser(this.searchKey).subscribe((res) => {
        this.emloyeeData = res;
        //this.recordStatus = res[]
        this.isEditButton = true;

        if (this.emloyeeData.length === 0) {
          this.isEditButton = false;
          this.sweetAlert.simpleAlert(
            'error',
            'Oops',
            'No result found for this search.'
          );
        }
      });
    }
  }
  // Function to add employee - HRPLUS
  addEmployee(idx: number) {
    this.selectUser(idx);
    if (this.selectedEmp) {
      this.isAdd = true;
      this.modalId = '#addEmployee';
    } else {
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }
  //Resets the form
  clear() {
    this.employeeForm.reset();
  }
  //Function to correct the application - HRPLUS
  edit() {
    if (this.selectedEmp) {
      this.modalTitle = 'Edit Employee';
      this.isEdit = true;
      this.modalId = '#editEmp';
    } else {
      this.modalId = '';
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'Please select employee to edit'
      );
    }
  }

  //Function to delete the application - HRPLUS
  deleteApplication() {
    if (this.selectedEmp) {
      this.modalId = '#deleteApplicationModal';
    } else {
      this.modalId = '';
      alert('No Employee Selected');
    }
  }

  //Resets the form after deletion of application - HRPLUS
  CheckDelete(e: any) {
    this.isDeleted = e;
    if (this.isDeleted === true && this.searchKey) {
      this.emloyeeData = this.SearchByUser(this.searchKey);
    } else {
      this.search();
    }
  }
  //Resets the form after correction of application - HRPLUS
  CheckEdit(e: any) {
    this.isEdit = e;
    if (this.isEdit === true && this.searchKey) {
      this.emloyeeData = this.SearchByUser(this.searchKey);
    } else {
      this.search();
    }
  }
  //Resets the form after cancellation of application - HRPLUS
  CheckCancel(e: any) {
    this.isCancel = e;
    if (this.isCancel === true && this.searchKey) {
      this.emloyeeData = this.SearchByUser(this.searchKey);
    } else {
      this.search();
    }
  }
  //Function to cancel and application application - HRPLUS
  cancelApplication() {
    if (this.selectedEmp) {
      if (this.selectedEmp && this.selectedEmp.appId) {
        this.td.appId.next(this.selectedEmp.appId);
        this.td.empsignDate.next(this.selectedEmp.empSignDate);
      } else {
        this.sweetAlert.simpleAlert('errror', 'App Id MIssing', '');
      }
      this.modalId = '#cancelApplicationModal';
    } else {
      this.modalId = '';
      alert('No Employee Selected');
    }
  }
  //Search-By grid - HRPLUS
  onAppTypeSelected(val: number) {
    if (val === 2) {
      this.isApplication = true;
      this.showFilter = false;
      this.emloyeeData = null;
      this.EditButtonDisabled1 = true;
      this.DeleteButtonDisabled1 = true;
      this.CancelButtonDisabled1 = true;
      this.userData = null;
      this.clearFilterData(this.filterData);
    }
    if (val === 1) {
      this.isApplication = !this.isApplication;
      this.showFilter = true;
      this.userData = null;
      if (this.isApplicationDataExists === false) {
        this.isApplicationDataExists = true;
      }
    }
  }

  clearFilterData(filterData: any) {
    Object.keys(filterData).forEach((k) => delete filterData[k]);
  }
  //Function to check if  application is selected - HRPLUS
  isApplicationSelected() {
    return $('input[name=application]:checked').length > 0;
  }

  cleanEmp(event: any) {}

  //Filter - HRPLUS
  showFilters() {
    if (this.showFilter) {
      this.showFilter = false;
      this.filterText = 'normal';
    } else {
      this.showFilter = true;
      this.filterText = 'bold';
    }
  }
  //Filter - HRPLUS
  getFilterValue(event: any) {
    this.filterData = event;
  }
  //Filter - HRPLUS
  getFilterDataUser(event: any) {
    this.filterDataUser = event;
  }
  //Search by Application - HRPLUS/UAW
  search() {
    if (this.userRole === UserRoles.SKILLEDUSER) {
      this.searchApplication();
      return;
    }
    this.loading = true;
    let queryParams = this.filterData;
    Object.keys(queryParams).forEach(
      (k) =>
        !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]
    );
    if (queryParams.departmentId === undefined) {
      queryParams.departmentId = 'All';
    }
    queryParams.AppType = '150';
    this.td.searchFilterApplication(queryParams).subscribe((data) => {
      this.emloyeeData = data;

      for (var i = 0; i < this.emloyeeData.length; i++) {
        if (this.emloyeeData[i].empSignDate != null) {
          this.emloyeeData[i].empSignDateSort = new Date(
            this.emloyeeData[i].empSignDate
              .replace(/-/g, '/')
              .replace(/T.+/, '')
          ).toISOString();
        }
        if (this.emloyeeData[i].seniorityDate != null) {
          this.emloyeeData[i].seniorityDateSort = new Date(
            this.emloyeeData[i].seniorityDate
              .replace(/-/g, '/')
              .replace(/T.+/, '')
          ).toISOString();
        }
      }
      var appstatus = data.map((t: { appStatusCode: any }) => t.appStatusCode);

      for (let index = 0; index < appstatus.length; index++) {
        // var res = appstatus[index].substring(0, 4)
        // var res2 = appstatus[index].substring(0, 6)
        //    if (res==="OPEN" ){
        if (appstatus[index].includes('OPEN')) {
          this.seniordateforOpen = true;
        } else {
          this.seniordateforOpen = false;
        }
      }
      this.isApplicationDataExists = this.emloyeeData.length > 0 ? true : false;
      this.EditButtonDisabled1 = false;
      this.DeleteButtonDisabled1 = false;
      this.CancelButtonDisabled1 = false;
      this.disablededit = true;
      this.disabledcancel = true;
      this.disableddelete = true;
      //this.CancelButtonDisabled = true;
      this.Applicationpage = 1;
      this.TotalApplicationCount = data.length;
      this.loading = false;
    });
  }
  searchbyUser() {
    this.loading = true;
    let queryParams = this.filterDataUser;
    Object.keys(queryParams).forEach(
      (k) =>
        !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]
    );
    queryParams.departmentId = 'All';
    queryParams.AppType = '150';
    this.td.searchFilterApplicationUser(queryParams).subscribe((data) => {
      this.userData = data;
      this.isUserDataExists = this.userData.length > 0 ? true : false;
      this.Userpage = 1;
      this.loading = false;
    });
  }

  //Function search for UAW user's applications
  searchApplication() {
    this.loading = true;
    let queryParams = {
      employeeId: this.userInformation.employeeId,
      appStatusCode: '',
    };
    this.td.searchFilterApplication(queryParams).subscribe((data) => {
      this.applicationData = data;
      this.loading = false;
      if (this.applicationData.length > 0) {
        this.applicationData[0].selected = true;
        this.selectedEmp = this.applicationData[0];
      }
      //checking if the appstatus of the selected record is CANCELLED
      if (
        this.selectedEmp != null &&
        this.selectedEmp['appStatusCode']?.includes('CANCELLED')
      ) {
        this.returnstatus = true;
        this.returnOpenStatus = false;
      }
      //checking if the appstatus of the selected record is OPEN
      else if (
        this.selectedEmp !== null &&
        this.selectedEmp['appStatusCode']?.includes('OPEN')
      ) {
        this.returnOpenStatus = true;
        this.returnstatus = false;
      } else {
        this.returnOpenStatus = false;
        this.returnstatus = false;
      }
    });
  }

  //Resets the list of user applications
  CheckAdd(e: any) {
    this.isAdd = e;
    if (this.isAdd === false) {
      this.applicationData = this.searchApplication();
    } else {
      this.searchApplication();
    }
  }

  // UAW user Add
  addApplication() {
    if (this.userInformation) {
      this.isAdd = true;
      this.modalId = '#addEmployee';
    } else {
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }
  getbyenter(e: any) {
    this.loading = true;
    e.departmentId = 'All';
    e.AppType = '150';
    let searchData;
    if (this.filterData != null) {
      if (this.filterData.departmentId === '') {
        this.filterData.departmentId = 'All';
      }
      searchData = { ...e, ...this.filterData };
    }
    this.td.searchFilterApplication(searchData).subscribe((data) => {
      this.emloyeeData = data;

      for (var i = 0; i < this.emloyeeData.length; i++) {
        if (this.emloyeeData[i].empSignDate != null) {
          this.emloyeeData[i].empSignDateSort = new Date(
            this.emloyeeData[i].empSignDate
              .replace(/-/g, '/')
              .replace(/T.+/, '')
          ).toISOString();
        }
        if (this.emloyeeData[i].seniorityDate != null) {
          this.emloyeeData[i].seniorityDateSort = new Date(
            this.emloyeeData[i].seniorityDate
              .replace(/-/g, '/')
              .replace(/T.+/, '')
          ).toISOString();
        }
      }

      this.isApplicationDataExists = this.emloyeeData.length > 0 ? true : false;
      this.EditButtonDisabled1 = false;
      this.DeleteButtonDisabled1 = false;
      this.disablededit = true;
      this.disabledcancel = true;
      this.disableddelete = true;
      this.CancelButtonDisabled1 = false;
      this.Applicationpage = 1;
      this.TotalApplicationCount = data.length;
      this.loading = false;
    });
  }

  getbyenterforuser(e: any) {
    this.loading = true;
    e.departmentId = 'All';
    e.AppType = '150';
    this.td.searchFilterApplicationUser(e).subscribe((data) => {
      this.userData = data;
      this.isUserDataExists = this.userData.length > 0 ? true : false;
      this.Userpage = 1;
      this.loading = false;
    });
  }

  @ViewChild('ToolAndDieExportTable', { read: ElementRef, static: false })
  ToolAndDieExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.ToolAndDieExportTable,
        `${ApplicationEnum.TOOLANDDIE}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.emloyeeData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.emloyeeData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
