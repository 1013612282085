import { HttpErrorResponse } from '@angular/common/http';
import {
  ElementRef,
  EventEmitter,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { ShiftPreferenceService } from 'src/app/Services/Applications/ShiftPreference/shift-preference.service';
import { userData } from 'src/models/userdata';
import { RuleEngineService } from 'src/app/Services/Ruleengine/rule-engine.service';
import { DateService } from 'src/app/Services/Date/date.service';

@Component({
  selector: 'app-add-shift-preference',
  templateUrl: './add-shift-preference.component.html',
  styleUrls: ['./add-shift-preference.component.css'],
})
export class AddShiftPreferenceComponent implements OnInit, OnChanges {
  employeeForm!: FormGroup;
  empSelected: any;
  empInformation: any;
  shifts: any[] = [
    {
      data: [],
      default: 'Select any shift',
    },
  ];

  ShiftRequestType: any[] = [
    {
      data: [],
      default: 'Choose One',
    },
  ];
  areas: any[] = [
    {
      data: [],
      default: 'Select an area',
    },
  ];

  selectedPlantId: any;
  atid: any;
  employeeId: any;
  currentDate = new Date();

  @Input() selectedEmp: any;
  @Input() isAdd: boolean = false;
  @Input() title: any;
  @Input() isLoggedInUserAdmin = false;
  @Input() AdminAtid = '';
  @Output() clean = new EventEmitter();
  @Output() isSucess = new EventEmitter();
  @Input() AppType: string = '';
  @ViewChild('closeAddModel') closeAddModal!: ElementRef;

  ClassificationList: any;
  EnteredBy: string = '';
  CalenderBlockedAfterTodayDate: string = '';
  EffectiveDate: string = '';

  ShiftAppliyingFor: string = '';
  ShiftTypeApplyingFor: string = '';
  CurrentShift: any;

  ValidateSpecialMessage: boolean = false;
  SpecialOverride: boolean = false;
  PlantSeniorityValidate: boolean = false;
  AreaField: boolean = false;
  controlEnabled: boolean = true;

  StatusCode: boolean = true;
  ruleMessages!: any[];
  medicalRestrictionList: string[] = [];
  errorlength: number = 0;

  usersData!: userData;
  JobcodeValidationObj: Array<any> = [
    {
      Obj: 1,
      JOBCODE: [
        {
          ID: 1,
          JOBCODEID: '223J',
        },
        {
          ID: 2,
          JOBCODEID: '429J',
        },
        {
          ID: 3,
          JOBCODEID: '581J',
        },
      ],
    },
    {
      Obj: 2,
      JOBCODE: [
        {
          ID: 1,
          JOBCODEID: '447J',
        },
        {
          ID: 2,
          JOBCODEID: '447L',
        },
      ],
    },
  ];

  constructor(
    private fb: FormBuilder,
    private _ShiftPreferenceService: ShiftPreferenceService,
    private _sweetAlertService: SweetAlertService,
    private _commonApplicationService: CommonService,
    private _dateService: DateService,
    private _ruleEngineService: RuleEngineService,
    private _datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getShifts();
    this.getAreas();
    this.getShiftRequestTypes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createEmpForm();
    this.loadEmployeeData(changes);
    this.onChangeShift();
  }

  loadEmployeeData(changes: SimpleChanges) {
    this.empSelected =
      (changes.selectedEmp && changes.selectedEmp.currentValue) || '';
    if (this.empSelected === '') {
      this.empSelected =
        (changes.selectedEmp && changes.selectedEmp.previousValue) || '';
    }
    this.CalenderBlockedAfterTodayDate =
      this._commonApplicationService.formatDate(this.currentDate);
    this.employeeForm
      .get('fullname')
      ?.patchValue(
        this.empSelected.lastName +
          ', ' +
          this.empSelected.firstName +
          ' ' +
          this.empSelected.middleName
      );
    this.employeeForm
      .get('classification')
      ?.patchValue(
        this.empSelected.jobCodeId + '-' + this.empSelected.jobCodeDescription
      );
    this.employeeForm
      .get('currentPlant')
      ?.patchValue(
        this.empSelected.plantId + '-' + this.empSelected.plantDescription
      );
    this.employeeForm
      .get('currentShift')
      ?.patchValue(this.empSelected.shiftDescription);
    this.employeeForm.get('currentDept')?.patchValue(this.empSelected.deptId);
    this.employeeForm
      .get('currentShiftID')
      ?.patchValue(this.empSelected.shiftId);
    this.employeeForm
      .get('empSignDate')
      ?.patchValue(this._commonApplicationService.formatDate(new Date()));
    this.atid = this.employeeForm
      .get('ATID')
      ?.patchValue(this.empSelected.atid);
    this.employeeId = this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.empSelected.employeeId);
    this.CurrentShift = this.empSelected.shiftId;
    this.SpecialOverride = false;
    this.ValidateSpecialMessage = false;
    this.AreaField = false;
    this.getRuleEngineValidations(this.empSelected.employeeId);
    this.GetMedicalRestrictions(this.empSelected.employeeId);
    this.onCheckCurrentJobCodeId(
      this._commonApplicationService.stringToUpperCase(
        this.empSelected.jobCodeId
      )
    );
    this.patchDate();
    this.checkUserRole();
    this.employeeForm.get('EnteredBy')?.patchValue(this.EnteredBy);
  }

  checkUserRole() {
    if (this.isLoggedInUserAdmin) {
      this.EnteredBy = this.AdminAtid;
    } else {
      this.EnteredBy = 'USER';
    }
    return this.EnteredBy;
  }

  patchDate() {
    if (this.isLoggedInUserAdmin === false) {
      this.employeeForm
        .get('empSignDate')
        ?.patchValue(formatDate(new Date(), 'yyyy-MM-ddTHH:mm', 'en-US'));
    }
  }

  getRuleEngineValidations(employeeId: string) {
    this._ruleEngineService
      .addShiftPreferenceApplicationRule(employeeId)
      .subscribe((data: any) => {
        this.StatusCode = data['status']; //for error: false
        this.ruleMessages = data['errors'];
        this.errorlength = data['errors'].length;
      });
  }

  DistinctiveAreaField: any = [];
  DistinctiveAreaFieldAccess: boolean = false;

  onCheckCurrentJobCodeId(value: string) {
    if (value != null && value != '') {
      if (
        value.includes('223J') ||
        value.includes('429J') ||
        value.includes('581J')
      ) {
        this.AreaField = true;
        this.DistinctiveAreaFieldAccess = false;
      } else if (value.includes('447J') || value.includes('447L')) {
        this.AreaField = false;
        this.DistinctiveAreaFieldAccess = true;
      } else {
        this.AreaField = false;
        this.DistinctiveAreaFieldAccess = false;
        this.employeeForm.get('AppAreaId')?.patchValue('0');
      }
    }
    return this.AreaField;
  }

  createEmpForm() {
    this.employeeForm = this.fb.group({
      fullname: [''],
      classification: [''],
      currentPlant: [''],
      currentShift: [''],
      currentShiftID: [''],
      currentDept: [''],
      ATID: [this.atid],
      EmployeeId: [this.employeeId],
      AppNo: ['0'],
      AppStatusCode: ['OPEN'],
      T09OverrideFlag: [null],
      ExpValidateFlag: [null],
      T04OverrideFlag: [null, []],
      ClassOverrideFlag: [null, []],
      SeniorityOverrideFlag: [false],
      Comments: [null, []],
      SpecialSP: ['', [Validators.required]],
      App150PlantGroupId: ['0'],
      AppType: ['SP'],
      DateEntered: [this.currentDate],
      EnteredBy: [''],
      appStatusDate: [this.currentDate],
      empSignDate: ['', [Validators.required]],
      shiftId: ['', [Validators.required]],
      StatusOverrideFlag: [null, []],
      AppAreaId: ['', [Validators.required]],
      SpecialOverride: [false],
    });
    this.isAdd = false;
  }

  clear() {
    this.clean.emit('');
    this.closeAddModal.nativeElement.click();
    this.emitResponse();
  }

  emitResponse() {
    this.isSucess.emit(true);
  }

  //Method to add an employee
  addEmployee() {
    const currentShiftID: string =
      this.employeeForm.controls['currentShiftID'].value;
    const shiftIdAppliedForId: string =
      this.employeeForm.controls['shiftId'].value;
    const specialspCodeAppliedForId: string =
      this.employeeForm.controls['SpecialSP'].value;
    const speicalOverirideCheck: boolean =
      this.employeeForm.controls['SpecialOverride'].value;
    const EmpSignDate: string = this.employeeForm.controls['empSignDate'].value;
    var effectiveDate = this._datePipe.transform(
      this._commonApplicationService.formatDate(
        this._dateService.calculateEffectiveDate(
          EmpSignDate,
          specialspCodeAppliedForId.toString()
        )
      ),
      'MM/dd/yyyy'
    );
    var conditionReturn;
    specialspCodeAppliedForId.toString() === '0'
      ? (conditionReturn = false)
      : (conditionReturn = true);

    if (
      (conditionReturn &&
        !this.isLoggedInUserAdmin &&
        confirm(
          'You are about to add this application and you will not be able to cancel it.  Are you sure that you wish to continue?'
        )) ||
      (!conditionReturn &&
        !this.isLoggedInUserAdmin &&
        confirm(
          `You are about to add this application with an effective date of ${effectiveDate}.  You will not be able to cancel it.  Are you sure that you wish to continue?`
        )) ||
      this.isLoggedInUserAdmin
    ) {
      this._ShiftPreferenceService
        .ShiftPreferenceSPAdd(this.employeeForm.value)
        .subscribe(
          (data: any) => {
            this.clear();
            this.isSucess.emit(true);
            this._sweetAlertService.successAlert(
              'center',
              'success',
              'The Application has been Added Successfully.',
              false,
              1500
            );
          },
          (_error: HttpErrorResponse) => {
            if (_error.status === 406) {
              if (
                currentShiftID === shiftIdAppliedForId &&
                specialspCodeAppliedForId != '1'
              ) {
                this._sweetAlertService.validationError(
                  'error',
                  'Cannot create application',
                  'You cannot apply for your current shift unless Shift Request Type is Special.'
                );
              } else if (
                currentShiftID !== shiftIdAppliedForId &&
                specialspCodeAppliedForId === '1' &&
                speicalOverirideCheck === false
              ) {
                this._sweetAlertService.validationError(
                  'error',
                  'Cannot create application',
                  'You must apply for your current shift if Shift Request Type is Special or apply the override..'
                );
              } else {
                specialspCodeAppliedForId.toString() === '1'
                  ? this._sweetAlertService.validationError(
                      'error',
                      'Cannot create application',
                      'The employee already has a open Special Shift Preference application.'
                    )
                  : this._sweetAlertService.validationError(
                      'error',
                      'Cannot create application',
                      'The employee already has a open Regular Shift Preference application.'
                    );
              }
            } else if (_error.status === 400) {
              this._sweetAlertService.validationError(
                'error',
                'Cannot Correct application',
                'You must provide an override due to less than 90 days plant seniority.'
              );
              this.PlantSeniorityValidate = true;
            } else {
              this._sweetAlertService.InternalServerError500(
                'error',
                'ERROR',
                'An Error occured while processing.Please try again.'
              );
            }
          }
        );
    }
  }

  getAreas() {
    const areaObject = {
      areaId: 0,
      areaDescription: 'Any',
    };
    this._commonApplicationService.getAreasList().subscribe((data: any) => {
      this.areas[this.areas.length - 1].data = data;
      this.areas[this.areas.length - 1].data.splice(0, 2);
      this.areas[this.areas.length - 1].data.push(areaObject);
      this.DistinctiveAreaField.length = 0;
      var a = JSON.stringify(this.areas[0].data);
      this.DistinctiveAreaField = JSON.parse(a);
      let index = this.DistinctiveAreaField.map((o: any) => o.areaId).indexOf(
        1
      );
      this.DistinctiveAreaField.splice(index, 1);
    });
  }

  GetMedicalRestrictions(employeeId: string) {
    this._ruleEngineService
      .getMedicalRestrictions(employeeId)
      .subscribe((data) => {
        this.medicalRestrictionList = data;
      });
  }
  //method to get shifts
  getShifts() {
    this._commonApplicationService.getShiftsList().subscribe((data: any) => {
      this.shifts[0].data = data;
      let index = this.shifts[0].data.map((o: any) => o.shiftId).indexOf('A');
      this.shifts[0].data.splice(index, 1);
    });
  }

  getShiftRequestTypes() {
    this._ShiftPreferenceService
      .ShiftRequestType()
      .subscribe((shiftRequestTypes) => {
        this.ShiftRequestType[0].data = shiftRequestTypes;
      });
  }

  onChangeShift() {
    this.employeeForm.get('shiftId')?.valueChanges.subscribe((shiftIdValue) => {
      if (shiftIdValue === this.CurrentShift) {
        this.employeeForm.get('SpecialSP')?.patchValue(1);
      } else {
        this.employeeForm.get('SpecialSP')?.patchValue(0);
      }
    });
  }
}
