<div class="modal fade" id="addEmployee" tabindex="-1" role="dialog" aria-labelledby="addEmployeeLabel"
    aria-hidden="true">
    <div class="modal-dialog add-form-alignment" role="document">
        <div class="modal-content modal-size" style="position: fixed; width: 945px">
            <div class="modal-header">
                <h5 class="modal-title" id="addEmployeeLabel">Add Application</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body" style="padding-left: 80px; padding-right: 80px">
                <form [formGroup]="employeeForm">

                    <div class="text-danger" *ngIf="errorlength > 0">
                        <h6 *ngFor="let error of ruleMessages"> *{{error}} </h6>
                    </div>
                    <br>
                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Full Name:</label>
                        <div class="col-sm-8">
                            <input readonly type="text" formControlName="fullname" placeholder="First Name"
                                class="form-control">

                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Current Plant:</label>
                        <div class="col-sm-8">
                            <input readonly formControlName="currentPlant" type="text" class="form-control">

                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Current Department:</label>
                        <div class="col-sm-8">
                            <input readonly formControlName="currentDept" type="text" class="form-control">

                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Current Shift:</label>
                        <div class="col-sm-8">
                            <input readonly formControlName="currentShift" type="text" class="form-control">

                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Current Classification:</label>
                        <div class="col-sm-8">
                            <input readonly formControlName="classification" type="text" class="form-control">

                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Shift Request *:</label>
                        <div class="col-sm-8">
                            <select (change)="onChangeShift()" formControlName="shiftId" class="form-control">
                                <option disabled *ngIf="shifts[0].default" selected value="">{{shifts[0].default}}
                                </option>
                                <option *ngFor="let shift of shifts[0].data" value={{shift.shiftId}}>
                                    {{shift.shiftDescription}}
                                </option>
                            </select>
                            <div
                                *ngIf="employeeForm.controls['shiftId'].invalid && employeeForm.controls['shiftId'].touched">
                                <small class="text-danger" *ngIf="employeeForm.controls['shiftId'].errors?.required">
                                    Shift is required.
                                </small>
                            </div>

                        </div>
                    </div>


                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Shift Request Type *:</label>
                        <div class="col-sm-8">
                            <select [attr.disabled]="controlEnabled" formControlName="SpecialSP" class="form-control">
                                <option disabled *ngIf="ShiftRequestType[0].default" selected value="">
                                    {{ShiftRequestType[0].default}}
                                </option>
                                <option *ngFor="let specialSp of ShiftRequestType[0].data"
                                    value={{specialSp.shiftRequestTypeId}}>
                                    {{specialSp.shiftRequestTypeName}}
                                </option>

                            </select>
                            <div
                                *ngIf="employeeForm.controls['shiftId'].invalid && employeeForm.controls['shiftId'].touched">
                                <small class="text-danger" *ngIf="employeeForm.controls['shiftId'].errors?.required">
                                    Shift Request is required.
                                </small>
                            </div>

                        </div>
                    </div>

                    <!-- Area Field for 223J,429J,581J -->
                    <div *ngIf ="AreaField" class="form-group row">
                        <label class="col-sm-4 col-form-label">Area *: </label>
                        <div class="col-sm-8">
                            <select formControlName="AppAreaId" class="form-control" id="exampleFormControlSelect2">
                                <option *ngIf="areas[areas.length-1].default" selected value="">{{areas[areas.length-1].default}}
                                </option>
                                <option *ngFor="let area of areas[areas.length-1].data" value={{area.areaId}}>
                                    {{area.areaDescription}}
                                </option>
                            </select>
                            <div
                                *ngIf="employeeForm.controls['AppAreaId'].invalid && employeeForm.controls['AppAreaId'].touched">
                                <small class="text-danger" *ngIf="employeeForm.controls['AppAreaId'].errors?.required">
                                    Area is required.
                                </small>
                            </div>

                        </div>
                    </div>

                    <!-- Area Field for 447J,447L -->
                    <div *ngIf ="DistinctiveAreaFieldAccess" class="form-group row">
                        <label class="col-sm-4 col-form-label">Area *:</label>
                        <div class="col-sm-8">
                            <select formControlName="AppAreaId" class="form-control" id="exampleFormControlSelect2">
                                <option selected value="">Select an area
                                </option>
                                <option *ngFor="let area of DistinctiveAreaField" value={{area.areaId}}>
                                    {{area.areaDescription}}
                                </option>
                            </select>
                            <div
                                *ngIf="employeeForm.controls['AppAreaId'].invalid && employeeForm.controls['AppAreaId'].touched">
                                <small class="text-danger" *ngIf="employeeForm.controls['AppAreaId'].errors?.required">
                                    Area is required.
                                </small>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="isLoggedInUserAdmin" class="form-group row">
                        <label for="datmax" class="col-sm-4 col-form-label">Employee Sign Date *:</label>
                        <div class="col-sm-8">
                            <input formControlName="empSignDate" id="datmax" name="datmax" type="date"
                                class="form-control" max={{CalenderBlockedAfterTodayDate}}>
                        </div>

                    </div>

                    <div *ngIf="!isLoggedInUserAdmin" class="form-group row">
                        <label class="col-sm-4 col-form-label">Employee Sign Date:</label>
                        <div class="col-sm-8">
                            <input readonly type="date" class="form-control"
                                value="{{currentDate | date:'yyyy-MM-dd'}}" max = {{CalenderBlockedAfterTodayDate}}>
                        </div>

                    </div>

                    <div class="text-danger" *ngIf= "isLoggedInUserAdmin && medicalRestrictionList.length > 0">
                        <h6>WARNING: This employee has a current medical restriction</h6>
                        <ul>
                            <li *ngFor="let medicalRestriction of medicalRestrictionList">
                                {{medicalRestriction}}
                            </li>
                        </ul>
                    </div>


                    <div *ngIf="isLoggedInUserAdmin && SpecialOverride" class="form-group row">
                        <label class="col-sm-4 col-form-label">Validate:</label>
                        <div class="col-sm-8">
                            <input formControlName="SpecialOverride" type="checkbox"
                                class="form-control checkbox-label">
                        </div>

                        <small class="text-danger" *ngIf="SpecialOverride && ValidateSpecialMessage">
                            You have chosen a request type of 'Special' but a shift other than the employee's current
                            one. You must provide an override.
                        </small>
                    </div>

                    <div *ngIf="isLoggedInUserAdmin && PlantSeniorityValidate" class="form-group row">
                        <label class="col-sm-4 col-form-label">Validate</label>
                        <div class="col-sm-8">
                            <input formControlName="SeniorityOverrideFlag" type="checkbox"
                                class="form-control checkbox-label">
                        </div>
                    </div>
                    <small class="text-danger" *ngIf="PlantSeniorityValidate">
                        You must provide an override due to less than 90 days plant seniority.
                    </small>

                </form>
            </div>
            <div class="modal-footer" style="padding-right: 80px">
                <button  style="
                height: 40px;
                width: 150px;
                border-radius: 10px;
              " type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" #closeAddModel
                    (click)="clear()">Cancel</button>
                <button style="
                height: 40px;
                width: 150px;
                border-radius: 10px;
                
              "  type="button" class="btn btn-primary" [disabled]="employeeForm.invalid || !StatusCode"
                    (click)="addEmployee()">Add </button>
            </div>
        </div>
    </div>
</div>