import { Component, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { CommonSearchService } from 'src/app/Services/common.search.service';
import {
  OverTimeMainSearchGroupPostModel,
  OverTimeMainSearchIndividualPostModel,
} from '../../search-model/search-model';
import { ToolAndDiePlantService } from 'src/app/Services/Applications/ToolAndDie/tool-and-die-plant.service';
import { KeyValue } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { OverTimeMaintanceService } from 'src/app/Services/Applications/OvertimeMaintanence/overtime.maintanence.service';

@Component({
  selector: 'app-common-search',
  templateUrl: './common-search.component.html',
  styleUrls: ['./common-search.component.css'],
})
export class CommonSearchComponent implements OnInit {
  searchForm: FormGroup | any;
  InputSearchForm: FormGroup | any;
  DropDownSearchForm: FormGroup | any;
  @Input() SectionDepartmentbool: boolean | any;
  @Input() DepartmentDefaultVal: string | any;

  @Input() SectionClassificationbool: boolean | any;
  @Input() SectionAsOfDatebool: boolean | any;
  @Input() SectionOverTimeTypeBool: boolean | any;
  @Input() SectionShiftBool: boolean | any;
  @Input() SectionFirstNamebool: boolean | any;
  @Input() SectionLastNamebool: boolean | any;
  @Input() SectionATIDbool: boolean | any;

  @Input() SectionOverTimeStatusbool: boolean | any;
  @Input() SectionDateRangebool: boolean | any;
  @Input() isAllocationView: any;
  @Input()
  control: FormControl | undefined;
  SectionDepartment: any;
  SectionClassification: any;
  SectionPlant: any;
  SectionShift: any;
  DepartmentData: any;
  ClassificationData: any;
  ShiftsData: any;
  OverTimeTypeData: any;
  StatusData: any;
  groupPostModel: OverTimeMainSearchGroupPostModel =
    new OverTimeMainSearchGroupPostModel();
  individualPostModel: OverTimeMainSearchIndividualPostModel =
    new OverTimeMainSearchIndividualPostModel();
  @Output() AnyEventEmitter = new EventEmitter();
  SectionFirstName: string;
  SectionLastName: string;
  SectionEmployeeId: string;
  SectionAsOfDate: string;
  SectionCutOfDate: string;
  SectionOverTimeType: string;
  isIndiviualSearchType!: boolean | false;
  SectionOverTimeStatus: string;
  SectionEndDate: string;
  SectionStartDate: string;
  showFilter: boolean = true;
  // DefaultAsofDateVal: string;
  @Input() DefaultDeptVal: string | undefined;
  @Input() DefaultClassVal: string | undefined;
  @Input() DefaultShiftVal: string | undefined;
  @Input() DefaultOverTimeTypeVal: string | undefined;
  @Input() DefaultAsOfDateVal: string | undefined;
  @Input() DefaultOverTimeStatusVal: string | undefined;
  @Input() DefaultStartDateVal: string | undefined;
  @Input() DefaultEndDateVal: string | undefined;

  @Input() DefaultIndFirstNameVal: string | undefined;
  @Input() DefaultIndLastNameVal: string | undefined;
  @Input() DefaultIndEmpIdVal: string | undefined;

  constructor(
    private httpClient: HttpClient,
    private td: ToolAndDiePlantService,
    private fb: FormBuilder,
    private fb2: FormBuilder,
    private ot: OverTimeMaintanceService,

    private commonSearchForm: CommonSearchService
  ) {
    // const format = 'yyyy-MM-dd';
    // const locale = 'en-US';
    // var dtAsOfDate = new Date;
    // this.DefaultAsofDateVal = formatDate(dtAsOfDate, format, locale);

    this.SectionDepartment = 'Department';
    this.SectionClassification = 'Classification';
    this.SectionAsOfDate = 'As of Date';
    this.SectionCutOfDate = 'Cut of Date';
    this.SectionOverTimeType = 'Overtime Type';
    this.SectionShift = 'Shift';

    this.SectionEmployeeId = 'EmployeeId';
    this.SectionFirstName = 'First Name';
    this.SectionLastName = 'Last Name';

    this.SectionOverTimeStatus = 'Overtime Status';
    this.SectionEndDate = 'End Date';
    this.SectionStartDate = 'Start Date';

    this.getStatusData();
    this.getOvertimeType();
    this.getShifts();
  }

  inputs: any;
  ngOnInit(): void {
    this.groupPostModel = {
      SEARCHTYPE: '',
      DEPTID: '',
      JOBCODEID: '',
      SHIFTID: '',
      OVERTIMETYPEID: '',
      ASOFDATE: this.DefaultAsOfDateVal,
      EMPLOYEEID: '',
      STATUS: '',
      DATEOFWORKSTART: '',
      DATEOFWORKEND: '',
    };

    this.individualPostModel = {
      SEARCHTYPE: '',
      EMPLOYEEID: '',
      FIRSTNAME: '',
      LASTNAME: '',
      CUT_OFF_DATE: '',
      OVERTIMETYPEID: null,
    };

    this.searchForm = this.fb.group({
      searchFromGroup: this.commonSearchForm.commonSearchForm(),
    });

    this.InputSearchForm = this.fb.group({});
    this.DropDownSearchForm = this.fb2.group({});
    this.InputSearchForm.addControl(
      'DepartmentControl',
      this.fb.control('', [Validators.maxLength(4)])
    );
    this.InputSearchForm.addControl(
      'ClassificationControl',
      this.fb.control('', [Validators.maxLength(4)])
    );
    this.InputSearchForm.addControl('AsOfDateControl', this.fb.control('', []));
    this.InputSearchForm.addControl('OverTimeType', this.fb.control('', []));
    this.InputSearchForm.addControl('OverTimeStatus', this.fb.control('', []));
    this.InputSearchForm.addControl('ShiftControl', this.fb.control('', []));

    this.InputSearchForm.addControl(
      'FirstNameControl',
      this.fb.control('', [])
    );
    this.InputSearchForm.addControl('LastNameControl', this.fb.control('', []));
    this.InputSearchForm.addControl(
      'EmployeeIdControl',
      this.fb.control('', [])
    );

    this.InputSearchForm.addControl('OverTimeType', this.fb.control('', []));
    this.InputSearchForm.addControl('OverTimeStatus', this.fb.control('', []));
    this.InputSearchForm.addControl('StartDate', this.fb.control('', []));
    //this.InputSearchForm.addControl('EndDate', this.fb.control('', [this.dateRangeValidator]));
  }

  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const from = this.searchForm && this.searchForm.get('StartDate').value;
    const to = this.searchForm && this.searchForm.get('EndDate').value;
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };

  DropDownEventHandler(event: any, key: string) {
    if (key === this.SectionShift) {
      this.groupPostModel.SHIFTID = event;
    }

    if (key === this.SectionOverTimeType) {
      this.groupPostModel.OVERTIMETYPEID = event;
    }

    if (key === this.SectionOverTimeStatus) {
      this.groupPostModel.STATUS = event;
    }

    this.NotifyParent();
  }

  TextBoxEventHandler(event: any, key: string) {
    if (key === this.SectionFirstName) {
      this.isIndiviualSearchType = true;
      this.individualPostModel.FIRSTNAME = event;
    }
    if (key === this.SectionLastName) {
      this.isIndiviualSearchType = true;
      this.individualPostModel.LASTNAME = event;
    }
    if (key === this.SectionEmployeeId) {
      this.isIndiviualSearchType = true;
      this.individualPostModel.EMPLOYEEID = event;
    }
    if (key === this.SectionClassification) {
      this.groupPostModel.JOBCODEID = event;
    }
    if (key === this.SectionDepartment) {
      this.groupPostModel.DEPTID = event;
    }
    this.NotifyParent();
  }

  DateEventHandler(event: any, key: string) {
    if (key === this.SectionStartDate) {
      this.groupPostModel.DATEOFWORKSTART = event;
    }
    if (key === this.SectionEndDate) {
      this.groupPostModel.DATEOFWORKEND = event;
    }
    if (key === this.SectionAsOfDate) {
      this.groupPostModel.ASOFDATE = event;
    }

    if (key === this.SectionCutOfDate) {
      this.isIndiviualSearchType = true;
      this.individualPostModel.CUT_OFF_DATE = event;
    }
    this.NotifyParent();
  }

  getShifts() {
    this.td.getShiftsList().subscribe((res: any) => {
      const elements = res || [];

      this.ShiftsData = elements.map(
        (element: { shiftId: any; shiftDescription: any }) => ({
          Id: element.shiftId,
          Description: element.shiftDescription,
        })
      );

      // for (var i = 0; i < this.ShiftsData.length; i++) {
      //   if(this.ShiftsData[i].Description==='Any'){
      //   //if (this.ShiftsData[i] === 5) {
      //     this.ShiftsData.splice(i, 1);
      //   }
      //   if(this.ShiftsData[i].Description==='All'){
      //       this.ShiftsData[i].Description = 'Select';
      //       this.ShiftsData.splice(i, 1);
      //     }
      // }

      let index = this.ShiftsData.map((o: any) => o.shiftId).indexOf('A');
      //console.log('Index',index)
      this.ShiftsData.splice(index, 1);

      //var removed = this.ShiftsData.splice("Any");
      //console.log('sd',this.ShiftsData)
      //this.ShiftsData.splice( this.ShiftsData.findIndex(item:any => item.shiftDescription === 'Any'),1);
    });
  }

  getOvertimeType() {
    this.ot.getOvertimeType().subscribe((res) => {
      const elements = res || [];
      this.OverTimeTypeData = elements.map(
        (element: { overtimetypeid: any; overtimetypedescription: any }) => ({
          Id: element.overtimetypeid,
          Description: element.overtimetypedescription,
        })
      );
    });
  }

  getStatusData() {
    this.StatusData = [
      {
        Id: 'I',
        Description: 'Archived',
      },
      {
        Id: 'A',
        Description: 'Active',
      },
    ];
  }

  get f() {
    return this.InputSearchForm.controls;
  }

  onAppTypeSelected(val: number) {
    if (val === 2) {
      this.showFilter = false;
    }
    if (val === 1) {
      this.showFilter = true;
    }
  }

  private NotifyParent(): void {
    if (this.isIndiviualSearchType) {
      this.individualPostModel.SEARCHTYPE = 'Individual';
      let keyVal: KeyValue<any, any> = {
        key: this.individualPostModel,
        value: this.InputSearchForm.valid,
      };
      this.isIndiviualSearchType = false;
      this.AnyEventEmitter.emit(keyVal);
    } else {
      this.groupPostModel.SEARCHTYPE = 'Group';
      let keyVal: KeyValue<any, any> = {
        key: this.groupPostModel,
        value: this.InputSearchForm.valid,
      };
      this.isIndiviualSearchType = false;
      this.AnyEventEmitter.emit(keyVal);
    }
  }
}
