<div
  class="modal fade"
  id="deleteApplicationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteApplicationModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-alignment" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteApplicationModalLabel">
          Delete Application
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>
      <div class="modal-body">
        <p class="">
          Are you sure you want to delete the application?
        </p>
      </div>
      <div class="modal-footer">
        <button type="reset" class="btn popupbuttonscancel cancel-btn" data-dismiss="modal" (click)="clear()" >
          Cancel
        </button>
        <button type="button" class="btn btn-primary delete-btn" data-dismiss="modal" (click)="confirmDelete()">
          Delete
        </button>
      </div>
    </div>
  </div>
</div>
