import { DatePipe, formatDate, KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'gyansys-date',
  templateUrl: './date.component.html',
  providers: [DatePipe],
  // styleUrls: ['./department-search.component.css'],
})
export class DateComponent {
  @Input() Parent: FormGroup | any;
  @Input() SectionName: any;
  @Output() EventEmitToParent = new EventEmitter();
  @Input() SourceData: any;
  @Input() dateValue: any;

  constructor(private datePipe: DatePipe) {}

  onChanges(val: any): void {
    const format = 'MM/dd/yyyy';
    const locale = 'en-US';
    const formattedDate = formatDate(this.dateValue, format, locale);

    let txtBox: KeyValue<any, any> = {
      key: this.SectionName,
      value: formattedDate,
    };
    this.EventEmitToParent.emit(formattedDate);
  }

  ChildDepartmentEventHandler() {
    let val = this.datePipe.transform(this.dateValue, 'MM/dd/yyyy');
    //console.log("darteval"+this.dateValue);
    //console.log("darteval"+val);
    this.EventEmitToParent.emit(val);
  }
}
