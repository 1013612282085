import { Component, OnDestroy, OnInit } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { OverTimeMaintanceService } from 'src/app/Services/Applications/OvertimeMaintanence/overtime.maintanence.service';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { DatePipe } from '@angular/common';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { LocalStorageService } from 'src/app/Services/LocalStorageService/locastorage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-overtime-allocation-summary',
  templateUrl: './overtime-allocation-summary.component.html',
  styleUrls: ['./overtime-allocation-summary.component.css'],
})
export class OvertimeAllocationSummaryComponent implements OnInit, OnDestroy {
  @ViewChild('content') content!: ElementRef;

  isView: any;
  isAllocationView: any;
  overtimeDetailData: any[] = [];
  overtimeGroupUserData: any[] = [];
  overtimeGroupName: any;
  overtimeGroupId: any;
  asOfDate: any;
  todayDate: any;

  isDay1Weekend = false;
  isDay2Weekend = false;
  isDay3Weekend = false;
  isDay4Weekend = false;
  isDay5Weekend = false;
  isDay6Weekend = false;
  isDay7Weekend = false;
  isDay8Weekend = false;
  isDay9Weekend = false;
  isDay10Weekend = false;

  isDay11Weekend = false;
  isDay12Weekend = false;
  isDay13Weekend = false;
  isDay14Weekend = false;
  isDay15Weekend = false;
  isDay16Weekend = false;
  isDay17Weekend = false;
  isDay18Weekend = false;
  isDay19Weekend = false;
  isDay20Weekend = false;

  isDay21Weekend = false;
  isDay22Weekend = false;
  isDay23Weekend = false;
  isDay24Weekend = false;
  isDay25Weekend = false;
  isDay26Weekend = false;
  isDay27Weekend = false;
  isDay28Weekend = false;
  isDay29Weekend = false;
  isDay30Weekend = false;
  isDay31Weekend = false;

  isDay29Availabe = true;
  isDay30Availabe = true;
  isDay31Availabe = true;


  constructor(
    private _overtimeMaintenanceService: OverTimeMaintanceService,
    private _commonApplicationService: CommonService,
    public _userInfo: UsergroupService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private _sweetalert: SweetAlertService
  ) {
    this.isView = this.localStorageService.getItem('isView');
    this.isAllocationView =
      this.localStorageService.getItem('isAllocationView');
  }


  ngOnDestroy(): void {
    this.localStorageService.removeItem('isView');
    this.localStorageService.removeItem('isAllocationView');
  }

  ngOnInit(): void {
    this.asOfDate = this.localStorageService.getItem('oasAsOfDate');
    this.overtimeGroupId =
      this.localStorageService.getItem('oasOvertimeGroupId');
    this.overtimeGroupName = this.localStorageService.getItem(
      'oasOvertimeGroupName'
    );
    this.todayDate = new Date();
    this.GetOvertimeData();
  }

  filterItemsOfUser(employeeid: any) {
    return this.overtimeDetailData.filter((x) => x.employeeid === employeeid);
  }

  filterUsersBasedOnSpreadIfTrue() {
    return this.overtimeGroupUserData.filter((x) => x.isinspread);
  }

  filterUsersBasedOnSpreadIfFalse() {
    return this.overtimeGroupUserData.filter((x) => !x.isinspread);
  }

  getTotalByDayByUser(employeeid: any, carriedforwardhours: any) {
    return this.accumulatedTotalHours.filter(
      (x) => x.employeeid === employeeid
    );
  }

  onPrintClick() {
    var allLinks = $('head').clone().find('script').remove().end().html();
    var keepColors =
      '<style>body {-webkit-print-color-adjust: exact !important; }</style>';
    var innerContents = document.getElementById('content')?.innerHTML;
    var popupWinindow = window.open('Print', '_blank');
    popupWinindow?.document.open();
    popupWinindow?.document.write(
      '<html><head>' +
        keepColors +
        allLinks +
        '</head><body onload="window.print();window.close();">' +
        innerContents +
        '</html>'
    );
    popupWinindow?.document.close();
  }

  MainNavigate() {
    if (this.isView === 'Y') {
      this.router.navigate(['OvertimeView']);
    } else if (this.isAllocationView === 'Y') {
      this.router.navigate(['ViewOvertimeAllocationSummary']);
    } else {
      this.router.navigate(['Overtime']);
    }
  }

  GetOvertimeData() {
    var datePipe = new DatePipe('en-US');
    var asOfDate = datePipe.transform(this.asOfDate, 'MM/dd/yyyy');
    var paramUpdateData = {
      ASOFDATE: asOfDate,
      OVERTIMEGROUPID: this.overtimeGroupId,
    };

    this._overtimeMaintenanceService
      .OvertimeAllocationSummaryDetail(paramUpdateData)
      .subscribe((data: any) => {
        this.overtimeDetailData = data;
        this.getAccumTotal();
        this.findWeekendDays();
      });

    this._overtimeMaintenanceService
      .OvertimeAllocationSummary(paramUpdateData)
      .subscribe((data: any) => {
        this.overtimeGroupUserData = data;
      });
  }

  findWeekendDays() {
    var date = new Date(this.asOfDate);
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var diff = Math.abs(lastDay.getTime() - firstDay.getTime());
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    diffDays += 1;

    if (diffDays === 28) {
      this.isDay29Availabe = false;
      this.isDay30Availabe = false;
      this.isDay31Availabe = false;
    } else if (diffDays === 29) {
      this.isDay30Availabe = false;
      this.isDay31Availabe = false;
    } else if (diffDays === 30) {
      this.isDay31Availabe = false;
    }

    for (var i = 1; i < 32; i++) {
      var isweekend = false;
      var dynDay = new Date(date.getFullYear(), date.getMonth(), 1);
      dynDay.setDate(dynDay.getDate() + (i - 1));
      if (dynDay.getDay() === 6 || dynDay.getDay() === 0) {
        isweekend = true;
      }

      if (i === 1) {
        this.isDay1Weekend = isweekend;
      } else if (i === 2) {
        this.isDay2Weekend = isweekend;
      } else if (i === 3) {
        this.isDay3Weekend = isweekend;
      } else if (i === 4) {
        this.isDay4Weekend = isweekend;
      } else if (i === 5) {
        this.isDay5Weekend = isweekend;
      } else if (i === 6) {
        this.isDay6Weekend = isweekend;
      } else if (i === 7) {
        this.isDay7Weekend = isweekend;
      } else if (i === 8) {
        this.isDay8Weekend = isweekend;
      } else if (i === 9) {
        this.isDay9Weekend = isweekend;
      } else if (i === 10) {
        this.isDay10Weekend = isweekend;
      } else if (i === 11) {
        this.isDay11Weekend = isweekend;
      } else if (i === 12) {
        this.isDay12Weekend = isweekend;
      } else if (i === 13) {
        this.isDay13Weekend = isweekend;
      } else if (i === 14) {
        this.isDay14Weekend = isweekend;
      } else if (i === 15) {
        this.isDay15Weekend = isweekend;
      } else if (i === 16) {
        this.isDay16Weekend = isweekend;
      } else if (i === 17) {
        this.isDay17Weekend = isweekend;
      } else if (i === 18) {
        this.isDay18Weekend = isweekend;
      } else if (i === 19) {
        this.isDay19Weekend = isweekend;
      } else if (i === 20) {
        this.isDay20Weekend = isweekend;
      } else if (i === 21) {
        this.isDay21Weekend = isweekend;
      } else if (i === 22) {
        this.isDay22Weekend = isweekend;
      } else if (i === 23) {
        this.isDay23Weekend = isweekend;
      } else if (i === 24) {
        this.isDay24Weekend = isweekend;
      } else if (i === 25) {
        this.isDay25Weekend = isweekend;
      } else if (i === 26) {
        this.isDay26Weekend = isweekend;
      } else if (i === 27) {
        this.isDay27Weekend = isweekend;
      } else if (i === 28) {
        this.isDay28Weekend = isweekend;
      } else if (i === 29) {
        this.isDay29Weekend = isweekend;
      } else if (i === 30) {
        this.isDay30Weekend = isweekend;
      } else if (i === 31) {
        this.isDay31Weekend = isweekend;
      }
    }
  }

  accumulatedTotalHours: any[] = [];
  filteredEmpOTRecords: any[] = [];
  getAccumTotal() {
    this.accumulatedTotalHours.length = 0;
    var total,
      day1,
      day2,
      day3,
      day4,
      day5,
      day6,
      day7,
      day8,
      day9,
      day10,
      day11,
      day12,
      day13,
      day14,
      day15,
      day16,
      day17,
      day18,
      day19,
      day20,
      day21,
      day22,
      day23,
      day24,
      day25,
      day26,
      day27,
      day28,
      day29,
      day30,
      day31 = 0;

    let DistinctEmpList = [
      ...new Set(this.overtimeDetailData.map((item) => item.employeeid)),
    ];

    for (var emp = 0; emp < DistinctEmpList.length; emp++) {
      this.filteredEmpOTRecords = this.overtimeDetailData.filter(
        (s) => s.employeeid === DistinctEmpList[emp]
      );

      day1 = 0;
      day2 = 0;
      day3 = 0;
      day4 = 0;
      day5 = 0;
      day6 = 0;
      day7 = 0;
      day8 = 0;
      day9 = 0;
      day10 = 0;
      day11 = 0;
      day12 = 0;
      day13 = 0;
      day14 = 0;
      day15 = 0;
      day16 = 0;
      day17 = 0;
      day18 = 0;
      day19 = 0;
      day20 = 0;
      day21 = 0;
      day22 = 0;
      day23 = 0;
      day24 = 0;
      day25 = 0;
      day26 = 0;
      day27 = 0;
      day28 = 0;
      day29 = 0;
      day30 = 0;
      day31 = 0;
      total = 0;

      for (var i = 0; i < this.filteredEmpOTRecords.length; i++) {
        day1 += parseFloat(this.filteredEmpOTRecords[i].daY1);
        day2 += parseFloat(this.filteredEmpOTRecords[i].daY2);
        day3 += parseFloat(this.filteredEmpOTRecords[i].daY3);
        day4 += parseFloat(this.filteredEmpOTRecords[i].daY4);
        day5 += parseFloat(this.filteredEmpOTRecords[i].daY5);
        day6 += parseFloat(this.filteredEmpOTRecords[i].daY6);
        day7 += parseFloat(this.filteredEmpOTRecords[i].daY7);
        day8 += parseFloat(this.filteredEmpOTRecords[i].daY8);
        day9 += parseFloat(this.filteredEmpOTRecords[i].daY9);
        day10 += parseFloat(this.filteredEmpOTRecords[i].daY10);

        day11 += parseFloat(this.filteredEmpOTRecords[i].daY11);
        day12 += parseFloat(this.filteredEmpOTRecords[i].daY12);
        day13 += parseFloat(this.filteredEmpOTRecords[i].daY13);
        day14 += parseFloat(this.filteredEmpOTRecords[i].daY14);
        day15 += parseFloat(this.filteredEmpOTRecords[i].daY15);
        day16 += parseFloat(this.filteredEmpOTRecords[i].daY16);
        day17 += parseFloat(this.filteredEmpOTRecords[i].daY17);
        day18 += parseFloat(this.filteredEmpOTRecords[i].daY18);
        day19 += parseFloat(this.filteredEmpOTRecords[i].daY19);
        day20 += parseFloat(this.filteredEmpOTRecords[i].daY20);

        day21 += parseFloat(this.filteredEmpOTRecords[i].daY21);
        day22 += parseFloat(this.filteredEmpOTRecords[i].daY22);
        day23 += parseFloat(this.filteredEmpOTRecords[i].daY23);
        day24 += parseFloat(this.filteredEmpOTRecords[i].daY24);
        day25 += parseFloat(this.filteredEmpOTRecords[i].daY25);
        day26 += parseFloat(this.filteredEmpOTRecords[i].daY26);
        day27 += parseFloat(this.filteredEmpOTRecords[i].daY27);
        day28 += parseFloat(this.filteredEmpOTRecords[i].daY28);
        day29 += parseFloat(this.filteredEmpOTRecords[i].daY29);
        day30 += parseFloat(this.filteredEmpOTRecords[i].daY30);
        day31 += parseFloat(this.filteredEmpOTRecords[i].daY31);

        total += parseFloat(this.filteredEmpOTRecords[i].total);

        if (i === this.filteredEmpOTRecords.length - 1) {
          day2 += day1;
          day3 += day2;
          day4 += day3;
          day5 += day4;
          day6 += day5;
          day7 += day6;
          day8 += day7;
          day9 += day8;
          day10 += day9;
          day11 += day10;

          day12 += day11;
          day13 += day12;
          day14 += day13;
          day15 += day14;
          day16 += day15;
          day17 += day16;
          day18 += day17;
          day19 += day18;
          day20 += day19;
          day21 += day20;

          day22 += day21;
          day23 += day22;
          day24 += day23;
          day25 += day24;
          day26 += day25;
          day27 += day26;
          day28 += day27;
          day29 += day28;
          day30 += day29;
          day31 += day30;
          total += total;

          var paramUpdateData = {
            employeeid: this.filteredEmpOTRecords[i].employeeid,
            day1: day1,
            day2: day2,
            day3: day3,
            day4: day4,
            day5: day5,
            day6: day6,
            day7: day7,
            day8: day8,
            day9: day9,
            day10: day10,

            day11: day11,
            day12: day12,
            day13: day13,
            day14: day14,
            day15: day15,
            day16: day16,
            day17: day17,
            day18: day18,
            day19: day19,
            day20: day20,

            day21: day21,
            day22: day22,
            day23: day23,
            day24: day24,
            day25: day25,
            day26: day26,
            day27: day27,
            day28: day28,
            day29: day29,
            day30: day30,
            day31: day31,
            total: total,
          };
          this.accumulatedTotalHours.push(paramUpdateData);
        }
      }
    }
  }
}

