import { HttpErrorResponse } from '@angular/common/http';
import {
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClassificationDataService } from 'src/app/Services/TableMaintenance/ClassificationData/classification-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-classification',
  templateUrl: './edit-classification.component.html',
  styleUrls: ['./edit-classification.component.css'],
})
export class EditClassificationComponent implements OnInit, OnChanges {
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;
  @Input() selectedClassification: any;
  @Output() isSucess = new EventEmitter();
  classificationSelected: any;
  classificationForm!: FormGroup;
  @Input() userName: any;
  Types: any[] = [
    {
      data: [
        {
          classificationType: 'P',
          classificationName: 'Production',
        },
        {
          classificationType: 'S',
          classificationName: 'Skilled',
        },
      ],
      default: 'Select One',
    },
  ];
  Status: any[] = [
    {
      data: [
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
        {
          statusType: 'A',
          statusName: 'Active',
        },
      ],
      default: 'Select One',
    },
  ];
  Nio: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];
  constructor(
    private fb: FormBuilder,
    private cds: ClassificationDataService
  ) {}

  ngOnInit(): void {
    this.getNIOGroups();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createForm();
    this.loadClassificationData(changes);
  }

  getNIOGroups() {
    this.cds.getNIOGroups().subscribe((res) => {
      this.Nio[this.Nio.length - 1].data = res;
    });
  }

  createForm() {
    this.classificationForm = this.fb.group({
      JOBCODEID: ['', [Validators.required]],
      SAP_JOBCODEID: ['', Validators.required],
      JOBCODEDESCRIPTION: ['', [Validators.required]],
      CLASSIFICATIONTYPE: ['', [Validators.required]],
      STATUS: ['', [Validators.required]],
      MAXRATE: ['', [Validators.required]],
      NIO: ['', [Validators.required]],
      REPLACES: [],
      REPLACEDBY: [],
      // BOX: [''],
      LASTUPDATEBY: [this.userName],
    });
  }

  loadClassificationData(changes: SimpleChanges) {
    this.classificationSelected =
      (changes.selectedClassification &&
        changes.selectedClassification.currentValue) ||
      '';
    if (this.classificationSelected === '') {
      this.classificationSelected =
        (changes.selectedClassification &&
          changes.selectedClassification.previousValue) ||
        '';
    }
    this.classificationForm
      .get('JOBCODEID')
      ?.patchValue(this.classificationSelected.jobcodeid);
    this.classificationForm
      .get('SAP_JOBCODEID')
      ?.patchValue(
        this.classificationSelected.saP_JOBCODEID
          ? this.classificationSelected.saP_JOBCODEID
          : 'N/A'
      );
    this.classificationForm
      .get('JOBCODEDESCRIPTION')
      ?.patchValue(this.classificationSelected.jobcodedescription);
    this.classificationForm
      .get('CLASSIFICATIONTYPE')
      ?.patchValue(this.classificationSelected.classificationtype);
    this.classificationForm
      .get('STATUS')
      ?.patchValue(this.classificationSelected.status);
    this.classificationForm
      .get('MAXRATE')
      ?.patchValue(this.classificationSelected.maxrate);
    this.classificationForm
      .get('NIO')
      ?.patchValue(this.classificationSelected.nio);
    //this.classificationForm.get('BOX')?.patchValue(this.classificationSelected.box);
    this.classificationForm
      .get('REPLACES')
      ?.patchValue(this.classificationSelected.replaces);
    this.classificationForm
      .get('REPLACEDBY')
      ?.patchValue(this.classificationSelected.replacedby);
  }

  clear() {
    this.closeModal.nativeElement.click();
    this.createForm();
    this.loadCancelledData();
  }

  submitClassification() {
    this.cds.editClassification(this.classificationForm.value).subscribe(
      () => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 500) {
          this.badRequestAlert(_error.error);
        } else {
          this.validationError(_error.error);
        }
      }
    );
  }

  loadCancelledData() {
    this.classificationForm
      .get('JOBCODEID')
      ?.patchValue(this.classificationSelected.jobcodeid);
    this.classificationForm
      .get('SAP_JOBCODEID')
      ?.patchValue(
        this.classificationSelected.saP_JOBCODEID
          ? this.classificationSelected.saP_JOBCODEID
          : 'N/A'
      );
    this.classificationForm
      .get('JOBCODEDESCRIPTION')
      ?.patchValue(this.classificationSelected.jobcodedescription);
    this.classificationForm
      .get('CLASSIFICATIONTYPE')
      ?.patchValue(this.classificationSelected.classificationtype);
    this.classificationForm
      .get('STATUS')
      ?.patchValue(this.classificationSelected.status);
    this.classificationForm
      .get('MAXRATE')
      ?.patchValue(this.classificationSelected.maxrate);
    this.classificationForm
      .get('NIO')
      ?.patchValue(this.classificationSelected.nio);
    //this.classificationForm.get('BOX')?.patchValue(this.classificationSelected.box);
    this.classificationForm
      .get('REPLACES')
      ?.patchValue(this.classificationSelected.replaces);
    this.classificationForm
      .get('REPLACEDBY')
      ?.patchValue(this.classificationSelected.replacedby);
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Classification has been updated successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
