import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { CommonService } from '../Applications/Common/common.service';

const MIN_DAYS_BETWEEN_APP_AND_BUMP = 14;

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor(private _commonService: CommonService) {}

  /* gets the date after no. of days */
  /*
    Param 1: Days in number
    Param 2: Date
  */
  getDateNoDaysFromToday(days: number, dateSigned: any) {
    return new Date(
      new Date(dateSigned).setDate(new Date(dateSigned).getDate() + days)
    );
  }

  /* gets the date of the first monday of after no. of months*/
  /*
    Param 1: Date
    Param 2: No of months
  */
  getDateOfFirstMondayOfMonth(datesigned: any, noOfMonth: number) {
    let date = null;
    if (typeof datesigned === 'number') {
      date = new Date(datesigned);
    } else {
      const splitDate = datesigned.split('-');
      const estDate = new Date(
        `${splitDate[1]}-${splitDate[2]}-${splitDate[0]}`
      ).toLocaleString('en-US', {
        timeZone: 'America/New_York',
      });
      date = new Date(estDate);
    }

    // var date = new Date(estDate);
    var noOdfDays = date!.getDate();
    date!.setDate(date!.getDate() - (noOdfDays - 1));
    date!.setMonth(date!.getMonth() + noOfMonth);

    date!.setDate(date!.getDate() + ((8 - date!.getDay()) % 7));
    return new Date(new Date(date!).setHours(0, 0, 0, 0));
  }

  /*function to get the effective date for Shift Preference application*/
  /*
    Param 1: Date
    Param 2: Shift Request Type
  */
  calculateEffectiveDate(DateSigned: any, shiftRequestType: string) {
    var formattedDate = this._commonService.formatDate(DateSigned);
    if (
      shiftRequestType === '0' &&
      this.getDateNoDaysFromToday(
        MIN_DAYS_BETWEEN_APP_AND_BUMP,
        formattedDate
      ) < this.getDateOfFirstMondayOfMonth(formattedDate, 1)
    ) {
      return this.getDateOfFirstMondayOfMonth(formattedDate, 1);
    } else if (shiftRequestType === '1') {
      return null;
    } else {
      return this.getDateOfFirstMondayOfMonth(formattedDate, 2);
    }
  }

  /* checks if the input date is weekend or not*/
  /*
    Param 1 : Date
  */
  checkIfDateIsWeekend(dateEntered: any) {
    const SelectedLocalDateTime = this.GetLocalDateTime(dateEntered);
    var dateValue = new Date(SelectedLocalDateTime).getDay();
    if (dateValue === 0 || dateValue === 6) {
      return true;
    } else {
      return false;
    }
  }
  /* checks if the input date is a holiday or not */
  /*
    Param 1: Date
    Param 2: Holiday List
  */
  checkIfDateIsAHoliday(dateOfWork: any, HolidayArray: any) {
    for (var i = 0; i < HolidayArray.length; i++) {
      if (HolidayArray[i].holidayDate.toString() === dateOfWork.toString()) {
        return true;
      }
    }
    return false;
  }

  /* Gets the UTC Date */
  GetUTCDate(date: any) {
    var empdd = new Date(date);
    var dd = new Date();
    empdd.setDate(empdd.getUTCDate());
    empdd.setMonth(empdd.getUTCMonth());
    empdd.setFullYear(empdd.getUTCFullYear());
    empdd.setHours(dd.getUTCHours());
    empdd.setMinutes(dd.getUTCMinutes());
    empdd.setSeconds(dd.getUTCSeconds());

    return formatDate(new Date(empdd), 'yyyy-MM-ddTHH:mm', 'en-US');
  }

  /* Formats selected date with system time */
  /*
   Param 1 : Date
  */
  GetLocalDateTime(date: any) {
    var curDate = new Date();
    var hr = curDate.getHours();
    var mn = curDate.getMinutes();
    var sec = curDate.getSeconds();
    return date + ' ' + hr + ':' + mn + ':' + sec;
  }

  /* Check if Application is Timely */
  /*
  Param 1 : Date
  Param 2 : Date
  */
  IsApplicationTimely(eligibleBumpDate: Date) {
    var getFirstMonday = this.getDateOfFirstMondayOfMonth(
      new Date().setHours(0, 0, 0, 0),
      0
    );
    if (
      eligibleBumpDate < getFirstMonday ||
      this.equalsIgnoresTime(eligibleBumpDate, getFirstMonday)
    ) {
      return true;
    }
    return false;
  }

  /* check if two Dates are equal (ignoring the timestamp) */
  /*
  Param 1 : Date
  Param 2 : Date
  */
  equalsIgnoresTime(dateFrom: Date, dateTo: Date) {
    return dateFrom.setHours(0, 0, 0, 0) === dateTo.setHours(0, 0, 0, 0);
  }
}
