import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { HolidayDataService } from 'src/app/Services/TableMaintenance/HolidayData/holiday-data.service';
import Swal from 'sweetalert2';
import { HolidayModel } from '../../../../Models/HolidayModel';

@Component({
  selector: 'app-edit-holiday-data',
  templateUrl: './edit-holiday-data.component.html',
  styleUrls: ['./edit-holiday-data.component.css'],
})
export class EditHolidayDataComponent implements OnChanges {
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;
  holidayForm!: FormGroup;
  @Input() userName: any;
  @Output() isSucess = new EventEmitter();
  @Input() selectedHoliday: any;
  holidaySelected: any;

  constructor(
    private fb: FormBuilder,
    private hds: HolidayDataService,
    private _commonApplicationService: CommonService,
    private _sweetAlert: SweetAlertService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.createForm();
    this.loadHolidayData(changes);
  }

  createForm() {
    this.holidayForm = this.fb.group({
      LastUpdateBy: [this.userName],
      holidays: this.fb.array([]),
    });
  }

  loadHolidayData(changes: SimpleChanges) {
    this.holidaySelected =
      (changes.selectedHoliday && changes.selectedHoliday.currentValue) || '';
    if (this.holidaySelected === '') {
      this.holidaySelected =
        (changes.selectedHoliday && changes.selectedHoliday.previousValue) ||
        '';
    }
    this.holidaySelected.sort((a: any, b: any) => {
      let date1 = new Date(a.holiday),
        date2 = new Date(b.holiday);
      return date1.getTime() - date2.getTime();
    });
    this.patchEmployeeValues();
  }

  patchEmployeeValues() {
    const holidayArray: HolidayModel[] = [];
    for (let i = 0; i < this.holidaySelected.length; i++) {
      let tempFormGroup = this.fb.group(new HolidayModel());
      this.holidays().push(tempFormGroup);
      const tempHolidayObj = {
        HolidayId: this.holidaySelected[i].holidayId,
        Holiday: this._commonApplicationService.formatDate(
          this.holidaySelected[i].holiday
        ),
        Description: this.holidaySelected[i].description,
      };
      holidayArray.push(tempHolidayObj);
      holidayArray.sort();
    }
    this.holidays().patchValue(holidayArray);
  }

  holidays(): FormArray {
    return this.holidayForm.get('holidays') as FormArray;
  }

  newHoliday(): FormGroup {
    return this.fb.group({
      Holiday: ['', [Validators.required]],
      Description: ['', [Validators.required]],
    });
  }

  removeHoliday(i: number) {
    this.holidays().removeAt(i);
  }

  clear(type: string) {
    this.closeModal.nativeElement.click();
    this.createForm();
    this.loadCancelledData();
    if (type === 'cancel') {
      this.isSucess.emit(false);
    } else {
      this.isSucess.emit(true);
    }
  }

  loadCancelledData() {
    this.patchEmployeeValues();
  }
  checkIfDuplicateExists(arr: any) {
    return new Set(arr).size !== arr.length;
  }
  submitHoliday() {
    let arrayControl = this.holidayForm.get('holidays') as FormArray;
    const formArrayData = arrayControl.value;
    var holidaysDate: any = [];
    holidaysDate.length = 0;
    for (var i = 0; i < formArrayData.length; i++) {
      holidaysDate.push(formArrayData[i].Holiday);
    }

    if (this.checkIfDuplicateExists(holidaysDate)) {
      var errorMessage = 'Duplicate entry for same date found.';
      return this._sweetAlert.invalidSelection('error', 'ERROR', errorMessage);
    }
    if (formArrayData.length > 0) {
      this.hds.editHolidayData(this.holidayForm.value).subscribe(
        () => {
          this.clear('not cancel');
          this.successAlert();
        },
        (_error: HttpErrorResponse) => {
          if (_error.status === 500) {
            this.badRequestAlert(_error.error);
          } else {
            this.validationError(_error.error);
          }
        }
      );
    } else {
      this.validationError(
        'You have not entered any record, Please add at least one record to save'
      );
    }
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Holiday data has been updated successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
