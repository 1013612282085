<div class="container-fluid app-box">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Table Maintenance</li>
        <li class="breadcrumb-item brd-li brd-li-child">
          Representation Group Data
        </li>
      </ol>
    </nav>
    <div class="align-self-end ml-auto">
      <button
        *ngIf="views[0]?.allowAdd"
        class="btn btn-success"
        data-toggle="modal"
        [attr.data-target]="modalId"
        data-backdrop="false"
        (click)="showAddModal()"
      >
        Add representation data
      </button>
    </div>
    <div *ngIf="repGroupSearchData !== null && repGroupSearchData.length > 0">
      <button
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        style="margin-right: 50px"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
    </div>
  </div>

  <div>
    <div class="row content-container mt-2 mb-2">
      <div class="pt-2 div-color">
        <div class="search-divider-div filtercss">
          <p class="search-divder-p"></p>
          <hr class="search-divider-hr" />
          <div class="jumbotron jumb-new jumb-newcontrols">
            <div class="row" display="float">
              <div class="col">
                <app-filter
                  [filterConfig]="filterConfig"
                  (filterValue)="getFilterValue($event)"
                  (formcontrolobj)="getbyenter($event)"
                >
                </app-filter>
              </div>
              <div class="col-md-1 mr-5">
                <label for="" style="color: white">&nbsp;</label>
                <a
                  class="btn btn-lg search-button"
                  (click)="searchRepGroupData()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          style="height: 10px; background: white; position: sticky; top: 101px"
        ></div>
        <div>
          <div>
            <!-- <div class='info-label  mb-2 divcrudbtns'>
                            <p style="text-align: left;" class="p-margin">
                                <button data-toggle="modal" class="btn btn-success buttoncolours crudbuttons  pull-right my-2 my-sm-0" [attr.data-target]="modalId" (click)="showAddModal()">Add
                                </button>
                                <button data-toggle="modal" *ngIf="showButton" class="btn btn-success buttoncolours crudbuttons  pull-right my-2 my-sm-0" [attr.data-target]="modalId" (click)="showEditModal()">Edit
                                </button>
                                <button data-toggle="modal" *ngIf="showButton" class="btn btn-success buttoncolours crudbuttons  pull-right my-2 my-sm-0" [attr.data-target]="modalId" (click)="showDeleteModal()">Delete
                                </button>


                            </p>
                        </div> -->

            <table
              class="table table-striped"
              *ngIf="
                !isDataExportableView &&
                repGroupSearchData !== null &&
                repGroupSearchData.length > 0
              "
              #representationgroupdataTablemaintenanceExportTable
            >
              <thead class="table-header">
                <tr>
                  <!-- <th scope="col">Name</th>
                                    <th scope="col">Department(s)</th>
                                    <th scope="col">Classification(s)</th>
                                    <th scope="col">Shift</th>
                                    <th scope="col">District</th>
                                    <th scope="col">Zone</th>
                                    <th scope="col">Status</th> -->

                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'representationGroupName', 'string')
                      "
                      >Name
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'representationGroupName'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'representationGroupName'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'representationGroupName'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'department', 'string')"
                      >Department(s)
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'department'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'department'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'department'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'classification', 'string')"
                      >Classification(s)
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'classification'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'classification'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'classification'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'shiftDescription', 'string')
                      "
                      >Shift
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'shiftDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'districtId', 'string')"
                      >District
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'districtId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'districtId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'districtId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'zoneId', 'string')"
                      >Zone
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'zoneId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'zoneId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'zoneId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'status', 'string')"
                      >Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'status'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'status'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>

                  <!-- <th scope="col">Edit</th>
                                    <th scope="col">Delete</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  class=""
                  *ngFor="let data of repGroupSearchData; let i = index"
                >
                  <td class="tab-value">{{ data.representationGroupName }}</td>
                  <td class="tab-value">{{ data.department }}</td>
                  <td class="left-tab-value">{{ data.classification }}</td>
                  <td class="tab-value">{{ data.shiftDescription }}</td>
                  <td class="tab-value">{{ data.districtId }}</td>
                  <td class="tab-value">{{ data.zoneId }}</td>
                  <td class="tab-value">
                    {{ data.status === 'A' ? 'Active' : 'Inactive' }}
                  </td>
                  <!-- <td class="tab-value">
                                        <button *ngIf='views[0]?.allowUpdate' data-toggle="modal" [attr.data-target]="modalId" class="cursor"><img
                                                src="../../../../../assets/edit-regular.svg"
                                                (click)="showEditModal(data.representationGroupId)"></button>
                                    </td>
                                    <td class="tab-value"> <button *ngIf='views[0]?.allowDelete' data-toggle="modal" [attr.data-target]="modalId" class="cursor"><img
                                                src="../../../../../assets/delete.png"
                                                (click)="showDeleteModal(data.representationGroupId)"></button></td> -->
                </tr>
              </tbody>
            </table>

            <table
              class="table table-striped"
              *ngIf="
                repGroupSearchData !== null && repGroupSearchData.length > 0
              "
            >
              <thead class="table-header">
                <tr>
                  <!-- <th scope="col">Name</th>
                                    <th scope="col">Department(s)</th>
                                    <th scope="col">Classification(s)</th>
                                    <th scope="col">Shift</th>
                                    <th scope="col">District</th>
                                    <th scope="col">Zone</th>
                                    <th scope="col">Status</th> -->

                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'representationGroupName', 'string')
                      "
                      >Name
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'representationGroupName'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'representationGroupName'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'representationGroupName'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'department', 'string')"
                      >Department(s)
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'department'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'department'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'department'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'classification', 'string')"
                      >Classification(s)
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'classification'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'classification'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'classification'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'shiftDescription', 'string')
                      "
                      >Shift
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'shiftDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'districtId', 'string')"
                      >District
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'districtId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'districtId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'districtId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'zoneId', 'string')"
                      >Zone
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'zoneId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'zoneId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'zoneId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'status', 'string')"
                      >Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'status'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'status'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">Edit</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class=""
                  *ngFor="
                    let data of repGroupSearchData
                      | paginate
                        : {
                            id: 'joblist',
                            itemsPerPage: 20,
                            currentPage: Applicationpage,
                            totalItems: TotalApplicationCount
                          };
                    let i = index
                  "
                >
                  <td class="tab-value">{{ data.representationGroupName }}</td>
                  <td class="tab-value">{{ data.department }}</td>
                  <td class="left-tab-value">{{ data.classification }}</td>
                  <td class="tab-value">{{ data.shiftDescription }}</td>
                  <td class="tab-value">{{ data.districtId }}</td>
                  <td class="tab-value">{{ data.zoneId }}</td>
                  <td class="tab-value">
                    {{ data.status === 'A' ? 'Active' : 'Inactive' }}
                  </td>
                  <td class="tab-value">
                    <button
                      *ngIf="views[0]?.allowUpdate"
                      data-toggle="modal"
                      [attr.data-target]="modalId"
                      data-backdrop="false"
                      class="cursor"
                    >
                      <img
                        src="../../../../../assets/edit-regular.svg"
                        (click)="showEditModal(data.representationGroupId)"
                      />
                    </button>
                  </td>
                  <td class="tab-value">
                    <button
                      *ngIf="views[0]?.allowDelete"
                      data-toggle="modal"
                      [attr.data-target]="modalId"
                      data-backdrop="false"
                      class="cursor"
                    >
                      <img
                        src="../../../../../assets/delete.png"
                        (click)="showDeleteModal(data.representationGroupId)"
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="single-wrap d-flex justify-content-center"
              *ngIf="
                repGroupSearchData !== null && repGroupSearchData.length > 0
              "
            >
              <nav aria-label="Page navigation example">
                <pagination-controls
                  class="my-pagination"
                  id="joblist"
                  (pageChange)="Applicationpage = $event"
                  previousLabel=""
                  nextLabel=""
                >
                </pagination-controls>
              </nav>
            </div>
            <div *ngIf="isApplicationDataEmpty">
              <p *ngIf="isApplicationDataEmpty">No existing records Found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Add Modal -->
  <app-add-representation-group-data
    *ngIf="showAdd"
    [userName]="this.userInformation.userName"
    (isSucess)="CheckAdd($event)"
  >
  </app-add-representation-group-data>

  <!--Edit Modal-->
  <app-edit-representation-group-data
    *ngIf="showEdit"
    [userName]="this.userInformation.userName"
    [selectedRepGroup]="selectedRepGroup"
    (isSucess)="CheckEdit($event)"
  >
  </app-edit-representation-group-data>

  <!--Delete Modal-->
  <app-delete-representation-group-data
    *ngIf="showDelete"
    [selectedRepGroup]="selectedRepGroup"
    (isSucess)="CheckDelete($event)"
  >
  </app-delete-representation-group-data>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
