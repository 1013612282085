import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class ProductionToSkilledService {

  private readonly api_searchApplications = '/ProductionToSkilled178SearchApplication';
  private readonly api_searchUsers = '/SearchUsers';

  private readonly api_getClassification = '/SkilledToSkilled152GetClassifications';

  private readonly api_Add178_Path = '/ProductionToSkilled178Add';
  private readonly api_Update178Path = '/ProductionToSkilled178Update';
  private readonly api_cancelApplication = '/CancelApplication';

  appId = new Subject();
  empsignDate = new Subject();
  atid = new Subject();
  commonUtility = new CommonUtility();
  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  searchFilterApplication(formData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` + this.api_searchApplications,
      formData,
      { headers }
    );
  }

  searchFilterApplicationUser(formData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` + this.api_searchUsers,
      formData,
      { headers }
    );
  }

  getClassifications(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(`${environment.applicationsURL}` +
      this.api_getClassification,
      { headers });
  }

  Add178Application(add178: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` + this.api_Add178_Path,
      add178,
      { headers }
    );
  }

  Edit178Application(app178: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.put<any>(
      `${environment.applicationsURL}` + this.api_Update178Path,
      app178,
      { headers }
    );
  }

  Cancel178Application(obj: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.applicationsURL}` + this.api_cancelApplication,
      obj,
      { headers }
    );
  }
}
