import { Component } from '@angular/core';

@Component({
  selector: 'app-overtime-view',
  templateUrl: './overtime-view.component.html',
  styleUrls: ['./overtime-view.component.css'],
})
export class OvertimeViewComponent {
  isView: boolean = true;
  viewName: string = 'OVERTIMEVIEW';
  moduleName: string = 'VIEWS';
  constructor() {}
}
