import { KeyValue } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

export interface DropDownSearchModel {
  dropDownValue: string;
}
@Component({
  selector: 'gyansys-input',
  templateUrl: './input.component.html',
  // styleUrls: ['./department-search.component.css'],
})
export class InputBoxComponent implements OnChanges {
  @Input() Parent: FormGroup | any;
  @Input() SectionName: any;
  @Output() EventEmitToParent = new EventEmitter();
  @Input() SourceData: any;
  @Input() DeptDefVal: any;
  //@Input() InputSearchForm: FormGroup | any;
  @Input() txtBoxValue: any;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    this.Notifyparent();
  }
  onKeyUp(val: any) {
    this.Notifyparent();
  }

  onChanges(): void {}

  ChildDepartmentEventHandler() {
    let txtBox: KeyValue<any, any> = {
      key: this.SectionName,
      value: this.txtBoxValue,
    };
    this.EventEmitToParent.emit(txtBox);
  }
  Notifyparent() {
    let txtBox: KeyValue<any, any> = {
      key: this.SectionName,
      value: this.txtBoxValue,
    };
    this.EventEmitToParent.emit(this.txtBoxValue);
  }
}
