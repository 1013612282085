import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShiftDataService } from 'src/app/Services/TableMaintenance/ShiftData/shift-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-shift-data',
  templateUrl: './update-shift-data.component.html',
  styleUrls: ['./update-shift-data.component.css'],
})
export class UpdateShiftDataComponent {
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;
  @Input() userName: any;
  @Input() selectedShift: any;
  @Output() isSucess = new EventEmitter();
  shiftForm!: FormGroup;
  shiftSelected: any;
  Status: any[] = [
    {
      data: [
        {
          statusType: 'A',
          statusName: 'Active',
        },
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
      ],
      default: 'Select One',
    },
  ];
  constructor(
    private fb: FormBuilder,
    private shiftDataService: ShiftDataService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.createForm();
    this.loadData(changes);
  }

  createForm() {
    this.shiftForm = this.fb.group({
      SHIFTID: ['', [Validators.required]],
      SHIFTDESCRIPTION: ['', Validators.required],
      STATUS: ['', [Validators.required]],
      LASTUPDATEBY: [this.userName],
    });
  }

  loadData(changes: SimpleChanges) {
    this.shiftSelected =
      (changes.selectedShift && changes.selectedShift.currentValue) || '';
    if (this.shiftSelected === '') {
      this.shiftSelected =
        (changes.selectedShift && changes.selectedShift.previousValue) || '';
    }
    this.shiftForm.get('SHIFTID')?.patchValue(this.shiftSelected.shiftid);
    this.shiftForm
      .get('SHIFTDESCRIPTION')
      ?.patchValue(this.shiftSelected.shiftdescription);
    this.shiftForm.get('STATUS')?.patchValue(this.shiftSelected.status);
  }
  submitShift() {
    this.shiftDataService.editShiftData(this.shiftForm.value).subscribe(
      () => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 500) {
          this.badRequestAlert(_error.error);
        } else {
          this.validationError(_error.error);
        }
      }
    );
  }
  clear() {
    this.closeModal.nativeElement.click();
    this.createForm();
    this.loadCancelledData();
  }
  loadCancelledData() {
    this.shiftForm.get('SHIFTID')?.patchValue(this.shiftSelected.shiftid);
    this.shiftForm
      .get('SHIFTDESCRIPTION')
      ?.patchValue(this.shiftSelected.shiftdescription);
    this.shiftForm.get('STATUS')?.patchValue(this.shiftSelected.status);
  }
  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Record has been updated successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }
  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
