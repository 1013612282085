import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToolAndDiePlantService } from 'src/app/Services/Applications/ToolAndDie/tool-and-die-plant.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';

@Component({
  selector: 'app-delete-paragraph19-application',
  templateUrl: './delete-paragraph19-application.component.html',
  styleUrls: ['./delete-paragraph19-application.component.css'],
})
export class DeleteParagraph19ApplicationComponent {
  @Input() selectedEmp: any;
  appId: any;
  @Output() isSucess = new EventEmitter();

  constructor(
    private td: ToolAndDiePlantService,
    private _sweetAlertService: SweetAlertService
  ) {}

  confirmDelete() {
    if (this.selectedEmp) {
      this.appId = this.selectedEmp.appId;
      this.td.Delete150Application(this.appId).subscribe((res) => {
        this._sweetAlertService.successAlert(
          'center',
          'success',
          'The Application has been Deleted Successfully.',
          false,
          1500
        );
        this.isSucess.emit(true);
      });
    }
  }

  clear() {
    //TODO
  }
}
