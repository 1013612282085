<div class="container-fluid app-box">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Views</li>
        <li class="breadcrumb-item brd-li brd-li-child">View Seniority</li>
      </ol>
    </nav>
    <div
      style="float: right; position: absolute; right: 276px"
      *ngIf="
        seniorityViewSearchData !== null && seniorityViewSearchData.length > 0
      "
    >
      <button
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        style="height: 34px; margin-left: 15px"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
    </div>
  </div>

  <div>
    <div class="row content-container mt-2 mb-2">
      <div class="pt-2 div-color">
        <div class="search-divider-div filtercss">
          <p class="search-divder-p"></p>
          <hr class="search-divider-hr" />
          <div class="jumbotron jumb-new jumb-newcontrols">
            <div class="row" display="float">
              <div class="col">
                <app-filter
                  [filterConfig]="filterConfig"
                  (filterValue)="getFilterValue($event)"
                >
                </app-filter>
              </div>
              <div class="col-md-1 mr-5">
                <a
                  class="btn btn-lg search-button"
                  (click)="searchSeniority()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>

          <!-- Button Start-->
          <!-- <div style="margin-top: 0px;
                    margin-bottom: 0px;
                    height: 54px;
                    padding-top: 8px;
                    background-color: white;
                    position: sticky;
                    top: 97px;
                    padding-bottom: 10px;"
                        *ngIf="seniorityViewSearchData !==null && seniorityViewSearchData   .length > 0">
                        <button style="margin-right: 0.5rem;border-color: #003AA0;"
                            class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
                            (click)="exportElmToExcel()">Export to Excel</button>
                    </div> -->
          <!-- Button End-->
        </div>
        <div class="container-divider-div"></div>
        <div style="margin-top: 5px">
          <div>
            <table
              class="table table-striped"
              *ngIf="
                seniorityViewSearchData !== null &&
                seniorityViewSearchData.length > 0
              "
              #SeniorityViewExportTable
            >
              <thead class="table-header position">
                <tr>
                  <th scope="col" style="width: 170px">
                    <span (click)="onSortClick($event, 'lastName', 'string')"
                      >Name
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'lastName'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'lastName'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'lastName'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>

                  <th scope="col">
                    <span (click)="onSortClick($event, 'employeeId', 'string')"
                      >Emp Id
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'employeeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'employeeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'employeeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'jobCodeId', 'string')"
                      >Class
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'jobCodeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'jobCodeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'jobCodeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'dept', 'string')"
                      >Dept
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'dept'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'dept'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'dept'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'shiftDescription', 'string')
                      "
                      >Shift
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'shiftDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'plant_Sen_Date', 'string')"
                      >Plant Sen Date
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'plant_Sen_Date'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'plant_Sen_Date'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'plant_Sen_Date'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'gM_Sen_Date', 'string')"
                      >GM Sen Date
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'gM_Sen_Date'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'gM_Sen_Date'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'gM_Sen_Date'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'plant_Skill_Date', 'string')
                      "
                      >Plant Skill Date
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'plant_Skill_Date'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'plant_Skill_Date'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'plant_Skill_Date'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'gM_Skill_Date', 'string')"
                      >GM Skill Date
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'gM_Skill_Date'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'gM_Skill_Date'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'gM_Skill_Date'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'empCategory', 'string')"
                      >Emp Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'empCategory'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'empCategory'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'empCategory'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <!-- <th scope="col" style="width:140px">Name</th>
                                    <th scope="col">Employee Id</th>
                                    <th scope="col">Classification</th>
                                    <th scope="col">Department</th>
                                    <th scope="col">Shift</th>
                                    <th scope="col">Plant Seniority Date</th>
                                    <th scope="col">GM Seniority Date</th>
                                    <th scope="col">Plant Skill Date</th>
                                    <th scope="col">GM Skill Date</th>
                                    <th scope="col">Emp. Status</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  class="table-container"
                  *ngFor="let data of seniorityViewSearchData; let i = index"
                >
                  <td class="left-tab-value" style="width: 170px">
                    {{ data.lastName ? data.lastName : "N/A" }},
                    {{ data.firstName ? data.firstName : "N/A" }}&nbsp;{{
                      data.middle ? data.middle : ""
                    }}
                  </td>
                  <td class="tab-value">
                    {{ data.employeeId ? data.employeeId : "-" }}
                  </td>
                  <td class="tab-value">
                    {{ data.jobCodeId ? data.jobCodeId : "-" }}
                  </td>
                  <td class="tab-value">
                    {{ data.dept ? data.dept : "-" }} -
                    {{ data.deptDescription ? data.deptDescription : "-" }}
                  </td>
                  <td class="tab-value">
                    {{ data.shiftDescription ? data.shiftDescription : "-" }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.plant_Sen_Date !== "0001-01-01T00:00:00"
                        ? (data.plant_Sen_Date | date : "MM/dd/yyyy")
                        : "-"
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.gM_Sen_Date !== "0001-01-01T00:00:00"
                        ? (data.gM_Sen_Date | date : "MM/dd/yyyy")
                        : "-"
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.plant_Skill_Date !== "0001-01-01T00:00:00"
                        ? (data.plant_Skill_Date | date : "MM/dd/yyyy")
                        : "-"
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.gM_Skill_Date !== "0001-01-01T00:00:00"
                        ? (data.gM_Skill_Date | date : "MM/dd/yyyy")
                        : "-"
                    }}
                  </td>
                  <td class="tab-value custon-padding">
                    {{ data.empCategory ? data.empCategory : "-" }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <div class="single-wrap d-flex justify-content-center" *ngIf="seniorityViewSearchData !==null && seniorityViewSearchData.length > 0">
                            <nav aria-label="Page navigation example">
                                <pagination-controls class="my-pagination" id="joblist" (pageChange)="Applicationpage=$event" previousLabel="" nextLabel="">
                                </pagination-controls>
                            </nav>
                        </div> -->
            <div *ngIf="isApplicationDataEmpty">
              <p *ngIf="isApplicationDataEmpty">No existing records Found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
