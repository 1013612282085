import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { LocalStorageService } from 'src/app/Services/LocalStorageService/locastorage.service';
import { DemographicViewService } from 'src/app/Services/TableMaintenance/DemographicView/demographic-view.service';

@Component({
  selector: 'app-demographic-detailed',
  templateUrl: './demographic-detailed.component.html',
  styleUrls: ['./demographic-detailed.component.css'],
})
export class DemographicDetailedComponent {
  // @Input() selectedRecord: any;
  // @Input() showDetails: boolean = false;
  // @Input() title: any;
  // @Output() clean = new EventEmitter();
  // @Output() isSucess = new EventEmitter();
  // @ViewChild('closeAddModel') closeAddModal!: ElementRef;
  selectedRecord: any;
  MedicalRestriction: boolean = false;
  modalId: string = '';
  recordSelected: any;
  plantSenDate: any;
  plantSkillDate: any;
  gmSenDate: any;
  gmSkillDate: any;
  empStartDate: any;
  plantMngrName: any;
  plantMngrPhone: any;
  plantMngrCell: any;
  hasMedicalRestrictions: boolean = false;
  constructor(
    private _commonApplicationService: CommonService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private dv: DemographicViewService
  ) {
    let employeeid = this.localStorageService.getItem('employeeid');
    this.dv.searchDemographicDetails(employeeid).subscribe((res) => {
      this.recordSelected = res;
      if (this.recordSelected.flagOut) {
        this.hasMedicalRestrictions = true;
      } else {
        this.hasMedicalRestrictions = false;
      }
      this.formatSupervisorString(this.recordSelected.foreman);
      this.formatPlantMngrString(this.recordSelected.planT_MNGR);
      this.formatAreaMngrString(this.recordSelected.areA_MNGR);
      this.formatDates(
        this.recordSelected.planT_SEN_DATE,
        this.recordSelected.pLant_Skill_Date,
        this.recordSelected.gm_Sen_Date,
        this.recordSelected.gm_Skill_Date,
        this.recordSelected.emp_Start_Date
      );
    });
  }

  formatDates(
    plant_Sen_Date: any,
    pLant_Skill_Date: any,
    gm_Sen_Date: any,
    gm_Skill_Date: any,
    emp_Start_Date: any
  ) {
    this.plantSenDate = plant_Sen_Date
      ? this._commonApplicationService.formatDate(plant_Sen_Date)
      : plant_Sen_Date;
    this.plantSkillDate = pLant_Skill_Date
      ? this._commonApplicationService.formatDate(pLant_Skill_Date)
      : pLant_Skill_Date;
    this.gmSenDate = gm_Sen_Date
      ? this._commonApplicationService.formatDate(gm_Sen_Date)
      : gm_Sen_Date;
    this.gmSkillDate = gm_Skill_Date
      ? this._commonApplicationService.formatDate(gm_Skill_Date)
      : gm_Skill_Date;
    this.empStartDate = emp_Start_Date
      ? this._commonApplicationService.formatDate(emp_Start_Date)
      : emp_Start_Date;
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   this.loadEmployeeData(changes);
  // }

  // loadEmployeeData(changes: SimpleChanges) {
  //   this.recordSelected =
  //     (changes.selectedRecord && changes.selectedRecord.currentValue) || '';
  //   if (this.recordSelected === '') {
  //     this.recordSelected =
  //       (changes.selectedRecord && changes.selectedRecord.previousValue) || '';
  //   console.log(this.recordSelected);
  //   this.plantSenDate= this._commonApplicationService.formatDate(this.recordSelected.plant_Sen_Date);
  //   this.plantSkillDate= this._commonApplicationService.formatDate(this.recordSelected.pLant_Skill_Date);
  //   this.gmSenDate= this._commonApplicationService.formatDate(this.recordSelected.gm_Sen_Date);
  //   this.gmSkillDate= this._commonApplicationService.formatDate(this.recordSelected.gm_Skill_Date);
  //   this.empStartDate= this._commonApplicationService.formatDate(this.recordSelected.emp_Start_Date);
  //   console.log(this.recordSelected.planT_MNGR);
  //   this.formatString(this.recordSelected.planT_MNGR);
  //   }
  // }
  supervisorName: any;
  supervisorPhone: any;
  supervisorCell: any;
  formatSupervisorString(value: any) {
    let val = value;
    let array = val.split('|');
    this.supervisorName = array[0];
    this.supervisorPhone = array[1];
    this.supervisorCell = array[2];
  }
  formatPlantMngrString(value: any) {
    var val = value;
    let array = val.split('|');
    this.plantMngrName = array[0];
    this.plantMngrPhone = array[1];
    this.plantMngrCell = array[2];
  }
  AreaMngrName: any;
  AreaMngrPhone: any;
  AreaMngrCell: any;
  formatAreaMngrString(value: any) {
    var val = value;
    let array = val.split('|');
    this.AreaMngrName = array[0];
    this.AreaMngrPhone = array[1];
    this.AreaMngrCell = array[2];
  }
  EmployeeIdPassData: any;
  ViewMedicalRestrictions(employeeid: any) {
    this.EmployeeIdPassData = employeeid;
    this.MedicalRestriction = true;
    this.modalId = '#ViewEmployeeMedicalRestriction';
  }
  medicalRestriction(e: any) {}

  MainNavigate() {
    this.router.navigate(['DemographicView']);
  }
}
