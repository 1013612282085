import { HttpErrorResponse } from '@angular/common/http';
import {
  ElementRef,
  EventEmitter,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { TransferAgreementService } from 'src/app/Services/Applications/TransferAgreementService/transfer-agreement.service';
import { DateService } from 'src/app/Services/Date/date.service';
import { RuleEngineService } from 'src/app/Services/Ruleengine/rule-engine.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';

@Component({
  selector: 'app-edit-transfer-agreement',
  templateUrl: './edit-transfer-agreement.component.html',
  styleUrls: ['./edit-transfer-agreement.component.css'],
})
export class EditTransferAgreementComponent implements OnInit {
  @Input() selectedEmp: any;
  @Input() userInformation: any;
  @Input() isLoggedInUserAdmin = false;
  @Input() isEdit: boolean = false;
  @Input() title: any;
  @Output() clean = new EventEmitter();
  @Output() isSucess = new EventEmitter();
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;

  employeeForm!: FormGroup;
  empSelected: any;
  usersData: any;
  SelectedPlantId: any;

  currentDate = new Date();

  currentAppStatusCodeForComparison: string = '';

  statusCodes: string[] = [];
  medicalRestrictionList: string[] = [];
  shifts: any[] = [
    {
      data: [],
      default: 'Select any shift',
    },
  ];
  plants: any[] = [
    {
      data: [],
      default: 'Select any plant',
    },
  ];
  deptbyplantid: any[] = [
    {
      data: [],
      default: 'Select any department',
    },
  ];
  classifications: any[] = [
    {
      data: [],
      default: 'Select any classification',
    },
  ];

  statusCancelled: boolean = false;
  statusClosed: boolean = false;
  invalidStatus: boolean = false;
  isExpOverride: boolean = false;

  isExpOverrideClassification: string = '';
  error: any = { isError: false, errorMessage: '' };
  lastupdater: string = '';
  CalenderBlockedAfterTodayDate: string = '';
  plantId: string = '';

  constructor(
    private fb: FormBuilder,
    private _user: UsergroupService,
    private _transferAgreementService: TransferAgreementService,
    private _ruleEngineService: RuleEngineService,
    private _sweetAlertService: SweetAlertService,
    private _commonApplicationService: CommonService,
    private _dateService: DateService
  ) {}

  ngOnInit() {
    this.getShifts();
    this.getplants();
    this.getUserInfo();
    this.getTAClassifications();
    this.getEmpStatusCodes();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.createEmpForm();
    this.loadEmployeeData(changes);
  }

  loadEmployeeData(changes: SimpleChanges) {
    this.empSelected =
      (changes.selectedEmp && changes.selectedEmp.currentValue) || '';
    if (this.empSelected === '') {
      this.empSelected =
        (changes.selectedEmp && changes.selectedEmp.previousValue) || '';
    }

    this.CalenderBlockedAfterTodayDate =
      this._commonApplicationService.formatDate(this.currentDate);
    this.employeeForm.get('AppId')?.patchValue(this.empSelected.appId);
    this.employeeForm
      .get('fullname')
      ?.patchValue(
        this.empSelected.firstName + ' ' + this.empSelected.lastName
      );
    this.employeeForm.get('JobCodeId')?.patchValue(this.empSelected.jobCodeId);
    this.employeeForm.get('aTin')?.patchValue(this.empSelected.atin);
    this.employeeForm
      .get('ATID')
      ?.patchValue(
        this._commonApplicationService.stringToUpperCase(this.empSelected.atid)
      );
    this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.empSelected.employeeId);
    this.employeeForm
      .get('AppStatusCode')
      ?.patchValue(
        this._commonApplicationService.removePendingFromEdit(
          this._commonApplicationService.removeAfterChar(
            this.empSelected.appStatusCode
          )
        )
      );
    this.employeeForm
      .get('currentStatus')
      ?.patchValue(this.empSelected.appStatusCode);
    this.employeeForm
      .get('PlantId')
      ?.patchValue(
        this._commonApplicationService.stringIn2Digits(this.empSelected.plantId)
      );
    this.employeeForm.get('ShiftId')?.patchValue(this.empSelected.shiftId);
    this.employeeForm
      .get('departmentId')
      ?.patchValue(
        this.empSelected.deptId === null ? '0' : this.empSelected.deptId
      );
    this.employeeForm
      .get('EmpSignDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          new Date(this.empSelected.empSignDate)
        )
      );
    this.employeeForm
      .get('AppStatusDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          new Date(this.empSelected.appStatusDate)
        )
      );
    this.employeeForm
      .get('LastUpdatedDate')
      ?.patchValue(this.empSelected.lastUpdateDate);
    this.employeeForm
      .get('expValidateFlag')
      ?.patchValue(
        this.empSelected.expValidateFlag === null
          ? (this.isExpOverride = true)
          : (this.isExpOverride = false)
      );
    this._transferAgreementService
      .searchFilterApplicationUser({ employeeId: this.empSelected.employeeId })
      .subscribe((data) => {
        this.usersData = data[0];
        this.employeeForm
          .get('classification')
          ?.patchValue(
            this.usersData.jobCodeId + '-' + this.usersData.jobCodeDescription
          );
        this.employeeForm
          .get('currentPlant')
          ?.patchValue(
            this.usersData.plantId + '-' + this.usersData.plantDescription
          );
        this.employeeForm
          .get('currentShift')
          ?.patchValue(this.usersData.shiftDescription);
        this.employeeForm.get('currentDept')?.patchValue(this.usersData.deptId);
      });
    this._commonApplicationService
      .getDeptbyPlantId(
        this._commonApplicationService.stringIn2Digits(this.empSelected.plantId)
      )
      .subscribe((data) => {
        this.plantId = this.empSelected.plantId;
        this.deptbyplantid[0].data = data;
      });
    this.GetMedicalRestrictions(this.empSelected.employeeId);
    this.isExpOverride = this.ifJobCodeRequiresValidation(
      this.empSelected.jobCodeId
    );
    this.statusCancelled = false;
    this.statusClosed = false;
    this.invalidStatus = false;
  }

  createEmpForm() {
    this.employeeForm = this.fb.group(
      {
        fullname: [''],
        currentPlant: [''],
        currentShift: [''],
        currentDept: [''],
        currentStatus: [''],
        AppId: ['', [Validators.required]],
        ATIN: [''],
        ATID: [''],
        EmployeeId: [''],
        AppType: ['TA'],
        classification: [''],
        JobCodeId: [''],
        AppStatusCode: ['', [Validators.required]],
        PlantId: [''],
        ShiftId: [''],
        ExpValidateFlag: [false],
        departmentId: [''],
        EmpSignDate: ['', [Validators.required]],
        AppStatusDate: ['', [Validators.required]],
        LastUpdatedBy: [
          this.userInformation.userName.toString().replace('@alsn.com', ''),
        ],
      },
      {
        validator: this._commonApplicationService.dateLessThan(
          'EmpSignDate',
          'AppStatusDate'
        ),
      }
    );
    this.isEdit = false;
  }

  getEmpStatusCodes() {
    this._ruleEngineService.getEmpStatusCode().subscribe((data) => {
      this.statusCodes = data;
    });
  }

  GetMedicalRestrictions(employeeId: string) {
    this._ruleEngineService
      .getMedicalRestrictions(employeeId)
      .subscribe((data) => {
        this.medicalRestrictionList = data;
      });
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      this.lastupdater = this.userInformation.userName;
    });
  }

  clear() {
    this.clean.emit('');

    this.closeModal.nativeElement.click();
    this.DataReset();
    this.loadApplicationDataAfterReset();
  }

  loadApplicationDataAfterReset() {
    this.employeeForm
      .get('employeeName')
      ?.patchValue(
        this.empSelected.lastName +
          ', ' +
          this.empSelected.firstName +
          ' ' +
          this.empSelected.middleName
      );
    this.employeeForm.get('AppId')?.patchValue(this.empSelected.appId);
    this.employeeForm.get('firstName')?.patchValue(this.empSelected.firstName);
    this.employeeForm.get('lastName')?.patchValue(this.empSelected.lastName);
    this.employeeForm.get('aTin')?.patchValue(this.empSelected.atin);
    this.employeeForm
      .get('ATID')
      ?.patchValue(
        this._commonApplicationService.stringToUpperCase(this.empSelected.atid)
      );
    this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.empSelected.employeeId);
    this.employeeForm
      .get('PlantId')
      ?.patchValue(
        this._commonApplicationService.stringIn2Digits(this.empSelected.plantId)
      );
    this.employeeForm.get('ShiftId')?.patchValue(this.empSelected.shiftId);
    this.employeeForm
      .get('departmentId')
      ?.patchValue(
        this.empSelected.deptId === null ? '0' : this.empSelected.deptId
      );
    this.employeeForm
      .get('AppStatusCode')
      ?.patchValue(
        this._commonApplicationService.removePendingFromEdit(
          this._commonApplicationService.removeAfterChar(
            this.empSelected.appStatusCode
          )
        )
      );
    this.employeeForm
      .get('EmpSignDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(this.empSelected.empSignDate)
      );
    this.employeeForm
      .get('AppStatusDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.empSelected.appStatusDate
        )
      );
    this._transferAgreementService
      .searchFilterApplicationUser({ employeeId: this.empSelected.employeeId })
      .subscribe((data) => {
        this.usersData = data[0];
      });
    this._commonApplicationService
      .getDeptbyPlantId(this.empSelected.plantId)
      .subscribe((data) => {
        this.plantId = this.empSelected.plantId;
        this.deptbyplantid = data;
        this.employeeForm.get('departmentId')?.patchValue(this.deptbyplantid);
      });
    this.isSucess.emit(true);
  }

  DataReset() {
    this.employeeForm.get('JobCodeId')?.setValue(this.selectedEmp);
    this.getTAClassifications();
    this.isSucess.emit(true);
  }

  UpdateApplication() {
    const jobCodes: string = this.employeeForm.controls['JobCodeId'].value;
    const ExpValidate = this.employeeForm.controls['ExpValidateFlag'].value;
    const StatusCode = this.employeeForm.controls['AppStatusCode'].value;
    var ifContainsCancelled: Boolean = false;
    // StatusCode.toUpperCase().includes('CANCELLED')
    //   ? (ifContainsCancelled = true)
    //   : (ifContainsCancelled = false);
    if (ifContainsCancelled) {
      this._sweetAlertService.validationError(
        'error',
        'You cannot enter a status of "CANCELLED"',
        'If you wish to cancel this application, please go to the "Cancel" page.'
      );
      return;
    } else {
      if (
        this.JobCodeValidationCheck.includes(jobCodes) &&
        this.isLoggedInUserAdmin &&
        !ExpValidate
      ) {
        this._sweetAlertService.invalidSelection(
          'warning',
          'Validation Required',
          ' Please select the Validate due to prerequisite required for the classification "' +
            jobCodes +
            '".'
        );
        return;
      } else {
        var dt = this.employeeForm.get('EmpSignDate')?.value;
        // if ((this._commonApplicationService.formatDate(this.empSelected.empSignDate) != this._commonApplicationService.formatDate(this.employeeForm.get('EmpSignDate')?.value)) ||
        //   (this._commonApplicationService.formatDate(this.empSelected.appStatusDate) != this._commonApplicationService.formatDate(this.employeeForm.get('AppStatusDate')?.value))) {
        //   var empDt = new Date(dt);
        //   var empSignDtInUTC = this._dateService.GetUTCDate(empDt);

        //   var appDt = this.employeeForm.get('AppStatusDate')?.value;
        //   var appStsDt = new Date(appDt);
        //   var appStatusDateInUTC = this._dateService.GetUTCDate(appStsDt);

        //   this.employeeForm.patchValue({ EmpSignDate: empSignDtInUTC });
        //   this.employeeForm.patchValue({ AppStatusDate: appStatusDateInUTC });
        // }
        this._transferAgreementService
          .TransferAgreementTACorrect(this.employeeForm.value)
          .subscribe(
            (data: any) => {
              this.clear();
              this.isSucess.emit(true);
              this._sweetAlertService.successAlert(
                'center',
                'success',
                'The Application has been Updated Successfully.',
                false,
                1500
              );
            },
            (_error: HttpErrorResponse) => {
              if (_error.status === 500) {
                this._sweetAlertService.InternalServerError500(
                  'error',
                  'ERROR',
                  'An Error occured while processing.Please try again.'
                );
              } else {
                this._sweetAlertService.validationError(
                  'error',
                  'Cannot create application',
                  'You cannot duplicate an open application'
                );
              }
            }
          );
      }
    }
  }

  ifJobCodeRequiresValidation(jobcodeId: string) {
    var checkIf: boolean = false;
    if (jobcodeId.includes('195B') || jobcodeId.includes('200B')) {
      checkIf = true;
    } else {
      checkIf = false;
    }
    return checkIf;
  }

  checkStatus(e: any) {
    const statuscode = this.employeeForm.get('AppStatusCode');

    statuscode?.valueChanges.subscribe(() => {
      statuscode?.patchValue(statuscode.value.toUpperCase(), {
        emitEvent: false,
      });
    });

    var statusEntered = e.target.value;
    if (statusEntered.toUpperCase().includes('CANCELLED')) {
      this.statusCancelled = true;
      this.statusClosed = false;
      this.invalidStatus = false;
    } else if (statusEntered.toUpperCase().includes('CLOSED')) {
      this.statusCancelled = false;
      this.statusClosed = true;
      this.invalidStatus = false;
      if (statusEntered.toUpperCase().includes('CLOSED -')) {
        //let appStatusCode = this.employeeForm.get('AppStatusCode')?.value;
        let statusCode = statusEntered.split('-')[2].trim();
        for (let i = 0; i < this.statusCodes.length; i++) {
          if (statusCode === this.statusCodes[i]) {
            this.statusClosed = false;
            this.statusCancelled = false;
            this.invalidStatus = false;
            break;
          } else {
            this.statusClosed = true;
          }
        }
      }
    } else if (
      !statusEntered.toUpperCase().includes('CLOSED') ||
      !statusEntered.toUpperCase().includes('OPEN') ||
      !statusEntered.toUpperCase().includes('CANCELLED')
    ) {
      this.statusClosed = false;
      this.statusCancelled = false;
      this.invalidStatus = true;
    }
    if (statusEntered.toUpperCase().includes('OPEN')) {
      this.statusClosed = false;
      this.invalidStatus = false;
      this.statusCancelled = false;
    }
  }
  //method to get shifts

  getShifts() {
    this._commonApplicationService.getShiftsList().subscribe((data: any) => {
      this.shifts[0].data = data;
    });
  }

  //Method to get plants
  getplants() {
    this._commonApplicationService.GetPlants('TA').subscribe((data) => {
      this.plants[0].data = data;
      this.plants[0].data.splice(0, 1);
    });
  }

  //Method to get department by plant id
  getDeptListByPlantId(event: any) {
    if (event != null) {
      this._commonApplicationService
        .getDeptbyPlantId(event.target.value)
        .subscribe((data) => {
          this.plantId = event.target.value;
          this.deptbyplantid[0].data = data;
          this.employeeForm.get('departmentId')?.patchValue('0');
        });
    }
  }

  /* Method to get Classifications for TA*/

  JobCodeValidationCheck: any[] = []; // Stores validation required jobcode classifications
  /* Method to get Classifications for TA*/
  getTAClassifications() {
    this._transferAgreementService.GetClassificationsTA().subscribe((data) => {
      this.classifications[0].data = data;
      for (var i = 0; i < data.length; i++) {
        if (this.classifications[0].data[i].override === true) {
          this.JobCodeValidationCheck.push(
            this.classifications[0].data[i].jobCodeId
          );
        }
      }
    });
  }

  /*check if classification need override check*/
  validateClassification(e: any) {
    for (var i = 0; i < this.classifications[0].data.length; i++) {
      if (
        this.classifications[0].data[i].jobCodeId === e.target.value &&
        this.classifications[0].data[i].override === true
      ) {
        this.isExpOverride = true;
        this.isExpOverrideClassification = e.target.value;
        break;
      } else {
        this.isExpOverride = false;
      }
    }
  }
}
