import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OvertimeReasonDataService } from 'src/app/Services/TableMaintenance/OvertimeReasonData/overtime-reason-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-overtime-reason-data',
  templateUrl: './edit-overtime-reason-data.component.html',
  styleUrls: ['./edit-overtime-reason-data.component.css'],
})
export class EditOvertimeReasonDataComponent implements OnChanges {
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;
  @Input() userName: any;
  @Input() selectedOvertime: any;
  @Output() isSucess = new EventEmitter();
  overtimeForm!: FormGroup;
  overtimeSelected: any;
  Status: any[] = [
    {
      data: [
        {
          statusType: 'A',
          statusName: 'Active',
        },
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
      ],
      default: 'Select One',
    },
  ];
  ReasonAssociation: any[] = [
    {
      data: [
        {
          Type: 'A',
          Name: 'Accepted',
        },
        {
          Type: 'R',
          Name: 'Refused',
        },
        {
          Type: 'F',
          Name: 'Free Form',
        },
      ],
      default: 'Select One',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private ods: OvertimeReasonDataService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.createForm();
    this.loadData(changes);
  }

  createForm() {
    this.overtimeForm = this.fb.group({
      OvertimeReasonId: ['', [Validators.required]],
      OvertimeReasonDescription: ['', Validators.required],
      TypeAssociation: ['', Validators.required],
      CommentRequired: ['1', Validators.required],
      Status: ['', [Validators.required]],
      LastUpdateBy: [this.userName],
    });
  }

  loadData(changes: SimpleChanges) {
    this.overtimeSelected =
      (changes.selectedOvertime && changes.selectedOvertime.currentValue) || '';
    if (this.overtimeSelected === '') {
      this.overtimeSelected =
        (changes.selectedOvertime && changes.selectedOvertime.previousValue) ||
        '';
    }
    this.overtimeForm
      .get('OvertimeReasonId')
      ?.patchValue(this.overtimeSelected.overtimeReasonId);
    this.overtimeForm
      .get('OvertimeReasonDescription')
      ?.patchValue(this.overtimeSelected.overtimeReasonDescription);
    this.overtimeForm
      .get('TypeAssociation')
      ?.patchValue(this.overtimeSelected.typeAssociation);
    this.overtimeForm
      .get('CommentRequired')
      ?.patchValue(this.overtimeSelected.commentRequired);
    this.overtimeForm.get('Status')?.patchValue(this.overtimeSelected.status);
  }

  submitOvertime() {
    this.ods.editOvertimeReasonData(this.overtimeForm.value).subscribe(
      () => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 500) {
          this.badRequestAlert(_error.error);
        } else {
          this.validationError(_error.error);
        }
      }
    );
  }

  clear() {
    this.closeModal.nativeElement.click();
    this.createForm();
    this.loadCancelledData();
  }

  loadCancelledData() {
    this.overtimeForm
      .get('OvertimeReasonId')
      ?.patchValue(this.overtimeSelected.overtimeReasonId);
    this.overtimeForm
      .get('OvertimeReasonDescription')
      ?.patchValue(this.overtimeSelected.overtimeReasonDescription);
    this.overtimeForm
      .get('TypeAssociation')
      ?.patchValue(this.overtimeSelected.typeAssociation);
    this.overtimeForm
      .get('CommentRequired')
      ?.patchValue(this.overtimeSelected.commentRequired);
    this.overtimeForm.get('Status')?.patchValue(this.overtimeSelected.status);
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Record has been updated successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
