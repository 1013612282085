import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { DemographicViewService } from 'src/app/Services/TableMaintenance/DemographicView/demographic-view.service';
import { RepresentationGroupDataService } from 'src/app/Services/TableMaintenance/RepresentationGroupData/representation-group-data.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { SeniorityViewService } from 'src/app/Services/Views/SeniorityView/seniority-view.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-seniority-view',
  templateUrl: './seniority-view.component.html',
  styleUrls: ['./seniority-view.component.css'],
})
export class SeniorityViewComponent implements OnInit {
  public loading: boolean = false;
  TotalApplicationCount: any;
  UserPage: number = 1;
  Applicationpage: number = 1;
  modalId: string = '';
  filterData: any = {};
  seniorityViewSearchData: any[] = [];
  userInformation: any;
  views: any[] = [];
  classifications: any[] = [];
  isApplicationDataEmpty: boolean = false;
  filterConfig: any = [
    {
      label: 'Classification*',
      type: 'dropdown',
      data: [],
      default: 'Select',
      controlName: 'ClassGroup',
      runClassification: true,
      width: '300px',
    },
    {
      label: 'Shift',
      type: 'dropdown',
      data: [],
      default: 'Select',
      controlName: 'Shift_Search',
      runClassification: true,
      width: '200px',
    },
    {
      label: 'Shift Preference Group',
      type: 'dropdown',
      data: [],
      default: 'Select',
      controlName: 'SPgroup_Search',
      runClassification: true,
    },
  ];
  constructor(
    private dv: DemographicViewService,
    private _user: UsergroupService,
    private _commonApplicationService: CommonService,
    private rds: RepresentationGroupDataService,
    private svs: SeniorityViewService
  ) {}

  ngOnInit(): void {
    this.getClassifications();
    this.getShifts();
    this.getPlantGroups();
    this.getUserInfo();
  }

  getClassifications() {
    this.svs.getSeniorityClassifications().subscribe((data: any) => {
      this.classifications = data;
      const elements = data || [];
      const mapElement = elements.map(
        (element: {
          classGroup: any;
          jobCodeDescription: any;
          classificationType: string;
        }) => ({
          id: element.classGroup,
          description: element.jobCodeDescription,
          classificationType: element.classificationType,
        })
      );

      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Classification*') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  getShifts() {
    this.rds.getShifts().subscribe((data: any) => {
      const elements = data || [];
      const mapElement = elements.map(
        (element: { shiftId: any; shiftDescription: any }) => ({
          id: element.shiftId,
          description: element.shiftDescription,
        })
      );

      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Shift') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  getPlantGroups() {
    this._commonApplicationService.GetPlantGroups().subscribe((res) => {
      const elements = res || [];
      const mapElement = elements.map(
        (element: { plantGroupId: any; plantGroupDescription: any }) => ({
          id: element.plantGroupId,
          description: element.plantGroupDescription,
        })
      );
      for (let i = 0; i < mapElement.length; i++) {
        if (mapElement[i].id === '99') {
          mapElement.splice(i, 1);
          i--;
        }
      }
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Shift Preference Group') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'VIEWS',
        'VIEWSENIORITY'
      );
    });
  }

  getFilterValue(event: any) {
    this.filterData = event;
  }

  searchSeniority() {
    this.loading = true;
    let queryParams = this.filterData;
    for (let i = 0; i < this.classifications.length; i++) {
      if (this.classifications[i].classGroup === queryParams.ClassGroup) {
        queryParams.Classification_Search =
          this.classifications[i].classification;
      }
    }
    this.svs.searchSeniority(queryParams).subscribe(
      (res) => {
        this.seniorityViewSearchData = res;
        //this.Applicationpage = 1;
        // this.TotalApplicationCount = res.length;
        this.loading = false;
      },
      (_error: HttpErrorResponse) => {
        this.loading = false;
        this.validationError(_error.error);
      }
    );
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
  @ViewChild('SeniorityViewExportTable', { read: ElementRef, static: false })
  SeniorityViewExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.SeniorityViewExportTable,
        `${ApplicationEnum.SENIORITYVIEW}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; //1= 'ASE' -1= DSC
  sortColumn = 'plant_Skill_Date';

  // onSortClick(event: any, fieldName: string, dataType: string) {
  //     this._commonApplicationService.callSortService(this.medicalRestrictionsSearchData, event, fieldName, dataType, this.sortDir, this.sortColumn);
  // }

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.seniorityViewSearchData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.seniorityViewSearchData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
