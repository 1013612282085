<div
  class="modal fade"
  id="editEmp"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addEmployeeLabel"
  aria-hidden="true"
>
  <!-- .modal-dialog removed from 10 -->
  <div
    class="modal-dialog add-form-alignment"
    role="document"
    style="max-width: 100%; margin: 0px"
  >
    <div class="modal-content modal-size" style="width: 945px">
      <div class="modal-header">
        <h5 class="modal-title" id="addEmployeeLabel">Edit application</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #closeAddModel
          (click)="clear()"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>

      <form [formGroup]="employeeForm">
        <div
          style="padding-left: 80px; padding-right: 80px; height: 450px"
          class="modal-body"
        >
          <div class="form-row">
            <div class="form-group col-md-12">
              <input
                hidden
                readonly
                type="email"
                formControlName="AppId"
                placeholder="AppId"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Employee Name:</label>
            <div class="col-sm-8">
              <input
                readonly
                type="email"
                formControlName="fullname"
                placeholder="Full Name"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Employee Id:</label>
            <div class="col-sm-8">
              <input
                readonly
                formControlName="EmployeeId"
                type="email"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Current Classification:</label
            >
            <div class="col-sm-8">
              <input
                readonly
                type="email"
                formControlName="classification"
                placeholder="Classification"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Current Plant:</label>
            <div class="col-sm-8">
              <input
                readonly
                type="email"
                formControlName="currentPlant"
                placeholder="Classification"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Current Department:</label>
            <div class="col-sm-8">
              <input
                readonly
                type="email"
                formControlName="currentDept"
                placeholder="Classification"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Current Shift:</label>
            <div class="col-sm-8">
              <input
                readonly
                type="email"
                style="height: 35px"
                formControlName="currentShift"
                placeholder="Classification"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Classification Applying For *:</label
            >
            <div class="col-sm-8">
              <select
                (change)="validateClassification($event)"
                formControlName="JobCodeId"
                style="height: 35px"
                class="form-control"
                id="exampleFormControlSelect1"
              >
                <option
                  disabled
                  *ngIf="classifications[0].default"
                  selected
                  value=""
                >
                  {{ classifications[0].default }}
                </option>
                <option
                  *ngFor="let classification of classifications[0].data"
                  value="{{ classification.jobCodeId }}"
                >
                  {{ classification.jobCodeId }}-{{
                    classification.jobCodeDescription
                  }}
                </option>
              </select>
              <div
                *ngIf="
                  employeeForm.controls['JobCodeId'].invalid &&
                  employeeForm.controls['JobCodeId'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['JobCodeId'].errors?.required"
                >
                  Classification is required.
                </small>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Plant Applying For *:</label>
            <div class="col-sm-8">
              <select
                style="height: 35px"
                formControlName="PlantId"
                class="form-control"
                (change)="getDeptListByPlantId($event)"
              >
                <option disabled *ngIf="plants[0].default" selected value="">
                  {{ plants[0].default }}
                </option>
                <option
                  *ngFor="let plant of plants[0].data"
                  value="{{ plant.plantId }}"
                >
                  {{ plant.plantId }}-{{ plant.plantDescription }}
                </option>
              </select>
              <div
                *ngIf="
                  employeeForm.controls['PlantId'].invalid &&
                  employeeForm.controls['PlantId'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['PlantId'].errors?.required"
                >
                  Plant is required.
                </small>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Department Applying For *:</label
            >
            <div class="col-sm-8">
              <select
                style="height: 35px"
                formControlName="departmentId"
                class="form-control"
              >
                <option disabled value="">Select Any department</option>
                <option value="0">
                  Any Department within - Plant - {{ plantId }}
                </option>
                <option
                  *ngFor="let dept of deptbyplantid[0].data"
                  value="{{ dept.departmentId }}"
                >
                  {{ dept.departmentId }}
                </option>
              </select>
              <div
                *ngIf="
                  employeeForm.controls['departmentId'].invalid &&
                  employeeForm.controls['departmentId'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['departmentId'].errors?.required"
                >
                  Department is required.
                </small>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Shift Applying For *:</label>
            <div class="col-sm-8">
              <select
                style="height: 35px"
                formControlName="ShiftId"
                class="form-control"
              >
                <option disabled *ngIf="shifts[0].default" selected value="">
                  {{ shifts[0].default }}
                </option>
                <option
                  *ngFor="let shift of shifts[0].data"
                  value="{{ shift.shiftId }}"
                >
                  {{ shift.shiftDescription }}
                </option>
              </select>
              <div
                *ngIf="
                  employeeForm.controls['ShiftId'].invalid &&
                  employeeForm.controls['ShiftId'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['ShiftId'].errors?.required"
                >
                  Shift is required.
                </small>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Status:</label>
            <div class="col-sm-8">
              <input
                style="height: 35px"
                readonly
                type="email"
                formControlName="currentStatus"
                placeholder="Status"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Status *:</label>
            <div class="col-sm-8">
              <input
                style="height: 35px"
                (change)="checkStatus($event)"
                (keyup)="checkStatus($event)"
                [class.is-invalid]="
                  employeeForm.get('AppStatusCode')?.invalid &&
                  employeeForm.get('AppStatusCode')?.touched
                "
                formControlName="AppStatusCode"
                class="form-control"
                id="exampleFormControlSelect2"
              />
              <small class="text-danger" *ngIf="statusCancelled"
                >You cannot enter a status of 'CANCELLED'<br />
                If you wish to cancel this application, please go to the
                'Cancel' page. <br />
                If the status was 'Cancelled', you cannot modify the application
                until the status is changed to 'OPEN' or 'CLOSED'.</small
              >
              <small class="text-danger" *ngIf="statusClosed"
                >You must enter a valid status to close the application ('CLOSED
                - REASON - XXX').</small
              >
              <small class="text-danger" *ngIf="invalidStatus"
                >You must enter a valid status ('OPEN' or
                'CLOSED-REASON-XXX').</small
              >
              <div
                *ngIf="
                  employeeForm.controls['AppStatusCode'].invalid &&
                  employeeForm.controls['AppStatusCode'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="
                    employeeForm.controls['AppStatusCode'].errors?.required
                  "
                >
                  Status is required.
                </small>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label
              for="EmpSignDate"
              name="datmax"
              class="col-sm-4 col-form-label"
              >Employee Signature Date *</label
            >
            <div class="col-sm-8">
              <input
                formControlName="EmpSignDate"
                style="height: 35px"
                type="date"
                id="EmpSignDate"
                name="EmpSignDate"
                class="form-control"
                max="{{ CalenderBlockedAfterTodayDate }}"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="datmax" class="col-sm-4 col-form-label"
              >Date of Status *:</label
            >
            <div class="col-sm-8">
              <input
                formControlName="AppStatusDate"
                style="height: 35px"
                type="date"
                id="datmax"
                name="datmax"
                class="form-control"
                max="{{ CalenderBlockedAfterTodayDate }}"
              />
            </div>
            <div
              *ngIf="
                employeeForm.controls['AppStatusDate'].invalid &&
                employeeForm.controls['AppStatusDate'].touched
              "
            >
              <small
                class="text-danger"
                *ngIf="employeeForm.controls['AppStatusDate'].errors?.required"
              >
                Status date is required.
              </small>
            </div>

            <label class="alert alert-danger" *ngIf="employeeForm.errors">{{
              employeeForm.errors.dates
            }}</label>
          </div>

          <div class="text-danger" *ngIf="medicalRestrictionList.length > 0">
            <h6>WARNING: This employee has a current medical restriction</h6>
            <ul>
              <li *ngFor="let medicalRestriction of medicalRestrictionList">
                {{ medicalRestriction }}
              </li>
            </ul>
          </div>

          <div *ngIf="isExpOverride" class="form-group row">
            <label class="col-sm-4 col-form-label">Validate:</label>
            <div class="col-sm-8">
              <input
                formControlName="ExpValidateFlag"
                type="checkbox"
                class="form-control checkbox-label"
              />
            </div>
            <div
              *ngIf="
                employeeForm.controls['ExpValidateFlag'].invalid &&
                employeeForm.controls['ExpValidateFlag'].touched
              "
            >
              <small
                class="text-danger"
                *ngIf="
                  employeeForm.controls['ExpValidateFlag'].errors?.required
                "
              >
                You must provide an override due to prerequisite  required for
                the classification "{{ isExpOverrideClassification }}".
              </small>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-4">
              Entered By:
              {{
                empSelected.enteredBy
                  ? (empSelected.enteredBy | uppercase)
                  : '-'
              }}
            </div>
            <div class="col-sm-4">
              Date Entered :
              {{
                empSelected.dateEntered
                  ? (empSelected.dateEntered | date: 'MM/dd/yyyy')
                  : '-'
              }}
            </div>
            <div class="col-sm-4">
              Last Update By:
              {{
                empSelected.lastUpdateBy
                  ? (empSelected.lastUpdateBy | uppercase)
                  : '-'
              }}
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            style="
              height: 40px;
              width: 150px;
              border-radius: 10px;
              margin-right: 20px;
            "
            type="button"
            class="btn btn-secondary popupbuttonscancel"
            data-dismiss="modal"
            #closeCorrectModel
            (click)="clear()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            style="height: 40px; width: 150px; border-radius: 10px"
            [disabled]="
              employeeForm.errors ||
              statusCancelled ||
              statusClosed ||
              employeeForm.invalid ||
              invalidStatus
            "
            (click)="UpdateApplication()"
          >
            Update
          </button>
        </div>
      </form>

      <!-- <div class="modal-footer" style="padding-right: 80px">

      </div> -->
    </div>
  </div>
</div>
