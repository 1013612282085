import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TransferAgreementService } from 'src/app/Services/Applications/TransferAgreementService/transfer-agreement.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';

@Component({
  selector: 'app-delete-transfer-agreement',
  templateUrl: './delete-transfer-agreement.component.html',
  styleUrls: ['./delete-transfer-agreement.component.css'],
})
export class DeleteTransferAgreementComponent {
  @Input() selectedEmp: any;
  @Input() employeeForm: any;
  appId: any;
  @Output() isSucess = new EventEmitter();
  constructor(
    private _transferAgreementService: TransferAgreementService,
    private _sweetAlertService: SweetAlertService
  ) {}

  DeleteApplication() {
    if (this.selectedEmp) {
      this.appId = this.selectedEmp.appId;
      this._transferAgreementService
        .TransferAgreementTADelete(this.appId)
        .subscribe((res) => {
          this._sweetAlertService.successAlert(
            'center',
            'success',
            'The Application has been Deleted Successfully.',
            false,
            1500
          );
          this.isSucess.emit(true);
        });
    }
  }

  clear() {
    //TODO
  }
}
