<div class="container-fluid app-box" style="background-color: #ffffff">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">UAWCAS</li>
        <li class="breadcrumb-item brd-li">Overtime</li>
        <li class="breadcrumb-item brd-li-child">Overtime Maintenance</li>
        <li class="breadcrumb-item brd-li-child">Detail Search</li>
      </ol>
    </nav>
  </div>

  <div>
    <div class="row content-container mt-2 mb-2">
      <nav class="navbar navbar-expand-lg navbar-light bg-light applicationcss">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto"></ul>
          <form class="my-2 my-lg-0 frmOtDt">
            <div style="float: left">
              <label class="select-label" for="1">Overtime Detail </label>
            </div>
            <div class="text-right">
              <div *ngIf="isView === 'N'">
                <input
                  type="button"
                  class="backbutton"
                  value="Back to Main Search"
                  (click)="MainNavigate()"
                />&nbsp;
                <input
                  *ngIf="views[0]?.allowReassign"
                  type="button"
                  class="backbutton"
                  value="Reassign Overtime Group"
                  (click)="ReassginOvertimeNaviagte()"
                  data-toggle="modal"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                />&nbsp;
                <input
                  *ngIf="views[0]?.allowReassign"
                  type="button"
                  class="backbutton"
                  value="Reassign Representation Group"
                  (click)="OvertimeReassignRepGroupNavigate()"
                  data-toggle="modal"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                />&nbsp;
                <input
                  *ngIf="views[0]?.allowReassign"
                  type="button"
                  class="backbutton"
                  (click)="archiveAll()"
                  value="Archive All"
                  data-toggle="modal"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                />&nbsp;
              </div>
              <div *ngIf="isView === 'Y'">
                <input
                  type="button"
                  class="backbutton"
                  value="Back to Main Search"
                  (click)="MainNavigate()"
                />&nbsp;
              </div>
            </div>
          </form>
        </div>
      </nav>
      <div class="div-color">
        <div>
          <table>
            <th><b>Employee Id</b></th>
            <th><b>Name</b></th>
            <th><b>Department</b></th>
            <th><b>Classification</b></th>
            <th><b>Shift</b></th>
            <th><b>Acc. Hours</b></th>
            <th><b>OT Group</b></th>
            <th><b>Rep. Group</b></th>
            <tr>
              <td>{{ demographData?.employeeid }}</td>
              <td>
                {{
                  demographData?.lasT_NAME ? demographData?.lasT_NAME : 'N/A'
                }},&nbsp;{{
                  demographData?.firsT_NAME ? demographData?.firsT_NAME : 'N/A'
                }}&nbsp;{{ demographData?.middle ? demographData?.middle : '' }}
              </td>
              <td>
                {{ demographData?.dept }} - {{ demographData?.dptdescription }}
              </td>
              <td>
                {{ demographData?.jobcodeid }} -
                {{ demographData?.jobcodedescription }}
              </td>
              <td>{{ demographData?.shiftdescription }}</td>
              <td>{{ accHours | number: '1.2-2' }}</td>
              <td>{{ demographData?.overtimegrpname }}</td>
              <td>{{ demographData?.representationgrpname }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- global search filter starts -->
    </div>
  </div>

  <div>
    <div>
      <div>
        <div class="row content-container mt-2 mb-2">
          <div class="search-divider-div filtercss" style="top: 8px !important">
            <p class="search-divder-p">
              <!-- filer -->
            </p>

            <div class="jumbotron jumb-new jumb-newcontrols">
              <div class="row" display="float">
                <div class="col">
                  <form (ngSubmit)="submitForm()">
                    <div class="form-row">
                      <div class="form-group col-md-11">
                        <app-common-search
                          [SectionOverTimeStatusbool]="true"
                          [DefaultOverTimeTypeVal]="DefaultOverTimeTypeOvt"
                          [DefaultOverTimeStatusVal]="DefaultOverTimeStatusOvt"
                          [DefaultStartDateVal]="DefaultStartDateOvt"
                          [DefaultEndDateVal]="DefaultEndDateOvt"
                          [SectionDateRangebool]="true"
                          [SectionOverTimeTypeBool]="true"
                          (AnyEventEmitter)="ModelRepopulateHandler($event)"
                        >
                        </app-common-search>
                      </div>
                      <div class="form-group col-md-1">
                        <button
                          class="btn btn-lg search-button"
                          [disabled]="!disableSubmit"
                          type="submit"
                          (click)="submitForm()"
                        >
                          Search
                          <img
                            src="./assets/search-solid.svg"
                            class="img-fluid user-icon ml-1 search-icon"
                          />
                        </button>
                      </div>
                    </div>
                  </form>
                  <!-- <hr class="my-4"> -->
                </div>
              </div>
            </div>

            <!-- filter ends -->
          </div>
          <div
            *ngIf="!isOvertimeDataEmpty"
            style="
              position: sticky;
              height: 45px;
              top: 96px !important;
              background-color: white;
              padding: 5px;
            "
          >
            <div *ngIf="isView === 'Y'"></div>
            <div *ngIf="isView === 'N'">
              <input
                type="button"
                [disabled]="!enableIndButtons"
                [ngClass]="!enableIndButtons ? 'disablededitcolors' : ''"
                value="Update"
                (click)="onIndOvertimeUpdate()"
                data-toggle="modal"
                [attr.data-target]="modalId"
                data-backdrop="false"
                class="btn btn-success buttoncolours crudbuttons pull-right my-2 my-sm-0"
              />&nbsp;
              <input
                *ngIf="views[0]?.allowDelete"
                type="button"
                [disabled]="!enableIndButtons"
                [ngClass]="!enableIndButtons ? 'disablededitcolors' : ''"
                (click)="delete()"
                value="Delete"
                data-toggle="modal"
                [attr.data-target]="modalId"
                data-backdrop="false"
                class="btn btn-success buttoncolours crudbuttons pull-right my-2 my-sm-0"
              />&nbsp;
              <input
                *ngIf="userData !== null && userData.length > 0"
                type="button"
                value="Export To Excel"
                (click)="exportElmToExcel()"
                style="margin: 5px"
                class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
              />
            </div>
            <!-- <input *ngIf="roleService.roleMatch(['HRPLUS'])" type="button" (click)="archiveAll()" value="Archive All" data-toggle="modal" [attr.data-target]="modalId"
              data-backdrop="false" class="btn btn-success buttoncolours crudbuttons pull-right my-2 my-sm-0">&nbsp; -->
          </div>

          <div class="reulsttbl">
            <!--For user-->
            <div>
              <!-- this is export to excel table  -->
              <table
                class="table table-striped"
                *ngIf="
                  !isDataExportableView &&
                  userData !== null &&
                  userData.length > 0
                "
                #OvertimeTableDetailsExport
              >
                <thead class="table-header">
                  <tr>
                    <th scope="col">No.</th>
                    <th scope="col">
                      <span
                        (click)="onSortClick($event, 'dateofwork', 'string')"
                        >Overtime Date
                        <img
                          *ngIf="sortDir === 1 && sortColumn === 'dateofwork'"
                          src="./assets/downarrow.png"
                        />
                        <img
                          *ngIf="sortDir === -1 && sortColumn === 'dateofwork'"
                          src="./assets/uparrow.png"
                        />
                        <img
                          *ngIf="sortDir !== 0 && sortColumn !== 'dateofwork'"
                          src="./assets/nosort.png"
                        />
                      </span>
                    </th>
                    <th scope="col">
                      <span
                        (click)="
                          onSortClick($event, 'overtimegroupid', 'string')
                        "
                        >Overtime Group
                        <img
                          *ngIf="
                            sortDir === 1 && sortColumn === 'overtimegroupid'
                          "
                          src="./assets/downarrow.png"
                        />
                        <img
                          *ngIf="
                            sortDir === -1 && sortColumn === 'overtimegroupid'
                          "
                          src="./assets/uparrow.png"
                        />
                        <img
                          *ngIf="
                            sortDir !== 0 && sortColumn !== 'overtimegroupid'
                          "
                          src="./assets/nosort.png"
                        />
                      </span>
                    </th>
                    <th scope="col">
                      <span (click)="onSortClick($event, 'paidhours', 'string')"
                        >Paid Hours
                        <img
                          *ngIf="sortDir === 1 && sortColumn === 'paidhours'"
                          src="./assets/downarrow.png"
                        />
                        <img
                          *ngIf="sortDir === -1 && sortColumn === 'paidhours'"
                          src="./assets/uparrow.png"
                        />
                        <img
                          *ngIf="sortDir !== 0 && sortColumn !== 'paidhours'"
                          src="./assets/nosort.png"
                        />
                      </span>
                    </th>
                    <th scope="col">
                      <span
                        (click)="
                          onSortClick(
                            $event,
                            'overtimetypedescription',
                            'string'
                          )
                        "
                        >Overtime Type
                        <img
                          *ngIf="
                            sortDir === 1 &&
                            sortColumn === 'overtimetypedescription'
                          "
                          src="./assets/downarrow.png"
                        />
                        <img
                          *ngIf="
                            sortDir === -1 &&
                            sortColumn === 'overtimetypedescription'
                          "
                          src="./assets/uparrow.png"
                        />
                        <img
                          *ngIf="
                            sortDir !== 0 &&
                            sortColumn !== 'overtimetypedescription'
                          "
                          src="./assets/nosort.png"
                        />
                      </span>
                    </th>
                    <th scope="col">
                      <span
                        (click)="
                          onSortClick(
                            $event,
                            'overtimereasondescription',
                            'string'
                          )
                        "
                        >Overtime Reason
                        <img
                          *ngIf="
                            sortDir === 1 &&
                            sortColumn === 'overtimereasondescription'
                          "
                          src="./assets/downarrow.png"
                        />
                        <img
                          *ngIf="
                            sortDir === -1 &&
                            sortColumn === 'overtimereasondescription'
                          "
                          src="./assets/uparrow.png"
                        />
                        <img
                          *ngIf="
                            sortDir !== 0 &&
                            sortColumn !== 'overtimereasondescription'
                          "
                          src="./assets/nosort.png"
                        />
                      </span>
                    </th>
                    <th scope="col">
                      <span
                        (click)="
                          onSortClick($event, 'freeformcomment', 'string')
                        "
                        >Comments
                        <img
                          *ngIf="
                            sortDir === 1 && sortColumn === 'freeformcomment'
                          "
                          src="./assets/downarrow.png"
                        />
                        <img
                          *ngIf="
                            sortDir === -1 && sortColumn === 'freeformcomment'
                          "
                          src="./assets/uparrow.png"
                        />
                        <img
                          *ngIf="
                            sortDir !== 0 && sortColumn !== 'freeformcomment'
                          "
                          src="./assets/nosort.png"
                        />
                      </span>
                    </th>
                    <th scope="col">
                      <span (click)="onSortClick($event, 'status', 'string')"
                        >Overtime Status
                        <img
                          *ngIf="sortDir === 1 && sortColumn === 'status'"
                          src="./assets/downarrow.png"
                        />
                        <img
                          *ngIf="sortDir === -1 && sortColumn === 'status'"
                          src="./assets/uparrow.png"
                        />
                        <img
                          *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                          src="./assets/nosort.png"
                        />
                      </span>
                    </th>
                    <th scope="col">
                      <span
                        (click)="onSortClick($event, 'dateoffered', 'string')"
                        >Date Offered
                        <img
                          *ngIf="sortDir === 1 && sortColumn === 'dateoffered'"
                          src="./assets/downarrow.png"
                        />
                        <img
                          *ngIf="sortDir === -1 && sortColumn === 'dateoffered'"
                          src="./assets/uparrow.png"
                        />
                        <img
                          *ngIf="sortDir !== 0 && sortColumn !== 'dateoffered'"
                          src="./assets/nosort.png"
                        />
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let val of userData; let i = index"
                    class="table-container"
                  >
                    <!-- <td class='tab-value'>
                                            <input type="Radio" class="radiotbl" name="val.employeeid" value="Details" (change)=onIndEmployeeSelect(i,val)>
                                        </td> -->
                    <td class="tab-value">{{ i + 1 }}</td>
                    <td class="tab-value">
                      {{ val.dateofwork ? val.dateofwork : '-' }}
                    </td>
                    <td class="tab-value">
                      {{ val.overtimegroupid ? val.overtimegroupname : '-' }}
                    </td>
                    <td class="tab-value">
                      {{ val.paidhours | number: '1.2-2' }}
                    </td>
                    <td class="left-tab-value">
                      {{
                        val.overtimetypedescription
                          ? val.overtimetypedescription
                          : '-'
                      }}
                    </td>
                    <td class="tab-value">
                      {{
                        val.overtimereasondescription
                          ? val.overtimereasondescription
                          : '-'
                      }}
                    </td>
                    <td class="tab-value">
                      {{ val.freeformcomment ? val.freeformcomment : '-' }}
                    </td>
                    <td class="tab-value">
                      {{ val.status === 'A' ? 'Active' : 'Archived' }}
                    </td>
                    <td class="tab-value">
                      {{ val.dateoffered ? val.dateoffered : '-' }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- this is main table  -->
              <table
                class="table table-striped"
                *ngIf="userData !== null && userData.length > 0"
              >
                <thead class="table-header">
                  <th scope="col">Select</th>
                  <th scope="col">No.</th>
                  <!--<th scope="col">Overtime Date</th>
                                    <th scope="col">Overtime Group</th>
                                    <th scope="col">Paid Hours</th>
                                    <th scope="col">Overtime Type</th>
                                    <th scope="col">Overtime Reason</th>
                                    <th scope="col">Comments</th>
                                    <th scope="col">Overtime Status</th>
                                    <th scope="col">Date Offered</th> -->

                  <th scope="col">
                    <span (click)="onSortClick($event, 'dateofwork', 'string')"
                      >Overtime Date
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'dateofwork'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'dateofwork'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'dateofwork'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'overtimegroupid', 'string')"
                      >Overtime Group
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'overtimegroupid'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'overtimegroupid'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'overtimegroupid'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'paidhours', 'string')"
                      >Paid Hours
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'paidhours'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'paidhours'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'paidhours'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'overtimetypedescription', 'string')
                      "
                      >Overtime Type
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'overtimetypedescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'overtimetypedescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'overtimetypedescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick(
                          $event,
                          'overtimereasondescription',
                          'string'
                        )
                      "
                      >Overtime Reason
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'overtimereasondescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'overtimereasondescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'overtimereasondescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'freeformcomment', 'string')"
                      >Comments
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'freeformcomment'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'freeformcomment'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'freeformcomment'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'status', 'string')"
                      >Overtime Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'status'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'status'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'dateoffered', 'string')"
                      >Date Offered
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'dateoffered'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'dateoffered'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'dateoffered'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let val of userData; let i = index"
                    class="table-container"
                  >
                    <td class="tab-value">
                      <input
                        type="Radio"
                        class="radiotbl"
                        name="val.employeeid"
                        value="Details"
                        (change)="onIndEmployeeSelect(i, val)"
                      />
                    </td>
                    <td class="tab-value">{{ i + 1 }}</td>
                    <td class="tab-value">
                      {{ val.dateofwork ? val.dateofwork : '-' }}
                    </td>
                    <td class="tab-value">
                      {{ val.overtimegroupid ? val.overtimegroupname : '-' }}
                    </td>
                    <td class="tab-value">
                      {{ val.paidhours | number: '1.2-2' }}
                    </td>
                    <td class="left-tab-value">
                      {{
                        val.overtimetypedescription
                          ? val.overtimetypedescription
                          : '-'
                      }}
                    </td>
                    <td class="tab-value">
                      {{
                        val.overtimereasondescription
                          ? val.overtimereasondescription
                          : '-'
                      }}
                    </td>
                    <td class="tab-value">
                      {{ val.freeformcomment ? val.freeformcomment : '-' }}
                    </td>
                    <td class="tab-value">
                      {{ val.status === 'A' ? 'Active' : 'Archived' }}
                    </td>
                    <td class="tab-value">
                      {{ val.dateoffered ? val.dateoffered : '-' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <p
                *ngIf="
                  isSearchHit && (userData === null || userData.length === 0)
                "
                class="noDataMsg"
              >
                No Records Found
              </p>
            </div>
          </div>
        </div>
      </div>

      <app-delete-overtimedetail
        [selectedEmp]="selectedEmp"
        (isSucess)="CheckDelete($event)"
      >
      </app-delete-overtimedetail>

      <app-mass-update-overtime-maintenance
        [modifiedOvertimRecords]="modifiedOvertimRecords"
        [passUpdateData]="passUpdateData"
        (isSucess)="CheckMassUpdate($event)"
        [isUpdate]="isUpdate"
        [isSingleRecord]="isSingleRecord"
      >
      </app-mass-update-overtime-maintenance>

      <app-archive-overtime-detail
        [selectedEmp]="selectedEmp"
        [isArchive]="isArchive"
        (isSuccess)="CheckArchive($event)"
        [loggedInUserATID]="loggedInUserATID"
        (clean)="cleanEmp($event)"
        *ngIf="isArchive"
      >
      </app-archive-overtime-detail>

      <app-reassign-overtimegroup
        [isReassignOvertimeGroup]="isReassignOvertimeGroup"
        *ngIf="isReassignOvertimeGroup"
        (isSuccess)="onReassignOvertime($event)"
        (clean)="cleanEmp($event)"
      ></app-reassign-overtimegroup>

      <app-reassign-representation-group-overtimedetail
        [isReassignRepresentationOvertimeGroup]="
          isReassignRepresentationOvertimeGroup
        "
        *ngIf="isReassignRepresentationOvertimeGroup"
        (isSuccess)="onReassignOvertime($event)"
        (clean)="cleanEmp($event)"
      >
      </app-reassign-representation-group-overtimedetail>
    </div>
  </div>
</div>
