import { Component } from '@angular/core';

@Component({
  selector: 'app-overtime-allocation-view',
  templateUrl: './overtime-allocation-view.component.html',
  styleUrls: ['./overtime-allocation-view.component.css'],
})
export class OvertimeAllocationViewComponent {
  isAllocationView: boolean = true;
  viewName: string = 'OVERTIMEALLOCATIONSUMMARY';
  moduleName: string = 'VIEWS';
  constructor() {}
}
