import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RegionDataService } from 'src/app/Services/TableMaintenance/RegionData/region-data.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-delete-region-data',
  templateUrl: './delete-region-data.component.html',
  styleUrls: ['./delete-region-data.component.css'],
})
export class DeleteRegionDataComponent {
  @Input() selectedRegion: any;
  regionId: string = '';
  @Output() isSucess = new EventEmitter();
  constructor(private rds: RegionDataService) {}

  clear() {
    //TODO
  }

  confirmDelete() {
    if (this.selectedRegion) {
      this.regionId = this.selectedRegion.regionId;
      this.rds.deleteRegionData(this.regionId).subscribe(
        () => {
          this.successAlert();
          this.isSucess.emit(true);
        },
        (_error: HttpErrorResponse) => {
          if (_error.status === 500) {
            this.badRequestAlert(_error.error);
          } else {
            this.badRequestAlert(_error.error);
          }
        }
      );
    }
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'warning',
      title: `${errorMessage}`,
    });
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Record previously selected has been removed successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }
}
