<div class="container-fluid app-box" style="background-color: white">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Views</li>
        <li class="breadcrumb-item brd-li brd-li-child">
          View All Applications
        </li>
      </ol>
    </nav>
  </div>

  <div class="main-section">
    <div
      *ngIf="userInformation !== undefined && userInformation !== null"
      class="row content-container mt-2 mb-2"
    >
      <!-- global search filter starts -->
      <div id="content" #content class="pt-2 div-color">
        <div class="search-divider-div">
          <p class="search-divder-p"></p>

          <div
            class="jumbotron jumb-new jumb-newcontrols"
            style="margin-bottom: 0px"
          >
            <div class="row userinfo-header-div" display="float">
              <div class="col">
                <h3 class="h1-label">
                  Welcome! {{ userInformation.firstName }}
                </h3>
              </div>
              <div>
                <button
                  class="btn btn-lg search-button add-button-a button-align print-btn non-printable"
                  (click)="PrintAllApplication()"
                >
                  Print
                </button>
              </div>
            </div>
          </div>
          <div class="empdescription-box">
            <div class="empdescription-table">
              <table class="table">
                <tr>
                  <th class="empdescription-th emp-atid">Employee Id:</th>
                  <th class="empdescription-th">Current Classification:</th>
                  <th class="empdescription-th">Current Department:</th>
                  <th class="empdescription-th">Current Shift:</th>
                </tr>
                <tr *ngIf="usersData !== undefined">
                  <td class="empdescription-table-tr">
                    {{ usersData.employeeId }}
                  </td>
                  <td class="empdescription-table-tr">
                    {{ usersData.jobCodeId }} {{ usersData.jobCodeDescription }}
                  </td>
                  <td class="empdescription-table-tr">
                    {{ usersData.deptId }} - {{ usersData.deptDescription }}
                  </td>
                  <td class="empdescription-table-tr">
                    {{ usersData.shiftDescription }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div
            class="jumbotron jumb-new jumb-newcontrols"
            style="margin-bottom: 0px"
          >
            <div class="row" display="float">
              <div class="col">
                <app-filter
                  [filterConfig]="filterConfig"
                  (filterValue)="getFilterValue($event)"
                >
                </app-filter>
              </div>
              <div class="col-md-1 mr-5">
                <a
                  class="btn btn-lg search-button"
                  (click)="searchApplications()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <!-- filter ends -->
        </div>
        <div class="pt-5">
          <!--Shift Preference Application -->
          <div *ngIf="shiftPrefView">
            <p class="modulename">
              <strong>Application Type :</strong> Shift Preference
            </p>
            <table
              class="table table-striped"
              *ngIf="shiftPrefData !== null && shiftPrefData.length > 0"
            >
              <thead class="table-header">
                <th scope="col">Shift Requested</th>
                <th scope="col">Shift Request Type</th>
                <th scope="col">Employee Signature Date:</th>
                <th scope="col">Status</th>
              </thead>

              <tbody>
                <tr
                  *ngFor="let emp of shiftPrefData; let i = index"
                  class="table-container"
                >
                  <td class="tab-value">
                    {{ emp.shiftDescription ? emp.shiftDescription : '-' }}
                  </td>
                  <td class="tab-value">
                    {{
                      emp.specialSPDescription ? emp.specialSPDescription : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{ emp.empSignDate | date: 'MM/dd/yyyy' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.appStatusCode ? emp.appStatusCode : '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <p *ngIf="shiftPrefRecods" class="text-danger">
                No result found for your search.<br />
              </p>
            </div>

            <hr style="border-top: 4px solid #003aa0" />
          </div>

          <!--150 skilled Applications-->
          <div *ngIf="Skill150View">
            <p class="modulename">
              <strong>Application Type :</strong> Skilled Plant Transfer (150)
            </p>
            <table
              class="table table-striped"
              *ngIf="Skill150Data !== null && Skill150Data.length > 0"
            >
              <thead class="table-header">
                <th scope="col">Plant</th>
                <th *ngIf="FSType" scope="col">Area</th>
                <th *ngIf="TDType" scope="col">Department</th>
                <th scope="col">Shift</th>
                <th scope="col">Employee Signature Date</th>
                <th scope="col">Status</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="let emp of Skill150Data; let i = index"
                  class="table-container"
                >
                  <td class="tab-value">
                    {{ emp.plantId }} - {{ emp.plantDescription }}
                  </td>
                  <td *ngIf="FSType" class="tab-value">
                    {{ emp.areaDescription ? emp.areaDescription : 'N/A' }}
                  </td>
                  <td *ngIf="TDType" class="tab-value">
                    {{ emp.deptDescription }} - {{ emp.deptId }}
                  </td>
                  <td class="tab-value">
                    {{ emp.shiftDescription ? emp.shiftDescription : 'N/A' }}
                  </td>
                  <td class="tab-value">{{ emp.empSignDate | date }}</td>
                  <td class="tab-value">
                    {{ emp.appStatusCode ? emp.appStatusCode : 'N/A' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <p *ngIf="Skill150Rec" class="text-danger">
                No result found for your search.<br />
              </p>
            </div>

            <hr style="border-top: 4px solid #003aa0" />
          </div>

          <!--Skilled to Skilled Application -->
          <div *ngIf="skillToSkillView">
            <p class="modulename">
              <strong>Application Type :</strong> Skilled to Skilled Transfer
              (152)
            </p>
            <table
              class="table table-striped"
              *ngIf="skillToSkillData !== null && skillToSkillData.length > 0"
            >
              <thead class="table-header">
                <th scope="col">Applied for Classification:</th>
                <th scope="col">Status:</th>
                <th scope="col">Employee Signature Date:</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="let emp of skillToSkillData; let i = index"
                  class="table-container"
                >
                  <td class="tab-value">
                    {{ emp.jobCodeId }} - {{ emp.jobCodeDescription }}
                  </td>
                  <td class="tab-value">
                    {{ emp.appStatusCode ? emp.appStatusCode : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.empSignDate | date: 'MM/dd/yyyy' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <p *ngIf="skillToSkillRec" class="text-danger">
                No result found for your search. <br />
              </p>
            </div>

            <hr style="border-top: 4px solid #003aa0" />
          </div>

          <!--Prod to Skilled Application -->
          <div *ngIf="prodToSkillView">
            <p class="modulename">
              <strong>Application Type :</strong> Production to Skilled Transfer
            </p>
            <table
              class="table table-striped"
              *ngIf="prodToSkillData !== null && prodToSkillData.length > 0"
            >
              <thead class="table-header">
                <th scope="col">Classification Applied for</th>
                <th scope="col">Status</th>
                <th scope="col">Employee Signature Date</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="let emp of prodToSkillData; let i = index"
                  class="table-container"
                >
                  <td class="tab-value">
                    {{ emp.jobCodeId }} - {{ emp.jobCodeDescription }}
                  </td>
                  <td class="tab-value">
                    {{ emp.appStatusCode ? emp.appStatusCode : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.empSignDate | date: 'MM/dd/yyyy' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <p *ngIf="prodToSkillRec" class="text-danger">
                No result found for your search.<br />
              </p>
            </div>

            <hr style="border-top: 4px solid #003aa0" />
          </div>

          <!--Transfer Agreements Applications -->
          <div *ngIf="transferAgreeView">
            <p class="modulename">
              <strong>Application Type :</strong> Transfer Agreement
              Applications
            </p>
            <table
              class="table table-striped"
              *ngIf="TAData !== null && TAData.length > 0"
            >
              <thead class="table-header">
                <!-- <th scope="col">AppNo</th>
                            <th scope="col">Classification Applied For</th>
                            <th scope="col">Plant Applied For</th>
                            <th scope="col">Dept Applied For</th>
                            <th scope="col">Shift Applied For</th>
                            <th scope="col">Status</th>
                            <th scope="col">Employee Signature Date</th> -->

                <th scope="col">
                  <span (click)="onSortClick($event, 'appNo', 'string')"
                    >App No
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'appNo'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'appNo'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'appNo'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="
                      onSortClick($event, 'jobCodeDescription', 'string')
                    "
                    >Classification Applied For
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'jobCodeDescription'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'jobCodeDescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'jobCodeDescription'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'plantDescription', 'string')"
                    >Plant Applied For
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'plantDescription'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'plantDescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'plantDescription'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'deptDescription', 'string')"
                    >Dept Applied For
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'deptDescription'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'deptDescription'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'deptDescription'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'shiftDescription', 'string')"
                    >Shift Applied For
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'shiftDescription'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'shiftDescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'shiftDescription'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'appStatusCode', 'string')"
                    >Status
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'appStatusCode'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'appStatusCode'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'appStatusCode'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'empSignDate', 'string')"
                    >Employee Signature Date
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'empSignDate'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'empSignDate'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'empSignDate'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
              </thead>
              <tbody>
                <tr
                  *ngFor="let emp of TAData; let i = index"
                  class="table-container"
                >
                  <td class="tab-value">{{ emp.appNo ? emp.appNo : '-' }}</td>

                  <td class="tab-value">
                    {{ emp.jobCodeId }} - {{ emp.jobCodeDescription }}
                  </td>
                  <td class="tab-value">
                    {{ emp.plantId }} - {{ emp.plantDescription }}
                  </td>
                  <td class="tab-value">
                    {{ emp.deptId }} - {{ emp.deptDescription }}
                  </td>
                  <td class="tab-value">
                    {{ emp.shiftDescription ? emp.shiftDescription : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.appStatusCode ? emp.appStatusCode : '-' }}
                  </td>
                  <td class="tab-value custon-padding">
                    {{ emp.empSignDate | date: 'MM/dd/yyyy' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <p *ngIf="transferAgreeRec" class="text-danger">
                No result found for your search.<br />
              </p>
            </div>

            <hr style="border-top: 4px solid #003aa0" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
