import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class PlantDataService {

  commonUtility = new CommonUtility();
  private readonly api_getPlantData = '/PlantDataGetPlantsData'
  private readonly api_addPlantData = '/PlantDataAdd'
  private readonly api_deletePlantData = '/PlantDataDelete?plantid='
  private readonly api_editPlantData = '/PlantDataUpdate'
  private readonly api_getApp150PlantGroupData = '/PlantDataGetApp150PlantGroups'
  private readonly api_getPlantGroupData = '/PlantDataGetPlantGroupsData'

  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  getPlantData(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getPlantData,
      { headers }
    );
  }

  getApp150PlantGroupData(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getApp150PlantGroupData,
      { headers }
    );
  }

  getPlantGroupData(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getPlantGroupData,
      { headers }
    );
  }

  editPlantData(plantData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.tableMaintenanceURL}` + this.api_editPlantData,
      plantData,
      { headers }
    );
  }

  addPlantData(plantData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_addPlantData,
      plantData,
      { headers }
    );
  }

  deletePlantData(plantid: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.delete<any>(
      `${environment.tableMaintenanceURL}` + this.api_deletePlantData + plantid,
      { headers }
    );
  }
}
