<div class="container-fluid app-box" style="background-color: white">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Views</li>
        <li class="breadcrumb-item brd-li brd-li-child">
          Transfer Applications View
        </li>
      </ol>
    </nav>
  </div>
  <div>
    <div class="row content-container mt-2 mb-2">
      <div class="pt-2 div-color">
        <div class="search-divider-div filtercss">
          <p class="search-divder-p"></p>
          <hr class="search-divider-hr" />
          <div class="jumbotron jumb-new jumb-newcontrols">
            <div class="row" display="float">
              <div class="col">
                <app-filter
                  [filterConfig]="filterConfig"
                  (filterValue)="getFilterValue($event)"
                  (formcontrolobj)="getbyenter($event)"
                >
                </app-filter>
              </div>
              <div class="col-md-1 mr-5">
                <a
                  class="btn btn-lg search-button"
                  (click)="searchTransferApplications()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>

          <!-- Button Start-->
          <div
            style="margin-top: 13px; margin-bottom: 2px"
            *ngIf="
              transferApplicationsViewData !== null &&
              transferApplicationsViewData.length > 0
            "
          >
            <button
              style="margin-right: 0.5rem; border-color: #003aa0"
              class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
              (click)="exportElmToExcel()"
            >
              Export to Excel
            </button>
          </div>
          <!-- Button End-->
        </div>
        <div class="container-divider-div"></div>
        <div>
          <div *ngIf="!isApplicationDataEmpty">
            <!-- this is export to excel  -->
            <table
              class="table table-striped"
              *ngIf="
                !isDataExportableView &&
                transferApplicationsViewData !== null &&
                transferApplicationsViewData.length > 0
              "
              #TransferApplicationsViewExportTable
            >
              <!-- <thead class="table-header">
                                <th class="table-height" style="border-right: 2px solid #fff" colspan="7" scope="col">Employee Information</th>
                                <th class="table-height" colspan="7" scope="col">Application Information</th>
                            </thead> -->
              <thead class="table-header position">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Employee Id</th>
                  <th scope="col">Plant Sen Date</th>
                  <th scope="col">GM Sen Date</th>
                  <th scope="col">Curr. Class</th>
                  <th scope="col">Curr. Dept</th>
                  <th style="border-right: 2px solid #fff" scope="col">
                    Medical Restrictions
                  </th>
                  <th scope="col">App. #</th>
                  <th scope="col">Date Signed</th>
                  <th scope="col">Class</th>
                  <th scope="col">Plant</th>
                  <th scope="col">Dept</th>
                  <th scope="col">Shift</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let data of transferApplicationsViewData;
                    let i = index
                  "
                  class="table-container"
                >
                  <td class="left-tab-value">
                    {{ data.last_Name ? data.last_Name : 'N/A' }},
                    {{ data.first_Name ? data.first_Name : 'N/A' }}&nbsp;{{
                      data.middle ? data.middle : ''
                    }}
                  </td>
                  <td class="tab-value">
                    {{ data.employeeid ? data.employeeid : '-' }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.plantSeniorityDateFormatted
                        ? (data.plantSeniorityDateFormatted
                          | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.gmSeniorityDateFormatted
                        ? (data.gmSeniorityDateFormatted | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{ data.currentJobCodeId ? data.currentJobCodeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.currentDeptId ? data.currentDeptId : '-'
                    }}&nbsp;-&nbsp;{{
                      data.currentDeptDesc ? data.currentDeptDesc : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    <p
                      [ngClass]="
                        data.isEmployeeMedicallyRestricted === true
                          ? 'isMedicallyRestricted'
                          : 'isNotMedicallyRestricted'
                      "
                    >
                      {{ data.isEmployeeMedicallyRestricted ? 'Yes' : 'No' }}
                    </p>
                  </td>
                  <td class="tab-value">{{ data.appno ? data.appno : '-' }}</td>
                  <td class="tab-value">
                    {{
                      data.empSignDate
                        ? (data.empSignDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.jobCodeApplyingFor ? data.jobCodeApplyingFor : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.plantApplyingForID ? data.plantApplyingForID : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.deptApplyingForID === null
                        ? data.deptApplyingForDesc === null
                          ? 'Any'
                          : data.deptApplyingForDesc
                        : data.deptApplyingForID
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.shiftApplyingForDesc
                        ? data.shiftApplyingForDesc
                        : '-'
                    }}
                  </td>
                  <td class="tab-value custon-padding">
                    {{ data.appStatusCode ? data.appStatusCode : '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- this is the main table -->
            <table
              class="table table-striped"
              *ngIf="
                transferApplicationsViewData !== null &&
                transferApplicationsViewData.length > 0
              "
            >
              <thead class="table-header">
                <th
                  class="table-height"
                  style="border-right: 2px solid #fff"
                  colspan="7"
                  scope="col"
                >
                  Employee Information
                </th>
                <th class="table-height" colspan="7" scope="col">
                  Application Information
                </th>
              </thead>
              <thead class="table-header position">
                <th scope="col">Name</th>
                <th scope="col">Employee Id</th>
                <th scope="col">Plant Sen Date</th>
                <th scope="col">GM Sen Date</th>
                <th scope="col">Curr. Class</th>
                <th scope="col">Curr. Dept</th>
                <th style="border-right: 2px solid #fff" scope="col">
                  Medical Restrictions
                </th>
                <th scope="col">App. #</th>
                <th scope="col">Date Signed</th>
                <th scope="col">Class</th>
                <th scope="col">Plant</th>
                <th scope="col">Dept</th>
                <th scope="col">Shift</th>
                <th scope="col">Status</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let data of transferApplicationsViewData
                      | paginate
                        : {
                            id: 'joblist',
                            itemsPerPage: 20,
                            currentPage: Applicationpage,
                            totalItems: TotalApplicationCount
                          };
                    let i = index
                  "
                  class="table-container"
                >
                  <td class="left-tab-value">
                    {{ data.last_Name ? data.last_Name : 'N/A' }},
                    {{ data.first_Name ? data.first_Name : 'N/A' }}&nbsp;{{
                      data.middle ? data.middle : ''
                    }}
                  </td>
                  <td class="tab-value">
                    {{ data.employeeid ? data.employeeid : '-' }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.plantSeniorityDateFormatted
                        ? (data.plantSeniorityDateFormatted
                          | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.gmSeniorityDateFormatted
                        ? (data.gmSeniorityDateFormatted | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{ data.currentJobCodeId ? data.currentJobCodeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.currentDeptId ? data.currentDeptId : '-'
                    }}&nbsp;-&nbsp;{{
                      data.currentDeptDesc ? data.currentDeptDesc : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    <p
                      [ngClass]="
                        data.isEmployeeMedicallyRestricted === true
                          ? 'isMedicallyRestricted'
                          : 'isNotMedicallyRestricted'
                      "
                    >
                      {{ data.isEmployeeMedicallyRestricted ? 'Yes' : 'No' }}
                    </p>
                  </td>
                  <td class="tab-value">{{ data.appno ? data.appno : '-' }}</td>
                  <td class="tab-value">
                    {{
                      data.empSignDate
                        ? (data.empSignDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.jobCodeApplyingFor ? data.jobCodeApplyingFor : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.plantApplyingForID ? data.plantApplyingForID : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.deptApplyingForID === null
                        ? data.deptApplyingForDesc === null
                          ? 'Any'
                          : data.deptApplyingForDesc
                        : data.deptApplyingForID
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.shiftApplyingForDesc
                        ? data.shiftApplyingForDesc
                        : '-'
                    }}
                  </td>
                  <td class="tab-value custon-padding">
                    {{ data.appStatusCode ? data.appStatusCode : '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="single-wrap d-flex justify-content-center"
              *ngIf="
                transferApplicationsViewData !== null &&
                transferApplicationsViewData.length > 0
              "
            >
              <nav aria-label="Page navigation example">
                <pagination-controls
                  id="joblist"
                  (pageChange)="Applicationpage = $event"
                  previousLabel=""
                  nextLabel=""
                ></pagination-controls>
              </nav>
            </div>
          </div>
          <div *ngIf="isApplicationDataEmpty">
            <p *ngIf="isApplicationDataEmpty">No existing records Found</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
