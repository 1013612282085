<div
  class="modal fade"
  id="ReassignOvertimeRepGroupModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ReassignOvertimeRepGroupModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
    style="max-width: 80%"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ReassignOvertimeRepGroupModalLabel">
          Reassign Overtime Representation Group
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>
      <div>
        <div class="row content-container mt-2 mb-2">
          <nav
            class="navbar navbar-expand-lg navbar-light bg-light applicationcss"
          >
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mr-auto"></ul>
              <form class="my-2 my-lg-0 frmOtDt">
                <div style="float: left">
                  <label class="select-label" for="1"
                    >Demographic Detail
                  </label>
                </div>
                <div class="text-right">
                  <input
                    type="button"
                    class="backbutton"
                    value="Back to Detail Search"
                    (click)="clear()"
                  />&nbsp;
                  <input
                    type="button"
                    class="backbutton"
                    value="Back to Main Search"
                    (click)="MainNavigate()"
                  />&nbsp;

                  <input
                    type="button"
                    *ngif="1 === 0"
                    value="Add to Archive"
                  />&nbsp;
                </div>
              </form>
            </div>
            <br />
          </nav>
          <div class="demographic-details">
            <div class="div-color">
              <div>
                <table>
                  <th><b>Employee Id</b></th>
                  <th><b>Name</b></th>
                  <th><b>Department</b></th>
                  <th><b>Classification</b></th>
                  <th><b>Shift</b></th>
                  <th><b>Acc. Hours</b></th>
                  <th><b>OT Group</b></th>
                  <th><b>Rep. Group</b></th>
                  <tr>
                    <td>{{ demographData?.employeeid | uppercase }}</td>
                    <td>
                      {{
                        demographData?.lasT_NAME
                          ? demographData?.lasT_NAME
                          : 'N/A'
                      }},&nbsp;{{
                        demographData?.firsT_NAME
                          ? demographData?.firsT_NAME
                          : 'N/A'
                      }}&nbsp;{{
                        demographData?.middle ? demographData?.middle : ''
                      }}
                    </td>
                    <td>
                      {{ demographData?.dept }} -
                      {{ demographData?.dptdescription }}
                    </td>
                    <td>
                      {{ demographData?.jobcodeid }} -
                      {{ demographData?.jobcodedescription }}
                    </td>
                    <td>{{ demographData?.shiftdescription }}</td>
                    <td>{{ accHours | number: '1.2-2' }}</td>
                    <td>{{ demographData?.overtimegrpname }}</td>
                    <td>{{ demographData?.representationgrpname }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <!-- global search filter starts -->
        </div>
      </div>

      <div class="emp-contain" role="main">
        <!-- <div class="blue-bar"></div> -->
        <div class="emp-form-group">
          <div>
            <h5
              style="font-size: 16px !important; padding-left: 27px"
              class="text-danger"
            >
              <b>NOTE:</b> Before Overtime Reassign Rep group, employee's
              Department and Job Classification need to be updated in the
              Demographic under table meintenance.
            </h5>
          </div>
          <form
            [formGroup]="EmployeeReassignRepresentationGroupForm"
            class="container emp-profile"
          >
            <div class="emp-form-header" style="padding-left: 30px">
              Reassign Representation Group
              <!-- <hr> -->
            </div>
            <div class="box-container">
              <div class="row">
                <div class="col-md-8">
                  <div class="tab-content profile-tab" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div class="row">
                        <div class="col-md-4">
                          <label
                            >Department
                            <span class="text-danger text-highlight">*:</span>
                          </label>
                        </div>
                        <div class="col-md-8">
                          <div class="form-group d-flex flex-row">
                            <select
                              formControlName="departmentId"
                              class="form-control"
                            >
                              <!-- <option value={{demographData?.dept}}>{{demographData?.dept}} - {{demographData?.dptdescription}}</option> -->
                              <option
                                *ngFor="
                                  let department of DepartmentList[0].data
                                "
                                value="{{ department.departmentId }}"
                              >
                                {{ department.departmentId }} -
                                {{ department.departmentDescription }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="tab-content profile-tab" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div class="row">
                        <div class="col-md-4">
                          <label
                            >Classification
                            <span class="text-danger text-highlight">*:</span>
                          </label>
                        </div>
                        <div class="col-md-8">
                          <div class="form-group d-flex flex-row">
                            <select
                              formControlName="jobCodeId"
                              class="form-control"
                            >
                              <!-- <option disabled *ngIf="ClassificationList[0].default" selected value="">{{ClassificationList[0].default}}</option> -->
                              <option
                                *ngFor="
                                  let classification of ClassificationList[0]
                                    .data
                                "
                                value="{{ classification.jobCodeId }}"
                              >
                                {{ classification.jobCodeId }}-{{
                                  classification.jobCodeDescription
                                }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="tab-content profile-tab" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div class="row">
                        <div class="col-md-4">
                          <label
                            >Shift
                            <span class="text-danger text-highlight"
                              >*:</span
                            ></label
                          >
                        </div>
                        <div class="col-md-8">
                          <div class="form-group d-flex flex-row">
                            <select
                              formControlName="shiftId"
                              class="form-control"
                            >
                              <!-- <option disabled *ngIf="ShiftsList[0].default" selected value="">{{ShiftsList[0].default}}</option> -->
                              <option
                                *ngFor="let shift of ShiftsList[0].data"
                                value="{{ shift.shiftId }}"
                              >
                                {{ shift.shiftDescription }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary popupbuttonscancel"
                data-dismiss="modal"
                #closeReassignRepresntationOvertimeGroupModal
                (click)="clear()"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                (click)="SubmitData()"
              >
                Reassign
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
