import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class ShiftDataService {

  commonUtility = new CommonUtility();
  private readonly api_getShiftsData = '/ShiftDataGetAllShifts';
  private readonly api_addShiftsData = '/ShiftDataAdd';
  private readonly api_deleteShiftsData = '/ShiftDataDelete?shiftId=';
  private readonly api_getSpecificShiftsData = '/ShiftDataGetSpecificShift?shiftId=';
  private readonly api_updateShiftsData = '/ShiftDataUpdate';

  constructor(
    private _http: HttpClient, 
    private appCom: MSALserviceService
  ) { }

  getShiftData(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getShiftsData,
      { headers }
    );
  }
  addShiftData(shiftData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
     `${environment.tableMaintenanceURL}` + this.api_addShiftsData,
      shiftData,
      { headers }
    );
  }
  editShiftData(shiftData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.tableMaintenanceURL}` + this.api_updateShiftsData,
      shiftData,
      { headers }
    );
  }
  deleteShiftData(shiftId: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.delete<any>(
      `${environment.tableMaintenanceURL}` + this.api_deleteShiftsData + shiftId,
      { headers }
    );
  }
  getSpecificShiftData(shiftId: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getSpecificShiftsData + shiftId,
      { headers }
    );
  }

}
