<div class="container-fluid app-box">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Admin</li>
        <li class="breadcrumb-item brd-li brd-li-child">
          Administer Groups > Modify Group (Committee)
        </li>
      </ol>
    </nav>
  </div>

  <div class="row content-container mt-2 mb-2">
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
      <!--[show]="loading"  -->
    </ngx-loading>
    <div class="div-color">
      <div class="pt-5" style="padding-top: 0px !important">
        <!-- Views -->
        <div>
          <p class="modulename">
            <strong style="margin-left: 0.8%">Views</strong>
          </p>
          <form>
            <table class="table table-striped">
              <thead class="table-header transfer-header">
                <th scope="col">View Name</th>
                <th scope="col">View</th>
              </thead>
              <tbody>
                <tr *ngFor="let view of viewModules; let i = index" class="">
                  <td class="tab-value">{{ view.viewName }}</td>
                  <td class="tab-value">
                    <input
                      type="checkbox"
                      name="view{{ view.viewId }}"
                      [(ngModel)]="viewModules[i].allowRead"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
        <!-- Transfer Application -->
        <div>
          <p class="modulename">
            <strong style="margin-left: 0.8%">Applications</strong>
          </p>
          <form>
            <table class="table table-striped">
              <thead class="table-header transfer-header">
                <th scope="col">Application Type</th>
                <th scope="col">Add</th>
                <th scope="col">Update</th>
                <th scope="col">Cancel</th>
                <th scope="col">Delete</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="let app of applicationModules; let i = index"
                  class=""
                >
                  <td class="tab-value">{{ app.viewName }}</td>
                  <td class="tab-value">
                    <input
                      type="checkbox"
                      name="app{{ app.viewId }}"
                      [(ngModel)]="applicationModules[i].allowAdd"
                    />
                  </td>
                  <td class="tab-value">
                    <input
                      type="checkbox"
                      name="app{{ app.viewId + i }}"
                      [(ngModel)]="applicationModules[i].allowUpdate"
                    />
                  </td>
                  <td class="tab-value">
                    <input
                      type="checkbox"
                      name="app{{ app.viewId + i + 1 }}"
                      [(ngModel)]="applicationModules[i].allowCancel"
                    />
                  </td>
                  <td class="tab-value">
                    <input
                      type="checkbox"
                      name="app{{ app.viewId + i + 2 }}"
                      [(ngModel)]="applicationModules[i].allowDelete"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
        <!-- Overtime -->
        <div>
          <p class="modulename">
            <strong style="margin-left: 0.8%">Overtime</strong>
          </p>
          <form>
            <table class="table table-striped">
              <thead class="table-header transfer-header">
                <th scope="col">Section Name</th>
                <th scope="col">Add</th>
                <th scope="col">Update</th>
                <th scope="col">Delete</th>
                <th scope="col">Reassign</th>
              </thead>
              <tbody>
                <tr *ngFor="let ovt of overtimeModules; let i = index" class="">
                  <td class="tab-value">{{ ovt.viewName }}</td>
                  <td class="tab-value">
                    <input
                      type="checkbox"
                      name="ovt{{ ovt.viewId }}"
                      [(ngModel)]="overtimeModules[i].allowAdd"
                    />
                  </td>
                  <td class="tab-value">
                    <input
                      type="checkbox"
                      name="ovt{{ ovt.viewId + i }}"
                      [(ngModel)]="overtimeModules[i].allowUpdate"
                    />
                  </td>
                  <td class="tab-value">
                    <input
                      type="checkbox"
                      name="ovt{{ ovt.viewId + i + 1 }}"
                      [(ngModel)]="overtimeModules[i].allowDelete"
                    />
                  </td>
                  <td class="tab-value">
                    <input
                      type="checkbox"
                      name="ovt{{ ovt.viewId + i + 2 }}"
                      [(ngModel)]="overtimeModules[i].allowReassign"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
        <!-- Admin -->
        <div>
          <p class="modulename">
            <strong style="margin-left: 0.8%">Admin</strong>
          </p>
          <form>
            <table class="table table-striped">
              <thead class="table-header transfer-header">
                <th scope="col">Section Name</th>
                <th scope="col">View</th>
              </thead>
              <tbody>
                <tr *ngFor="let admin of adminModules; let i = index" class="">
                  <td class="tab-value">{{ admin.viewName }}</td>
                  <td class="tab-value">
                    <input
                      type="checkbox"
                      name="admin{{ admin.viewId }}"
                      [(ngModel)]="adminModules[i].allowRead"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
        <!-- Table Maintenance -->
        <div>
          <p class="modulename">
            <strong style="margin-left: 0.8%">Table Maintenance</strong>
          </p>
          <form>
            <table class="table table-striped">
              <thead class="table-header transfer-header">
                <th scope="col">Table Name</th>
                <th scope="col">Add</th>
                <th scope="col">Update</th>
                <th scope="col">Delete</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="let tbl of tableMaintenanceModules; let i = index"
                  class=""
                >
                  <td class="tab-value">{{ tbl.viewName }}</td>
                  <td
                    *ngIf="tbl.viewCode !== 'APPLICATIONTYPESDATA'"
                    class="tab-value"
                  >
                    <input
                      type="checkbox"
                      name="tbl{{ tbl.viewId }}"
                      [(ngModel)]="tableMaintenanceModules[i].allowAdd"
                    />
                  </td>
                  <td
                    *ngIf="tbl.viewCode === 'APPLICATIONTYPESDATA'"
                    class="tab-value"
                  >
                    N/A
                  </td>
                  <td class="tab-value">
                    <input
                      type="checkbox"
                      name="tbl{{ tbl.viewId + i + 1 }}"
                      [(ngModel)]="tableMaintenanceModules[i].allowUpdate"
                    />
                  </td>
                  <td
                    *ngIf="tbl.viewCode !== 'APPLICATIONTYPESDATA'"
                    class="tab-value"
                  >
                    <input
                      type="checkbox"
                      name="tbl{{ tbl.viewId + i + 2 }}"
                      [(ngModel)]="tableMaintenanceModules[i].allowDelete"
                    />
                  </td>
                  <td
                    *ngIf="tbl.viewCode === 'APPLICATIONTYPESDATA'"
                    class="tab-value"
                  >
                    N/A
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
    <button type="button" class="button" (click)="sendModifedModules()">
      Modify Group
    </button>
  </div>
</div>
