<div class="modal fade" id="addEmployee" tabindex="-1" role="dialog" aria-labelledby="addEmployeeLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addEmployeeLabel">Add Region Data</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="clear()">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="regionForm">

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Region ID*</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="RegionId" class="form-control">
                            <div *ngIf="regionForm.controls['RegionId'].invalid && regionForm.controls['RegionId'].touched">
                                <small class="text-danger" *ngIf="regionForm.controls['RegionId'].errors?.required">
                                    You must specify region code to be entered
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Region Description*</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="RegionDescription" class="form-control">
                            <div *ngIf="regionForm.controls['RegionDescription'].invalid && regionForm.controls['RegionDescription'].touched">
                                <small class="text-danger" *ngIf="regionForm.controls['RegionDescription'].errors?.required">
                                    You must specify region code description
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Status*</label>
                        <div class="col-sm-8">
                            <select formControlName="Status" class="form-control">
                                <option *ngIf="Status[Status.length-1].default" selected value="">
                                    {{Status[Status.length-1].default}}</option>
                                <option *ngFor="let data of Status[Status.length-1].data" value={{data.statusType}}>
                                    {{data.statusName}}
                                </option>
                            </select>
                            <div *ngIf="regionForm.controls['Status'].invalid && regionForm.controls['Status'].touched">
                                <small class="text-danger" *ngIf="regionForm.controls['Status'].errors?.required">
                                    You must specify a valid status
                                </small>
                            </div>
                        </div>
                    </div>

                </form>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" #closeAddModel (click)="clear()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]="regionForm.errors || regionForm.invalid" (click)="submitRegion()">Add
                </button>
            </div>
        </div>
    </div>
</div>