import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { DemographicDataService } from 'src/app/Services/TableMaintenance/DemographicData/demographic-data.service';

@Component({
  selector: 'app-delete-demographic-data',
  templateUrl: './delete-demographic-data.component.html',
  styleUrls: ['./delete-demographic-data.component.css'],
})
export class DeleteDemographicDataComponent {
  @Input() selectedDemographic: any;
  @Output() isSucess = new EventEmitter();
  constructor(
    private dds: DemographicDataService,
    private _sweetAlertService: SweetAlertService
  ) {}

  DeleteApplication() {
    if (this.selectedDemographic) {
      let deleteObj = {
        Last_Name: this.selectedDemographic.last_Name,
        First_Name: this.selectedDemographic.first_Name,
        EMPLOYEEID: this.selectedDemographic.employeeid,
        ATID: this.selectedDemographic.atid,
      };
      this.dds.DemographicDataDelete(deleteObj).subscribe((res) => {
        this._sweetAlertService.successAlert(
          'center',
          'success',
          'The Employee record has been Deleted Successfully.',
          false,
          1500
        );
        this.isSucess.emit(true);
      });
    }
  }

  clear() {
    //TODO
  }
}
