<div class="modal fade" id="editEmp" tabindex="-1" role="dialog" aria-labelledby="addEmployeeLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addEmployeeLabel">Edit Plant Data</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="clear()">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="plantForm">

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Plant ID</label>
                        <div class="col-sm-8">
                            <input type="text" readonly formControlName="PLANTID" class="form-control">
                            <!-- <div *ngIf="plantForm.controls['PLANTID'].invalid && plantForm.controls['PLANTID'].touched">
                                <small class="text-danger" *ngIf="plantForm.controls['PLANTID'].errors?.required">
                                    You must specify the NIO group to be entered
                                </small>
                            </div> -->
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Plant Description*</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="PLANTDESCRIPTION" class="form-control">
                            <div *ngIf="plantForm.controls['PLANTDESCRIPTION'].invalid && plantForm.controls['PLANTDESCRIPTION'].touched">
                                <small class="text-danger" *ngIf="plantForm.controls['PLANTDESCRIPTION'].errors?.required">
                                    You must specify plant description
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Shift Preference Group*</label>
                        <div class="col-sm-8">
                            <select formControlName="PLANTGROUPID" class="form-control">
                                <option *ngIf="plantGroupData[plantGroupData.length-1].default" selected value="">
                                    {{plantGroupData[plantGroupData.length-1].default}}</option>
                                <option *ngFor="let data of plantGroupData[plantGroupData.length-1].data"
                                    value={{data.plantgroupid}}>
                                    {{data.plantgroupdescription}}
                                </option>
                            </select>
                            <div *ngIf="plantForm.controls['PLANTGROUPID'].invalid && plantForm.controls['PLANTGROUPID'].touched">
                                <small class="text-danger" *ngIf="plantForm.controls['PLANTGROUPID'].errors?.required">
                                    You must specify shift preference group to be entered
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">150 Plant Group</label>
                        <div class="col-sm-8">
                            <select formControlName="APP150PLANTGROUPID" class="form-control">
                                <option *ngIf="app150PlantGroupData[app150PlantGroupData.length-1].default" selected
                                    value="">
                                    {{app150PlantGroupData[app150PlantGroupData.length-1].default}}</option>
                                <option *ngFor="let data of app150PlantGroupData[app150PlantGroupData.length-1].data"
                                    value={{data.apP150PLANTGROUPID}}>
                                    {{data.apP150PLANTGROUPDESCRIPTION}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">SAP Personnel Area</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="SAP_PERSONNEL_AREA" class="form-control">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Status*</label>
                        <div class="col-sm-8">
                            <select formControlName="STATUS" class="form-control">
                                <option *ngIf="Status[Status.length-1].default" selected value="">
                                    {{Status[Status.length-1].default}}</option>
                                <option *ngFor="let data of Status[Status.length-1].data" value={{data.statusType}}>
                                    {{data.statusName}}
                                </option>
                            </select>
                            <div *ngIf="plantForm.controls['STATUS'].invalid && plantForm.controls['STATUS'].touched">
                                <small class="text-danger" *ngIf="plantForm.controls['STATUS'].errors?.required">
                                    You must specify a valid status
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Last Updated By</label>
                        <div class="col-sm-8">
                            {{plantSelected.lastupdateby}}
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Date of Last Update</label>
                        <div class="col-sm-8">
                            {{plantSelected.lastupdatedate | date:'short'}}
                        </div>
                    </div>

                </form>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" #closeCorrectModel (click)="clear()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]="plantForm.errors || plantForm.invalid" (click)="submitPlant()">Update</button>
            </div>
        </div>
    </div>
</div>