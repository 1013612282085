import { HttpErrorResponse } from '@angular/common/http';
import { ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HolidayDataService } from 'src/app/Services/TableMaintenance/HolidayData/holiday-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-holiday-data',
  templateUrl: './add-holiday-data.component.html',
  styleUrls: ['./add-holiday-data.component.css'],
})
export class AddHolidayDataComponent implements OnInit {
  @ViewChild('closeAddModel') closeAddModal!: ElementRef;
  holidayForm!: FormGroup;
  @Input() userName: any;
  @Output() isSucess = new EventEmitter();
  isAddError: boolean = false;
  errorMessage: string = '';

  constructor(private fb: FormBuilder, private hds: HolidayDataService) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.holidayForm = this.fb.group({
      LastUpdateBy: [this.userName],
      holidays: this.fb.array([]),
    });
  }

  holidays(): FormArray {
    return this.holidayForm.get('holidays') as FormArray;
  }

  newHoliday(): FormGroup {
    return this.fb.group({
      Holiday: ['', [Validators.required]],
      Description: ['', [Validators.required]],
    });
  }

  addHoliday() {
    this.holidays().push(this.newHoliday());
  }

  removeHoliday(i: number) {
    this.holidays().removeAt(i);
    this.isAddError = true;
    this.errorMessage = '';
  }

  clear() {
    this.closeAddModal.nativeElement.click();
    this.isAddError = true;
    this.errorMessage = '';
    this.createForm();
  }

  checkIfDuplicateExists(arr: any) {
    return new Set(arr).size !== arr.length;
  }
  submitHoliday() {
    let arrayControl = this.holidayForm.get('holidays') as FormArray;
    const formArrayData = arrayControl.value;

    var holidaysDate: any = [];
    this.errorMessage = '';
    holidaysDate.length = 0;
    for (var i = 0; i < formArrayData.length; i++) {
      holidaysDate.push(formArrayData[i].Holiday);
    }

    if (this.checkIfDuplicateExists(holidaysDate)) {
      this.isAddError = true;
      this.errorMessage = 'Duplicate entry for same date found.';
      return;
    }

    if (formArrayData.length > 0) {
      this.hds.addHolidayData(this.holidayForm.value).subscribe(
        () => {
          this.clear();
          this.successAlert();
          this.isSucess.emit(true);
        },
        (_error: HttpErrorResponse) => {
          if (_error.status === 500) {
            this.badRequestAlert(_error.error);
          } else {
            this.isAddError = true;
            this.errorMessage = _error.error;
          }
        }
      );
    } else {
      this.validationError(
        'You have not entered any record, Please add at least one record to save'
      );
    }
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Holiday(s) has been added successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
