import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RepresentationGroupClassification } from 'src/app/Models/RepresentationGroupClassification';
import { RepresentationGroupDept } from 'src/app/Models/RepresentationGroupDept';
import { OvertimeGroupDataService } from 'src/app/Services/TableMaintenance/OvertimeGroupData/overtime-group-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-overtime-group-data',
  templateUrl: './update-overtime-group-data.component.html',
  styleUrls: ['./update-overtime-group-data.component.css'],
})
export class UpdateOvertimeGroupDataComponent implements OnInit, OnChanges {
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;
  @Input() userName: any;
  @Input() selectedOvertimeGroup: any;
  @Output() isSucess = new EventEmitter();
  overtimeGroupForm!: FormGroup;
  overtimeGroupSelected: any;
  classification: RepresentationGroupClassification[] = [];
  department: RepresentationGroupDept[] = [];
  depatFGs: any[] = [];
  newDeptArray: any[] = [];
  selectedDepartment: any[] = [];
  selectedClassification: any[] = [];
  shifts: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];
  Status: any[] = [
    {
      data: [
        {
          statusType: 'A',
          statusName: 'Active',
        },
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
      ],
      default: 'Select One',
    },
  ];
  constructor(private fb: FormBuilder, private otg: OvertimeGroupDataService) {}

  ngOnInit(): void {
    this.getClassification();
    this.getShifts();
    this.getDepartment();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.createForm();
    this.loadData(changes);
  }
  getDepartment() {
    this.otg.getDepartments().subscribe((data) => {
      this.department = data.map((dept: RepresentationGroupDept) => ({
        deptDescription: dept.deptDescription,
        deptId: dept.deptId,
        checked: false,
      }));
      for (let i = 0; i < this.selectedDepartment.length; i++) {
        for (let j = 0; j < this.department.length; j++) {
          if (this.department[j].deptId === this.selectedDepartment[i]) {
            this.department[j].checked = true;
          }
        }
      }
    });
  }
  getClassification() {
    this.otg.getClassifications().subscribe((data) => {
      this.classification = data.map(
        (classification: RepresentationGroupClassification) => ({
          jobCodeDescription: classification.jobCodeDescription,
          jobCodeId: classification.jobCodeId,
          checked: false,
        })
      );
      for (let i = 0; i < this.selectedClassification.length; i++) {
        for (let j = 0; j < this.classification.length; j++) {
          if (
            this.classification[j].jobCodeId === this.selectedClassification[i]
          ) {
            this.classification[j].checked = true;
          }
        }
      }
    });
  }

  getShifts() {
    this.otg.getShifts().subscribe((data) => {
      this.shifts[0].data = data;
    });
  }

  createForm() {
    this.overtimeGroupForm = this.fb.group({
      OvertimeGroupName: ['', [Validators.required]],
      OvertimeGroupId: [],
      OvertimeGroupDescription: [''],
      ShiftId: ['', Validators.required],
      Spread: ['', Validators.required],
      Status: ['', [Validators.required]],
      DepartmentArray: [],
      ClassificationArray: [],
      LASTUPDATEBY: [this.userName],
    });
  }

  loadData(changes: SimpleChanges) {
    this.overtimeGroupSelected =
      (changes.selectedOvertimeGroup &&
        changes.selectedOvertimeGroup.currentValue) ||
      '';
    if (this.overtimeGroupSelected === '') {
      this.overtimeGroupSelected =
        (changes.selectedOvertimeGroup &&
          changes.selectedOvertimeGroup.previousValue) ||
        '';
    }
    this.getClassification();
    this.getDepartment();

    this.selectedDepartment = this.overtimeGroupSelected.department
      .split(',')
      .map((dept: string) => dept.trim());
    this.selectedClassification = this.overtimeGroupSelected.classification
      .split(',')
      .map((classification: string) => classification.trim());
    this.overtimeGroupForm
      .get('OvertimeGroupId')
      ?.patchValue(this.overtimeGroupSelected.overtimegroupid);
    this.overtimeGroupForm
      .get('OvertimeGroupName')
      ?.patchValue(this.overtimeGroupSelected.overtimegroupname);
    this.overtimeGroupForm
      .get('OvertimeGroupDescription')
      ?.patchValue(this.overtimeGroupSelected.overtimegroupdescription);
    this.overtimeGroupForm
      .get('ShiftId')
      ?.patchValue(this.overtimeGroupSelected.shiftid);
    this.overtimeGroupForm
      .get('Spread')
      ?.patchValue(this.overtimeGroupSelected.spread);
    this.overtimeGroupForm
      .get('Status')
      ?.patchValue(this.overtimeGroupSelected.status);
  }

  submitOvertimeGroupData() {
    const classarr = this.classification.filter((item) => {
      return item.checked;
    });
    const classtemp = classarr.map((item) => item.jobCodeId);
    const deptarr = this.department.filter((item) => {
      return item.checked;
    });
    const depttemp = deptarr.map((item) => item.deptId);
    this.overtimeGroupForm.get('ClassificationArray')?.patchValue(classtemp);
    this.overtimeGroupForm.get('DepartmentArray')?.patchValue(depttemp);
    this.otg.updateOvertimeGroupData(this.overtimeGroupForm.value).subscribe(
      () => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 500) {
          this.badRequestAlert(_error.error);
        } else {
          this.validationError(_error.error);
        }
      }
    );
  }

  clear() {
    this.closeModal.nativeElement.click();
    this.createForm();
    this.loadCancelledData();
    this.getClassification();
    this.getDepartment();
  }

  loadCancelledData() {
    this.overtimeGroupForm
      .get('OvertimeGroupName')
      ?.patchValue(this.selectedOvertimeGroup.overtimegroupname);
    this.overtimeGroupForm
      .get('OvertimeGroupDescription')
      ?.patchValue(this.selectedOvertimeGroup.overtimegroupdescription);
    this.overtimeGroupForm
      .get('ShiftId')
      ?.patchValue(this.selectedOvertimeGroup.status);
    this.overtimeGroupForm
      .get('Spread')
      ?.patchValue(this.selectedOvertimeGroup.representativephone);
    this.overtimeGroupForm
      .get('Status')
      ?.patchValue(this.selectedOvertimeGroup.status);
    this.overtimeGroupForm
      .get('CreatedBy')
      ?.patchValue(this.selectedOvertimeGroup.status);
    this.overtimeGroupForm
      .get('Status')
      ?.patchValue(this.selectedOvertimeGroup.status);
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Record has been updated successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }
  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
