
<div class="modal fade" id="editEmp" tabindex="-1" role="dialog" aria-labelledby="addEmployeeLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="width: 700px; right:120px;top:60px;height:600px">
            <div class="modal-header">
                <h5 class="modal-title" id="addEmployeeLabel">Edit District Data</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="clear()">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="DistrictForm">

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">District ID:</label>
                        <div class="col-sm-8">
                                {{DistrictSelected.districtid}}
                            <!-- <input type="text" readonly formControlName="DISTRICTID" class="form-control"> -->
                            <!-- <div *ngIf="plantForm.controls['PLANTID'].invalid && plantForm.controls['PLANTID'].touched">
                                <small class="text-danger" *ngIf="plantForm.controls['PLANTID'].errors?.required">
                                    You must specify the NIO group to be entered
                                </small>
                            </div> -->
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Description:</label>
                        <div class="col-sm-8">
                            <textarea type="text" rows="6" cols="60" formControlName="DISTRICTDESCRIPTION" class="form-control"></textarea>
                            <!-- <div *ngIf="DistrictForm.controls['DISTRICTDESCRIPTION'].invalid && DistrictForm.controls['DISTRICTDESCRIPTION'].touched">
                                <small class="text-danger" *ngIf="DistrictForm.controls['DISTRICTDESCRIPTION'].errors?.required">
                                    You must specify District description
                                </small>
                            </div> -->
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Employee Id of Representative*:</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="REPRESENTATIVE_EMPLOYEEID" class="form-control">
                            <div *ngIf="DistrictForm.controls['REPRESENTATIVE_EMPLOYEEID'].invalid && DistrictForm.controls['REPRESENTATIVE_EMPLOYEEID'].touched">
                                <small class="text-danger" *ngIf="DistrictForm.controls['REPRESENTATIVE_EMPLOYEEID'].errors?.required">
                                    You must specify the Employee Id of representative
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Representative Contact #:</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="REPRESENTATIVEPHONE" class="form-control">
                            <small class = "text-danger" *ngIf="DistrictForm.controls['REPRESENTATIVEPHONE'].errors?.representativePhoneFormatError">
                                You must enter the entire representative's phone number in the format "xyz-abcd" or leave it blank.
                            </small>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Employee Id of Alternate:</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="ALTERNATE_EMPLOYEEID" class="form-control">
                            <!-- <div *ngIf="DistrictForm.controls['ALTERNATE_EMPLOYEEID'].invalid && DistrictForm.controls['ALTERNATE_EMPLOYEEID'].touched">
                                <small class="text-danger" *ngIf="DistrictForm.controls['ALTERNATE_EMPLOYEEID'].errors?.required">
                                    You must specify the Employee Id of alternate.
                                </small>
                            </div> -->
                        </div>
                    </div>

                    

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Alternate Contact #:</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="ALTERNATEPHONE" class="form-control">
                                <small class="text-danger" *ngIf="DistrictForm.controls['ALTERNATEPHONE'].errors?.alternatePhoneFormatError">
                                    You must enter the entire alternate's phone number in the format "xyz-abcd" or leave it blank.
                                </small>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Status*:</label>
                        <div class="col-sm-8">
                            <select formControlName="STATUS" class="form-control">
                                <option *ngIf="Status[Status.length-1].default" selected value="">
                                    {{Status[Status.length-1].default}}</option>
                                <option *ngFor="let data of Status[Status.length-1].data" value={{data.statusType}}>
                                    {{data.statusName}}
                                </option>
                            </select>
                            <div *ngIf="DistrictForm.controls['STATUS'].invalid && DistrictForm.controls['STATUS'].touched">
                                <small class="text-danger" *ngIf="DistrictForm.controls['STATUS'].errors?.required">
                                    You must specify a valid status
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Entered By: </label>
                        <div class="col-sm-8">
                            {{DistrictSelected.createdby}}
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Date Entered: </label>
                        <div class="col-sm-8">
                            {{DistrictSelected.createdate}}
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Last Updated By:</label>
                        <div class="col-sm-8">
                            {{DistrictSelected.lastupdateby}}
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Date of Last Update:</label>
                        <div class="col-sm-8">
                            {{DistrictSelected.lastupdatedate | date:'short'}}
                        </div>
                    </div>

                </form>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" #closeCorrectModel (click)="clear()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]="DistrictForm.errors || DistrictForm.invalid" (click)="submitDistrictData()">Update</button>
            </div>
        </div>
    </div>
</div>