import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  CheckAuthorization(module: any[], moduleCodeName: string, viewCodeName: string): boolean {
    let isAuthroized: boolean = false;
    for (var i = 0; i < module.length; i++) {
      if (module[i].moduelCode === moduleCodeName) {
        for (var j = 0; j < module[i].views.length; j++) {
          if (module[i].views[j].viewCode === viewCodeName) {
            isAuthroized = true;
          }
        }
      }
    }
    return isAuthroized;
  }
}
