
<div>
    <div class="form-row">
        <div class="form-group col-md-12" style="margin-bottom: 0.3rem !important"> 
            <label class="filter-label" style="color: white !important;">{{SectionName}}</label>
            <select [(ngModel)]="selectedDevice" (ngModelChange)="onChange($event)" class="form-control"  >
                            <option  *ngIf="Items[0].default" selected value=''>{{Items[0].default}}</option>
                           
                            <option *ngFor="let item of Items[0].data" value={{item.id}}>
                                {{item.description}}
                            </option>
            </select>
        </div>
    </div>
</div>
      
