import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShiftPreferenceDataService } from 'src/app/Services/TableMaintenance/ShiftPreferenceData/shift-preference-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-shift-preference-data',
  templateUrl: './update-shift-preference-data.component.html',
  styleUrls: ['./update-shift-preference-data.component.css'],
})
export class UpdateShiftPreferenceDataComponent {
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;
  @Input() userName: any;
  @Input() selectedPlantGroup: any;
  @Output() isSucess = new EventEmitter();
  plantGroupDataForm!: FormGroup;
  plantGroupDataSelected: any;
  Status: any[] = [
    {
      data: [
        {
          statusType: 'A',
          statusName: 'Active',
        },
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
      ],
      default: 'Select One',
    },
  ];
  constructor(
    private fb: FormBuilder,
    private shiftPreferenceDataService: ShiftPreferenceDataService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.createForm();
    this.loadData(changes);
  }

  createForm() {
    this.plantGroupDataForm = this.fb.group({
      PLANTGROUPID: ['', [Validators.required]],
      PLANTGROUPDESCRIPTION: ['', Validators.required],
      STATUS: ['', [Validators.required]],
      LASTUPDATEBY: [this.userName],
    });
  }

  loadData(changes: SimpleChanges) {
    this.plantGroupDataSelected =
      (changes.selectedPlantGroup && changes.selectedPlantGroup.currentValue) ||
      '';
    if (this.plantGroupDataSelected === '') {
      this.plantGroupDataSelected =
        (changes.selectedPlantGroup &&
          changes.selectedPlantGroup.previousValue) ||
        '';
    }
    this.plantGroupDataForm
      .get('PLANTGROUPID')
      ?.patchValue(this.plantGroupDataSelected.plantgroupid);
    this.plantGroupDataForm
      .get('PLANTGROUPDESCRIPTION')
      ?.patchValue(this.plantGroupDataSelected.plantgroupdescription);
    this.plantGroupDataForm
      .get('STATUS')
      ?.patchValue(this.plantGroupDataSelected.status);
  }

  submitPlantGroupData() {
    this.shiftPreferenceDataService
      .editPlantGroupData(this.plantGroupDataForm.value)
      .subscribe(
        () => {
          this.clear();
          this.successAlert();
          this.isSucess.emit(true);
        },
        (_error: HttpErrorResponse) => {
          if (_error.status === 500) {
            this.badRequestAlert(_error.error);
          } else {
            this.validationError(_error.error);
          }
        }
      );
  }
  clear() {
    this.closeModal.nativeElement.click();
    this.createForm();
    this.loadCancelledData();
  }
  loadCancelledData() {
    this.plantGroupDataForm
      .get('PLANTGROUPID')
      ?.patchValue(this.plantGroupDataSelected.plantgroupid);
    this.plantGroupDataForm
      .get('PLANTGROUPDESCRIPTION')
      ?.patchValue(this.plantGroupDataSelected.plantgroupdescription);
    this.plantGroupDataForm
      .get('STATUS')
      ?.patchValue(this.plantGroupDataSelected.status);
  }
  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Record has been updated successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }
  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
