<div class="modal fade" id="addEmployee" tabindex="-1" role="dialog" aria-labelledby="addEmployeeLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addEmployeeLabel">Add Representation Data</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="repGroupForm">
                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Representation group name*</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="RepresentationGroupName" class="form-control">
                            <div *ngIf="repGroupForm.controls['RepresentationGroupName'].invalid && repGroupForm.controls['RepresentationGroupName'].touched">
                                <small class="text-danger" *ngIf="repGroupForm.controls['RepresentationGroupName'].errors?.required">
                                    You must enter the representation group name
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Description*</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="RepresentationGroupDescription" class="form-control">
                            <div *ngIf="repGroupForm.controls['RepresentationGroupDescription'].invalid && repGroupForm.controls['RepresentationGroupDescription'].touched">
                                <small class="text-danger" *ngIf="repGroupForm.controls['RepresentationGroupDescription'].errors?.required">
                                    You must enter the representation group description
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Department(s)*</label>
                        <div class="col-sm-8">
                            <div class="checkbox-container" *ngFor="let dept of department; let i=index">
                                <fieldset>
                                    <label class="checkbox-inline">
                                        <input #checkboxes type="checkbox" [value]="dept.deptId"
                                            (change)="onDeptCheckboxChange($event)" />{{dept.deptId}}
                                    </label>
                                </fieldset>
                            </div>
                            <div class="container" *ngIf="repGroupForm.controls['DepartmentArray'].invalid && repGroupForm.controls['DepartmentArray'].touched">
                                <small class="text-danger" *ngIf="repGroupForm.controls['DepartmentArray'].errors?.required">
                                    You must select at least one department
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Classification(s)*</label>
                        <div class="col-sm-8">
                            <div class="checkbox-container" *ngFor="let class of classification; let i=index">
                                <fieldset>
                                    <label class="checkbox-inline">
                                        <input #checkboxes type="checkbox" [value]="class.jobCodeId"
                                            (change)="onClassCheckboxChange($event)" />{{class.jobCodeId}}
                                    </label>
                                </fieldset>
                            </div>
                            <div *ngIf="repGroupForm.controls['ClassificationArray'].invalid && repGroupForm.controls['ClassificationArray'].touched">
                                <small class="text-danger" *ngIf="repGroupForm.controls['ClassificationArray'].errors?.required">
                                    You must select at least one classification
                                </small>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="enableOverride" class="form-group row">
                        <label class="col-sm-4 col-form-label">Override*</label>
                        <div class="col-sm-8">
                            <input formControlName="MixedClassOverride" type="checkbox" value="1" class="form-control">
                            <div>
                                <small class="text-danger">
                                    You have selected both production and skilled classifications for the same
                                    representation group. You must override
                                </small>
                            </div>
                        </div>

                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Shift*</label>
                        <div class="col-sm-8">
                            <select formControlName="ShiftId" class="form-control">
                                <option *ngIf="shifts[shifts.length-1].default" selected value="">
                                    {{shifts[shifts.length-1].default}}</option>
                                <option *ngFor="let data of shifts[shifts.length-1].data" value={{data.shiftId}}>
                                    {{data.shiftDescription}}
                                </option>
                            </select>
                            <div *ngIf="repGroupForm.controls['ShiftId'].invalid && repGroupForm.controls['ShiftId'].touched">
                                <small class="text-danger" *ngIf="repGroupForm.controls['ShiftId'].errors?.required">
                                    You must select the shift of the representation group
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">District*</label>
                        <div class="col-sm-8">
                            <select formControlName="DistrictId" class="form-control">
                                <option *ngIf="districts[districts.length-1].default" selected value="">
                                    {{districts[districts.length-1].default}}</option>
                                <option *ngFor="let dist of districts[districts.length-1].data"
                                    value={{dist.districtid}}>
                                    {{dist.districtid}}
                                </option>
                            </select>
                            <div *ngIf="repGroupForm.controls['DistrictId'].invalid && repGroupForm.controls['DistrictId'].touched">
                                <small class="text-danger" *ngIf="repGroupForm.controls['DistrictId'].errors?.required">
                                    You must select the district of the representation group
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Zone*</label>
                        <div class="col-sm-8">
                            <select formControlName="ZoneId" class="form-control">
                                <option *ngIf="zones[zones.length-1].default" selected value="">
                                    {{zones[zones.length-1].default}}</option>
                                <option *ngFor="let zone of zones[zones.length-1].data" value={{zone.zoneid}}>
                                    {{zone.zoneid}}
                                </option>
                            </select>
                            <div *ngIf="repGroupForm.controls['ZoneId'].invalid && repGroupForm.controls['ZoneId'].touched">
                                <small class="text-danger" *ngIf="repGroupForm.controls['ZoneId'].errors?.required">
                                    You must select the zone of the representation group
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Status*</label>
                        <div class="col-sm-8">
                            <select formControlName="Status" class="form-control">
                                <option *ngIf="Status[Status.length-1].default" selected value="">
                                    {{Status[Status.length-1].default}}</option>
                                <option *ngFor="let class of Status[Status.length-1].data" value={{class.statusType}}>
                                    {{class.statusName}}
                                </option>
                            </select>
                            <div *ngIf="repGroupForm.controls['Status'].invalid && repGroupForm.controls['Status'].touched">
                                <small class="text-danger" *ngIf="repGroupForm.controls['Status'].errors?.required">
                                    You must choose the status
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" #closeAddModel (click)="clear()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]="repGroupForm.errors || repGroupForm.invalid" (click)="submitRepGroupData()">Add </button>
            </div>
        </div>
    </div>
</div>