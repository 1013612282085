import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { ClassificationDataService } from 'src/app/Services/TableMaintenance/ClassificationData/classification-data.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';

@Component({
  selector: 'app-classification-data',
  templateUrl: './classification-data.component.html',
  styleUrls: ['./classification-data.component.css'],
})
export class ClassificationDataComponent implements OnInit {
  public loading: boolean = false;
  showAdd: boolean = false;
  showEdit: boolean = false;
  showDelete: boolean = false;
  TotalApplicationCount: any;
  UserPage: number = 1;
  Applicationpage: number = 1;
  modalId: string = '';
  filterData: any = {};
  classificationSearchData: any[] = [];
  selectedClassification: any;
  showButton: boolean = false;
  userInformation: any;
  isApplicationDataEmpty: boolean = false;
  allClassifications: any[] = [];
  views: any[] = [];
  filterConfig: any = [
    {
      label: 'Classification code',
      type: 'input',
      data: [],
      placeholder: 'Enter classification code',
      default: 'All',
      controlName: 'JOBCODEID',
      runClassification: false,
    },
    {
      label: 'Classification description',
      type: 'input',
      data: [],
      placeholder: 'Enter description',
      default: 'All',
      controlName: 'JOBCODEDESCRIPTION',
      runClassification: false,
    },
  ];

  constructor(
    private cds: ClassificationDataService,
    private _user: UsergroupService,
    private _commonApplicationService: CommonService
  ) {
    this.getUserInfo();
  }

  ngOnInit(): void {
    this.getAllClassifications();
  }

  getAllClassifications() {
    let queryParams = {
      jobCodeId: '',
      jobCodeDescription: '',
    };
    this.cds.getClassificationData(queryParams).subscribe((res) => {
      //console.log(res);
      this.allClassifications = res;
    });
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'TABLEMAINTENANCE',
        'CLASSIFICATIONDATA'
      );
    });
  }

  selectClassification(idx: number, jobcodeid: string) {
    for (let index = 0; index < this.classificationSearchData.length; index++) {
      if (this.classificationSearchData[index].jobcodeid === jobcodeid) {
        this.selectedClassification = this.classificationSearchData[index];
        this.showButton = true;
        //console.log(this.selectedClassification)
      }
    }
    if (this.selectedClassification.replaces !== null) {
      for (let index = 0; index < this.allClassifications.length; index++) {
        if (
          this.allClassifications[index].jobcodeid ===
          this.selectedClassification.replaces
        ) {
          this.selectedClassification.replaces = `${this.allClassifications[index].jobcodeid} - ${this.allClassifications[index].jobcodedescription}`;
        }
      }
    }
  }

  showAddModal() {
    this.showAdd = true;
    this.modalId = '#addEmployee';
  }

  showEditModal() {
    this.showEdit = true;
    this.modalId = '#editEmp';
  }

  showDeleteModal() {
    this.showDelete = true;
    this.modalId = '#deleteApplicationModal';
  }

  CheckAdd(e: any) {
    this.searchClassifications();
  }

  CheckEdit(e: any) {
    this.searchClassifications();
    this.showButton = false;
  }

  CheckDelete(e: any) {
    this.searchClassifications();
    this.showButton = false;
  }

  getFilterValue(event: any) {
    this.filterData = event;
  }

  searchClassifications() {
    this.loading = true;
    let queryParams = this.filterData;
    if (Object.keys(this.filterData).length === 0) {
      queryParams.jobCodeId = '';
      queryParams.jobCodeDescription = '';
    }
    //Object.keys(queryParams).forEach((k) => !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]);
    // console.log(queryParams)
    this.cds.getClassificationData(queryParams).subscribe((res) => {
      // console.log(res);
      this.classificationSearchData = res;
      this.isApplicationDataEmpty =
        this.classificationSearchData.length === 0 ? true : false;
      this.showButton = false;
      this.Applicationpage = 1;
      this.TotalApplicationCount = res.length;
      this.loading = false;
    });
  }

  getbyenter(e: any) {
    this.loading = true;
    this.cds.getClassificationData(e).subscribe((res: any) => {
      this.classificationSearchData = res;
      this.isApplicationDataEmpty =
        this.classificationSearchData.length === 0 ? true : false;
      this.showButton = false;
      this.Applicationpage = 1;
      this.TotalApplicationCount = res.length;
      this.loading = false;
    });
  }

  viewAllClassifications() {
    this.loading = true;
    let queryParams = {
      jobCodeId: '',
      jobCodeDescription: '',
    };
    this.cds.getClassificationData(queryParams).subscribe((res) => {
      // console.log(res);
      this.classificationSearchData = res;
      this.Applicationpage = 1;
      this.TotalApplicationCount = res.length;
      this.loading = false;
    });
  }

  @ViewChild('classificationdataTablemaintenanceExportTable', {
    read: ElementRef,
    static: false,
  })
  classificationdataTablemaintenanceExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.classificationdataTablemaintenanceExportTable,
        `${ApplicationEnum.CLASSIFICATIONDATATABLEMAINTENANCE}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.classificationSearchData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.classificationSearchData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
