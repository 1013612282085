<div class="container-fluid app-box" style="background-color: white">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Transfer Applications</li>
        <li class="breadcrumb-item brd-li brd-li-child">Paragraph19</li>
      </ol>
    </nav>
  </div>

  <div *ngIf="isAdmin()">
    <div class="row content-container mt-2 mb-2">
      <div id="s12">
        <nav
          class="navbar navbar-expand-lg navbar-light bg-light applicationcss"
        >
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto"></ul>
            <form class="form-inline my-2 my-lg-0">
              <input
                class="select-radio"
                checked="checked"
                name="tool-and-die"
                type="radio"
                Id="1"
                (change)="onAppTypeSelected(1)"
              />
              <label class="select-label" for="1">Existing Application</label>
              <input
                *ngIf="views[0].allowAdd"
                class="select-radio"
                name="tool-and-die"
                type="radio"
                Id="2"
                (change)="onAppTypeSelected(2)"
              />
              <label *ngIf="views[0].allowAdd" class="select-label" for="2"
                >Add Application</label
              >
            </form>
          </div>
          <br />
        </nav>

        <!-- global search filter starts -->
        <div class="pt-2 div-color">
          <div class="search-divider-div filtercss">
            <p class="search-divder-p"></p>
            <hr class="search-divider-hr" />
            <!-- filer -->
            <div
              class="jumbotron jumb-new jumb-newcontrols"
              style="margin-bottom: 0px !important"
              *ngIf="masterSearchFilter"
            >
              <div class="row" display="float">
                <div class="col">
                  <app-filter
                    [filterConfig]="filterConfig"
                    (formcontrolobj)="getbyenter($event)"
                    (filterValue)="getFilterValue($event)"
                  >
                  </app-filter>
                </div>
                <div class="col-md-1 mr-5" style="padding-right: 80px">
                  <a
                    class="btn btn-lg search-button"
                    (click)="searchApplication()"
                    role="button"
                    >Search
                    <img
                      src="./assets/search-solid.svg"
                      class="img-fluid user-icon ml-1 search-icon"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="jumbotron jumb-new"
              style="margin-bottom: 0px !important; padding: 1rem 2rem"
              *ngIf="!masterSearchFilter"
            >
              <div class="row" display="float">
                <div class="col">
                  <app-filter
                    [filterConfigAdd]="filterConfigAdd"
                    (formcontrolobj)="getbyenterforuser($event)"
                    (filterValue)="getFilterDataUser($event)"
                  >
                  </app-filter>
                </div>
                <div class="col-md-1 mr-5">
                  <a
                    class="btn btn-lg search-button"
                    role="button"
                    (click)="searchUser()"
                    >Search
                    <img
                      src="./assets/search-solid.svg"
                      class="img-fluid user-icon ml-1 search-icon"
                    />
                  </a>
                </div>
              </div>
            </div>
            <!-- filter ends -->
          </div>
          <div class="">
            <!--For application-->
            <div *ngIf="isApplicationData">
              <div class="info-label divcrudbtns">
                <p style="text-align: left" class="p-margin"></p>
                <div *ngIf="views[0].allowUpdate">
                  <button
                    [disabled]="disablededit"
                    [ngClass]="disablededit ? 'disablededitcolors' : ''"
                    *ngIf="
                      (isApplicationSelected() && !EditButtonDisabled) ||
                      !EditButtonDisabled1
                    "
                    data-toggle="modal"
                    (click)="edit()"
                    [attr.data-target]="modalId"
                    data-backdrop="false"
                    style="margin-right: 0.5rem"
                    class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
                  >
                    Edit
                  </button>
                </div>
                <div *ngIf="views[0].allowDelete">
                  <button
                    [disabled]="disableddelete"
                    [ngClass]="disableddelete ? 'disablededitcolors' : ''"
                    *ngIf="isApplicationSelected() || !DeleteButtonDisabled1"
                    data-toggle="modal"
                    (click)="deleteApplication()"
                    [attr.data-target]="modalId"
                    data-backdrop="false"
                    style="margin-right: 0.5rem"
                    class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
                  >
                    Delete
                  </button>
                </div>
                <!-- <button *ngIf="!returnCancelledstatus && isApplicationSelected()"  data-toggle="modal" (click)="cancelApplication()"
                                    [attr.data-target]="modalId"
                                    data-backdrop="false"
                                    class="btn btn-success buttoncolours crudbuttons pull-right my-2 my-sm-0">Cancel
                                </button> -->
                <div
                  *ngIf="applicationData !== null && applicationData.length > 0"
                >
                  <button
                    style="margin-right: 0.5rem"
                    class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
                    (click)="exportElmToExcel()"
                  >
                    Export to Excel
                  </button>
                </div>
              </div>
              <!-- This is Export to document Section -->
              <table
                class="table table-striped"
                *ngIf="
                  !isDataExportableView &&
                  applicationData !== null &&
                  applicationData.length > 0
                "
                #Paragraph19ExportTable
              >
                <thead
                  style="position: sticky; top: 298px"
                  class="table-header"
                >
                  <tr>
                    <!-- <th scope="col">Select</th> -->
                    <th scope="col">Name</th>
                    <th scope="col">Employee Id</th>
                    <th scope="col">Classification</th>
                    <th scope="col">Status</th>
                    <th scope="col">Plant Seniority Date</th>
                    <th scope="col">GM Seniority Date</th>
                    <th scope="col">Date Signed</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let emp of applicationData; let i = index"
                    class=""
                  >
                    <!-- <tr *ngFor="let emp of applicationData, let i = index;" class='table-container'> -->
                    <!-- <td style="text-align:center;">
                                            <input type="radio" id="select" name="application" (click)="selectEmp(i, emp.appId)">
                                        </td> -->

                    <td class="left-tab-value">
                      {{ emp.lastName ? emp.lastName : '-' }},&nbsp;{{
                        emp.firstName ? emp.firstName : '-'
                      }}&nbsp;{{ emp.middleName ? emp.middleName : '' }}
                    </td>

                    <td class="tab-value">
                      {{ emp.employeeId ? emp.employeeId : '-' }}
                    </td>
                    <td class="tab-value">
                      {{ emp.jobCodeId ? emp.jobCodeId : '-' }}
                    </td>
                    <td class="left-tab-value">
                      {{ emp.appStatusCode ? emp.appStatusCode : '-' }}
                    </td>
                    <td class="tab-value">
                      {{ emp.plantSeniorityDate | date: 'MM/dd/yyyy' }}
                    </td>
                    <td class="tab-value">
                      {{
                        emp.gmSeniorityDate
                          ? (emp.gmSeniorityDate | date: 'MM/dd/yyyy')
                          : '-'
                      }}
                    </td>
                    <td class="tab-value">
                      {{ emp.empSignDate | date: 'MM/dd/yyyy' }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- This is Main Table Section -->
              <table
                class="table table-striped"
                *ngIf="applicationData !== null && applicationData.length > 0"
              >
                <thead
                  style="position: sticky; top: 298px"
                  class="table-header"
                >
                  <th scope="col">Select</th>
                  <!-- <th scope="col">Name</th>
                                    <th scope="col">Employee Id</th>
                                    <th scope="col">Classification</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Plant Seniority Date</th>
                                    <th scope="col">GM Seniority Date</th>
                                    <th scope="col">Date Signed</th> -->
                  <th scope="col">
                    <span (click)="onSortClick($event, 'lastName', 'string')"
                      >Name
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'lastName'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'lastName'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'lastName'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'employeeId', 'string')"
                      >Emp Id
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'employeeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'employeeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'employeeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'jobCodeId', 'string')"
                      >Class
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'jobCodeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'jobCodeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'jobCodeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'appStatusCode', 'string')"
                      >Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'appStatusCode'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'appStatusCode'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'appStatusCode'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'plantSeniorityDateSort', 'string')
                      "
                      >Plant Sen Date
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'plantSeniorityDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'plantSeniorityDateSort', 'string')
                      "
                      >GM Sen Date
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'plantSeniorityDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'empSignDateSort', 'string')"
                      >Date Signed
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'empSignDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let emp of applicationData
                        | paginate
                          : {
                              id: 'joblist',
                              itemsPerPage: 20,
                              currentPage: Applicationpage,
                              totalItems: TotalApplicationCount
                            };
                      let i = index
                    "
                    class=""
                  >
                    <!-- <tr *ngFor="let emp of applicationData, let i = index;" class='table-container'> -->
                    <td style="text-align: center">
                      <input
                        type="radio"
                        id="select"
                        name="application"
                        (click)="selectEmp(i, emp.appId)"
                      />
                    </td>
                    <td class="left-tab-value">
                      {{ emp.lastName ? emp.lastName : '-' }},&nbsp;{{
                        emp.firstName ? emp.firstName : '-'
                      }}&nbsp;{{ emp.middleName ? emp.middleName : '' }}
                    </td>

                    <td class="tab-value">
                      {{ emp.employeeId ? emp.employeeId : '-' }}
                    </td>
                    <td class="tab-value">
                      {{ emp.jobCodeId ? emp.jobCodeId : '-' }}
                    </td>
                    <td class="left-tab-value">
                      {{ emp.appStatusCode ? emp.appStatusCode : '-' }}
                    </td>
                    <td class="tab-value">
                      {{ emp.plantSeniorityDate | date: 'MM/dd/yyyy' }}
                    </td>
                    <td class="tab-value">
                      {{
                        emp.gmSeniorityDate
                          ? (emp.gmSeniorityDate | date: 'MM/dd/yyyy')
                          : '-'
                      }}
                    </td>
                    <td class="tab-value">
                      {{ emp.empSignDate | date: 'MM/dd/yyyy' }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="single-wrap d-flex justify-content-center"
                *ngIf="applicationData !== null && applicationData.length > 0"
              >
                <nav aria-label="Page navigation example">
                  <pagination-controls
                    class="my-pagination"
                    id="joblist"
                    (pageChange)="Applicationpage = $event"
                    previousLabel=""
                    nextLabel=""
                  >
                  </pagination-controls>
                </nav>
              </div>
              <div *ngIf="isApplicationData">
                <p *ngIf="!isApplicationDataExists">
                  No existing records Found
                </p>
              </div>
            </div>
            <!--For user-->
            <div
              style="
                height: 15px;
                background: white;
                position: sticky;
                top: 139px;
              "
            ></div>
            <div *ngIf="!isApplicationData">
              <table
                class="table table-striped"
                *ngIf="userData !== null && userData.length > 0"
              >
                <thead
                  class="table-header"
                  style="position: sticky; top: 153px !important"
                >
                  <th scope="col">Employee Name</th>
                  <th scope="col">Employee Id</th>
                  <th scope="col">Current Classification</th>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let emp of userData
                        | paginate
                          : {
                              id: 'joblist',
                              itemsPerPage: 20,
                              currentPage: UserPage,
                              totalItems: TotalApplicationCount
                            };
                      let i = index
                    "
                    class="table-container"
                  >
                    <!-- <tr *ngFor="let emp of userData, let i = index;" class='table-container'> -->
                    <td class="left-tab-value">
                      <p
                        class="my-2 my-sm-0 click-add"
                        type="submit"
                        data-toggle="modal"
                        [attr.data-target]="modalId"
                        data-backdrop="false"
                        (click)="addApplication(i, emp.employeeId)"
                      >
                        {{ emp.lastName ? emp.lastName : '-' }},&nbsp;{{
                          emp.firstName ? emp.firstName : '-'
                        }}&nbsp;{{ emp.middleName ? emp.middleName : '' }}
                      </p>
                    </td>
                    <td class="tab-value">
                      {{ emp.employeeId ? emp.employeeId : '-' }}
                    </td>
                    <td class="tab-value">
                      {{ emp.jobCodeId ? emp.jobCodeId : '-' }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="single-wrap d-flex justify-content-center"
                *ngIf="userData !== null && userData.length > 0"
              >
                <nav aria-label="Page navigation example">
                  <pagination-controls
                    class="my-pagination"
                    id="joblist"
                    (pageChange)="UserPage = $event"
                    previousLabel=""
                    nextLabel=""
                  >
                  </pagination-controls>
                </nav>
              </div>
            </div>
            <div *ngIf="!isApplicationData">
              <p *ngIf="userData !== null && userData.length === 0">
                No User Found
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isAdmin()">
    <div
      *ngIf="userInformation !== undefined && userInformation !== null"
      class="row content-container mt-2 mb-2"
    >
      <!-- global search filter starts -->
      <div class="pt-2 div-color">
        <div class="search-divider-div">
          <p class="search-divder-p"></p>

          <div
            class="jumbotron jumb-new jumb-newcontrols"
            style="margin-bottom: 0px"
          >
            <div class="row userinfo-header-div" display="float">
              <div class="col">
                <h1 class="h1-label">
                  Welcome! {{ userInformation.firstName }}
                </h1>
              </div>
              <!-- <div class="col-md-1 mr-5 add-button-div">
                                <button *ngIf="!returnOpenStatus && isLoggedInUserAdmin"
                                    class="btn btn-lg search-button add-button-a button-align" type="submit"
                                    data-toggle="modal" [attr.data-target]="modalId"
                                    data-backdrop="false" (click)="addApplicationUAW()"
                                    role="button">Add Application
                                </button>

                            </div> -->
            </div>
          </div>
          <div class="empdescription-box">
            <div class="empdescription-table">
              <table>
                <tr>
                  <th class="empdescription-th emp-atid">Employee Id:</th>
                  <th class="empdescription-th">Current Classification:</th>
                  <th class="empdescription-th">Current Department:</th>
                  <th class="empdescription-th">Current Shift:</th>
                </tr>
                <tr *ngIf="usersData !== undefined">
                  <td class="empdescription-table-tr">
                    {{ usersData.employeeId }}
                  </td>
                  <td class="empdescription-table-tr">
                    {{ usersData.jobCodeId }}
                    {{ usersData.jobCodeDescription }}
                  </td>
                  <td class="empdescription-table-tr">
                    {{ usersData.deptId }}
                  </td>
                  <td class="empdescription-table-tr">
                    {{ usersData.shiftDescription }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- filter ends -->
        </div>
        <div class="pt-5">
          <!--For application-->
          <div>
            <div class="info-label mb-2">
              <p style="text-align: left">
                <button
                  *ngIf="
                    isApplicationSelected() &&
                    !returnCancelledstatus &&
                    applicationUawData !== undefined &&
                    applicationUawData.length > 0
                  "
                  class="btn buttoncolours pull-right my-2 my-sm-0"
                  data-toggle="modal"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                  (click)="cancelApplication()"
                >
                  Cancel
                </button>
              </p>
            </div>
            <table
              class="table table-striped"
              *ngIf="
                applicationUawData !== null && applicationUawData.length > 0
              "
            >
              <thead class="table-header">
                <th scope="col">#</th>
                <th scope="col">Applied for Classification</th>
                <th scope="col">Status</th>
                <th scope="col">Date Signed</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="let emp of applicationUawData; let i = index"
                  class="table-container"
                >
                  <td>
                    <input
                      type="radio"
                      id="select"
                      name="application"
                      (click)="selectUserEmp(i)"
                    />
                  </td>

                  <td class="tab-value">
                    {{ emp.jobCodeId ? emp.jobCodeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.appStatusCode ? emp.appStatusCode : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.empSignDate | date: 'MM/dd/yyyy' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="isApplicationData">
              <p
                *ngIf="
                  applicationUawData !== null && applicationUawData.length === 0
                "
              >
                Your requested application will display here. <br />
                Note: You can not edit your application.
              </p>

              <p
                class="text-danger"
                *ngIf="
                  applicationUawData !== null && applicationUawData.length === 0
                "
              >
                <b>No active Paragraph 19 application found.</b> <br />
                <small class="text-danger" *ngIf="!isLoggedInUserAdmin">
                  See Employee Relations regarding Paragraph 19 applications.
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Edit Modal -->
  <app-edit-paragraph19-application
    [selectedEmp]="selectedEmp"
    [userInformation]="userInformation"
    [isEdit]="isEdit"
    (clean)="cleanEmp($event)"
    (isSucess)="CheckEdit($event)"
    [title]="modalTitle"
    *ngIf="isEdit"
  >
  </app-edit-paragraph19-application>

  <!--Add  Modal-->
  <app-add-paragraph19
    [selectedEmp]="selectedEmp"
    [isAdd]="isAdd"
    [loggedInUser]="userInformation"
    (clean)="cleanEmp($event)"
    [isLoggedInUserAdmin]="isLoggedInUserAdmin"
    (isSucess)="CheckAdd($event)"
    *ngIf="isAdd"
  >
  </app-add-paragraph19>

  <!--Cancel  Modal-->
  <app-cancel-paragraph19-application
    [selectedEmp]="selectedEmp"
    [userInformation]="userInformation"
    [isLoggedInUserAdmin]="isLoggedInUserAdmin"
    (isSucess)="CheckCancel($event)"
  >
  </app-cancel-paragraph19-application>

  <!--Delete  Modal-->
  <app-delete-paragraph19-application
    [selectedEmp]="selectedEmp"
    (isSucess)="CheckDelete($event)"
  >
  </app-delete-paragraph19-application>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
