import { formatDate } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { OverTimeMaintanceService } from 'src/app/Services/Applications/OvertimeMaintanence/overtime.maintanence.service';
import { LocalStorageService } from 'src/app/Services/LocalStorageService/locastorage.service';
import { RoleMatchService } from 'src/app/Services/role-match.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';
import { UserRoles } from 'src/enum/userroles';
import { userData } from 'src/models/userdata';
import { UserInfo } from 'src/models/userinfo';

@Component({
  selector: 'app-overtime-detail',
  templateUrl: './overtimedetail.component.html',
  styleUrls: ['./overtimedetail.component.css'],
})
export class OvertimeDetailComponent implements OnInit, OnDestroy {
  isView: any;
  emloyeeData: any = [];
  userData: any = [];
  modalPopTitle = {
    title: '',
    type: '',
  };

  employeeForm!: FormGroup;
  selectedEmp: any;
  isEditButton: boolean = false;
  isAddButton: boolean = false;
  isEdit: boolean = false;
  isCancel: boolean = false;
  modalTitle: any = '';
  isAdd: boolean = false;
  AppType: string = '150';
  modalId: string | any;
  overtimeGroupModalId: string | any;
  typeSelected: any;
  screenType: any = [];
  isApplication: boolean = false;
  searchKey: string = '';
  isDeleted: boolean = false;
  showFilter: boolean = true;
  applicationData: any = [];
  demographData: any;
  userInformation!: UserInfo; // Model for UserInfo
  usersData!: userData; // Model for UAW user details
  userRole: UserRoles = UserRoles.SKILLEDUSER; // Enum for userrole
  returnstatus!: boolean; // stores cancelled status fif any
  returnOpenStatus!: boolean; // stores open status if any
  isUser: boolean = false;
  filterText: string = 'normal';
  DepartmentDefaultVal: string = '0330';
  atid: undefined;
  filterData: any = {};
  filterDataUser: any = {};
  routeData$: any;
  event$: any;
  accHours: any;
  enableIndButtons: boolean = false;
  recordId: any;
  DefaultOverTimeTypeOvt: any;
  DefaultOverTimeStatusOvt: any;
  DefaultStartDateOvt: any;
  DefaultEndDateOvt: any;
  loggedInUserATID: string = '';
  isArchive: boolean = false;
  isReassignOvertimeGroup: boolean = false;
  isReassignRepresentationOvertimeGroup: boolean = false;
  isOvertimeDataEmpty: boolean = true;
  isSearchHit: boolean = false;
  departmentId: any;
  jobCodeId: any;
  shiftId: any;
  searchType: any;
  checkedAtidByuser: any;
  recordAccHours: any;
  views: any[] = [];

  constructor(
    private fb: FormBuilder,
    private ot: OverTimeMaintanceService,
    private sweetAlert: SweetAlertService,
    private router: Router,
    private localStorageService: LocalStorageService,
    public roleService: RoleMatchService,
    public _userInfo: UsergroupService,
    private _commonApplicationService: CommonService
  ) {
    let employeeid = this.localStorageService.getItem('ovtAtid');
    this.accHours = this.localStorageService.getItem('ovtAtidAccHours');
    this.checkedAtidByuser = employeeid;
    this.recordAccHours = this.accHours;
    this.getUserRoleDescreption();
    this.ot.NavigateToDetailByUser(employeeid).subscribe((data) => {
      this.demographData = data;
      // this.submitForm();
    });
    this.isView = this.localStorageService.getItem('isView');
  }

  ngOnDestroy(): void {
    if (
      this.router.url !== '/Overtime' &&
      this.router.url !== '/OvertimeView'
    ) {
      this.localStorageService.removeItem('searchType');
      this.localStorageService.removeItem('deptId');
      this.localStorageService.removeItem('jobCodeId');
      this.localStorageService.removeItem('shiftId');
      this.localStorageService.removeItem('asOfDate');
      this.localStorageService.removeItem('overTimeTypeId');
      this.localStorageService.removeItem('toggleMainDetail');
    }

    this.localStorageService.removeItem('ovtAtid');
    this.localStorageService.removeItem('ovtAtidAccHours');
    this.localStorageService.removeItem('isView');
  }

  ngOnInit(): void {
    this.DefaultOverTimeTypeOvt = '';
    this.DefaultOverTimeStatusOvt = '';
    const format = 'yyyy-MM-dd';
    const locale = 'en-US';
    var dtAsOfDate = new Date();
    var weekStart = new Date(
      dtAsOfDate.setDate(dtAsOfDate.getDate() - dtAsOfDate.getDay() + 1)
    );
    var weekEnd = new Date(
      dtAsOfDate.setDate(dtAsOfDate.getDate() - dtAsOfDate.getDay() + 7)
    );
    this.DefaultStartDateOvt = formatDate(weekStart, format, locale);
    this.DefaultEndDateOvt = formatDate(weekEnd, format, locale);
  }

  getUserRoleDescreption() {
    this._userInfo.getUserInfo().subscribe((data: any) => {
      this.userRole =
        data['groupDescription'] === UserRoles.HRPLUS
          ? UserRoles.HRPLUS
          : UserRoles.SKILLEDUSER;
      this.userInformation = data;
      this.loggedInUserATID = this.userInformation.userName;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'OVERTIME',
        'OVERTIME'
      );
    });
  }

  addEmployee(idx: number) {
    this.selectUser(idx);
    if (this.selectedEmp) {
      this.isAdd = true;
      this.modalId = '#addEmployee';
    } else {
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }

  //For User
  selectUser(idx: number) {
    this.userData[idx].selected = true;
    for (let index = 0; index < this.userData.length; index++) {
      this.userData[index].selected = false;
    }
    this.userData[idx].selected = true;
    this.selectedEmp = this.userData[idx];
  }

  disableSubmit!: boolean | false;
  ModelRepopulateHandler(event: any) {
    this.postModel = event.key;
    this.disableSubmit = true;
  }

  postModel: any;
  onAppTypeSelected(val: number) {
    if (val === 2) {
      this.showFilter = false;
    }
    if (val === 1) {
      this.showFilter = true;
    }
    this.userData = null;
  }

  onIndEmployeeSelect(idx: number, recordId: number) {
    this.enableIndButtons = true;
    this.selectedEmp = this.userData[idx];
  }

  submitForm() {
    this.enableIndButtons = false;
    this.postModel.EMPLOYEEID = this.demographData.employeeid;
    const format = 'MM/dd/yyyy';
    const locale = 'en-US';
    var dtAsOfDate = new Date();
    var weekStart = new Date(
      dtAsOfDate.setDate(dtAsOfDate.getDate() - dtAsOfDate.getDay() + 1)
    );
    var weekEnd = new Date(
      dtAsOfDate.setDate(dtAsOfDate.getDate() - dtAsOfDate.getDay() + 7)
    );
    if (
      new Date(this.postModel.DATEOFWORKSTART).valueOf() >
      new Date(this.postModel.DATEOFWORKEND).valueOf()
    ) {
      this.sweetAlert.validationError(
        'error',
        'Overtime Detail',
        'End Date cannot be early than Start Date'
      );
    }

    if (
      this.postModel.DATEOFWORKSTART === '' &&
      this.postModel.DATEOFWORKEND === ''
    ) {
      if (this.postModel.DATEOFWORKSTART === '') {
        this.postModel.DATEOFWORKSTART = formatDate(weekStart, format, locale);
      }
      if (this.postModel.DATEOFWORKEND === '') {
        this.postModel.DATEOFWORKEND = formatDate(weekEnd, format, locale);
      }
    }
    if (this.postModel.DATEOFWORKSTART === '') {
      this.postModel.DATEOFWORKSTART = formatDate(weekStart, format, locale);
    }
    if (this.postModel.DATEOFWORKEND === '') {
      this.postModel.DATEOFWORKEND = formatDate(weekEnd, format, locale);
    }
    //console.log(this.postModel);
    this.ot.SearchOverTimeDetailByUser(this.postModel).subscribe((data) => {
      this.userData = data;
      //console.log(this.userData);
      this.isOvertimeDataEmpty = this.userData.length > 0 ? false : true;
      this.isSearchHit = true;
    });
  }

  changeRadio(val: any) {
    this.recordId = val.recordid;
  }

  CheckDelete(e: any) {
    this.isDeleted = e;
    this.enableIndButtons = false;
    for (let data of this.userData) {
      if (data.recordid === this.selectedEmp.recordid) {
        this.userData.splice(this.userData.indexOf(data), 1);
        break;
      }
    }
  }

  CheckArchive(e: any) {
    this.isArchive = e;
  }

  delete() {
    if (this.selectedEmp) {
      this.modalId = '#deleteOvertimeModal';
    } else {
      this.modalId = '';
      alert('No Record Selected');
    }
  }

  archiveAll() {
    this.modalTitle = 'Archive Record';
    this.isArchive = true;
    this.modalId = '#archiveOvertimeModal';
  }

  cleanEmp(event: any) {}

  MainNavigate() {
    if (this.isView === 'Y') {
      this.router.navigate(['OvertimeView']);
    } else {
      this.router.navigate(['Overtime']);
    }
  }

  OvertimeReassignRepGroupNavigate() {
    this.modalTitle = 'Reassign Representation OvertimeGroup';
    this.isReassignRepresentationOvertimeGroup = true;
    this.modalId = '#ReassignOvertimeRepGroupModal';
  }

  isSucess: boolean = false;
  CheckMassUpdate(e: any) {
    this.isSucess = e;
    if (this.isSucess === true) {
      this.userData = this.submitForm();
      this.enableIndButtons = false;
    }
  }

  modifiedOvertimRecords: any[] = [];
  passUpdateData: any[] = [];
  isUpdate: boolean = false;
  isSingleRecord: boolean = true;

  onIndOvertimeUpdate() {
    this.passUpdateData.length = 0;
    this.modifiedOvertimRecords.length = 0;
    var tempPassUpdateData: any[] = [];
    var tempModifiedOvertimRecords: any[] = [];
    tempPassUpdateData.push(this.selectedEmp);
    var isSingleRec = true;
    this.isSingleRecord = isSingleRec;
    this.passUpdateData = JSON.parse(JSON.stringify(tempPassUpdateData));
    //console.log(this.passUpdateData);
    this.modifiedOvertimRecords = JSON.parse(
      JSON.stringify(tempModifiedOvertimRecords)
    );
    if (this.passUpdateData) {
      this.isUpdate = true;
      this.modalId = '#updateEmployee';
    } else {
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }

  ReassginOvertimeNaviagte() {
    this.modalTitle = 'Reassign OvertimeGroup';
    this.isReassignOvertimeGroup = true;
    this.modalId = '#reassignOvertimeGroupModal';
  }

  onReassignOvertime(e: any) {
    let employeeid = this.localStorageService.getItem('ovtAtid');
    if (e) {
      this.ot.NavigateToDetailByUser(employeeid).subscribe((data) => {
        this.demographData = data;
      });
    }
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.userData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.userData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }

  @ViewChild('OvertimeTableDetailsExport', { read: ElementRef, static: false })
  OvertimeTableDetailsExport!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.OvertimeTableDetailsExport,
        `${ApplicationEnum.OVERTIMEDETAILS}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }
}
