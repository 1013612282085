import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonUtility } from 'src/common/commonUtility'
import { MSALserviceService } from '../MSALservice/msalservice.service';


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private readonly api_getModules = '/Modules';
  private readonly api_modifyModules = '/ModifyPermissions';
  commonUtility = new CommonUtility();
  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  getModules(groupParam: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.adminURL}` + this.api_getModules,
       groupParam,
      { headers }
    );
  }

  modifyPermission(modifiedModules: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.put<any>(
      `${environment.adminURL}` + this.api_modifyModules,
      modifiedModules,
      { headers }
    );
  }
}
