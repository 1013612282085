<div class="container-fluid app-box">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Reports & Charts</li>
        <li class="breadcrumb-item brd-li brd-li-child">Action Code Changes</li>
      </ol>
    </nav>
    <!-- <div *ngIf="ActionCodeChangeData !== null && ActionCodeChangeData.length > 0">
            <button class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0" style="margin-left: 15px;"
                (click)="exportElmToExcel()">Export to Excel</button>
        </div> -->

    <div
      *ngIf="ActionCodeChangeData !== null && ActionCodeChangeData.length > 0"
      style="float: right; position: absolute; right: 50px"
    >
      <button
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        style="margin-left: 15px"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
    </div>
  </div>

  <div>
    <div class="row content-container mt-2 mb-2">
      <div class="pt-2 div-color">
        <div class="search-divider-div filtercss">
          <p class="search-divder-p"></p>
          <hr class="search-divider-hr" />
          <div class="jumbotron jumb-new jumb-newcontrols">
            <div class="row" display="float">
              <div class="col">
                <div>
                  <div class="form-row">
                    <div
                      class="form-group col-md-3"
                      style="margin-bottom: 0.3rem !important"
                    >
                      <label
                        class="filter-label"
                        style="color: white !important"
                        >From Date
                      </label>
                      <input
                        type="date"
                        [(ngModel)]="srcFormattedFromDate"
                        placeholder="From Date"
                        (change)="onFromDateChange($event)"
                        class="form-control"
                      />
                    </div>
                    <div
                      class="form-group col-md-3"
                      style="margin-bottom: 0.3rem !important"
                    >
                      <label
                        class="filter-label"
                        style="color: white !important"
                        >To Date</label
                      >
                      <input
                        type="date"
                        [(ngModel)]="srcFormattedToDate"
                        placeholder="To Date"
                        (change)="onToDateChange($event)"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <!-- 
                                    <app-filter [filterConfig]="filterConfig"                                   
                                        (formcontrolobj)="getbyenter($event)" (filterValue)="getFilterValue($event)" >
                                    </app-filter> -->
              </div>
              <div class="col-md-1 mr-5">
                <label for="" style="color: white">&nbsp;</label>
                <a
                  class="btn btn-lg search-button"
                  (click)="getActionCodeChanges()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          style="
            height: 8px;
            background-color: white;
            position: sticky;
            top: 102px;
          "
        ></div>
        <div>
          <div>
            <!-- this is export to excel table -->
            <table
              class="table table-striped"
              *ngIf="
                !isDataExportableView &&
                ActionCodeChangeData !== null &&
                ActionCodeChangeData.length > 0
              "
              #ActionCodeChangeHistoryExportTable
            >
              <thead class="table-header">
                <tr>
                  <th scope="col" style="width: 3%">No.</th>
                  <th scope="col" style="width: 8%">
                    <span (click)="onSortClick($event, 'empID', 'string')"
                      >Emp Id
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'empID'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'empID'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'empID'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" style="width: 15%">
                    <span (click)="onSortClick($event, 'empName', 'string')"
                      >Emp Name
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'empName'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'empName'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'empName'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" style="width: 10%">
                    <span
                      (click)="
                        onSortClick($event, 'action_Effective_Date', 'string')
                      "
                      >Action Date
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'action_Effective_Date'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'action_Effective_Date'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'action_Effective_Date'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" style="width: 5%">
                    <span (click)="onSortClick($event, 'oldAction', 'string')"
                      >Old Action
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'oldAction'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'oldAction'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'oldAction'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" style="width: 5%">
                    <span (click)="onSortClick($event, 'newAction', 'string')"
                      >New Action
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'newAction'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'newAction'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'newAction'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" style="width: 5%">
                    <span (click)="onSortClick($event, 'oldPlantID', 'string')"
                      >Old Plant
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'oldPlantID'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'oldPlantID'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'oldPlantID'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" style="width: 5%">
                    <span (click)="onSortClick($event, 'plantID', 'string')">
                      New Plant
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'plantID'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'plantID'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'plantID'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" style="width: 5%">
                    <span (click)="onSortClick($event, 'oldDept', 'string')"
                      >Old Dept
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'oldDept'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'oldDept'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'oldDept'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" style="width: 5%">
                    <span (click)="onSortClick($event, 'dept', 'string')">
                      New Dept
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'dept'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'dept'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'dept'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" style="width: 5%">
                    <span
                      (click)="
                        onSortClick($event, 'old_JobClassification', 'string')
                      "
                      >Old Class
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'old_JobClassification'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'old_JobClassification'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'old_JobClassification'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" style="width: 5%">
                    <span (click)="onSortClick($event, 'costCenter', 'string')"
                      >New Class
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'costCenter'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'costCenter'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'costCenter'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" style="width: 8%">
                    <span (click)="onSortClick($event, 'oldShift', 'string')"
                      >Old Shift
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'oldShift'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'oldShift'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'oldShift'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" style="width: 8%">
                    <span (click)="onSortClick($event, 'shift', 'string')"
                      >New Shift
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'shift'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'shift'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'shift'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">Application Comments</th>
                  <th scope="col">Overtime Comments</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of ActionCodeChangeData; let i = index">
                  <td class="tab-value" style="width: 3%">{{ i + 1 }}</td>
                  <td class="tab-value" style="width: 8%">{{ data.empID }}</td>
                  <td class="left-tab-value" style="width: 15%">
                    {{ data.empName }}
                  </td>
                  <td class="tab-value" style="width: 10%">
                    {{ data.action_Effective_Date | date: 'MM/dd/yyyy' }}
                  </td>
                  <td class="tab-value" style="width: 5%">
                    {{ data.oldAction }}
                  </td>
                  <td class="tab-value" style="width: 5%">
                    {{ data.newAction }}
                  </td>
                  <td class="tab-value" style="width: 5%">
                    {{ data.oldPlantId }}
                  </td>
                  <td class="tab-value" style="width: 5%">
                    {{ data.newPlantId }}
                  </td>
                  <td class="tab-value" style="width: 5%">
                    {{ data.oldDept }}
                  </td>
                  <td class="tab-value" style="width: 5%">
                    {{ data.newDept }}
                  </td>
                  <td class="tab-value" style="width: 5%">
                    {{ data.oldJobCodeId }}
                  </td>
                  <td class="tab-value" style="width: 5%">
                    {{ data.newJobCodeId }}
                  </td>
                  <td class="tab-value" style="width: 8%">
                    <span *ngIf="data.oldShift === 1">Day</span>
                    <span *ngIf="data.oldShift === 2">Afternoon</span>
                    <span *ngIf="data.oldShift === 3">Night</span>
                  </td>
                  <td class="tab-value" style="width: 8%">
                    <span *ngIf="data.newShift === 1">Day</span>
                    <span *ngIf="data.newShift === 2">Afternoon</span>
                    <span *ngIf="data.newShift === 3">Night</span>
                  </td>
                  <td class="tab-value">
                    {{ data.applicationCommentsRaw }}
                  </td>
                  <td class="tab-value">
                    {{ data.overtimeCommentsRaw }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- this is original table -->
            <table class="table table-striped">
              <thead class="table-header">
                <tr>
                  <td colspan="11">
                    <table style="width: 100%">
                      <th scope="col" style="width: 3%">No.</th>
                      <th scope="col" style="width: 8%">
                        <span (click)="onSortClick($event, 'empID', 'string')"
                          >Emp Id
                          <img
                            *ngIf="sortDir === 1 && sortColumn === 'empID'"
                            src="./assets/downarrow.png"
                          />
                          <img
                            *ngIf="sortDir === -1 && sortColumn === 'empID'"
                            src="./assets/uparrow.png"
                          />
                          <img
                            *ngIf="sortDir !== 0 && sortColumn !== 'empID'"
                            src="./assets/nosort.png"
                          />
                        </span>
                      </th>
                      <th scope="col" style="width: 15%">
                        <span (click)="onSortClick($event, 'empName', 'string')"
                          >Emp Name
                          <img
                            *ngIf="sortDir === 1 && sortColumn === 'empName'"
                            src="./assets/downarrow.png"
                          />
                          <img
                            *ngIf="sortDir === -1 && sortColumn === 'empName'"
                            src="./assets/uparrow.png"
                          />
                          <img
                            *ngIf="sortDir !== 0 && sortColumn !== 'empName'"
                            src="./assets/nosort.png"
                          />
                        </span>
                      </th>
                      <th scope="col" style="width: 10%">
                        <span
                          (click)="
                            onSortClick(
                              $event,
                              'action_Effective_Date',
                              'string'
                            )
                          "
                          >Action Date
                          <img
                            *ngIf="
                              sortDir === 1 &&
                              sortColumn === 'action_Effective_Date'
                            "
                            src="./assets/downarrow.png"
                          />
                          <img
                            *ngIf="
                              sortDir === -1 &&
                              sortColumn === 'action_Effective_Date'
                            "
                            src="./assets/uparrow.png"
                          />
                          <img
                            *ngIf="
                              sortDir !== 0 &&
                              sortColumn !== 'action_Effective_Date'
                            "
                            src="./assets/nosort.png"
                          />
                        </span>
                      </th>
                      <th scope="col" style="width: 5%">
                        <span
                          (click)="onSortClick($event, 'oldAction', 'string')"
                          >Old Action
                          <img
                            *ngIf="sortDir === 1 && sortColumn === 'oldAction'"
                            src="./assets/downarrow.png"
                          />
                          <img
                            *ngIf="sortDir === -1 && sortColumn === 'oldAction'"
                            src="./assets/uparrow.png"
                          />
                          <img
                            *ngIf="sortDir !== 0 && sortColumn !== 'oldAction'"
                            src="./assets/nosort.png"
                          />
                        </span>
                      </th>
                      <th scope="col" style="width: 5%">
                        <span
                          (click)="onSortClick($event, 'newAction', 'string')"
                          >New Action
                          <img
                            *ngIf="sortDir === 1 && sortColumn === 'newAction'"
                            src="./assets/downarrow.png"
                          />
                          <img
                            *ngIf="sortDir === -1 && sortColumn === 'newAction'"
                            src="./assets/uparrow.png"
                          />
                          <img
                            *ngIf="sortDir !== 0 && sortColumn !== 'newAction'"
                            src="./assets/nosort.png"
                          />
                        </span>
                      </th>
                      <th scope="col" style="width: 5%">
                        <span
                          (click)="onSortClick($event, 'oldPlantId', 'string')"
                          >Old Plant
                          <img
                            *ngIf="sortDir === 1 && sortColumn === 'oldPlantId'"
                            src="./assets/downarrow.png"
                          />
                          <img
                            *ngIf="
                              sortDir === -1 && sortColumn === 'oldPlantId'
                            "
                            src="./assets/uparrow.png"
                          />
                          <img
                            *ngIf="sortDir !== 0 && sortColumn !== 'oldPlantId'"
                            src="./assets/nosort.png"
                          />
                        </span>
                      </th>
                      <th scope="col" style="width: 5%">
                        <span
                          (click)="onSortClick($event, 'newPlantId', 'string')"
                        >
                          New Plant
                          <img
                            *ngIf="sortDir === 1 && sortColumn === 'newPlantId'"
                            src="./assets/downarrow.png"
                          />
                          <img
                            *ngIf="
                              sortDir === -1 && sortColumn === 'newPlantId'
                            "
                            src="./assets/uparrow.png"
                          />
                          <img
                            *ngIf="sortDir !== 0 && sortColumn !== 'newPlantId'"
                            src="./assets/nosort.png"
                          />
                        </span>
                      </th>
                      <th scope="col" style="width: 5%">
                        <span (click)="onSortClick($event, 'oldDept', 'string')"
                          >Old Dept
                          <img
                            *ngIf="sortDir === 1 && sortColumn === 'oldDept'"
                            src="./assets/downarrow.png"
                          />
                          <img
                            *ngIf="sortDir === -1 && sortColumn === 'oldDept'"
                            src="./assets/uparrow.png"
                          />
                          <img
                            *ngIf="sortDir !== 0 && sortColumn !== 'oldDept'"
                            src="./assets/nosort.png"
                          />
                        </span>
                      </th>
                      <th scope="col" style="width: 5%">
                        <span
                          (click)="onSortClick($event, 'newDept', 'string')"
                        >
                          New Dept
                          <img
                            *ngIf="sortDir === 1 && sortColumn === 'newDept'"
                            src="./assets/downarrow.png"
                          />
                          <img
                            *ngIf="sortDir === -1 && sortColumn === 'newDept'"
                            src="./assets/uparrow.png"
                          />
                          <img
                            *ngIf="sortDir !== 0 && sortColumn !== 'newDept'"
                            src="./assets/nosort.png"
                          />
                        </span>
                      </th>
                      <th scope="col" style="width: 5%">
                        <span
                          (click)="
                            onSortClick($event, 'oldJobCodeId', 'string')
                          "
                          >Old Class
                          <img
                            *ngIf="
                              sortDir === 1 && sortColumn === 'oldJobCodeId'
                            "
                            src="./assets/downarrow.png"
                          />
                          <img
                            *ngIf="
                              sortDir === -1 && sortColumn === 'oldJobCodeId'
                            "
                            src="./assets/uparrow.png"
                          />
                          <img
                            *ngIf="
                              sortDir !== 0 && sortColumn !== 'oldJobCodeId'
                            "
                            src="./assets/nosort.png"
                          />
                        </span>
                      </th>
                      <th scope="col" style="width: 5%">
                        <span
                          (click)="
                            onSortClick($event, 'newJobCodeId', 'string')
                          "
                          >New Class
                          <img
                            *ngIf="
                              sortDir === 1 && sortColumn === 'newJobCodeId'
                            "
                            src="./assets/downarrow.png"
                          />
                          <img
                            *ngIf="
                              sortDir === -1 && sortColumn === 'newJobCodeId'
                            "
                            src="./assets/uparrow.png"
                          />
                          <img
                            *ngIf="
                              sortDir !== 0 && sortColumn !== 'newJobCodeId'
                            "
                            src="./assets/nosort.png"
                          />
                        </span>
                      </th>
                      <th scope="col" style="width: 8%">
                        <span
                          (click)="onSortClick($event, 'oldShift', 'string')"
                          >Old Shift
                          <img
                            *ngIf="sortDir === 1 && sortColumn === 'oldShift'"
                            src="./assets/downarrow.png"
                          />
                          <img
                            *ngIf="sortDir === -1 && sortColumn === 'oldShift'"
                            src="./assets/uparrow.png"
                          />
                          <img
                            *ngIf="sortDir !== 0 && sortColumn !== 'oldShift'"
                            src="./assets/nosort.png"
                          />
                        </span>
                      </th>
                      <th scope="col" style="width: 8%">
                        <span
                          (click)="onSortClick($event, 'newShift', 'string')"
                          >New Shift
                          <img
                            *ngIf="sortDir === 1 && sortColumn === 'newShift'"
                            src="./assets/downarrow.png"
                          />
                          <img
                            *ngIf="sortDir === -1 && sortColumn === 'newShift'"
                            src="./assets/uparrow.png"
                          />
                          <img
                            *ngIf="sortDir !== 0 && sortColumn !== 'newShift'"
                            src="./assets/nosort.png"
                          />
                        </span>
                      </th>
                      <th scope="col" (click)="ShowAllFields($event)">
                        <span *ngIf="!AllShow">Show </span>
                        <span *ngIf="AllShow">Hide </span>
                        Comments
                        <img
                          src="./assets/white-dbl-down-arrow.png"
                          class="hdrShrink"
                          *ngIf="!AllShow"
                        />
                        <img
                          src="./assets/white-dbl-up-arrow.png"
                          class="hdrShrink"
                          width="25px !important"
                          *ngIf="AllShow"
                        />
                      </th>
                    </table>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of ActionCodeChangeData; let i = index">
                  <td colspan="11">
                    <table style="width: 100%">
                      <tr class="">
                        <td class="tab-value" style="width: 3%">{{ i + 1 }}</td>
                        <td class="tab-value" style="width: 8%">
                          {{ data.empID }}
                        </td>
                        <td class="left-tab-value" style="width: 15%">
                          {{ data.empName }}
                        </td>
                        <td class="tab-value" style="width: 10%">
                          {{ data.action_Effective_Date | date: 'MM/dd/yyyy' }}
                        </td>
                        <td
                          class="tab-value"
                          style="width: 5%"
                          title="{{ data.oldAction }}-{{
                            data.old_Action_Desc
                          }}"
                        >
                          {{ data.oldAction }}
                        </td>
                        <td
                          class="tab-value"
                          style="width: 5%"
                          title="{{ data.newAction }}-{{
                            data.new_Action_Desc
                          }}"
                          [ngClass]="
                            data.oldAction === data.newAction
                              ? 'nodiff'
                              : 'yesdiff'
                          "
                        >
                          {{ data.newAction }}
                        </td>
                        <td class="tab-value" style="width: 5%">
                          {{ data.oldPlantId }}
                        </td>
                        <td
                          class="tab-value"
                          style="width: 5%"
                          [ngClass]="
                            data.oldPlantId === data.newPlantId
                              ? 'nodiff'
                              : 'yesdiff'
                          "
                        >
                          {{ data.newPlantId }}
                        </td>
                        <td class="tab-value" style="width: 5%">
                          {{ data.oldDept }}
                        </td>
                        <td
                          class="tab-value"
                          style="width: 5%"
                          [ngClass]="
                            data.oldDept === data.newDept ? 'nodiff' : 'yesdiff'
                          "
                        >
                          {{ data.newDept }}
                        </td>
                        <td class="tab-value" style="width: 5%">
                          {{ data.oldJobCodeId }}
                        </td>
                        <td
                          class="tab-value"
                          style="width: 5%"
                          [ngClass]="
                            data.oldJobCodeId === data.newJobCodeId
                              ? 'nodiff'
                              : 'yesdiff'
                          "
                        >
                          {{ data.newJobCodeId }}
                        </td>
                        <td class="tab-value" style="width: 8%">
                          <span *ngIf="data.oldShift === 1">Day</span>
                          <span *ngIf="data.oldShift === 2">Afternoon</span>
                          <span *ngIf="data.oldShift === 3">Night</span>
                        </td>
                        <td
                          class="tab-value"
                          style="width: 8%"
                          [ngClass]="
                            data.oldShift === data.newShift
                              ? 'nodiff'
                              : 'yesdiff'
                          "
                        >
                          <span *ngIf="data.newShift === 1">Day</span>
                          <span *ngIf="data.newShift === 2">Afternoon</span>
                          <span *ngIf="data.newShift === 3">Night</span>
                        </td>
                        <td class="tab-value" (click)="showLogMessage(i)">
                          <img
                            src="./assets/DownDblArrow.png"
                            *ngIf="!data.showField"
                          />
                          <img
                            src="./assets/UpDblArrow.png"
                            *ngIf="data.showField"
                          />
                        </td>
                      </tr>
                      <tr *ngIf="data.showField">
                        <td
                          colspan="15"
                          [innerHTML]="data.applicationComments"
                        ></td>
                      </tr>
                      <tr *ngIf="data.showField">
                        <td
                          colspan="15"
                          [innerHTML]="data.overtimeComments"
                        ></td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="responseDataExists">
              <p *ngIf="responseDataExists"></p>
              <h6>No existing records Found</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
