import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsAndChartsService {
  private readonly _overtimeReassignHistory = '/GetOvertimeRepresentationGroupMove';
  private readonly _ActionCodeChanges = '/ActionCodeGetData';

  commonUtility = new CommonUtility();
  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  GetOvertimeReassignHistory(searchData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.reportandchartsURL}` +
      this._overtimeReassignHistory,
      searchData,
      { headers }
    );
  }

  GetActionCodeChangesSM(searchData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.reportandchartsURL}` +
      this._ActionCodeChanges,
      searchData,
      { headers }
    );
  }
}
