<div
  *ngIf="selectedEmp"
  class="modal fade"
  id="cancelApplicationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="cancelApplicationModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog add-form-alignment" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="cancelApplicationModalLabel">
          Cancel Application
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>

      <div class="modal-body">
        <form [formGroup]="dateForm">
          <table class="table table-borderless mt-2 mb-2 info-table">
            <tbody>
              <tr>
                <td class="tab-label">Employee Name:</td>
                <td class="tab-value">
                  {{ selectedEmp.firstName ? selectedEmp.firstName : 'N/A' }},
                  {{ selectedEmp.lastName ? selectedEmp.lastName : 'N/A' }}
                </td>
              </tr>
              <tr>
                <td class="tab-label">Current Classification:</td>
                <td class="tab-value">{{ JobCodeClassification }}</td>
              </tr>
              <tr>
                <td class="tab-label">Current Plant:</td>
                <td class="tab-value">{{ Plant }}</td>
              </tr>
              <tr>
                <td class="tab-label">Current Department:</td>
                <td class="tab-value">{{ Department }}</td>
              </tr>
              <tr>
                <td class="tab-label">Current Shift:</td>
                <td class="tab-value">{{ Shift }}</td>
              </tr>
              <tr>
                <td class="tab-label">Application #:</td>
                <td class="tab-value">
                  #{{ selectedEmp.appId ? selectedEmp.appId : 'N/A' }}
                </td>
              </tr>
              <tr>
                <td class="tab-label">Classification Applied For:</td>
                <td class="tab-value">
                  {{ selectedEmp.jobCodeId ? selectedEmp.jobCodeId : 'N/A' }}-{{
                    selectedEmp.jobCodeDescription
                      ? selectedEmp.jobCodeDescription
                      : 'N/A'
                  }}
                </td>
              </tr>
              <tr>
                <td class="tab-label">Plant Applied For:</td>
                <td class="tab-value">
                  {{ selectedEmp.plantId ? selectedEmp.plantId : 'N/A' }}-{{
                    selectedEmp.plantDescription
                      ? selectedEmp.plantDescription
                      : 'N/A'
                  }}
                </td>
              </tr>
              <tr>
                <td class="tab-label">Department Applied For:</td>
                <td class="tab-value">
                  {{ selectedEmp.deptId ? selectedEmp.deptId : 'N/A' }}-{{
                    selectedEmp.deptDescription
                      ? selectedEmp.deptDescription
                      : 'N/A'
                  }}
                </td>
              </tr>
              <tr>
                <td class="tab-label">Shift Applied For:</td>
                <td class="tab-value">
                  {{
                    selectedEmp.shiftDescription
                      ? selectedEmp.shiftDescription
                      : 'N/A'
                  }}
                </td>
              </tr>
              <tr>
                <td class="tab-label">Employee Signature Date:</td>
                <td class="tab-value">
                  {{
                    selectedEmp.empSignDate
                      ? (selectedEmp.empSignDate | date: 'MM/dd/yyyy')
                      : 'N/A'
                  }}
                </td>
                <!-- <td class='tab-value'>{{selectedEmp.empSignDate ? (selectedEmp.empSignDate | date:'MM/dd/yyyy') 'N/A'}}</td> -->
              </tr>
              <tr *ngIf="isLoggedInUserAdmin">
                <td class="tab-label">Enter Cancel Date:</td>
                <td class="tab-value">
                  <input
                    hidden
                    readonly
                    readonly
                    type="email"
                    formControlName="appId"
                    class="form-control"
                  />

                  <input
                    type="date"
                    class="form-control"
                    formControlName="AppStatusDate"
                  />
                  <div
                    *ngIf="
                      dateForm.controls['AppStatusDate'].invalid &&
                      dateForm.controls['AppStatusDate'].touched
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="
                        dateForm.controls['AppStatusDate'].errors?.required
                      "
                    >
                      Cancel date is required.
                    </small>
                  </div>
                  <label class="alert alert-danger" *ngIf="dateForm.errors">{{
                    dateForm.errors.dates
                  }}</label>
                </td>
              </tr>
              <tr *ngIf="!isLoggedInUserAdmin">
                <td class="tab-value">
                  <input
                    hidden
                    readonly
                    readonly
                    type="email"
                    formControlName="appId"
                    class="form-control"
                  />

                  <input
                    hidden
                    type="date"
                    class="form-control"
                    formControlName="AppStatusDate"
                    value="{{ currentDate | date: 'yyyy-MM-dd' }}"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary popupbuttonscancel"
          data-dismiss="modal"
          (click)="clear()"
          #closeCancelModal
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="dateForm.errors || dateForm.invalid"
          (click)="confirmCancel()"
        >
          Cancel Application
        </button>
      </div>
    </div>
  </div>
</div>
