<div class="container-fluid app-box" style="background-color: white">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Transfer Applications</li>
        <li class="breadcrumb-item brd-li brd-li-child">Shift Preference</li>
      </ol>
    </nav>
  </div>
  <div *ngIf="isAdmin()">
    <div class="row content-container mt-2 mb-2">
      <nav class="navbar navbar-expand-lg navbar-light bg-light applicationcss">
        <!-- <ul class="navbar-nav mr-auto bg-light navbar-light"> -->
        <!-- <li class="nav-item ">
                        <a *ngIf="!isUser" [ngStyle]="{'font-weight': filterText}" class="nav-link" style="color:white" (click)="showFilters();">Filter</a>
                    </li> -->
        <!-- </ul> -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto"></ul>
          <form class="form-inline my-2 my-lg-0">
            <input
              class="select-radio"
              checked="checked"
              name="tool-and-die"
              type="radio"
              Id="1"
              (change)="onAppTypeSelected(1)"
            />
            <label class="select-label" for="1">Existing Application</label>
            <input
              *ngIf="views[0].allowAdd"
              class="select-radio"
              name="tool-and-die"
              type="radio"
              Id="2"
              (change)="onAppTypeSelected(2)"
            />
            <label *ngIf="views[0].allowAdd" class="select-label" for="2"
              >Add Application</label
            >
          </form>
        </div>
        <br />
      </nav>
      <!-- global search filter starts -->
      <div class="pt-2 div-color">
        <div class="search-divider-div filtercss">
          <p class="search-divder-p"></p>
          <hr class="search-divider-hr" />
          <!-- filer -->
          <div
            class="jumbotron jumb-new jumb-newcontrols"
            style="margin-bottom: 0px !important; padding: 0.7rem 2.5rem"
            *ngIf="showFilter"
          >
            <div class="row" display="float">
              <div class="col">
                <app-filter
                  [filterConfig]="filterConfig"
                  (formcontrolobj)="getbyenter($event)"
                  (filterValue)="getFilterValue($event)"
                >
                </app-filter>
                <!-- <hr class="my-4"> -->
              </div>
              <div class="col-md-1 mr-5" style="padding-right: 80px">
                <a
                  class="btn btn-lg search-button"
                  (click)="search()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <div
            class="jumbotron jumb-new"
            style="margin-bottom: 0px !important; padding: 1rem 2rem"
            *ngIf="!showFilter"
          >
            <div class="row" display="float">
              <div class="col">
                <app-filter
                  (formcontrolobj)="getbyenterforuser($event)"
                  [filterConfigAdd]="filterConfigAdd"
                  (filterValue)="getFilterDataUser($event)"
                >
                </app-filter>
                <!-- <hr class="my-4"> -->
              </div>
              <div class="col-md-1 mr-5" style="padding-right: 80px">
                <a
                  class="btn btn-lg search-button btn-align"
                  (click)="searchbyUser()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <!-- filter ends -->
        </div>
        <div class="">
          <!--For application-->
          <div *ngIf="!isApplication">
            <div class="info-label divcrudbtns">
              <p style="margin: 0px; text-align: left"></p>
              <div *ngIf="views[0].allowUpdate">
                <button
                  [disabled]="disablededit"
                  [ngClass]="disablededit ? 'disablededitcolors' : ''"
                  *ngIf="
                    (!EditButtonDisabled && isApplicationSelected()) ||
                    !EditButtonDisabled1
                  "
                  (click)="edit()"
                  data-toggle="modal"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                  class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
                >
                  Edit
                </button>
              </div>
              <div *ngIf="views[0].allowDelete">
                <button
                  [disabled]="disableddelete"
                  [ngClass]="disableddelete ? 'disablededitcolors' : ''"
                  *ngIf="isApplicationSelected() || !DeleteButtonDisabled1"
                  class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
                  data-toggle="modal"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                  style="margin: 0.5rem"
                  (click)="deleteApplication()"
                >
                  Delete
                </button>
              </div>
              <div
                *ngIf="ApplicationData !== null && ApplicationData.length > 0"
              >
                <button
                  style="margin-right: 0.5rem"
                  class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
                  (click)="exportElmToExcel()"
                >
                  Export to Excel
                </button>
              </div>
            </div>

            <!-- This is Export to document Section -->
            <table
              class="table table-striped"
              *ngIf="
                !isDataExportableView &&
                ApplicationData !== null &&
                ApplicationData.length > 0
              "
              #ShiftPreferenceExportTable
            >
              <thead class="table-header">
                <tr>
                  <th class="th-height" scope="col">Name</th>
                  <th class="th-height" scope="col">Employee Id</th>
                  <th class="th-height" scope="col">Classification</th>
                  <th class="th-height" scope="col">Dept</th>
                  <th
                    class="th-height"
                    style="border-right: 2px solid grey"
                    scope="col"
                  >
                    Shift
                  </th>
                  <th class="th-height" scope="col">Shift Requested</th>
                  <th class="th-height" scope="col">Shift Request Type</th>
                  <th class="th-height" scope="col">Area</th>
                  <th class="th-height" scope="col">Date Signed</th>
                  <th class="th-height" scope="col">Status</th>
                  <th class="th-height" scope="col">Effective Date</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let emp of ApplicationData; let i = index" class="">
                  <td class="left-tab-value">
                    {{ emp.lastName ? emp.lastName : '-' }},&nbsp;{{
                      emp.firstName ? emp.firstName : '-'
                    }}&nbsp;{{ emp.middleName ? emp.middleName : '' }}
                    <!-- class='tab-value'> -->
                  </td>

                  <td class="tab-value">
                    {{ emp.employeeId ? emp.employeeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.jobCodeId ? emp.jobCodeId : '-' }}
                  </td>
                  <td class="tab-value">{{ emp.deptId ? emp.deptId : '-' }}</td>
                  <td class="left-tab-value">
                    {{
                      emp.employeeShiftDescription
                        ? emp.employeeShiftDescription
                        : '-'
                    }}
                  </td>
                  <td class="left-tab-value">
                    {{ emp.shiftDescription ? emp.shiftDescription : '-' }}
                  </td>
                  <td class="tab-value">
                    {{
                      emp.specialSPDescription ? emp.specialSPDescription : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{ emp.areaDescription ? emp.areaDescription : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.empSignDate | date: 'MM/dd/yyyy' }}
                  </td>
                  <td class="left-tab-value">
                    {{ emp.appStatusCode ? emp.appStatusCode : '-' }}
                  </td>
                  <td class="tab-value">
                    {{
                      emp.EffectiveDate
                        ? (emp.EffectiveDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- This is Main Table Section -->
            <table
              class="table table-striped"
              *ngIf="ApplicationData !== null && ApplicationData.length > 0"
            >
              <thead class="table-header">
                <tr>
                  <th class="table-height" colspan="6" scope="col">
                    Employee Information
                  </th>
                  <th class="table-height" colspan="6" scope="col">
                    Application Information
                  </th>
                </tr>
                <tr>
                  <th class="th-height" scope="col">Select</th>
                  <!-- <th class="th-height" scope="col">Name</th>
                                    <th class="th-height" scope="col">Employee Id</th>
                                    <th class="th-height" scope="col">Classification</th>
                                    <th class="th-height" scope="col">Dept</th>
                                    <th class="th-height" style="border-right: 2px solid grey;" scope="col">Shift
                                    </th>
                                    <th class="th-height" scope="col">Shift Requested</th>
                                    <th class="th-height" scope="col">Shift Request Type</th>
                                    <th class="th-height" scope="col">Area</th>
                                    <th class="th-height" scope="col">Date Signed</th>
                                    <th class="th-height" scope="col">Status</th>
                                    <th class="th-height" scope="col">Effective Date</th> -->

                  <th class="th-height" scope="col">
                    <span (click)="onSortClick($event, 'lastName', 'string')"
                      >Name
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'lastName'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'lastName'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'lastName'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span (click)="onSortClick($event, 'employeeId', 'string')"
                      >Emp Id
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'employeeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'employeeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'employeeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span (click)="onSortClick($event, 'jobCodeId', 'string')"
                      >Class
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'jobCodeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'jobCodeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'jobCodeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span (click)="onSortClick($event, 'deptId', 'string')"
                      >Dept
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'deptId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'deptId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'deptId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th
                    class="th-height"
                    scope="col"
                    style="border-right: 2px solid grey"
                  >
                    <span
                      (click)="
                        onSortClick(
                          $event,
                          'employeeShiftDescription',
                          'string'
                        )
                      "
                      >Shift
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'employeeShiftDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'employeeShiftDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'employeeShiftDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'shiftDescription', 'string')
                      "
                      >Shift Req.
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'shiftDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'specialSPDescription', 'string')
                      "
                      >Shift Req. Type
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'specialSPDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'specialSPDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'specialSPDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span
                      (click)="onSortClick($event, 'areaDescription', 'string')"
                      >Area
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'areaDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'areaDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'areaDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span
                      (click)="onSortClick($event, 'empSignDateSort', 'string')"
                      >Date Signed
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'empSignDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span
                      (click)="onSortClick($event, 'appStatusCode', 'string')"
                      >Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'appStatusCode'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'appStatusCode'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'appStatusCode'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'EffectiveDateSort', 'string')
                      "
                      >Effective Date
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'EffectiveDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'EffectiveDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'EffectiveDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let emp of ApplicationData
                      | paginate
                        : {
                            id: 'joblist',
                            itemsPerPage: 20,
                            currentPage: Applicationpage,
                            totalItems: TotalApplicationCount
                          };
                    let i = index
                  "
                  class=""
                >
                  <td class="text-center">
                    <input
                      type="radio"
                      id="select"
                      name="application"
                      (click)="selectEmp(i, emp.employeeId, emp.appId)"
                    />
                  </td>
                  <td class="left-tab-value">
                    {{ emp.lastName ? emp.lastName : '-' }},&nbsp;{{
                      emp.firstName ? emp.firstName : '-'
                    }}&nbsp;{{ emp.middleName ? emp.middleName : '' }}
                    <!-- class='tab-value'> -->
                  </td>

                  <td class="tab-value">
                    {{ emp.employeeId ? emp.employeeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.jobCodeId ? emp.jobCodeId : '-' }}
                  </td>
                  <td class="tab-value">{{ emp.deptId ? emp.deptId : '-' }}</td>
                  <td class="left-tab-value">
                    {{
                      emp.employeeShiftDescription
                        ? emp.employeeShiftDescription
                        : '-'
                    }}
                  </td>
                  <td class="left-tab-value">
                    {{ emp.shiftDescription ? emp.shiftDescription : '-' }}
                  </td>
                  <td class="tab-value">
                    {{
                      emp.specialSPDescription ? emp.specialSPDescription : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{ emp.areaDescription ? emp.areaDescription : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.empSignDate | date: 'MM/dd/yyyy' }}
                  </td>
                  <td class="left-tab-value">
                    {{ emp.appStatusCode ? emp.appStatusCode : '-' }}
                  </td>
                  <td class="tab-value">
                    {{
                      emp.EffectiveDate
                        ? (emp.EffectiveDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="single-wrap d-flex justify-content-center"
              *ngIf="ApplicationData !== null && ApplicationData.length > 0"
            >
              <nav aria-label="Page navigation example">
                <pagination-controls
                  class="my-pagination"
                  id="joblist"
                  (pageChange)="Applicationpage = $event"
                  previousLabel=""
                  nextLabel=""
                >
                </pagination-controls>
              </nav>
            </div>
            <div *ngIf="!isApplication">
              <p *ngIf="isExistingRecordsDatalength">
                No existing records Found
              </p>
            </div>
          </div>
          <!--For user-->
          <div
            style="
              height: 15px;
              background: white;
              position: sticky;
              top: 146px;
            "
          ></div>
          <div *ngIf="isApplication">
            <table
              class="table table-striped"
              *ngIf="userData !== null && userData.length > 0"
            >
              <thead
                class="table-header"
                style="position: sticky; top: 160px !important"
              >
                <th scope="col">Name</th>
                <th scope="col">Employee Id</th>
                <th scope="col">Classification</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let emp of userData
                      | paginate
                        : {
                            id: 'userlist',
                            itemsPerPage: 20,
                            currentPage: Userpage,
                            totalItems: TotalUserCount
                          };
                    let i = index
                  "
                  class="table-container"
                >
                  <td class="left-tab-value">
                    <button
                      class="btn my-2 my-sm-0 click-add"
                      type="submit"
                      data-toggle="modal"
                      [attr.data-target]="modalId"
                      data-backdrop="false"
                      (click)="addEmployee(i, emp.employeeId)"
                    >
                      {{ emp.lastName ? emp.lastName : '-' }},&nbsp;{{
                        emp.firstName ? emp.firstName : '-'
                      }}&nbsp;{{ emp.middleName ? emp.middleName : '' }}
                    </button>
                  </td>
                  <td class="tab-value">
                    {{ emp.employeeId ? emp.employeeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.jobCodeId ? emp.jobCodeId : '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="single-wrap d-flex justify-content-center"
              *ngIf="userData !== null && userData.length > 0"
            >
              <nav aria-label="Page navigation example">
                <pagination-controls
                  class="my-pagination"
                  id="userlist"
                  (pageChange)="Userpage = $event"
                  previousLabel=""
                  nextLabel=""
                ></pagination-controls>
              </nav>
            </div>
          </div>
          <div *ngIf="isApplication">
            <p *ngIf="isUserListDataEmpty">No User Found</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isAdmin()">
    <div
      *ngIf="userInformation !== undefined && userInformation !== null"
      class="row content-container mt-2 mb-2"
    >
      <!-- global search filter starts -->
      <div id="content" #content class="pt-2 div-color">
        <div class="search-divider-div">
          <p class="search-divder-p"></p>

          <div
            class="jumbotron jumb-new jumb-newcontrols"
            style="margin-bottom: 0px"
          >
            <div class="row userinfo-header-div" display="float">
              <div class="col">
                <h1 class="h1-label">
                  Welcome! {{ userInformation.firstName }}
                </h1>
              </div>
              <div>
                <button
                  class="btn btn-lg search-button add-button-a button-align print-btn non-printable"
                  (click)="print()"
                >
                  Print
                </button>
              </div>
              <div class="col-md-1 mr-5 add-button-div">
                <button
                  class="btn btn-lg search-button add-button-a button-align non-printable"
                  type="submit"
                  data-toggle="modal"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                  (click)="addApplication()"
                  role="button"
                >
                  Add Application
                </button>
              </div>
            </div>
          </div>
          <div class="empdescription-box">
            <div class="empdescription-table">
              <table>
                <thead class="user-table-header">
                  <th class="empdescription-th emp-atid">Employee Id:</th>
                  <th class="empdescription-th">Current Classification:</th>
                  <th class="empdescription-th">Current Department:</th>
                  <th class="empdescription-th">Current Shift:</th>
                </thead>
                <tr *ngIf="usersData !== undefined">
                  <td class="empdescription-table-tr tab-value">
                    {{ usersData.employeeId }}
                  </td>
                  <td class="empdescription-table-tr tab-value">
                    {{ usersData.jobCodeId }} {{ usersData.jobCodeDescription }}
                  </td>
                  <td class="empdescription-table-tr tab-value">
                    {{ usersData.deptId }}
                  </td>
                  <td class="empdescription-table-tr tab-value">
                    {{ usersData.shiftDescription }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- filter ends -->
        </div>
        <div class="pt-5">
          <!--For application-->
          <div>
            <table
              class="table table-striped"
              *ngIf="applicationData !== null && applicationData.length > 0"
            >
              <thead class="user-table-header">
                <th scope="col">Shift Request Type</th>
                <th scope="col">Date Signed</th>
                <th scope="col">Shift Requested</th>
                <th scope="col">Status</th>
                <th scope="col">Effective Date</th>
              </thead>

              <tbody>
                <tr
                  *ngFor="let emp of applicationData; let i = index"
                  class="table-container"
                >
                  <td class="tab-value">
                    {{
                      emp.specialSPDescription ? emp.specialSPDescription : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{ emp.empSignDate | date: 'MM/dd/yyyy' }}
                  </td>
                  <td class="left-tab-value">
                    {{ emp.shiftDescription ? emp.shiftDescription : '-' }}
                  </td>
                  <td class="left-tab-value">
                    {{ emp.appStatusCode ? emp.appStatusCode : '-' }}
                  </td>
                  <td class="tab-value">
                    {{
                      emp.EffectiveDate
                        ? (emp.EffectiveDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="!isApplication">
              <p
                *ngIf="applicationData !== null && applicationData.length === 0"
              >
                Your requested application will display here. <br />
                Note: You can not edit your application.
              </p>
              <p
                class="text-danger"
                *ngIf="applicationData !== null && applicationData.length === 0"
              >
                <b>You do not have any open application at this time.</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Add / Edit Modal -->

  <app-edit-shift-preference
    [selectedEmp]="selectedEmp"
    [LastUpdatedBy]="LastUpdatedBy"
    [isEdit]="isEdit"
    (clean)="cleanEmp($event)"
    (isSucess)="CheckEdit($event)"
    [title]="modalTitle"
    *ngIf="isEdit"
  >
  </app-edit-shift-preference>

  <app-add-shift-preference
    [selectedEmp]="selectedEmp"
    [AdminAtid]="AdminAtid"
    [isLoggedInUserAdmin]="isLoggedInUserAdmin"
    [isAdd]="isAdd"
    (clean)="cleanEmp($event)"
    (isSucess)="CheckAdd($event)"
    *ngIf="isAdd"
  ></app-add-shift-preference>

  <!-- Delete Modal -->

  <app-delete-shift-preference
    [selectedEmp]="selectedEmp"
    [employeeForm]="employeeForm"
    (isSucess)="CheckDelete($event)"
  ></app-delete-shift-preference>

  <!-- Cancel Modal -->
  <app-cancel-shift-preference
    [selectedEmp]="selectedEmp"
    [isLoggedInUserAdmin]="isLoggedInUserAdmin"
    (isSucess)="CheckCancel($event)"
    [employeeForm]="employeeForm"
  >
  </app-cancel-shift-preference>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
    <!--[show]="loading"  -->
  </ngx-loading>
</div>
