import { HttpErrorResponse } from '@angular/common/http';
import {
  ElementRef,
  OnChanges,
  SimpleChanges,
  ViewChild,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RepresentationGroupClassification } from 'src/app/Models/RepresentationGroupClassification';
import { RepresentationGroupDept } from 'src/app/Models/RepresentationGroupDept';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { RepresentationGroupDataService } from 'src/app/Services/TableMaintenance/RepresentationGroupData/representation-group-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-representation-group-data',
  templateUrl: './edit-representation-group-data.component.html',
  styleUrls: ['./edit-representation-group-data.component.css'],
})
export class EditRepresentationGroupDataComponent implements OnInit, OnChanges {
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;
  @ViewChildren('checkboxes') checkboxes!: QueryList<ElementRef>;
  @Input() userName: any;
  @Input() selectedRepGroup: any;
  @Output() isSucess = new EventEmitter();
  repGroupForm!: FormGroup;
  repGroupSelected: any;
  enableOverride: boolean = false;
  disableAdd: boolean = false;
  classification: RepresentationGroupClassification[] = [];
  deptArray: any[] = [];
  classArray: any[] = [];
  department: RepresentationGroupDept[] = [];
  zones: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];
  districts: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];
  shifts: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];
  Status: any[] = [
    {
      data: [
        {
          statusType: 'A',
          statusName: 'Active',
        },
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
      ],
      default: 'Select One',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private rds: RepresentationGroupDataService,
    private _commonApplicationService: CommonService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.createForm();
    this.loadData(changes);
  }

  ngOnInit(): void {
    this.getClassification();
    this.getDepartment();
    this.getDistricts();
    this.getZones();
    this.getShifts();
  }

  createForm() {
    this.repGroupForm = this.fb.group({
      RepresentationGroupName: ['', [Validators.required]],
      RepresentationGroupId: [],
      RepresentationGroupDescription: ['', [Validators.required]],
      ShiftId: ['', [Validators.required]],
      ZoneId: ['', [Validators.required]],
      DistrictId: ['', [Validators.required]],
      Status: ['', [Validators.required]],
      LastUpdateBy: [this.userName],
      DepartmentArray: [],
      ClassificationArray: [],
      MixedClassOverride: [false],
    });
  }

  loadData(changes: SimpleChanges) {
    this.repGroupSelected =
      (changes.selectedRepGroup && changes.selectedRepGroup.currentValue) || '';
    if (this.repGroupSelected === '') {
      this.repGroupSelected =
        (changes.selectedRepGroup && changes.selectedRepGroup.previousValue) ||
        '';
    }
    this.getClassification();
    this.getDepartment();
    this.deptArray = this.repGroupSelected.department
      .split(',')
      .map((dept: string) => dept.trim());
    this.classArray = this.repGroupSelected.classification
      .split(',')
      .map((classification: string) => classification.trim());
    this.repGroupForm
      .get('RepresentationGroupId')
      ?.patchValue(this.repGroupSelected.representationGroupId);
    this.repGroupForm
      .get('RepresentationGroupName')
      ?.patchValue(this.repGroupSelected.representationGroupName);
    this.repGroupForm
      .get('RepresentationGroupDescription')
      ?.patchValue(this.repGroupSelected.representationGroupDescription);
    this.repGroupForm.get('ShiftId')?.patchValue(this.repGroupSelected.shiftId);
    this.repGroupForm.get('ZoneId')?.patchValue(this.repGroupSelected.zoneId);
    this.repGroupForm
      .get('DistrictId')
      ?.patchValue(this.repGroupSelected.districtId);
    this.repGroupForm.get('Status')?.patchValue(this.repGroupSelected.status);
  }

  getClassification() {
    this.rds.getClassifications().subscribe((data) => {
      this.classification = data.map(
        (classification: RepresentationGroupClassification) => ({
          jobCodeDescription: classification.jobCodeDescription,
          jobCodeId: classification.jobCodeId,
          checked: false,
        })
      );
      for (let i = 0; i < this.classArray.length; i++) {
        for (let j = 0; j < this.classification.length; j++) {
          if (this.classification[j].jobCodeId === this.classArray[i]) {
            this.classification[j].checked = true;
          }
        }
      }
    });
  }

  getDepartment() {
    this.rds.getDepartments().subscribe((data) => {
      this.department = data.map((dept: RepresentationGroupDept) => ({
        deptDescription: dept.deptDescription,
        deptId: dept.deptId,
        checked: false,
      }));
      for (let i = 0; i < this.deptArray.length; i++) {
        for (let j = 0; j < this.department.length; j++) {
          if (this.department[j].deptId === this.deptArray[i]) {
            this.department[j].checked = true;
          }
        }
      }
    });
  }

  getDistricts() {
    this.rds.getDistricts().subscribe((data) => {
      this.districts[0].data = data;
    });
  }

  getZones() {
    this.rds.getZones().subscribe((data) => {
      this.zones[0].data = data;
    });
  }

  getShifts() {
    this._commonApplicationService.getShiftsList().subscribe((data) => {
      this.shifts[0].data = data;
    });
  }

  clear() {
    this.closeModal.nativeElement.click();
    this.getDepartment();
    this.getClassification();
    this.createForm();
    this.enableOverride = false;
    this.uncheckAll();
    this.loadCancelledData();
  }

  uncheckAll() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
  }

  loadCancelledData() {
    this.repGroupForm
      .get('RepresentationGroupName')
      ?.patchValue(this.repGroupSelected.representationGroupName);
    this.repGroupForm
      .get('RepresentationGroupDescription')
      ?.patchValue(this.repGroupSelected.representationGroupDescription);
    this.repGroupForm.get('ShiftId')?.patchValue(this.repGroupSelected.shiftId);
    this.repGroupForm.get('ZoneId')?.patchValue(this.repGroupSelected.zoneId);
    this.repGroupForm
      .get('DistrictId')
      ?.patchValue(this.repGroupSelected.districtId);
    this.repGroupForm.get('Status')?.patchValue(this.repGroupSelected.status);
  }

  submitRepGroupData() {
    this.enableOverride = false;
    const classarr = this.classification.filter((item) => {
      return item.checked;
    });
    const classtemp = classarr.map((item) => item.jobCodeId);
    const deptarr = this.department.filter((item) => {
      return item.checked;
    });
    const depttemp = deptarr.map((item) => item.deptId);
    this.repGroupForm.get('ClassificationArray')?.patchValue(classtemp);
    this.repGroupForm.get('DepartmentArray')?.patchValue(depttemp);
    this.rds.editRepGroupData(this.repGroupForm.value).subscribe(
      () => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 500) {
          this.badRequestAlert(_error.error);
        } else if (_error.status === 406) {
          this.setValue(this.enableOverride);
          this.badRequestAlert(_error.error);
        } else {
          this.badRequestAlert(_error.error);
        }
      }
    );
  }

  setValue(status: boolean) {
    if (!status) {
      this.enableOverride = !this.enableOverride;
    }
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'The representation group has been updated successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
