<div class="container-fluid app-box">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Table Maintenance</li>
        <li class="breadcrumb-item brd-li brd-li-child">Shift Data</li>
      </ol>
    </nav>
    <div class="align-self-end ml-auto">
      <button
        *ngIf="views[0]?.allowAdd"
        class="btn btn-success newbtn"
        data-toggle="modal"
        [attr.data-target]="modalId"
        data-backdrop="false"
        (click)="showAddModal()"
      >
        Add Shift Data
      </button>
    </div>

    <div *ngIf="shiftsData !== null && shiftsData.length > 0">
      <button
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        style="margin-right: 50px"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
    </div>
  </div>

  <div class="row content-container mt-2 mb-2">
    <div class="div-color">
      <div class="pt-5" style="padding-top: 0px !important">
        <div>
          <table
            *ngIf="!isDataExportableView && shiftsData"
            class="table table-striped"
            #shiftdataTablemaintenanceExportTable
          >
            <thead class="table-header transfer-header">
              <tr>
                <!-- <th scope="col">Shift ID</th>
                                <th scope="col">Shift Description</th>
                                <th scope="col">Status</th> -->

                <th scope="col">
                  <span (click)="onSortClick($event, 'shiftid', 'string')"
                    >Shift Id
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'shiftid'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'shiftid'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'shiftid'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'shiftdescription', 'string')"
                    >Shift Description
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'shiftdescription'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'shiftdescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'shiftdescription'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'status', 'string')"
                    >Status
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'status'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'status'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <!-- <th scope="col">Edit</th>
                                <th scope="col">Delete</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of shiftsData; let i = index" class="">
                <td class="tab-value">{{ data.shiftid }}</td>
                <td class="left-tab-value">{{ data.shiftdescription }}</td>
                <td class="tab-value">
                  {{ data.status === 'A' ? 'Active' : 'Inactive' }}
                </td>
                <!-- <td class="tab-value">
                                    <button *ngIf='views[0]?.allowUpdate' data-toggle="modal" [attr.data-target]="modalId"
                                    data-backdrop="false" class="cursor" (click)="showEditModal(data.shiftid)"><img
                                            src="../../../../../assets/edit-regular.svg"></button>
                                </td>
                                <td class="tab-value"> <button *ngIf='views[0]?.allowDelete' data-toggle="modal" [attr.data-target]="modalId"
                                  data-backdrop="false" class="cursor" (click)="showDeleteModal(data.shiftid)"><img
                                            src="../../../../../assets/delete.png"></button></td> -->
              </tr>
            </tbody>
          </table>

          <table class="table table-striped">
            <thead class="table-header transfer-header">
              <tr>
                <!-- <th scope="col">Shift ID</th>
                                <th scope="col">Shift Description</th>
                                <th scope="col">Status</th> -->
                <th scope="col">
                  <span (click)="onSortClick($event, 'shiftid', 'string')"
                    >Shift Id
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'shiftid'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'shiftid'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'shiftid'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'shiftdescription', 'string')"
                    >Shift Description
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'shiftdescription'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'shiftdescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'shiftdescription'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'status', 'string')"
                    >Status
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'status'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'status'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of shiftsData; let i = index" class="">
                <td class="tab-value">{{ data.shiftid }}</td>
                <td class="left-tab-value">{{ data.shiftdescription }}</td>
                <td class="tab-value">
                  {{ data.status === 'A' ? 'Active' : 'Inactive' }}
                </td>
                <td class="tab-value">
                  <button
                    *ngIf="views[0]?.allowUpdate"
                    data-toggle="modal"
                    [attr.data-target]="modalId"
                    data-backdrop="false"
                    class="cursor"
                    (click)="showEditModal(data.shiftid)"
                  >
                    <img src="../../../../../assets/edit-regular.svg" />
                  </button>
                </td>
                <td class="tab-value">
                  <button
                    *ngIf="views[0]?.allowDelete"
                    data-toggle="modal"
                    [attr.data-target]="modalId"
                    data-backdrop="false"
                    class="cursor"
                    (click)="showDeleteModal(data.shiftid)"
                  >
                    <img src="../../../../../assets/delete.png" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <app-update-shift-data
    *ngIf="showEdit"
    [userName]="userName"
    [selectedShift]="selectedShift"
    (isSucess)="checkEdit($event)"
  >
  </app-update-shift-data>
  <app-add-shift-data
    *ngIf="showAdd"
    [userName]="this.userInformation.userName"
    (isSucess)="checkAdd($event)"
  >
  </app-add-shift-data>
  <app-delete-shift-data
    [selectedShift]="selectedShift"
    (isSucess)="checkDelete($event)"
  ></app-delete-shift-data>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
