<div
  class="modal fade"
  id="editEmp"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addEmployeeLabel"
  aria-hidden="true"
>
  <div class="modal-dialog add-form-alignment" role="document">
    <div class="modal-content" style="position: fixed; width: 945px">
      <div class="modal-header">
        <h5 class="modal-title" id="addEmployeeLabel">Edit Application</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>
      <div class="modal-body" style="padding-left: 80px; padding-right: 80px">
        <form [formGroup]="employeeForm">
          <div class="form-row">
            <div class="form-group col-md-12">
              <input
                hidden
                readonly
                type="email"
                formControlName="AppId"
                placeholder="AppId"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Employee Name:</label>
            <div class="col-sm-8">
              <input
                readonly
                type="email"
                formControlName="fullname"
                placeholder="Full Name"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Employee Id:</label>
            <div class="col-sm-8">
              <input
                readonly
                formControlName="EmployeeId"
                type="email"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Current Classification:</label
            >
            <div class="col-sm-8">
              <input
                readonly
                type="email"
                formControlName="classification"
                placeholder="Classification"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Current Plant:</label>
            <div class="col-sm-8">
              <input
                readonly
                type="email"
                formControlName="currentPlant"
                placeholder="Classification"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Current Department:</label>
            <div class="col-sm-8">
              <input
                readonly
                type="email"
                formControlName="currentDept"
                placeholder="Classification"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Current Shift:</label>
            <div class="col-sm-8">
              <input
                readonly
                type="email"
                formControlName="currentShift"
                placeholder="Classification"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Shift *:</label>
            <div class="col-sm-8">
              <select
                (change)="onChangeShiftType()"
                formControlName="ShiftId"
                class="form-control"
                id="exampleFormControlSelect3"
              >
                <option
                  *ngFor="let shift of shifts[0].data"
                  value="{{ shift.shiftId }}"
                >
                  {{ shift.shiftDescription }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Shift Request Type:</label>
            <div class="col-sm-8">
              <select
                [attr.disabled]="controlEnabled"
                formControlName="SpecialSP"
                class="form-control"
                id="exampleFormControlSelect3"
              >
                <option disabled selected value="">Choose One</option>
                <option
                  *ngFor="let shiftType of ShiftRequestType[0].data"
                  value="{{ shiftType.shiftRequestTypeId }}"
                >
                  {{ shiftType.shiftRequestTypeName }}
                </option>
              </select>
              <div
                *ngIf="
                  employeeForm.controls['SpecialSP'].invalid &&
                  employeeForm.controls['SpecialSP'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['SpecialSP'].errors?.required"
                >
                  SpecialSP is required.
                </small>
              </div>
            </div>
          </div>

          <!-- Area Field for 223J,429J,581J -->
          <div *ngIf="AreaField" class="form-group row">
            <label class="col-sm-4 col-form-label">Area *: </label>
            <div class="col-sm-8">
              <select
                formControlName="AppAreaId"
                class="form-control"
                id="exampleFormControlSelect2"
              >
                <option
                  *ngIf="areas[areas.length - 1].default"
                  selected
                  value=""
                >
                  {{ areas[areas.length - 1].default }}
                </option>
                <option
                  *ngFor="let area of areas[areas.length - 1].data"
                  value="{{ area.areaId }}"
                >
                  {{ area.areaDescription }}
                </option>
              </select>
              <div
                *ngIf="
                  employeeForm.controls['AppAreaId'].invalid &&
                  employeeForm.controls['AppAreaId'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['AppAreaId'].errors?.required"
                >
                  Area is required.
                </small>
              </div>
            </div>
          </div>

          <!-- Area Field for 447J,447L -->
          <div *ngIf="DistinctiveAreaFieldAccess" class="form-group row">
            <label class="col-sm-4 col-form-label">Area *:</label>
            <div class="col-sm-8">
              <select
                formControlName="AppAreaId"
                class="form-control"
                id="exampleFormControlSelect2"
              >
                <option selected value="">Select an area</option>
                <option
                  *ngFor="let area of DistinctiveAreaField"
                  value="{{ area.areaId }}"
                >
                  {{ area.areaDescription }}
                </option>
              </select>
              <div
                *ngIf="
                  employeeForm.controls['AppAreaId'].invalid &&
                  employeeForm.controls['AppAreaId'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['AppAreaId'].errors?.required"
                >
                  Area is required.
                </small>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Employee Sign Date *:</label>
            <div class="col-sm-8">
              <input
                formControlName="EmpSignDate"
                type="date"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Status *:</label>
            <div class="col-sm-8">
              <input
                (change)="checkStatus($event)"
                (keyup)="checkStatus($event)"
                [class.is-invalid]="
                  employeeForm.get('AppStatusCode')?.invalid &&
                  employeeForm.get('AppStatusCode')?.touched
                "
                formControlName="AppStatusCode"
                class="form-control"
                id="exampleFormControlSelect2"
              />

              <small class="text-danger" *ngIf="statusClosed"
                >You must enter a valid status to close the application ('CLOSED
                - REASON - XXX').</small
              >
              <small class="text-danger" *ngIf="invalidStatus"
                >You must enter a valid status ('OPEN' or
                'CLOSED-REASON-XXX').</small
              >
              <div
                *ngIf="
                  employeeForm.controls['AppStatusCode'].invalid &&
                  employeeForm.controls['AppStatusCode'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="
                    employeeForm.controls['AppStatusCode'].errors?.required
                  "
                >
                  Status is required.
                </small>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Date of Status *:</label>
            <div class="col-sm-8">
              <input
                formControlName="AppStatusDate"
                type="date"
                class="form-control"
              />
            </div>
            <div
              *ngIf="
                employeeForm.controls['AppStatusDate'].invalid &&
                employeeForm.controls['AppStatusDate'].touched
              "
            >
              <small
                class="text-danger"
                *ngIf="employeeForm.controls['AppStatusDate'].errors?.required"
              >
                Status date is required.
              </small>
            </div>

            <label class="alert alert-danger" *ngIf="employeeForm.errors">{{
              employeeForm.errors.dates
            }}</label>
          </div>

          <div class="text-danger" *ngIf="medicalRestrictionList.length > 0">
            <h6>WARNING: This employee has a current medical restriction</h6>
            <ul>
              <li *ngFor="let medicalRestriction of medicalRestrictionList">
                {{ medicalRestriction }}
              </li>
            </ul>
          </div>

          <div *ngIf="SpecialOverride" class="form-group row">
            <label class="col-sm-4 col-form-label">Validate:</label>
            <div class="col-sm-8">
              <input
                formControlName="SpecialOverride"
                type="checkbox"
                class="form-control checkbox-label"
              />
            </div>
          </div>
          <small class="text-danger" *ngIf="ValidateSpecialMessage">
            You have chosen a request type of 'Special' but a shift other than
            the employee's current one. You must provide an override.
          </small>

          <div *ngIf="PlantSeniorityValidate" class="form-group row">
            <label class="col-sm-4 col-form-label">Validate</label>
            <div class="col-sm-8">
              <input
                formControlName="SeniorityOverrideFlag"
                type="checkbox"
                class="form-control checkbox-label"
              />
            </div>
          </div>
          <small class="text-danger" *ngIf="PlantSeniorityValidate">
            You must provide an override due to less than 90 days plant
            seniority.
          </small>

          <div class="form-group row">
            <div class="col-sm-4">
              Entered By:
              {{
                empSelected.enteredBy
                  ? (empSelected.enteredBy | uppercase)
                  : '-'
              }}
            </div>
            <div class="col-sm-4">
              Date Entered :
              {{
                empSelected.dateEntered
                  ? (empSelected.dateEntered | date: 'MM/dd/yyyy')
                  : '-'
              }}
            </div>
            <div class="col-sm-4">
              Last Update By:
              {{
                empSelected.lastUpdateBy
                  ? (empSelected.lastUpdateBy | uppercase)
                  : '-'
              }}
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer" style="padding-right: 80px">
        <button
          style="height: 40px; width: 150px; border-radius: 10px"
          type="button"
          class="btn btn-secondary popupbuttonscancel cancel-btn"
          data-dismiss="modal"
          #closeCorrectModel
          (click)="clear()"
        >
          Cancel
        </button>
        <button
          style="height: 40px; width: 150px; border-radius: 10px"
          type="button"
          class="btn btn-primary update-btn"
          [disabled]="
            employeeForm.errors ||
            statusClosed ||
            employeeForm.invalid ||
            invalidStatus
          "
          (click)="addEmployee()"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>
