import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';
import { CommonUtility } from 'src/common/commonUtility'

@Injectable({
  providedIn: 'root',
})
export class OverTimeMaintanceService {
  private readonly api_OvertimeMainSearch = '/OvertimeMainSearch';
  private readonly api_OvertimeNavigateToDetail = '/NavigateToOvertimeDetailSearch/?id=';
  private readonly api_OvertimeMainDetailSearch = '/OvertimeDetailSearch';
  private readonly api_RuleEngineMainSearch = '/OvertimeMainSearch';
  private readonly _getOvertimeReason = '/GetOvertimeReason';
  private readonly _getOvertimeType = '/GetOvertimeType';
  private readonly _overtimeAdd = '/OvertimeAdd';
  private readonly _overtimeMassAdd = '/OverTimeMassAdd';
  private readonly _getHolidayList = '/GetHolidaysList';
  private readonly api_OvertimeMainDetailDelete = '/OvertimeDelete';
  private readonly _overtimeMassDelete = '/OvertimeMassDelete';
  private readonly api_OvertimeGetRecords = '/OvertimeGetRecords';
  private readonly api_OvertimeMassUpdate = '/OverTimeMassUpdate';
  private readonly api_NavigateToOvertimeArchiveAll = '/NavigateToOvertimeDeatilsArchiveAll?id=';
  private readonly api_ArchiveAll = '/OvertimeArchiveAll';
  private readonly api_OvertimeAllocSummary = '/OvertimeAllocationSummaryReport';
  private readonly api_OvertimeAllocSummaryDetails = '/OvertimeAllocationSummaryDetail';
  private readonly api_MedicalRestrictions = '/GetMedicalRestrictions';
  private readonly api_ReassignOvertimeGroup = '/ReassignOvertimeGroup'
  private readonly api_AddOvertimeReassignGroup = '/AddOvertimeReassignGroup';
  private readonly api_getOvertimeGroup = '/GetOvertimeGroup';
  private readonly _overtimeAcceptedRefusedReport = '/OvertimeAcceptedRefusedReport';


  appId = new Subject();
  empsignDate = new Subject();
  atid = new Subject();
  commonUtility = new CommonUtility();
  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  SearchOverTimeMainByUser(searchData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.overTimeUrl}` +
      this.api_OvertimeMainSearch,
      searchData,
      { headers }
    );
  }

  NavigateToDetailByUser(employeeid: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.overTimeUrl}` +
      this.api_OvertimeNavigateToDetail +
      employeeid,
      { headers }
    );
  }

  SearchOverTimeDetailByUser(searchData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.overTimeUrl}` +
      this.api_OvertimeMainDetailSearch,
      searchData,
      { headers }
    );
  }

  ValidateRuleEngineOvertimeMainSearch(searchData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.rulesEngineURL}` + this.api_RuleEngineMainSearch, searchData,
      { headers }
    );
  }

  getOvertimeType(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.overTimeUrl}` +
      this._getOvertimeType,
      { headers }
    );
  }

  getOvertimeReason(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.overTimeUrl}` +
      this._getOvertimeReason,
      { headers }
    );
  }

  overtimeAdd(overtimeAdd: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.overTimeUrl}` +
      this._overtimeAdd,
      overtimeAdd,
      { headers }
    );
  }

  overtimeMassAdd(overtimeMassAdd: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.overTimeUrl}` +
      this._overtimeMassAdd,
      overtimeMassAdd,
      { headers }
    );
  }

  overtimeDelete(overtimeDeleteRecord: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.overTimeUrl}` +
      this.api_OvertimeMainDetailDelete,
      overtimeDeleteRecord,
      { headers }
    );
  }


  overtimeMassDelete(overtimeMassDelete: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
       `${environment.overTimeUrl}` +
       this._overtimeMassDelete,
      overtimeMassDelete,
      { headers }
    );
  }


  GetOvertimeRecordsForUpdate(searchData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.overTimeUrl}` +
      this.api_OvertimeGetRecords,
      searchData,
      { headers }
    );
  }

  MassUpdateOvertimeRecords(searchData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.overTimeUrl}` + this.api_OvertimeMassUpdate,
      searchData,
      { headers }
    );
  }

  NavigateToArchiveAll(atid: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.overTimeUrl}` + this.api_NavigateToOvertimeArchiveAll
      + atid,
      { headers }
    );
  }

  OvertimeAllocationSummary(searchData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.overTimeUrl}` + this.api_OvertimeAllocSummary, searchData,
      { headers }
    );
  }

  OvertimeAllocationSummaryDetail(searchData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.overTimeUrl}` + this.api_OvertimeAllocSummaryDetails, searchData,
      { headers }
    );
  }

  OveritmeArchiveAll(overtimeArchiveAll: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.overTimeUrl}` + this.api_ArchiveAll,
      overtimeArchiveAll,
      { headers }
    );
  }

  GetHolidaysList(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.overTimeUrl}` + this._getHolidayList,
      { headers }
    );
  }

  getMedicalRestrictions(medicalRestrictionEmployeeId: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.overTimeUrl}` + this.api_MedicalRestrictions,
      medicalRestrictionEmployeeId,
      { headers }
    );
  }

  reassignOvertimeGroup(ReassignOvertimeGroupData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.overTimeUrl}` + this.api_ReassignOvertimeGroup,
      ReassignOvertimeGroupData,
      { headers }
    );
  }

  /**/
  AddOvertimeReassignRepGroup(representationObj: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.overTimeUrl}` + this.api_AddOvertimeReassignGroup,
      representationObj,
      { headers }
    );
  }

  SearchOvertimeGroup(searchData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.overTimeUrl}` + this.api_getOvertimeGroup, 
      searchData,
      { headers }
    );
  }

  GenerateReport(searchCriteria:any) :Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.overTimeUrl}` + this._overtimeAcceptedRefusedReport,
      searchCriteria,
      { headers }
    );
  }
}

