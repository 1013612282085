import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class OvertimeReasonDataService {
  commonUtility = new CommonUtility();
  private readonly api_addOvertimeReasonData = '/OvertimeReasonDataAdd'
  private readonly api_deleteOvertimeReasonData = '/OvertimeReasonDataDelete?overtimeReasonId='
  private readonly api_editOvertimeReasonData = '/OvertimeReasonDataUpdate'
  private readonly api_getOvertimeReasonData = '/OvertimeReasonDataGetAll'

  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  addOvertimeReasonData(overtimeReasonData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_addOvertimeReasonData,
      overtimeReasonData,
      { headers }
    );
  }

  editOvertimeReasonData(overtimeReasonData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.tableMaintenanceURL}` + this.api_editOvertimeReasonData,
      overtimeReasonData,
      { headers }
    );
  }

  deleteOvertimeReasonData(overtimeReasonId: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.delete<any>(
      `${environment.tableMaintenanceURL}` + this.api_deleteOvertimeReasonData + overtimeReasonId,
      { headers }
    );
  }

  getOvertimeReasonData(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getOvertimeReasonData,
      { headers }
    );
  }
}
