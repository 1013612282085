<router-outlet></router-outlet>
<div class="container-fluid app-box" style="background-color: white">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol *ngIf="isView" class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Views</li>
        <li class="breadcrumb-item brd-li brd-li-child">Overtime View</li>
      </ol>
      <ol *ngIf="isAllocationView" class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Views</li>
        <li class="breadcrumb-item brd-li brd-li-child">
          Overtime Allocation Summary
        </li>
      </ol>
      <ol *ngIf="!isView && !isAllocationView" class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">UAWCAS</li>
        <li class="breadcrumb-item brd-li-child">Overtime</li>
        <li class="breadcrumb-item brd-li-child">Overtime Maintenance</li>
      </ol>
    </nav>
    <div
      style="float: right; position: absolute; right: 276px"
      *ngIf="
        !isAllocationView &&
        userData !== null &&
        userData?.length > 0 &&
        showFilter &&
        !isUserDataEmpty
      "
    >
      <button
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        style="margin-left: 15px"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
    </div>
    <div
      style="float: right; position: absolute; right: 276px"
      *ngIf="
        userData !== null &&
        userData?.length > 0 &&
        !showFilter &&
        !isUserDataEmpty
      "
    >
      <button
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        style="margin-left: 15px"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
    </div>
  </div>
  <div>
    <div class="row content-container mt-2 mb-2">
      <nav class="navbar navbar-expand-lg navbar-light bg-light applicationcss">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto"></ul>
          <form *ngIf="!isAllocationView" class="form-inline my-2 my-lg-0">
            <div *ngFor="let iSearchType of searchTypes; let i = index">
              <input
                id="iSearchType{{ i }}"
                class="select-radio"
                name="seasons"
                type="radio"
                [value]="iSearchType"
                [(ngModel)]="searchType"
                (change)="onSearchTypeSelected(iSearchType)"
              />
              <label
                class="select-label"
                for=""
                (click)="onSearchTypeSelected(iSearchType)"
                >{{ iSearchType }}</label
              >
            </div>
          </form>
          <h5 *ngIf="isAllocationView" style="color: white">
            Overtime Allocation Summary
          </h5>
        </div>
        <br />
      </nav>
      <!-- global search filter starts -->
      <div class="div-color">
        <div class="search-divider-div filtercss">
          <!-- filer -->
          <div
            style="margin-bottom: 0px !important; padding: 1rem 1rem"
            class="jumbotron jumb-new jumb-newcontrols"
            *ngIf="showFilter"
          >
            <div class="row" display="float">
              <div class="col">
                <form (ngSubmit)="submitForm()">
                  <div class="form-row">
                    <div class="form-group col-md-10">
                      <app-common-search
                        [isAllocationView]="isAllocationView"
                        [SectionDepartmentbool]="true"
                        [DefaultDeptVal]="DefaultDeptOvt"
                        [SectionClassificationbool]="true"
                        [DefaultClassVal]="DefaultClassOvt"
                        [SectionAsOfDatebool]="true"
                        [DefaultShiftVal]="DefaultShiftOvt"
                        [SectionOverTimeTypeBool]="true"
                        [DefaultOverTimeTypeVal]="DefaultOverTimeTypeOvt"
                        [SectionShiftBool]="true"
                        [DefaultAsOfDateVal]="DefaultAsofDateOvt"
                        (AnyEventEmitter)="modelRepopulateHandler($event)"
                      ></app-common-search>
                    </div>
                    <div class="form-group col-md-1" style="padding-top: 20px">
                      <button
                        *ngIf="views[0]?.allowSearch"
                        class="btn btn-lg search-button"
                        [disabled]="!disableSubmit"
                        type="submit"
                      >
                        Search
                        <img
                          src="./assets/search-solid.svg"
                          class="img-fluid user-icon ml-1 search-icon"
                        />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- filer -->
          <div
            class="jumbotron jumb-new jumb-newcontrols"
            style="
              margin-bottom: 0px !important;
              padding: 1rem 2rem;
              height: 88px;
            "
            *ngIf="!showFilter"
          >
            <div class="row" display="float">
              <div class="col">
                <form (ngSubmit)="submitForm()">
                  <div class="form-row">
                    <div class="form-group col-md-10">
                      <app-common-search
                        [SectionDepartmentbool]="false"
                        [SectionClassificationbool]="false"
                        [SectionOverTimeTypeBool]="false"
                        [SectionShiftBool]="false"
                        [SectionFirstNamebool]="true"
                        [SectionLastNamebool]="true"
                        [SectionATIDbool]="true"
                        [DefaultIndFirstNameVal]="DefaultIndFirstName"
                        [DefaultIndLastNameVal]="DefaultIndLastName"
                        [DefaultIndEmpIdVal]="DefaultIndEmployeeId"
                        (AnyEventEmitter)="modelRepopulateHandler($event)"
                      >
                      </app-common-search>
                    </div>
                    <div
                      class="form-group col-md-1"
                      style="padding-top: 20px; padding-right: 60px !important"
                    >
                      <button
                        style="width: 110px"
                        *ngIf="views[0]?.allowSearch"
                        class="btn btn-lg search-button"
                        [disabled]="!disableSubmit"
                        type="submit"
                      >
                        Search
                        <img
                          src="./assets/search-solid.svg"
                          class="img-fluid user-icon ml-1 search-icon"
                        />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- filter ends -->
        </div>
        <div
          [ngClass]="!isAllocationView ? 'height: 68px' : 'height: 10px'"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            top: 145px;
            position: sticky;
            background: white;
          "
        >
          <div
            style="float: left !important; height: 38px; padding-top: 0.3rem"
          >
            <div *ngIf="showFilter && !isUserDataEmpty">
              <input
                *ngIf="!isAllocationView"
                type="button"
                value="Details"
                (click)="DetailNavigate()"
                [disabled]="!enableDetailButton"
                style="margin: 5px"
                [ngClass]="!enableDetailButton ? 'disablededitcolors' : ''"
                class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
              />
              <input
                *ngIf="views[0]?.allowAdd && !isView && !isAllocationView"
                type="button"
                value="Add"
                style="margin: 5px"
                [disabled]="!enableAddButton"
                (click)="onAddOvertime()"
                [ngClass]="!enableAddButton ? 'disablededitcolors' : ''"
                data-toggle="modal"
                data-backdrop="false"
                [attr.data-target]="modalId"
                class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
              />
              <input
                *ngIf="views[0]?.allowUpdate && !isView && !isAllocationView"
                type="button"
                value="Update"
                style="margin: 5px"
                [disabled]="!enableAddButton"
                (click)="onUpdateOvertime()"
                [ngClass]="!enableAddButton ? 'disablededitcolors' : ''"
                data-toggle="modal"
                [attr.data-target]="modalId"
                data-backdrop="false"
                class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
              />
              <input
                *ngIf="views[0]?.allowDelete && !isView && !isAllocationView"
                type="button"
                value="Delete"
                style="margin: 5px"
                [disabled]="!enableAddButton"
                (click)="onDeleteOvertime()"
                [ngClass]="!enableAddButton ? 'disablededitcolors' : ''"
                data-toggle="modal"
                [attr.data-target]="modalId"
                data-backdrop="false"
                class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
              />
              <input
                *ngIf="!isAllocationView"
                type="button"
                value="Allocation Summary"
                (click)="onAllocationSummaryClick()"
                style="margin: 5px"
                class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
              />

              <input
                *ngIf="!isAllocationView"
                type="button"
                value="Print"
                data-toggle="modal"
                [attr.data-target]="modalId"
                (click)="onPrintClick()"
                class="btn btn-success buttoncolours crudbuttons pull-right my-2 my-sm-0"
                data-backdrop="false"
              />
              <!-- <input *ngIf="!isAllocationView" type="button" value="Export To Excel" (click)="exportElmToExcel()" style="margin: 5px" class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0" /> -->

              <!-- <a target="_blank" type="button" routerLink="/OvertimeAllocationSummary"  value="Allocation Summary" (click)="onAllocationSummaryClick()" class="btn btn-success buttoncolours crudbuttons pull-right my-2 my-sm-0"> Allocation Summary</a> -->
              <br /><br />
            </div>

            <!-- For Individual Buttons -->
            <div *ngIf="!showFilter && !isUserDataEmpty">
              <input
                type="button"
                [disabled]="!enableIndButtons"
                [ngClass]="!enableIndButtons ? 'disablededitcolors' : ''"
                value="Details"
                (click)="DetailNavigateInd()"
                style="margin: 15px"
                class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
              />
              <input
                *ngIf="views[0]?.allowAdd && !isView"
                type="button"
                value="Add"
                [disabled]="!enableIndButtons"
                [ngClass]="!enableIndButtons ? 'disablededitcolors' : ''"
                (click)="onIndOvertimeAdd()"
                style="margin: 15px"
                data-toggle="modal"
                [attr.data-target]="modalId"
                data-backdrop="false"
                class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
              />
              <input
                *ngIf="views[0]?.allowUpdate && !isView"
                type="button"
                [disabled]="!enableIndButtons"
                [ngClass]="!enableIndButtons ? 'disablededitcolors' : ''"
                value="Update"
                (click)="onIndOvertimeUpdate()"
                style="margin: 15px"
                (click)="onIndOvertimeUpdate()"
                data-toggle="modal"
                [attr.data-target]="modalId"
                data-backdrop="false"
                class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
              />
              <input
                *ngIf="views[0]?.allowDelete && !isView"
                type="button"
                [disabled]="!enableIndButtons"
                [ngClass]="!enableIndButtons ? 'disablededitcolors' : ''"
                value="Delete"
                (click)="onIndOvertimeDelete()"
                style="margin: 15px"
                (click)="onIndOvertimeDelete()"
                data-toggle="modal"
                [attr.data-target]="modalId"
                data-backdrop="false"
                class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
              />
              <input
                type="button"
                value="Print"
                data-toggle="modal"
                [attr.data-target]="modalId"
                data-backdrop="false"
                (click)="onPrintClick()"
                class="btn btn-success buttoncolours crudbuttons pull-right my-2 my-sm-0"
              />
              <!-- <input *ngIf="!isAllocationView" type="button" value="Export To Excel" (click)="exportElmToExcel()" style="margin: 5px" class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0" /> -->
            </div>
          </div>
          <div
            *ngIf="!isUserDataEmpty && !isAllocationView"
            style="
              float: right;
              color: #0032a0;
              height: 47px;
              width: 500px;
              padding: 4px;
              border-radius: 4px;
              background: #e1e9f8;
            "
          >
            <div *ngIf="showFilter" style="font-weight: bold; font-size: 14px">
              Average Accumulated Hours for Overtime Group
              {{ overtimeGroupName }}: {{ AverageAccHours | number : "1.2-2" }}
            </div>
            <div style="font-size: 12px">
              (Note: All accumulated hours are based on an as-of date of
              {{ asOfDate | date : "MMM d, y" }})
            </div>
          </div>
        </div>

        <div class="reulsttbl">
          <!-- This is Export to document Section -->
          <div *ngIf="!isAllocationView">
            <table
              style="font-size: 14px"
              *ngIf="
                !isDataExportableView &&
                userData !== null &&
                userData?.length > 0
              "
              #OvertimeTableExport
            >
              <thead class="table-header">
                <tr>
                  <th scope="col">
                    <span>Name</span>
                  </th>
                  <th scope="col">
                    <span>Emp Id</span>
                  </th>
                  <th scope="col">
                    <span>Dept</span>
                  </th>
                  <th scope="col" *ngIf="!showFilter">
                    <span>Overtime Group</span>
                  </th>
                  <th
                    scope="col"
                    *ngIf="ClassificationType === 'P' && showFilter"
                  >
                    <span>Seniority Date</span>
                  </th>
                  <th
                    scope="col"
                    *ngIf="ClassificationType === 'S' && showFilter"
                  >
                    <span>Skill Date</span>
                  </th>
                  <th scope="col">
                    <span>Acc Hrs</span>
                  </th>
                  <th scope="col">
                    <span>
                      {{ monday | date : "EEE" }}<br />{{
                        monday | date : "MM/dd"
                      }}
                    </span>
                  </th>
                  <th scope="col">
                    <span>
                      {{ tuesday | date : "EEE" }}<br />{{
                        tuesday | date : "MM/dd"
                      }}
                    </span>
                  </th>
                  <th scope="col">
                    <span>
                      {{ wednesday | date : "EEE" }}<br />{{
                        wednesday | date : "MM/dd"
                      }}
                    </span>
                  </th>
                  <th scope="col">
                    <span>
                      {{ thursday | date : "EEE" }}<br />{{
                        thursday | date : "MM/dd"
                      }}
                    </span>
                  </th>
                  <th scope="col">
                    <span>
                      {{ friday | date : "EEE" }}<br />{{
                        friday | date : "MM/dd"
                      }}
                    </span>
                  </th>
                  <th scope="col">
                    <span>
                      {{ saturday | date : "EEE" }}<br />{{
                        saturday | date : "MM/dd"
                      }}
                    </span>
                  </th>
                  <th scope="col">
                    <span>
                      {{ sunday | date : "EEE" }}<br />{{
                        sunday | date : "MM/dd"
                      }}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let val of userData; let i = index">
                  <!-- <td class="tab-value">{{ i + 1 }}</td>
                                        <td class="tab-value">
                                            <span *ngIf="showFilter">
                                                <input type="checkbox" [(ngModel)]="val.ischecked"
                                                    (change)="onEmployeeSelect(val)" />
                                            </span>
                                            <span *ngIf="!showFilter">
                                                <input type="radio" name="IndEmp" (change)="onIndEmployeeSelect(val, i)" />
                                            </span>
                                        </td> -->

                  <td class="left-tab-value" class="otlabel">
                    <span *ngIf="showFilter">
                      <a
                        [ngStyle]="{
                          color:
                            val.isinspread === true && showFilter
                              ? 'green'
                              : 'red'
                        }"
                        class="medrestrict"
                        *ngIf="val.hasrestrict === false"
                        data-toggle="modal"
                        [attr.data-target]="modalId"
                        data-backdrop="false"
                        (click)="
                          showMedicalRestrictions1(
                            val.name,
                            val.teL_NUM,
                            'PhoneNumber',
                            val.firsT_NAME,
                            val.lasT_NAME,
                            val.middle
                          )
                        "
                      >
                        {{ val.lasT_NAME ? val.lasT_NAME : "N/A" }},&nbsp;{{
                          val.firsT_NAME ? val.firsT_NAME : "N/A"
                        }}&nbsp;{{ val.middle ? val.middle : "" }}</a
                      >
                      <a
                        [ngStyle]="{
                          color:
                            val.isinspread === true && showFilter
                              ? 'green'
                              : 'red'
                        }"
                        class="medrestrict"
                        *ngIf="val.hasrestrict === true"
                        data-toggle="modal"
                        [attr.data-target]="modalId"
                        data-backdrop="false"
                        (click)="
                          showMedicalRestrictions1(
                            val.employeeid,
                            val.teL_NUM,
                            'MedicalRestriction',
                            val.firsT_NAME,
                            val.lasT_NAME,
                            val.middle
                          )
                        "
                        >{{ val.lasT_NAME ? val.lasT_NAME : "N/A" }},&nbsp;{{
                          val.firsT_NAME ? val.firsT_NAME : "N/A"
                        }}&nbsp;{{ val.middle ? val.middle : "" }} *</a
                      >
                    </span>
                    <span *ngIf="!showFilter" class="otlabel">
                      <a
                        class="phoneNumeber"
                        *ngIf="val.hasrestrict === false"
                        data-toggle="modal"
                        [attr.data-target]="modalId"
                        data-backdrop="false"
                        (click)="
                          showMedicalRestrictions1(
                            val.name,
                            val.teL_NUM,
                            'PhoneNumber',
                            val.firsT_NAME,
                            val.lasT_NAME,
                            val.middle
                          )
                        "
                        >{{ val.lasT_NAME ? val.lasT_NAME : "N/A" }},&nbsp;{{
                          val.firsT_NAME ? val.firsT_NAME : "N/A"
                        }}&nbsp;{{ val.middle ? val.middle : "" }}</a
                      >
                      <a
                        [ngStyle]="{
                          color:
                            val.isinspread === true && showFilter
                              ? 'black'
                              : 'black'
                        }"
                        class="medrestrictInd"
                        *ngIf="val.hasrestrict === true"
                        data-toggle="modal"
                        [attr.data-target]="modalId"
                        data-backdrop="false"
                        (click)="
                          showMedicalRestrictions1(
                            val.employeeid,
                            val.teL_NUM,
                            'MedicalRestriction',
                            val.firsT_NAME,
                            val.lasT_NAME,
                            val.middle
                          )
                        "
                        >{{ val.lasT_NAME ? val.lasT_NAME : "N/A" }},&nbsp;{{
                          val.firsT_NAME ? val.firsT_NAME : "N/A"
                        }}&nbsp;{{ val.middle ? val.middle : "" }}*</a
                      >
                    </span>
                    <!-- <label *ngIf="!showFilter">
                          <span>{{ val.name ? val.name : "N/A" }}</span>
                          <span class="medrestrict" *ngIf="val.hasrestrict===1"
                            >*</span
                          >
                        </label> -->
                  </td>
                  <td class="tab-value">
                    {{ val.employeeid ? val.employeeid : "N/A" }}
                  </td>
                  <td class="tab-value">{{ val.dept ? val.dept : "N/A" }}</td>
                  <td class="tab-value" *ngIf="!showFilter">
                    {{ val.overtimegroupname ? val.overtimegroupname : "N/A" }}
                  </td>
                  <td class="tab-value" *ngIf="showFilter">
                    {{ val.emP_START_DATE | date : "MM/dd/yyyy" }} --
                  </td>
                  <td class="tab-value">
                    <span *ngIf="val.acchours !== 0">{{
                      val.acchours | number : "1.2-2"
                    }}</span>
                    <span *ngIf="val.acchours === 0.0 && val.isactive">
                      {{ val.acchours | number : "1.2-2" }}</span
                    >
                    <span
                      *ngIf="val.acchours === 0.0 && !val.isactive"
                      style="color: red"
                      >No Hours</span
                    >
                  </td>
                  <td class="left-tab-value">
                    {{ val.monday ? val.monday : "" }}
                  </td>
                  <td class="left-tab-value">
                    {{ val.tuesday ? val.tuesday : "" }}
                  </td>
                  <td class="left-tab-value">
                    {{ val.wednesday ? val.wednesday : "" }}
                  </td>
                  <td class="left-tab-value">
                    {{ val.thursday ? val.thursday : "" }}
                  </td>
                  <td class="left-tab-value">
                    {{ val.friday ? val.friday : "" }}
                  </td>
                  <td class="left-tab-value">
                    {{ val.saturday ? val.saturday : "" }}
                  </td>
                  <td class="left-tab-value">
                    {{ val.sunday ? val.sunday : "" }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <div class="single-wrap d-flex justify-content-center" *ngIf="userData !==null && userData?.length > 0">
                                <nav aria-label="Page navigation example">
                                    <pagination-controls class="my-pagination" id="joblist" (pageChange)="Applicationpage=$event" previousLabel="" nextLabel="">
                                    </pagination-controls>
                                </nav>
                            </div> -->
            <div>
              <p
                *ngIf="
                  !isDataExportableView &&
                  isSearchHit &&
                  (userData === null || userData?.length === 0)
                "
                class="noDataMsg"
              >
                &nbsp;No Record Found
              </p>
            </div>
          </div>
          <!-- This is Main Table Section -->
          <!--For user-->
          <div *ngIf="!isAllocationView">
            <table
              style="font-size: 14px"
              class="table table-striped"
              *ngIf="userData !== null && userData?.length > 0"
            >
              <thead class="table-header" style="position: sticky">
                <th scope="col">No.</th>
                <th scope="col" class="pb-1">
                  <label class="mb-0">
                    <input
                      type="checkbox"
                      *ngIf="showFilter"
                      [(ngModel)]="isSelectAllEmployee"
                      (change)="selectAllEmployee()"
                    />
                  </label>
                </th>

                <th scope="col">
                  <span (click)="onSortClick($event, 'name', 'string')"
                    >Name
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'name'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'name'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'name'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'employeeid', 'string')"
                    >Emp Id
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'employeeid'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'employeeid'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'employeeid'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'dept', 'string')"
                    >Dept
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'dept'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'dept'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'dept'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col" *ngIf="!showFilter">
                  <span
                    (click)="onSortClick($event, 'overtimegroupname', 'string')"
                    >Overtime Group
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'overtimegroupname'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'overtimegroupname'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'overtimegroupname'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th
                  scope="col"
                  *ngIf="ClassificationType === 'P' && showFilter"
                >
                  <span
                    (click)="onSortClick($event, 'emP_START_DATE', 'datetime')"
                    >Seniority Date
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'emP_START_DATE'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'emP_START_DATE'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'emP_START_DATE'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th
                  scope="col"
                  *ngIf="ClassificationType === 'S' && showFilter"
                >
                  <span
                    (click)="
                      onSortClick($event, 'planT_SKILL_DATE', 'datetime')
                    "
                    >Skill Date
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'planT_SKILL_DATE'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'planT_SKILL_DATE'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'planT_SKILL_DATE'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'acchours', 'decimal')"
                    >Acc Hrs
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'acchours'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'acchours'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'acchours'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'monday', 'string')">
                    {{ monday | date : "EEE" }}<br />{{
                      monday | date : "MM/dd"
                    }}
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'monday'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'monday'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'monday'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'tuesday', 'string')">
                    {{ tuesday | date : "EEE" }}<br />{{
                      tuesday | date : "MM/dd"
                    }}
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'tuesday'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'tuesday'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'tuesday'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'wednesday', 'string')">
                    {{ wednesday | date : "EEE" }}<br />{{
                      wednesday | date : "MM/dd"
                    }}
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'wednesday'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'wednesday'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'wednesday'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'thursday', 'string')">
                    {{ thursday | date : "EEE" }}<br />{{
                      thursday | date : "MM/dd"
                    }}
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'thursday'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'thursday'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'thursday'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'friday', 'string')">
                    {{ friday | date : "EEE" }}<br />{{
                      friday | date : "MM/dd"
                    }}
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'friday'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'friday'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'friday'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'saturday', 'string')">
                    {{ saturday | date : "EEE" }}<br />{{
                      saturday | date : "MM/dd"
                    }}
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'saturday'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'saturday'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'saturday'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'sunday', 'string')">
                    {{ sunday | date : "EEE" }}<br />{{
                      sunday | date : "MM/dd"
                    }}
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'sunday'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'sunday'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'sunday'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
              </thead>
              <tbody>
                <tr
                  *ngFor="let val of userData; let i = index"
                  class="table-container"
                >
                  <td class="tab-value">{{ i + 1 }}</td>
                  <td class="tab-value">
                    <span *ngIf="showFilter">
                      <input
                        type="checkbox"
                        [(ngModel)]="val.ischecked"
                        (change)="onEmployeeSelect(val)"
                      />
                    </span>
                    <span *ngIf="!showFilter">
                      <input
                        type="radio"
                        name="IndEmp"
                        (change)="onIndEmployeeSelect(val, i)"
                      />
                    </span>
                  </td>

                  <td class="left-tab-value" class="otlabel">
                    <span *ngIf="showFilter">
                      <a
                        [ngStyle]="{
                          color:
                            val.isinspread === true && showFilter
                              ? 'green'
                              : 'red'
                        }"
                        class="medrestrict"
                        *ngIf="val.hasrestrict === false"
                        data-toggle="modal"
                        [attr.data-target]="modalId"
                        data-backdrop="false"
                        (click)="
                          showMedicalRestrictions1(
                            val.name,
                            val.teL_NUM,
                            'PhoneNumber',
                            val.firsT_NAME,
                            val.lasT_NAME,
                            val.middle
                          )
                        "
                        >{{ val.lasT_NAME ? val.lasT_NAME : "N/A" }},&nbsp;{{
                          val.firsT_NAME ? val.firsT_NAME : "N/A"
                        }}&nbsp;{{ val.middle ? val.middle : "" }}</a
                      >
                      <a
                        [ngStyle]="{
                          color:
                            val.isinspread === true && showFilter
                              ? 'green'
                              : 'red'
                        }"
                        class="medrestrict"
                        *ngIf="val.hasrestrict === true"
                        data-toggle="modal"
                        [attr.data-target]="modalId"
                        data-backdrop="false"
                        (click)="
                          showMedicalRestrictions1(
                            val.employeeid,
                            val.teL_NUM,
                            'MedicalRestriction',
                            val.firsT_NAME,
                            val.lasT_NAME,
                            val.middle
                          )
                        "
                        >{{ val.lasT_NAME ? val.lasT_NAME : "N/A" }},&nbsp;{{
                          val.firsT_NAME ? val.firsT_NAME : "N/A"
                        }}&nbsp;{{ val.middle ? val.middle : "" }} *</a
                      >
                    </span>
                    <span *ngIf="!showFilter" class="otlabel">
                      <a
                        class="phoneNumeber"
                        *ngIf="val.hasrestrict === false"
                        data-toggle="modal"
                        [attr.data-target]="modalId"
                        data-backdrop="false"
                        (click)="
                          showMedicalRestrictions1(
                            val.name,
                            val.teL_NUM,
                            'PhoneNumber',
                            val.firsT_NAME,
                            val.lasT_NAME,
                            val.middle
                          )
                        "
                        >{{ val.lasT_NAME ? val.lasT_NAME : "N/A" }},&nbsp;{{
                          val.firsT_NAME ? val.firsT_NAME : "N/A"
                        }}&nbsp;{{ val.middle ? val.middle : "" }}</a
                      >
                      <a
                        [ngStyle]="{
                          color:
                            val.isinspread === true && showFilter
                              ? 'black'
                              : 'black'
                        }"
                        class="medrestrictInd"
                        *ngIf="val.hasrestrict === true"
                        data-toggle="modal"
                        [attr.data-target]="modalId"
                        data-backdrop="false"
                        (click)="
                          showMedicalRestrictions1(
                            val.employeeid,
                            val.teL_NUM,
                            'MedicalRestriction',
                            val.firsT_NAME,
                            val.lasT_NAME,
                            val.middle
                          )
                        "
                        >{{ val.lasT_NAME ? val.lasT_NAME : "N/A" }},&nbsp;{{
                          val.firsT_NAME ? val.firsT_NAME : "N/A"
                        }}&nbsp;{{ val.middle ? val.middle : "" }}*</a
                      >
                    </span>
                    <!-- <label *ngIf="!showFilter">
                      <span>{{ val.name ? val.name : "N/A" }}</span>
                      <span class="medrestrict" *ngIf="val.hasrestrict===1"
                        >*</span
                      >
                    </label> -->
                  </td>
                  <td class="tab-value">
                    {{ val.employeeid ? val.employeeid : "N/A" }}
                  </td>
                  <td class="tab-value">{{ val.dept ? val.dept : "N/A" }}</td>
                  <td class="tab-value" *ngIf="!showFilter">
                    {{ val.overtimegroupname ? val.overtimegroupname : "N/A" }}
                  </td>
                  <td
                    class="tab-value"
                    *ngIf="showFilter && ClassificationType === 'P'"
                  >
                    {{ val.emP_START_DATE | date : "MM/dd/yyyy" }}
                  </td>

                  <td
                    class="tab-value"
                    *ngIf="showFilter && ClassificationType === 'S'"
                  >
                    {{ val.planT_SKILL_DATE | date : "MM/dd/yyyy" }}
                  </td>

                  <td class="tab-value">
                    <span *ngIf="val.acchours !== 0">{{
                      val.acchours | number : "1.2-2"
                    }}</span>
                    <span *ngIf="val.acchours === 0.0 && val.isactive">
                      {{ val.acchours | number : "1.2-2" }}</span
                    >
                    <span
                      *ngIf="val.acchours === 0.0 && !val.isactive"
                      style="color: red"
                      >No Hours</span
                    >
                  </td>
                  <td class="left-tab-value">
                    {{ val.monday ? val.monday : "" }}
                  </td>
                  <td class="left-tab-value">
                    {{ val.tuesday ? val.tuesday : "" }}
                  </td>
                  <td class="left-tab-value">
                    {{ val.wednesday ? val.wednesday : "" }}
                  </td>
                  <td class="left-tab-value">
                    {{ val.thursday ? val.thursday : "" }}
                  </td>
                  <td class="left-tab-value">
                    {{ val.friday ? val.friday : "" }}
                  </td>
                  <td class="left-tab-value">
                    {{ val.saturday ? val.saturday : "" }}
                  </td>
                  <td class="left-tab-value">
                    {{ val.sunday ? val.sunday : "" }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <div class="single-wrap d-flex justify-content-center" *ngIf="userData !==null && userData?.length > 0">
                            <nav aria-label="Page navigation example">
                                <pagination-controls class="my-pagination" id="joblist" (pageChange)="Applicationpage=$event" previousLabel="" nextLabel="">
                                </pagination-controls>
                            </nav>
                        </div> -->
            <div>
              <p
                *ngIf="
                  isSearchHit && (userData === null || userData?.length === 0)
                "
                class="noDataMsg"
              >
                &nbsp;No Record Found
              </p>
            </div>
          </div>
          <!-- View overtime allocation summary -->
          <div *ngIf="isAllocationView">
            <table class="table table-striped" *ngIf="otGroupData !== null">
              <thead
                class="table-header"
                style="position: sticky; top: 105px !important"
              >
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Department(s)</th>
                <th scope="col">Classification(s)</th>
              </thead>
              <tbody>
                <td>
                  <p
                    class="btn my-2 my-sm-0 click-add"
                    (click)="onAllocationSummaryClick()"
                  >
                    {{ otGroupData.overtimegroupname }}
                  </p>
                </td>
                <td class="tab-value">
                  {{ otGroupData.overtimegroupdescription }}
                </td>
                <td class="tab-value">{{ otGroupData.deptlist }}</td>
                <td class="tab-value">{{ otGroupData.classlist }}</td>
              </tbody>
            </table>
            <div>
              <p *ngIf="isSearchHit && otGroupData === null" class="noDataMsg">
                &nbsp;No Record Found
              </p>
            </div>
          </div>
          <!-- View overtime allocation summary ends -->
        </div>
      </div>

      <app-add-overtime-maintenance
        [SelectedEmp]="SelectedEmp"
        [overtimeGroupName]="overtimeGroupName"
        [passAddData]="passAddData"
        [loggedInUserATID]="loggedInUserATID"
        (isIndAddSucess)="CheckIndAdd($event)"
        (MassOvertimeSuccess)="CheckMassAdd($event)"
        [isAdd]="isAdd"
      >
      </app-add-overtime-maintenance>

      <app-mass-update-overtime-maintenance
        [modifiedOvertimRecords]="modifiedOvertimRecords"
        [passUpdateData]="passUpdateData"
        (isSucess)="CheckMassUpdate($event)"
        [isUpdate]="isUpdate"
        [isSingleRecord]="isSingleRecord"
        [overtimeGroupName]="overtimeGroupName"
      >
      </app-mass-update-overtime-maintenance>

      <app-delete-overtime-maintenance
        [modifiedOvertimRecords]="modifiedOvertimRecords"
        [passUpdateData]="passUpdateData"
        (isMassDeleteSuccess)="CheckMassDelete($event)"
        [isDelete]="isDelete"
        [isSingleRecord]="isSingleRecord"
        [overtimeGroupName]="overtimeGroupName"
      ></app-delete-overtime-maintenance>

      <app-show-medical-restrictions
        [medicalRestrictionEmployeeId]="medicalRestrictionEmployeeId"
        [medicalRestrictionEmpName]="medicalRestrictionEmpName"
        [ModalName]="ModalName"
        [employeePhoneNumber]="employeePhoneNumber"
      >
      </app-show-medical-restrictions>

      <app-overtime-weekly-report
        [weeklyOvertimeRecords]="weeklyOvertimeRecords"
        [monday]="monday"
        [tuesday]="tuesday"
        [wednesday]="wednesday"
        [thursday]="thursday"
        [friday]="friday"
        [saturday]="saturday"
        [sunday]="sunday"
        [overtimeGroupName]="overtimeGroupName"
        [asOfDate]="asOfDate"
        [ClassificationType]="ClassificationType"
        [AverageAccHours]="AverageAccHours"
        [showFilter]="showFilter"
        [postModel]="postModel"
      >
      </app-overtime-weekly-report>
    </div>
  </div>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
