import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FacilitiesServicePlantService } from 'src/app/Services/Applications/FacilitiesAndService/facilities-service-plant.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';

@Component({
  selector: 'app-delete-facilites-service',
  templateUrl: './delete-facilites-service.component.html',
  styleUrls: ['./delete-facilites-service.component.css'],
})
export class DeleteFacilitesServiceComponent {
  @Input() selectedEmp: any;
  @Input() employeeForm: any;
  appId: any;
  @Output() isSucess = new EventEmitter();
  constructor(
    private fs: FacilitiesServicePlantService,
    private _sweetAlert: SweetAlertService
  ) {}

  confirmDelete() {
    if (this.selectedEmp) {
      this.appId = this.selectedEmp.appId;
      this.fs.Delete150Application(this.appId).subscribe((res) => {
        this._sweetAlert.successAlert(
          'center',
          'success',
          'The Application has been Deleted Successfully',
          false,
          1500
        );
        this.isSucess.emit(true);
      });
    }
  }

  clear() {
    //TODO
  }
}
