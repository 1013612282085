<div class="modal fade" id="editEmp" tabindex="-1" role="dialog" aria-labelledby="addEmployeeLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addEmployeeLabel">Edit overtime type</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="clear()">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="overtimeForm">

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Overtime Type ID</label>
                        <div class="col-sm-8">
                            <input type="text" readonly formControlName="OvertimeTypeId" class="form-control">
                            <!-- <div *ngIf="overtimeForm.controls['OvertimeTypeId'].invalid && overtimeForm.controls['OvertimeTypeId'].touched">
                                <small class="text-danger" *ngIf="overtimeForm.controls['OvertimeTypeId'].errors?.required">
                                    You must enter the overtime type ID
                                </small>
                            </div> -->
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Description*</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="OvertimeTypeDescription" class="form-control">
                            <div *ngIf="overtimeForm.controls['OvertimeTypeDescription'].invalid && overtimeForm.controls['OvertimeTypeDescription'].touched">
                                <small class="text-danger" *ngIf="overtimeForm.controls['OvertimeTypeDescription'].errors?.required">
                                    You must enter the overtime type description
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Reason Association*</label>
                        <div class="col-sm-8">
                            <select formControlName="ReasonAssociation" class="form-control">
                                <option *ngIf="ReasonAssociation[ReasonAssociation.length-1].default" selected value="">
                                    {{ReasonAssociation[ReasonAssociation.length-1].default}}</option>
                                <option *ngFor="let data of ReasonAssociation[ReasonAssociation.length-1].data"
                                    value={{data.Type}}>
                                    {{data.Name}}
                                </option>
                            </select>
                            <div *ngIf="overtimeForm.controls['ReasonAssociation'].invalid && overtimeForm.controls['ReasonAssociation'].touched">
                                <small class="text-danger" *ngIf="overtimeForm.controls['ReasonAssociation'].errors?.required">
                                    You must select the reason association of the overtime type
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Skilled Timing</label>
                        <div class="col-sm-8">
                            <input type="text" readonly formControlName="SkilledTiming" class="form-control">
                            <!-- <div *ngIf="overtimeForm.controls['SkilledTiming'].invalid && overtimeForm.controls['SkilledTiming'].touched">
                                <small class="text-danger" *ngIf="overtimeForm.controls['SkilledTiming'].errors?.required">
                                    You must select the skilled timing of the overtime type
                                </small>
                            </div> -->
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Included in Production hours?</label>
                        <div class="col-sm-8">
                            <input type="text" readonly formControlName="ProdIncluded" class="form-control">
                            <!-- <div
                                *ngIf="overtimeForm.controls['ProdIncluded'].invalid && overtimeForm.controls['ProdIncluded'].touched">
                                <small class="text-danger"
                                    *ngIf="overtimeForm.controls['ProdIncluded'].errors?.required">
                                    You must select whether or not this overtime type is included in hours caclulations
                                    for production workers
                                </small>
                            </div> -->
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Status*</label>
                        <div class="col-sm-8">
                            <select formControlName="Status" class="form-control">
                                <option *ngIf="Status[Status.length-1].default" selected value="">
                                    {{Status[Status.length-1].default}}</option>
                                <option *ngFor="let data of Status[Status.length-1].data" value={{data.statusType}}>
                                    {{data.statusName}}
                                </option>
                            </select>
                            <div *ngIf="overtimeForm.controls['Status'].invalid && overtimeForm.controls['Status'].touched">
                                <small class="text-danger" *ngIf="overtimeForm.controls['Status'].errors?.required">
                                    You must specify a valid status
                                </small>
                            </div>
                        </div>
                    </div>

                </form>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" #closeCorrectModel (click)="clear()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]="overtimeForm.errors || overtimeForm.invalid" (click)="submitOvertime()">Update</button>
            </div>
        </div>
    </div>
</div>