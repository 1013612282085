import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class DistrictDataService {
  commonUtility = new CommonUtility();
  private readonly api_addDistrictsData = '/DistrictDataAdd';
  private readonly api_deleteDistrictsData = '/DistrictDataDelete?districtId=';
  private readonly api_updateDistrictsData = '/DistrictDataUpdate';
  private readonly api_getDistrictsData = '/DistrictDataGetData';
  private readonly api_getSpecificDistrictsData = '/DistrictDataGetSpecificData?districtid=';
  

  constructor(
    private _http: HttpClient, 
    private appCom: MSALserviceService
  ) { }

  getDistrictData(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getDistrictsData,
      { headers }
    );
  }
  getSpecificDistrictData(districtId: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getSpecificDistrictsData + districtId,
      { headers }
    );
  }
  addDistrictData(districtData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_addDistrictsData,
      districtData,
      { headers }
    );
  }
  editDistrictData(districtData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.tableMaintenanceURL}` + this.api_updateDistrictsData,
      districtData,
      { headers }
    );
  }
  deleteDistrictData(districtId: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.delete<any>(
      `${environment.tableMaintenanceURL}` + this.api_deleteDistrictsData + districtId,
      { headers }
    );
  }
}
