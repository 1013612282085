import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class DemographicDataService {
  commonUtility = new CommonUtility();
  private readonly api_searchDemographicData = '/DemographicDataSearch'
  private readonly api_getEligibilityDate = '/DemographicDataGetEligibility?employeeid='
  private readonly api_editDemographicData = '/DemographicDataUpdate'
  private readonly api_demographicDataDelete = '/DemographicDataDelete'
  private readonly api_getDepartment = '/GetDepartments'
  private readonly api_getClassification = '/GetClassifications'
  private readonly api_getPlants = '/PlantDataGetPlantsData'

  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  editDemographicData(demographicData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
     `${environment.tableMaintenanceURL}` + this.api_editDemographicData,
      demographicData,
      { headers }
    );
  }

  searchDemographicData(demographicData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
     `${environment.tableMaintenanceURL}` + this.api_searchDemographicData,
      demographicData,
      { headers }
    );
  }

  getEligibilityDate(employeeid: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getEligibilityDate + employeeid,
      { headers }
    );
  }

  getClassification(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getClassification,
      { headers }
    );
  }

  getDepartment(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getDepartment,
      { headers }
    );
  }

  getPlants(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getPlants,
      { headers }
    );
  }

  DemographicDataDelete(queryParam:any): Observable<any>{
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_demographicDataDelete,
      queryParam,
      { headers }
    );
  }
}
