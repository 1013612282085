<div class="modal fade" id="editGroup" tabindex="-1" role="dialog" aria-labelledby="addEmployeeLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addEmployeeLabel">Edit Overtime Group Data</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="overtimeGroupForm">
                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Overtime Group Name *:</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="OvertimeGroupName" class="form-control">
                            <div
                                *ngIf="overtimeGroupForm.controls['OvertimeGroupName'].invalid && overtimeGroupForm.controls['OvertimeGroupName'].touched">
                                <small class="text-danger"
                                    *ngIf="overtimeGroupForm.controls['OvertimeGroupName'].errors?.required">
                                    You must enter the overtime group name
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Description*</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="OvertimeGroupDescription" class="form-control">
                            <div
                                *ngIf="overtimeGroupForm.controls['OvertimeGroupDescription'].invalid && overtimeGroupForm.controls['OvertimeGroupDescription'].touched">
                                <small class="text-danger"
                                    *ngIf="overtimeGroupForm.controls['OvertimeGroupDescription'].errors?.required">
                                    You must enter the overtime group description
                                </small>
                            </div>
                        </div>
                    </div>

                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Shift*</label>
                            <div class="col-sm-8">
                                <select formControlName="ShiftId" class="form-control">
                                    <option *ngIf="shifts[shifts.length-1].default" selected value="">
                                        {{shifts[shifts.length-1].default}}</option>
                                    <option *ngFor="let data of shifts[shifts.length-1].data" value={{data.shiftId}}>
                                        {{data.shiftDescription}}
                                    </option>
                                </select>
                                <div
                                    *ngIf="overtimeGroupForm.controls['ShiftId'].invalid && overtimeGroupForm.controls['ShiftId'].touched">
                                    <small class="text-danger"
                                        *ngIf="overtimeGroupForm.controls['ShiftId'].errors?.required">
                                        You must select the shift of the overtime group
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Spread*</label>
                            <div class="col-sm-8">
                                <input type="text" formControlName="Spread" class="form-control">
                                <div
                                    *ngIf="overtimeGroupForm.controls['Spread'].invalid && overtimeGroupForm.controls['Spread'].touched">
                                    <small class="text-danger"
                                        *ngIf="overtimeGroupForm.controls['Spread'].errors?.required">
                                        You must enter the spread of the overtime group
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Status*</label>
                            <div class="col-sm-8">
                                <select formControlName="Status" class="form-control">
                                    <option *ngIf="Status[Status.length-1].default" selected value="">
                                        {{Status[Status.length-1].default}}</option>
                                    <option *ngFor="let class of Status[Status.length-1].data"
                                        value={{class.statusType}}>
                                        {{class.statusName}}
                                    </option>
                                </select>
                                <div
                                    *ngIf="overtimeGroupForm.controls['Status'].invalid && overtimeGroupForm.controls['Status'].touched">
                                    <small class="text-danger"
                                        *ngIf="overtimeGroupForm.controls['Status'].errors?.required">
                                        You must choose the status
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputPassword" class="col-sm-4 col-form-label">Entered By: </label>
                            <div class="col-sm-8">
                                {{overtimeGroupSelected.createdby}}
                            </div>
                        </div>
    
                        <div class="form-group row">
                            <label for="inputPassword" class="col-sm-4 col-form-label">Date Entered: </label>
                            <div class="col-sm-8">
                                {{overtimeGroupSelected.createdate | date:'short'}}
                            </div>
                        </div>
    
                        <div class="form-group row">
                            <label for="inputPassword" class="col-sm-4 col-form-label">Last Updated By</label>
                            <div class="col-sm-8">
                                {{overtimeGroupSelected.lastupdateby}}
                            </div>
                        </div>
    
                        <div class="form-group row">
                            <label for="inputPassword" class="col-sm-4 col-form-label">Date of Last Update</label>
                            <div class="col-sm-8">
                                {{overtimeGroupSelected.lastupdatedate | date:'short'}}
                            </div>
                        </div>
                </form>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Department(s)*</label>
                    <div class="col-sm-8">
                        <div class="checkbox-container" *ngFor="let dept of department; let i=index">
                            <fieldset>
                                <label class="checkbox-inline">
                                    <input type="checkbox" name="dept{{dept.deptId+i+1}}"
                                    [(ngModel)]="department[i].checked" />{{dept.deptId}}
                                </label>
                            </fieldset>
                        </div>

                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Classification(s)*</label>
                    <div class="col-sm-8">
                        <div class="checkbox-container" *ngFor="let class of classification; let i=index">
                            <fieldset>
                                <label class="checkbox-inline">
                                    <input type="checkbox" name="class{{class.jobCodeId+i+1}}"
                                    [(ngModel)]="classification[i].checked" />{{class.jobCodeId}}
                                </label>
                            </fieldset>
                        </div>
                       
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" #closeCorrectModel
                    (click)="clear()">Cancel</button>
                <button type="button" class="btn btn-primary"
                    [disabled]="overtimeGroupForm.errors || overtimeGroupForm.invalid"
                    (click)="submitOvertimeGroupData()">Update </button>
            </div>
        </div>
    </div>
</div>