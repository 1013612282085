<div class="container-fluid app-box">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Views</li>
        <li class="breadcrumb-item brd-li brd-li-child">
          View All Shift Preferences
        </li>
      </ol>
    </nav>
    <div
      style="float: right; position: absolute; right: 276px"
      *ngIf="
        EligibleSPApplications !== null && EligibleSPApplications.length > 0
      "
    >
      <button
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        style="height: 34px; margin-left: 15px"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
    </div>
  </div>

  <div>
    <div class="row content-container mt-2 mb-2">
      <div class="pt-2 div-color">
        <div class="search-divider-div filtercss">
          <p class="search-divder-p"></p>
          <hr class="search-divider-hr" />
          <div class="jumbotron jumb-new jumb-newcontrols">
            <div class="row" display="float">
              <div class="col-md-1 mr-5">
                <label>Effective Date</label>
                <p style="color: white">
                  {{ effectiveDate | date: 'MM/dd/yyyy' }}
                </p>
              </div>
              <div class="col">
                <app-filter
                  [filterConfig]="filterConfig"
                  (filterValue)="getFilterValue($event)"
                >
                </app-filter>
              </div>
              <div class="col-md-1 mr-5">
                <a
                  class="btn btn-lg search-button"
                  (click)="searchShiftPreferenceApplications()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>

          <!-- Button Start-->
          <!-- <div style="margin-top: 13px;margin-bottom: 2px;" *ngIf="EligibleSPApplications !==null && EligibleSPApplications   .length > 0">
    <button style="margin-right: 0.5rem;border-color: #003AA0;" class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0" (click)="exportElmToExcel()">Export to Excel</button>
</div> -->
          <!-- Button End-->
        </div>
        <div class="container-divider-div"></div>
        <div>
          <div>
            <!-- this is export to excel format -->
            <table
              class="table table-striped"
              *ngIf="
                !isDataExportableView &&
                EligibleSPApplications !== null &&
                EligibleSPApplications.length > 0
              "
              #ShiftPreferenceViewExportTable
            >
              <thead class="table-header position">
                <tr>
                  <!-- <th scope="col" style="width: 150px;">Name</th>
                                    <th scope="col">Emp <br> Id</th>
                                    <th scope="col">Curr <br> Dept</th>
                                    <th scope="col">Curr <br> Shift</th>
                                    <th scope="col">Plant <br> Sen <br>Date</th>
                                    <th scope="col">GM <br>Sen <br>Date</th>
                                    <th scope="col">Plant <br>Skill <br>Date</th>
                                    <th scope="col">GM <br>Skill <br>Date</th>
                                    <th scope="col">Emp Sign Date</th>
                                    <th scope="col">Area</th>
                                    <th scope="col" >Shift Pref Group</th>
                                    <th scope="col">Job <br> Class</th>
                                    <th scope="col">Applied Shift</th>
                                    <th scope="col">Effective Date</th> -->
                  <th scope="col" style="width: 100px">
                    <span (click)="onSortClick($event, 'lastName', 'string')"
                      >Name
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'lastName'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'lastName'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'lastName'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'employeeId', 'string')"
                      >Emp Id
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'employeeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'employeeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'employeeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'deptId', 'string')"
                      >Curr Dept
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'deptId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'deptId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'deptId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick(
                          $event,
                          'employeeShiftDescription',
                          'string'
                        )
                      "
                      >Curr Shift
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'employeeShiftDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'employeeShiftDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'employeeShiftDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'plantSeniorityDateSort', 'string')
                      "
                      >Plant Sen Date
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'plantSeniorityDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'seniorityDateSort', 'string')
                      "
                      >GM Sen Date
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'seniorityDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'seniorityDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'seniorityDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'plantSkillDateSort', 'string')
                      "
                      >Plant Skill Date
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'plantSkillDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'plantSkillDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'plantSkillDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'skillDateSort', 'string')"
                      >GM Skill Date
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'skillDateSort'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'skillDateSort'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'skillDateSort'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'empSignDateSort', 'string')"
                      >Emp Sign Date
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'empSignDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'areaDescription', 'string')"
                      >Area
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'areaDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'areaDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'areaDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'plantGroupDescription', 'string')
                      "
                      >Shift Pref Group
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'plantGroupDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'plantGroupDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'plantGroupDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'specialSP', 'string')"
                      >Type
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'jobCodeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'jobCodeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'jobCodeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'jobCodeId', 'string')"
                      >Class
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'jobCodeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'jobCodeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'jobCodeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'shiftDescription', 'string')
                      "
                      >Applied Shift
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'shiftDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'EffectiveDateSort', 'string')
                      "
                      >Effective Date
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'EffectiveDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'EffectiveDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'EffectiveDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="table-container"
                  *ngFor="let data of EligibleSPApplications; let i = index"
                >
                  <td class="left-tab-value" style="width: 100px">
                    {{ data.lastName ? data.lastName : 'N/A' }},
                    {{ data.firstName ? data.firstName : 'N/A' }}&nbsp;{{
                      data.middleName ? data.middleName : ''
                    }}
                  </td>
                  <td class="tab-value">{{ data.employeeId }}</td>
                  <td class="tab-value">{{ data.deptId }}</td>
                  <td class="tab-value">
                    {{
                      data.employeeShiftDescription
                        ? data.employeeShiftDescription
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.plantSeniorityDate
                        ? (data.plantSeniorityDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.seniorityDate
                        ? (data.seniorityDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.plantSkillDate
                        ? (data.plantSkillDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.skillDate
                        ? (data.skillDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.empSignDate
                        ? (data.empSignDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="left-tab-value">
                    {{ data.areaDescription ? data.areaDescription : '-' }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.plantGroupDescription
                        ? data.plantGroupDescription
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{ data.specialSP === '1' ? 'Special' : 'Regular' }}
                  </td>
                  <td class="tab-value">
                    {{ data.jobCodeId ? data.jobCodeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ data.shiftDescription ? data.shiftDescription : '-' }}
                  </td>
                  <td class="tab-value custon-padding">
                    {{
                      data.EffectiveDate
                        ? (data.EffectiveDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- this is main table -->
            <table
              class="table table-striped"
              *ngIf="
                EligibleSPApplications !== null &&
                EligibleSPApplications.length > 0
              "
            >
              <thead class="table-header position">
                <tr>
                  <!-- <th scope="col" style="width: 150px;">Name</th>
                                <th scope="col">Emp <br> Id</th>
                                <th scope="col">Curr <br> Dept</th>
                                <th scope="col">Curr <br> Shift</th>
                                <th scope="col">Plant <br> Sen <br>Date</th>
                                <th scope="col">GM <br>Sen <br>Date</th>
                                <th scope="col">Plant <br>Skill <br>Date</th>
                                <th scope="col">GM <br>Skill <br>Date</th>
                                <th scope="col">Emp Sign Date</th>
                                <th scope="col">Area</th>
                                <th scope="col" >Shift Pref Group</th>
                                <th scope="col">Job <br> Class</th>
                                <th scope="col">Applied Shift</th>
                                <th scope="col">Effective Date</th> -->
                  <th scope="col" style="width: 100px">
                    <span (click)="onSortClick($event, 'lastName', 'string')"
                      >Name
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'lastName'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'lastName'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'lastName'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'employeeId', 'string')"
                      >Emp Id
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'employeeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'employeeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'employeeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'deptId', 'string')"
                      >Curr Dept
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'deptId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'deptId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'deptId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick(
                          $event,
                          'employeeShiftDescription',
                          'string'
                        )
                      "
                      >Curr Shift
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'employeeShiftDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'employeeShiftDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'employeeShiftDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'plantSeniorityDateSort', 'string')
                      "
                      >Plant Sen Date
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'plantSeniorityDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'seniorityDateSort', 'string')
                      "
                      >GM Sen Date
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'seniorityDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'seniorityDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'seniorityDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'plantSkillDateSort', 'string')
                      "
                      >Plant Skill Date
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'plantSkillDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'plantSkillDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'plantSkillDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'skillDateSort', 'string')"
                      >GM Skill Date
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'skillDateSort'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'skillDateSort'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'skillDateSort'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'empSignDateSort', 'string')"
                      >Emp Sign Date
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'empSignDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'areaDescription', 'string')"
                      >Area
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'areaDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'areaDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'areaDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'plantGroupDescription', 'string')
                      "
                      >Shift Pref Group
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'plantGroupDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'plantGroupDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'plantGroupDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'specialSP', 'string')"
                      >Type
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'jobCodeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'jobCodeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'jobCodeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'jobCodeId', 'string')"
                      >Class
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'jobCodeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'jobCodeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'jobCodeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'shiftDescription', 'string')
                      "
                      >Applied Shift
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'shiftDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'EffectiveDateSort', 'string')
                      "
                      >Effective Date
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'EffectiveDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'EffectiveDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'EffectiveDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="table-container"
                  *ngFor="
                    let data of EligibleSPApplications
                      | paginate
                        : {
                            id: 'joblist',
                            itemsPerPage: 20,
                            currentPage: Applicationpage,
                            totalItems: TotalApplicationCount
                          };
                    let i = index
                  "
                >
                  <td class="left-tab-value" style="width: 100px">
                    {{ data.lastName ? data.lastName : 'N/A' }},
                    {{ data.firstName ? data.firstName : 'N/A' }}&nbsp;{{
                      data.middleName ? data.middleName : ''
                    }}
                  </td>
                  <td class="tab-value">{{ data.employeeId }}</td>
                  <td class="tab-value">{{ data.deptId }}</td>
                  <td class="tab-value">
                    {{
                      data.employeeShiftDescription
                        ? data.employeeShiftDescription
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.plantSeniorityDate
                        ? (data.plantSeniorityDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.seniorityDate
                        ? (data.seniorityDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.plantSkillDate
                        ? (data.plantSkillDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.skillDate
                        ? (data.skillDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.empSignDate
                        ? (data.empSignDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="left-tab-value">
                    {{ data.areaDescription ? data.areaDescription : '-' }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.plantGroupDescription
                        ? data.plantGroupDescription
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{ data.specialSP === '1' ? 'Special' : 'Regular' }}
                  </td>
                  <td class="tab-value">
                    {{ data.jobCodeId ? data.jobCodeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ data.shiftDescription ? data.shiftDescription : '-' }}
                  </td>
                  <td class="tab-value custon-padding">
                    {{
                      data.EffectiveDate
                        ? (data.EffectiveDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="single-wrap d-flex justify-content-center"
              *ngIf="
                EligibleSPApplications !== null &&
                EligibleSPApplications.length > 0
              "
            >
              <nav aria-label="Page navigation example">
                <pagination-controls
                  class="my-pagination"
                  id="joblist"
                  (pageChange)="Applicationpage = $event"
                  previousLabel=""
                  nextLabel=""
                >
                </pagination-controls>
              </nav>
            </div>
            <div *ngIf="isApplicationDataEmpty">
              <p *ngIf="isApplicationDataEmpty">No existing records Found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
