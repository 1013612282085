<div
  class="modal fade"
  id="printWeeklyReport"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteApplicationModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-dialog-centered modal-dialog"
    style="max-width: 80%"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteApplicationModalLabel">
          Overtime Weekly Report
        </h5>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>

      <div class="form-group col-md-12" style="padding-top: 5px">
        <button
          type="button"
          class="btn btn-primary save-btn alignment"
          (click)="DownloadWeeklyReport()"
        >
          Print
        </button>
      </div>

      <div id="content" #content style="height: auto; width: auto">
        <div class="rpttitle">
          <!-- <hr class="hrrpt"> -->

          <div>
            <div style="float: left">
              Date:&nbsp;
              {{ todayDate | date: 'MM/d/yyyy' }}
              <br />
              Time: &nbsp;{{ todayDate | date: 'shortTime' }}
            </div>
            <div
              style="
                float: right;
                vertical-align: middle;
                margin: auto;
                width: 65%;
              "
            >
              <div *ngIf="showFilter === true" style="font-weight: 700">
                Average Accumulated Hours for Overtime Group
                {{ overtimeGroupName }}: {{ AverageAccHours | number: '1.2-2' }}
              </div>
              <div>
                (Note: All accumulated hours are based on an as-of date of
                {{
                  asOfDate | date: 'MMM d,
                                y'
                }})
              </div>
            </div>
          </div>

          <!-- <hr class="hrrpt">  -->
        </div>

        <div style="padding: 5px">
          <table style="width: 100%; padding: 5px">
            <thead>
              <tr class="overtime-preview-table-tr">
                <th scope="col" class="overtime-preview-table-th">No.</th>
                <th scope="col" class="overtime-preview-table-th">
                  <span>Name</span>
                </th>
                <th scope="col" class="overtime-preview-table-th">
                  <span>Emp Id</span>
                </th>
                <th scope="col" class="overtime-preview-table-th">
                  <span>Dept</span>
                </th>
                <!-- <th scope="col" class="overtime-preview-table-th"> <span>
                                        Seniority Date</span>  </th> -->
                <th
                  scope="col"
                  class="overtime-preview-table-th"
                  *ngIf="ClassificationType === 'P'"
                >
                  <span>Seniority Date</span>
                </th>
                <th
                  th
                  scope="col"
                  class="overtime-preview-table-th"
                  *ngIf="ClassificationType === 'S'"
                >
                  <span>Skill Date</span>
                </th>
                <th scope="col" class="overtime-preview-table-th">
                  <span> Acc<br />Hrs</span>
                </th>
                <th scope="col" class="overtime-preview-table-th">
                  <span>
                    {{ monday | date: 'EEE' }}<br />{{
                      monday | date: 'MM/dd'
                    }}</span
                  >
                </th>
                <th scope="col" class="overtime-preview-table-th">
                  <span>
                    {{ tuesday | date: 'EEE' }}<br />{{
                      tuesday | date: 'MM/dd'
                    }}</span
                  >
                </th>
                <th scope="col" class="overtime-preview-table-th">
                  <span>
                    {{ wednesday | date: 'EEE' }}<br />{{
                      wednesday | date: 'MM/dd'
                    }}</span
                  >
                </th>
                <th scope="col" class="overtime-preview-table-th">
                  <span>
                    {{ thursday | date: 'EEE' }}<br />{{
                      thursday | date: 'MM/dd'
                    }}</span
                  >
                </th>
                <th scope="col" class="overtime-preview-table-th">
                  <span>
                    {{ friday | date: 'EEE' }}<br />{{
                      friday | date: 'MM/dd'
                    }}</span
                  >
                </th>
                <th scope="col" class="overtime-preview-table-th">
                  <span>
                    {{ saturday | date: 'EEE' }}<br />{{
                      saturday | date: 'MM/dd'
                    }}</span
                  >
                </th>
                <th scope="col" class="overtime-preview-table-th">
                  <span>
                    {{ sunday | date: 'EEE' }}<br />{{
                      sunday | date: 'MM/dd'
                    }}</span
                  >
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let val of filterItemsBySpread(true); let i = index"
                class="overtime-preview-table-tr"
              >
                <td class="tab-value">
                  <span class="cellcnt">{{ i + 1 }}</span>
                </td>
                <td class="tab-value">
                  {{ val.lasT_NAME ? val.lasT_NAME : 'N/A' }},&nbsp;{{
                    val.firsT_NAME ? val.firsT_NAME : 'N/A'
                  }}&nbsp;{{ val.middle ? val.middle : '' }}
                </td>
                <td class="tab-value">
                  {{ val.employeeid ? val.employeeid : 'N/A' }}
                </td>
                <td class="tab-value">{{ val.dept ? val.dept : 'N/A' }}</td>
                <td class="tab-value">
                  {{ val.emP_START_DATE | date: 'MM/dd/yyyy' }}
                </td>
                <td class="tab-value">
                  <span>{{ val.acchours | number: '1.2-2' }}</span>
                </td>
                <td class="tab-value">{{ val.monday ? val.monday : '' }}</td>
                <td class="tab-value">{{ val.tuesday ? val.tuesday : '' }}</td>
                <td class="tab-value">
                  {{ val.wednesday ? val.wednesday : '' }}
                </td>
                <td class="tab-value">
                  {{ val.thursday ? val.thursday : '' }}
                </td>
                <td class="tab-value">{{ val.friday ? val.friday : '' }}</td>
                <td class="tab-value">
                  {{ val.saturday ? val.saturday : '' }}
                </td>
                <td class="tab-value">{{ val.sunday ? val.sunday : '' }}</td>
              </tr>
              <tr></tr>
              <tr *ngIf="showFilter === true">
                <td class="spreadrow" colspan="13" style="text-align: center">
                  <span>Everyone below this line is outside spread</span>
                </td>
              </tr>
              <tr
                *ngFor="let val of filterItemsBySpread(false); let i = index"
                class="overtime-preview-table-tr"
              >
                <td class="tab-value">
                  <span class="cellcnt">{{ i + 1 }}</span>
                </td>
                <td class="tab-value">
                  {{ val.lasT_NAME ? val.lasT_NAME : 'N/A' }},&nbsp;{{
                    val.firsT_NAME ? val.firsT_NAME : 'N/A'
                  }}&nbsp;{{ val.middle ? val.middle : '' }}
                </td>
                <td class="tab-value">
                  {{ val.employeeid ? val.employeeid : 'N/A' }}
                </td>
                <td class="tab-value">{{ val.dept ? val.dept : 'N/A' }}</td>
                <td class="tab-value">
                  {{ val.emP_START_DATE | date: 'MM/dd/yyyy' }}
                </td>
                <td class="tab-value">
                  <span>{{ val.acchours | number: '1.2-2' }}</span>
                </td>
                <td class="tab-value">{{ val.monday ? val.monday : '' }}</td>
                <td class="tab-value">{{ val.tuesday ? val.tuesday : '' }}</td>
                <td class="tab-value">
                  {{ val.wednesday ? val.wednesday : '' }}
                </td>
                <td class="tab-value">
                  {{ val.thursday ? val.thursday : '' }}
                </td>
                <td class="tab-value">{{ val.friday ? val.friday : '' }}</td>
                <td class="tab-value">
                  {{ val.saturday ? val.saturday : '' }}
                </td>
                <td class="tab-value">{{ val.sunday ? val.sunday : '' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="center">
          <table style="width: 100%; padding: 10px">
            <tr class="overtime-preview-table-tr">
              <th
                colspan="6"
                class="spreadrow"
                style="text-align: center"
                scope="col"
              >
                Search Criteria
              </th>
            </tr>
            <tr class="overtime-preview-table-tr">
              <th scope="col" style="text-align: right">Search Type:</th>
              <th scope="col">{{ this.postModel?.SEARCHTYPE }}</th>
              <th scope="col" style="text-align: right">As of Date:</th>
              <th scope="col">{{ this.postModel?.ASOFDATE }}</th>
              <th scope="col" style="text-align: right">Overtime Type:</th>
              <th scope="col">
                {{
                  this.postModel?.OVERTIMETYPEID
                    ? this.postModel?.OVERTIMETYPEID
                    : 'All'
                }}
              </th>
            </tr>
            <tr class="overtime-preview-table-tr">
              <th scope="col" style="text-align: right">Department:</th>
              <th scope="col">{{ this.postModel?.DEPTID }}</th>
              <th scope="col" style="text-align: right">Clasification:</th>
              <th scope="col">{{ this.postModel?.JOBCODEID }}</th>
              <th scope="col" style="text-align: right">Shift:</th>
              <th scope="col">{{ this.postModel?.SHIFTID }}</th>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
