import { Component } from '@angular/core';

@Component({
  selector: 'app-view-all150-applications',
  templateUrl: './view-all150-applications.component.html',
  styleUrls: ['./view-all150-applications.component.css'],
})
export class ViewAll150ApplicationsComponent {
  isAll150App: boolean = true;
  moduleName: string = 'VIEWS';
  viewName: string = 'VIEWALL150APPLICATIONS';
  constructor() {}
}
