import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './Components/home/home.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { DatePipe } from '@angular/common';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
} from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: environment.redirectUrl,
      postLogoutRedirectUri: null,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'user.read',
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToolAndDiePlantComponent } from './Components/Applications/ToolAndDie/tool-and-die-plant/tool-and-die-plant.component';
import { DeleteComponent } from './Components/Applications/ToolAndDie/delete/delete.component';
import { CancelComponent } from './Components/Applications/ToolAndDie/cancel/cancel.component';
import { AddComponent } from './Components/Applications/ToolAndDie/add/add.component';
import { EditComponent } from './Components/Applications/ToolAndDie/edit/edit.component';
import { FilterComponent } from './Components/shared/filter/filter.component';
import { MSALserviceService } from './Services/MSALservice/msalservice.service';
//import { FacilitiesServiceTransferPlantComponent } from './Components/Applications/FacilitiesServiceTransfer/facilities-service-transfer-plant/facilities-service-transfer-plant.component';
import { AddFacilitesServiceComponent } from './Components/Applications/FacilitiesServiceTransfer/add-facilites-service/add-facilites-service.component';
import { EditFacilitesServiceComponent } from './Components/Applications/FacilitiesServiceTransfer/edit-facilites-service/edit-facilites-service.component';
import { DeleteFacilitesServiceComponent } from './Components/Applications/FacilitiesServiceTransfer/delete-facilites-service/delete-facilites-service.component';
import { CancelFacilitesServiceComponent } from './Components/Applications/FacilitiesServiceTransfer/cancel-facilites-service/cancel-facilites-service.component';
import { FacilitiesServiceTransferPlantComponent } from './Components/Applications/FacilitiesServiceTransfer/facilities-service-transfer-plant/facilities-service-transfer-plant.component';
import { TransferAgreementComponent } from './Components/Applications/TransferAgreement/transfer-agreement/transfer-agreement.component';
import { AddTransferAgreementComponent } from './Components/Applications/TransferAgreement/add-transfer-agreement/add-transfer-agreement.component';
import { CancelTransferAgreementComponent } from './Components/Applications/TransferAgreement/cancel-transfer-agreement/cancel-transfer-agreement.component';
import { DeleteTransferAgreementComponent } from './Components/Applications/TransferAgreement/delete-transfer-agreement/delete-transfer-agreement.component';
import { EditTransferAgreementComponent } from './Components/Applications/TransferAgreement/edit-transfer-agreement/edit-transfer-agreement.component';
import { OvertimeComponent } from './Components/Overtime/overtime/overtime.component';
import { DropDownComponent } from './Components/SharedForm/generic/drop-down.component';
import { InputBoxComponent } from './Components/SharedForm/generic/input.component';
import { CommonSearchComponent } from './Components/SharedForm/commonsearch/common-search/common-search.component';
import { DateComponent } from './Components/SharedForm/generic/date.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AddSkilledToSkilledComponent } from './Components/Applications/SkilledToSkilled/add-skilled-to-skilled/add-skilled-to-skilled.component';
import { EditSkilledToSkilledComponent } from './Components/Applications/SkilledToSkilled/edit-skilled-to-skilled/edit-skilled-to-skilled.component';
import { CancelSkilledToSkilledComponent } from './Components/Applications/SkilledToSkilled/cancel-skilled-to-skilled/cancel-skilled-to-skilled.component';
import { DeleteSkilledToSkilledComponent } from './Components/Applications/SkilledToSkilled/delete-skilled-to-skilled/delete-skilled-to-skilled.component';
import { SkilledToSkilledTransferComponent } from './Components/Applications/SkilledToSkilled/skilled-to-skilled-transfer/skilled-to-skilled-transfer.component';
import { ProductionToSkilledComponent } from './Components/Applications/ProductionToSkilled/production-to-skilled/production-to-skilled/production-to-skilled.component';
import { AddProductionToSkilledComponent } from './Components/Applications/ProductionToSkilled/add-production-to-skilled/add-production-to-skilled/add-production-to-skilled.component';
import { CancelProductionToSkilledComponent } from './Components/Applications/ProductionToSkilled/cancel-production-to-skilled/cancel-production-to-skilled/cancel-production-to-skilled.component';
import { DeleteProductionToSkilledComponent } from './Components/Applications/ProductionToSkilled/delete-production-to-skilled/delete-production-to-skilled/delete-production-to-skilled.component';
import { EditProductionToSkilledComponent } from './Components/Applications/ProductionToSkilled/edit-production-to-skilled/edit-production-to-skilled/edit-production-to-skilled.component';
import { AddShiftPreferenceComponent } from './Components/Applications/ShiftPreference/add-shift-preference/add-shift-preference.component';
import { EditShiftPreferenceComponent } from './Components/Applications/ShiftPreference/edit-shift-preference/edit-shift-preference.component';
import { DeleteShiftPreferenceComponent } from './Components/Applications/ShiftPreference/delete-shift-preference/delete-shift-preference.component';
import { CancelShiftPreferenceComponent } from './Components/Applications/ShiftPreference/cancel-shift-preference/cancel-shift-preference.component';
import { ShiftPreferenceComponent } from './Components/Applications/ShiftPreference/shift-preference/shift-preference.component';
import { OvertimeDetailComponent } from './Components/Overtime/overtimedetail/overtimedetail.component';
import { AddOvertimeMaintenanceComponent } from './Components/Overtime/add-overtime-maintenance/add-overtime-maintenance.component';
import { EditOvertimeMaintenanceComponent } from './Components/Overtime/edit-overtime-maintenance/edit-overtime-maintenance.component';
import { AddParagraph19Component } from './Components/Applications/Paragraph19/add-paragraph19/add-paragraph19/add-paragraph19.component';
import { Paragraph19ApplicationComponent } from './Components/Applications/Paragraph19/paragraph19-application/paragraph19-application/paragraph19-application.component';
import { RecallRightsComponent } from './Components/Applications/RecallRights/recall-rights/recall-rights.component';
import { AddRecallRightsComponent } from './Components/Applications/RecallRights/add-recall-rights/add-recall-rights.component';
import { EditRecallRightsComponent } from './Components/Applications/RecallRights/edit-recall-rights/edit-recall-rights.component';
import { CancelRecallRightsComponent } from './Components/Applications/RecallRights/cancel-recall-rights/cancel-recall-rights.component';
import { DeleteRecallRightsComponent } from './Components/Applications/RecallRights/delete-recall-rights/delete-recall-rights.component';
import { DeleteParagraph19ApplicationComponent } from './Components/Applications/Paragraph19/delete-paragraph19/delete-paragraph19-application/delete-paragraph19-application.component';
import { EditParagraph19ApplicationComponent } from './Components/Applications/Paragraph19/edit-paragraph19/edit-paragraph19-application/edit-paragraph19-application.component';
import { CancelParagraph19ApplicationComponent } from './Components/Applications/Paragraph19/cancel-paragraph19/cancel-paragraph19-application/cancel-paragraph19-application.component';
import { MassUpdateOvertimeMaintenanceComponent } from './Components/Overtime/mass-update-overtime-maintenance/mass-update-overtime-maintenance.component';
import { DeleteOvertimedetailComponent } from './Components/Overtime/overtimedetail/delete-overtimedetail/delete-overtimedetail.component';
import { ArchiveOvertimeDetailComponent } from './Components/Overtime/overtimedetail/archive-overtime-detail/archive-overtime-detail.component';
import { OvertimeAllocationSummaryComponent } from './Components/Overtime/overtime-allocation-summary/overtime-allocation-summary.component';
import { AbsenceReasonDataComponent } from './Components/TableMaintenance/AbsenceReasonData/absence-reason-data.component';
import { ApplicationTypeComponent } from './Components/TableMaintenance/ApplicationType/application-type/application-type.component';
import { ClassificationDataComponent } from './Components/TableMaintenance/ClassificationData/classification-data/classification-data.component';
import { AddClassificationComponent } from './Components/TableMaintenance/ClassificationData/add-classification/add-classification.component';
import { EditClassificationComponent } from './Components/TableMaintenance/ClassificationData/edit-classification/edit-classification.component';
import { DeleteClassificationComponent } from './Components/TableMaintenance/ClassificationData/delete-classification/delete-classification.component';
import { ShowMedicalRestrictionsComponent } from './Components/Overtime/show-medical-restrictions/show-medical-restrictions.component';
import { PlantDataComponent } from './Components/TableMaintenance/PlantData/plant-data/plant-data.component';
import { AddPlantDataComponent } from './Components/TableMaintenance/PlantData/add-plant-data/add-plant-data.component';
import { EditPlantDataComponent } from './Components/TableMaintenance/PlantData/edit-plant-data/edit-plant-data.component';
import { DeletePlantDataComponent } from './Components/TableMaintenance/PlantData/delete-plant-data/delete-plant-data.component';
import { ReassignRepresentationGroupOvertimedetailComponent } from './Components/Overtime/overtimedetail/reassign-representation-group-overtimedetail/reassign-representation-group-overtimedetail.component';
import { ReassignOvertimegroupComponent } from './Components/Overtime/overtimedetail/reassign-overtimegroup/reassign-overtimegroup.component';
import { DemographicDataComponent } from './Components/TableMaintenance/DemographicData/demographic-data/demographic-data.component';
import { EditDemographicDataComponent } from './Components/TableMaintenance/DemographicData/edit-demographic-data/edit-demographic-data.component';
import { NIODataComponent } from './Components/TableMaintenance/NIOGroupsData/niodata/niodata.component';
import { EditNIODataComponent } from './Components/TableMaintenance/NIOGroupsData/edit-niodata/edit-niodata.component';
import { AddNIODataComponent } from './Components/TableMaintenance/NIOGroupsData/add-niodata/add-niodata.component';
import { DeleteNIODataComponent } from './Components/TableMaintenance/NIOGroupsData/delete-niodata/delete-niodata.component';
import { AddShiftDataComponent } from './Components/TableMaintenance/ShiftData/add-shift-data/add-shift-data.component';
import { UpdateShiftDataComponent } from './Components/TableMaintenance/ShiftData/update-shift-data/update-shift-data.component';
import { DeleteShiftDataComponent } from './Components/TableMaintenance/ShiftData/delete-shift-data/delete-shift-data.component';
import { ShiftDataComponent } from './Components/TableMaintenance/ShiftData/shift-data/shift-data.component';
import { RegionDataComponent } from './Components/TableMaintenance/RegionData/region-data/region-data.component';
import { AddRegionDataComponent } from './Components/TableMaintenance/RegionData/add-region-data/add-region-data.component';
import { EditRegionDataComponent } from './Components/TableMaintenance/RegionData/edit-region-data/edit-region-data.component';
import { DeleteRegionDataComponent } from './Components/TableMaintenance/RegionData/delete-region-data/delete-region-data.component';
import { DemographicViewComponent } from './Components/Views/DemographicsView/DemographicView/demographic-view.component';
import { OvertimeViewComponent } from './Components/Views/OvertimeView/overtime-view.component';
import { AdministerGroupsComponent } from './Components/Admin/AdministerGroups/administer-groups.component';
import { AdministerUsersComponent } from './Components/Admin/AdministerUsers/administer-users.component';
import { ShiftPreferenceDataComponent } from './Components/TableMaintenance/ShiftPreferenceData/shift-preference-data/shift-preference-data.component';
import { AddShiftPreferenceDataComponent } from './Components/TableMaintenance/ShiftPreferenceData/add-shift-preference-data/add-shift-preference-data.component';
import { UpdateShiftPreferenceDataComponent } from './Components/TableMaintenance/ShiftPreferenceData/update-shift-preference-data/update-shift-preference-data.component';
import { DeleteShiftPreferenceDataComponent } from './Components/TableMaintenance/ShiftPreferenceData/delete-shift-preference-data/delete-shift-preference-data.component';
import { DistrictDataComponent } from './Components/TableMaintenance/DistrictData/district-data/district-data.component';
import { AddDistrictDataComponent } from './Components/TableMaintenance/DistrictData/add-district-data/add-district-data.component';
import { EditOvertimeTypeDataComponent } from './Components/TableMaintenance/OvertimeTypeData/edit-overtime-type-data/edit-overtime-type-data.component';
import { DeleteDistrictDataComponent } from './Components/TableMaintenance/DistrictData/delete-district-data/delete-district-data.component';
import { UpdateDistrictDataComponent } from './Components/TableMaintenance/DistrictData/update-district-data/update-district-data.component';
import { OvertimeTypeDataComponent } from './Components/TableMaintenance/OvertimeTypeData/overtime-type-data/overtime-type-data.component';
import { AddOvertimeTypeDataComponent } from './Components/TableMaintenance/OvertimeTypeData/add-overtime-type-data/add-overtime-type-data.component';
import { DeleteOvertimeTypeDataComponent } from './Components/TableMaintenance/OvertimeTypeData/delete-overtime-type-data/delete-overtime-type-data.component';
import { ZoneDataComponent } from './Components/TableMaintenance/ZoneData/zone-data/zone-data.component';
import { AddZoneDataComponent } from './Components/TableMaintenance/ZoneData/add-zone-data/add-zone-data.component';
import { DeleteZoneDataComponent } from './Components/TableMaintenance/ZoneData/delete-zone-data/delete-zone-data.component';
import { UpdateZoneDataComponent } from './Components/TableMaintenance/ZoneData/update-zone-data/update-zone-data.component';
import { DebounceDirective } from './Services/Directive/debounce.directive';
import { OvertimeWeeklyReportComponent } from './Components/Overtime/overtime-weekly-report/overtime-weekly-report.component';
import { RepresentationGroupDataComponent } from './Components/TableMaintenance/RepresentationGroupData/representation-group-data/representation-group-data.component';
import { AddRepresentationGroupDataComponent } from './Components/TableMaintenance/RepresentationGroupData/add-representation-group-data/add-representation-group-data.component';
import { EditRepresentationGroupDataComponent } from './Components/TableMaintenance/RepresentationGroupData/edit-representation-group-data/edit-representation-group-data.component';
import { DeleteRepresentationGroupDataComponent } from './Components/TableMaintenance/RepresentationGroupData/delete-representation-group-data/delete-representation-group-data.component';
import { DepartmentDataComponent } from './Components/TableMaintenance/DepartmentData/department-data/department-data.component';
import { AddDepartmentDataComponent } from './Components/TableMaintenance/DepartmentData/add-department-data/add-department-data.component';
import { EditDepartmentDataComponent } from './Components/TableMaintenance/DepartmentData/edit-department-data/edit-department-data.component';
import { DeleteDepartmentDataComponent } from './Components/TableMaintenance/DepartmentData/delete-department-data/delete-department-data.component';
import { AddOvertimeGroupDataComponent } from './Components/TableMaintenance/OvertimeGroupData/add-overtime-group-data/add-overtime-group-data.component';
import { OvertimeGroupDataComponent } from './Components/TableMaintenance/OvertimeGroupData/overtime-group-data/overtime-group-data.component';
import { UpdateOvertimeGroupDataComponent } from './Components/TableMaintenance/OvertimeGroupData/update-overtime-group-data/update-overtime-group-data.component';
import { DeleteOvertimeGroupDataComponent } from './Components/TableMaintenance/OvertimeGroupData/delete-overtime-group-data/delete-overtime-group-data.component';
import { SeniorityViewComponent } from './Components/Views/SeniorityView/seniority-view/seniority-view.component';
import { MedicalRestrictionsComponent } from './Components/Views/MedicalRestrictionsView/medical-restrictions/medical-restrictions.component';
import { EmployeeMedicalRestrictionsComponent } from './Components/Views/MedicalRestrictionsView/employee-medical-restrictions/employee-medical-restrictions.component';
import { DemographicDetailedComponent } from './Components/Views/DemographicsView/demographic-detailed/demographic-detailed.component';
import { TransferAgreementViewComponent } from './Components/Views/TransferAgreementView/transfer-agreement-view/transfer-agreement-view.component';
import { OvertimeAllocationViewComponent } from './Components/Views/OvertimeAllocationSummary/overtime-allocation-view/overtime-allocation-view.component';
import { ShiftPreferenceViewComponent } from './Components/Views/ShiftPreferenceView/shift-preference-view/shift-preference-view.component';
import { ViewAll150ApplicationsComponent } from './Components/Views/view-all150-applications/view-all150-applications.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { ViewAllREcallRightsComponent } from './Components/Views/view-all-recall-rights/view-all-recall-rights.component';
import { OvertimeReasonDataComponent } from './Components/TableMaintenance/OvertimeReasonData/overtime-reason-data/overtime-reason-data.component';
import { AddOvertimeReasonDataComponent } from './Components/TableMaintenance/OvertimeReasonData/add-overtime-reason-data/add-overtime-reason-data.component';
import { EditOvertimeReasonDataComponent } from './Components/TableMaintenance/OvertimeReasonData/edit-overtime-reason-data/edit-overtime-reason-data.component';
import { DeleteOvertimeReasonDataComponent } from './Components/TableMaintenance/OvertimeReasonData/delete-overtime-reason-data/delete-overtime-reason-data.component';
import { TransferApplicationsComponent } from './Components/Views/transfer-applications/transfer-applications.component';
import { AuthGuard } from 'src/common/auth.guard';
import { ViewAllMyAppliactionsComponent } from './Components/Views/view-all-my-appliactions/view-all-my-appliactions.component';
import { CookieService } from 'ngx-cookie-service';
import { HolidayDataComponent } from './Components/TableMaintenance/HolidayData/holiday-data/holiday-data.component';
import { AddHolidayDataComponent } from './Components/TableMaintenance/HolidayData/add-holiday-data/add-holiday-data.component';
import { EditHolidayDataComponent } from './Components/TableMaintenance/HolidayData/edit-holiday-data/edit-holiday-data.component';
import { DeleteHolidayDataComponent } from './Components/TableMaintenance/HolidayData/delete-holiday-data/delete-holiday-data.component';
import { AnnouncementComponent } from './Components/Admin/Announcement/announcement/announcement.component';
import { AddAnnouncementComponent } from './Components/Admin/Announcement/add-announcement/add-announcement.component';
import { EditAnnouncementComponent } from './Components/Admin/Announcement/edit-announcement/edit-announcement.component';
import { DeleteAnnouncementComponent } from './Components/Admin/Announcement/delete-announcement/delete-announcement.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ViewRecallRightsComponent } from './Components/Views/view-recall-rights/view-recall-rights.component';
import { ViewTd150ApplicationsComponent } from './Components/Views/view-td150-applications/view-td150-applications.component';
import { DeleteDemographicDataComponent } from './Components/TableMaintenance/DemographicData/delete-demographic-data/delete-demographic-data.component';
import { DeleteOvertimeMaintenanceComponent } from './Components/Overtime/delete-overtime-maintenance/delete-overtime-maintenance.component';
import { OvertimeAnalyticsComponent } from './Components/ReportsAndCharts/Analytics/overtime-analytics/overtime-analytics.component';
import { OvertimeReassignGroupMovesComponent } from './Components/ReportsAndCharts/overtime-reassign-group-moves/overtime-reassign-group-moves.component';
import { ActionCodeChangeReportComponent } from './Components/ReportsAndCharts/action-code-change-report/action-code-change-report.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ToolAndDiePlantComponent,
    FacilitiesServiceTransferPlantComponent,
    DeleteComponent,
    CancelComponent,
    AddComponent,
    EditComponent,
    FilterComponent,
    AddFacilitesServiceComponent,
    EditFacilitesServiceComponent,
    DeleteFacilitesServiceComponent,
    CancelFacilitesServiceComponent,
    TransferAgreementComponent,
    AddTransferAgreementComponent,
    CancelTransferAgreementComponent,
    DeleteTransferAgreementComponent,
    EditTransferAgreementComponent,
    OvertimeComponent,
    OvertimeDetailComponent,
    DropDownComponent,
    InputBoxComponent,
    DateComponent,
    CommonSearchComponent,
    AddSkilledToSkilledComponent,
    EditSkilledToSkilledComponent,
    CancelSkilledToSkilledComponent,
    DeleteSkilledToSkilledComponent,
    SkilledToSkilledTransferComponent,
    ProductionToSkilledComponent,
    AddProductionToSkilledComponent,
    CancelProductionToSkilledComponent,
    DeleteProductionToSkilledComponent,
    EditProductionToSkilledComponent,
    AddShiftPreferenceComponent,
    EditShiftPreferenceComponent,
    DeleteShiftPreferenceComponent,
    CancelShiftPreferenceComponent,
    ShiftPreferenceComponent,
    AddOvertimeMaintenanceComponent,
    EditOvertimeMaintenanceComponent,
    RecallRightsComponent,
    AddRecallRightsComponent,
    EditRecallRightsComponent,
    CancelRecallRightsComponent,
    DeleteRecallRightsComponent,
    AddParagraph19Component,
    Paragraph19ApplicationComponent,
    DeleteParagraph19ApplicationComponent,
    EditParagraph19ApplicationComponent,
    CancelParagraph19ApplicationComponent,
    MassUpdateOvertimeMaintenanceComponent,
    DeleteOvertimedetailComponent,
    ArchiveOvertimeDetailComponent,
    OvertimeAllocationSummaryComponent,
    AbsenceReasonDataComponent,
    ApplicationTypeComponent,
    ClassificationDataComponent,
    AddClassificationComponent,
    EditClassificationComponent,
    DeleteClassificationComponent,
    ShowMedicalRestrictionsComponent,
    PlantDataComponent,
    AddPlantDataComponent,
    EditPlantDataComponent,
    DeletePlantDataComponent,
    ReassignRepresentationGroupOvertimedetailComponent,
    ShowMedicalRestrictionsComponent,
    ReassignOvertimegroupComponent,
    DemographicDataComponent,
    EditDemographicDataComponent,
    NIODataComponent,
    EditNIODataComponent,
    AddNIODataComponent,
    DeleteNIODataComponent,
    ShiftDataComponent,
    AddShiftDataComponent,
    UpdateShiftDataComponent,
    DeleteShiftDataComponent,
    DemographicViewComponent,
    OvertimeViewComponent,
    AdministerGroupsComponent,
    AdministerUsersComponent,
    RegionDataComponent,
    AddRegionDataComponent,
    EditRegionDataComponent,
    DeleteRegionDataComponent,
    ShiftPreferenceDataComponent,
    AddShiftPreferenceDataComponent,
    UpdateShiftPreferenceDataComponent,
    DeleteShiftPreferenceDataComponent,
    DistrictDataComponent,
    AddDistrictDataComponent,
    UpdateDistrictDataComponent,
    DeleteDistrictDataComponent,
    OvertimeTypeDataComponent,
    AddOvertimeTypeDataComponent,
    EditOvertimeTypeDataComponent,
    DeleteOvertimeTypeDataComponent,
    DebounceDirective,
    ZoneDataComponent,
    AddZoneDataComponent,
    DeleteZoneDataComponent,
    UpdateZoneDataComponent,
    OvertimeWeeklyReportComponent,
    RepresentationGroupDataComponent,
    AddRepresentationGroupDataComponent,
    EditRepresentationGroupDataComponent,
    DeleteRepresentationGroupDataComponent,
    DepartmentDataComponent,
    AddDepartmentDataComponent,
    EditDepartmentDataComponent,
    DeleteDepartmentDataComponent,
    DeleteRepresentationGroupDataComponent,
    OvertimeGroupDataComponent,
    AddOvertimeGroupDataComponent,
    UpdateOvertimeGroupDataComponent,
    DeleteOvertimeGroupDataComponent,
    SeniorityViewComponent,
    MedicalRestrictionsComponent,
    EmployeeMedicalRestrictionsComponent,
    DemographicDetailedComponent,
    TransferAgreementViewComponent,
    ShiftPreferenceViewComponent,
    DemographicDetailedComponent,
    OvertimeAllocationViewComponent,
    ViewAll150ApplicationsComponent,
    ViewAllREcallRightsComponent,
    TransferApplicationsComponent,
    OvertimeReasonDataComponent,
    AddOvertimeReasonDataComponent,
    EditOvertimeReasonDataComponent,
    DeleteOvertimeReasonDataComponent,
    ViewAllMyAppliactionsComponent,
    HolidayDataComponent,
    AddHolidayDataComponent,
    EditHolidayDataComponent,
    DeleteHolidayDataComponent,
    AnnouncementComponent,
    AddAnnouncementComponent,
    EditAnnouncementComponent,
    DeleteAnnouncementComponent,
    ViewRecallRightsComponent,
    ViewTd150ApplicationsComponent,
    DeleteDemographicDataComponent,
    DeleteOvertimeMaintenanceComponent,
    OvertimeAnalyticsComponent,
    OvertimeReassignGroupMovesComponent,
    ActionCodeChangeReportComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
    TableModule,
    ReactiveFormsModule,
    ScrollingModule,
    MatTabsModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    AngularEditorModule ,
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({

      animationType: ngxLoadingAnimationTypes.threeBounce,
      // backdropBackgroundColour: 'rgba(0,0,0,0.87)',
      backdropBorderRadius: '4px',
      primaryColour: '#FFFFFF',
      secondaryColour: '#FFFFFF',
      tertiaryColour: '#FFFFFF'
    }),
    RouterModule.forRoot([
      { path: 'Home', component: HomeComponent, data: { breadcrumb: 'Home' } },
      //TRANSFER APPLICATIONS ROUTES
      {
        path: 'tool-die-plant',
        component: ToolAndDiePlantComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'TOOLSANDDIEMAKER', moduleCode: 'APPLICATIONS' },
      },
      {
        path: 'facilities-service-transfer-plant',
        component: FacilitiesServiceTransferPlantComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'FACILITYSERVICE', moduleCode: 'APPLICATIONS' },
      },
      {
        path: 'production-to-skilled',
        component: ProductionToSkilledComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'PRODUCTIONTOSKILEED', moduleCode: 'APPLICATIONS' },
      },
      {
        path: 'shift-preference',
        component: ShiftPreferenceComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'SHIFTPREFERENCE', moduleCode: 'APPLICATIONS' },
      },
      {
        path: 'transfer-agreement',
        component: TransferAgreementComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'TRANSFERAGREEMENT', moduleCode: 'APPLICATIONS' },
      },
      {
        path: 'skilled-to-skilled',
        component: SkilledToSkilledTransferComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'SKILLEDTOSKILEED', moduleCode: 'APPLICATIONS' },
      },
      {
        path: 'recall-rights',
        component: RecallRightsComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'RECALLRIGHTS', moduleCode: 'APPLICATIONS' },
      },
      {
        path: 'paragraph19',
        component: Paragraph19ApplicationComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'PARAGRAPH19', moduleCode: 'APPLICATIONS' },
      },
      //OVERTIME ROUTES
      {
        path: 'Overtime',
        component: OvertimeComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'OVERTIME', moduleCode: 'OVERTIME' },
      },
      {
        path: 'OvertimeDetail',
        component: OvertimeDetailComponent
      },
      {
        path: 'overtimemaintenancereassignrepgroup',
        component: ReassignRepresentationGroupOvertimedetailComponent
      },
      {
        path: 'OvertimeAllocationSummary',
        component: OvertimeAllocationSummaryComponent
      },
      {
        path: 'ReassignOvertimegroup',
        component: ReassignOvertimegroupComponent
      },
      //TABLE MAINTENANCE ROUTES
      {
        path: 'ApplicationType',
        component: ApplicationTypeComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'APPLICATIONTYPESDATA', moduleCode: 'TABLEMAINTENANCE' },
      },
      {
        path: 'ClassificationData',
        component: ClassificationDataComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'CLASSIFICATIONDATA', moduleCode: 'TABLEMAINTENANCE' },
      },
      {
        path: 'PlantData',
        component: PlantDataComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'PLANTDATA', moduleCode: 'TABLEMAINTENANCE' },
      },
      {
        path: 'DemographicData',
        component: DemographicDataComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'DEMOGRAPHICDATA', moduleCode: 'TABLEMAINTENANCE' },
      },
      {
        path: 'NIOGroupsData',
        component: NIODataComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'NIOGROUPSDATA', moduleCode: 'TABLEMAINTENANCE' },
      },
      {
        path: 'ShiftData',
        component: ShiftDataComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'SHIFTDATA', moduleCode: 'TABLEMAINTENANCE' },
      },
      {
        path: 'ShiftPreferenceData',
        component: ShiftPreferenceDataComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'ShiftPreferenceData', moduleCode: 'TABLEMAINTENANCE' },
      },
      {
        path: 'DistrictData',
        component: DistrictDataComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'DISTRICTDATA', moduleCode: 'TABLEMAINTENANCE' },
      },
      {
        path: 'ZoneData',
        component: ZoneDataComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'ZONEDATA', moduleCode: 'TABLEMAINTENANCE' },
      },
      {
        path: 'OvertimeReasonData',
        component: OvertimeReasonDataComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'OVERTIMEREASONDATA', moduleCode: 'TABLEMAINTENANCE' },
      },
      {
        path: 'RegionsData',
        component: RegionDataComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'REGIONSDATA', moduleCode: 'TABLEMAINTENANCE' },
      },
      {
        path: 'OvertimeTypeData',
        component: OvertimeTypeDataComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'OVERTIMETYPEDATA', moduleCode: 'TABLEMAINTENANCE' },
      },
      {
        path: 'RepresentationGroupData',
        component: RepresentationGroupDataComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'REPRESENTATIONGROUPDATA', moduleCode: 'TABLEMAINTENANCE' },
      },
      {
        path: 'DepartmentData',
        component: DepartmentDataComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'DEPARTMENTDATA', moduleCode: 'TABLEMAINTENANCE' },
      },
      {
        path: 'OvertimeGroupData',
        component: OvertimeGroupDataComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'OVERTIMEGROUPDATA', moduleCode: 'TABLEMAINTENANCE' },
      },
      {
        path: 'HolidayData',
        component: HolidayDataComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'HOLIDAYDATA', moduleCode: 'TABLEMAINTENANCE' },
      },
      //VIEWS ROUTES
      {
        path: 'DemographicView',
        component: DemographicViewComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'DEMOGRAPHICVIEW', moduleCode: 'VIEWS' },
      },
      {
        path: 'DemographicDetailsView',
        component: DemographicDetailedComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'DEMOGRAPHICVIEW', moduleCode: 'VIEWS' },
      },
      {
        path: 'OvertimeView',
        component: OvertimeViewComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'OVERTIMEVIEW', moduleCode: 'VIEWS' },
      },
      {
        path: 'ViewSeniority',
        component: SeniorityViewComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'VIEWSENIORITY', moduleCode: 'VIEWS' },
      },
      {
        path: 'MedicalRestrictions',
        component: MedicalRestrictionsComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'MEDICALRESTRICTIONSVIEW', moduleCode: 'VIEWS' },
      },
      {
        path: 'TransferAgreementView',
        component: TransferAgreementViewComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'VIEWALLTRANSFERAGREEMENTS', moduleCode: 'VIEWS' },
      },
      {
        path: 'ShiftPreferenceView',
        component: ShiftPreferenceViewComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'VIEWALLSHIFTPREFERENCES', moduleCode: 'VIEWS' },
      },
      {
        path: 'ViewOvertimeAllocationSummary',
        component: OvertimeAllocationViewComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'OVERTIMEALLOCATIONSUMMARY', moduleCode: 'VIEWS' },
      },
      // {
      //   path: 'ViewAll150Applications',
      //   component: ViewAll150ApplicationsComponent,
      //   canActivate: [AuthGuard],
      //   data: { viewCode: 'VIEWALL150APPLICATIONS', moduleCode: 'VIEWS' },
      // },
      {
        path: 'ViewAll150Applications',
        component: ViewTd150ApplicationsComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'VIEWALL150APPLICATIONS', moduleCode: 'VIEWS' },
      },
      // {
      //   path: 'ViewAllRecallRights',
      //   component: ViewAllREcallRightsComponent,
      //   canActivate: [AuthGuard],
      //   data: { viewCode: 'ViewAllRecallRights', moduleCode: 'VIEWS' },
      // },
      {
        path: 'ViewAllRecallRights',
        component: ViewRecallRightsComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'ViewAllRecallRights', moduleCode: 'VIEWS' },
      },
      {
        path: 'TransferApplicationsView',
        component: TransferApplicationsComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'TRANSFERAPPLICATIONSVIEW', moduleCode: 'VIEWS' },
      },
      {
        path: 'ViewAllMyApplications',
        component: ViewAllMyAppliactionsComponent,
        data: { breadcrumb: 'ViewAllMyApplications' }
      },
      //ADMIN ROUTES
      {
        path: 'AdministerGroupsCommitee',
        component: AdministerGroupsComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'ADMINISTERGROUPS', moduleCode: 'ADMIN' },
      },
      {
        path: 'AdministerGroupscommitteeMedRes',
        component: AdministerUsersComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'AdministerUsers', moduleCode: 'ADMIN' },
      },
       {
        path: 'Announcements',
        component: AnnouncementComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'ADMINISTERGROUPS', moduleCode: 'ADMIN' },
      },
      {
        path: 'OvertimeAnalytics',
        component: OvertimeAnalyticsComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'OVERTIMEACCEPTEDREJECTED', moduleCode: 'REPORTS' },
      },
      {
        path: 'OvertimeReassignGroupMoves',
        component: OvertimeReassignGroupMovesComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'OVERTIMEREASSIGNHISTORY', moduleCode: 'OVERTIME' }, 
      },

      {
        path: 'ActionCodeChanges',
        component: ActionCodeChangeReportComponent,
        canActivate: [AuthGuard],
        data: { viewCode: 'ACTIONCODECHANGE', moduleCode: 'REPORTS' },
      },
    ]),
    MsalModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AppComponent,
    MSALserviceService,
    DatePipe,
    AuthGuard,
    CookieService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
