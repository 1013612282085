import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { Paragraph19Service } from 'src/app/Services/Applications/Paragraph19/paragraph19.service';
import { RecallRightsService } from 'src/app/Services/Applications/RecallRights/recall-rights.service';
import { SkilledToSkilledService } from 'src/app/Services/Applications/SkilledToSkilled/skilled-to-skilled.service';
import { DateService } from 'src/app/Services/Date/date.service';
import { RuleEngineService } from 'src/app/Services/Ruleengine/rule-engine.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { UserInfo } from 'src/models/userinfo';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-recall-rights',
  templateUrl: './add-recall-rights.component.html',
  styleUrls: ['./add-recall-rights.component.css'],
})
export class AddRecallRightsComponent implements OnInit, OnChanges, OnDestroy {
  employeeForm!: FormGroup;
  empSelected: any;
  selectedPlantId: any;
  employeeId: any;
  atid: any;
  currentDate = new Date();
  userEmployeeId: string = '';
  usersData: any;
  currentJobClassification: any;
  StatusCode: boolean = true;
  ruleMessages!: any[];
  errorlength: number = 0;
  UserRole: string = '';
  medicalRestrictionList: string[] = [];
  CalenderBlockedAfterTodayDate: string = '';
  prodClassifications: any[] = [
    {
      data: [],
      default: 'Select any classification',
    },
  ];
  skilledClassification: any[] = [
    {
      data: [],
      default: 'Select any classification',
    },
  ];

  @Input() loggedInUser: any;
  @Input() selectedEmp: any;
  @Input() isAdd: boolean = false;
  @Input() title: any;
  @Output() clean = new EventEmitter();
  @Output() isSucess = new EventEmitter();
  @Input() AppType: string = '';
  @ViewChild('closeAddModel') closeAddModal!: ElementRef;

  userInformation!: UserInfo;

  constructor(
    private fb: FormBuilder,
    private _user: UsergroupService,
    private rr: RecallRightsService,
    private p19: Paragraph19Service,
    private ss: SkilledToSkilledService,
    private _ruleEngine: RuleEngineService,
    private _commonApplicationService: CommonService,
    private _dateService: DateService
  ) {}

  ngOnDestroy(): void {
    localStorage.removeItem('SKILLED_CLASS_KEY');
    localStorage.removeItem('PROD_CLASS_KEY');
  }

  ngOnInit(): void {
    this.getClassifications();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createEmpForm();
    this.loadEmployeeData(changes);
  }

  createEmpForm() {
    this.employeeForm = this.fb.group({
      employeeName: [''],
      firstName: [''],
      lastName: [''],
      EmployeeId: [this.employeeId],
      AppStatusCode: ['OPEN'],
      currentClassification: [''],
      currentDepartment: [''],
      currentShift: [''],
      JobCodeId: [
        '',
        Validators.compose([
          Validators.required,
          // this.CheckLeaderClassification,
          // this.CheckApprenticeClassification,
        ]),
      ],
      Comments: [],
      AppType: ['RR'],
      EnteredBy: [this.loggedInUser.userName],
      appStatusDate: [this.currentDate],
      empSignDate: ['', [Validators.required]],
      StatusOverrideFlag: [null, []],
    });
    this.isAdd = false;
  }

  loadEmployeeData(changes: SimpleChanges) {
    this.empSelected =
      (changes.selectedEmp && changes.selectedEmp.currentValue) || '';
    if (this.empSelected === '') {
      this.empSelected =
        (changes.selectedEmp && changes.selectedEmp.previousValue) || '';
    }
    if (
      this.empSelected.jobCodeId.charAt(
        this.empSelected.jobCodeId.length - 1
      ) === 'J'
    ) {
      this.UserRole = 'SKILLED';
    } else if (
      this.empSelected.jobCodeId.charAt(
        this.empSelected.jobCodeId.length - 1
      ) === 'B'
    ) {
      this.UserRole = 'PRODUCTION';
    }
    this.getRuleEngineValidations(this.empSelected.employeeId);
    this.getMedicalRestrictions(this.empSelected.employeeId);
    this.currentJobClassification = this.empSelected.jobCodeId;
    this.CalenderBlockedAfterTodayDate =
      this._commonApplicationService.formatDate(this.currentDate);
    this.employeeForm
      .get('employeeName')
      ?.patchValue(
        this.empSelected.lastName +
          ', ' +
          this.empSelected.firstName +
          ' ' +
          this.empSelected.middleName
      );
    this.employeeForm.get('firstName')?.patchValue(this.empSelected.firstName);
    this.employeeForm.get('lastName')?.patchValue(this.empSelected.lastName);
    this.employeeId = this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.empSelected.employeeId);
    this.atid = this.employeeForm
      .get('ATID')
      ?.patchValue(this.empSelected.atid);
    this.employeeForm
      .get('currentClassification')
      ?.patchValue(
        this.empSelected.jobCodeId + '-' + this.empSelected.jobCodeDescription
      );
    this.employeeForm
      .get('currentDepartment')
      ?.patchValue(this.empSelected.deptId);
    this.employeeForm
      .get('currentShift')
      ?.patchValue(this.empSelected.shiftDescription);
    this.employeeForm
      .get('empSignDate')
      ?.patchValue(this._commonApplicationService.formatDate(new Date()));
  }

  getRuleEngineValidations(employeeId: string) {
    this._ruleEngine
      .addRecallRightRRApplicationRule(employeeId)
      .subscribe((data: any) => {
        this.StatusCode = data['status']; //for error: false
        this.ruleMessages = data['errors'];
        this.errorlength = data['errors'].length;
      });
  }

  getMedicalRestrictions(employeeId: string) {
    this._ruleEngine.getMedicalRestrictions(employeeId).subscribe((data) => {
      this.medicalRestrictionList = data;
    });
  }

  CheckLeaderClassification(control: FormControl) {
    let jobClassification = control.value;
    let checkChar = jobClassification.charAt(jobClassification.length - 1);
    if (checkChar === 'L') {
      return {
        leaderClassificationError:
          'The selected classification cannot be a leader classification',
      };
    }
    return null;
  }

  CheckApprenticeClassification(control: FormControl) {
    let jobClassification = control.value;
    let checkChar = jobClassification.charAt(jobClassification.length - 1);
    if (checkChar === 'A') {
      return {
        apprenticeClassificationError:
          'The selected classification cannot be a apprentice classification',
      };
    }
    return null;
  }

  CheckExpValidation(control: FormControl) {
    let isExpValidateChecked = control.value;
    if (isExpValidateChecked === false) {
      return {
        expValidateError: 'Validate Experience is required',
      };
    }
    return null;
  }

  // CheckEmpSignDate(control: FormControl) {
  //   let currentDate = new Date();
  //   let empSignDate = new Date(control.value);
  //   if (empSignDate >= currentDate) {
  //     return {
  //       empSignDateError:
  //         'Employee sign date cannot be greater than today date',
  //     };
  //   }
  //   return null;
  // }

  clear() {
    this.clean.emit('');
    this.closeAddModal.nativeElement.click();
    this.ResetEmployeeForm();
  }

  ResetEmployeeForm() {
    this.createEmpForm();
    this.ReLoadFormData();
  }

  ReLoadFormData() {
    this.getMedicalRestrictions(this.empSelected.employeeId);
    this.getRuleEngineValidations(this.empSelected.employeeId);
    this.currentJobClassification = this.empSelected.jobCodeId;
    this.CalenderBlockedAfterTodayDate =
      this._commonApplicationService.formatDate(this.currentDate);
    this.employeeForm
      .get('employeeName')
      ?.patchValue(
        this.empSelected.firstName + ' ' + this.empSelected.lastName
      );
    this.employeeForm.get('firstName')?.patchValue(this.empSelected.firstName);
    this.employeeForm.get('lastName')?.patchValue(this.empSelected.lastName);
    this.employeeId = this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.empSelected.employeeId);
    this.atid = this.employeeForm
      .get('ATID')
      ?.patchValue(this.empSelected.atid);
    this.employeeForm
      .get('currentClassification')
      ?.patchValue(
        this.empSelected.jobCodeId + '-' + this.empSelected.jobCodeDescription
      );
    this.employeeForm
      .get('currentDepartment')
      ?.patchValue(this.empSelected.deptId);
    this.employeeForm
      .get('currentShift')
      ?.patchValue(this.empSelected.shiftDescription);
    this.employeeForm
      .get('empSignDate')
      ?.patchValue(this._commonApplicationService.formatDate(new Date()));
    this.skilledClassification[this.skilledClassification.length - 1].data =
      JSON.parse(localStorage.getItem('SKILLED_CLASS_KEY') || '{}');
    this.prodClassifications[this.prodClassifications.length - 1].data =
      JSON.parse(localStorage.getItem('PROD_CLASS_KEY') || '{}');
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
    });
  }

  getClassifications() {
    this.p19.getClassificationList().subscribe((data) => {
      this.prodClassifications[this.prodClassifications.length - 1].data = data;
      localStorage.setItem('PROD_CLASS_KEY', JSON.stringify(data));
      localStorage.setItem('SKILLED_CLASS_KEY', JSON.stringify(data));
    });
  }

  //Method to add an application for an employee
  addEmployee() {
    let appliedClassification = this.employeeForm.get('JobCodeId')?.value;
    if (this.currentJobClassification === appliedClassification) {
      this.wrongClassificationError(); // if the employee's application job classification and current job classification is same.
    } else {
      // var dt = this.employeeForm.get('empSignDate')?.value;
      // var empDt = new Date(dt);
      // var empSignDtInUtc = this._dateService.GetUTCDate(empDt);
      // this.employeeForm.patchValue({ empSignDate: empSignDtInUtc });
      // console.log(this.employeeForm.value);

      this.rr.Add152Application(this.employeeForm.value).subscribe(
        (data: any) => {
          this.clear();
          this.isSucess.emit(true);
          this.successAlert();
        },
        (_error: HttpErrorResponse) => {
          //this.badRequestAlert();
          if (_error.status === 500) {
            this.badRequestAlert();
          } else {
            this.validationError();
          }
        }
      );
    }
  }

  RuleEngineError() {
    Swal.fire({
      icon: 'error',
      title: 'Cannot add application for this employee',
      text: 'Employee is not eligible.',
    });
  }

  successAlert() {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'The Application has been Added Successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  badRequestAlert() {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: 'An Error occured while processing.Please try again.',
    });
  }

  wrongClassificationError() {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: 'Cannot apply for current classification.',
    });
  }

  validationError() {
    Swal.fire({
      title: 'Cannot create application',
      text: 'User has an OPEN application and/or not eligible to create a new application',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
