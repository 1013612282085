import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { DistrictDataService } from 'src/app/Services/TableMaintenance/DistrictData/district-data.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';

@Component({
  selector: 'app-district-data',
  templateUrl: './district-data.component.html',
  styleUrls: ['./district-data.component.css'],
})
export class DistrictDataComponent implements OnInit {
  public loading = false;
  districtsData: any[] = [];
  modalId: string = '';
  showEdit: boolean = false;
  showAdd: boolean = false;
  showDelete: boolean = false;
  userInformation: any;
  userName: any;
  views: any[] = [];
  selectedDistrict: any;
  constructor(
    private _user: UsergroupService,
    private districtDataService: DistrictDataService,
    private _commonApplicationService: CommonService
  ) {}

  ngOnInit(): void {
    this.getDistrictData();
    this.getUserInfo();
  }

  getDistrictData() {
    this.loading = true;
    this.districtDataService.getDistrictData().subscribe((res) => {
      this.districtsData = res;
      this.loading = false;
    });
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      this.userName = this.userInformation.userName;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'TABLEMAINTENANCE',
        'DISTRICTDATA'
      );
    });
  }

  showAddModal() {
    this.showAdd = true;
    this.modalId = '#addEmployee';
  }

  showEditModal(districtid: any) {
    this.showEdit = true;
    this.modalId = '#editEmp';
    this.districtDataService
      .getSpecificDistrictData(districtid)
      .subscribe((res) => {
        this.selectedDistrict = res[0];
      });
  }

  showDeleteModal(districtid: any) {
    this.showDelete = true;
    this.modalId = '#deleteApplicationModal';
    this.districtDataService
      .getSpecificDistrictData(districtid)
      .subscribe((res) => {
        this.selectedDistrict = res[0];
      });
  }

  checkAdd(e: any) {
    this.getDistrictData();
  }

  checkEdit(e: any) {
    this.getDistrictData();
  }

  checkDelete(e: any) {
    this.getDistrictData();
  }

  @ViewChild('districtdataTablemaintenanceExportTable', {
    read: ElementRef,
    static: false,
  })
  districtdataTablemaintenanceExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.districtdataTablemaintenanceExportTable,
        `${ApplicationEnum.DISTRICTDATATABLEMAINTENANCE}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.districtsData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.districtsData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
