import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class RegionDataService {

  commonUtility = new CommonUtility();
  private readonly api_addRegionData = '/RegionDataAdd'
  private readonly api_deleteRegionData = '/RegionDataDelete?regionId='
  private readonly api_editRegionData = '/RegionDataUpdate'
  private readonly api_getRegionData = '/RegionDataGetRegion'

  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  addRegionData(regionData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_addRegionData,
      regionData,
      { headers }
    );
  }

  editRegionData(regionData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.tableMaintenanceURL}` + this.api_editRegionData,
      regionData,
      { headers }
    );
  }

  deleteRegionData(regionId: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.delete<any>(
      `${environment.tableMaintenanceURL}` + this.api_deleteRegionData + regionId,
      { headers }
    );
  }

  getRegionData(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getRegionData,
      { headers }
    );
  }
}
