import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class TransferAgreementService {

  atid = new Subject();
  applicationAtid = new Subject();
  EmployeeSignDate = new Subject();
  Appid = new Subject();

  /* Search apis */
  private readonly _SearchUser = '/SearchUsers';
  private readonly _GetSearchApplication = '/TransferAgreementTASearchApplication';
  private readonly _SearchApplicationByUser = '/SearchApplicationsByUser';

  /* Classification apis */
  private readonly _GetClassificationsTA = '/TransferAgreementTAGetClassifications';

  /* CRUD apis*/
  private readonly _TransferAgreementTAAdd = '/TransferAgreementTAAdd';
  private readonly _TransferAgreementTACancel = '/TransferAgreementTACancel';
  private readonly _TransferAgreementTACorrect = '/TransferAgreementTACorrect';
  private readonly _TransferAgreementTADelete = '/DeleteApplication/';


  /* Common for getting key and token*/
  commonUtility = new CommonUtility();
  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }


  /* Search Functions*/


  searchFilterApplication(formData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` +
      this._GetSearchApplication,
      formData,
      { headers }
    );
  }

  SearchApplicationByUser(searchKey: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.applicationsURL}` +
      this._SearchApplicationByUser +
      searchKey,
      { headers }
    );
  }

  SearchUser(searchKey: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.applicationsURL}` +
      this._SearchUser +
      searchKey,
      { headers }
    );
  }

  searchFilterApplicationUser(formData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` +
      this._SearchUser,
      formData,
      { headers }
    );
  }


  /* Get Functions */
  GetClassificationsTA(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(`${environment.applicationsURL}` +
      this._GetClassificationsTA,
      { headers });
  }

  /* CRUD Functions*/

  //Add TA
  TransferAgreementTAAdd(AddTABody: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` + this._TransferAgreementTAAdd,
      AddTABody,
      { headers });
  }

  //Cancel TA
  TransferAgreementTACancel(AddTABody: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.put<any>(
      `${environment.applicationsURL}` + this._TransferAgreementTACancel,
      AddTABody,
      { headers });
  }

  //Correct TA
  TransferAgreementTACorrect(AddTABody: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` +
      this._TransferAgreementTACorrect,
      AddTABody,
      { headers });
  }

  //Delete TA
  TransferAgreementTADelete(AppIdTA: number): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.delete<any>(`${environment.applicationsURL}` +
      this._TransferAgreementTADelete +
      AppIdTA,
      { headers });
  }



}

export class TransferAgreementVariables {

  ClosedType: Array<string> = [
    'CLOSED - CONTRACTUAL - T99',
    'CLOSED - PRIMARY - B709',
    'CLOSED - PRIMARY - B711',
    'CLOSED - PRIMARY - B718',
    'CLOSED - PRIMARY - B719',
    'CLOSED - PRIMARY - B721',
    'CLOSED - PRIMARY - B726',
    'CLOSED - PRIMARY - B730',
    'CLOSED - SECONDARY - B708',
    'CLOSED - SECONDARY - B717',
    'CLOSED - SECONDARY - B719',
    'CLOSED - SECONDARY - B718',
    'CLOSED - SECONDARY - B708',
    'CLOSED - SECONDARY - B725',
    'CLOSED - SECONDARY - B728',
    'CLOSED - SECONDARY - B726',
    'CLOSED - SECONDARY - B730',
    'CLOSED - RET OR DEC - B501',
    'CLOSED - RET OR DEC - B510'
  ];
}
