import { HttpErrorResponse } from '@angular/common/http';
import {
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PlantDataService } from 'src/app/Services/TableMaintenance/PlantData/plant-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-plant-data',
  templateUrl: './edit-plant-data.component.html',
  styleUrls: ['./edit-plant-data.component.css'],
})
export class EditPlantDataComponent implements OnInit {
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;
  @Input() userName: any;
  @Input() selectedPlant: any;
  @Output() isSucess = new EventEmitter();
  plantForm!: FormGroup;
  plantSelected: any;
  app150PlantGroupData: any[] = [
    {
      data: [],
      default: 'Select one',
    },
  ];
  plantGroupData: any[] = [
    {
      data: [],
      default: 'Select one',
    },
  ];
  Status: any[] = [
    {
      data: [
        {
          statusType: 'A',
          statusName: 'Active',
        },
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
      ],
      default: 'Select One',
    },
  ];
  constructor(private fb: FormBuilder, private pds: PlantDataService) {}

  ngOnInit(): void {
    this.getApp150PlantGroupData();
    this.getPlantGroupData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createForm();
    this.loadData(changes);
  }

  getApp150PlantGroupData() {
    this.pds.getApp150PlantGroupData().subscribe((res) => {
      this.app150PlantGroupData[this.app150PlantGroupData.length - 1].data =
        res;
    });
  }

  getPlantGroupData() {
    this.pds.getPlantGroupData().subscribe((res) => {
      this.plantGroupData[this.plantGroupData.length - 1].data = res;
    });
  }

  createForm() {
    this.plantForm = this.fb.group({
      PLANTID: ['', [Validators.required]],
      PLANTDESCRIPTION: ['', Validators.required],
      PLANTGROUPID: ['', [Validators.required]],
      APP150PLANTGROUPID: [''],
      SAP_PERSONNEL_AREA: [''],
      STATUS: ['', [Validators.required]],
      LASTUPDATEBY: [this.userName],
    });
  }

  loadData(changes: SimpleChanges) {
    this.plantSelected =
      (changes.selectedPlant && changes.selectedPlant.currentValue) || '';
    if (this.plantSelected === '') {
      this.plantSelected =
        (changes.selectedPlant && changes.selectedPlant.previousValue) || '';
    }
    this.plantForm.get('PLANTID')?.patchValue(this.plantSelected.plantid);
    this.plantForm
      .get('PLANTDESCRIPTION')
      ?.patchValue(this.plantSelected.plantdescription);
    this.plantForm
      .get('PLANTGROUPID')
      ?.patchValue(this.plantSelected.plantgroupid);
    this.plantForm
      .get('APP150PLANTGROUPID')
      ?.patchValue(this.plantSelected.apP150PLANTGROUPID);
    this.plantForm
      .get('SAP_PERSONNEL_AREA')
      ?.patchValue(this.plantSelected.saP_PERSONNEL_AREA);
    this.plantForm.get('STATUS')?.patchValue(this.plantSelected.status);
  }

  submitPlant() {
    this.pds.editPlantData(this.plantForm.value).subscribe(
      () => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 500) {
          this.badRequestAlert(_error.error);
        } else {
          this.validationError(_error.error);
        }
      }
    );
  }

  clear() {
    this.closeModal.nativeElement.click();
    this.createForm();
    this.loadCancelledData();
  }

  loadCancelledData() {
    this.plantForm.get('PLANTID')?.patchValue(this.plantSelected.plantid);
    this.plantForm
      .get('PLANTDESCRIPTION')
      ?.patchValue(this.plantSelected.plantdescription);
    this.plantForm
      .get('PLANTGROUPID')
      ?.patchValue(this.plantSelected.plantgroupid);
    this.plantForm
      .get('APP150PLANTGROUPID')
      ?.patchValue(this.plantSelected.apP150PLANTGROUPID);
    this.plantForm
      .get('SAP_PERSONNEL_AREA')
      ?.patchValue(this.plantSelected.saP_PERSONNEL_AREA);
    this.plantForm.get('STATUS')?.patchValue(this.plantSelected.status);
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Record has been updated successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
