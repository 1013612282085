<div class="modal fade" id="AddAnnouncement" tabindex="-1" role="dialog" aria-labelledby="AddAnnouncementLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content modal-size" style=" width: 945px; right:150px;top:100px;height:700px">
            <div class="modal-header">
                <h5 class="modal-title" id="CorrectAnnouncementLabel">Add Announcement</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="AnnouncementForm">
                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Title *:</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="TITLE" class="form-control">
                            <div
                                *ngIf="AnnouncementForm.controls['TITLE'].invalid && AnnouncementForm.controls['TITLE'].touched">
                                <small class="text-danger" *ngIf="AnnouncementForm.controls['TITLE'].errors?.required">
                                    Must enter Title
                                </small>
                            </div>
                        </div>
                    </div>


                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Text *</label>
                        <div class="col-sm-8">
                            <angular-editor formControlName="TEXT"  [config]="config"></angular-editor>
                            <div
                                *ngIf="AnnouncementForm.controls['TEXT'].invalid && AnnouncementForm.controls['TEXT'].touched">
                                <small class="text-danger" *ngIf="AnnouncementForm.controls['TEXT'].errors?.required">
                                    Must enter Text
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Start Date*</label>
                        <div class="col-sm-8">
                            <div >
                                <input formControlName="STARTTIME"  type="datetime-local" id="datmax"
                                    name="datmax" class="form-control" />
                            </div>
                            <div *ngIf="
                            AnnouncementForm.controls['STARTTIME'].invalid &&
                            AnnouncementForm.controls['STARTTIME'].touched
                                ">
                                <small class="text-danger"
                                    *ngIf="AnnouncementForm.controls['STARTTIME'].errors?.required">
                                    Start Time is required.
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">End Date*</label>
                        <div class="col-sm-8">
                            <div >
                                <input formControlName="ENDTIME"  type="datetime-local" id="datmax"
                                    name="datmax" class="form-control" />
                            </div>
                            <div *ngIf="
                            AnnouncementForm.controls['ENDTIME'].invalid &&
                            AnnouncementForm.controls['ENDTIME'].touched
                                ">
                                <small class="text-danger"
                                    *ngIf="AnnouncementForm.controls['ENDTIME'].errors?.required">
                                    End Time is required.
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Status*</label>
                        <div class="col-sm-8">
                            <select formControlName="STATUS" class="form-control">
                                <option selected value="A">Active</option>
                                <option value="I">Inactive</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Groups*</label>
                        <div class="col-sm-8">
                            <div class="checkbox-container" *ngFor="let group of groupsList; let i=index">
                                <fieldset>
                                    <label class="checkbox-inline">
                                        <input  (change)="updateGroupPermission(group.groupid,group.allow)" type="checkbox" [checked]="group.allow"
                                       /> {{group.groupname}}
                                    </label>
                                </fieldset>
                            </div>

                        </div>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary popupbuttonscancel" #closeAddModel data-dismiss="modal"
                    #closeCorrectModel (click)="clear()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]="AnnouncementForm.invalid" (click)="AddAnnouncement()">Add</button>
            </div>
        </div>
    </div>
</div>
