import { HttpErrorResponse } from '@angular/common/http';
import {
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { ToolAndDiePlantService } from 'src/app/Services/Applications/ToolAndDie/tool-and-die-plant.service';
import { DemographicDataService } from 'src/app/Services/TableMaintenance/DemographicData/demographic-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-demographic-data',
  templateUrl: './edit-demographic-data.component.html',
  styleUrls: ['./edit-demographic-data.component.css'],
})
export class EditDemographicDataComponent implements OnInit, OnChanges {
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;
  @Input() selectedDemographic: any;
  @Output() isSucess = new EventEmitter();
  demographicSelected: any;
  demographicForm!: FormGroup;
  @Input() userName: any;
  eligibilityData: any;
  Classifications: any[] = [
    {
      data: [],
      default: 'Select Classification',
    },
  ];
  Departments: any = [
    {
      data: [],
      default: 'Select Department',
    },
  ];
  FMS: any[] = [
    {
      FMSTraining: '',
    },
    {
      FMSTraining: 'Completed',
    },
    {
      FMSTraining: 'Equivalent',
    },
  ];
  shifts: any[] = [];
  plants: any[] = [
    {
      data: [],
      default: 'Select a plant',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private dds: DemographicDataService,
    private td: ToolAndDiePlantService,
    private _commonApplicationService: CommonService
  ) {}

  ngOnInit(): void {
    this.getShifts();
    this.getDepartment();
    this.getClassification();
    this.getPlants();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createForm();
    this.loadClassificationData(changes);
  }
  getPlants() {
    this.dds.getPlants().subscribe((data) => {
      this.plants[0].data = data;
    });
  }

  getDepartment() {
    this.dds.getDepartment().subscribe((data) => {
      this.Departments[0].data = data;
    });
  }

  getClassification() {
    this.dds.getClassification().subscribe((data) => {
      this.Classifications[0].data = data;
    });
  }

  getShifts() {
    this.td.getShiftsList().subscribe((data: any) => {
      this.shifts = data;
    });
  }

  createForm() {
    this.demographicForm = this.fb.group({
      Dept: ['', [Validators.required]],
      JobCodeId: ['', [Validators.required]],
      Shift: [''],
      Action_Date: [[Validators.required]],
      Emp_Status: ['', [Validators.required]],
      Ret_Loa_Date: [],
      Gm_Sen_Date: [],
      Plant_Sen_Date: ['', [Validators.required]],
      Plant_Skill_Date: [],
      Gm_Skill_Date: [],
      Emp_Start_Date: [],
      FMS_Training: [''],
      EligibilityDate: [],
      PlantId: [''],
      EMPLOYEEID: [''],
    });
  }

  loadClassificationData(changes: SimpleChanges) {
    this.demographicSelected =
      (changes.selectedDemographic &&
        changes.selectedDemographic.currentValue) ||
      '';
    if (this.demographicSelected === '') {
      this.demographicSelected =
        (changes.selectedDemographic &&
          changes.selectedDemographic.previousValue) ||
        '';
    }
    //console.log(this.demographicSelected);
    this.demographicForm
      .get('EMPLOYEEID')
      ?.patchValue(this.demographicSelected.employeeid);
    this.demographicForm
      .get('Dept')
      ?.patchValue(this.demographicSelected.deptId);
    this.demographicForm
      .get('JobCodeId')
      ?.patchValue(this.demographicSelected.jobCodeId);
    this.demographicForm
      .get('Shift')
      ?.patchValue(this.demographicSelected.shiftId);
    this.demographicForm
      .get('Action_Date')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.demographicSelected.action_Date === null
            ? ''
            : this.demographicSelected.action_Date
        )
      );
    this.demographicForm
      .get('Emp_Status')
      ?.patchValue(this.demographicSelected.emp_Status);
    this.demographicForm
      .get('Ret_Loa_Date')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.demographicSelected.ret_Loa_Date === null
            ? ''
            : this.demographicSelected.ret_Loa_Date
        )
      );
    this.demographicForm
      .get('Gm_Sen_Date')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.demographicSelected.gm_Sen_Date === null
            ? ''
            : this.demographicSelected.gm_Sen_Date
        )
      );
    this.demographicForm
      .get('Plant_Sen_Date')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.demographicSelected.planT_SEN_DATE === null
            ? ''
            : this.demographicSelected.planT_SEN_DATE
        )
      );
    this.demographicForm
      .get('Plant_Skill_Date')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.demographicSelected.pLant_Skill_Date === null
            ? ''
            : this.demographicSelected.pLant_Skill_Date
        )
      );
    this.demographicForm
      .get('Gm_Skill_Date')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.demographicSelected.gm_Skill_Date === null
            ? ''
            : this.demographicSelected.gm_Skill_Date
        )
      );
    this.demographicForm
      .get('Emp_Start_Date')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.demographicSelected.emp_Start_Date === null
            ? ''
            : this.demographicSelected.emp_Start_Date
        )
      );
    this.demographicForm
      .get('FMS_Training')
      ?.patchValue(this.demographicSelected.fmS_Training);
    this.dds
      .getEligibilityDate(this.demographicSelected.employeeid)
      .subscribe((data) => {
        this.eligibilityData = data;
        if (this.eligibilityData?.eligibilityDate === null) {
          this.demographicForm
            .get('EligibilityDate')
            ?.patchValue(this._commonApplicationService.formatDate(''));
        } else {
          this.demographicForm
            .get('EligibilityDate')
            ?.patchValue(
              this._commonApplicationService.formatDate(
                this.eligibilityData?.eligibilityDate
              )
            );
        }
        if (this.eligibilityData?.plantId === null) {
          this.demographicForm.get('PlantId')?.patchValue('');
        } else {
          this.demographicForm
            .get('PlantId')
            ?.patchValue(this.eligibilityData?.plantId);
        }
      });
  }

  clear() {
    this.closeModal.nativeElement.click();
    this.createForm();
    this.loadCancelledData();
  }

  submitClassification() {
    const EligibilityDate =
      this.demographicForm.controls['EligibilityDate'].value;
    const Ret_Loa_Date = this.demographicForm.controls['Ret_Loa_Date'].value;
    const Gm_Sen_Date = this.demographicForm.controls['Gm_Sen_Date'].value;
    const Plant_Sen_Date =
      this.demographicForm.controls['Plant_Sen_Date'].value;
    const Plant_Skill_Date =
      this.demographicForm.controls['Plant_Skill_Date'].value;
    const Gm_Skill_Date = this.demographicForm.controls['Gm_Skill_Date'].value;
    const Emp_Start_Date =
      this.demographicForm.controls['Emp_Start_Date'].value;

    if (EligibilityDate === '' || EligibilityDate === 'NaN-NaN-NaN') {
      this.demographicForm.get('EligibilityDate')?.patchValue(null);
    }
    if (Ret_Loa_Date === '' || Ret_Loa_Date === 'NaN-NaN-NaN') {
      this.demographicForm.get('Ret_Loa_Date')?.patchValue(null);
    }
    if (Gm_Sen_Date === '' || Gm_Sen_Date === 'NaN-NaN-NaN') {
      this.demographicForm.get('Gm_Sen_Date')?.patchValue(null);
    }
    if (Plant_Sen_Date === '' || Plant_Sen_Date === 'NaN-NaN-NaN') {
      this.demographicForm.get('Plant_Sen_Date')?.patchValue(null);
    }
    if (Plant_Skill_Date === '' || Plant_Skill_Date === 'NaN-NaN-NaN') {
      this.demographicForm.get('Plant_Skill_Date')?.patchValue(null);
    }
    if (Gm_Skill_Date === '' || Gm_Skill_Date === 'NaN-NaN-NaN') {
      this.demographicForm.get('Gm_Skill_Date')?.patchValue(null);
    }
    if (Emp_Start_Date === '' || Emp_Start_Date === 'NaN-NaN-NaN') {
      this.demographicForm.get('Emp_Start_Date')?.patchValue(null);
    }

    this.dds.editDemographicData(this.demographicForm.value).subscribe(
      () => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 500) {
          this.badRequestAlert(_error.error);
        } else {
          this.validationError(_error.error);
        }
      }
    );
  }

  loadCancelledData() {
    this.demographicForm
      .get('EMPLOYEEID')
      ?.patchValue(this.demographicSelected.employeeid);
    this.demographicForm
      .get('Dept')
      ?.patchValue(this.demographicSelected.deptId);
    this.demographicForm
      .get('JobCodeId')
      ?.patchValue(this.demographicSelected.jobCodeId);
    this.demographicForm
      .get('Shift')
      ?.patchValue(this.demographicSelected.shiftId);
    this.demographicForm
      .get('Action_Date')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.demographicSelected.action_Date
        )
      );
    this.demographicForm
      .get('Emp_Status')
      ?.patchValue(this.demographicSelected.emp_Status);
    this.demographicForm
      .get('FMS_Training')
      ?.patchValue(this.demographicSelected.fmS_Training);
    this.demographicForm
      .get('EligibilityDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.eligibilityData?.eligibilityDate === null
            ? ''
            : this.eligibilityData?.eligibilityDate
        )
      );
    this.demographicForm
      .get('PlantId')
      ?.patchValue(this.eligibilityData?.plantId);
    this.demographicForm
      .get('Ret_Loa_Date')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.demographicSelected.ret_Loa_Date === null
            ? ''
            : this.demographicSelected.ret_Loa_Date
        )
      );
    this.demographicForm
      .get('Gm_Sen_Date')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.demographicSelected.gm_Sen_Date === null
            ? ''
            : this.demographicSelected.gm_Sen_Date
        )
      );
    this.demographicForm
      .get('Plant_Sen_Date')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.demographicSelected.planT_SEN_DATE === null
            ? ''
            : this.demographicSelected.planT_SEN_DATE
        )
      );
    this.demographicForm
      .get('Plant_Skill_Date')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.demographicSelected.pLant_Skill_Date === null
            ? ''
            : this.demographicSelected.pLant_Skill_Date
        )
      );
    this.demographicForm
      .get('Gm_Skill_Date')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.demographicSelected.gm_Skill_Date === null
            ? ''
            : this.demographicSelected.gm_Skill_Date
        )
      );
    this.demographicForm
      .get('Emp_Start_Date')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.demographicSelected.emp_Start_Date === null
            ? ''
            : this.demographicSelected.emp_Start_Date
        )
      );
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Demographic data has been updated successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
