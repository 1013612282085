import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OvertimeReasonDataService } from 'src/app/Services/TableMaintenance/OvertimeReasonData/overtime-reason-data.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-delete-overtime-reason-data',
  templateUrl: './delete-overtime-reason-data.component.html',
  styleUrls: ['./delete-overtime-reason-data.component.css'],
})
export class DeleteOvertimeReasonDataComponent {
  @Input() selectedOvertime: any;
  overtimeReasonId: string = '';
  @Output() isSucess = new EventEmitter();
  constructor(private ods: OvertimeReasonDataService) {}

  clear() {
    //TODO
  }

  confirmDelete() {
    if (this.selectedOvertime) {
      this.overtimeReasonId = this.selectedOvertime.overtimeReasonId;
      this.ods.deleteOvertimeReasonData(this.overtimeReasonId).subscribe(
        () => {
          this.successAlert();
          this.isSucess.emit(true);
        },
        (_error: HttpErrorResponse) => {
          if (_error.status === 500) {
            this.badRequestAlert(_error.error);
          } else {
            this.badRequestAlert(_error.error);
          }
        }
      );
    }
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'warning',
      title: `${errorMessage}`,
    });
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Record previously selected has been removed successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }
}
