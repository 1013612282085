import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NIOGroupsDataService } from 'src/app/Services/TableMaintenance/NIOGroupsData/niogroups-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-niodata',
  templateUrl: './edit-niodata.component.html',
  styleUrls: ['./edit-niodata.component.css'],
})
export class EditNIODataComponent implements OnChanges {
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;
  @Input() userName: any;
  @Input() selectedNIO: any;
  @Output() isSucess = new EventEmitter();
  nioForm!: FormGroup;
  nioSelected: any;
  Status: any[] = [
    {
      data: [
        {
          statusType: 'A',
          statusName: 'Active',
        },
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
      ],
      default: 'Select One',
    },
  ];
  constructor(private fb: FormBuilder, private nds: NIOGroupsDataService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.createForm();
    this.loadData(changes);
  }

  createForm() {
    this.nioForm = this.fb.group({
      nio: [''],
      nioGroupDescription: ['', Validators.required],
      status: ['', [Validators.required]],
      lastUpdateBy: [this.userName],
    });
  }

  loadData(changes: SimpleChanges) {
    this.nioSelected =
      (changes.selectedNIO && changes.selectedNIO.currentValue) || '';
    if (this.nioSelected === '') {
      this.nioSelected =
        (changes.selectedNIO && changes.selectedNIO.previousValue) || '';
    }
    //console.log(this.nioSelected)
    this.nioForm.get('nio')?.patchValue(this.nioSelected.nio);
    this.nioForm
      .get('nioGroupDescription')
      ?.patchValue(this.nioSelected.niogroupdescription);
    this.nioForm.get('status')?.patchValue(this.nioSelected.status);
  }

  submitNIO() {
    //console.log(this.nioForm.value);
    this.nds.editNIOData(this.nioForm.value).subscribe(
      () => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 500) {
          this.badRequestAlert(_error.error);
        } else {
          this.validationError(_error.error);
        }
      }
    );
  }

  clear() {
    this.closeModal.nativeElement.click();
    this.createForm();
    this.loadCancelledData();
  }

  loadCancelledData() {
    this.nioForm.get('nio')?.patchValue(this.nioSelected.nio);
    this.nioForm
      .get('nioGroupDescription')
      ?.patchValue(this.nioSelected.niogroupdescription);
    this.nioForm.get('status')?.patchValue(this.nioSelected.status);
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Record has been updated successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
