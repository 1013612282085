import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ShiftPreferenceDataService } from 'src/app/Services/TableMaintenance/ShiftPreferenceData/shift-preference-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-delete-shift-preference-data',
  templateUrl: './delete-shift-preference-data.component.html',
  styleUrls: ['./delete-shift-preference-data.component.css'],
})
export class DeleteShiftPreferenceDataComponent {
  @Input() selectedPlantGroup: any;
  plantgroupid: string = '';
  @Output() isSucess = new EventEmitter();
  constructor(private shiftPreferenceDataService: ShiftPreferenceDataService) {}

  clear() {
    //TODO
  }
  confirmDelete() {
    if (this.selectedPlantGroup) {
      this.plantgroupid = this.selectedPlantGroup.plantgroupid;
      this.shiftPreferenceDataService
        .deletePlantGroupData(this.plantgroupid)
        .subscribe(
          (res) => {
            this.successAlert();
            this.isSucess.emit(true);
          },
          (_error: HttpErrorResponse) => {
            if (_error.status === 500) {
              this.badRequestAlert(_error.error);
            } else {
              this.badRequestAlert(_error.error);
            }
          }
        );
    }
  }
  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'warning',
      title: `${errorMessage}`,
    });
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Record previously selected has been removed successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }
}
