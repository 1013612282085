import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DistrictDataService } from 'src/app/Services/TableMaintenance/DistrictData/district-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-district-data',
  templateUrl: './update-district-data.component.html',
  styleUrls: ['./update-district-data.component.css'],
})
export class UpdateDistrictDataComponent {
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;
  @Input() userName: any;
  @Input() selectedDistrict: any;
  @Output() isSucess = new EventEmitter();
  DistrictForm!: FormGroup;
  DistrictSelected: any;
  representativePhoneFormat: boolean = false;
  alternatePhoneFormat: boolean = false;
  Status: any[] = [
    {
      data: [
        {
          statusType: 'A',
          statusName: 'Active',
        },
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
      ],
      default: 'Select One',
    },
  ];
  constructor(
    private fb: FormBuilder,
    private districtDataService: DistrictDataService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.createForm();
    this.loadData(changes);
  }
  createForm() {
    this.DistrictForm = this.fb.group({
      DISTRICTID: ['', [Validators.required]],
      DISTRICTDESCRIPTION: [''],
      REPRESENTATIVE_EMPLOYEEID: ['', Validators.required],
      REPRESENTATIVEPHONE: [null, this.checkRepresentativePhoneStatus],
      ALTERNATE_EMPLOYEEID: [''], //Validators.required
      ALTERNATEPHONE: [null, this.checkAlternatehoneStatus],
      STATUS: ['', [Validators.required]],
      LASTUPDATEBY: [this.userName],
    });
  }

  loadData(changes: SimpleChanges) {
    this.DistrictSelected =
      (changes.selectedDistrict && changes.selectedDistrict.currentValue) || '';
    if (this.DistrictSelected === '') {
      this.DistrictSelected =
        (changes.selectedDistrict && changes.selectedDistrict.previousValue) ||
        '';
    }
    this.DistrictForm.get('DISTRICTID')?.patchValue(
      this.DistrictSelected.districtid
    );
    this.DistrictForm.get('DISTRICTDESCRIPTION')?.patchValue(
      this.DistrictSelected.districtdescription
    );
    this.DistrictForm.get('REPRESENTATIVE_EMPLOYEEID')?.patchValue(
      this.DistrictSelected.representativE_EMPLOYEEID
    );
    this.DistrictForm.get('REPRESENTATIVEPHONE')?.patchValue(
      this.DistrictSelected.representativephone
    );
    this.DistrictForm.get('ALTERNATE_EMPLOYEEID')?.patchValue(
      this.DistrictSelected.alternatE_EMPLOYEEID
    );
    this.DistrictForm.get('ALTERNATEPHONE')?.patchValue(
      this.DistrictSelected.alternatephone
    );
    this.DistrictForm.get('STATUS')?.patchValue(this.DistrictSelected.status);
  }
  submitDistrictData() {
    this.districtDataService
      .editDistrictData(this.DistrictForm.value)
      .subscribe(
        () => {
          this.clear();
          this.successAlert();
          this.isSucess.emit(true);
        },
        (_error: HttpErrorResponse) => {
          if (_error.status === 500) {
            this.badRequestAlert(_error.error);
          } else {
            this.validationError(_error.error);
          }
        }
      );
  }
  clear() {
    this.closeModal.nativeElement.click();
    this.createForm();
    this.loadCancelledData();
  }
  loadCancelledData() {
    this.representativePhoneFormat = false;
    this.DistrictForm.get('DISTRICTID')?.patchValue(
      this.DistrictSelected.districtid
    );
    this.DistrictForm.get('DISTRICTDESCRIPTION')?.patchValue(
      this.DistrictSelected.districtdescription
    );
    this.DistrictForm.get('REPRESENTATIVE_EMPLOYEEID')?.patchValue(
      this.DistrictSelected.representativE_EMPLOYEEID
    );
    this.DistrictForm.get('REPRESENTATIVEPHONE')?.patchValue(
      this.DistrictSelected.representativephone
    );
    this.DistrictForm.get('ALTERNATE_EMPLOYEEID')?.patchValue(
      this.DistrictSelected.alternatE_EMPLOYEEID
    );
    this.DistrictForm.get('ALTERNATEPHONE')?.patchValue(
      this.DistrictSelected.alternatephone
    );
    this.DistrictForm.get('STATUS')?.patchValue(this.DistrictSelected.status);
  }

  checkRepresentativePhoneStatus(control: FormControl) {
    var phoneEntered = control.value;
    if (
      !phoneEntered?.includes('-') &&
      phoneEntered != '' &&
      phoneEntered != null
    ) {
      return {
        representativePhoneFormatError:
          "You must enter the entire representative's phone number or leave it blank.",
      };
    }
    return null;
  }
  checkAlternatehoneStatus(control: FormControl) {
    var alternatePhoneEntered = control.value;
    if (
      !alternatePhoneEntered?.includes('-') &&
      alternatePhoneEntered != '' &&
      alternatePhoneEntered != null
    ) {
      return {
        alternatePhoneFormatError:
          "You must enter the entire alternative's phone number or leave it blank.",
      };
    }
    return null;
  }
  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Record has been updated successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }
  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
