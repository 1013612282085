import { DatePipe, formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { OverTimeMaintanceService } from 'src/app/Services/Applications/OvertimeMaintanence/overtime.maintanence.service';
import { DateService } from 'src/app/Services/Date/date.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { UserRoles } from 'src/enum/userroles';

@Component({
  selector: 'app-delete-overtime-maintenance',
  templateUrl: './delete-overtime-maintenance.component.html',
  styleUrls: ['./delete-overtime-maintenance.component.css'],
})
export class DeleteOvertimeMaintenanceComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private _overtimeMaintenanceService: OverTimeMaintanceService,
    private _commonApplicationService: CommonService,
    public _userInfo: UsergroupService,
    private _commonService: CommonService,
    private _sweetAlertService: SweetAlertService,
    private _dateService: DateService
  ) {
    this.getUserRoleDescreption();
  }

  employeeForm!: FormGroup;
  checkAllRecords: boolean = false; //tbd
  listATID: string = '';
  searchDateOfWork: any;
  userRole: UserRoles = UserRoles.SKILLEDUSER; // Enum for userrole

  @Input() isDelete: boolean = false;
  @Input() isSingleRecord: boolean = false;
  @Input() overtimeGroupName: any;
  @Input() title: any;
  @Input() passUpdateData: any[] = [];
  @Input() modifiedOvertimRecords: any[] = [];
  @Output() clean = new EventEmitter();
  @Output() isSucess = new EventEmitter();
  @ViewChild('closeDeleteModal') closeDeleteModal!: ElementRef;
  @Output() isMassDeleteSuccess = new EventEmitter();
  OvertimeType: any;

  OvertimeReason: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];

  ngOnInit(): void {
    const format = 'yyyy-MM-dd';
    const locale = 'en-US';
    var dtTodayDate = new Date();
    this.searchDateOfWork = formatDate(dtTodayDate, format, locale);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getOvertimeType();
    this.getOvertimeReason();
    if (this.isSingleRecord) {
      this.GetOvertimeData();
    }
  }

  loggedInUserATID: string = '';

  getUserRoleDescreption() {
    this._userInfo.getUserInfo().subscribe((data: any) => {
      this.userRole =
        data['groupDescription'] === UserRoles.HRPLUS
          ? UserRoles.HRPLUS
          : UserRoles.SKILLEDUSER;
      this.loggedInUserATID = data.userName;
    });
  }

  ismodifiedOvertimRecordsExists: boolean = true;
  GetOvertimeData() {
    this.checkAllRecords = false;
    var overtimeGroupId = 0;
    this.listATID === '';
    this.modifiedOvertimRecords.length = 0;
    var listATID = '';
    var recordId = 0;
    for (var j = 0; j < this.passUpdateData.length; j++) {
      overtimeGroupId = this.passUpdateData[j].overtimegroupid;
      if (listATID === '') {
        listATID = this.passUpdateData[j].employeeid;
      } else {
        listATID = listATID + ',' + this.passUpdateData[j].employeeid;
      }
      recordId = this.passUpdateData[j].recordid;
    }

    var searchtype = '';
    if (recordId != null && recordId != undefined) {
      searchtype = 'NORMAL';
      listATID = '';
    } else {
      searchtype = 'MassUpdate';
    }
    var datePipe = new DatePipe('en-US');
    var dateOfWork = datePipe.transform(this.searchDateOfWork, 'MM/dd/yyyy');

    var paramUpdateData = {
      searchtype: searchtype,
      dateofworkfrom: dateOfWork,
      dateofworkto: dateOfWork,
      atidlist: listATID,
      recordid: recordId,
      overtimegroupid: overtimeGroupId,
    };

    this._overtimeMaintenanceService
      .GetOvertimeRecordsForUpdate(paramUpdateData)
      .subscribe((data: any) => {
        this.modifiedOvertimRecords = data;
        this.ismodifiedOvertimRecordsExists =
          this.modifiedOvertimRecords.length > 0 ? true : false;
        for (var j = 0; j < this.modifiedOvertimRecords.length; j++) {
          this.modifiedOvertimRecords[j].dateofwork =
            this._commonApplicationService.formatDate(
              new Date(this.modifiedOvertimRecords[j].dateofwork)
            );
          this.modifiedOvertimRecords[j].dateoffered =
            this._commonApplicationService.formatDate(
              new Date(this.modifiedOvertimRecords[j].dateoffered)
            );
          this.modifiedOvertimRecords[
            j
          ].OvertimeReasonArrayBasedOnOvertimeType = this.onChangeGetOvertimeType(
            this.modifiedOvertimRecords[j].overtimetypeid
          );
          this.modifiedOvertimRecords[j].dateofworkflag = false;
          this.modifiedOvertimRecords[j].dateofferedflag = false;
          this.modifiedOvertimRecords[j].overtimegroupidflag = false;
          this.modifiedOvertimRecords[j].overtimereasonidflag = false;
          this.modifiedOvertimRecords[j].paidhoursflag = false;
          this.modifiedOvertimRecords[j].freeformcommentflag = false;
          this.modifiedOvertimRecords[j].isCommentRequired = false;
          this.modifiedOvertimRecords[j].overtimeRequireValidation = false;
          this.modifiedOvertimRecords[j].Override = false;
          this.modifiedOvertimRecords[j].paidHoursMissing = false;
          this.modifiedOvertimRecords[j].overtimeTypeMissing = false;
          this.modifiedOvertimRecords[j].overtimeReasonMissing = false;
          this.modifiedOvertimRecords[j].DowIsGreaterThanDo = false;
          this.modifiedOvertimRecords[j].DateEarlierOrOlderFlag = false;
          this.modifiedOvertimRecords[j].callinRefusedFalg = false;
          this.modifiedOvertimRecords[j].statusFalg = false;
          if (this.modifiedOvertimRecords[j].status === 'A') {
            this.modifiedOvertimRecords[j].boolStatus = true;
          } else {
            this.modifiedOvertimRecords[j].boolStatus = false;
          }
        }
        this.originalOvertimRecords = JSON.parse(
          JSON.stringify(this.modifiedOvertimRecords)
        );
        //console.log('logs',this.modifiedOvertimRecords);
      });
  }

  originalOvertimRecords: any = [];
  getOvertimeType() {
    this._overtimeMaintenanceService
      .getOvertimeType()
      .subscribe((overtimeType: any) => {
        this.OvertimeType = overtimeType;
        //console.log('OT type Called');
      });
  }

  isCommentRequiredArray: any[] = [];
  getOvertimeReason() {
    this._overtimeMaintenanceService
      .getOvertimeReason()
      .subscribe((OvertimeReason: any) => {
        this.OvertimeReason[0].data = OvertimeReason;
        for (var i = 0; i < OvertimeReason.length; i++) {
          if (OvertimeReason[i].commentrequired === '1') {
            this.isCommentRequiredArray.push(OvertimeReason[i]);
          }
        }
      });
  }

  /*check box functions*/
  selectAllEmployee() {
    for (var i = 0; i < this.modifiedOvertimRecords.length; i++) {
      if (this.checkAllRecords === true) {
        this.modifiedOvertimRecords[i].isselected = this.checkAllRecords;
      } else {
        this.modifiedOvertimRecords[i].isselected = this.checkAllRecords;
      }
    }
  }

  listOfOvertimeRecords: any[] = [];
  onEmployeeSelect(idx: number) {
    this.modifiedOvertimRecords[idx].isSelected =
      !this.modifiedOvertimRecords[idx].isSelected;
  }

  /*Cascading Dropdown*/
  onChangeGetOvertimeType(value: string) {
    var newOvertimeReasonArray: any = [];
    var tempOvertimeTypeStore: any;
    var tempstoreVar: any;
    newOvertimeReasonArray.length = 0;
    tempOvertimeTypeStore = this.OvertimeType.filter(
      (selectedType: any) => selectedType['overtimetypeid'] === value
    );

    newOvertimeReasonArray.push(
      this.OvertimeReason[0].data.filter(
        (selectedReason: any) =>
          selectedReason['typeassociation'] ===
          tempOvertimeTypeStore[0].reasonassociation
      )
    );

    if (newOvertimeReasonArray.length > 0) {
      tempstoreVar = newOvertimeReasonArray[0].overtimereasonid;
    } else {
      tempstoreVar = '';
    }

    return newOvertimeReasonArray;
  }

  clear() {
    this.clean.emit('');
    this.closeDeleteModal.nativeElement.click();
  }

  buttonDisabled: boolean = false;
  DeleteMassOvertimeRecord() {
    this.listOfOvertimeRecords.splice(0, this.listOfOvertimeRecords.length);
    this.modifiedOvertimRecords.forEach((ele: any) => {
      if (ele.isselected) {
        this.listOfOvertimeRecords.push(ele.recordid);
      }
    });
    //console.log(JSON.stringify(this.listOfOvertimeRecords));
    if (confirm('Are you sure you want to delete these records?')) {
      this.buttonDisabled = true;
      this._overtimeMaintenanceService
        .overtimeMassDelete(JSON.stringify(this.listOfOvertimeRecords))
        .subscribe(
          (_success: any) => {
            this.clear();
            this.buttonDisabled = false;
            this._sweetAlertService.successAlert(
              'top-end',
              'success',
              'The records have been Deleted successfully.',
              false,
              1500
            );
            this.isMassDeleteSuccess.emit(true);
            this.checkAllRecords = true;
          },
          (_error: HttpErrorResponse) => {
            this.buttonDisabled = false;
            this._sweetAlertService.InternalServerError500(
              'error',
              'ERROR',
              'An error occured while processing. Please try again.'
            );
          }
        );
    }
  }
}
