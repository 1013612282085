import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class OvertimeTypeDataService {
  commonUtility = new CommonUtility();
  private readonly api_addOvertimeTypeData = '/OvertimeTypeDataAdd'
  private readonly api_deleteOvertimeTypeData = '/OvertimeTypeDataDelete?overtimeTypeId='
  private readonly api_editOvertimeTypeData = '/OvertimeTypeDataUpdate'
  private readonly api_getOvertimeTypeData = '/OvertimeTypeDataGetAll'

  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  addOvertimeTypeData(overtimeTypeData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_addOvertimeTypeData,
      overtimeTypeData,
      { headers }
    );
  }

  editOvertimeTypeData(overtimeTypeData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.tableMaintenanceURL}` + this.api_editOvertimeTypeData,
      overtimeTypeData,
      { headers }
    );
  }

  deleteOvertimeTypeData(overtimeTypeId: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.delete<any>(
      `${environment.tableMaintenanceURL}` + this.api_deleteOvertimeTypeData + overtimeTypeId,
      { headers }
    );
  }

  getOvertimeTypeData(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getOvertimeTypeData,
      { headers }
    );
  }
}
