<div *ngIf="selectedEmp" class="modal fade" id="cancelApplicationModal" tabindex="-1" role="dialog" aria-labelledby="cancelApplicationModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="cancelApplicationModalLabel">Cancel Application</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="dateForm">
                    <table class="table table-borderless mt-2 mb-2 info-table">

                        <tbody>
                            <tr>
                                <td class='tab-label'>Employee Name:</td>
                                <td class='tab-value'> {{ selectedEmp.lastName ? selectedEmp.lastName : "N/A" }},&nbsp;{{ selectedEmp.firstName ? selectedEmp.firstName : "N/A" }}&nbsp;{{ selectedEmp.middleName ? selectedEmp.middleName : "" }}</td>
                            </tr>

                            <tr>
                                <td class='tab-label'>Employee Id:</td>
                                <td class='tab-value'>{{selectedEmp.employeeId? selectedEmp.employeeId: 'N/A'}}</td>
                            </tr>

                            <tr>
                                <td class='tab-label'>Current Classification:</td>
                                <td class='tab-value'>{{usersData.jobCodeId}} - {{usersData.jobCodeDescription}}</td>
                            </tr>

                            <tr>
                                <td class='tab-label'>Current Department:</td>
                                <td class='tab-value'>{{usersData.deptId}}</td>
                            </tr>

                            <tr>
                                <td class='tab-label'>Current Shift:</td>
                                <td class='tab-value'>{{usersData.shiftDescription}}</td>
                            </tr>

                            <tr>
                                <td class='tab-label'>Classification Applied For:</td>
                                <td class='tab-value'>{{selectedEmp.jobCodeId?selectedEmp.jobCodeId: 'N/A'}} - {{selectedEmp.jobCodeDescription}}
                                </td>
                            </tr>

                            <!-- <tr *ngIf="isLoggedInUserAdmin && !showCancelButton">
                                <td class='tab-label'>Date of Cancel:</td>
                                <td class='tab-value'>
                                    <input hidden readonly readonly type="email" formControlName="AppStatusDate" class="form-control">
                                    
                                    <input type="date"  class="form-control" formControlName="AppStatusDate" max= {{maxCancelDate}} >
                                    <div *ngIf="dateForm.controls['AppStatusDate'].invalid && dateForm.controls['AppStatusDate'].touched">
                                        <small class="text-danger" *ngIf="dateForm.controls['AppStatusDate'].errors?.required">
                                            Cancel date is required.
                                        </small>
                                    </div>
                                    <label class="alert alert-danger" *ngIf="dateForm.errors">{{
                                        dateForm.errors?.dates}}</label>                                   
                                </td>                               
                            </tr> -->

                            <tr *ngIf="!isLoggedInUserAdmin">
                                <td class='tab-label'>Date of Cancel:</td>
                                <td class='tab-value'>
                                    <input hidden readonly readonly type="email" formControlName="appId" class="form-control">

                                    <input hidden type="date" class="form-control" formControlName="AppStatusDate" value="{{currentDate | date:'yyyy-MM-dd'}}">
                                    <label>{{currentDate | date: "MM/dd/yyyy"}}</label>
                                </td>
                            </tr>

                            <tr>
                                <td class='tab-label'>Employee Signature Date:</td>
                                <td class='tab-value'>{{selectedEmp.empSignDate? (selectedEmp.empSignDate | date:'MM/dd/yyyy'): 'N/A'}}</td>
                            </tr>

                            <tr>
                                <td class='tab-label'>Status:</td>
                                <td class='tab-value'>{{selectedEmp.appStatusCode? selectedEmp.appStatusCode : 'N/A'}}
                                </td>

                            </tr>
                            <tr>
                                <td class='tab-label'>Status Date:</td>
                                <td class='tab-value'>{{selectedEmp.appStatusDate? (selectedEmp.appStatusDate | date:'MM/dd/yyyy'): 'N/A'}}
                                </td>

                            </tr>
                            <tr>
                                <td class='tab-label'>Entered By:</td>
                                <td class='tab-value'>{{editorName}}</td>

                            </tr>
                            <tr>
                                <td class='tab-label'>Date Entered:</td>
                                <td class='tab-value'>{{selectedEmp.empSignDate? (selectedEmp.empSignDate | date:'MM/dd/yyyy'): 'N/A'}}</td>

                            </tr>
                            <tr>
                                <td class='tab-label'>LastUpdate By:</td>
                                <td class='tab-value'>{{selectedEmp.lastUpdateBy?selectedEmp.lastUpdateBy: 'N/A'}}</td>

                            </tr>

                            <tr>
                                <td class='tab-label'>LastUpdate Date:</td>
                                <td class='tab-value'>{{selectedEmp.lastUpdateDate? (selectedEmp.lastUpdateDate | date:'MM/dd/yyyy') : 'N/A'}}
                                </td>

                            </tr>



                        </tbody>
                    </table>
                </form>
                <div *ngIf="showCancelButton">
                    <small class="text-danger">The application is no longer "Open", it cannot be cancelled.</small>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" (click)="clear()" #closeCancelModal> Cancel </button>
                <button *ngIf="!showCancelButton" type="button" class="btn btn-primary" [disabled]="dateForm.invalid" (click)="confirmCancel()">
                    Cancel Application
                </button>
            </div>
        </div>
    </div>
</div>