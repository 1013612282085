import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToolAndDiePlantService } from 'src/app/Services/Applications/ToolAndDie/tool-and-die-plant.service';
import { ViewChild, ElementRef } from '@angular/core';
import { TransferAgreementService } from 'src/app/Services/Applications/TransferAgreementService/transfer-agreement.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { DateService } from 'src/app/Services/Date/date.service';

@Component({
  selector: 'app-cancel-shift-preference',
  templateUrl: './cancel-shift-preference.component.html',
  styleUrls: ['./cancel-shift-preference.component.css']
})
export class CancelShiftPreferenceComponent implements OnInit {

  @Input() selectedEmp: any;
  @Input() employeeForm: any;
  @Input() isLoggedInUserAdmin: boolean = false;
  @Output() isSucess = new EventEmitter();
  @ViewChild('closeCancelModal') closeModal!: ElementRef;


  dateForm!: FormGroup;
  modalId: string | any;
  currentDate = new Date();

  /* these variables hold current position of applicant*/
  usersData: any;
  JobCodeClassification: string = '';
  Department: string = '';
  Shift: string = '';
  Plant: string = '';



  constructor(private fb: FormBuilder,
    private td: ToolAndDiePlantService,
    private _transferAgreement: TransferAgreementService,
    private _sweetAlertService: SweetAlertService,
    private _commonApplicationService: CommonService,
    private _dateService: DateService
  ) { }
  ngOnInit(): void {
    this.dateForm = this.fb.group({
      appId: ['',],
      AppStatusDate: ['', [Validators.required]],
      empSignDate: ['', [Validators.required]],
      LastUpdatedBy: ['', [Validators.required]],
      AppType: ['SP']

    },
      {
        validator: this._commonApplicationService.dateLessThan('empSignDate', 'AppStatusDate'),
      }
    );
    this.formBindingData();
  }


  formBindingData() {
    this.td.appId.subscribe(id => {
      if (id) {
        this.dateForm.get('appId')?.patchValue(id)

      }
    })
    this.td.empsignDate.subscribe(esdate => {
      if (esdate) {
        this.dateForm.get('empSignDate')?.patchValue(esdate)

      }
    })
    this._transferAgreement.atid.subscribe(atid => {
      if (atid) {
        this.dateForm.get('LastUpdatedBy')?.patchValue(atid)

      }

    })
    if (this.isLoggedInUserAdmin === false) {
      this.dateForm.get('AppStatusDate')?.patchValue(this.currentDate);
    }

    this._transferAgreement.applicationAtid.subscribe(_applicationAtid => {
      if (_applicationAtid) {
        this._transferAgreement.searchFilterApplicationUser({ atid: _applicationAtid }).subscribe((data) => {
          this.usersData = data[0];
          this.JobCodeClassification = this.usersData.jobCodeId + '-' + this.usersData.jobCodeDescription;
          this.Plant = this.usersData.plantId + '-' + this.usersData.plantDescription;
          this.Shift = this.usersData.shiftDescription;
          this.Department = this.usersData.deptId;
        });
      }
    })
  }
  clear() {
    //this.dateForm.reset();
    this.closeModal.nativeElement.click();
  }
  confirmCancel() {
    // var dt = this.dateForm.get('AppStatusDate')?.value;
    // var appStsDt = new Date(dt);
    // var appStsDtInUTC = this._dateService.GetUTCDate(appStsDt);

    // this.dateForm.patchValue({ AppStatusDate: appStsDtInUTC });

    this._transferAgreement.TransferAgreementTACancel(this.dateForm.value).subscribe(
      (data: any) => {
        this.clear();
        this.isSucess.emit(true);
        this._sweetAlertService.successAlert('center', 'success', 'Cancelled Succesfully', false, 1500);
      },
      (error) => {
        this._sweetAlertService.InternalServerError500('error', 'Error', 'Something happened. Please try again.');
      }

    );
  }

}
