import { Component } from '@angular/core';

@Component({
  selector: 'app-absence-reason-data',
  templateUrl: './absence-reason-data.component.html',
  styleUrls: ['./absence-reason-data.component.css'],
})
export class AbsenceReasonDataComponent {
  constructor() {}
}
