<html lang="en">
  <head>
    <!-- Required meta tags -->
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <title>UAW Contract Administration System</title>
  </head>

  <body style="background-color: white">
    <div class="container-fluid app-box">
      <!-- Header -->
      <div class="row header-container">
        <div class="col">
          <a style="cursor: pointer" title="Home" routerLink="/Home">
            <img
              src="./assets/logo.png"
              class="img-fluid allison-logo pr-3 mr-3"
            />
          </a>
          <img
            src="./assets/divider.png"
            class="img-fluid allison-logo pr-3 mr-3"
            style="padding-left: 82px"
          />
          <span class="app-name">UAW Contract Administration System</span>
        </div>
        <div *ngIf="showUserName" class="col text-right">
          <span class="user-name">{{ userName }} ({{ role }})</span>
          <img src="./assets/user.svg" class="img-fluid user-icon ml-1" />
        </div>
        <div class="showusername">
          <a
            *ngIf="showUserName"
            (click)="logout()"
            style="cursor: pointer"
            title="Logout"
          >
            <img class="img" src="./assets/logouticon.png" alt="Logout" />
          </a>
        </div>
      </div>

      <!-- Main page load here here -->
      <div class="row app-container main-div">
        <!-- Side Nav Load up here -->
        <div class="col-1 side-nav pl-0 sub">
          <div class="nav-item pb-3 application-dashboard-menu subnav-menu">
            <div
              class="nav-label nav-margin"
              *ngFor="let menuA of newDataSet; let i = index"
            >
              <nav>
                <ul class="nav" (click)="isModuleSelected(menuA.index)">
                  <li appExpandmenu [ngClass]="menuA.isClicked ? 'active' : ''">
                    <a class="btn cust-btn li-item btnCustBtnLiItem li-padding">
                      <img src="{{ menuA.moduleLogo }}" />
                      <strong>{{ menuA.moduleName }}</strong></a
                    >
                  </li>
                  <ul class="nav submenu">
                    <ng-container
                      *ngFor="let menuB of newDataSet[i].views; let i = index"
                    >
                      <li
                        *ngIf="menuB.viewCode !== 'AdministerUsers'"
                        [class.selected]="i === selectedUser"
                        class="list-group-item li-item listGroupItemLiItemColor"
                      >
                        <a
                          routerLink="facilities-service-transfer"
                          routerLinkActive="active"
                          class="btn cust-btn li-item submenuli-item"
                          [routerLink]="menuB.viewRouterName"
                        >
                          <div class="lnavmnu">
                            <div>
                              <img [src]="menuB.viewLogo" />
                            </div>
                            <div class="lnavmnuempty">&nbsp;</div>
                            <div class="lnavmnudivtext">
                              <strong class="lnavmnutext">{{
                                menuB.viewName
                              }}</strong>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ng-container>
                  </ul>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <!-- Router Outlet -->
        <div class="col main-container div-container">
          <router-outlet> </router-outlet>
        </div>
      </div>

      <!-- Footer -->
      <div class="row footer pt-2 pb-2">
        <div class="col text-center footer-content">
          Copyright 2021 Allison Transmission Inc. All Rights Reserved
        </div>
      </div>
    </div>
  </body>
</html>
