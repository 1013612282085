import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ViewChild, ElementRef } from '@angular/core';
import { MSALserviceService } from 'src/app/Services/MSALservice/msalservice.service';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { RecallRightsService } from 'src/app/Services/Applications/RecallRights/recall-rights.service';
import { DateService } from 'src/app/Services/Date/date.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';

@Component({
  selector: 'app-cancel-recall-rights',
  templateUrl: './cancel-recall-rights.component.html',
  styleUrls: ['./cancel-recall-rights.component.css'],
})
export class CancelRecallRightsComponent implements OnInit {
  @Input() selectedEmp: any;
  @Input() userInformation: any;
  @Input() isLoggedInUserAdmin: boolean = false;
  @Output() isSucess = new EventEmitter();
  @ViewChild('closeCancelModal') closeModal!: ElementRef;
  applicationSelected: any;
  dateForm!: FormGroup;
  empSignDate: any;
  usersData: any;
  currentDate = new Date();
  showCancelButton: boolean = false;
  CalenderBlockedAfterTodayDate: string = '';
  editorName: any;
  constructor(
    private fb: FormBuilder,
    private rr: RecallRightsService,
    private appCom: MSALserviceService,
    private _commonApplicationService: CommonService,
    private _dateService: DateService,
    private _sweetAlertService: SweetAlertService
  ) {}

  ngOnInit(): void {
    let loggedInUser = this.parseJwt(this.appCom.getAuthToken());
    this.editorName = loggedInUser.name;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.CreateCancelForm();
    this.LoadApplicationData(changes);
  }

  CreateCancelForm() {
    this.dateForm = this.fb.group(
      {
        appId: [''],
        AppStatusDate: ['', [Validators.required]],
        appType: ['RR'],
        LastUpdatedBy: [''],
        Comments: [],
        empSignDate: [],
      },
      {
        validator: this.dateLessThan('AppStatusDate'),
      }
    );
  }

  LoadApplicationData(changes: SimpleChanges) {
    this.applicationSelected =
      (changes.selectedEmp && changes.selectedEmp.currentValue) || '';
    if (this.applicationSelected === '') {
      this.applicationSelected =
        (changes.selectedEmp && changes.selectedEmp.previousValue) || '';
    }
    this.CalenderBlockedAfterTodayDate =
      this._commonApplicationService.formatDate(this.currentDate);
    this.empSignDate = this.applicationSelected.empSignDate;
    this.removeAfterChar(this.applicationSelected.appStatusCode);
    this.dateForm.get('appId')?.patchValue(this.applicationSelected.appId);
    this.dateForm
      .get('AppStatusCode')
      ?.patchValue(this.applicationSelected.appStatusCode);
    this.dateForm
      .get('empSignDate')
      ?.patchValue(this.applicationSelected.empSignDate);
    this.dateForm
      .get('LastUpdatedBy')
      ?.patchValue(this.userInformation?.userName);
    this.getCurrentClassification(this.applicationSelected.employeeId);
    if (this.isLoggedInUserAdmin === false) {
      this.dateForm.get('AppStatusDate')?.patchValue(this.currentDate);
    } else {
      this.dateForm
        .get('AppStatusDate')
        ?.patchValue(
          this._commonApplicationService.formatDate(this.currentDate)
        );
    }
  }

  getCurrentClassification(employeeId: string) {
    this.rr
      .searchFilterApplicationUser({ employeeId: employeeId })
      .subscribe((data) => {
        this.usersData = data[0];
      });
  }

  clear() {
    this.closeModal.nativeElement.click();
  }

  confirmCancel() {
    // var dt = this.dateForm.get('AppStatusDate')?.value;
    // var appStsDt  = new Date(dt);
    // var appStsDtInUTC  = this._dateService.GetUTCDate(appStsDt);

    // this.dateForm.patchValue({AppStatusDate: appStsDtInUTC});

    const AppStatusDate = new Date(
      this.dateForm.controls['AppStatusDate'].value
    );
    const EmpSignDate = new Date(this.dateForm.controls['empSignDate'].value);

    if (EmpSignDate > AppStatusDate) {
      const error = "Status date shouldn't be earlier than Employee sign date";
      return this._sweetAlertService.invalidSelection('error', 'ERROR', error);
    }

    this.rr.Cancel152Application(this.dateForm.value).subscribe(
      (data: any) => {
        this.clear();
        this.isSucess.emit(true);
        this.successAlert();
      },
      (error) => {}
    );
  }

  successAlert() {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'The Application has been Cancelled Successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  // dateLessThan(to: string) {
  //   return (group: FormGroup): { [key: string]: any } => {
  //     let empsigndate = this.empSignDate;
  //     let appstatusdate = group.controls[to];
  //     if (new Date(empsigndate) > new Date(appstatusdate?.value)) {
  //       return {
  //         dates: "Status date shouldn't be earlier than Employee sign date",
  //       };
  //     }
  //     return {};
  //   };
  // }

  dateLessThan(to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let empsigndate = this.empSignDate;
      let appstatusdate = group.controls[to];
      if (
        new Date(new Date(empsigndate).setHours(0, 0, 0, 0)) >
        new Date(
          new Date(new Date(appstatusdate?.value).setHours(24)).setHours(
            0,
            0,
            0,
            0
          )
        )
      ) {
        return {
          dates: "Status date shouldn't be earlier than Employee sign date",
        };
      }
      return {};
    };
  }

  removeAfterChar(value: string) {
    let resultString = '';
    if (value != null && value != '') {
      let tempString = value.split('[')[0];
      resultString = tempString.trim();
    }
    if (resultString.indexOf('CLOSED') !== -1) {
      let tempString = value.split(' -')[0];
      resultString = tempString.trim();
    }
    if (resultString === 'CLOSED' || resultString === 'CANCELLED') {
      this.showCancelButton = true;
    } else {
      this.showCancelButton = false;
    }
  }

  parseJwt(token: any) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}
