import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { OverTimeMaintanceService } from 'src/app/Services/Applications/OvertimeMaintanence/overtime.maintanence.service';
import { LocalStorageService } from 'src/app/Services/LocalStorageService/locastorage.service';
import { MSALserviceService } from 'src/app/Services/MSALservice/msalservice.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';

@Component({
  selector: 'app-reassign-overtimegroup',
  templateUrl: './reassign-overtimegroup.component.html',
  styleUrls: ['./reassign-overtimegroup.component.css'],
})
export class ReassignOvertimegroupComponent implements OnInit {
  emloyeeData: any = [];
  userData: any = [];
  modalPopTitle = {
    title: '',
    type: '',
  };

  @Input() isReassignOvertimeGroup: boolean = false;
  @Input() title: any;
  @Output() clean = new EventEmitter();
  @Output() isSuccess = new EventEmitter();
  @ViewChild('closeReassignOvertimeGroupModal')
  closeReassignOvertimeGroupModal!: ElementRef;

  modalTitle: any = '';
  demographData: any;

  accHours: any;
  recordId: any;
  loggedInUserATID: string = '';
  deptId: any;
  jobCodeId: any;
  shift: any;
  ReassignOvertimeGroup!: FormGroup;
  employeeid: any;
  postModel: any;

  constructor(
    private fb: FormBuilder,
    private ot: OverTimeMaintanceService,
    private _sweetAlert: SweetAlertService,
    private _commonService: CommonService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private _msalService: MSALserviceService
  ) {
    this.employeeid = this.localStorageService.getItem('ovtAtid');
    this.accHours = this.localStorageService.getItem('ovtAtidAccHours');
    this.deptId = this.localStorageService.getItem('deptId');
    this.ot.NavigateToDetailByUser(this.employeeid).subscribe((data) => {
      this.demographData = data;
      this.ReassignOvertimeGroup.get('shiftId')?.patchValue(
        this.demographData.shift
      );
      this.ReassignOvertimeGroup.get('jobCodeId')?.patchValue(
        this.demographData.jobcodeid === null
          ? 'GLIS'
          : this.demographData.jobcodeid
      );
      this.ReassignOvertimeGroup.get('deptId')?.patchValue(
        this.demographData.dept === null ? 'GLIS' : this.demographData.dept
      );
      this.ReassignOvertimeGroup.get('lastUpdateBy')?.patchValue(
        this.GetLastUpdatedBy(this._msalService.userAtid)
      );
    });
  }

  ngOnInit(): void {
    this.getDepartments();
    this.getClassifications();
    this.getShifts();
    this.CreateReassignOevrtimeGroup();
    this._msalService.getUserName();
  }

  MainNavigate() {
    this.closeReassignOvertimeGroupModal.nativeElement.click();
    this.router.navigate(['Overtime']);
  }

  clear() {
    this.clean.emit('');
    this.closeReassignOvertimeGroupModal.nativeElement.click();
    this.isSuccess.emit(true);
  }

  CreateReassignOevrtimeGroup() {
    this.ReassignOvertimeGroup = this.fb.group({
      employeeid: [this.employeeid],
      shiftId: ['', [Validators.required]],
      jobCodeId: ['', [Validators.required]],
      deptId: ['', [Validators.required]],
      lastUpdateBy: ['', [Validators.required]],
    });
  }

  GetLastUpdatedBy(value: string) {
    let resultString = '';
    if (value != null && value != '') {
      let tempString = value.replace('@alsn.com', '');
      resultString = tempString.trim();
    }
    return resultString;
  }

  ShiftsList: any[] = [];
  ClassificationList: any[] = [];
  DepartmentList: any[] = [];

  getShifts() {
    this._commonService.getShiftsList().subscribe((shifts: any) => {
      this.ShiftsList = shifts;
      this.ShiftsList.splice(3, 1);
    });
  }

  getClassifications() {
    this._commonService
      .getClassificationList()
      .subscribe((classifications: any) => {
        this.ClassificationList = classifications;
      });
  }

  getDepartments() {
    this._commonService.getDepartmentList().subscribe((departments: any) => {
      this.DepartmentList = departments;
    });
  }

  onSubmit() {
    this.ot.reassignOvertimeGroup(this.ReassignOvertimeGroup.value).subscribe(
      (_res) => {
        this._sweetAlert.successAlert(
          'top-end',
          'success',
          'This employee has been reassigned to a new Overtime Group successfully.',
          false,
          1500
        );
        this.clear();
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 406) {
          let errorMsg = _error.error.replace(
            'Error: InvalidDataException:',
            ''
          );
          this._sweetAlert.validationErrorMessage(
            errorMsg,
            'warning',
            false,
            '#3085d6',
            'Ok'
          );
        } else {
          this._sweetAlert.validationError(
            'error',
            'ERROR',
            'An Error occured while processing. Please try again later.'
          );
        }
      }
    );
  }
}
