import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AnnouncementService } from 'src/app/Services/Admin/Announcement/announcement.service';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.css'],
})
export class AnnouncementComponent implements OnInit {
  public loading = false;
  showAdd: boolean = false;
  showEdit: boolean = false;
  showDelete: boolean = false;
  TotalApplicationCount: any;
  UserPage: number = 1;
  Applicationpage: number = 1;
  modalId: string = '';
  filterData: any = {};
  applicationData: any[] = [];
  selectedAnnouncement: any;
  showButton: boolean = false;
  userInformation: any;
  isApplicationDataExists: boolean = false;
  views: any[] = [];

  disablededit = false;
  disabledcancel = false;
  disableddelete = false;

  filterConfig: any = [
    {
      label: 'Announcements Group:',
      labelcode: 'groupid',
      type: 'dropdown',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'GROUPID',
      width: '200px',
      runClassification: false,
    },
    {
      label: 'Start Date',
      labelcode: 'DateActive',
      type: 'date',
      data: [],
      default: 'All',
      controlName: 'SEARCH_MM',
      width: '200px',
      runClassification: false,
    },
  ];

  AnnouncementPermissions: any;

  constructor(
    private _commonApplicationService: CommonService,
    public _userInfo: UsergroupService,
    private _announcementService: AnnouncementService,
    private _sweetAlertService: SweetAlertService
  ) {
    this.getUserInfo();
  }

  ngOnInit(): void {
    this.getGroups();
    this.filterData['SEARCH_MM'] = this._commonApplicationService.formatDate(
      new Date(new Date().setHours(0, 0, 0, 0))
    );
    this.SearchAnnouncements();
  }

  getGroups() {
    this._announcementService.GetGroups().subscribe((res) => {
      this.AnnouncementPermissions = res;
      for (var i = 0; i < this.AnnouncementPermissions.length; i++) {
        this.AnnouncementPermissions[i].AllowToView = false;
      }
      const elements = res || [];
      const mapElement = elements.map(
        (element: { groupid: any; groupname: any }) => ({
          id: element.groupid,
          description: element.groupname,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].labelcode === 'groupid') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  getUserInfo() {
    this._userInfo.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'ADMIN',
        'ANNOUNCEMENTS'
      );
    });
  }

  CorrectAnnouncement() {
    this.showEdit = true;
    this.modalId = '#CorrectAnnouncement';
  }
  AddAnnouncement() {
    this.showAdd = true;
    this.modalId = '#AddAnnouncement';
  }
  DeleteAnnouncement() {
    this.showDelete = true;
    this.modalId = '#DeleteAnnouncement';
  }
  SearchAnnouncements() {
    this.loading = true;
    let queryParams = this.filterData;
    Object.keys(queryParams).forEach(
      (k) =>
        !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]
    );
    this._announcementService.AnnouncementSearch(queryParams).subscribe(
      (data) => {
        this.applicationData = data;
        for (var i = 0; i < this.applicationData.length; i++) {
          this.applicationData[i].groupidArray = this.applicationData[i].groupid
            .split(',')
            .map(String);
        }
        this.isApplicationDataExists =
          this.applicationData.length === 0 ? true : false;
        this.Applicationpage = 1;
        this.TotalApplicationCount = data.length;
        this.loading = false;
        this.disablededit = true;
        this.disabledcancel = true;
        this.disableddelete = true;
      },
      (_error: HttpErrorResponse) => {
        this.loading = false;
        this.isApplicationDataExists = true;
        if (_error.status === 500) {
          let errorMsg = _error.error.replace(
            'GetAnnouncements - Generic Exception:',
            ''
          );
          this._sweetAlertService.validationError(
            'error',
            'ERROR',
            `${errorMsg}`
          );
        } else {
          return this._sweetAlertService.InternalServerError500(
            'error',
            'Error',
            'Something happened. Please try agin later'
          );
        }
      }
    );
  }
  SelectAnnouncement(i: number, announcementObj: any) {
    for (let index = 0; index < this.applicationData.length; index++) {
      if (
        this.applicationData[index].announcementid ===
        announcementObj['announcementid']
      ) {
        this.selectedAnnouncement = this.applicationData[index];
        this.showButton = true;
      }
    }
  }

  getbyenter(event: any) {}
  getFilterValue(event: any) {
    this.filterData = event;
  }

  cleanEmp(event: any) {}

  /*Refresh search section*/
  CheckAdd(event: any) {
    this.SearchAnnouncements();
    this.showButton = false;
  }

  CheckEdit(event: any) {
    this.SearchAnnouncements();
    this.showButton = false;
  }
  CheckDelete(event: any) {
    this.SearchAnnouncements();
    this.showButton = false;
  }

  @ViewChild('AnnouncementExportTable', { read: ElementRef, static: false })
  AnnouncementExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.AnnouncementExportTable,
        `${ApplicationEnum.ANNOUNCEMENT}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.applicationData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.applicationData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
