import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';


@Injectable({
  providedIn: 'root'
})
export class HolidayDataService {
  commonUtility = new CommonUtility();
  private readonly api_addHolidayData = '/HolidayDataAdd';
  private readonly api_deleteHolidayData = '/HolidayDataDelete';
  private readonly api_updateHolidayData = '/HolidayDataUpdate';
  private readonly api_getHolidayData = '/HolidayDataSearch?month=';


  constructor(
    private _http: HttpClient,
    private appCom: MSALserviceService
  ) { }

  getHolidayData(month: string, year: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getHolidayData + month + '&year=' + year,
      { headers }
    );
  }

  addHolidayData(holidayData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_addHolidayData,
      holidayData,
      { headers }
    );
  }

  editHolidayData(holidayData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.tableMaintenanceURL}` + this.api_updateHolidayData,
      holidayData,
      { headers }
    );
  }

  deleteHolidayData(holidayData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_deleteHolidayData,
      holidayData,
      { headers }
    );
  }
}
