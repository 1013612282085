import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { ShiftPreferenceService } from 'src/app/Services/Applications/ShiftPreference/shift-preference.service';
import { TransferAgreementService } from 'src/app/Services/Applications/TransferAgreementService/transfer-agreement.service';
import { DateService } from 'src/app/Services/Date/date.service';
import { RuleEngineService } from 'src/app/Services/Ruleengine/rule-engine.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';
import { UserRoles } from 'src/enum/userroles';
import { userData } from 'src/models/userdata';
import { UserInfo } from 'src/models/userinfo';

@Component({
  selector: 'app-shift-preference',
  templateUrl: './shift-preference.component.html',
  styleUrls: ['./shift-preference.component.css'],
})
export class ShiftPreferenceComponent implements OnInit {
  public loading: boolean = false;
  ApplicationData: any = [];
  userData: any = [];
  modalPopTitle = {
    title: '',
    type: '',
  };
  employeeForm!: FormGroup;
  selectedEmp: any;
  isEditButton: boolean = false;
  isAddButton: boolean = false;
  isEdit: boolean = false;
  isCancel: boolean = false;
  modalTitle: any = '';
  isAdd: boolean = false;
  AppType: string = 'SP';
  modalId: string | any;
  typeSelected: any;
  screenType: any = [];
  isApplication: boolean = false;
  searchKey: string = '';
  isDeleted: boolean = false;
  showFilter: boolean = true;
  applicationData: any = [];
  LastUpdatedBy: string = '';
  views: any[] = [];
  userInformation!: UserInfo; // Model for UserInfo
  usersData!: userData; // Model for UAW user details

  userRole: UserRoles = UserRoles.PRODUCTIONUSER; // Enum for userrole
  isUserListDataEmpty: boolean = false;
  isExistingRecordsDatalength: boolean = false;

  disablededit = false;
  disabledcancel = false;
  disableddelete = false;

  EditButtonDisabled1!: boolean;
  DeleteButtonDisabled1!: boolean;
  CancelButtonDisabled1!: boolean;

  EditButtonDisabled!: boolean; // checks if status is Cancelled
  //CancelButtonDisabled!: boolean; // checks if status is Cancelled

  noOfOpenApplications!: number; /* will show how many applications user has open*/

  isLoggedInUserAdmin: boolean = false; /* stores the bool value if admin*/
  AdminAtid: string = '';

  TotalApplicationCount!: number;
  Applicationpage: number = 1;

  TotalUserCount!: number;
  Userpage: number = 1;

  isAddButtonEnabled: boolean = true;
  ruleEngineValidation: boolean = false;
  ruleEngineMessage: any;

  filterText: string = 'normal';
  filterConfig: any = [
    {
      label: 'Emp Id',
      type: 'input',
      data: [],
      placeholder: 'Emp Id',
      default: 'All',
      controlName: 'EmployeeId',
      width: '80px',
      runClassification: false,
    },
    {
      label: 'Last Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'lastName',
      width: '80px',
      runClassification: false,
    },
    {
      label: 'First Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'firstName',
      width: '80px',
      runClassification: false,
    },

    {
      label: 'Status',
      type: 'dropdown',
      data: ['option 1', 'option 2'],
      default: 'All',
      controlName: 'appStatusCode',
      width: '80px',
      runClassification: false,
    },
    {
      label: 'Classification',
      type: 'dropdown',
      data: [],
      placeholder: 'Classification',
      default: 'All',
      controlName: 'jobCodeId',
      width: '90px',
      runClassification: true,
    },
    {
      label: 'Shift Pref. Group',
      type: 'dropdown',
      data: [],
      placeholder: 'Shift Preference Group:',
      default: 'All',
      controlName: 'plantid',
      width: '110px',
      runClassification: false,
    },
    {
      label: 'From',
      type: 'date',
      data: ['option 1', 'option 2'],
      default: 'All',
      controlName: 'fromDate',
      width: '140px',
      runClassification: false,
    },
    {
      label: 'To',
      type: 'date',
      data: ['option 1', 'option 2'],
      default: 'All',
      controlName: 'toDate',
      width: '140px',
      runClassification: false,
    },
  ];
  filterConfigAdd: any = [
    {
      label: 'Last Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'lastName',
      width: '120px',
    },
    {
      label: 'First Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'firstName',
      width: '120px',
    },
    {
      label: 'Employee Id',
      type: 'input',
      data: [],
      placeholder: 'Employee Id',
      default: 'All',
      controlName: 'EmployeeId',
      width: '120px',
    },
  ];
  filterData: any = {};
  filterDataUser: any = {};
  constructor(
    private fb: FormBuilder,
    private _dateService: DateService,
    private _transferAgreementService: TransferAgreementService,
    private _shiftApplicationPreference: ShiftPreferenceService,
    private _commonApplicationService: CommonService,
    private sweetAlert: SweetAlertService,
    public _userInfo: UsergroupService,
    public _ruleEngine: RuleEngineService
  ) {}

  ngOnInit(): void {
    this.EditButtonDisabled1 = true;
    this.CancelButtonDisabled1 = true;
    this.DeleteButtonDisabled1 = true;
    this.screenType = [
      { Id: 1, Name: 'Application' },
      { Id: 2, Name: 'User' },
    ];
    this.typeSelected = 1;
    this.getStatus();
    this.getClassifications();
    this.getUserRoleDescription();
    this.getPlantGroups();
  }
  //apis

  //Function to get logged in user's role and details
  getUserRoleDescription() {
    this._userInfo.getUserInfo().subscribe((data: any) => {
      //this.userRole = data["groupDescription"] === UserRoles.HRPLUS ? UserRoles.HRPLUS : UserRoles.PRODUCTIONUSER;
      if (
        data['groupDescription'] === UserRoles.HRPLUS ||
        data['groupDescription'] === UserRoles.COMMITTEE
      ) {
        this.userRole = UserRoles.HRPLUS;
      } else {
        this.userRole = UserRoles.PRODUCTIONUSER;
      }
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'APPLICATIONS',
        'SHIFTPREFERENCE'
      );
      this._transferAgreementService.atid.next(this.userInformation.userName);
      this.AdminAtid = this.userInformation.userName;
      this.LastUpdatedBy = this.userInformation.userName;
      if (this.userRole === UserRoles.PRODUCTIONUSER) {
        this.searchApplication();
        this._transferAgreementService
          .searchFilterApplicationUser({
            employeeId: this.userInformation.employeeId,
          })
          .subscribe((res) => {
            this.userData = res;
            this.usersData = res[0];
            if (this.userData != undefined && this.userData.length > 0) {
              this.selectedEmp = this.userData[0];
              this.selectedEmp.selected = true;
            }
          });
      }
    });
  }

  //Checks if logged in user is Admin
  isAdmin(): boolean {
    if (this.userRole === UserRoles.HRPLUS) {
      this.isLoggedInUserAdmin = true;
    } else {
      this.isLoggedInUserAdmin = false;
    }
    return this.userRole === UserRoles.HRPLUS;
  }
  getStatus() {
    const elements: any = [
      {
        statusId: 'OPEN',
        statusDescription: 'OPEN',
      },
      {
        statusId: 'CLOSED',
        statusDescription: 'CLOSED',
      },
    ];
    const mapElement = elements.map(
      (element: { statusId: string; statusDescription: string }) => ({
        id: element.statusId,
        description: element.statusDescription,
      })
    );
    for (let index = 0; index < this.filterConfig.length; index++) {
      if (this.filterConfig[index].label === 'Status') {
        this.filterConfig[index].data = mapElement;
      }
    }
  }

  getPlantGroups() {
    this._commonApplicationService.GetPlantGroups().subscribe((res) => {
      const elements = res || [];
      const mapElement = elements.map(
        (element: { plantGroupId: any; plantGroupDescription: any }) => ({
          id: element.plantGroupId,
          description: element.plantGroupDescription,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Shift Preference Group') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  getClassifications() {
    this._commonApplicationService.GetClassifications().subscribe((res) => {
      const elements = res || [];
      const mapElement = elements.map(
        (element: {
          jobCodeId: any;
          jobCodeDescription: any;
          classificationType: string;
        }) => ({
          id: element.jobCodeId,
          description: element.jobCodeDescription,
          classificationType: element.classificationType,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Classification') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  getShifts() {
    this._commonApplicationService.getShiftsList().subscribe((res) => {
      const elements = res || [];
      const mapElement = elements.map(
        (element: { shiftId: any; shiftDescription: any }) => ({
          id: element.shiftId,
          description: element.shiftDescription,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Shift') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  //For application - HRPLUS
  selectEmp(idx: number, employeeId: any, appId: string) {
    this.disablededit = false;
    this.disabledcancel = false;
    this.disableddelete = false;
    // this._shiftApplicationPreference.ShiftPreferenceSPSearchApplications({ appId: appId }).subscribe((data: any) => {
    for (let index = 0; index < this.ApplicationData.length; index++) {
      if (
        this.ApplicationData[index].employeeId === employeeId &&
        this.ApplicationData[index].appId === appId
      ) {
        this.selectedEmp = this.ApplicationData[index];
      }
    }

    if (this.selectedEmp['appStatusCode'].includes('CLOSED')) {
      this.disablededit = false;
      this.disableddelete = false;
      this.disabledcancel = true;
    } else if (this.selectedEmp['appStatusCode'].includes('CANCELLED')) {
      //this.returnstatus = true;
      // this.CancelButtonDisabled = true;
      this.EditButtonDisabled = true;
      this.disabledcancel = true;
      this.disablededit = true;
    } else {
      //  this.returnstatus = false;
      this.disablededit = false;
      this.disabledcancel = false;
      this.disableddelete = false;
    }
    //checking if the appstatus of the selected record is cancelled
    // if (this.selectedEmp["appStatusCode"].includes("CANCELLED")) {
    //   this.CancelButtonDisabled = true;
    //   this.EditButtonDisabled = true;
    // } else {
    //   this.CancelButtonDisabled = false;
    //   this.EditButtonDisabled = false;
    // }
    // });
  }
  //For application - HRPLUS
  selectUserEmp(idx: number) {
    this.applicationData[idx].selected = true;
    // for (let index = 0; index < this.applicationData.length; index++) {
    //   this.applicationData[index].selected = false;
    // }
    this.applicationData[idx].selected = true;
    this.selectedEmp = this.applicationData[idx];

    //checking if the appstatus of the selected record is cancelled
    // if (this.selectedEmp["appStatusCode"].includes("CANCELLED")) {
    //   this.CancelButtonDisabled = true;
    // } else {
    //   this.CancelButtonDisabled = false;
    // }
  }

  //For User - UAW
  selectUser(idx: number, employeeId: string) {
    // this.userData[idx].selected = true;
    // for (let index = 0; index < this.userData.length; index++) {
    //   this.userData[index].selected = false;
    // }
    // this.userData[idx].selected = true;
    // this.selectedEmp = this.userData[idx];
    for (let index = 0; index < this.userData.length; index++) {
      if (this.userData[index].employeeId === employeeId) {
        this.selectedEmp = this.userData[index];
      }
    }
  }
  //Function searches users
  SearchByUser(e: any) {
    if (e && e.target && e.target.value) {
      this.searchKey = (e && e.target && e.target.value) || '';
    } else {
      this.searchKey = e;
    }
    if (this.isApplication) {
      this._transferAgreementService
        .SearchUser(this.searchKey)
        .subscribe((res) => {
          this.userData = res;
          this.isAddButton = true;
          if (this.userData.length === 0) {
            this.isAddButton = false;
            this.sweetAlert.simpleAlert(
              'error',
              'Oops',
              'No result found for this search.'
            );
          }
        });
    } else {
      this._transferAgreementService
        .SearchApplicationByUser(this.searchKey)
        .subscribe((res) => {
          this.ApplicationData = res;
          //this.recordStatus = res[]
          this.isEditButton = true;
          if (this.ApplicationData.length === 0) {
            this.isEditButton = false;
            this.sweetAlert.simpleAlert(
              'error',
              'Oops',
              'No result found for this search.'
            );
          }
        });
    }
  }
  // Function to add employee - HRPLUS
  addEmployee(idx: number, employeeId: string) {
    this.selectUser(idx, employeeId);
    if (this.selectedEmp) {
      this.isAdd = true;
      this.modalId = '#addEmployee';
    } else {
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }
  //Resets the form
  clear() {
    this.employeeForm.reset();
  }
  //Function to correct the application - HRPLUS
  edit() {
    if (this.selectedEmp && this.userInformation) {
      this.modalTitle = 'Edit Employee';
      this.isEdit = true;
      this.modalId = '#editEmp';
    } else {
      this.modalId = '';
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'Please select employee to edit'
      );
    }
  }

  //Function to delete the application - HRPLUS
  deleteApplication() {
    if (this.selectedEmp) {
      this.modalId = '#deleteApplicationModal';
    } else {
      this.modalId = '';
      alert('No Employee Selected');
    }
  }

  //Resets the form after deletion of application - HRPLUS
  CheckDelete(e: any) {
    this.isDeleted = e;
    if (this.isDeleted === true && this.searchKey) {
      this.ApplicationData = this.SearchByUser(this.searchKey);
    } else {
      this.search();
    }
  }
  //Resets the form after correction of application - HRPLUS
  CheckEdit(e: any) {
    this.isEdit = e;
    if (this.isEdit === true && this.searchKey) {
      this.ApplicationData = this.SearchByUser(this.searchKey);
    } else {
      this.search();
    }
  }
  //Resets the form after cancellation of application - HRPLUS
  CheckCancel(e: any) {
    this.isCancel = e;
    if (this.isCancel === true && this.searchKey) {
      this.ApplicationData = this.SearchByUser(this.searchKey);
    } else {
      this.search();
    }
  }
  //Function to cancel and application application - HRPLUS
  // cancelApplication() {
  //   if (this.selectedEmp && this.userInformation) {
  //     if (this.selectedEmp && this.selectedEmp.appId) {
  //       this.td.appId.next(this.selectedEmp.appId);
  //       this.td.empsignDate.next(this.selectedEmp.empSignDate);
  //       this._transferAgreementService.applicationAtid.next(this.selectedEmp.atid)
  //     } else {
  //       this.sweetAlert.simpleAlert('errror', 'App Id MIssing', '');
  //     }
  //     this.modalId = '#cancelApplicationModal';
  //   } else {
  //     this.modalId = '';
  //     alert('No Employee Selected');
  //   }
  // }
  //Search-By grid - HRPLUS
  onAppTypeSelected(val: number) {
    if (val === 2) {
      this.isApplication = true;
      this.showFilter = false;
      this.ApplicationData = null;
      this.filterData = {};
      this.userData = null;
      this.isExistingRecordsDatalength = false;
    }
    if (val === 1) {
      this.isApplication = !this.isApplication;
      this.showFilter = true;
      this.userData = null;
      this.filterDataUser = {};
      this.isUserListDataEmpty = false;

      this.EditButtonDisabled1 = true;
      this.CancelButtonDisabled1 = true;
      this.DeleteButtonDisabled1 = true;
    }
  }

  //Function to check if  application is selected - HRPLUS
  isApplicationSelected() {
    return $('input[name=application]:checked').length > 0;
  }

  cleanEmp(event: any) {}

  //Filter - HRPLUS
  showFilters() {
    if (this.showFilter) {
      this.showFilter = false;
      this.filterText = 'normal';
    } else {
      this.showFilter = true;
      this.filterText = 'bold';
    }
  }
  //Filter - HRPLUS
  getFilterValue(event: any) {
    this.filterData = event;
  }
  //Filter - HRPLUS
  getFilterDataUser(event: any) {
    this.filterDataUser = event;
  }
  //Search by Application - HRPLUS/UAW
  search() {
    if (this.userRole === UserRoles.PRODUCTIONUSER) {
      this.searchApplication();
      return;
    }
    this.loading = true;
    let queryParams = this.filterData;
    Object.keys(queryParams).forEach(
      (k) =>
        !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]
    );
    queryParams.appType = this.AppType;
    this._shiftApplicationPreference
      .ShiftPreferenceSPSearchApplications(queryParams)
      .subscribe((data) => {
        this.ApplicationData = data;

        for (var i = 0; i < this.ApplicationData.length; i++) {
          const effectiveDate = this._dateService.calculateEffectiveDate(
            this.ApplicationData[i].empSignDate,
            this.ApplicationData[i].specialSP
          );
          this.ApplicationData[i].EffectiveDate = effectiveDate;
          if (
            this.ApplicationData[i].appStatusCode.includes('OPEN') &&
            this.ApplicationData[i].specialSP === '0' &&
            this._dateService.IsApplicationTimely(
              this.ApplicationData[i].EffectiveDate
            )
          ) {
            this.ApplicationData[i].appStatusCode =
              'PENDING ' + this.ApplicationData[i].appStatusCode;
          }
          if (this.ApplicationData[i].empSignDate != null) {
            this.ApplicationData[i].empSignDateSort = new Date(
              this.ApplicationData[i].empSignDate
                .replace(/-/g, '/')
                .replace(/T.+/, '')
            ).toISOString();
          }
          if (this.ApplicationData[i].EffectiveDate != null) {
            this.ApplicationData[i].EffectiveDateSort = new Date(
              this.ApplicationData[i].EffectiveDate
            ).toISOString();
          }
        }

        this.isExistingRecordsDatalength =
          this.ApplicationData.length === 0 ? true : false;
        this.TotalApplicationCount = data.length;
        this.EditButtonDisabled1 = false;
        this.DeleteButtonDisabled1 = false;
        this.disablededit = true;
        this.disabledcancel = true;
        this.disableddelete = true;
        this.CancelButtonDisabled1 = false;
        this.Applicationpage = 1;
        this.loading = false;
      });
  }
  searchbyUser() {
    this.loading = true;
    let queryParams = this.filterDataUser;
    Object.keys(queryParams).forEach(
      (k) =>
        !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]
    );
    queryParams.AppType = 'SP';
    this._shiftApplicationPreference
      .ShiftPreferenceSPSearchUsers(queryParams)
      .subscribe((data) => {
        this.userData = data;
        this.TotalUserCount = data.length;
        this.isUserListDataEmpty = this.userData.length === 0 ? true : false;
        this.Userpage = 1;
        this.TotalUserCount = data.length;
        this.loading = false;
      });
  }

  //Function search for UAW user's applications
  searchApplication() {
    this.loading = true;
    let queryParams = {
      employeeId: this.userInformation.employeeId,
      appStatusCode: '',
      AppType: 'SP',
    };
    this._shiftApplicationPreference
      .ShiftPreferenceSPSearchApplications(queryParams)
      .subscribe((data) => {
        this.applicationData = data;
        for (var i = 0; i < this.applicationData.length; i++) {
          const effectiveDate = this._dateService.calculateEffectiveDate(
            this.applicationData[i].empSignDate,
            this.applicationData[i].specialSP
          );
          this.applicationData[i].EffectiveDate = effectiveDate;
          if (
            this.applicationData[i].appStatusCode.includes('OPEN') &&
            this.applicationData[i].specialSP === '0' &&
            this._dateService.IsApplicationTimely(
              this.applicationData[i].EffectiveDate
            )
          ) {
            this.applicationData[i].appStatusCode =
              'PENDING ' + this.applicationData[i].appStatusCode;
          }
        }
        this._ruleEngine
          .addTAApplicationRule(this.userInformation.employeeId)
          .subscribe((data) => {
            this.ruleEngineValidation = data['status'];
            this.ruleEngineMessage = data['errors'];
            if (this.ruleEngineValidation === false) {
              this.isAddButtonEnabled = false;
            }
          });
        this.noOfOpenApplications = data.length;
        this.loading = false;
      });
  }

  //Resets the list of user applications
  CheckAdd(e: any) {
    this.isAdd = e;
    if (this.isAdd === false) {
      this.applicationData = this.searchApplication();
      this.userData = this.searchbyUser();
    } else {
      if (this.userRole === UserRoles.PRODUCTIONUSER) {
        this.searchApplication();
        return;
      }
      this.searchbyUser();
    }
  }
  // UAW user Add
  addApplication() {
    if (this.userInformation) {
      this.isAdd = true;
      this.modalId = '#addEmployee';
    } else {
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }
  getbyenter(e: any) {
    this.loading = true;
    // e.AppType = 'SP'
    let searchData;
    if (this.filterData != null) {
      searchData = { ...e, ...this.filterData };
    }
    this._shiftApplicationPreference
      .ShiftPreferenceSPSearchApplications(searchData)
      .subscribe((data) => {
        this.ApplicationData = data;
        for (var i = 0; i < this.ApplicationData.length; i++) {
          const effectiveDate = this._dateService.calculateEffectiveDate(
            this.ApplicationData[i].empSignDate,
            this.ApplicationData[i].specialSP
          );
          this.ApplicationData[i].EffectiveDate = effectiveDate;
          if (
            this.ApplicationData[i].appStatusCode.includes('OPEN') &&
            this.ApplicationData[i].specialSP === '0' &&
            this._dateService.IsApplicationTimely(
              this.ApplicationData[i].EffectiveDate
            )
          ) {
            this.ApplicationData[i].appStatusCode =
              'PENDING ' + this.ApplicationData[i].appStatusCode;
          }
        }
        this.isExistingRecordsDatalength =
          this.ApplicationData.length === 0 ? true : false;
        this.Applicationpage = 1;
        this.EditButtonDisabled1 = false;
        this.DeleteButtonDisabled1 = false;
        this.disablededit = true;
        this.disabledcancel = true;
        this.disableddelete = true;
        this.CancelButtonDisabled1 = false;
        this.TotalApplicationCount = data.length;
        this.loading = false;
      });
  }
  getbyenterforuser(e: any) {
    this.loading = true;
    e.AppType = 'SP';
    this._shiftApplicationPreference
      .ShiftPreferenceSPSearchUsers(e)
      .subscribe((data) => {
        this.userData = data;
        this.isUserListDataEmpty = this.userData.length === 0 ? true : false;
        this.Userpage = 1;
        this.TotalUserCount = data.length;
        this.loading = false;
      });
  }

  @ViewChild('content') content!: ElementRef;
  print() {
    var allLinks = $('head').clone().find('script').remove().end().html();
    var keepColors =
      '<style>body {-webkit-print-color-adjust: exact !important; }</style>';
    var innerContents = document.getElementById('content')?.innerHTML;
    var popupWinindow = window.open('Print', '_blank');
    popupWinindow?.document.open();
    popupWinindow?.document.write(
      '<html><head>' +
        keepColors +
        allLinks +
        '</head><body onload="window.print();window.close();">' +
        innerContents +
        '</html>'
    );
    popupWinindow?.document.close();
  }

  @ViewChild('ShiftPreferenceExportTable', { read: ElementRef, static: false })
  ShiftPreferenceExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.ShiftPreferenceExportTable,
        `${ApplicationEnum.SHIFTPREFERENCE}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    //console.log("test1", this.ApplicationData);
    if (dataType === 'string') {
      this.ApplicationData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.ApplicationData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
