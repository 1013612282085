import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class ShiftPreferenceDataService {

  commonUtility = new CommonUtility();
  private readonly api_getShiftPreferenceData = '/ShiftPreferenceDataGetData';
  private readonly api_addShiftPreferenceData = '/ShiftPreferenceDataAdd';
  private readonly api_deleteShiftPreferenceData = '/ShiftPreferenceDataDelete?plantGroupId=';
  private readonly api_updateShiftPreferenceData = '/ShiftPreferenceDataUpdate';
  constructor(
    private _http: HttpClient, 
    private appCom: MSALserviceService
  ) { }

  getPlantGroupData(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getShiftPreferenceData,
      { headers }
    );
  }
  addPlantGroupData(plantGroupData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_addShiftPreferenceData,
      plantGroupData,
      { headers }
    );
  }
  editPlantGroupData(plantGroupData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.tableMaintenanceURL}` + this.api_updateShiftPreferenceData,
      plantGroupData,
      { headers }
    );
  }
  deletePlantGroupData(plantGroupId: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.delete<any>(
      `${environment.tableMaintenanceURL}` + this.api_deleteShiftPreferenceData + plantGroupId,
      { headers }
    );
  }
}
