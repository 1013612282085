import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';
import { CommonUtility } from 'src/common/commonUtility'

@Injectable({
  providedIn: 'root',
})
export class ToolAndDiePlantService {
  private readonly api_Delete150_Path = '/DeleteApplication/';
  private readonly api_SearchApplicationByUser = '/ToolAndDieMaker150SearchApplicationByUser?key='; // not used anywhere
  private readonly Edit150 = '/ToolAndDieMaker150Correct';
  private readonly getShifts = '/GetShifts';
  private readonly getPlants = '/GetPlants';
  private readonly getDeptById = '/GetDepartmentsByPlantId/';
  private readonly api_SearchUser = '/ToolAndDieMaker150SearchUser';
  private readonly api_Add150_Path = '/ToolAndDieMaker150Add';
  private readonly api_GetSearchApplication = '/ToolAndDieMaker150SearchApplication';
  private readonly AddApplicationRule = '/AddApplicationRule?employeeId=';
  private readonly api_cancelApplication = '/CancelApplication';

  appId = new Subject();
  empsignDate = new Subject();
  atid = new Subject();
  commonUtility = new CommonUtility();
  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  Delete150Application(app150Id: number): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.delete<any>(
      `${environment.applicationsURL}` + this.api_Delete150_Path + app150Id,
      { headers }
    );
  }

  SearchApplicationByUser(searchKey: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.applicationsURL}` +
      this.api_SearchApplicationByUser +
      searchKey,
      { headers }
    );
  }

  Edit150Application(app150: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.put<any>(
      `${environment.applicationsURL}` + this.Edit150,
      app150,
      { headers }
    );
  }

  Cancel150Application(obj: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.put<any>(
      `${environment.applicationsURL}` + this.api_cancelApplication,
      obj,
      { headers }
    );
  }

  SearchUser(searchKey: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.applicationsURL}` +
      this.api_SearchUser + searchKey,
      { headers }
    );
  }

  getShiftsList(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.applicationsURL}` + this.getShifts,
      { headers }
    );
  }

  getPlantsList(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.applicationsURL}` + this.getPlants,
      { headers }
    );
  }

  getDeptbyPlantId(plantId: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.applicationsURL}` + this.getDeptById + plantId,
      { headers }
    );
  }

  Add150Application(addTD: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` + this.api_Add150_Path,
      addTD,
      { headers }
    );
  }

  searchFilterApplication(formData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` + this.api_GetSearchApplication,
      formData,
      { headers }
    );
  }

  searchFilterApplicationUser(formData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` + this.api_SearchUser,
      formData,
      { headers }
    );
  }

  getRulesEngine(employeeId: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.rulesEngineURL}` +
      this.AddApplicationRule + employeeId,
      { headers }
    );
  }
}
