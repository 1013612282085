import { formatDate } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { DemographicDataService } from 'src/app/Services/TableMaintenance/DemographicData/demographic-data.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';

@Component({
  selector: 'app-demographic-data',
  templateUrl: './demographic-data.component.html',
  styleUrls: ['./demographic-data.component.css'],
})
export class DemographicDataComponent {
  public loading: boolean = false;
  showEdit: boolean = false;
  showDelete: boolean = false;
  TotalApplicationCount: any;
  UserPage: number = 1;
  Applicationpage: number = 1;
  modalId: string = '';
  filterData: any = {};
  demographicSearchData: any[] = [];
  selectedDemographic: any;
  showButton: boolean = false;
  userInformation: any;
  views: any[] = [];
  isApplicationDataEmpty: boolean = false;
  filterConfig: any = [
    {
      label: 'Last Name',
      type: 'input',
      data: [],
      placeholder: 'Enter last name',
      default: 'All',
      controlName: 'Last_Name',
      runClassification: false,
    },
    {
      label: 'First Name',
      type: 'input',
      data: [],
      placeholder: 'Enter first name',
      default: 'All',
      controlName: 'First_Name',
      runClassification: false,
    },
    {
      label: 'Employee Id',
      type: 'input',
      data: [],
      placeholder: 'Enter Employee Id',
      default: 'All',
      controlName: 'EMPLOYEEID',
      runClassification: false,
    },
  ];

  constructor(
    private dds: DemographicDataService,
    private _user: UsergroupService,
    private _commonApplicationService: CommonService
  ) {
    this.getUserInfo();
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'TABLEMAINTENANCE',
        'DEMOGRAPHICDATA'
      );
    });
  }

  selectDemographic(idx: number, employeeid: string) {
    for (let index = 0; index < this.demographicSearchData.length; index++) {
      if (this.demographicSearchData[index].employeeid === employeeid) {
        this.selectedDemographic = this.demographicSearchData[index];
        this.showButton = true;
      }
    }
    this.showEditModal();
  }

  selectDeleteDemographic(idx: number, employeeid: string) {
    for (let index = 0; index < this.demographicSearchData.length; index++) {
      if (this.demographicSearchData[index].employeeid === employeeid) {
        this.selectedDemographic = this.demographicSearchData[index];
        this.showButton = true;
      }
    }
    this.showDeleteModal();
  }

  showEditModal() {
    this.showEdit = true;
    this.modalId = '#editEmp';
  }

  showDeleteModal() {
    this.showDelete = true;
    this.modalId = '#deleteEmp';
  }

  CheckEdit(e: any) {
    this.searchClassifications();
    this.showButton = false;
  }
  CheckDelete(e: any) {
    this.searchClassifications();
    this.showButton = false;
  }

  getFilterValue(event: any) {
    this.filterData = event;
  }

  searchClassifications() {
    this.loading = true;
    let queryParams = this.filterData;
    if (Object.keys(this.filterData).length === 0) {
      queryParams.jobCodeId = '';
      queryParams.jobCodeDescription = '';
    }
    //Object.keys(queryParams).forEach((k) => !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]);
    this.dds.searchDemographicData(queryParams).subscribe((res) => {
      this.demographicSearchData = res;
      this.isApplicationDataEmpty =
        this.demographicSearchData.length === 0 ? true : false;
      this.showButton = false;
      this.Applicationpage = 1;
      this.TotalApplicationCount = res.length;
      this.loading = false;
    });
  }

  getbyenter(e: any) {
    this.loading = true;
    this.dds.searchDemographicData(e).subscribe((res: any) => {
      this.demographicSearchData = res;
      this.isApplicationDataEmpty =
        this.demographicSearchData.length === 0 ? true : false;
      this.showButton = false;
      this.Applicationpage = 1;
      this.TotalApplicationCount = res.length;
      this.loading = false;
    });
  }

  @ViewChild('demographicdataTablemaintenanceExportTable', {
    read: ElementRef,
    static: false,
  })
  demographicdataTablemaintenanceExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.demographicdataTablemaintenanceExportTable,
        `${ApplicationEnum.DEPARTMENTDATATABLEMAINTENANCE}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.demographicSearchData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.demographicSearchData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
