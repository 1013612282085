import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  ElementRef,
  EventEmitter,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { OverTimeMaintanceService } from 'src/app/Services/Applications/OvertimeMaintanence/overtime.maintanence.service';
import { DateService } from 'src/app/Services/Date/date.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';

@Component({
  selector: 'app-edit-overtime-maintenance',
  templateUrl: './edit-overtime-maintenance.component.html',
  styleUrls: ['./edit-overtime-maintenance.component.css'],
})
export class EditOvertimeMaintenanceComponent implements OnInit {
  @Input() passAddData: any = [];
  @Input() OverTimeAddValue: any;
  @Input() isEdit: boolean = false;
  @Input() title: any;
  @Input() overtimeGroupName: any;
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;
  employeeForm!: FormGroup;
  @Output() clean = new EventEmitter();
  @Output() isMassAddSuccess = new EventEmitter();

  DateFromMainScreen: any; //tbd
  DataFromChildCompopent: any; //tbd
  OvertimeType: any; //tbd
  OvertimeReason: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];

  MassAddOvertimeRecords: any = []; //tbd
  OriginalMassAddOvertimeRecords: any = []; //tbd
  checkedEmployeeCount: number = 0; //tbd
  checkAllRecords: boolean = false; //tbd

  CreationDate = new Date(); //tbd

  /*Checks validations*/
  DateCheck: boolean = false;
  PaidHoursCheck: boolean = false;
  OvertimeTypeIdCheck: boolean = false;
  OvertimeReasonIdCheck: boolean = false;
  OvertimeCommentCheck: boolean = false;

  DuplicateRecordsFound: boolean = false;
  isArrayLengthZero: boolean = false;

  /*Override Flags*/
  OvertimeDate1MonthEarlierLaterCheck: boolean = false;
  OvertimeTypeRequiesValidations: boolean = false;
  OverrideCheckIfOTisLRCheck: boolean = false;
  OverrideOTisCallinRefuseCheck: boolean = false;

  /*Override Check*/
  OverrideIfDateOlderEarlier1Month: boolean = false;
  OverrideIfDateSelectedIsWeekedForOvertimeType: boolean = false;
  OverrideCheckIfOTisLR: boolean = false;
  OverrideOTisCallinRefuse: boolean = false;

  constructor(
    private _sweetAlertService: SweetAlertService,
    private _overtimeMaintenanceService: OverTimeMaintanceService,
    private changeDetector: ChangeDetectorRef,
    private _commonService: CommonService,
    private _dateService: DateService
  ) {}
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    this.getOvertimeType();
    this.getOvertimeReason();
    this.getHolidayList();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.LoadOvertimeDate(changes);
    this.getHolidayList();
  }

  LoadOvertimeDate(changes: SimpleChanges) {
    this.isArrayLengthZero = false;
    this.DateCheck = false;
    this.PaidHoursCheck = false;
    this.OvertimeTypeIdCheck = false;
    this.OvertimeReasonIdCheck = false;
    this.OvertimeCommentCheck = false;
    this.OvertimeDate1MonthEarlierLaterCheck = false;
    this.OvertimeTypeRequiesValidations = false;
    this.DuplicateRecordsFound = false;
    this.OverrideCheckIfOTisLRCheck = false;
    this.OverrideOTisCallinRefuseCheck = false;

    this.OverrideIfDateOlderEarlier1Month = false;
    this.OverrideIfDateSelectedIsWeekedForOvertimeType = false;
    this.OverrideCheckIfOTisLR = false;
    this.OverrideOTisCallinRefuse = false;

    this.MassAddOvertimeRecords.length = 0;
    for (var i = 0; i < this.passAddData.length; i++) {
      var Index = i;
      var Name =
        this.passAddData[i].lasT_NAME +
        ', ' +
        this.passAddData[i].firsT_NAME +
        ' ' +
        this.passAddData[i].middle;
      var EmployeeId = this.passAddData[i].employeeid;
      var OvertimeGroupId = this.passAddData[i].overtimegroupid;
      var CreatedBy = this.OverTimeAddValue?.createdby;
      var CreatedDate = this.CreationDate;
      var DateOffered = this.OverTimeAddValue?.dateoffered;
      var DateOfWork = this.OverTimeAddValue?.dateofwork;
      var Comment = this.OverTimeAddValue?.freeformcomment;
      var OvertimeReasonId = this.OverTimeAddValue?.overtimereasonid;
      var OvertimeTypeId = this.OverTimeAddValue?.overtimetypeid;
      var PaidHrs = this.OverTimeAddValue?.paidhours;
      var Status = this.OverTimeAddValue?.status;
      var overtimeReasonArrayBasedOnOvertimeType = this.onChangeGetOvertimeType(
        this.OverTimeAddValue?.overtimetypeid
      );

      var obj = {
        index: Index,
        name: Name,
        employeeid: EmployeeId,
        createdby: CreatedBy,
        createddate: CreatedDate,
        overtimegroupid: OvertimeGroupId,
        dateoffered: DateOffered,
        dateofferedflag: false,
        dateofwork: DateOfWork,
        dateofworkflag: false,
        freeformcomment: Comment,
        freeformcommentflag: false,
        overtimereasonid: OvertimeReasonId,
        overtimereasonidflag: false,
        overtimetypeid: OvertimeTypeId,
        overtimetypeidflag: false,
        paidhours: PaidHrs,
        paidhoursflag: false,
        status: Status,
        isCopied: false,
        isSelected: false,
        childCount: 0,
        isDuplicated: false,
        isCommentRequired: false,
        overtimeRequireValidation: false,
        Override: false,
        paidHoursMissing: false,
        overtimeTypeMissing: false,
        overtimeReasonMissing: false,
        DowIsGreaterThanDo: false,
        DateEarlierOrOlderFlag: false,
        callinRefusedFalg: false,
        OvertimeReasonArrayBasedOnOvertimeType:
          overtimeReasonArrayBasedOnOvertimeType,
      };
      this.MassAddOvertimeRecords.push(obj);
      this.OriginalMassAddOvertimeRecords = JSON.parse(
        JSON.stringify(this.MassAddOvertimeRecords)
      );
    }

    if (
      this.MassAddOvertimeRecords.length === 0 ||
      this.MassAddOvertimeRecords[0].overtimetypeid === ''
    ) {
      this.isArrayLengthZero = true;
    } else {
      this.isArrayLengthZero = false;
    }
  }

  /* Adding a new row to the form */
  AddAdditionalOvertime(idx: number) {
    var OvertimeReasonListMassAddRecords = this.onChangeGetOvertimeType(
      this.MassAddOvertimeRecords[idx].overtimetypeid
    );
    var OvertimeReasonLisOriginalMassAddRecords = this.onChangeGetOvertimeType(
      this.OriginalMassAddOvertimeRecords[idx].overtimetypeid
    );

    var newRecord = {
      index: this.MassAddOvertimeRecords[idx].index + 0.1,
      name:
        this.MassAddOvertimeRecords[idx].lasT_NAME +
        ', ' +
        this.MassAddOvertimeRecords[idx].firsT_NAME +
        ' ' +
        this.MassAddOvertimeRecords[idx].middle,
      employeeid: this.MassAddOvertimeRecords[idx].employeeid,
      createdby: this.MassAddOvertimeRecords[idx].createdby,
      createddate: this.MassAddOvertimeRecords[idx].createddate,
      overtimegroupid: this.MassAddOvertimeRecords[idx].overtimegroupid,
      dateoffered: this.MassAddOvertimeRecords[idx].dateoffered,
      dateofwork: this.MassAddOvertimeRecords[idx].dateofwork,
      freeformcomment: this.MassAddOvertimeRecords[idx].freeformcomment,
      overtimereasonid: this.MassAddOvertimeRecords[idx].overtimereasonid,
      overtimetypeid: this.MassAddOvertimeRecords[idx].overtimetypeid,
      paidhours: this.MassAddOvertimeRecords[idx].paidhours,
      isSelected: this.MassAddOvertimeRecords[idx].isSelected,
      status: this.MassAddOvertimeRecords[idx].status,
      isCopied: true,
      dateofferedflag: this.MassAddOvertimeRecords[idx].dateofferedflag,
      dateofworkflag: this.MassAddOvertimeRecords[idx].dateofworkflag,
      freeformcommentflag: this.MassAddOvertimeRecords[idx].freeformcommentflag,
      overtimereasonidflag:
        this.MassAddOvertimeRecords[idx].overtimereasonidflag,
      overtimetypeidflag: this.MassAddOvertimeRecords[idx].overtimetypeidflag,
      paidhoursflag: this.MassAddOvertimeRecords[idx].paidhoursflag,
      childCount: 0,
      isDuplicated: this.MassAddOvertimeRecords[idx].isDuplicated,
      isCommentRequired: this.MassAddOvertimeRecords[idx].isCommentRequired,
      overtimeRequireValidation:
        this.MassAddOvertimeRecords[idx].overtimeRequireValidation,
      paidHoursMissing: this.MassAddOvertimeRecords[idx].paidHoursMissing,
      overtimeTypeMissing: this.MassAddOvertimeRecords[idx].overtimeTypeMissing,
      overtimeReasonMissing:
        this.MassAddOvertimeRecords[idx].overtimeReasonMissing,
      DowIsGreaterThanDo: this.MassAddOvertimeRecords[idx].DowIsGreaterThanDo,
      DateEarlierOrOlderFlag:
        this.MassAddOvertimeRecords[idx].DateEarlierOrOlderFlag,
      Override: this.MassAddOvertimeRecords[idx].Override,
      callinRefusedFalg: this.MassAddOvertimeRecords[idx].callinRefusedFalg,
      OvertimeReasonArrayBasedOnOvertimeType: OvertimeReasonListMassAddRecords,
    };

    var newRecordForOriginal = {
      index: this.OriginalMassAddOvertimeRecords[idx].index + 0.1,
      name: this.OriginalMassAddOvertimeRecords[idx].name,
      employeeid: this.OriginalMassAddOvertimeRecords[idx].employeeid,
      createdby: this.OriginalMassAddOvertimeRecords[idx].createdby,
      createddate: this.OriginalMassAddOvertimeRecords[idx].createddate,
      overtimegroupid: this.OriginalMassAddOvertimeRecords[idx].overtimegroupid,
      dateoffered: this.OriginalMassAddOvertimeRecords[idx].dateoffered,
      dateofwork: this.OriginalMassAddOvertimeRecords[idx].dateofwork,
      freeformcomment: this.OriginalMassAddOvertimeRecords[idx].freeformcomment,
      overtimereasonid:
        this.OriginalMassAddOvertimeRecords[idx].overtimereasonid,
      overtimetypeid: this.OriginalMassAddOvertimeRecords[idx].overtimetypeid,
      paidhours: this.OriginalMassAddOvertimeRecords[idx].paidhours,
      isSelected: this.OriginalMassAddOvertimeRecords[idx].isSelected,
      status: this.OriginalMassAddOvertimeRecords[idx].status,
      isCopied: true,
      dateofferedflag: this.OriginalMassAddOvertimeRecords[idx].dateofferedflag,
      dateofworkflag: this.OriginalMassAddOvertimeRecords[idx].dateofworkflag,
      freeformcommentflag:
        this.OriginalMassAddOvertimeRecords[idx].freeformcommentflag,
      overtimereasonidflag:
        this.OriginalMassAddOvertimeRecords[idx].overtimereasonidflag,
      overtimetypeidflag:
        this.OriginalMassAddOvertimeRecords[idx].overtimetypeidflag,
      paidhoursflag: this.OriginalMassAddOvertimeRecords[idx].paidhoursflag,
      isDuplicated: this.OriginalMassAddOvertimeRecords[idx].isDuplicated,
      isCommentRequired:
        this.OriginalMassAddOvertimeRecords[idx].isCommentRequired,
      overtimeRequireValidation:
        this.OriginalMassAddOvertimeRecords[idx].overtimeRequireValidation,
      paidHoursMissing:
        this.OriginalMassAddOvertimeRecords[idx].paidHoursMissing,
      overtimeTypeMissing:
        this.OriginalMassAddOvertimeRecords[idx].overtimeTypeMissing,
      overtimeReasonMissing:
        this.OriginalMassAddOvertimeRecords[idx].overtimeReasonMissing,
      DowIsGreaterThanDo:
        this.OriginalMassAddOvertimeRecords[idx].DowIsGreaterThanDo,
      DateEarlierOrOlderFlag:
        this.OriginalMassAddOvertimeRecords[idx].DateEarlierOrOlderFlag,
      Override: this.OriginalMassAddOvertimeRecords[idx].Override,
      callinRefusedFalg:
        this.OriginalMassAddOvertimeRecords[idx].callinRefusedFalg,
      OvertimeReasonArrayBasedOnOvertimeType:
        OvertimeReasonLisOriginalMassAddRecords,
    };

    this.MassAddOvertimeRecords[idx].childCount += 1;

    this.MassAddOvertimeRecords.push(newRecord);
    this.OriginalMassAddOvertimeRecords.push(newRecordForOriginal);

    this.MassAddOvertimeRecords.sort((a: any, b: any) => {
      return this.compareIndex(a, b);
    });
    this.OriginalMassAddOvertimeRecords.sort((a: any, b: any) => {
      return this.compareIndex(a, b);
    });
  }

  /*Sorting Algorithm - used to sort the form after addition of new row*/
  compareIndex(a: { index: number }, b: { index: number }): number {
    return a.index < b.index ? -1 : 1;
  }

  /*Deleted the selected row in the form*/
  RemoveAdditionalOvertime(idx: number) {
    var employeeid = this.MassAddOvertimeRecords[idx].employeeid;
    for (var j = 0; j < this.MassAddOvertimeRecords.length; j++) {
      if (
        this.MassAddOvertimeRecords[j].employeeid === employeeid &&
        this.MassAddOvertimeRecords[j].childCount > 0
      ) {
        this.MassAddOvertimeRecords[j].childCount -= 1;
        break;
      }
    }
    this.MassAddOvertimeRecords.splice(idx, 1);
  }

  /* Function which will tick all the checkboxs*/
  selectAllEmployee() {
    this.checkAllRecords = !this.checkAllRecords;
    for (var i = 0; i < this.MassAddOvertimeRecords.length; i++) {
      if (this.checkAllRecords === true) {
        this.MassAddOvertimeRecords[i].isselected = this.checkAllRecords;
      } else {
        this.MassAddOvertimeRecords[i].isselected = this.checkAllRecords;
      }
    }
  }

  /* Function which will tick the selected overtime row*/
  onEmployeeSelect(idx: number) {
    this.MassAddOvertimeRecords[idx].isSelected =
      !this.MassAddOvertimeRecords[idx].isSelected;
  }

  /*On change Functions*/
  /*New Functions created to change value of selected records*/
  onChangeDateOfWork(idx: number, e: any) {
    var dateOfWork = e?.target.value;
    this.MassAddOvertimeRecords[idx].dateofwork = dateOfWork;
    for (var i = 0; i < this.MassAddOvertimeRecords.length; i++) {
      if (this.MassAddOvertimeRecords[idx].isselected === true) {
        if (this.MassAddOvertimeRecords[i].isselected === true) {
          this.MassAddOvertimeRecords[i].dateofwork = dateOfWork;
        }
      }
      if (
        this.MassAddOvertimeRecords[i].dateofwork !=
        this.OriginalMassAddOvertimeRecords[i].dateofwork
      ) {
        this.MassAddOvertimeRecords[i].dateofworkflag = true;
      } else {
        this.MassAddOvertimeRecords[i].dateofworkflag = false;
      }
    }
  }

  onChangePaidHours(idx: number, e: any) {
    var paidhrs = e?.target.value;
    this.MassAddOvertimeRecords[idx].paidhours = parseFloat(paidhrs);
    for (var i = 0; i < this.MassAddOvertimeRecords.length; i++) {
      if (this.MassAddOvertimeRecords[idx].isselected === true) {
        if (this.MassAddOvertimeRecords[i].isselected === true) {
          this.MassAddOvertimeRecords[i].paidhours = parseFloat(paidhrs);
        }
      }
      if (
        this.MassAddOvertimeRecords[i].paidhours !=
        this.OriginalMassAddOvertimeRecords[i].paidhours
      ) {
        this.MassAddOvertimeRecords[i].paidhoursflag = true;
      } else {
        this.MassAddOvertimeRecords[i].paidhoursflag = false;
      }
    }
  }

  /*Change event called in html*/
  onChangeOvertimeType(idx: number, e: any) {
    var overtimeType = e?.target.value;
    this.MassAddOvertimeRecords[idx].overtimetypeid = overtimeType;
    this.MassAddOvertimeRecords[idx].OvertimeReasonArrayBasedOnOvertimeType =
      this.onChangeGetOvertimeType(overtimeType);
    this.MassAddOvertimeRecords[idx].overtimereasonid = '';
    for (var i = 0; i < this.MassAddOvertimeRecords.length; i++) {
      if (this.MassAddOvertimeRecords[idx].isselected === true) {
        if (this.MassAddOvertimeRecords[i].isselected === true) {
          this.MassAddOvertimeRecords[i].overtimetypeid = overtimeType;
          this.MassAddOvertimeRecords[
            i
          ].OvertimeReasonArrayBasedOnOvertimeType =
            this.onChangeGetOvertimeType(overtimeType);
          this.MassAddOvertimeRecords[i].overtimereasonid = '';
        }
      }
      if (
        this.MassAddOvertimeRecords[i].overtimetypeid !=
        this.OriginalMassAddOvertimeRecords[i].overtimetypeid
      ) {
        this.MassAddOvertimeRecords[i].overtimetypeidflag = true;
      } else {
        this.MassAddOvertimeRecords[i].overtimetypeidflag = false;
      }
    }
  }

  onChangeOvertimeReason(idx: number, e: any) {
    var overtimeReason = e?.target.value;
    this.MassAddOvertimeRecords[idx].overtimereasonid = overtimeReason;
    for (var i = 0; i < this.MassAddOvertimeRecords.length; i++) {
      if (this.MassAddOvertimeRecords[idx].isselected === true) {
        if (this.MassAddOvertimeRecords[i].isselected === true) {
          this.MassAddOvertimeRecords[i].overtimereasonid = overtimeReason;
        }
      }
      if (
        this.MassAddOvertimeRecords[i].overtimereasonid !=
        this.OriginalMassAddOvertimeRecords[i].overtimereasonid
      ) {
        this.MassAddOvertimeRecords[i].overtimereasonidflag = true;
      } else {
        this.MassAddOvertimeRecords[i].overtimereasonidflag = false;
      }
    }
  }

  onChangeOvertimeComment(idx: number, e: any) {
    var overtimeComment = e?.target.value;
    this.MassAddOvertimeRecords[idx].freeformcomment = overtimeComment;
    for (var i = 0; i < this.MassAddOvertimeRecords.length; i++) {
      if (this.MassAddOvertimeRecords[idx].isselected === true) {
        if (this.MassAddOvertimeRecords[i].isselected === true) {
          this.MassAddOvertimeRecords[i].freeformcomment = overtimeComment;
        }
      }
      if (
        this.MassAddOvertimeRecords[i].freeformcomment !=
        this.OriginalMassAddOvertimeRecords[i].freeformcomment
      ) {
        this.MassAddOvertimeRecords[i].freeformcommentflag = true;
      } else {
        this.MassAddOvertimeRecords[i].freeformcommentflag = false;
      }
    }
  }

  onChangeOvertimeDateOffered(idx: number, e: any) {
    var overtimeDateOffered = e?.target.value;
    this.MassAddOvertimeRecords[idx].dateoffered = overtimeDateOffered;
    for (var i = 0; i < this.MassAddOvertimeRecords.length; i++) {
      if (this.MassAddOvertimeRecords[idx].isselected === true) {
        if (this.MassAddOvertimeRecords[i].isselected === true) {
          this.MassAddOvertimeRecords[i].dateoffered = overtimeDateOffered;
        }
      }
      if (
        this.MassAddOvertimeRecords[i].dateoffered !=
        this.OriginalMassAddOvertimeRecords[i].dateoffered
      ) {
        this.MassAddOvertimeRecords[i].dateofferedflag = true;
      } else {
        this.MassAddOvertimeRecords[i].dateofferedflag = false;
      }
    }
  }

  /* on change override flags */
  /*New Functions created to change the override flag value*/
  onChange1MonthDateOverride() {
    this.OverrideIfDateOlderEarlier1Month =
      !this.OverrideIfDateOlderEarlier1Month;
  }

  onChangeWeekendDateForOTOverride() {
    this.OverrideIfDateSelectedIsWeekedForOvertimeType =
      !this.OverrideIfDateSelectedIsWeekedForOvertimeType;
  }

  onChangIfOTisLR() {
    this.OverrideCheckIfOTisLR = !this.OverrideCheckIfOTisLR;
  }

  onChangeIfOTRisCallinRefused() {
    this.OverrideOTisCallinRefuse = !this.OverrideOTisCallinRefuse;
  }

  /*Cascading Dropdown*/
  onChangeGetOvertimeType(value: string) {
    var newOvertimeReasonArray: any = [];
    var tempOvertimeTypeStore: any;
    newOvertimeReasonArray.length = 0;

    tempOvertimeTypeStore = this.OvertimeType.filter(
      (selectedType: any) => selectedType['overtimetypeid'] === value
    );

    newOvertimeReasonArray.push(
      this.OvertimeReason[0].data.filter(
        (selectedReason: any) =>
          selectedReason['typeassociation'] ===
          tempOvertimeTypeStore[0].reasonassociation
      )
    );

    return newOvertimeReasonArray;
  }

  /*Get Calls*/
  /* Overtime Type*/
  getOvertimeType() {
    this._overtimeMaintenanceService
      .getOvertimeType()
      .subscribe((overtimeType: any) => {
        this.OvertimeType = overtimeType;
      });
  }

  isCommentRequiredArray: any[] = [];

  /* Overtime Reason */
  getOvertimeReason() {
    this.isCommentRequiredArray.length = 0;
    this._overtimeMaintenanceService
      .getOvertimeReason()
      .subscribe((OvertimeReason: any) => {
        this.OvertimeReason[0].data = OvertimeReason;
        for (var i = 0; i < OvertimeReason.length; i++) {
          if (OvertimeReason[i].commentrequired === '1') {
            this.isCommentRequiredArray.push(OvertimeReason[i]);
          }
        }
      });
    // console.log(this.isCommentRequiredArray)
  }

  /* Holidays */
  HolidayListArray: any = [];
  getHolidayList() {
    this._overtimeMaintenanceService
      .GetHolidaysList()
      .subscribe((holidayList: any) => {
        for (var i = 0; i < holidayList.length; i++) {
          var HolidayID = holidayList[i].holidayid;
          var HolidayDate = this._commonService.formatDate(
            new Date(holidayList[i].holiday)
          );
          var HolidayDescription = holidayList[i].description;

          var objHoliday = {
            holidayId: HolidayID,
            holidayDate: HolidayDate,
            HolidayDescription: HolidayDescription,
          };
          this.HolidayListArray.push(objHoliday);
        }
      });
  }

  /*Clears the form*/
  clear() {
    this.clean.emit('');
    this.closeModal.nativeElement.click();
    this.MassAddOvertimeRecords.length = 0;
    this.passAddData.length = 0;

    this.OverTimeAddValue.overtimetypeid = '';
    this.OverTimeAddValue.overtimereasonid = '';
    this.OverTimeAddValue.paidhours = '';
    this.OverTimeAddValue.dateoffered = '';

    this.OverTimeAddValue.dateofwork = '';
    this.isArrayLengthZero = true;

    //this.resetForm();
  }

  /* Emits a bool value that would reset the main search*/
  resetForm() {
    this.isMassAddSuccess.emit(true);
  }

  /*Validation Checks*/
  /*Functions will check the validations required for Mass Add*/
  validationsCheckOvertimeType() {
    this.OvertimeTypeIdCheck = false;
    for (var j = 0; j < this.MassAddOvertimeRecords.length; j++) {
      this.MassAddOvertimeRecords[j].overtimeTypeMissing = false;
      if (
        this.MassAddOvertimeRecords[j].overtimetypeid === '' ||
        this.MassAddOvertimeRecords[j].overtimetypeid === null ||
        this.MassAddOvertimeRecords[j].overtimetypeid === '0'
      ) {
        this.OvertimeTypeIdCheck = true;
        this.MassAddOvertimeRecords[j].overtimeTypeMissing = true;
        // console.log(j);
        //  break;
      }
      // else { this.OvertimeTypeIdCheck = false; }
    }
  }

  validationsCheckPaidHours() {
    this.PaidHoursCheck = false;
    for (var j = 0; j < this.MassAddOvertimeRecords.length; j++) {
      this.MassAddOvertimeRecords[j].paidHoursMissing = false;
      if (
        parseFloat(this.MassAddOvertimeRecords[j].paidhours) === NaN ||
        parseFloat(this.MassAddOvertimeRecords[j].paidhours) === null ||
        this.MassAddOvertimeRecords[j].paidhours === ''
      ) {
        this.PaidHoursCheck = true;
        this.MassAddOvertimeRecords[j].paidHoursMissing = true;
        //console.log(j);
        //break;
      }
      // else { this.PaidHoursCheck = false; }
    }
  }

  validationsCheckOvertimeReason() {
    this.OvertimeReasonIdCheck = false;
    for (var j = 0; j < this.MassAddOvertimeRecords.length; j++) {
      this.MassAddOvertimeRecords[j].overtimeReasonMissing = false;
      if (
        this.MassAddOvertimeRecords[j].overtimereasonid === '' ||
        this.MassAddOvertimeRecords[j].overtimereasonid === null ||
        this.MassAddOvertimeRecords[j].overtimereasonid === ''
      ) {
        this.OvertimeReasonIdCheck = true;
        this.MassAddOvertimeRecords[j].overtimeReasonMissing = true;
        // console.log(j);
        // break;
      }
      // else { this.OvertimeReasonIdCheck = false; }
    }
  }

  validationsCheckDate() {
    this.DateCheck = false;
    for (var j = 0; j < this.MassAddOvertimeRecords.length; j++) {
      this.MassAddOvertimeRecords[j].DowIsGreaterThanDo = false;
      if (
        this.MassAddOvertimeRecords[j].dateofwork <
        this.MassAddOvertimeRecords[j].dateoffered
      ) {
        this.DateCheck = true;
        this.MassAddOvertimeRecords[j].DowIsGreaterThanDo = true;
        //break;
      }
      //else { this.DateCheck = false; }
    }
  }

  validationsCheckComment() {
    this.OvertimeCommentCheck = false;
    for (var j = 0; j < this.MassAddOvertimeRecords.length; j++) {
      this.MassAddOvertimeRecords[j].isCommentRequired = false;
      for (var i = 0; i < this.isCommentRequiredArray.length; i++) {
        if (
          this.MassAddOvertimeRecords[j].overtimereasonid ===
          this.isCommentRequiredArray[i].overtimereasonid
        ) {
          if (
            this.MassAddOvertimeRecords[j].freeformcomment === '' ||
            this.MassAddOvertimeRecords[j].freeformcomment === null
          ) {
            this.OvertimeCommentCheck = true;
            this.MassAddOvertimeRecords[j].isCommentRequired = true;
            // console.log(j);
            //break;
          }
          // else { this.OvertimeCommentCheck = false; }
        }
      }
    }
  }
  validationCheckEarlierOlderDate() {
    this.OvertimeDate1MonthEarlierLaterCheck = false;
    var Date1MonthFromToday = this._commonService.getDateFromToday(1);
    var Date1MonthEarlierToday = this._commonService.getDateEarlierFromToday(1);
    for (var j = 0; j < this.MassAddOvertimeRecords.length; j++) {
      this.MassAddOvertimeRecords[j].DateEarlierOrOlderFlag = false;
      if (
        new Date(this.MassAddOvertimeRecords[j].dateofwork) >
          new Date(Date1MonthFromToday) ||
        new Date(this.MassAddOvertimeRecords[j].dateofwork) <
          new Date(Date1MonthEarlierToday) ||
        new Date(this.MassAddOvertimeRecords[j].dateoffered) >
          (new Date(Date1MonthFromToday) ||
            new Date(this.MassAddOvertimeRecords[j].dateoffered) <
              new Date(Date1MonthEarlierToday))
      ) {
        this.OvertimeDate1MonthEarlierLaterCheck = true;
        this.MassAddOvertimeRecords[j].DateEarlierOrOlderFlag = true;
        // break;
      }
      //else { this.OvertimeDate1MonthEarlierLaterCheck = false; }
    }
  }

  OvertimeTypeRequiredValidation = ['CA', 'CR', 'LCA', 'NSC', 'CAB', 'CD'];
  ErrorOvertimeTypes: any[] = [];
  validationsIsDateHolidayReason() {
    this.OvertimeTypeRequiesValidations = false;
    var check: boolean = false;
    this.ErrorOvertimeTypes.length = 0;
    for (var i = 0; i < this.MassAddOvertimeRecords.length; i++) {
      this.OvertimeTypeRequiesValidations = false;
      this.MassAddOvertimeRecords[i].overtimeRequireValidation = false;
      for (var j = 0; j < this.OvertimeTypeRequiredValidation.length; j++) {
        if (
          this.MassAddOvertimeRecords[i].overtimetypeid ===
          this.OvertimeTypeRequiredValidation[j]
        ) {
          if (
            !this._dateService.checkIfDateIsWeekend(
              this.MassAddOvertimeRecords[i].dateofwork
            )
          ) {
            // checks if date is NOT weekend
            this.OvertimeTypeRequiesValidations = true;
            this.MassAddOvertimeRecords[i].overtimeRequireValidation = true;
          }
          if (
            !this._dateService.checkIfDateIsAHoliday(
              this.MassAddOvertimeRecords[i].dateofwork,
              this.HolidayListArray
            )
          ) {
            // checks if date is NOT Holiday
            this.OvertimeTypeRequiesValidations = true;
            this.MassAddOvertimeRecords[i].overtimeRequireValidation = true;
          }
          if (
            !this._dateService.checkIfDateIsWeekend(
              this.MassAddOvertimeRecords[i].dateofwork
            ) &&
            !this._dateService.checkIfDateIsAHoliday(
              this.MassAddOvertimeRecords[i].dateofwork,
              this.HolidayListArray
            )
          ) {
            // checks if date is NOT weekend AND NOT Holiday
            this.OvertimeTypeRequiesValidations = true;
            this.MassAddOvertimeRecords[i].overtimeRequireValidation = true;
          }
          if (
            !this._dateService.checkIfDateIsWeekend(
              this.MassAddOvertimeRecords[i].dateofwork
            ) &&
            this._dateService.checkIfDateIsAHoliday(
              this.MassAddOvertimeRecords[i].dateofwork,
              this.HolidayListArray
            )
          ) {
            // checks if date is NOT weekend AND but is Holiday
            this.OvertimeTypeRequiesValidations = false;
            this.MassAddOvertimeRecords[i].overtimeRequireValidation = false;
          }
          if (
            this._dateService.checkIfDateIsWeekend(
              this.MassAddOvertimeRecords[i].dateofwork
            ) &&
            !this._dateService.checkIfDateIsAHoliday(
              this.MassAddOvertimeRecords[i].dateofwork,
              this.HolidayListArray
            )
          ) {
            // checks if date is weekend AND but NOT Holiday
            this.OvertimeTypeRequiesValidations = false;
            this.MassAddOvertimeRecords[i].overtimeRequireValidation = false;
          }
        }
        if (this.MassAddOvertimeRecords[i].overtimeRequireValidation === true) {
          check = true;
        }
      }
      this.OvertimeTypeRequiesValidations = check;
    }
  }

  validationCheckForDuplicateRecord() {
    this.DuplicateRecordsFound = false;
    for (var j = 0; j < this.MassAddOvertimeRecords.length; j++) {
      this.MassAddOvertimeRecords[j].isDuplicated = false;
      for (var i = 0; i < this.MassAddOvertimeRecords.length; i++) {
        this.MassAddOvertimeRecords[j].isDuplicated = false;
        if (i != j) {
          if (
            this.MassAddOvertimeRecords[j].employeeid ===
              this.MassAddOvertimeRecords[i].employeeid &&
            this.MassAddOvertimeRecords[j].overtimetypeid ===
              this.MassAddOvertimeRecords[i].overtimetypeid &&
            this.MassAddOvertimeRecords[j].dateofwork ===
              this.MassAddOvertimeRecords[i].dateofwork
          ) {
            this.MassAddOvertimeRecords[j].isDuplicated = true;
            this.MassAddOvertimeRecords[i].isDuplicated = true;
            this.DuplicateRecordsFound = true;
            // break;
          }
        }
      }
    }
  }

  validateIfOTisLR() {
    this.OverrideCheckIfOTisLRCheck = false;
    for (var j = 0; j < this.MassAddOvertimeRecords.length; j++) {
      if (this.MassAddOvertimeRecords[j].overtimetypeid === 'LR') {
        // this.MassAddOvertimeRecords[j].isDuplicated = true;
        // this.MassAddOvertimeRecords[i].isDuplicated = true;
        this.OverrideCheckIfOTisLRCheck = true;
        // break;
      }
    }
  }

  validateIfOTRisCallinRefused() {
    // if the OT Reason is callin refused:

    this.OverrideOTisCallinRefuseCheck = false;
    for (var j = 0; j < this.MassAddOvertimeRecords.length; j++) {
      this.MassAddOvertimeRecords[j].callinRefusedFalg = false;
      if (this.MassAddOvertimeRecords[j].overtimereasonid === '25') {
        if (!(parseFloat(this.MassAddOvertimeRecords[j].paidhours) === 0)) {
          this.MassAddOvertimeRecords[j].callinRefusedFalg = true;
          this.OverrideOTisCallinRefuseCheck = true;
        }
        // break;
      }
    }
  }

  /*Override checks*/
  /*Functions to check if Override is provided*/
  isOverrideCheckedFor1Month() {
    var overrideProvidedForthis = false;
    if (
      this.OvertimeDate1MonthEarlierLaterCheck &&
      this.OverrideIfDateOlderEarlier1Month === true
    ) {
      overrideProvidedForthis = true;
    } else {
      overrideProvidedForthis = false;
    }
    return overrideProvidedForthis;
  }

  isOverrideCheckedForWeekendDateforOT() {
    var overrideProvidedForThis = false;
    if (
      this.OvertimeTypeRequiesValidations &&
      this.OverrideIfDateSelectedIsWeekedForOvertimeType === true
    ) {
      overrideProvidedForThis = true;
    } else {
      overrideProvidedForThis = false;
    }
    return overrideProvidedForThis;
  }

  isOverrideIfOTRisCallinRefused() {
    var overrideProvidedForthis = false;
    if (
      this.OverrideOTisCallinRefuseCheck &&
      this.OverrideOTisCallinRefuse === true
    ) {
      overrideProvidedForthis = true;
    } else {
      overrideProvidedForthis = false;
    }
    return overrideProvidedForthis;
  }

  isOverrideIfOTisLR() {
    var overrideProvidedForThis = false;
    if (
      this.OverrideCheckIfOTisLRCheck &&
      this.OverrideCheckIfOTisLR === true
    ) {
      overrideProvidedForThis = true;
    } else {
      overrideProvidedForThis = false;
    }
    return overrideProvidedForThis;
  }

  isArrayLengthIsZero(array: any) {
    var isBoolValue = false;
    array.length < 0 ? (isBoolValue = true) : (isBoolValue = false);

    return isBoolValue;
  }

  /*Mass Add function*/
  AddMassOvertimeRecord() {
    this.validationsCheckDate();
    this.validationsCheckOvertimeReason();
    this.validationsCheckOvertimeType();
    this.validationsCheckPaidHours();
    this.validationsCheckComment();
    this.validationCheckEarlierOlderDate();
    this.validationsIsDateHolidayReason();
    this.validationCheckForDuplicateRecord();
    this.validateIfOTisLR();
    this.validateIfOTRisCallinRefused();

    if (
      this.MassAddOvertimeRecords.length === 0 ||
      this.MassAddOvertimeRecords.length < 0
    ) {
      this._sweetAlertService.validationError(
        'error',
        'ERROR',
        'Please add some records'
      );
      return;
    }
    if (
      this.OvertimeDate1MonthEarlierLaterCheck &&
      this.isOverrideCheckedFor1Month() === false
    ) {
      return;
    }
    if (
      this.OvertimeTypeRequiesValidations &&
      this.isOverrideCheckedForWeekendDateforOT() === false
    ) {
      return;
    }
    if (
      this.OverrideOTisCallinRefuseCheck &&
      this.isOverrideIfOTRisCallinRefused() === false
    ) {
      return;
    }
    if (
      this.OverrideCheckIfOTisLRCheck &&
      this.isOverrideIfOTisLR() === false
    ) {
      return;
    }
    if (this.DuplicateRecordsFound === true) {
      this._sweetAlertService.validationError(
        'error',
        'Duplicate record found',
        'Record with same overtime type found'
      );
      return;
    }
    if (
      this.OvertimeTypeIdCheck ||
      this.PaidHoursCheck ||
      this.OvertimeReasonIdCheck ||
      this.DateCheck ||
      this.OvertimeCommentCheck
    ) {
      return;
    } else {
      var returnCount;
      this.isEdit = false;
      this._overtimeMaintenanceService
        .overtimeMassAdd(JSON.stringify(this.MassAddOvertimeRecords))
        .subscribe(
          (data: any) => {
            returnCount = data.length;
            if (returnCount > 0) {
              this._sweetAlertService.validationError(
                'error',
                'Duplicate record found',
                'Record with same overtime time code found'
              );
              for (var j = 0; j < this.MassAddOvertimeRecords.length; j++) {
                this.MassAddOvertimeRecords[j].isDuplicated = false;
                for (var i = 0; i < data.length; i++) {
                  if (
                    this.MassAddOvertimeRecords[j].employeeid ===
                      data[i].employeeid &&
                    this.MassAddOvertimeRecords[j].overtimetypeid ===
                      data[i].overTimeTypeId &&
                    this.MassAddOvertimeRecords[j].dateofwork ===
                      this._commonService.formatDate(data[i].dateOfWork)
                  ) {
                    this.MassAddOvertimeRecords[j].isDuplicated = true;
                    this.DuplicateRecordsFound = true;
                  }
                  // else {
                  //   this.MassAddOvertimeRecords[j].isDuplicated = false;
                  // }
                }
              }
            } else {
              this.clear();
              this._sweetAlertService.successAlert(
                'top-end',
                'success',
                'The records have been added successfully.',
                false,
                1500
              );
              this.isMassAddSuccess.emit(true);
              this.checkAllRecords = true;
            }
          },
          (_error: HttpErrorResponse) => {
            //console.error(_error.status);
            this._sweetAlertService.InternalServerError500(
              'error',
              'ERROR',
              'An error occured while processing. Please try again.'
            );
          }
        );
    }
  }
}
