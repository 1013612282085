<div class="container-fluid app-box">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Views</li>
        <li class="breadcrumb-item brd-li brd-li-child">
          Medical Restrictions Search
        </li>
      </ol>
    </nav>

    <div
      style="float: right; position: absolute; right: 268px"
      *ngIf="
        medicalRestrictionsSearchData !== null &&
        medicalRestrictionsSearchData.length > 0
      "
    >
      <button
        style="margin-right: 0.5rem; border-color: #003aa0; margin-left: 15px"
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
      <!-- <button class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0" (click)="exportElmToExcel()">Export to Excel</button> -->
      <!-- <button style="margin-right: 0.5rem;border-color: #003AA0;" class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0" (click)="exportElmToExcel()">Export to Excel</button> -->
    </div>

    <!-- <div style="margin-top: 8px; margin-bottom: 0px; background-color: white;
padding-top: 8px; padding-bottom: 0px; height: 50px;" *ngIf="medicalRestrictionsSearchData !==null && medicalRestrictionsSearchData.length > 0">
    <button style="margin-right: 0.5rem;border-color: #003AA0;" class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0" (click)="exportElmToExcel()">Export to Excel</button>
</div> -->
  </div>

  <div>
    <div class="row content-container mt-2 mb-2">
      <div class="pt-2 div-color">
        <div class="search-divider-div filtercss">
          <p class="search-divder-p"></p>
          <hr class="search-divider-hr" />
          <div class="jumbotron jumb-new jumb-newcontrols">
            <div class="row" display="float">
              <div class="col">
                <app-filter
                  [filterConfig]="filterConfig"
                  (filterValue)="getFilterValue($event)"
                  (formcontrolobj)="getbyenter($event)"
                >
                </app-filter>
              </div>
              <div class="col-md-1 mr-5">
                <a
                  class="btn btn-lg search-button"
                  (click)="searchMedicalRestrictions()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>

          <!-- Button Start-->
          <!-- <div style="margin-top: 8px; margin-bottom: 0px; background-color: white;
padding-top: 8px; padding-bottom: 0px; height: 50px;" *ngIf="medicalRestrictionsSearchData !==null && medicalRestrictionsSearchData.length > 0">
    <button style="margin-right: 0.5rem;border-color: #003AA0;" class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0" (click)="exportElmToExcel()">Export to Excel</button>
</div> -->
          <!-- Button End-->
        </div>

        <div class="container-divider-div"></div>
        <div>
          <div>
            <!-- this is export to excel table  -->
            <table
              class="table table-striped"
              *ngIf="
                !isDataExportableView &&
                medicalRestrictionsSearchData !== null &&
                medicalRestrictionsSearchData.length > 0
              "
              #MedicalRestrictionViewExportTable
            >
              <thead class="table-header position">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Emp. Id</th>
                  <th scope="col">Shift</th>
                  <th scope="col">Department</th>
                  <th scope="col">Classification</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="table-container"
                  *ngFor="
                    let data of medicalRestrictionsSearchData;
                    let i = index
                  "
                >
                  <td class="left-tab-value">
                    <p
                      class="btn my-2 my-sm-0 click-add"
                      data-toggle="modal"
                      [attr.data-target]="modalId"
                      data-backdrop="false"
                      (click)="ViewMedicalRestrictions(data.employeeid)"
                    >
                      {{ data.lasT_NAME ? data.lasT_NAME : '' }},
                      {{ data.firsT_NAME ? data.firsT_NAME : 'N/A' }}&nbsp;{{
                        data.middle ? data.middle : ''
                      }}
                    </p>
                  </td>
                  <td class="tab-value">{{ data.employeeid | uppercase }}</td>
                  <td class="tab-value">{{ data.currenT_SHIFTDESCRIPTION }}</td>
                  <td class="tab-value">{{ data.dept ? data.dept : '-' }}</td>
                  <td class="tab-value custon-padding">
                    {{ data.jobcodeid ? data.jobcodeid : '-' }}
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- this is main table  -->
            <table
              class="table table-striped"
              *ngIf="
                medicalRestrictionsSearchData !== null &&
                medicalRestrictionsSearchData.length > 0
              "
            >
              <thead class="table-header position">
                <!-- <th scope="col" >Name</th>
                                <th scope="col" >Emp. Id</th>
                                <th scope="col">Shift</th>
                                <th scope="col">Department</th>
                                <th scope="col">Classification</th> -->
                <th scope="col">
                  <span (click)="onSortClick($event, 'lasT_NAME', 'string')"
                    >Name
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'lasT_NAME'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'lasT_NAME'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'lasT_NAME'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'employeeid', 'string')"
                    >Emp. Id
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'employeeid'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'employeeid'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'employeeid'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="
                      onSortClick($event, 'currenT_SHIFTDESCRIPTION', 'string')
                    "
                    >Shift
                    <img
                      *ngIf="
                        sortDir === 1 &&
                        sortColumn === 'currenT_SHIFTDESCRIPTION'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 &&
                        sortColumn === 'currenT_SHIFTDESCRIPTION'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 &&
                        sortColumn !== 'currenT_SHIFTDESCRIPTION'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'dept', 'string')"
                    >Department
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'dept'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'dept'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'dept'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'jobcodeid', 'string')"
                    >Classification
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'jobcodeid'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'jobcodeid'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'jobcodeid'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
              </thead>
              <tbody>
                <tr
                  class="table-container"
                  *ngFor="
                    let data of medicalRestrictionsSearchData
                      | paginate
                        : {
                            id: 'joblist',
                            itemsPerPage: 20,
                            currentPage: Applicationpage,
                            totalItems: TotalApplicationCount
                          };
                    let i = index
                  "
                >
                  <td class="left-tab-value">
                    <p
                      class="btn my-2 my-sm-0 click-add"
                      data-toggle="modal"
                      [attr.data-target]="modalId"
                      data-backdrop="false"
                      (click)="ViewMedicalRestrictions(data.employeeid)"
                    >
                      {{ data.lasT_NAME ? data.lasT_NAME : '' }},
                      {{ data.firsT_NAME ? data.firsT_NAME : 'N/A' }}&nbsp;{{
                        data.middle ? data.middle : ''
                      }}
                    </p>
                  </td>
                  <td class="tab-value">{{ data.employeeid | uppercase }}</td>
                  <td class="tab-value">{{ data.currenT_SHIFTDESCRIPTION }}</td>
                  <td class="tab-value">{{ data.dept ? data.dept : '-' }}</td>
                  <td class="tab-value custon-padding">
                    {{ data.jobcodeid ? data.jobcodeid : '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="single-wrap d-flex justify-content-center"
              *ngIf="
                medicalRestrictionsSearchData !== null &&
                medicalRestrictionsSearchData.length > 0
              "
            >
              <nav aria-label="Page navigation example">
                <pagination-controls
                  class="my-pagination"
                  id="joblist"
                  (pageChange)="Applicationpage = $event"
                  previousLabel=""
                  nextLabel=""
                >
                </pagination-controls>
              </nav>
            </div>
            <div *ngIf="isApplicationDataEmpty">
              <p *ngIf="isApplicationDataEmpty">No existing records Found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-employee-medical-restrictions
  [EmployeeIdPassData]="EmployeeIdPassData"
  [MedicalRestriction]="MedicalRestriction"
  (isClosed)="medicalRestriction($event)"
  *ngIf="MedicalRestriction"
></app-employee-medical-restrictions>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
