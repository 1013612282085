import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { FacilitiesServicePlantService } from 'src/app/Services/Applications/FacilitiesAndService/facilities-service-plant.service';
import { Paragraph19Service } from 'src/app/Services/Applications/Paragraph19/paragraph19.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';
import { UserRoles } from 'src/enum/userroles';
import { userData } from 'src/models/userdata';
import { UserInfo } from 'src/models/userinfo';

@Component({
  selector: 'app-paragraph19-application',
  templateUrl: './paragraph19-application.component.html',
  styleUrls: ['./paragraph19-application.component.css'],
})
export class Paragraph19ApplicationComponent implements OnInit {
  public loading: boolean = false;
  applicationData: any = [];
  applicationUawData: any = [];
  userData: any = [];
  modalPopTitle = {
    title: '',
    type: '',
  };
  filterData: any = {};
  filterDataUser: any = {};
  masterSearchFilter: boolean = true;
  isApplicationData: boolean = true;
  searchKey: string = '';
  selectedEmp: any;
  modalId: string | any;
  isAdd: boolean = false;
  modalTitle: string = '';
  isEdit: boolean = false;
  isDeleted: boolean = false;
  isCancel: boolean = false;
  isLoggedInUserAdmin: boolean = false;
  CancelButtonDisabled!: boolean;
  UserPage: number = 1;
  Applicationpage: number = 1;
  isApplicationDataExists: boolean = true;
  TotalApplicationCount: any;
  EditButtonDisabled!: boolean;

  disablededit = false;
  disabledcancel = false;
  disableddelete = false;
  userRole: UserRoles = UserRoles.SKILLEDUSER; // Enum for user role
  userInformation!: UserInfo; // Model for UserInfo
  usersData!: userData; // Model for UAW user details

  returnCancelledstatus!: boolean; // stores cancelled status if any
  returnOpenStatus!: boolean; // stores open status if any

  filterConfig: any = [
    {
      label: 'Emp Id',
      type: 'input',
      data: [],
      placeholder: 'Emp Id',
      default: 'All',
      controlName: 'EmployeeId',
      width: '80px',
      runClassification: false,
    },
    {
      label: 'Last Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'lastName',
      width: '80px',
      runClassification: false,
    },
    {
      label: 'First Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'firstName',
      width: '80px',
      runClassification: false,
    },
    {
      label: 'Classification',
      type: 'dropdown',
      data: ['option 1', 'option 2'],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'jobCodeId',
      width: '80px',
      runClassification: false,
    },
    {
      label: 'Status',
      type: 'dropdown',
      data: ['option 1', 'option 2'],
      default: 'All',
      controlName: 'appStatusCode',
      width: '80px',
      runClassification: false,
    },
    {
      label: 'From',
      type: 'date',
      data: ['option 1', 'option 2'],
      default: 'All',
      controlName: 'fromDate',
      width: '142px',
      runClassification: false,
    },
    {
      label: 'To',
      type: 'date',
      data: ['option 1', 'option 2'],
      default: 'All',
      controlName: 'toDate',
      width: '142px',
      runClassification: false,
    },
  ];
  filterConfigAdd: any = [
    {
      label: 'Last Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'lastName',
      width: '120px',
    },
    {
      label: 'First Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'firstName',
      width: '120px',
    },
    {
      label: 'Employee Id',
      type: 'input',
      data: [],
      placeholder: 'Employee Id',
      default: 'All',
      controlName: 'EmployeeId',
      width: '120px',
    },
  ];
  EditButtonDisabled1!: boolean;
  CancelButtonDisabled1!: boolean;
  DeleteButtonDisabled1!: boolean;
  views: any[] = [];
  constructor(
    private fb: FormBuilder,
    private sweetAlert: SweetAlertService,
    private p19: Paragraph19Service,
    private fs: FacilitiesServicePlantService,
    public _userInfo: UsergroupService,
    private _commonApplicationService: CommonService
  ) {}
  ngOnInit(): void {
    this.EditButtonDisabled1 = true;
    this.CancelButtonDisabled1 = true;
    this.DeleteButtonDisabled1 = true;
    this.getStatus();
    this.getClassifications();
    this.getUserRoleDescreption();
  }
  //Checks if logged in user is Admin
  isAdmin(): boolean {
    if (this.userRole === UserRoles.HRPLUS) {
      this.isLoggedInUserAdmin = true;
    } else {
      this.isLoggedInUserAdmin = false;
    }
    return this.userRole === UserRoles.HRPLUS;
  }

  //On selection of search applications or add applications
  onAppTypeSelected(val: number) {
    if (val === 2) {
      this.isApplicationData = !this.isApplicationData;
      this.masterSearchFilter = false;
      this.userData = null;
      this.clearFilterData(this.filterData);
    }
    if (val === 1) {
      this.isApplicationData = true;
      this.masterSearchFilter = true;
      this.applicationData = null;
      this.userData = null;
      if (this.isApplicationDataExists === false) {
        this.isApplicationDataExists = true;
      }
      this.EditButtonDisabled1 = true;
      this.CancelButtonDisabled1 = true;
      this.DeleteButtonDisabled1 = true;
    }
  }

  //Select application for edit/delete operation for HR
  selectEmp(idx: number, appId: string) {
    this.disablededit = false;
    this.disabledcancel = false;
    this.disableddelete = false;
    for (let index = 0; index < this.applicationData.length; index++) {
      if (this.applicationData[index].appId === appId) {
        this.selectedEmp = this.applicationData[index];
      }
    }
    //checking if the appstatus of the selected record is cancelled
    //Code is for disbaling the cancel button
    if (this.selectedEmp['appStatusCode'].includes('CLOSED')) {
      this.CancelButtonDisabled = true;
      this.disablededit = false;
      this.disableddelete = false;
      this.disabledcancel = true;
    } else if (this.selectedEmp['appStatusCode'].includes('CANCELLED')) {
      this.returnCancelledstatus = true;
      this.CancelButtonDisabled = true;
      this.EditButtonDisabled = true;
      this.disabledcancel = true;
      this.disablededit = true;
    } else {
      this.returnCancelledstatus = false;
      //this.EditButtonDisabled = false;
      this.disablededit = false;
      this.disabledcancel = false;
      this.disableddelete = false;
    }
  }

  //Function to get logged in user's role and details
  getUserRoleDescreption() {
    this._userInfo.getUserInfo().subscribe((data: any) => {
      // this.userRole = data['groupDescription'] === UserRoles.HRPLUS ? UserRoles.HRPLUS
      //   : UserRoles.PRODUCTIONUSER;
      if (
        data['groupDescription'] === UserRoles.HRPLUS ||
        data['groupDescription'] === UserRoles.COMMITTEE
      ) {
        this.userRole = UserRoles.HRPLUS;
      } else {
        this.userRole = UserRoles.PRODUCTIONUSER;
      }
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'APPLICATIONS',
        'PARAGRAPH19'
      );
      this.p19.atid.next(this.userInformation.userName);
      if (this.userRole === UserRoles.PRODUCTIONUSER) {
        this.searchUawApplication();

        this.p19
          .searchFilterApplicationUser({
            employeeId: this.userInformation.employeeId,
          })
          .subscribe((res) => {
            this.userData = res;
            this.usersData = res[0];
            if (this.userData != undefined && this.userData.length > 0) {
              this.selectedEmp = this.userData[0];
              this.selectedEmp.selected = true;
            }
          });
      }
    });
  }

  getClassifications() {
    this.p19.getClassificationList().subscribe((res) => {
      const elements = res || [];
      const mapElement = elements.map(
        (element: { jobCodeId: any; jobCodeDescription: any }) => ({
          id: element.jobCodeId,
          description: element.jobCodeDescription,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Classification') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  getStatus() {
    const elements: any = [
      {
        statusId: 'OPEN',
        statusDescription: 'OPEN',
      },
      {
        statusId: 'CLOSED',
        statusDescription: 'CLOSED',
      },
      {
        statusId: 'CANCELLED',
        statusDescription: 'CANCELLED',
      },
    ];
    const mapElement = elements.map(
      (element: { statusId: string; statusDescription: string }) => ({
        id: element.statusId,
        description: element.statusDescription,
      })
    );
    for (let index = 0; index < this.filterConfig.length; index++) {
      if (this.filterConfig[index].label === 'Status') {
        this.filterConfig[index].data = mapElement;
      }
    }
  }

  //Get application search params
  getFilterValue(event: any) {
    this.filterData = event;
  }

  //Get user search params
  getFilterDataUser(event: any) {
    this.filterDataUser = event;
  }

  //Search  Application - HRPLUS/UAW
  searchApplication() {
    if (this.userRole === UserRoles.PRODUCTIONUSER) {
      this.searchUawApplication();
      return;
    }
    this.loading = true;
    let queryParams = this.filterData;
    Object.keys(queryParams).forEach(
      (k) =>
        !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]
    );
    queryParams.AppType = 'P19';
    this.p19.searchFilterApplication(queryParams).subscribe((data) => {
      this.applicationData = data;
      for (var i = 0; i < this.applicationData.length; i++) {
        if (this.applicationData[i].empSignDate != null) {
          this.applicationData[i].empSignDateSort = new Date(
            this.applicationData[i].empSignDate
              .replace(/-/g, '/')
              .replace(/T.+/, '')
          ).toISOString();
        }
        if (this.applicationData[i].plantSeniorityDate != null) {
          this.applicationData[i].plantSeniorityDateSort = new Date(
            this.applicationData[i].plantSeniorityDate
              .replace(/-/g, '/')
              .replace(/T.+/, '')
          ).toISOString();
        }
        if (this.applicationData[i].seniorityDate != null) {
          this.applicationData[i].seniorityDateSort = new Date(
            this.applicationData[i].seniorityDate
              .replace(/-/g, '/')
              .replace(/T.+/, '')
          ).toISOString();
        }
        if (this.applicationData[i].plantSkillDate != null) {
          this.applicationData[i].plantSkillDateSort = new Date(
            this.applicationData[i].plantSkillDate
              .replace(/-/g, '/')
              .replace(/T.+/, '')
          ).toISOString();
        }
        if (this.applicationData[i].skillDate != null) {
          this.applicationData[i].skillDateSort = new Date(
            this.applicationData[i].skillDate
              .replace(/-/g, '/')
              .replace(/T.+/, '')
          ).toISOString();
        }
      }
      this.isApplicationDataExists =
        this.applicationData.length > 0 ? true : false;
      this.Applicationpage = 1;
      this.EditButtonDisabled1 = false;
      this.DeleteButtonDisabled1 = false;
      this.disablededit = true;
      this.disabledcancel = true;
      this.disableddelete = true;
      this.CancelButtonDisabled1 = false;
      this.TotalApplicationCount = data.length;
      this.loading = false;
    });
  }

  //Search User for add aplications
  searchUser() {
    this.loading = true;
    let queryParams = this.filterDataUser;
    Object.keys(queryParams).forEach(
      (k) =>
        !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]
    );
    // queryParams.appAreaId = 'All';
    queryParams.AppType = 'P19';
    this.p19.searchFilterApplicationUser(queryParams).subscribe((data) => {
      this.userData = data;
      this.UserPage = 1;
      this.TotalApplicationCount = data.length;
      this.loading = false;
    });
  }

  //Search application of UAW user
  searchUawApplication() {
    this.loading = true;
    let queryParams = {
      employeeId: this.userInformation.employeeId,
      appStatusCode: 'OPEN',
      appType: '178',
    };
    this.p19.searchFilterApplication(queryParams).subscribe((data) => {
      this.applicationUawData = data;
      this.loading = false;
      if (this.applicationUawData.length > 0) {
        this.applicationUawData[0].selected = true;
        this.selectedEmp = this.applicationUawData[0];
      }
      //checking if the appstatus of the selected record is CANCELLED
      if (
        this.selectedEmp != null &&
        this.selectedEmp['appStatusCode'].includes('CANCELLED')
      ) {
        this.returnCancelledstatus = true;
        this.returnOpenStatus = false;
      }
      //checking if the appstatus of the selected record is OPEN
      else if (
        this.selectedEmp !== null &&
        this.selectedEmp['appStatusCode'].includes('OPEN')
      ) {
        this.returnOpenStatus = true;
        this.returnCancelledstatus = false;
      } else {
        this.returnOpenStatus = false;
        this.returnCancelledstatus = false;
      }
    });
  }

  clearFilterData(filterData: any) {
    Object.keys(filterData).forEach((k) => delete filterData[k]);
  }

  isApplicationSelected() {
    return $('input[name=application]:checked').length > 0;
  }

  //Select user to add application for
  // selectUser(idx: number) {
  //   this.userData[idx].selected = true;
  //   for (let index = 0; index < this.userData.length; index++) {
  //     this.userData[index].selected = false;
  //   }
  //   this.userData[idx].selected = true;
  //   this.selectedEmp = this.userData[idx];
  // }

  selectUser(idx: number, employeeId: string) {
    for (let index = 0; index < this.userData.length; index++) {
      if (this.userData[index].employeeId === employeeId) {
        this.selectedEmp = this.userData[index];
      }
    }
  }

  // Add application - HRPLUS
  addApplication(idx: number, employeeId: string) {
    this.selectUser(idx, employeeId);
    if (this.selectedEmp) {
      this.isAdd = true;
      this.modalId = '#addEmployee';
    } else {
      this.sweetAlert.simpleAlert(
        'Error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }

  // Add application - UAW
  addApplicationUAW() {
    if (this.userInformation) {
      this.isAdd = true;
      this.modalId = '#addEmployee';
    } else {
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }

  //Function to correct the application - HRPLUS
  edit() {
    if (this.selectedEmp) {
      this.modalTitle = 'Edit Employee';
      this.isEdit = true;
      this.modalId = '#editEmp';
    } else {
      this.modalId = '';
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'Please select employee to edit'
      );
    }
  }

  //Function to delete the application - HRPLUS
  deleteApplication() {
    if (this.selectedEmp) {
      this.modalId = '#deleteApplicationModal';
    } else {
      this.modalId = '';
      alert('No Employee Selected');
    }
  }
  //For application - UAW
  selectUserEmp(idx: number) {
    this.applicationUawData[idx].selected = true;
    for (let index = 0; index < this.applicationUawData.length; index++) {
      this.applicationUawData[index].selected = false;
    }
    this.applicationUawData[idx].selected = true;
    this.selectedEmp = this.applicationUawData[idx];

    // //checking if the appstatus of the selected record is cancelled
    // if (this.selectedEmp["appStatusCode"].includes("CANCELLED")) {
    //   this.CancelButtonDisabled = true;
    // } else {
    //   this.CancelButtonDisabled = false;
    // }
  }

  //Function to cancel an application - HRPLUS
  cancelApplication() {
    if (this.selectedEmp) {
      if (this.selectedEmp && this.selectedEmp.appId) {
        this.p19.appId.next(this.selectedEmp.appId);
        this.p19.empsignDate.next(this.selectedEmp.empSignDate);
      } else {
        this.sweetAlert.simpleAlert('errror', 'App Id MIssing', '');
      }
      this.modalId = '#cancelApplicationModal';
    } else {
      this.modalId = '';
      alert('No Employee Selected');
    }
  }

  //Resets the form
  //  clear() {
  //   this.employeeForm.reset();
  // }

  //Resets the list of user applications
  CheckAdd(e: any) {
    this.isAdd = e;
    if (this.isAdd === false) {
      this.applicationData = this.searchApplication();
    } else {
      this.searchApplication();
    }
  }

  CheckEdit(e: any) {
    this.isEdit = e;
    this.searchApplication();
  }

  //Resets the form after deletion of application - HRPLUS
  CheckDelete(e: any) {
    this.isDeleted = e;
    this.searchApplication();
  }

  //Resets the form after cancellation of application - HRPLUS
  CheckCancel(e: any) {
    this.isCancel = e;
    this.searchApplication();
  }

  cleanEmp(event: any) {}

  getbyenter(e: any) {
    this.loading = true;
    e.AppType = 'P19';
    let searchData;
    if (this.filterData != null) {
      searchData = { ...e, ...this.filterData };
    }
    this.p19.searchFilterApplication(searchData).subscribe((data) => {
      this.applicationData = data;
      this.isApplicationDataExists =
        this.applicationData.length > 0 ? true : false;
      this.Applicationpage = 1;
      this.EditButtonDisabled1 = false;
      this.DeleteButtonDisabled1 = false;
      this.disablededit = true;
      this.disabledcancel = true;
      this.disableddelete = true;
      this.CancelButtonDisabled1 = false;
      this.TotalApplicationCount = data.length;
      this.loading = false;
    });
  }

  getbyenterforuser(e: any) {
    this.loading = true;
    e.AppType = 'P19';
    this.p19.searchFilterApplicationUser(e).subscribe((data) => {
      this.userData = data;
      this.UserPage = 1;
      this.TotalApplicationCount = data.length;
      this.loading = false;
    });
  }

  @ViewChild('Paragraph19ExportTable', { read: ElementRef, static: false })
  Paragraph19ExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.Paragraph19ExportTable,
        `${ApplicationEnum.PARAGRAPH19}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    //console.log("testp19", this.applicationData);
    if (dataType === 'string') {
      this.applicationData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.applicationData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
