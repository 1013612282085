import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ViewChild, ElementRef } from '@angular/core';
import { TransferAgreementService } from 'src/app/Services/Applications/TransferAgreementService/transfer-agreement.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { DateService } from 'src/app/Services/Date/date.service';
import { MSALserviceService } from 'src/app/Services/MSALservice/msalservice.service';

@Component({
  selector: 'app-cancel-transfer-agreement',
  templateUrl: './cancel-transfer-agreement.component.html',
  styleUrls: ['./cancel-transfer-agreement.component.css']
})
export class CancelTransferAgreementComponent implements OnInit {
  @Input() selectedEmp: any;
  @Input() userInformation: any;
  @Input() employeeForm: any;
  @Input() isLoggedInUserAdmin: boolean = false;
  @Output() isSucess = new EventEmitter();
  @ViewChild('closeCancelModal') closeModal!: ElementRef;

  applicationSelected: any;

  dateForm!: FormGroup;
  modalId: string | any;
  currentDate = new Date();

  /* these variables hold current position of applicant*/
  usersData: any;
  JobCodeClassification: string = '';
  Department: string = '';
  Shift: string = '';
  Plant: string = '';
  maxCancelDate: string = '';


  constructor(private fb: FormBuilder,
    private _transferAgreement: TransferAgreementService,
    private _sweetAlertService: SweetAlertService,
    private _commonApplicationService: CommonService,
    private _dateService: DateService,
    private _msalService: MSALserviceService
  ) { }
  ngOnInit(): void {
    this.dateForm = this.fb.group({
      appId: ['',],
      AppStatusDate: [''],
      empSignDate: ['', [Validators.required]],
      LastUpdatedBy: ['', [Validators.required]],
      AppType: ['TA']

    },
      {
        validator: this.dateLessThan('empSignDate', 'AppStatusDate'),
      }
    );
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let empsigndate = group.controls[from];
      let appstatusdate = group.controls[to];
      if (new Date(new Date(empsigndate?.value).setHours(0, 0, 0, 0)) > new Date(new Date((new Date(appstatusdate?.value).setHours(24))).setHours(0, 0, 0, 0))) {
        return {
          dates: "Status date shouldn't be earlier than Employee sign date",
        };
      }
      return {};
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.LoadApplicationData(changes);
  }

  LoadApplicationData(changes: SimpleChanges) {
    this.applicationSelected =
    (changes.selectedEmp && changes.selectedEmp.currentValue) || '';
    if (this.applicationSelected === '') {
      this.applicationSelected =
        (changes.selectedEmp && changes.selectedEmp.previousValue) || '';
    }

    this.dateForm.get('appId')?.patchValue(this.applicationSelected.appId)
    this.dateForm.get('empSignDate')?.patchValue(this.applicationSelected.empSignDate);
    this.dateForm.get('LastUpdatedBy')?.patchValue(this.userInformation.userName);
    this.dateForm.get('AppStatusDate')?.patchValue(this._commonApplicationService.formatDate(this.currentDate));
    this._transferAgreement.searchFilterApplicationUser({ employeeId: this.applicationSelected.employeeId }).subscribe((data) => {
    this.usersData = data[0];
    this.JobCodeClassification = this.usersData.jobCodeId + '-' + this.usersData.jobCodeDescription;
    this.Plant = this.usersData.plantId + '-' + this.usersData.plantDescription;
    this.Shift = this.usersData.shiftDescription;
    this.Department = this.usersData.deptId;
    });
    if (this.isLoggedInUserAdmin === false) {
      this.maxCancelDate = this._commonApplicationService.formatDate(this.currentDate);
      this.dateForm.get('AppStatusDate')?.patchValue(this.currentDate);
    }
  }
  clear() {
    //this.dateForm.reset();
    this.closeModal.nativeElement.click();
    this.resetForm();
  }
  resetForm() {
    this.isSucess.emit(true);
  }
  CancelApplication() {
    // var dt = this.dateForm.get('AppStatusDate')?.value;
    // var appStsDt = new Date(dt);
    // var appStsDtInUTC = this._dateService.GetUTCDate(appStsDt);

    // this.dateForm.patchValue({ AppStatusDate: appStsDtInUTC });

    this._transferAgreement.TransferAgreementTACancel(this.dateForm.value).subscribe(
      (data: any) => {
        this.clear();
        this.isSucess.emit(true);
        this._sweetAlertService.successAlert('center', 'success', 'The Application has been Cancelled Successfully.', false, 1500);
      },
      (error) => {
        this._sweetAlertService.InternalServerError500('error', 'Error', 'Something happened. Please try again.');
      }

    );
  }

}
