<div class="modal fade" id="addEmployee" tabindex="-1" role="dialog" aria-labelledby="addEmployeeLabel"
    aria-hidden="true">
    <div class="modal-dialog add-form-alignment" role="document">
        <div class="modal-content" style="position: fixed; width: 945px">
            <div class="modal-header">
                <h5 class="modal-title" id="addEmployeeLabel">Add Application</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body" style="padding-left: 80px; padding-right: 80px">
                <form [formGroup]="employeeForm">

                    <div class="text-danger" *ngIf= "errorlength > 0">
                        <h6 *ngFor="let error of ruleMessages">  *{{error}} </h6>
                    </div>
                    <br>
                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Employee Name: </label>
                        <div class="col-sm-8">
                            <input readonly type="text" formControlName="employeeName" placeholder="Employee Name"
                                class="form-control">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Current Classification:</label>
                        <div class="col-sm-8">
                            <input readonly formControlName="currentClassification" type="text" class="form-control">
                        </div>
                    </div>


                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Classification Applied For *:</label>
                        <div class="col-sm-8">
                            <select formControlName="JobCodeId" class="form-control">
                                <option *ngIf="classifications[classifications.length-1].default" selected  value="">{{classifications[classifications.length-1].default}}</option>
                                <option *ngFor="let class of classifications[classifications.length-1].data" value={{class.jobCodeId}}>
                                    {{class.jobCodeId}}-{{class.jobCodeDescription}}
                                </option>
                            </select>  
                            <small *ngIf="employeeForm.controls['JobCodeId'].errors?.leaderClassificationError" class="text-danger">
                                The applied classification cannot be a leader classification
                            </small>  
                            <small *ngIf="employeeForm.controls['JobCodeId'].errors?.ApprenticeClassificationError" class="text-danger">
                                The applied classification cannot be an apprentice classification
                            </small>                                               
                            <div *ngIf="employeeForm.controls['JobCodeId'].invalid && employeeForm.controls['JobCodeId'].touched">
                                <small class="text-danger" *ngIf="employeeForm.controls['JobCodeId'].errors?.required">
                                    You must specify a valid classification.
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Employee Signature Date *:</label>
                        <div class="col-sm-8">
                            <input formControlName="empSignDate" type="date" class="form-control" max = {{maxDate}} >
                        </div>
                        <div *ngIf="employeeForm.controls['empSignDate'].invalid && employeeForm.controls['empSignDate'].touched">
                            <small class="text-danger" *ngIf="employeeForm.controls['empSignDate'].errors?.required">
                                You must specify a valid employee signature date.
                            </small>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">You must validate the employee's experience *:</label>
                        <div class="col-sm-8">
                            <input formControlName="ExpValidateFlag" type="checkbox" value="1" class="form-control">
                        </div>
                        <div *ngIf="employeeForm.controls['ExpValidateFlag'].invalid && employeeForm.controls['ExpValidateFlag'].touched">
                            <small class="text-danger" *ngIf="employeeForm.controls['ExpValidateFlag'].errors?.required">
                                You must validate the experience.
                            </small>
                        </div>
                    </div>

                    <div class="text-danger" *ngIf= "medicalRestrictionList.length > 0">
                        <h6>WARNING: This employee has a current medical restriction</h6>
                        <ul>
                            <li *ngFor="let medicalRestriction of medicalRestrictionList">
                                {{medicalRestriction}}
                            </li>
                        </ul>
                    </div>

                </form>
            </div>
            <div class="modal-footer" style="padding-right: 80px">
                <button style="
                height: 40px;
                width: 150px;
                border-radius: 10px;
                
              "
               type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" #closeAddModel
                    (click)="clear()">Cancel</button>
                <button style="
               height: 40px;
                width: 150px;
                border-radius: 10px;
                
              "  type="button" class="btn btn-primary" [disabled]="employeeForm.invalid || !StatusCode"
                    (click)="addEmployee()">Add </button>
            </div>
        </div>
    </div>
</div>

