import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { DemographicViewService } from 'src/app/Services/TableMaintenance/DemographicView/demographic-view.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { MedicalRestrictionsService } from 'src/app/Services/Views/MedicalRestrictionsView/medical-restrictions.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';

@Component({
  selector: 'app-medical-restrictions',
  templateUrl: './medical-restrictions.component.html',
  styleUrls: ['./medical-restrictions.component.css'],
})
export class MedicalRestrictionsComponent implements OnInit {
  public loading: boolean = false;
  TotalApplicationCount: any;
  UserPage: number = 1;
  Applicationpage: number = 1;
  modalId: string = '';
  filterData: any = {};
  medicalRestrictionsSearchData: any[] = [];
  userInformation: any;
  views: any[] = [];
  departments: any;
  isApplicationDataEmpty: boolean = false;

  MedicalRestriction: boolean = false;

  filterConfig: any = [
    {
      label: 'Last Name',
      type: 'input',
      data: [],
      placeholder: 'Enter last name',
      default: 'All',
      controlName: 'Last_Name',
      runClassification: false,
    },
    {
      label: 'First Name',
      type: 'input',
      data: [],
      placeholder: 'Enter first name',
      default: 'All',
      controlName: 'First_Name',
      runClassification: false,
    },
    {
      label: 'Employee Id',
      type: 'input',
      data: [],
      placeholder: 'Enter Employee Id',
      default: 'All',
      controlName: 'EMPLOYEEID',
      runClassification: false,
    },
    {
      label: 'Department',
      type: 'dropdown',
      data: [],
      placeholder: 'Search department here',
      default: 'All',
      controlName: 'Dept',
      runClassification: false,
      width: '200px',
    },
  ];
  constructor(
    private _medicalRestrictions: MedicalRestrictionsService,
    private dv: DemographicViewService,
    private _user: UsergroupService,
    private _commonApplicationService: CommonService,
    private _sweetAlert: SweetAlertService
  ) {}

  ngOnInit(): void {
    this.getAllDepartments();
    this.searchMedicalRestrictions();
    this.getUserInfo();
  }

  getAllDepartments() {
    this.dv.getAllDepartments().subscribe((data: any) => {
      this.departments = data;
      const mapElement = this.departments.map(
        (element: { deptId: any; deptDescription: any }) => ({
          id: element.deptId,
          description: element.deptDescription,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Department') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'VIEWS',
        'MEDICALRESTRICTIONSVIEW'
      );
    });
  }

  getFilterValue(event: any) {
    this.filterData = event;
  }

  EmployeeIdPassData: any;
  ViewMedicalRestrictions(employeeid: any) {
    this.EmployeeIdPassData = employeeid;
    if (this.EmployeeIdPassData) {
      this.MedicalRestriction = true;
      this.modalId = '#ViewEmployeeMedicalRestriction';
    } else {
      this._sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }

  medicalRestriction(e: any) {
    this.MedicalRestriction = e;
    if (this.MedicalRestriction === false) {
      this.searchMedicalRestrictions();
    }
  }

  searchMedicalRestrictions() {
    this.loading = true;
    let queryParams = this.filterData;
    Object.keys(queryParams).forEach(
      (k) =>
        !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]
    );
    if (Object.keys(this.filterData).length === 0) {
    }
    this._medicalRestrictions
      .getAlMedicalRestrictions(queryParams)
      .subscribe((res) => {
        this.medicalRestrictionsSearchData = res;
        for (var i = 0; i < res.length; i++) {
          this.medicalRestrictionsSearchData[i].fullname =
            this.medicalRestrictionsSearchData[i].lasT_NAME +
            ', ' +
            this.medicalRestrictionsSearchData[i].firsT_NAME +
            ' ' +
            this.medicalRestrictionsSearchData[i].middle;
        }
        this.medicalRestrictionsSearchData = [
          ...new Map(
            this.medicalRestrictionsSearchData.map((item) => [
              item['fullname'],
              item,
            ])
          ).values(),
        ];
        this.isApplicationDataEmpty =
          this.medicalRestrictionsSearchData.length === 0 ? true : false;
        this.Applicationpage = 1;
        this.loading = false;
      });
  }

  getbyenter(e: any) {
    this.loading = true;
    let searchData;
    if (this.filterData != null) {
      searchData = { ...e, ...this.filterData };
    }
    this._medicalRestrictions
      .getAlMedicalRestrictions(searchData)
      .subscribe((res: any) => {
        this.medicalRestrictionsSearchData = res;
        for (var i = 0; i < res.length; i++) {
          this.medicalRestrictionsSearchData[i].fullname =
            this.medicalRestrictionsSearchData[i].firsT_NAME +
            ' ' +
            this.medicalRestrictionsSearchData[i].lasT_NAME;
        }
        this.medicalRestrictionsSearchData = [
          ...new Map(
            this.medicalRestrictionsSearchData.map((item) => [
              item['fullname'],
              item,
            ])
          ).values(),
        ];
        this.isApplicationDataEmpty =
          this.medicalRestrictionsSearchData.length === 0 ? true : false;
        this.Applicationpage = 1;
        this.loading = true;
      });
  }
  @ViewChild('MedicalRestrictionViewExportTable', {
    read: ElementRef,
    static: false,
  })
  MedicalRestrictionViewExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.MedicalRestrictionViewExportTable,
        `${ApplicationEnum.MEDICALRESTRICTIONSVIEW}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; //1= 'ASE' -1= DSC
  sortColumn = 'lasT_NAME';

  // onSortClick(event: any, fieldName: string, dataType: string) {
  //     this._commonApplicationService.callSortService(this.medicalRestrictionsSearchData, event, fieldName, dataType, this.sortDir, this.sortColumn);
  // }

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.medicalRestrictionsSearchData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.medicalRestrictionsSearchData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
