import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ElementRef,
  EventEmitter,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { ToolAndDiePlantService } from 'src/app/Services/Applications/ToolAndDie/tool-and-die-plant.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FacilitiesServicePlantService } from 'src/app/Services/Applications/FacilitiesAndService/facilities-service-plant.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { RuleEngineService } from 'src/app/Services/Ruleengine/rule-engine.service';

@Component({
  selector: 'edit-facilites-service',
  templateUrl: './edit-facilites-service.component.html',
  styleUrls: ['./edit-facilites-service.component.css'],
})
export class EditFacilitesServiceComponent implements OnInit {
  @Input() selectedEmp: any;
  empSelected: any;
  @Input() isEdit: boolean = false;
  @Input() title: any;
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;
  employeeForm!: FormGroup;
  @Output() clean = new EventEmitter();
  @Output() isSucess = new EventEmitter();
  shifts: any[] = [];
  plants: any[] = [];
  areas: any[] = [];
  lastupdateby: string = 'Aniket Mandal';
  lastupdater: string = '';
  //eledit: string = '';
  //isValidDate: any;
  //error: any = { isError: false, errorMessage: '' };
  statusCancelled: boolean = false;
  statusClosed: boolean = false;
  invalidStatus: boolean = false;
  SelectedPlantId: any;
  userName: string = '';
  currentDate = new Date();
  maxDate: string = '';
  statusCodes: string[] = [];

  constructor(
    private fb: FormBuilder,
    private td: ToolAndDiePlantService,
    private authService: MsalService,
    private _userInfo: UsergroupService,
    private fs: FacilitiesServicePlantService,
    private re: RuleEngineService,
    private _commonApplicationService: CommonService
  ) {
    this.getUserInfo();
  }

  ngOnInit() {
    this.getUserInfo();
    this.getShifts();
    this.getplants();
    this.getAreas();
    this.getEmpStatusCodes();
  }

  getEmpStatusCodes() {
    this.re.getEmpStatusCode().subscribe((data) => {
      this.statusCodes = data;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.CreateEditApplicationForm();
    this.LoadApplicationData(changes);
  }
  getUserInfo() {
    var str = '';
    this._userInfo.getUserInfo().subscribe((data: any) => {
      str = data['userName'];
      this.userName = str.toUpperCase() + '@alsn.com';
    });
  }
  CreateEditApplicationForm() {
    this.employeeForm = this.fb.group(
      {
        firstName: [''],
        lastName: [''],
        employeeName: [''],
        AppId: ['', [Validators.required]],
        ATIN: [''],
        ATID: [''],
        EmployeeId: [''],
        AppType: ['150'],
        classification: [''],
        AppStatusCode: ['', [Validators.required]],
        PlantId: [''],
        ShiftId: [''],
        AppAreaId: [''],
        EmpSignDate: ['', [Validators.required]],
        appStatusDate: ['', [Validators.required]],
        //LastUpdatedDate: [new Date()],
        LastUpdatedBy: [],
      },
      {
        validator: this._commonApplicationService.dateLessThan(
          'EmpSignDate',
          'appStatusDate'
        ),
      }
    );
    this.isEdit = false;
  }

  LoadApplicationData(changes: SimpleChanges) {
    this.empSelected =
      (changes.selectedEmp && changes.selectedEmp.currentValue) || '';
    if (this.empSelected === '') {
      this.empSelected =
        (changes.selectedEmp && changes.selectedEmp.previousValue) || '';
    }
    this.maxDate = this._commonApplicationService.formatDate(this.currentDate);
    this.employeeForm.get('AppId')?.patchValue(this.empSelected.appId);
    this.employeeForm
      .get('employeeName')
      ?.patchValue(
        this.empSelected.lastName +
          ', ' +
          this.empSelected.firstName +
          ' ' +
          this.empSelected.middleName
      );
    this.employeeForm.get('firstName')?.patchValue(this.empSelected.firstName);
    this.employeeForm.get('lastName')?.patchValue(this.empSelected.lastName);
    this.employeeForm.get('aTin')?.patchValue(this.empSelected.atin);
    this.employeeForm
      .get('ATID')
      ?.patchValue(this.stringToUpperCase(this.empSelected.atid));
    this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.empSelected.employeeId);
    this.employeeForm
      .get('classification')
      ?.patchValue(this.empSelected.classification);
    this.employeeForm
      .get('AppStatusCode')
      ?.patchValue(this.removeAfterChar(this.empSelected.appStatusCode));
    this.employeeForm
      .get('PlantId')
      ?.patchValue(this.stringIn2Digits(this.empSelected.plantId));
    this.employeeForm
      .get('ShiftId')
      ?.patchValue(
        this.empSelected.shiftId === '0' || this.empSelected.shiftId === 'A'
          ? (this.empSelected.shiftId = 'A')
          : this.empSelected.shiftId
      );
    this.employeeForm.get('AppAreaId')?.patchValue(this.empSelected.areaId);
    this.employeeForm
      .get('EmpSignDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(this.empSelected.empSignDate)
      );
    this.employeeForm
      .get('appStatusDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.empSelected.appStatusDate
        )
      );
    this.statusCancelled = false;
    this.statusClosed = false;
    this.invalidStatus = false;
  }

  clear() {
    this.clean.emit('');
    this.closeModal.nativeElement.click();
    this.loadApplicationDataAfterReset();
  }

  loadApplicationDataAfterReset() {
    this.maxDate = this._commonApplicationService.formatDate(this.currentDate);
    this.employeeForm.get('AppId')?.patchValue(this.empSelected.appId);
    this.employeeForm.get('firstName')?.patchValue(this.empSelected.firstName);
    this.employeeForm.get('lastName')?.patchValue(this.empSelected.lastName);
    this.employeeForm.get('aTin')?.patchValue(this.empSelected.atin);
    this.employeeForm
      .get('ATID')
      ?.patchValue(this.stringToUpperCase(this.empSelected.atid));
    this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.empSelected.employeeId);
    this.employeeForm
      .get('classification')
      ?.patchValue(this.empSelected.classification);
    this.employeeForm
      .get('AppStatusCode')
      ?.patchValue(this.removeAfterChar(this.empSelected.appStatusCode));
    this.employeeForm
      .get('PlantId')
      ?.patchValue(this.stringIn2Digits(this.empSelected.plantId));
    this.employeeForm
      .get('ShiftId')
      ?.patchValue(
        this.empSelected.shiftId === '0' || this.empSelected.shiftId === 'A'
          ? (this.empSelected.shiftId = 'A')
          : this.empSelected.shiftId
      );
    this.employeeForm.get('AppAreaId')?.patchValue(this.empSelected.areaId);
    this.employeeForm
      .get('EmpSignDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(this.empSelected.empSignDate)
      );
    this.employeeForm
      .get('appStatusDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.empSelected.appStatusDate
        )
      );
    this.statusCancelled = false;
    this.statusClosed = false;
    this.invalidStatus = false;
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (new Date(f?.value) > new Date(t?.value)) {
        return {
          dates: "Status date shouldn't be earlier than Employee sign date",
        };
      }
      return {};
    };
  }

  stringIn2Digits(stringNumber: number) {
    let resultString = '';
    if (stringNumber.toString().length === 1) {
      resultString = '0' + stringNumber;
    } else {
      return stringNumber.toString();
    }
    return resultString;
  }

  removeAfterChar(value: string) {
    let resultString = '';
    if (value != null && value != '') {
      let tempString = value.split('[')[0];
      resultString = tempString.trim();
    }
    return resultString;
  }

  stringToUpperCase(value: string) {
    let resultString = '';
    if (value != null && value != '') {
      resultString = value.toUpperCase();
    }
    return resultString;
  }

  //function to update application
  UpdateApplication() {
    this.employeeForm
      .get('LastUpdatedBy')
      ?.patchValue(this.userName.toString().replace('@alsn.com', ''));
    this.fs.Edit150Application(this.employeeForm.value).subscribe(
      (_data) => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 400) {
          this.validationError();
        }
        if (_error.status === 406) {
          this.validationError();
        } else {
          this.badRequestAlert();
        }
      }
    );
  }

  // function to validate app status code from input field
  checkStatus(e: any) {
    const statuscode = this.employeeForm.get('AppStatusCode');

    statuscode?.valueChanges.subscribe(() => {
      statuscode?.patchValue(statuscode.value.toUpperCase(), {
        emitEvent: false,
      });
    });

    var statusEntered = e.target.value;
    if (statusEntered.toUpperCase().includes('CANCELLED')) {
      this.statusCancelled = true;
      this.statusClosed = false;
      this.invalidStatus = false;
    } else if (statusEntered.toUpperCase().includes('CLOSED')) {
      this.statusCancelled = false;
      this.statusClosed = true;
      this.invalidStatus = false;
      if (statusEntered.toUpperCase().includes('CLOSED -')) {
        //let appStatusCode = this.employeeForm.get('AppStatusCode')?.value;
        let statusCode = statusEntered.split('-')[2].trim();
        for (let i = 0; i < this.statusCodes.length; i++) {
          if (statusCode === this.statusCodes[i]) {
            this.statusCancelled = false;
            this.statusClosed = false;
            this.invalidStatus = false;
            break;
          } else {
            this.statusClosed = true;
          }
        }
      }
    } else if (
      !statusEntered.toUpperCase().includes('CLOSED') ||
      !statusEntered.toUpperCase().includes('OPEN') ||
      !statusEntered.toUpperCase().includes('CANCELLED')
    ) {
      this.statusClosed = false;
      this.statusCancelled = false;
      this.invalidStatus = true;
    }
    if (statusEntered.toUpperCase().includes('OPEN')) {
      this.statusClosed = false;
      this.invalidStatus = false;
      this.statusCancelled = false;
    }
  }

  // function to get shifts
  getShifts() {
    this.td.getShiftsList().subscribe((data) => {
      this.shifts = data;
    });
  }

  // function to get plants
  getplants() {
    this.fs.getPlantsList().subscribe((data) => {
      this.plants = data;
    });
  }

  // function to get areas
  getAreas() {
    const areaObject = {
      areaId: 0,
      areaDescription: 'Any',
    };
    this.fs.getAreasList().subscribe((data) => {
      this.areas = data;
      this.areas.splice(0, 2);
      this.areas.push(areaObject);
    });
  }

  //swal success alert
  successAlert() {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'The Application has been Updated Successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  // swal fail alert
  validationError() {
    Swal.fire({
      title: 'Cannot Edit application',
      text: 'Cannot make this application as OPEN',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }

  // swal bad request alert
  badRequestAlert() {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: 'An Error occured while processing.Please try again.',
    });
  }
}
