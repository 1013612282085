import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RoleMatchService {
  constructor() { }

  roleMatch(allowedRoles: any[]): boolean {
    var isMatch = false;
    var userRoles: string = JSON.parse(localStorage.getItem('role') || '{}');
    for (let i = 0; i < allowedRoles.length; i++) {
      if (allowedRoles[i].indexOf(userRoles) > -1) {
        isMatch = true;
        return true;
      }
    }
    return isMatch;
  }
}
