import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegionDataService } from 'src/app/Services/TableMaintenance/RegionData/region-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-region-data',
  templateUrl: './edit-region-data.component.html',
  styleUrls: ['./edit-region-data.component.css'],
})
export class EditRegionDataComponent {
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;
  @Input() userName: any;
  @Input() selectedRegion: any;
  @Output() isSucess = new EventEmitter();
  regionForm!: FormGroup;
  regionSelected: any;
  Status: any[] = [
    {
      data: [
        {
          statusType: 'A',
          statusName: 'Active',
        },
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
      ],
      default: 'Select One',
    },
  ];
  constructor(private fb: FormBuilder, private rds: RegionDataService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.createForm();
    this.loadData(changes);
  }

  createForm() {
    this.regionForm = this.fb.group({
      RegionId: ['', [Validators.required]],
      RegionDescription: ['', Validators.required],
      Status: ['', [Validators.required]],
      LastUpdateBy: [this.userName],
    });
  }

  loadData(changes: SimpleChanges) {
    this.regionSelected =
      (changes.selectedRegion && changes.selectedRegion.currentValue) || '';
    if (this.regionSelected === '') {
      this.regionSelected =
        (changes.selectedRegion && changes.selectedRegion.previousValue) || '';
    }
    this.regionForm.get('RegionId')?.patchValue(this.regionSelected.regionId);
    this.regionForm
      .get('RegionDescription')
      ?.patchValue(this.regionSelected.regionDescription);
    this.regionForm.get('Status')?.patchValue(this.regionSelected.status);
  }

  submitRegion() {
    this.rds.editRegionData(this.regionForm.value).subscribe(
      () => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 500) {
          this.badRequestAlert(_error.error);
        } else {
          this.validationError(_error.error);
        }
      }
    );
  }

  clear() {
    this.closeModal.nativeElement.click();
    this.createForm();
    this.loadCancelledData();
  }

  loadCancelledData() {
    this.regionForm.get('RegionId')?.patchValue(this.regionSelected.regionId);
    this.regionForm
      .get('RegionDescription')
      ?.patchValue(this.regionSelected.regionDescription);
    this.regionForm.get('Status')?.patchValue(this.regionSelected.status);
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Record has been updated successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
