import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { Paragraph19Service } from 'src/app/Services/Applications/Paragraph19/paragraph19.service';
import { DateService } from 'src/app/Services/Date/date.service';
import { RuleEngineService } from 'src/app/Services/Ruleengine/rule-engine.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-paragraph19-application',
  templateUrl: './edit-paragraph19-application.component.html',
  styleUrls: ['./edit-paragraph19-application.component.css'],
})
export class EditParagraph19ApplicationComponent implements OnInit {
  @Input() loggedInUser: any;
  @Input() selectedEmp: any;
  @Input() isEdit: boolean = false;
  @Input() title: any;
  @Input() userInformation: any;
  @Output() clean = new EventEmitter();
  @Output() isSucess = new EventEmitter();
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;

  userName: string = '';
  employeeForm!: FormGroup;
  applicationSelected: any;
  usersData: any;
  statusCancelled: boolean = false;
  statusClosed: boolean = false;
  invalidStatus: boolean = false;
  classifications: any;
  currentJobClassification: any;
  maxDate: string = '';
  currentDate = new Date();
  statusCodes: string[] = [];
  constructor(
    private fb: FormBuilder,
    private _user: UsergroupService,
    private p19: Paragraph19Service,
    private _commonApplicationService: CommonService,
    private _ruleEngineService: RuleEngineService,
    private _sweetAlertService: SweetAlertService,
    private _dateService: DateService
  ) {}

  ngOnInit(): void {
    this.getUserInfo();
    this.getClassifications();
    this.getEmpStatusCodes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.CreateEditApplicationForm();
    this.LoadApplicationData(changes);
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
    });
  }

  getEmpStatusCodes() {
    this._ruleEngineService.getEmpStatusCode().subscribe((data) => {
      this.statusCodes = data;
    });
  }

  CreateEditApplicationForm() {
    this.employeeForm = this.fb.group(
      {
        employeeName: [''],
        firstName: [''],
        lastName: [''],
        AppId: ['', [Validators.required]],
        ATIN: [''],
        ATID: [''],
        EmployeeId: [''],
        AppType: ['P19'],
        currentClassification: [''],
        currentDepartment: [''],
        currentShift: [''],
        jobCodeId: ['', [Validators.required, this.CheckLeaderClassification]],
        EmpSignDate: ['', [Validators.required]],
        EnteredBy: [],
        AppStatusCode: ['', [Validators.required]],
        AppStatusDate: ['', [Validators.required]],
        LastUpdatedBy: [
          this.userInformation.userName.toString().replace('@alsn.com', ''),
        ],
      },
      {
        validator: this._commonApplicationService.dateLessThan(
          'EmpSignDate',
          'AppStatusDate'
        ),
      }
    );
    this.isEdit = false;
  }

  CheckLeaderClassification(control: FormControl) {
    let jobClassification = control.value;
    let checkChar = jobClassification.charAt(jobClassification.length - 1);
    if (checkChar === 'L') {
      return {
        leaderClassificationError:
          'The selected classification cannot be a leader classification',
      };
    }
    return null;
  }

  LoadApplicationData(changes: SimpleChanges) {
    this.applicationSelected =
      (changes.selectedEmp && changes.selectedEmp.currentValue) || '';
    if (this.applicationSelected === '') {
      this.applicationSelected =
        (changes.selectedEmp && changes.selectedEmp.previousValue) || '';
    }
    this.maxDate = this._commonApplicationService.formatDate(this.currentDate);
    this.employeeForm
      .get('employeeName')
      ?.patchValue(
        this.applicationSelected.lastName +
          ', ' +
          this.applicationSelected.firstName +
          ' ' +
          this.applicationSelected.middleName
      );
    this.employeeForm.get('AppId')?.patchValue(this.applicationSelected.appId);
    this.employeeForm
      .get('firstName')
      ?.patchValue(this.applicationSelected.firstName);
    this.employeeForm
      .get('lastName')
      ?.patchValue(this.applicationSelected.lastName);
    this.employeeForm.get('aTin')?.patchValue(this.applicationSelected.atin);
    this.employeeForm
      .get('ATID')
      ?.patchValue(
        this._commonApplicationService.stringToUpperCase(
          this.applicationSelected.atid
        )
      );
    this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.applicationSelected.employeeId);
    this.employeeForm
      .get('jobCodeId')
      ?.patchValue(this.applicationSelected.jobCodeId);
    this.employeeForm
      .get('EnteredBy')
      ?.patchValue(this.applicationSelected.enteredBy);
    this.employeeForm
      .get('AppStatusCode')
      ?.patchValue(
        this._commonApplicationService.removeAfterChar(
          this.applicationSelected.appStatusCode
        )
      );
    this.employeeForm
      .get('EmpSignDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.applicationSelected.empSignDate
        )
      );
    this.employeeForm
      .get('AppStatusDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.applicationSelected.appStatusDate
        )
      );

    this.p19
      .searchFilterApplicationUser({
        employeeId: this.applicationSelected.employeeId,
      })
      .subscribe((data) => {
        this.usersData = data[0];
        this.currentJobClassification = this.usersData.jobCodeId;
        this.employeeForm
          .get('currentClassification')
          ?.patchValue(
            this.usersData.jobCodeId + '-' + this.usersData.jobCodeDescription
          );
        this.employeeForm
          .get('currentDepartment')
          ?.patchValue(this.usersData.deptId);
        this.employeeForm
          .get('currentShift')
          ?.patchValue(this.usersData.shiftDescription);
      });
    //this.statusCancelled = false;
    this.statusClosed = false;
    this.invalidStatus = false;
  }

  getClassifications() {
    this.p19.getClassificationList().subscribe((data) => {
      this.classifications = data;
    });
  }

  clear() {
    this.clean.emit('');
    this.closeModal.nativeElement.click();
    this.loadApplicationDataAfterReset();
  }

  loadApplicationDataAfterReset() {
    this.maxDate = this._commonApplicationService.formatDate(this.currentDate);
    this.employeeForm
      .get('employeeName')
      ?.patchValue(
        this.applicationSelected.firstName +
          ' ' +
          this.applicationSelected.lastName
      );
    this.employeeForm.get('AppId')?.patchValue(this.applicationSelected.appId);
    this.employeeForm
      .get('firstName')
      ?.patchValue(this.applicationSelected.firstName);
    this.employeeForm
      .get('lastName')
      ?.patchValue(this.applicationSelected.lastName);
    this.employeeForm.get('aTin')?.patchValue(this.applicationSelected.atin);
    this.employeeForm
      .get('ATID')
      ?.patchValue(
        this._commonApplicationService.stringToUpperCase(
          this.applicationSelected.atid
        )
      );
    this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.applicationSelected.employeeId);
    this.employeeForm
      .get('jobCodeId')
      ?.patchValue(this.applicationSelected.jobCodeId);
    this.employeeForm
      .get('AppStatusCode')
      ?.patchValue(
        this._commonApplicationService.removeAfterChar(
          this.applicationSelected.appStatusCode
        )
      );
    this.employeeForm
      .get('EmpSignDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.applicationSelected.empSignDate
        )
      );
    this.employeeForm
      .get('AppStatusDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.applicationSelected.appStatusDate
        )
      );

    this.p19
      .searchFilterApplicationUser({
        employeeId: this.applicationSelected.employeeId,
      })
      .subscribe((data) => {
        this.usersData = data[0];
        this.currentJobClassification = this.usersData.jobCodeId;
        this.employeeForm
          .get('currentClassification')
          ?.patchValue(
            this.usersData.jobCodeId + '-' + this.usersData.jobCodeDescription
          );
      });
    this.statusCancelled = false;
    this.statusClosed = false;
    this.invalidStatus = false;
  }

  updateApplication() {
    let prevStatusCode = this._commonApplicationService.removeAfterChar(
      this.applicationSelected.appStatusCode
    );
    let newStatusCode = this.employeeForm.get('AppStatusCode')?.value;
    if (
      prevStatusCode.toLowerCase() === 'cancelled' &&
      newStatusCode.toLowerCase() === 'open'
    ) {
      this.CancelToOpenError();
    } else {
      let appliedClassification = this.employeeForm.get('jobCodeId')?.value;
      let currentClassification = this.employeeForm.get(
        'currentClassification'
      )?.value;
      // if ((this._commonApplicationService.formatDate(this.applicationSelected.empSignDate) != this._commonApplicationService.formatDate(this.employeeForm.get('EmpSignDate')?.value)) ||
      //   (this._commonApplicationService.formatDate(this.applicationSelected.appStatusDate) != this._commonApplicationService.formatDate(this.employeeForm.get('AppStatusDate')?.value))) {
      //   var dt = this.employeeForm.get('EmpSignDate')?.value;
      //   var empDt = new Date(dt);
      //   var empSignDtInUTC = this._dateService.GetUTCDate(empDt);

      //   var appDt = this.employeeForm.get('AppStatusDate')?.value;
      //   var appStsDt = new Date(appDt);
      //   var appStatusDateInUTC = this._dateService.GetUTCDate(appStsDt);

      //   this.employeeForm.patchValue({ EmpSignDate: empSignDtInUTC });
      //   this.employeeForm.patchValue({ AppStatusDate: appStatusDateInUTC });
      // }
      if (this.currentJobClassification === appliedClassification) {
        this._sweetAlertService.validationError(
          'error',
          'ERROR',
          'Cannot apply for current classification.'
        );
      } else {
        this.p19.EditP19Application(this.employeeForm.value).subscribe(
          (_data) => {
            this.clear();
            this._sweetAlertService.successAlert(
              'center',
              'success',
              'The Application has been Updated Successfully.',
              false,
              1500
            );
            this.isSucess.emit(true);
          },
          (_error: HttpErrorResponse) => {
            if (_error.status === 400) {
              this.validationError();
            }
            if (_error.status === 406) {
              if (appliedClassification !== currentClassification) {
                this._sweetAlertService.validationError(
                  'error',
                  'Cannot edit application',
                  'Cannot duplicate an open application.'
                );
              } else {
                this._sweetAlertService.validationError(
                  'error',
                  'Cannot edit application',
                  'Cannot make this application as OPEN.'
                );
              }
            } else {
              this._sweetAlertService.validationError(
                'error',
                'ERROR',
                'An Error occured while processing.Please try again.'
              );
            }
          }
        );
      }
    }
  }

  checkStatus(e: any) {
    const statuscode = this.employeeForm.get('AppStatusCode');

    statuscode?.valueChanges.subscribe(() => {
      statuscode?.patchValue(statuscode.value.toUpperCase(), {
        emitEvent: false,
      });
    });

    var statusEntered = e.target.value;
    if (statusEntered.toUpperCase().includes('CANCELLED')) {
      this.statusCancelled = true;
      this.statusClosed = false;
      this.invalidStatus = false;
    } else if (statusEntered.toUpperCase().includes('CLOSED')) {
      this.statusClosed = true;
      this.invalidStatus = false;
      if (statusEntered.toUpperCase().includes('CLOSED -')) {
        //let appStatusCode = this.employeeForm.get('AppStatusCode')?.value;
        let statusCode = statusEntered.split('-')[2].trim();
        for (let i = 0; i < this.statusCodes.length; i++) {
          if (statusCode === this.statusCodes[i]) {
            this.statusCancelled = false;
            this.statusClosed = false;
            this.invalidStatus = false;
            break;
          } else {
            this.statusClosed = true;
          }
        }
      }
    } else if (
      !statusEntered.toUpperCase().includes('CLOSED') ||
      !statusEntered.toUpperCase().includes('OPEN') ||
      !statusEntered.toUpperCase().includes('CANCELLED')
    ) {
      this.statusCancelled = false;
      this.statusClosed = false;
      this.invalidStatus = true;
    }
    if (statusEntered.toUpperCase().includes('OPEN')) {
      this.statusClosed = false;
      this.invalidStatus = false;
    }
  }

  // swal fail alert
  validationError() {
    Swal.fire({
      title: 'Cannot Edit application',
      text: 'Cannot make this application as OPEN',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }

  CancelToOpenError() {
    Swal.fire({
      title: 'Cannot modify the current application',
      text: 'Cannot make CANCELLED application as OPEN.',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
