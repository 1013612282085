import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ZoneDataService } from 'src/app/Services/TableMaintenance/ZoneData/zone-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-zone-data',
  templateUrl: './update-zone-data.component.html',
  styleUrls: ['./update-zone-data.component.css'],
})
export class UpdateZoneDataComponent {
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;
  @Input() userName: any;
  @Input() selectedZone: any;
  @Output() isSucess = new EventEmitter();
  ZoneDataForm!: FormGroup;
  zoneSelected: any;
  representativePhoneFormat: boolean = false;
  alternatePhoneFormat: boolean = false;
  Status: any[] = [
    {
      data: [
        {
          statusType: 'A',
          statusName: 'Active',
        },
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
      ],
      default: 'Select One',
    },
  ];
  constructor(
    private fb: FormBuilder,
    private zoneDataService: ZoneDataService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.createForm();
    this.loadData(changes);
  }
  createForm() {
    this.ZoneDataForm = this.fb.group({
      ZONEID: ['', [Validators.required]],
      ZONEDESCRIPTION: [''],
      REPRESENTATIVE_EMPLOYEEID: ['', Validators.required],
      REPRESENTATIVEPHONE: [null, this.checkRepresentativePhoneStatus],
      STATUS: ['', [Validators.required]],
      LASTUPDATEBY: [this.userName],
    });
  }

  loadData(changes: SimpleChanges) {
    this.zoneSelected =
      (changes.selectedZone && changes.selectedZone.currentValue) || '';
    if (this.zoneSelected === '') {
      this.zoneSelected =
        (changes.selectedZone && changes.selectedZone.previousValue) || '';
    }
    this.ZoneDataForm.get('ZONEID')?.patchValue(this.zoneSelected.zoneid);
    this.ZoneDataForm.get('ZONEDESCRIPTION')?.patchValue(
      this.zoneSelected.zonedescription
    );
    this.ZoneDataForm.get('REPRESENTATIVE_EMPLOYEEID')?.patchValue(
      this.zoneSelected.representativE_EMPLOYEEID
    );
    this.ZoneDataForm.get('REPRESENTATIVEPHONE')?.patchValue(
      this.zoneSelected.representativephone
    );
    this.ZoneDataForm.get('STATUS')?.patchValue(this.zoneSelected.status);
  }
  submitZoneData() {
    this.zoneDataService.editZoneData(this.ZoneDataForm.value).subscribe(
      () => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 500) {
          this.badRequestAlert(_error.error);
        } else {
          this.validationError(_error.error);
        }
      }
    );
  }
  clear() {
    this.closeModal.nativeElement.click();
    this.createForm();
    this.loadCancelledData();
  }
  loadCancelledData() {
    this.representativePhoneFormat = false;
    this.ZoneDataForm.get('ZONEID')?.patchValue(this.zoneSelected.zoneid);
    this.ZoneDataForm.get('ZONEDESCRIPTION')?.patchValue(
      this.zoneSelected.zonedescription
    );
    this.ZoneDataForm.get('REPRESENTATIVE_EMPLOYEEID')?.patchValue(
      this.zoneSelected.representativE_EMPLOYEEID
    );
    this.ZoneDataForm.get('REPRESENTATIVEPHONE')?.patchValue(
      this.zoneSelected.representativephone
    );
    this.ZoneDataForm.get('STATUS')?.patchValue(this.zoneSelected.status);
  }

  checkRepresentativePhoneStatus(control: FormControl) {
    var phoneEntered = control.value;
    if (
      !phoneEntered?.includes('-') &&
      phoneEntered !== null &&
      phoneEntered !== ''
    ) {
      return {
        representativePhoneFormatError:
          "You must enter the entire representative's phone number or leave it blank.",
      };
    }
    return null;
  }
  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Record has been updated successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }
  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
