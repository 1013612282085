import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { MSALserviceService } from '../../app/Services/MSALservice/msalservice.service';
import { CommonUtility } from 'src/common/commonUtility'

@Injectable({
  providedIn: 'root'
})
export class CommonSearchService {
  private readonly apiOvertimeType = '/GetOvertimeType';
  commonUtility = new CommonUtility();

//formBuilder: FormBuilder
  constructor(private fb: FormBuilder, private _http: HttpClient, private appCom: MSALserviceService) {
  //  this.formBuilder=fb;
   }

  // commonSearchForm(SectionDemoGraphicFirstName:string): FormGroup { 
  //   let group = {
  //     'name': ['', [Validators.required]],
  //     'surname': ['', [Validators.required]],
  //     'email': ['', [Validators.required]]
  //   };

  //   let middlename = true;

  //   if(middlename) {
  //     group['middlename'] = ['', [Validators.required]];
  //   }

  //   return group; 
  // } 
  commonSearchForm(): FormGroup { 
    const fg = this.fb.group({
     txtBox : ['', Validators.required],
     dateControl: ['', Validators.required],
    drpDown : ['', Validators.required]
    });
    //fg.addControl("FirstNameControl", new FormControl('', [Validators.required]));

    //fg.addControl("FirstNameControl",  this.guestControl());
    return fg;
   } 



  // getOvertimeType(): Observable<any> {
  //   const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
  //   return this._http.get<any>(
  //     `${environment.overTimeUrl}` + this.apiOvertimeType,
  //     { headers }
  //   );
  // }

}
