<div class="container-fluid app-box" style="background-color: #ffffff">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol *ngIf="!isAll150App" class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Transfer Applications</li>
        <li class="breadcrumb-item brd-li brd-li-child">
          Facilities Service Plant
        </li>
      </ol>
      <ol *ngIf="isAll150App" class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Views</li>
        <li class="breadcrumb-item brd-li brd-li-child">
          View All 150 Applications
        </li>
      </ol>
    </nav>
    <div
      style="float: right; position: absolute; right: 276px"
      *ngIf="emloyeeData !== null && emloyeeData.length > 0"
    >
      <button
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        style="height: 34px; margin-left: 15px"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
    </div>
  </div>
  <div *ngIf="isAdmin() || isAll150App">
    <div class="row content-container mt-2 mb-2">
      <nav
        *ngIf="!isAll150App"
        class="navbar navbar-expand-lg navbar-light bg-light applicationcss"
      >
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto"></ul>
          <form class="form-inline my-2 my-lg-0">
            <input
              class="select-radio"
              checked="checked"
              name="tool-and-die"
              type="radio"
              Id="1"
              (change)="onAppTypeSelected(1)"
            />
            <label class="select-label" for="1">Existing Application</label>
            <input
              *ngIf="views[0].allowAdd"
              class="select-radio"
              name="tool-and-die"
              type="radio"
              Id="2"
              (change)="onAppTypeSelected(2)"
            />
            <label *ngIf="views[0].allowAdd" class="select-label" for="2"
              >Add Application</label
            >
          </form>
        </div>
        <br />
      </nav>
      <!-- global search filter starts -->
      <div class="pt-2 div-color">
        <div class="search-divider-div filtercss">
          <p class="search-divder-p"></p>
          <hr class="search-divider-hr" />
          <!-- filer -->
          <div
            class="jumbotron jumb-new jumb-newcontrols"
            style="margin-bottom: 0px !important; padding: 2rem 2rem"
            *ngIf="showFilter"
          >
            <div class="row" display="float">
              <div class="col">
                <app-filter
                  [filterConfig]="filterConfig"
                  (formcontrolobj)="getbyenter($event)"
                  (filterValue)="getFilterValue($event)"
                >
                </app-filter>
                <!-- <hr class="my-4"> -->
              </div>
              <div class="col-md-1 mr-5" style="padding-right: 80px">
                <a
                  class="btn btn-lg search-button"
                  (click)="search()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <div
            class="jumbotron jumb-new"
            style="margin-bottom: 0px !important"
            *ngIf="!showFilter"
          >
            <div class="row" display="float">
              <div class="col">
                <app-filter
                  [filterConfigAdd]="filterConfigAdd"
                  (formcontrolobj)="getbyenterforuser($event)"
                  (filterValue)="getFilterDataUser($event)"
                >
                </app-filter>
                <!-- <hr class="my-4"> -->
              </div>
              <div class="col-md-1 mr-5">
                <a
                  class="btn btn-lg search-button"
                  (click)="searchbyUser()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <!-- filter ends -->
        </div>
        <div class="container-divider-div"></div>
        <!-- pt-5 -->
        <div class="" style="margin-top: 5px">
          <!--For application-->
          <!-- <div *ngIf="isAll150App" style="height: 11px;
          background: white;
          position: sticky;
          top: 89px;"></div> -->

          <div *ngIf="!isApplication">
            <!-- <div style="
    position: sticky;
    background-color: white;
    position: sticky;
    top: 88px; height: 47px;
    padding-top: 8px;
    padding-bottom: 8px;" *ngIf="emloyeeData !==null && emloyeeData   .length > 0">
                            <button style="margin-right: 0.5rem;border-color: #003AA0;" class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0" (click)="exportElmToExcel()">Export to Excel</button>
                        </div> -->
            <!-- <div *ngIf="!isAll150App" class="info-label divcrudbtns">
                            <p style="text-align: left" class="p-margin">
                                <div *ngIf='views[0].allowUpdate'>
                                    <button [disabled]="disablededit" [ngClass]="disablededit ? 'disablededitcolors' : ''" *ngIf="isApplicationSelected() && !EditButtonDisabled  || (!EditButtonDisabled1)" (click)="edit()" data-toggle="modal" style="margin-right: 0.5rem" [attr.data-target]="modalId"
                                    data-backdrop="false"
                                        class="
                    btn
                    buttoncolours
                    crudbuttons
                    pull-right
                    my-2 my-sm-0
                  ">
                                    Edit
                                </button>
                                </div>
                                <div *ngIf='views[0].allowDelete'>
                                    <button [disabled]="disableddelete" [ngClass]="disableddelete ? 'disablededitcolors' : ''" *ngIf="isApplicationSelected() || !DeleteButtonDisabled1" data-toggle="modal" (click)="deleteApplication()" style="margin-right: 0.5rem" [attr.data-target]="modalId"
                                    data-backdrop="false"
                                        class="
                    btn
                    buttoncolours
                    crudbuttons
                    pull-right
                    my-2 my-sm-0
                  ">
                                    Delete
                                </button>
                                </div>
                                <div *ngIf='views[0].allowCancel'>
                                    <button [disabled]="disabledcancel" [ngClass]="disabledcancel ? 'disablededitcolors' : ''" *ngIf="!CancelButtonDisabled && !returnstatus && isApplicationSelected()  || (!CancelButtonDisabled1)" (click)="cancelApplication()" data-toggle="modal" [attr.data-target]="modalId"
                                    data-backdrop="false"
                                        style="margin-right: 0.5rem" class="
                    btn
                    buttoncolours
                    crudbuttons
                    pull-right
                    my-2 my-sm-0
                  ">
                                    Cancel
                                </button>
                                </div>

                        </div> -->
            <!-- this is the export to excel table -->
            <table
              class="table table-striped"
              *ngIf="
                !isDataExportableView &&
                emloyeeData !== null &&
                emloyeeData.length > 0
              "
              #ViewTD150ViewExportTable
            >
              <thead *ngIf="!isAll150App" class="table-header">
                <!-- <th scope="col" *ngIf="!isAll150App">Select</th> -->
                <tr>
                  <!-- <th scope="col">Name</th>
                                    <th scope="col">Employee Id</th>
                                    <th scope="col">Classification</th>
                                    <th scope="col">Employee Status</th>
                                    <th *ngIf="isAll150App" scope="col">Plant Skill Date:</th>
                                    <th *ngIf="isAll150App" scope="col">GM Skill Date:</th>
                                    <th *ngIf="isAll150App" scope="col">Plant Seniority Date:
                                    </th>
                                    <th *ngIf="isAll150App" scope="col">GM Seniority Date:</th>
                                    <th scope="col">Plant</th>
                                    <th *ngIf="showArea" scope="col">Area</th>
                                    <th scope="col">Shift</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Date Signed</th> -->

                  <th scope="col">
                    <span (click)="onSortClick($event, 'lastName', 'string')"
                      >Name
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'lastName'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'lastName'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'lastName'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'employeeId', 'string')"
                      >Emp. Id
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'employeeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'employeeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'employeeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'jobCodeId', 'string')"
                      >Class
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'jobCodeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'jobCodeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'jobCodeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'empStatus', 'string')"
                      >Emp. Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'empStatus'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'empStatus'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'empStatus'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th *ngIf="isAll150App" scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'plantSkillDateSort', 'datetime')
                      "
                      >Plant Skill Date
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'plantSkillDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'plantSkillDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'plantSkillDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th *ngIf="isAll150App" scope="col">
                    <span
                      (click)="onSortClick($event, 'skillDateSort', 'datetime')"
                      >GM Skill Date
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'skillDateSort'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'skillDateSort'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'skillDateSort'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th *ngIf="isAll150App" scope="col">
                    <span
                      (click)="
                        onSortClick(
                          $event,
                          'plantSeniorityDateSort',
                          'datetime'
                        )
                      "
                      >Plant Sen Date
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !==
                            'plantSenioplantSeniorityDateSortrityDate'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th *ngIf="isAll150App" scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'seniorityDateSort', 'datetime')
                      "
                      >GM Sen Date
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'seniorityDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'seniorityDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'seniorityDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'plantDescription', 'string')
                      "
                      >Plant
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'plantDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'plantDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'plantDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th *ngIf="showArea" scope="col">
                    <span
                      (click)="onSortClick($event, 'areaDescription', 'string')"
                      >Area
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'areaDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'areaDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'areaDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'shiftDescription', 'string')
                      "
                      >Shift
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'shiftDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'appStatusCode', 'string')"
                      >Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'appStatusCode'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'appStatusCode'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'appStatusCode'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'empSignDateSort', 'string')"
                      >Date Signed
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'empSignDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <!--View all 150 applications  header-->
              <thead *ngIf="isAll150App" class="table-header150">
                <!-- <th scope="col" *ngIf="!isAll150App">Select</th> -->
                <tr>
                  <!-- <th scope="col">Name</th>
                                    <th scope="col">Employee Id</th>
                                    <th scope="col">Classification</th>
                                    <th scope="col">Employee Status</th>
                                    <th *ngIf="isAll150App" scope="col">Plant Skill Date:</th>
                                    <th *ngIf="isAll150App" scope="col">GM Skill Date:</th>
                                    <th *ngIf="isAll150App" scope="col">Plant Seniority Date:
                                    </th>
                                    <th *ngIf="isAll150App" scope="col">GM Seniority Date:</th>
                                    <th scope="col">Plant</th>
                                    <th *ngIf="showArea" scope="col">Area</th>
                                    <th *ngIf="showDept && isAll150App" scope="col">Dept</th>
                                    <th scope="col">Shift</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Date Signed</th> -->

                  <th scope="col">
                    <span (click)="onSortClick($event, 'lastName', 'string')"
                      >Name
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'lastName'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'lastName'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'lastName'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'employeeId', 'string')"
                      >Emp. Id
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'employeeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'employeeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'employeeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'jobCodeId', 'string')"
                      >Class
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'jobCodeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'jobCodeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'jobCodeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'empStatus', 'string')"
                      >Emp Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'empStatus'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'empStatus'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'empStatus'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" *ngIf="isAll150App">
                    <span
                      (click)="
                        onSortClick($event, 'plantSkillDateSort', 'Date')
                      "
                      >Plant Skill Date
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'plantSkillDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'plantSkillDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'plantSkillDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" *ngIf="isAll150App">
                    <span (click)="onSortClick($event, 'skillDateSort', 'Date')"
                      >GM Skill Date
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'skillDateSort'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'skillDateSort'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'skillDateSort'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" *ngIf="isAll150App">
                    <span
                      (click)="
                        onSortClick($event, 'plantSeniorityDateSort', 'Date')
                      "
                      >Plant Sen Date
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'plantSeniorityDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" *ngIf="isAll150App">
                    <span
                      (click)="
                        onSortClick($event, 'plantSeniorityDateSort', 'Date')
                      "
                      >GM Sen Date
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'plantSeniorityDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'seniorityDateSort', 'number')
                      "
                      >Plant
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'seniorityDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'seniorityDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'seniorityDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" *ngIf="showArea">
                    <span
                      (click)="onSortClick($event, 'areaDescription', 'string')"
                      >Area
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'areaDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'areaDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'areaDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" *ngIf="showDept && isAll150App">
                    <span (click)="onSortClick($event, 'deptId', 'string')"
                      >Dept
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'deptId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'deptId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'deptId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'shiftDescription', 'string')
                      "
                      >Shift
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'shiftDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'appStatusCode', 'string')"
                      >Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'appStatusCode'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'appStatusCode'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'appStatusCode'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'empSignDateSort', 'string')"
                      >Date Signed
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'empSignDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let emp of emloyeeData; let i = index"
                  class="table-container"
                >
                  <!-- <tr *ngFor="let emp of emloyeeData, let i = index;" class='table-container'> -->
                  <!-- <td class="tab-value" *ngIf="!isAll150App" style="text-align: center">
                                        <input type="radio" id="select" name="application" (click)="selectEmp(i, emp.employeeId)" />
                                    </td> -->
                  <td class="left-tab-value">
                    {{ emp.lastName ? emp.lastName : '-' }},&nbsp;{{
                      emp.firstName ? emp.firstName : '-'
                    }}&nbsp;{{ emp.middleName ? emp.middleName : '' }}
                    <!-- class='tab-value'> -->
                  </td>

                  <td class="tab-value">
                    {{ emp.employeeId ? emp.employeeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.jobCodeId ? emp.jobCodeId : '-' }}
                  </td>

                  <td class="tab-value">
                    {{ emp.empStatus ? emp.empStatus : '-' }}
                  </td>

                  <td *ngIf="isAll150App" class="tab-value">
                    {{
                      emp.plantSkillDate
                        ? (emp.plantSkillDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>

                  <td *ngIf="isAll150App" class="tab-value">
                    {{
                      emp.skillDate ? (emp.skillDate | date: 'MM/dd/yyyy') : '-'
                    }}
                  </td>

                  <td *ngIf="isAll150App" class="tab-value">
                    {{
                      emp.plantSeniorityDate
                        ? (emp.plantSeniorityDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>

                  <td *ngIf="isAll150App" class="tab-value">
                    {{
                      emp.seniorityDate
                        ? (emp.seniorityDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>

                  <td class="tab-value">
                    {{
                      emp.plantId === '00' ? emp.plantDescription : emp.plantId
                    }}
                  </td>

                  <td *ngIf="showArea" class="tab-value">
                    {{ emp.areaId !== '0' ? emp.areaDescription : 'Any' }}
                  </td>

                  <td *ngIf="showDept && isAll150App" class="tab-value">
                    {{ emp.deptId ? emp.deptId : '-' }}
                  </td>

                  <td class="tab-value">
                    {{ emp.shiftDescription ? emp.shiftDescription : '-' }}
                  </td>

                  <td class="left-tab-value">
                    {{ emp.appStatusCode ? emp.appStatusCode : '-' }}
                  </td>

                  <td class="tab-value">
                    {{ emp.empSignDate | date: 'MM/dd/yyyy' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- this is the main table -->
            <table
              class="table table-striped"
              *ngIf="emloyeeData !== null && emloyeeData.length > 0"
            >
              <thead *ngIf="!isAll150App" class="table-header">
                <!-- <th scope="col" *ngIf="!isAll150App">Select</th>
                                <th scope="col">Name 111</th>
                                <th scope="col">Employee Id</th>
                                <th scope="col">Classification</th>
                                <th scope="col">Employee Status</th>
                                <th *ngIf="isAll150App" scope="col">Plant Skill Date:</th>
                                <th *ngIf="isAll150App" scope="col">GM Skill Date:</th>
                                <th *ngIf="isAll150App" scope="col">Plant Seniority Date:
                                </th>
                                <th *ngIf="isAll150App" scope="col">GM Seniority Date:</th>
                                <th scope="col">Plant</th>
                                <th *ngIf="showArea" scope="col">Area</th>
                                <th scope="col">Shift</th>
                                <th scope="col">Status</th>
                                <th scope="col">Date Signed</th> -->

                <th scope="col" *ngIf="!isAll150App">Select</th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'lastName', 'string')"
                    >Name
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'lastName'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'lastName'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'lastName'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'employeeId', 'string')"
                    >Emp. Id
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'employeeId'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'employeeId'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'employeeId'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'jobCodeId', 'string')"
                    >Class
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'jobCodeId'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'jobCodeId'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'jobCodeId'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'empStatus', 'string')"
                    >Emp Status
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'empStatus'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'empStatus'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'empStatus'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th *ngIf="isAll150App" scope="col">
                  <span
                    (click)="
                      onSortClick($event, 'plantSkillDateSort', 'string')
                    "
                    >Plant Skill Date
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'plantSkillDateSort'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'plantSkillDateSort'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'plantSkillDateSort'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th *ngIf="isAll150App" scope="col">
                  <span (click)="onSortClick($event, 'skillDateSort', 'string')"
                    >GM Skill Date
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'skillDateSort'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'skillDateSort'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'skillDateSort'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th *ngIf="isAll150App" scope="col">
                  <span
                    (click)="
                      onSortClick($event, 'plantSeniorityDateSort', 'string')
                    "
                    >Plant Sen Date
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'plantSeniorityDateSort'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 &&
                        sortColumn === 'plantSeniorityDateSort'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'plantSeniorityDateSort'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th *ngIf="isAll150App" scope="col">
                  <span
                    (click)="onSortClick($event, 'seniorityDateSort', 'string')"
                    >GM Sen Date
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'seniorityDateSort'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'seniorityDateSort'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'seniorityDateSort'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'plantDescription', 'decimal')"
                    >Plant
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'plantDescription'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'plantDescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'plantDescription'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th *ngIf="showArea" scope="col">
                  <span
                    (click)="onSortClick($event, 'areaDescription', 'string')"
                    >Area
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'areaDescription'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'areaDescription'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'areaDescription'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'shiftDescription', 'string')"
                    >Shift
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'shiftDescription'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'shiftDescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'shiftDescription'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'appStatusCode', 'string')"
                    >Status
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'appStatusCode'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'appStatusCode'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'appStatusCode'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'empSignDateSort', 'string')"
                    >Date Signed
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'empSignDateSort'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'empSignDateSort'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'empSignDateSort'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
              </thead>
              <!--View all 150 applications  header-->
              <thead *ngIf="isAll150App" class="table-header150">
                <!-- <th scope="col" *ngIf="!isAll150App">Select</th>
                                <th scope="col">Name 222</th>
                                <th scope="col">Employee Id</th>
                                <th scope="col">Classification</th>
                                <th scope="col">Employee Status</th>
                                <th *ngIf="isAll150App" scope="col">Plant Skill Date:</th>
                                <th *ngIf="isAll150App" scope="col">GM Skill Date:</th>
                                <th *ngIf="isAll150App" scope="col">Plant Seniority Date:
                                </th>
                                <th *ngIf="isAll150App" scope="col">GM Seniority Date:</th>
                                <th scope="col">Plant</th>
                                <th *ngIf="showArea" scope="col">Area</th>
                                <th *ngIf="showDept && isAll150App" scope="col">Dept</th>
                                <th scope="col">Shift</th>
                                <th scope="col">Status</th>
                                <th scope="col">Date Signed</th> -->

                <th scope="col" *ngIf="!isAll150App">Select</th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'lastName', 'string')"
                    >Name
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'lastName'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'lastName'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'lastName'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'employeeId', 'string')"
                    >Emp. Id
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'employeeId'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'employeeId'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'employeeId'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'jobCodeId', 'string')"
                    >Class
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'jobCodeId'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'jobCodeId'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'jobCodeId'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'empStatus', 'string')"
                    >Emp Status
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'empStatus'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'empStatus'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'empStatus'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col" *ngIf="isAll150App">
                  <span
                    (click)="
                      onSortClick($event, 'plantSkillDateSort', 'datetime')
                    "
                    >Plant Skill Date
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'plantSkillDateSort'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'plantSkillDateSort'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'plantSkillDateSort'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col" *ngIf="isAll150App">
                  <span
                    (click)="onSortClick($event, 'skillDateSort', 'datetime')"
                    >GM Skill Date
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'skillDateSort'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'skillDateSort'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'skillDateSort'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col" *ngIf="isAll150App">
                  <span
                    (click)="
                      onSortClick($event, 'plantSeniorityDateSort', 'datetime')
                    "
                    >Plant Sen Date
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'plantSeniorityDateSort'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 &&
                        sortColumn === 'plantSeniorityDateSort'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'plantSeniorityDateSort'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col" *ngIf="isAll150App">
                  <span
                    (click)="
                      onSortClick($event, 'plantSeniorityDateSort', 'datetime')
                    "
                    >GM Sen Date:
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'plantSeniorityDateSort'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 &&
                        sortColumn === 'plantSeniorityDateSort'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'plantSeniorityDateSort'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="
                      onSortClick($event, 'seniorityDateSort', 'decimal')
                    "
                    >Plant
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'seniorityDateSort'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'seniorityDateSort'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'seniorityDateSort'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col" *ngIf="showArea">
                  <span
                    (click)="onSortClick($event, 'areaDescription', 'string')"
                    >Area
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'areaDescription'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'areaDescription'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'areaDescription'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col" *ngIf="showDept && isAll150App">
                  <span (click)="onSortClick($event, 'deptId', 'string')"
                    >Dept
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'deptId'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'deptId'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'deptId'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'shiftDescription', 'string')"
                    >Shift
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'shiftDescription'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'shiftDescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'shiftDescription'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'appStatusCode', 'string')"
                    >Status
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'appStatusCode'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'appStatusCode'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'appStatusCode'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'empSignDateSort', 'datetime')"
                    >Date Signed
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'empSignDateSort'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'empSignDateSort'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'empSignDateSort'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let emp of emloyeeData
                      | paginate
                        : {
                            id: 'joblist',
                            itemsPerPage: 20,
                            currentPage: Applicationpage,
                            totalItems: TotalApplicationCount
                          };
                    let i = index
                  "
                  class="table-container"
                >
                  <!-- <tr *ngFor="let emp of emloyeeData, let i = index;" class='table-container'> -->
                  <td
                    class="tab-value"
                    *ngIf="!isAll150App"
                    style="text-align: center"
                  >
                    <input
                      type="radio"
                      id="select"
                      name="application"
                      (click)="selectEmp(i, emp.employeeId)"
                    />
                  </td>
                  <td class="left-tab-value">
                    {{ emp.lastName ? emp.lastName : '-' }},&nbsp;{{
                      emp.firstName ? emp.firstName : '-'
                    }}&nbsp;{{ emp.middleName ? emp.middleName : '' }}
                    <!-- class='tab-value'> -->
                  </td>

                  <td class="tab-value">
                    {{ emp.employeeId ? emp.employeeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.jobCodeId ? emp.jobCodeId : '-' }}
                  </td>

                  <td class="tab-value">
                    {{ emp.empStatus ? emp.empStatus : '-' }}
                  </td>

                  <td *ngIf="isAll150App" class="tab-value">
                    {{
                      emp.plantSkillDate
                        ? (emp.plantSkillDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>

                  <td *ngIf="isAll150App" class="tab-value">
                    {{
                      emp.skillDate ? (emp.skillDate | date: 'MM/dd/yyyy') : '-'
                    }}
                  </td>

                  <td *ngIf="isAll150App" class="tab-value">
                    {{
                      emp.plantSeniorityDate
                        ? (emp.plantSeniorityDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>

                  <td *ngIf="isAll150App" class="tab-value">
                    {{
                      emp.seniorityDate
                        ? (emp.seniorityDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>

                  <td class="tab-value">
                    {{
                      emp.plantId === '00' ? emp.plantDescription : emp.plantId
                    }}
                  </td>

                  <td *ngIf="showArea" class="tab-value">
                    {{ emp.areaId !== '0' ? emp.areaDescription : 'Any' }}
                  </td>

                  <td *ngIf="showDept && isAll150App" class="tab-value">
                    {{ emp.deptId ? emp.deptId : '-' }}
                  </td>

                  <td class="tab-value">
                    {{ emp.shiftDescription ? emp.shiftDescription : '-' }}
                  </td>

                  <td class="left-tab-value">
                    {{ emp.appStatusCode ? emp.appStatusCode : '-' }}
                  </td>

                  <td class="tab-value">
                    {{ emp.empSignDate | date: 'MM/dd/yyyy' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="single-wrap d-flex justify-content-center"
              *ngIf="emloyeeData !== null && emloyeeData.length > 0"
            >
              <nav aria-label="Page navigation example">
                <pagination-controls
                  class="my-pagination"
                  id="joblist"
                  (pageChange)="Applicationpage = $event"
                  previousLabel=""
                  nextLabel=""
                >
                </pagination-controls>
              </nav>
            </div>
            <div *ngIf="!isApplication">
              <p *ngIf="!isApplicationDataExists">No existing records Found</p>
            </div>
          </div>
          <!--For user-->
          <!-- <div style="height: 15px; background: white; position: sticky; top: 140px;"></div> -->
          <div *ngIf="isApplication">
            <table
              class="table table-striped"
              *ngIf="userData !== null && userData.length > 0"
            >
              <thead
                style="position: sticky; top: 154px !important"
                class="table-header"
              >
                <th scope="col">Name</th>
                <th scope="col">Employee Id</th>
                <th scope="col">Classification</th>
                <th scope="col">Status</th>
                <th scope="col">Plant</th>
                <!-- <th scope="col">Area</th> -->
                <th scope="col">Shift</th>
                <!-- <th scope="col">Date Signed:</th> -->
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let emp of userData
                      | paginate
                        : {
                            id: 'joblist',
                            itemsPerPage: 20,
                            currentPage: UserPage,
                            totalItems: TotalApplicationCount
                          };
                    let i = index
                  "
                  class="table-container"
                >
                  <!-- <tr *ngFor="let emp of userData, let i = index;" class='table-container'> -->
                  <td class="left-tab-value" style="font-size: 14px">
                    <p
                      class="btn my-2 my-sm-0 click-add"
                      data-toggle="modal"
                      [attr.data-target]="modalId"
                      data-backdrop="false"
                      (click)="addEmployee(i, emp.employeeId)"
                    >
                      {{ emp.lastName ? emp.lastName : '-' }},&nbsp;{{
                        emp.firstName ? emp.firstName : '-'
                      }}&nbsp;{{ emp.middleName ? emp.middleName : '' }}
                    </p>
                  </td>
                  <td class="tab-value">
                    {{ emp.employeeId ? emp.employeeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.jobCodeId ? emp.jobCodeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.empStatus ? emp.empStatus : '-' }}
                  </td>
                  <td class="tab-value">
                    {{
                      emp.plantId === '00' ? emp.plantDescription : emp.plantId
                    }}
                  </td>
                  <!-- <td class='tab-value'>{{emp.areaDescription?emp.areaDescription: '-'}}</td> -->
                  <td class="tab-value">
                    {{ emp.shiftDescription ? emp.shiftDescription : '-' }}
                  </td>
                  <!-- <td class='tab-value'>{{emp.empSignDate | date}}</td> -->
                </tr>
              </tbody>
            </table>
            <div
              class="single-wrap d-flex justify-content-center"
              *ngIf="userData !== null && userData.length > 0"
            >
              <nav aria-label="Page navigation example">
                <pagination-controls
                  class="my-pagination"
                  id="joblist"
                  (pageChange)="UserPage = $event"
                  previousLabel=""
                  nextLabel=""
                >
                </pagination-controls>
              </nav>
            </div>
          </div>
          <div *ngIf="isApplication">
            <p *ngIf="!isUserDataExists">No User Found</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isAdmin() && !isAll150App && !isAppTD">
    <div
      *ngIf="userInformation !== undefined && userInformation !== null"
      class="row content-container mt-2 mb-2"
    >
      <!-- global search filter starts -->
      <div class="pt-2 div-color">
        <div class="search-divider-div">
          <p class="search-divder-p"></p>

          <div
            class="jumbotron jumb-new jumb-newcontrols"
            style="margin-bottom: 0px"
          >
            <div class="row userinfo-header-div" display="float">
              <div class="col">
                <h1 class="h1-label">
                  Welcome! {{ userInformation.firstName }}
                </h1>
                <!-- <h3> If you are not {{userInformation.firstName}} {{userInformation.lastName}}, Please Logout</h3> -->
              </div>
              <div class="col-md-1 mr-5 add-button-div">
                <button
                  *ngIf="!returnOpenStatus"
                  class="btn btn-lg search-button add-button-a button-align"
                  type="submit"
                  data-toggle="modal"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                  (click)="addApplication()"
                  role="button"
                >
                  Add Application
                </button>
              </div>
            </div>
          </div>
          <div class="empdescription-box">
            <div class="empdescription-table">
              <table>
                <tr>
                  <th class="empdescription-th emp-atid">Employee Id:</th>
                  <th class="empdescription-th">Current Classification:</th>
                  <th class="empdescription-th">Current Department:</th>
                  <th class="empdescription-th">Current Shift:</th>
                </tr>
                <tr *ngIf="usersData !== undefined">
                  <td class="empdescription-table-tr">
                    {{ usersData.employeeId }}
                  </td>
                  <td class="empdescription-table-tr">
                    {{ usersData.jobCodeId }} {{ usersData.jobCodeDescription }}
                  </td>
                  <td class="empdescription-table-tr">
                    {{ usersData.deptId }}
                  </td>
                  <td class="empdescription-table-tr">
                    {{ usersData.shiftDescription }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- filter ends -->
        </div>
        <div class="pt-5">
          <!--For application-->
          <div>
            <div class="info-label mb-2">
              <p style="text-align: right">
                <button
                  *ngIf="
                    isApplicationSelected() &&
                    !returnstatus &&
                    applicationData !== undefined &&
                    applicationData.length > 0
                  "
                  class="btn btn-success buttoncolours pull-right my-2 my-sm-0"
                  data-toggle="modal"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                  (click)="cancelApplication()"
                >
                  Cancel
                </button>
              </p>
            </div>
            <table
              class="table table-striped"
              *ngIf="applicationData !== null && applicationData.length > 0"
            >
              <thead class="table-header">
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Employee Id</th>
                <th scope="col">Classification</th>
                <th scope="col">Status</th>
                <th scope="col">Plant</th>
                <th scope="col">Area</th>
                <th scope="col">Shift</th>
                <th scope="col">Date Signed</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="let emp of applicationData; let i = index"
                  class="table-container"
                >
                  <td>
                    <input
                      type="radio"
                      id="select"
                      name="application"
                      (click)="selectUserEmp(i)"
                    />
                  </td>

                  <td class="tab-value">
                    {{ emp.firstName ? emp.firstName : '-' }}&nbsp;{{
                      emp.lastName ? emp.lastName : '-'
                    }}
                    <!-- class='tab-value'> -->
                  </td>

                  <td class="tab-value">
                    {{ emp.employeeId ? emp.employeeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.jobCodeId ? emp.jobCodeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.appStatusCode ? emp.appStatusCode : '-' }}
                  </td>
                  <td class="tab-value">
                    {{
                      emp.plantId === '00' ? emp.plantDescription : emp.plantId
                    }}
                  </td>
                  <td class="tab-value">
                    {{ emp.areaDescription ? emp.areaDescription : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.shiftDescription ? emp.shiftDescription : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.empSignDate | date: 'MM/dd/yyyy' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="!isApplication">
              <p
                *ngIf="applicationData !== null && applicationData.length === 0"
              >
                Your requested application will display here. <br />
                Note: You can not edit your application.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isAdmin() && !isAll150App && isAppTD">
    <div
      *ngIf="userInformation !== undefined && userInformation !== null"
      class="row content-container mt-2 mb-2"
    >
      <!-- global search filter starts -->
      <div class="pt-2 div-color">
        <div class="search-divider-div">
          <p class="search-divder-p"></p>
          <div
            class="jumbotron jumb-new jumb-newcontrols"
            style="margin-bottom: 0px"
          >
            <div class="row userinfo-header-div" display="float">
              <div class="col">
                <h1 class="h1-label">
                  Welcome! {{ userInformation.firstName }}
                </h1>
              </div>
            </div>
          </div>
          <div class="empdescription-box">
            <div class="empdescription-table">
              <h4 style="font-weight: 600 !important; font-size: 16px">
                You have been sent to this page because you do not have
                permissions to view that page.
              </h4>
              <h6 style="font-weight: 600 !important; font-size: 14px">
                Please choose from the menu on the left.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
