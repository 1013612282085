import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class DepartmentDataService {
  commonUtility = new CommonUtility();
  private readonly api_getSearchDepartment = '/DepartmentDataSearch';
  private readonly api_getRegion = '/GetRegion';
  private readonly api_getPlant = '/GetPlants?type=';
  private readonly api_addDepartment = '/DepartmentDataAdd'
  private readonly api_deleteDepartment = '/DepartmentDataDelete?deptId='
  private readonly api_editDepartment = '/DepartmentDataUpdate'

  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  getDepartmentData(searchData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_getSearchDepartment,
      searchData,
      { headers }
    );
  }

  getRegions(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getRegion,
      { headers }
    );
  }

  getPlants(type: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getPlant + type,
      { headers }
    )
  }

  editDepartment(departmentData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.tableMaintenanceURL}` + this.api_editDepartment,
      departmentData,
      { headers }
    );
  }

  addDepartment(departmentData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_addDepartment,
      departmentData,
      { headers }
    );
  }

  deleteDepartment(deptId: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.delete<any>(
      `${environment.tableMaintenanceURL}` + this.api_deleteDepartment + deptId,
      { headers }
    );
  }
}
