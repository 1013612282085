import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

export class CommonUtility {
    constructor() { }

    getHttpHeader(): HttpHeaders {
        let customHeader = new HttpHeaders();
        customHeader = customHeader.append(environment.apimKeyText, environment.apimKeyValue);
        //Security fixes
        customHeader = customHeader.append('X-Content-Type-Options', 'nosniff');
        customHeader = customHeader.append('X-Frame-Options', 'Deny');
        customHeader = customHeader.append('X-XSS-Protection', '1');
        customHeader = customHeader.append('Server', 'delete');
        return customHeader;
    }

    getHttpHeaderWithBearerToken(bearerToken: string): HttpHeaders {
        let customHeader = new HttpHeaders();
        customHeader = customHeader.append(environment.apimKeyText, environment.apimKeyValue);
        customHeader = customHeader.append('Authorization', 'Bearer ' + bearerToken);
        //Security fixes
        customHeader = customHeader.append('X-Content-Type-Options', 'nosniff');
        customHeader = customHeader.append('X-Frame-Options', 'Deny');
        customHeader = customHeader.append('X-XSS-Protection', '1');
        customHeader = customHeader.append('Server', 'delete');
        return customHeader;
    }
}