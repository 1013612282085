import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { ReportsAndChartsService } from 'src/app/Services/ReportsAndCharts/reports-and-charts.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';

@Component({
  selector: 'app-action-code-change-report',
  templateUrl: './action-code-change-report.component.html',
  styleUrls: ['./action-code-change-report.component.css'],
})
export class ActionCodeChangeReportComponent implements OnInit {
  public loading: boolean = false;
  ActionCodeChangeData: any[] = [];
  views: any[] = [];
  userInformation: any;
  AllShow: boolean = false;
  srcFromDate: any;
  srcFormattedFromDate: any;
  srcToDate: any;
  srcFormattedToDate: any;
  responseDataExists: boolean = false;

  constructor(
    private _ReportsAndChartsService: ReportsAndChartsService,
    private _commonApplicationService: CommonService,
    private _userInfo: UsergroupService,
    private _sweetAlertService: SweetAlertService
  ) {}

  ngOnInit(): void {
    this.getWeekDayData();
    this.getActionCodeChanges();
  }

  getWeekDayData() {
    var dtAsOfDate = new Date();
    var d = new Date(dtAsOfDate);
    var dayName = d.toString().split(' ')[0];
    if (dayName === 'Sun') {
      dtAsOfDate.setDate(dtAsOfDate.getDate() - 1);
    }
    this.srcFromDate = new Date(
      dtAsOfDate.setDate(dtAsOfDate.getDate() - dtAsOfDate.getDay() + 1)
    );
    this.srcToDate = new Date();
    this.srcFormattedFromDate = this._commonApplicationService.formatDate(
      this.srcFromDate
    );
    this.srcFormattedToDate = this._commonApplicationService.formatDate(
      this.srcToDate
    );
  }

  onFromDateChange(val: any): void {
    this.srcFromDate = this.srcFormattedFromDate;

    // console.log(".this.srcFromDate", this.srcFromDate);
    // console.log(".this.srcToDate", this.srcToDate);
  }

  onToDateChange(val: any): void {
    this.srcToDate = this.srcFormattedToDate;

    // console.log(".this.srcFromDate", this.srcFromDate);
    // console.log(".this.srcToDate", this.srcToDate);
  }

  getActionCodeChanges() {
    this.loading = true;
    let queryParams = {
      FromDate: this.srcFormattedFromDate,
      ToDate: this.srcFormattedToDate,
    };

    if (
      queryParams.FromDate === null ||
      queryParams.FromDate === undefined ||
      queryParams.FromDate === ''
    ) {
      this.loading = false;
      return this._sweetAlertService.validationError(
        'error',
        'Error',
        'Please Enter From Date'
      );
    }
    if (
      queryParams.ToDate === null ||
      queryParams.ToDate === undefined ||
      queryParams.ToDate === ''
    ) {
      this.loading = false;
      return this._sweetAlertService.validationError(
        'error',
        'Error',
        'Please Enter To Date'
      );
    }
    if (this.srcFromDate > this.srcToDate) {
      this.loading = false;
      return this._sweetAlertService.validationError(
        'error',
        'Error',
        'To Date cannot be earlier than the From Date'
      );
    }

    this._ReportsAndChartsService.GetActionCodeChangesSM(queryParams).subscribe(
      (_response: any) => {
        this.loading = false;
        this.ActionCodeChangeData = _response;
        this.responseDataExists =
          this.ActionCodeChangeData.length === 0 ? true : false;
        for (var i = 0; i < this.ActionCodeChangeData.length; i++) {
          this.ActionCodeChangeData[i].Comments =
            '<b> Application Changes: </b> ' +
            this.ActionCodeChangeData[i].processEffectOnAppsComments +
            ' ' +
            this.ActionCodeChangeData[i].processEffectOnRRApps +
            ' ' +
            this.ActionCodeChangeData[i].processEffectOnSPApps +
            ' ' +
            '. <br> <b>Overtime Changes:</b> ' +
            this.ActionCodeChangeData[i].otgLogMessage +
            ' ' +
            this.ActionCodeChangeData[i].repgLogMessage;
          this.ActionCodeChangeData[i].showField = false;
        }
      },
      (_error: HttpErrorResponse) => {
        this.loading = false;
        if (_error.status === 500) {
          return this._sweetAlertService.validationError(
            'error',
            'Error',
            _error.error.replace(
              'Error: GetOvertimeRepresentationGroupMove - Generic Exception:',
              ''
            )
          );
        } else {
          return this._sweetAlertService.validationError(
            'error',
            'Error',
            'Something went Wrong'
          );
        }
      }
    );
  }

  getUserInfo() {
    this._userInfo.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'REPORTS',
        'ACTIONCODECHANGE'
      );
    });
  }

  showLogMessage(index: any) {
    this.ActionCodeChangeData[index].showField =
      !this.ActionCodeChangeData[index].showField;
  }

  ShowAllFields(e: any) {
    this.AllShow = !this.AllShow;

    for (var i = 0; i < this.ActionCodeChangeData.length; i++) {
      if (e.target.value) {
        this.ActionCodeChangeData[i].showField = this.AllShow;
      } else {
        this.ActionCodeChangeData[i].showField = this.AllShow;
      }
    }
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.ActionCodeChangeData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.ActionCodeChangeData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }

  @ViewChild('ActionCodeChangeHistoryExportTable', {
    read: ElementRef,
    static: false,
  })
  ActionCodeChangeHistoryExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.ActionCodeChangeHistoryExportTable,
        `${ApplicationEnum.ACTIONCODECHANGEHISTORY}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }
}
