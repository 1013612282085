<div class="container-fluid app-box">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Views</li>
        <li class="breadcrumb-item brd-li brd-li-child">Demographic View</li>
        <li class="breadcrumb-item brd-li brd-li-child">Details</li>
      </ol>
    </nav>
  </div>
  <div class="text-right" style="padding-top: 7px">
    <input
      type="button"
      class="backbutton"
      value="Back to Main Search"
      (click)="MainNavigate()"
    />&nbsp;
  </div>
  <br />
  <div class="form-group row">
    <div
      class="col-sm-12"
      style="background-color: grey; text-align: center; color: #ffffff"
    >
      <label class="col-form-label">Employee Information</label>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Name: </label>
      {{ recordSelected.lastName ? recordSelected.lastName : '' }},
      {{ recordSelected.firstName ? recordSelected.firstName : 'N/A' }}&nbsp;{{
        recordSelected.middle ? recordSelected.middle : ''
      }}
    </div>
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Employee Id: </label>
      {{ recordSelected.employeeid }}
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">ATIN: </label>
      {{ recordSelected.atin }}
    </div>
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">ZID: </label>
      {{ recordSelected.atid }}
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Classification: </label>
      {{ recordSelected.jobCodeId }} - {{ recordSelected.jobCodeDescription }}
    </div>
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Home Phone Number: </label>
      {{ recordSelected.teL_NUM }}
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Rate: </label> ${{
        recordSelected.rate | number: '.2-2'
      }}
    </div>
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Shift: </label>
      {{ recordSelected.shiftDescription }}
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Plant Seniority Date: </label>
      {{ plantSenDate | date: 'MM/dd/yyyy' }}
    </div>
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">GM Seniority Date: </label>
      {{ gmSenDate | date: 'MM/dd/yyyy' }}
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Plant Skill Date: </label>
      {{ plantSkillDate | date: 'MM/dd/yyyy' }}
    </div>
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">GM Skill Date: </label>
      {{ gmSkillDate | date: 'MM/dd/yyyy' }}
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Employee Start Date: </label>
      {{ empStartDate | date: 'MM/dd/yyyy' }}
    </div>
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">FMS Training: </label>
      {{ recordSelected.fmS_Training ? recordSelected.fmS_Training : '-' }}
    </div>
  </div>

  <div class="form-group row">
    <div
      class="col-sm-12"
      style="background-color: grey; text-align: center; color: #ffffff"
    >
      <label class="col-form-label">Department Information</label>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Department: </label>
      {{ recordSelected.deptId }}
    </div>
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Region: </label>
      {{ recordSelected.regionId }} - {{ recordSelected.regionDescription }}
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Speed Code: </label>
      {{ recordSelected.speedCode }}
    </div>
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Plant: </label>
      {{ recordSelected.plantId }} - {{ recordSelected.plantDescription }}
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Plant Manager: </label>
      {{ plantMngrName ? plantMngrName : '-' }}
    </div>
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Phone: </label>
      {{ plantMngrPhone !== ' ' ? plantMngrPhone : '-' }}
    </div>
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Cell: </label>
      {{ plantMngrCell !== ' ' ? plantMngrCell : '-' }}
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Area Manager: </label>
      {{ AreaMngrName ? AreaMngrName : '-' }}
    </div>
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Phone: </label>
      {{ AreaMngrPhone !== ' ' ? AreaMngrPhone : '-' }}
    </div>
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Cell: </label>
      {{ AreaMngrCell !== ' ' ? AreaMngrCell : '-' }}
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Supervisor: </label>
      {{ supervisorName ? supervisorName : '-' }}
    </div>
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Phone: </label>
      {{ supervisorPhone !== ' ' ? supervisorPhone : '-' }}
    </div>
    <div class="col-sm-6">
      <label class="col-sm-4 col-form-label">Cell: </label>
      {{ supervisorCell !== ' ' ? supervisorCell : '-' }}
    </div>
  </div>

  <div *ngIf="hasMedicalRestrictions">
    <p
      class="btn my-2 my-sm-0 click-add text-danger"
      type="submit"
      data-toggle="modal"
      [attr.data-target]="modalId"
      data-backdrop="false"
      (click)="ViewMedicalRestrictions(recordSelected.employeeid)"
    >
      WARNING: This employee has a current medical restriction
    </p>
  </div>

  <!-- <div class="modal-footer" style="padding-right: 80px">
                <button style="
                height: 50px;
                width: 175px;
                border-radius: 10px;

              "
               type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" #closeAddModel
                    (click)="clear()">Cancel</button>
                <button style="
                height: 50px;
                width: 175px;
                border-radius: 10px;

              "  type="button" class="btn btn-primary" [disabled]="employeeForm.invalid || !StatusCode"
                    (click)="addEmployee()">Add </button>
            </div> -->
  <app-employee-medical-restrictions
    [EmployeeIdPassData]="EmployeeIdPassData"
    (isClosed)="medicalRestriction($event)"
    *ngIf="MedicalRestriction"
  >
  </app-employee-medical-restrictions>
</div>
