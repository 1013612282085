<div class='container-fluid app-box overtimealloc'>
    <div class="row breadcrumb-container breadcrumbcss">
        <div>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb brd-ol">
                    <li class="breadcrumb-item brd-li">Overtime</li>
                    <li class="breadcrumb-item brd-li-child">Overtime Allocation Summary</li>
                </ol>
            </nav>
        </div>
        <div style="float: right; margin-right: 10px; padding-top: 5px;">
            <input type="button" (click)="onPrintClick()" value="Print"
                class="btn btn-success buttoncolours crudbuttons pull-right my-2 my-sm-0"> &nbsp;
            <input type="button" value="Back to Main search" (click)="MainNavigate()"
                class="btn btn-success buttoncolours crudbuttons pull-right my-2 my-sm-0">
        </div>
    </div>
    <div id="content" #content class='container-fluid app-box'>
        <!-- <table style="background-color: blueviolet; width: 100%;">
            <tbody>
                <tr> -->

        <!-- <hr class="hrstyle-four"> -->
        <!-- <div class="rpttitle" style="width: 100%;">
            <div style="float: left;">
                {{todayDate | date:'MM/d/yyyy'}} <br> {{todayDate | date:'shortTime'}}
            </div>
            <div style="float: right; vertical-align: middle;  margin: auto; width: 55%;">
                <b>Overtime Allocation Summary</b>
                <br> Overtime Group {{overtimeGroupName}} for {{asOfDate | date:'MMM d, y'}}
            </div>
        </div> -->
        <!-- <hr class="hrstyle-four"> -->
<!--
                </tr></tbody></table> -->

        <table>
            <tbody>
                <tr style="text-align: left !important;">
                    <td>
                        <div class="rpttitle">
                            <div style="float: left; font-size: 14px; text-align: left; ">
                                {{todayDate | date:'MM/d/yyyy'}}<br>{{todayDate | date:'shortTime'}}
                            </div>
                            <div style="text-align: center; margin: auto; width: 55%; font-size: 14px">
                                <b>Overtime Allocation Summary</b><br> Overtime Group {{overtimeGroupName}} for {{asOfDate |
                                date:'MMMd, y'}}
                            </div>
                        </div>
                    </td>
                </tr>
                <tr *ngFor="let val of filterUsersBasedOnSpreadIfTrue(); let i = index">
                    <td>
                        <table style="width: 100%;">
                            <thead>
                                <tr class="overtime-preview-table-tr">
                                    <td class="overtime-preview-table-th">Name: {{ val.lasT_NAME ? val.lasT_NAME : "N/A"
                                        }},&nbsp;{{ val.firsT_NAME ? val.firsT_NAME : "N/A" }}&nbsp;{{ val.middle ?
                                        val.middle : "" }}
                                    </td>
                                    <td class="overtime-preview-table-th">Employee Id: {{val.employeeid?val.employeeid:
                                        'N/A'}}
                                    </td>
                                    <td class="overtime-preview-table-th">Department: {{val.dept?val.dept: 'N/A'}}
                                    </td>
                                    <td class="overtime-preview-table-th">Clasification: {{val.jobcodeid?val.jobcodeid:
                                        'N/A'}}
                                    </td>
                                    <td class="overtime-preview-table-th">Shift:
                                        {{val.shiftdescription?val.shiftdescription: 'N/A'}}</td>
                                    <td class="overtime-preview-table-th">Carried Forward Hours:
                                        {{val.carriedforwardhours | number : '1.2-2'}}</td>
                                </tr>
                            </thead>
                        </table>
                        <table style="width: 100%;">
                            <thead>
                                <tr class="overtime-preview-table-tr">
                                    <th class="overtime-preview-table-ot">Type</th>
                                    <th class="overtime-preview-table-th">Total</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay1Weekend ? 'itsWeekend' :'itsNotWeekend'">1</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay2Weekend ? 'itsWeekend' :'itsNotWeekend'">2</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay3Weekend ? 'itsWeekend' :'itsNotWeekend'">3</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay4Weekend ? 'itsWeekend' :'itsNotWeekend'">4</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay5Weekend ? 'itsWeekend' :'itsNotWeekend'">5</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay6Weekend ? 'itsWeekend' :'itsNotWeekend'">6</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay7Weekend ? 'itsWeekend' :'itsNotWeekend'">7</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay8Weekend ? 'itsWeekend' :'itsNotWeekend'">8</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay9Weekend ? 'itsWeekend' :'itsNotWeekend'">9</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay10Weekend ? 'itsWeekend' :'itsNotWeekend'">10</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay11Weekend ? 'itsWeekend' :'itsNotWeekend'">11</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay12Weekend ? 'itsWeekend' :'itsNotWeekend'">12</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay13Weekend ? 'itsWeekend' :'itsNotWeekend'">13</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay14Weekend ? 'itsWeekend' :'itsNotWeekend'">14</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay15Weekend ? 'itsWeekend' :'itsNotWeekend'">15</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay16Weekend ? 'itsWeekend' :'itsNotWeekend'">16</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay17Weekend ? 'itsWeekend' :'itsNotWeekend'">17</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay18Weekend ? 'itsWeekend' :'itsNotWeekend'">18</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay19Weekend ? 'itsWeekend' :'itsNotWeekend'">19</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay20Weekend ? 'itsWeekend' :'itsNotWeekend'">20</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay21Weekend ? 'itsWeekend' :'itsNotWeekend'">21</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay22Weekend ? 'itsWeekend' :'itsNotWeekend'">22</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay23Weekend ? 'itsWeekend' :'itsNotWeekend'">23</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay24Weekend ? 'itsWeekend' :'itsNotWeekend'">24</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay25Weekend ? 'itsWeekend' :'itsNotWeekend'">25</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay26Weekend ? 'itsWeekend' :'itsNotWeekend'">26</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay27Weekend ? 'itsWeekend' :'itsNotWeekend'">27</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay28Weekend ? 'itsWeekend' :'itsNotWeekend'">28</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay29Weekend ? 'itsWeekend' :'itsNotWeekend'"
                                        *ngIf="isDay29Availabe">
                                        29</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay30Weekend ? 'itsWeekend' :'itsNotWeekend'"
                                        *ngIf="isDay30Availabe">
                                        30</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay31Weekend ? 'itsWeekend' :'itsNotWeekend'"
                                        *ngIf="isDay31Availabe">
                                        31</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let item of filterItemsOfUser(val.employeeid)">
                                    <td class="overtime-preview-table-ot">{{item.overtimetypeid}}
                                    </td>
                                    <td class="overtime-preview-table-td">
                                        <span *ngIf="item.total > 0"> {{item.total | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay1Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY1 > 0">{{item.daY1 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay2Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY2 > 0">{{item.daY2 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay3Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY3 > 0">{{item.daY3 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay4Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY4 > 0">{{item.daY4 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay5Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY5 > 0">{{item.daY5 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay6Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY6 > 0">{{item.daY6 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay7Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY7 > 0">{{item.daY7 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay8Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY8 > 0">{{item.daY8 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay9Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY9 > 0">{{item.daY9 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay10Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY10 > 0">{{item.daY10 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay11Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY11 > 0">{{item.daY11 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay12Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY12 > 0">{{item.daY12 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay13Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY13 > 0">{{item.daY13 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay14Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY14 > 0">{{item.daY14 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay15Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY15 > 0">{{item.daY15 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay16Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY16 > 0">{{item.daY16 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay17Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY17 > 0">{{item.daY17 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay18Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY18 > 0">{{item.daY18 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay19Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY19 > 0">{{item.daY19 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay20Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY20 > 0">{{item.daY20 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay21Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY21 > 0">{{item.daY21 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay22Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY22 > 0">{{item.daY22 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay23Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY23 > 0">{{item.daY23 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay24Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY24 > 0">{{item.daY24 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay25Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY25 > 0">{{item.daY25 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay26Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY26 > 0">{{item.daY26 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay27Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY27 > 0">{{item.daY27 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay28Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY28 > 0">{{item.daY28 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay29Weekend ? 'itsWeekend' :'itsNotWeekend'"
                                        *ngIf="isDay29Availabe">
                                        <span *ngIf="item.daY29 > 0">{{item.daY29 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay30Weekend ? 'itsWeekend' :'itsNotWeekend'"
                                        *ngIf="isDay30Availabe">
                                        <span *ngIf="item.daY30 > 0">{{item.daY30 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay31Weekend ? 'itsWeekend' :'itsNotWeekend'"
                                        *ngIf="isDay31Availabe">
                                        <span *ngIf="item.daY31 > 0">{{item.daY31 | number : '1.2-2'}}</span>
                                    </td>
                                </tr>
                                <tr *ngFor="let total of getTotalByDayByUser(val.employeeid, val.carriedforwardhours)">
                                    <td class="overtime-preview-table-td" colspan="2">Total:
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay1Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day1 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay2Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day2 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay3Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day3 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay4Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day4 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay5Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day5 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay6Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day6 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay7Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day7 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay8Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day8 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay9Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day9 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay10Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day10 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay11Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day11 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay12Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day12 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay13Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day13 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay14Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day14 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay15Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day15 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay16Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day16 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay17Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day17 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay18Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day18 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay19Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day19 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay20Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day20 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay21Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day21 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay22Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day22 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay23Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day23 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay24Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day24 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay25Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day25 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay26Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day26 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay27Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day27 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay28Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day28 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay29Weekend ? 'itsWeekend' :'itsNotWeekend'"
                                        *ngIf="isDay29Availabe">
                                        <span>{{total.day29 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay30Weekend ? 'itsWeekend' :'itsNotWeekend'"
                                        *ngIf="isDay30Availabe">
                                        <span>{{total.day30 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay31Weekend ? 'itsWeekend' :'itsNotWeekend'"
                                        *ngIf="isDay31Availabe">
                                        <span>{{total.day31 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table style="width: 100%;">
                            <tr>
                                <td class="spreadrow" style="text-align: center;">
                                    <span>Everyone below this line is outside spread</span>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>


                <tr *ngFor="let val of filterUsersBasedOnSpreadIfFalse(); let i = index">
                    <td>
                        <table style="width: 100%;">
                            <thead>
                                <tr class="overtime-preview-table-tr">
                                    <td class="overtime-preview-table-th">Name: {{ val.lasT_NAME ? val.lasT_NAME : "N/A"
                                        }},&nbsp;{{ val.firsT_NAME ? val.firsT_NAME : "N/A" }}&nbsp;{{ val.middle ?
                                        val.middle : "" }}
                                    </td>
                                    <td class="overtime-preview-table-th">Employee Id: {{val.employeeid?val.employeeid:
                                        'N/A'}}
                                    </td>
                                    <td class="overtime-preview-table-th">Department: {{val.dept?val.dept: 'N/A'}}
                                    </td>
                                    <td class="overtime-preview-table-th">Clasification: {{val.jobcodeid?val.jobcodeid:
                                        'N/A'}}
                                    </td>
                                    <td class="overtime-preview-table-th">Shift:
                                        {{val.shiftdescription?val.shiftdescription: 'N/A'}}</td>
                                    <td class="overtime-preview-table-th">Carried Forward Hours:
                                        {{val.carriedforwardhours | number : '1.2-2'}}</td>
                                </tr>
                            </thead>
                        </table>
                        <table style="width: 100%;">
                            <thead>
                                <tr class="overtime-preview-table-tr">
                                    <th class="overtime-preview-table-ot">Type</th>
                                    <th class="overtime-preview-table-th">Total</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay1Weekend ? 'itsWeekend' :'itsNotWeekend'">1</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay2Weekend ? 'itsWeekend' :'itsNotWeekend'">2</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay3Weekend ? 'itsWeekend' :'itsNotWeekend'">3</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay4Weekend ? 'itsWeekend' :'itsNotWeekend'">4</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay5Weekend ? 'itsWeekend' :'itsNotWeekend'">5</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay6Weekend ? 'itsWeekend' :'itsNotWeekend'">6</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay7Weekend ? 'itsWeekend' :'itsNotWeekend'">7</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay8Weekend ? 'itsWeekend' :'itsNotWeekend'">8</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay9Weekend ? 'itsWeekend' :'itsNotWeekend'">9</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay10Weekend ? 'itsWeekend' :'itsNotWeekend'">10</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay11Weekend ? 'itsWeekend' :'itsNotWeekend'">11</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay12Weekend ? 'itsWeekend' :'itsNotWeekend'">12</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay13Weekend ? 'itsWeekend' :'itsNotWeekend'">13</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay14Weekend ? 'itsWeekend' :'itsNotWeekend'">14</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay15Weekend ? 'itsWeekend' :'itsNotWeekend'">15</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay16Weekend ? 'itsWeekend' :'itsNotWeekend'">16</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay17Weekend ? 'itsWeekend' :'itsNotWeekend'">17</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay18Weekend ? 'itsWeekend' :'itsNotWeekend'">18</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay19Weekend ? 'itsWeekend' :'itsNotWeekend'">19</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay20Weekend ? 'itsWeekend' :'itsNotWeekend'">20</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay21Weekend ? 'itsWeekend' :'itsNotWeekend'">21</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay22Weekend ? 'itsWeekend' :'itsNotWeekend'">22</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay23Weekend ? 'itsWeekend' :'itsNotWeekend'">23</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay24Weekend ? 'itsWeekend' :'itsNotWeekend'">24</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay25Weekend ? 'itsWeekend' :'itsNotWeekend'">25</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay26Weekend ? 'itsWeekend' :'itsNotWeekend'">26</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay27Weekend ? 'itsWeekend' :'itsNotWeekend'">27</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay28Weekend ? 'itsWeekend' :'itsNotWeekend'">28</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay29Weekend ? 'itsWeekend' :'itsNotWeekend'"
                                        *ngIf="isDay29Availabe">
                                        29</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay30Weekend ? 'itsWeekend' :'itsNotWeekend'"
                                        *ngIf="isDay30Availabe">
                                        30</th>
                                    <th class="overtime-preview-table-th"
                                        [ngClass]="isDay31Weekend ? 'itsWeekend' :'itsNotWeekend'"
                                        *ngIf="isDay31Availabe">
                                        31</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of filterItemsOfUser(val.employeeid)">
                                    <td class="overtime-preview-table-ot">{{item.overtimetypeid}}
                                    </td>
                                    <td class="overtime-preview-table-td">
                                        <span *ngIf="item.total > 0"> {{item.total | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay1Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY1 > 0">{{item.daY1 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay2Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY2 > 0">{{item.daY2 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay3Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY3 > 0">{{item.daY3 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay4Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY4 > 0">{{item.daY4 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay5Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY5 > 0">{{item.daY5 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay6Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY6 > 0">{{item.daY6 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay7Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY7 > 0">{{item.daY7 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay8Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY8 > 0">{{item.daY8 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay9Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY9 > 0">{{item.daY9 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay10Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY10 > 0">{{item.daY10 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay11Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY11 > 0">{{item.daY11 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay12Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY12 > 0">{{item.daY12 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay13Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY13 > 0">{{item.daY13 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay14Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY14 > 0">{{item.daY14 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay15Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY15 > 0">{{item.daY15 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay16Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY16 > 0">{{item.daY16 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay17Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY17 > 0">{{item.daY17 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay18Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY18 > 0">{{item.daY18 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay19Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY19 > 0">{{item.daY19 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay20Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY20 > 0">{{item.daY20 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay21Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY21 > 0">{{item.daY21 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay22Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY22 > 0">{{item.daY22 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay23Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY23 > 0">{{item.daY23 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay24Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY24 > 0">{{item.daY24 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay25Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY25 > 0">{{item.daY25 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay26Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY26 > 0">{{item.daY26 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay27Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY27 > 0">{{item.daY27 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay28Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span *ngIf="item.daY28 > 0">{{item.daY28 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay29Weekend ? 'itsWeekend' :'itsNotWeekend'"
                                        *ngIf="isDay29Availabe">
                                        <span *ngIf="item.daY29 > 0">{{item.daY29 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay30Weekend ? 'itsWeekend' :'itsNotWeekend'"
                                        *ngIf="isDay30Availabe">
                                        <span *ngIf="item.daY30 > 0">{{item.daY30 | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay31Weekend ? 'itsWeekend' :'itsNotWeekend'"
                                        *ngIf="isDay31Availabe">
                                        <span *ngIf="item.daY31 > 0">{{item.daY31 | number : '1.2-2'}}</span>
                                    </td>
                                </tr>
                                <tr *ngFor="let total of getTotalByDayByUser(val.employeeid, val.carriedforwardhours)">
                                    <td class="overtime-preview-table-td" colspan="2">Total: </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay1Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day1 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay2Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day2 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay3Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day3 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay4Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day4 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay5Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day5 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay6Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day6 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay7Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day7 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay8Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day8 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay9Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day9 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay10Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day10 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay11Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day11 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay12Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day12 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay13Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day13 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay14Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day14 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay15Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day15 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay16Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day16 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay17Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day17 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay18Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day18 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay19Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day19 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay20Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day20 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay21Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day21 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay22Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day22 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay23Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day23 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay24Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day24 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay25Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day25 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay26Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day26 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay27Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day27 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay28Weekend ? 'itsWeekend' :'itsNotWeekend'">
                                        <span>{{total.day28 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay29Weekend ? 'itsWeekend' :'itsNotWeekend'"
                                        *ngIf="isDay29Availabe">
                                        <span>{{total.day29 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay30Weekend ? 'itsWeekend' :'itsNotWeekend'"
                                        *ngIf="isDay30Availabe">
                                        <span>{{total.day30 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                    <td class="overtime-preview-table-td"
                                        [ngClass]="isDay31Weekend ? 'itsWeekend' :'itsNotWeekend'"
                                        *ngIf="isDay31Availabe">
                                        <span>{{total.day31 + val.carriedforwardhours | number : '1.2-2'}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
