import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class Paragraph19Service {
  private readonly getProductionClassifications = '/GetClassificationsByType/';
  private readonly getProductionClassificationsAll = '/GetClassifications';
  private readonly api_searchApplications = '/Paragraph19SearchApplications';
  private readonly api_searchUsers = '/SearchUsers';

  private readonly api_AddP19_Path = '/Paragraph19Add';
  private readonly api_UpdateP19Path = '/Paragraph19Correct';
  private readonly api_cancelApplication = '/CancelApplication';



  appId = new Subject();
  empsignDate = new Subject();
  atid = new Subject();
  commonUtility = new CommonUtility();

  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  searchFilterApplication(formData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` + this.api_searchApplications,
      formData,
      { headers }
    );
  }

  searchFilterApplicationUser(formData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` + this.api_searchUsers,
      formData,
      { headers }
    );
  }

  getClassificationList(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.applicationsURL}` + this.getProductionClassificationsAll,
      { headers }
    );
  }

  AddP19Application(addP19: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` + this.api_AddP19_Path,
      addP19,
      { headers }
    );
  }

  EditP19Application(app19: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.put<any>(
      `${environment.applicationsURL}` + this.api_UpdateP19Path,
      app19,
      { headers }
    );
  }

  CancelP19Application(obj: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.applicationsURL}` + this.api_cancelApplication,
      obj,
      { headers }
    );
  }
}
