import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { ApplicationTypesDataService } from 'src/app/Services/TableMaintenance/ApplicationTypesData/application-types-data.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';
import { UserRoles } from 'src/enum/userroles';
import { UserInfo } from 'src/models/userinfo';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-application-type',
  templateUrl: './application-type.component.html',
  styleUrls: ['./application-type.component.css'],
})
export class ApplicationTypeComponent implements OnInit {
  public loading: boolean = false;
  selectedIndex: number = -1;
  selectedApplicationType: any;
  applicationForm!: FormGroup;
  userRole: UserRoles = UserRoles.SKILLEDUSER; // Enum for skilled worker role
  userInformation!: UserInfo; // Model for UserInfo
  applicationTypeData: any[] = [];
  views: any[] = [];
  showEditHeader: boolean = true;
  status: any[] = [
    {
      Id: 'A',
      statusDescription: 'Active',
    },
    {
      Id: 'I',
      statusDescription: 'Inactive',
    },
  ];

  constructor(
    private fb: FormBuilder,
    public _userInfo: UsergroupService,
    private atd: ApplicationTypesDataService,
    private _commonApplicationService: CommonService
  ) {
    this.createApplicationForm();
  }

  ngOnInit(): void {
    this.getApplicationTypesData();
    this.getUserRoleDescription();
  }

  createApplicationForm() {
    this.applicationForm = this.fb.group({
      appType: [''],
      appTypeDescription: ['', [Validators.required]],
      status: [''],
      lastUpdateBy: [''],
    });
  }

  getUserRoleDescription() {
    this._userInfo.getUserInfo().subscribe((data: any) => {
      this.userRole =
        data['groupDescription'] === UserRoles.HRPLUS
          ? UserRoles.HRPLUS
          : UserRoles.SKILLEDUSER;
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'TABLEMAINTENANCE',
        'APPLICATIONTYPESDATA'
      );
    });
  }

  getApplicationTypesData() {
    this.loading = true;
    this.atd.getApplicationTypesData().subscribe((res) => {
      this.applicationTypeData = res;
      this.loading = false;
    });
  }

  onIconChangeToSave(appInfo: any, idx: any) {
    this.selectedIndex = idx;
    this.selectedApplicationType = appInfo;
    this.showEditHeader = false;
    this.loadApplicationData();
  }

  loadApplicationData() {
    this.applicationForm
      .get('appType')
      ?.patchValue(this.selectedApplicationType.appType);
    this.applicationForm
      .get('appTypeDescription')
      ?.patchValue(this.selectedApplicationType.appTypeDescription);
    this.applicationForm
      .get('status')
      ?.patchValue(this.selectedApplicationType.status);
    this.applicationForm
      .get('lastUpdateBy')
      ?.patchValue(this.userInformation?.userName);
  }

  onIconChangeToEdit() {
    this.atd.EditApplicationTypesData(this.applicationForm.value).subscribe(
      (_res) => {
        this.successAlert();
        this.selectedIndex = -1;
        this.showEditHeader = true;
        this.getApplicationTypesData();
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 406) {
          this.validationError(_error.error);
        } else {
          this.badRequestAlert();
        }
      }
    );
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Record has been updated successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }

  badRequestAlert() {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: 'An Error occured while processing. Please try again later.',
    });
  }

  @ViewChild('absencereasondataTablemaintenanceExportTable', {
    read: ElementRef,
    static: false,
  })
  absencereasondataTablemaintenanceExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.absencereasondataTablemaintenanceExportTable,
        `${ApplicationEnum.APPLICATIONTYPETABLEMAINTENANCE}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.applicationTypeData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.applicationTypeData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
