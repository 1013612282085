<div class="modal fade" id="editEmp" tabindex="-1" role="dialog" aria-labelledby="addEmployeeLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addEmployeeLabel">Edit Demographic Data</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="clear()">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="demographicForm">
                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Name</label>
                        <div class="col-sm-8">
                            {{demographicSelected.last_Name}}, {{demographicSelected.first_Name}} {{demographicSelected.middle}}
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Employee Id</label>
                        <div class="col-sm-8">
                            {{demographicSelected.employeeid | uppercase}}
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Department</label>
                        <div class="col-sm-8">
                            <select formControlName="Dept" class="form-control">
                                <option *ngIf="Departments[Departments.length-1].default" selected value="">
                                    {{Departments[Departments.length-1].default}}</option>
                                <option *ngFor="let dept of Departments[Departments.length-1].data"
                                    value={{dept.deptId}}>
                                    {{dept.deptId}} - {{dept.deptDescription}}
                                </option>
                            </select>
                            <div *ngIf="demographicForm.controls['Dept'].invalid && demographicForm.controls['Dept'].touched">
                                <small class="text-danger" *ngIf="demographicForm.controls['Dept'].errors?.required">
                                    You must specify a valid department
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Classification</label>
                        <div class="col-sm-8">
                            <select formControlName="JobCodeId" class="form-control">
                                <option *ngIf="Classifications[Classifications.length-1].default" selected value="">
                                    {{Classifications[Classifications.length-1].default}}</option>
                                <option *ngFor="let class of Classifications[Classifications.length-1].data"
                                    value={{class.jobCodeId}}>
                                    {{class.jobCodeId}} - {{class.jobCodeDescription}}
                                </option>
                            </select>
                            <div *ngIf="demographicForm.controls['JobCodeId'].invalid && demographicForm.controls['JobCodeId'].touched">
                                <small class="text-danger" *ngIf="demographicForm.controls['JobCodeId'].errors?.required">
                                    You must specify a valid classification
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Shift</label>
                        <div class="col-sm-8">
                            <select formControlName="Shift" class="form-control">
                                <!-- <option *ngIf="shifts[shifts.length-1].default" selected value="">
                                    {{shifts[shifts.length-1].default}}</option> -->
                                <option *ngFor="let shift of shifts" value={{shift.shiftId}}>
                                    {{shift.shiftDescription}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Return LOA Date</label>
                        <div class="col-sm-8">
                            <input formControlName="Ret_Loa_Date" type="date" class="form-control" min="1/1/0001">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Action Code</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="Emp_Status" class="form-control">
                            <div *ngIf="demographicForm.controls['Emp_Status'].invalid && demographicForm.controls['Emp_Status'].touched">
                                <small class="text-danger" *ngIf="demographicForm.controls['Emp_Status'].errors?.required">
                                    You must enter the Action Reason
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Action date</label>
                        <div class="col-sm-8">
                            <input type="date" formControlName="Action_Date" class="form-control">
                            <div *ngIf="demographicForm.controls['Action_Date'].invalid && demographicForm.controls['Action_Date'].touched">
                                <small class="text-danger" *ngIf="demographicForm.controls['Action_Date'].errors?.required">
                                    You must specify a valid date for the action date
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Plant seniority date</label>
                        <div class="col-sm-8">
                            <input formControlName="Plant_Sen_Date" type="date" class="form-control">
                            <div *ngIf="demographicForm.controls['Plant_Sen_Date'].invalid && demographicForm.controls['Plant_Sen_Date'].touched">
                                <small class="text-danger" *ngIf="demographicForm.controls['Plant_Sen_Date'].errors?.required">
                                    You must specify a valid date for the plant seniority date
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Plant skill date</label>
                        <div class="col-sm-8">
                            <input formControlName="Plant_Skill_Date" type="date" class="form-control">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">GM seniority date</label>
                        <div class="col-sm-8">
                            <input formControlName="Gm_Sen_Date" type="date" class="form-control">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">GM skill date</label>
                        <div class="col-sm-8">
                            <input formControlName="Gm_Skill_Date" type="date" class="form-control">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Eligibility date</label>
                        <div class="col-sm-8">
                            <input formControlName="EligibilityDate" type="date" class="form-control">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Eligibility plant</label>
                        <div class="col-sm-8">
                            <select formControlName="PlantId" class="form-control">
                                <option *ngIf="plants[plants.length-1].default" selected value="">
                                    {{plants[plants.length-1].default}}</option>
                                <option *ngFor="let plant of plants[plants.length-1].data" value={{plant.plantid}}>
                                    {{plant.plantid}} - {{plant.plantdescription}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Employee start date</label>
                        <div class="col-sm-8">
                            <input formControlName="Emp_Start_Date" type="date" class="form-control">
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">FMS Training</label>
                        <div class="col-sm-8">
                            <select formControlName="FMS_Training" class="form-control">
                                <!-- <option *ngIf="FMS[FMS.length-1].default" selected value="">
                                    {{FMS[FMS.length-1].default}}</option> -->
                                <option *ngFor="let class of FMS" value={{class.FMSTraining}}>
                                    {{class.FMSTraining}}
                                </option>

                            </select>
                            <div *ngIf="demographicForm.controls['FMS_Training'].invalid && demographicForm.controls['FMS_Training'].touched">
                                <small class="text-danger" *ngIf="demographicForm.controls['FMS_Training'].errors?.required">
                                    You must choose the status
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" #closeCorrectModel (click)="clear()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]="demographicForm.errors || demographicForm.invalid" (click)="submitClassification()">Update</button>
            </div>
        </div>
    </div>
</div>