import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { Paragraph19Service } from 'src/app/Services/Applications/Paragraph19/paragraph19.service';
import { MSALserviceService } from 'src/app/Services/MSALservice/msalservice.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';

@Component({
  selector: 'app-cancel-paragraph19-application',
  templateUrl: './cancel-paragraph19-application.component.html',
  styleUrls: ['./cancel-paragraph19-application.component.css']
})
export class CancelParagraph19ApplicationComponent implements OnInit {
  @Input() selectedEmp: any;
  @Input() userInformation: any;
  @Input() isLoggedInUserAdmin: boolean = false;
  @Output() isSucess = new EventEmitter();
  @ViewChild('closeCancelModal') closeModal!: ElementRef;
  applicationSelected: any;
  dateForm!: FormGroup;
  empSignDate: any;
  usersData: any;
  currentDate = new Date();
  showCancelButton: boolean = false;
  editorName: any;
  maxCancelDate: string = '';

  constructor(
    private fb: FormBuilder,
    private p19: Paragraph19Service,
    private appCom: MSALserviceService,
    private _commonApplicationService: CommonService,
    private _sweetAlertService: SweetAlertService
  ) { }

  ngOnInit(): void {
    let loggedInUser = this.parseJwt(this.appCom.getAuthToken());
    this.editorName = loggedInUser.name;
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.CreateCancelForm();
    this.LoadApplicationData(changes);
  }

  CreateCancelForm() {
    this.dateForm = this.fb.group(
      {
        appId: [''],
        AppStatusDate: ['', Validators.required],
        appType: ['P19'],
        LastUpdatedBy: [''],
      },
      {
        validator: this.dateLessThan('AppStatusDate'),
      }
    );
  }

  LoadApplicationData(changes: SimpleChanges) {
    this.applicationSelected =
      (changes.selectedEmp && changes.selectedEmp.currentValue) || '';
    if (this.applicationSelected === '') {
      this.applicationSelected =
        (changes.selectedEmp && changes.selectedEmp.previousValue) || '';
    }
    this.empSignDate = this.applicationSelected.empSignDate;
    this._commonApplicationService.removeAfterChar(this.applicationSelected.appStatusCode);
    this.maxCancelDate = this._commonApplicationService.formatDate(this.currentDate);
    this.dateForm.get('appId')?.patchValue(this.applicationSelected.appId);
    this.dateForm.get('AppStatusCode')?.patchValue(this.applicationSelected.appStatusCode);
    this.dateForm.get('LastUpdatedBy')?.patchValue(this.userInformation?.userName);
    this.getCurrentClassification(this.applicationSelected.employeeId);
    if (this.isLoggedInUserAdmin === false) {
      this.dateForm.get('AppStatusDate')?.patchValue(this.currentDate);
    }

  }

  getCurrentClassification(employeeId: string) {
    this.p19.searchFilterApplicationUser({ employeeId: employeeId }).subscribe((data) => {
      this.usersData = data[0];
    });
  }

  clear() {
    this.closeModal.nativeElement.click();
  }

  confirmCancel() {
    // var dt = this.dateForm.get('AppStatusDate')?.value;
    // var appStsDt  = new Date(dt);
    // var appStsDtInUTC  = this._dateService.GetUTCDate(appStsDt);

    // this.dateForm.patchValue({AppStatusDate: appStsDtInUTC});
    this.p19.CancelP19Application(this.dateForm.value).subscribe(
      (data: any) => {
        this.clear();
        this.isSucess.emit(true);
        this._sweetAlertService.successAlert('center','success','The Application has been Updated Successfully.',false,1500)
      },
      (error: any) => {
      }
    );
  }

  dateLessThan(to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let empsigndate = this.empSignDate;
      let appstatusdate = group.controls[to];
      if (new Date(empsigndate) > new Date(appstatusdate?.value)) {
        return {
          dates: "Status date shouldn't be earlier than Employee sign date",
        };
      }
      return {};
    };
  }

  removeAfterChar(value: string) {
    let resultString = '';
    if (value != null && value != '') {
      let tempString = value.split('[')[0];
      resultString = tempString.trim();
    }
    if (resultString.indexOf('CLOSED') !== -1) {
      let tempString = value.split(' -')[0];
      resultString = tempString.trim();
    }
    if (resultString === 'CLOSED' || resultString === 'CANCELLED') {
      this.showCancelButton = true;
    } else {
      this.showCancelButton = false;
    }
  }

  parseJwt(token: any) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

}
