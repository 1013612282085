<div class="container-fluid app-box" style="background-color: white">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Views</li>
        <li class="breadcrumb-item brd-li brd-li-child">
          View All Transfer Agreement Applications
        </li>
      </ol>
    </nav>
    <div
      style="float: right; position: absolute; right: 276px"
      *ngIf="
        transferAgreementViewData !== null &&
        transferAgreementViewData.length > 0
      "
    >
      <button
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        style="height: 34px; margin-left: 15px"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
    </div>
  </div>
  <div>
    <div class="row content-container mt-2 mb-2">
      <div class="pt-2 div-color">
        <div class="search-divider-div filtercss">
          <p class="search-divder-p"></p>
          <hr class="search-divider-hr" />
          <div class="jumbotron jumb-new jumb-newcontrols">
            <div class="row" display="float">
              <div class="col">
                <app-filter
                  [filterConfig]="filterConfig"
                  (filterValue)="getFilterValue($event)"
                  (formcontrolobj)="getbyenter($event)"
                >
                </app-filter>
              </div>
              <div class="col-md-1 mr-5">
                <a
                  class="btn btn-lg search-button"
                  (click)="searchTransferAgreement()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>

          <!-- Button Start-->
          <!-- <div style="margin-top: 8px; margin-bottom: 2px; background-color: white; height: 51px;
padding-top: 8px; padding-bottom: 8px; position: sticky;" *ngIf="transferAgreementViewData !==null && transferAgreementViewData   .length > 0">
    <button style="margin-right: 0.5rem;border-color: #003AA0;" class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0" (click)="exportElmToExcel()">Export to Excel</button>
</div> -->
          <!-- Button End-->
        </div>
        <div class="container-divider-div"></div>
        <div style="margin-top: 5px">
          <div *ngIf="!isApplicationDataEmpty">
            <!-- this is the export to excel -->
            <table
              class="table table-striped"
              *ngIf="
                !isDataExportableView &&
                transferAgreementViewData !== null &&
                transferAgreementViewData.length > 0
              "
              #TransferAgreementViewExportTable
            >
              <thead class="table-header position">
                <tr>
                  <!-- <th scope="col">Name</th>
                                    <th scope="col">Employee Id</th>
                                    <th scope="col">Plant Sen Date</th>
                                    <th style="border-right: 2px solid #808080bf" scope="col">GM Sen Date</th>
                                    <th scope="col">Class</th>
                                    <th scope="col">Plant</th>
                                    <th scope="col">Dept</th>
                                    <th scope="col">Shift</th>
                                    <th scope="col">App No</th>
                                    <th scope="col">Date Signed</th>
                                    <th scope="col">Status</th> -->

                  <th scope="col">
                    <span (click)="onSortClick($event, 'last_Name', 'string')"
                      >Name
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'last_Name'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'last_Name'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'last_Name'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'employeeId', 'string')"
                      >Emp Id
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'employeeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'employeeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'employeeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'planT_SEN_DATESort', 'string')
                      "
                      >Plant Sen Date 1
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'planT_SEN_DATESort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'planT_SEN_DATESort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'planT_SEN_DATESort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'GMSeniorityDateSort', 'string')
                      "
                      >GM Sen Date
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'GMSeniorityDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'GMSeniorityDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'GMSeniorityDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'app_JobCodeID', 'string')"
                      >Class
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'app_JobCodeID'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'app_JobCodeID'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'app_JobCodeID'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'app_PlantID', 'string')"
                      >Plant
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'app_PlantID'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'app_PlantID'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'app_PlantID'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'apP_DEPTID', 'string')"
                      >Dept
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'apP_DEPTID'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'apP_DEPTID'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'apP_DEPTID'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'shiftDescription', 'string')
                      "
                      >Shift
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'shiftDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'appNo', 'string')"
                      >App No
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'appNo'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'appNo'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'appNo'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'appNo', 'string')"
                      >Date Signed
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'appNo'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'appNo'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'appNo'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'empSignDateSort', 'string')"
                      >Status
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'empSignDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let data of transferAgreementViewData; let i = index"
                  class="table-container"
                >
                  <td class="left-tab-value">
                    {{ data.last_Name ? data.last_Name : 'N/A' }},
                    {{ data.first_Name ? data.first_Name : 'N/A' }}&nbsp;{{
                      data.middle ? data.middle : ''
                    }}
                  </td>
                  <td class="tab-value">
                    {{ data.employeeId ? data.employeeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.planT_SEN_DATE
                        ? (data.planT_SEN_DATE | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.gM_SEN_DATE
                        ? (data.gM_SEN_DATE | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{ data.app_JobCodeID ? data.app_JobCodeID : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ data.app_PlantID ? data.app_PlantID : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ data.apP_DEPTID ? data.apP_DEPTID : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ data.shiftDescription ? data.shiftDescription : '-' }}
                  </td>
                  <td class="tab-value">{{ data.appNo ? data.appNo : '-' }}</td>
                  <td class="tab-value">
                    {{
                      data.empSignDate
                        ? (data.empSignDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>

                  <td class="tab-value custon-padding">
                    {{ data.appStatusCode ? data.appStatusCode : '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- this is the main table -->
            <table
              class="table table-striped"
              *ngIf="
                transferAgreementViewData !== null &&
                transferAgreementViewData.length > 0
              "
            >
              <thead class="table-header position-label">
                <th
                  class="table-height"
                  style="border-right: 2px solid #808080bf"
                  colspan="4"
                  scope="col"
                >
                  Employee Information
                </th>
                <th class="table-height" colspan="7" scope="col">
                  Application Information
                </th>
              </thead>
              <thead class="table-header position">
                <!-- <th scope="col" style="width: 200px;" >Name</th>
                                <th scope="col">Emp Id</th>
                                <th scope="col">Plant Sen Date</th>
                                <th style="border-right: 2px solid #808080bf" scope="col">GM Sen Date</th>
                                <th scope="col">Class</th>
                                <th scope="col">Plant</th>
                                <th scope="col">Dept</th>
                                <th scope="col">Shift</th>
                                <th scope="col">App No</th>
                                <th scope="col">Date Signed</th>
                                <th scope="col" style="width: 180px;">Status</th> -->

                <th scope="col" style="width: 150px">
                  <span (click)="onSortClick($event, 'last_Name', 'string')"
                    >Name
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'last_Name'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'last_Name'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'last_Name'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'employeeId', 'string')"
                    >Emp Id
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'employeeId'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'employeeId'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'employeeId'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'plantSenDateSort', 'string')"
                    >Plant Sen Date 2
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'plantSenDateSort'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'plantSenDateSort'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'plantSenDateSort'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col" style="border-right: 2px solid #808080bf">
                  <span
                    (click)="
                      onSortClick($event, 'GMSeniorityDateSort', 'string')
                    "
                    >GM Sen Date
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'GMSeniorityDateSort'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'GMSeniorityDateSort'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'GMSeniorityDateSort'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'app_JobCodeID', 'string')"
                    >Class
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'app_JobCodeID'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'app_JobCodeID'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'app_JobCodeID'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'app_PlantID', 'string')"
                    >Plant
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'app_PlantID'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'app_PlantID'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'app_PlantID'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'apP_DEPTID', 'string')"
                    >Dept
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'apP_DEPTID'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'apP_DEPTID'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'apP_DEPTID'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'shiftDescription', 'string')"
                    >Shift
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'shiftDescription'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'shiftDescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'shiftDescription'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'appNo', 'string')"
                    >App No
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'appNo'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'appNo'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'appNo'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'empSignDateSort', 'string')"
                    >Date Signed
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'empSignDateSort'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'empSignDateSort'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'empSignDateSort'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col" style="width: 150px">
                  <span (click)="onSortClick($event, 'appStatusCode', 'string')"
                    >Status
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'appStatusCode'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'appStatusCode'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'appStatusCode'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let data of transferAgreementViewData
                      | paginate
                        : {
                            id: 'joblist',
                            itemsPerPage: 20,
                            currentPage: Applicationpage,
                            totalItems: TotalApplicationCount
                          };
                    let i = index
                  "
                  class="table-container"
                >
                  <td class="left-tab-value" style="width: 150px">
                    {{ data.last_Name ? data.last_Name : 'N/A' }},
                    {{ data.first_Name ? data.first_Name : 'N/A' }}&nbsp;{{
                      data.middle ? data.middle : ''
                    }}
                  </td>
                  <td class="tab-value">
                    {{ data.employeeId ? data.employeeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.planT_SEN_DATE
                        ? (data.planT_SEN_DATE | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      data.gM_SEN_DATE
                        ? (data.gM_SEN_DATE | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{ data.app_JobCodeID ? data.app_JobCodeID : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ data.app_PlantID ? data.app_PlantID : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ data.apP_DEPTID ? data.apP_DEPTID : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ data.shiftDescription ? data.shiftDescription : '-' }}
                  </td>
                  <td class="tab-value">{{ data.appNo ? data.appNo : '-' }}</td>
                  <td class="tab-value">
                    {{
                      data.empSignDate
                        ? (data.empSignDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value custon-padding" style="width: 150px">
                    {{ data.appStatusCode ? data.appStatusCode : '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="single-wrap d-flex justify-content-center"
              *ngIf="
                transferAgreementViewData !== null &&
                transferAgreementViewData.length > 0
              "
            >
              <nav aria-label="Page navigation example">
                <pagination-controls
                  id="joblist"
                  (pageChange)="Applicationpage = $event"
                  previousLabel=""
                  nextLabel=""
                ></pagination-controls>
              </nav>
            </div>
            <div *ngIf="isApplicationDataEmpty">
              <p *ngIf="isApplicationDataEmpty">No existing records Found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
    <!--[show]="loading"  -->
  </ngx-loading>
</div>
