<html lang="en">

<head>
    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    <title>UAW Contract Administration System</title>
</head>

<body>
    <div class='container-fluid app-container'>
        <div class='row welcome-padding'>
            <div class='col text-center welcome-label'>
                <span>
                    Welcome
                </span>
            </div>
        </div>
        <div class='row'>
            <div class='col text-center desc-label'>
                <span>
                    UAW Contract Administration System
                </span>
            </div>
        </div>

        <div *ngIf="AnnouncementsExists" class="announcement-container">
            <div class="announcement-header">
                <div class="annocement-float"><img src="../../../assets/Views/annoucement.svg"></div>
                <div class="annocement-heading">
                    Announcements</div>
            </div>
            <div class="annocement-overflow">
                <div style="padding-left:5px">
                    <table style="width: 99%;">
                        <tbody>
                            <tr *ngFor="let data of Announcements,let i = index" class="tr-class">
                                <td colspan="1" class="td-class"><img src="../../../assets/Views/tick.svg" class="td-img"></td>
                                <td colspan="7" class="td-class-col">
                                    <div class="annocement-float" [innerHTML]="data.text"></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>


</body>

</html>