import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class ShiftPreferenceService {

  private readonly _ShiftPreferenceSPAdd = '/ShiftPreferenceSPAdd';
  private readonly _ShiftPreferenceSPDelete = '/DeleteApplication/';
  private readonly _ShiftPreferenceSPCorrect = '/ShiftPreferenceSPCorrect';

  private readonly _ShiftPreferenceSPSearchApplications = '/ShiftPreferenceSPSearchApplications';
  private readonly _ShiftPreferenceSPSearchApplicationsByUser = '/SearchApplicationsByUser';
  private readonly _ShiftPreferenceSPSearchUser = '/SearchUsers';

  private readonly _ShiftRequestTypes = '/GetShiftRequestTypes';


  commonUtility = new CommonUtility();

  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }



  ShiftPreferenceSPAdd(AddSPBody: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` +
      this._ShiftPreferenceSPAdd,
      AddSPBody,
      { headers });
  }

  ShiftPreferenceSPDelete(appIdSP: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.delete<any>(`${environment.applicationsURL}` +
      this._ShiftPreferenceSPDelete + appIdSP,
      { headers });
  }

  ShiftPreferenceSPCorrect(CorrectSpApplication: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.put<any>(
      `${environment.applicationsURL}` +
      this._ShiftPreferenceSPCorrect,
      CorrectSpApplication,
      { headers });
  }


  ShiftPreferenceSPSearchApplications(SearchSP: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` +
      this._ShiftPreferenceSPSearchApplications,
      SearchSP,
      { headers });
  }

  ShiftPreferenceSPSearchUsers(SearchSP: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` +
      this._ShiftPreferenceSPSearchUser,
      SearchSP,
      { headers });
  }

  ShiftPreferenceSPSearchApplicationsByUser(SearchSP: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.applicationsURL}` +
      this._ShiftPreferenceSPSearchApplications + SearchSP,
      { headers });
  }

  ShiftRequestType(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(`${environment.applicationsURL}` +
      this._ShiftRequestTypes,
      { headers });
  }

}



