import { Component, Input, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ElementRef,
  EventEmitter,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { AppComponent } from 'src/app/app.component';
import { ToolAndDiePlantService } from 'src/app/Services/Applications/ToolAndDie/tool-and-die-plant.service';
import { UserInfo } from 'src/models/userinfo';
import { FacilitiesServicePlantService } from 'src/app/Services/Applications/FacilitiesAndService/facilities-service-plant.service';
import { RuleEngineService } from 'src/app/Services/Ruleengine/rule-engine.service';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';

@Component({
  selector: 'app-add-facilites-service',
  templateUrl: './add-facilites-service.component.html',
  styleUrls: ['./add-facilites-service.component.css'],
})
export class AddFacilitesServiceComponent implements OnInit {
  employeeForm!: FormGroup;
  empSelected: any;
  shifts: any[] = [
    {
      data: [],
      default: 'Select a shift',
    },
  ];
  plants: any[] = [
    {
      data: [],
      default: 'Select a plant',
    },
  ];
  areas: any[] = [
    {
      data: [],
      default: 'Select an area',
    },
  ];
  selectedPlantId: any;
  atid: any;
  employeeId: any;
  currentDate = new Date();
  @Input() selectedEmp: any;
  @Input() isAdd: boolean = false;
  @Input() title: any;
  @Output() clean = new EventEmitter();
  @Output() isSucess = new EventEmitter();
  @Input() AppType: string = '';
  @ViewChild('closeAddModel') closeAddModal!: ElementRef;

  userInformation!: UserInfo;

  StatusCode: boolean = true;
  ruleMessages!: any[];
  errorlength: number = 0;
  maxDate: string = '';

  constructor(
    private fb: FormBuilder,
    private td: ToolAndDiePlantService,
    private fs: FacilitiesServicePlantService,
    private appCom: AppComponent,
    private authService: MsalService,
    private _commonApplicationService: CommonService,
    private _ruleEngine: RuleEngineService,
    private _sweetAlert: SweetAlertService
  ) {}

  ngOnInit(): void {
    this.getShifts();
    this.getplants();
    this.getAreas();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createEmpForm();
    this.loadEmployeeData(changes);
  }

  loadEmployeeData(changes: SimpleChanges) {
    this.empSelected =
      (changes.selectedEmp && changes.selectedEmp.currentValue) || '';
    if (this.empSelected === '') {
      this.empSelected =
        (changes.selectedEmp && changes.selectedEmp.previousValue) || '';
    }
    this.maxDate = this._commonApplicationService.formatDate(this.currentDate);
    this.employeeForm
      .get('employeeName')
      ?.patchValue(
        this.empSelected.lastName +
          ', ' +
          this.empSelected.firstName +
          ' ' +
          this.empSelected.middleName
      );
    this.employeeForm.get('firstName')?.patchValue(this.empSelected.firstName);
    this.employeeForm.get('lastName')?.patchValue(this.empSelected.lastName);
    this.selectedPlantId = this.employeeForm
      .get('appPlantId')
      ?.patchValue(this.empSelected.plantid);
    this.employeeForm
      .get('currentPlant')
      ?.patchValue(this.empSelected.plantDescription);
    this.employeeForm
      .get('currentShift')
      ?.patchValue(this.empSelected.shiftDescription);
    this.atid = this.employeeForm
      .get('ATID')
      ?.patchValue(this.empSelected.atid);
    this.employeeId = this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.empSelected.employeeId);
    this.getRuleEngineValidations(this.empSelected.employeeId);
  }

  createEmpForm() {
    this.employeeForm = this.fb.group({
      employeeName: [''],
      firstName: [''],
      lastName: [''],
      currentPlant: [''],
      currentShift: [''],
      ATID: [this.atid],
      EmployeeId: [this.employeeId],
      AppNo: ['0'],
      AppStatusCode: ['OPEN'],
      JobCodeId: [null],
      T09OverrideFlag: [null],
      ExpValidateFlag: [null],
      T04OverrideFlag: [null, []],
      ClassOverrideFlag: [null, []],
      SeniorityOverrideFlag: [null, []],
      Comments: [null, []],
      SpecialSP: [null, []],
      App150PlantGroupId: ['0'],
      AppType: ['150'],
      DateEntered: [this.currentDate],
      EnteredBy: ['USER'],
      appStatusDate: [this.currentDate],
      empSignDate: [this.currentDate],
      plantId: ['', [Validators.required]],
      shiftId: ['', [Validators.required]],
      //areaId: ['', [Validators.required]],
      StatusOverrideFlag: [null, []],
      AppAreaId: ['', [Validators.required]],
    });
    this.isAdd = false;
  }

  getRuleEngineValidations(employeeId: string) {
    this._ruleEngine
      .addFS150ApplicationRule(employeeId)
      .subscribe((data: any) => {
        this.StatusCode = data['status'];
        this.ruleMessages = data['errors'];
        this.errorlength = data['errors'].length;
      });
  }
  clear() {
    this.clean.emit('');
    this.closeAddModal.nativeElement.click();
    this.resetFields();
  }

  clearForm() {
    this.employeeForm.reset;
  }

  resetFields() {
    this.employeeForm.get('plantId')?.reset();
    this.employeeForm.get('shiftId')?.reset();
    this.employeeForm.get('AppAreaId')?.reset();
    this.getplants();
    this.getShifts();
    this.getAreas();
  }

  //Method to add an employee
  addFSApplication() {
    this.fs.Add150Application(this.employeeForm.value).subscribe(
      (data: any) => {
        this.clear();
        this.isSucess.emit(true);
        this._sweetAlert.successAlert(
          'center',
          'success',
          'The Application has been Added Successfully.',
          false,
          1500
        );
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 406) {
          this._sweetAlert.validationError(
            'error',
            'Error',
            `${_error.error
              .toString()
              .replace(
                'Error: FacilitiesService150Add - InvalidDataException:',
                ''
              )}`
          );
        } else {
          this._sweetAlert.validationError(
            'error',
            'ERROR',
            'An Error occured while processing.Please try again.'
          );
        }
      }
    );
  }

  //method to get shifts
  getShifts() {
    this.td.getShiftsList().subscribe((data: any) => {
      this.shifts[0].data = data;
    });
  }

  //Method to get plants
  getplants() {
    this.fs.getPlantsList().subscribe((data) => {
      // this.plants = data;
      this.plants[0].data = data;
    });
  }

  getAreas() {
    const areaObject = {
      areaId: 0,
      areaDescription: 'Any',
    };
    this.fs.getAreasList().subscribe((data) => {
      this.areas[this.areas.length - 1].data = data;
      this.areas[this.areas.length - 1].data.splice(0, 2);
      this.areas[this.areas.length - 1].data.push(areaObject);
    });
  }
}
