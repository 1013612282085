import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnnouncementService } from 'src/app/Services/Admin/Announcement/announcement.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';

@Component({
  selector: 'app-delete-announcement',
  templateUrl: './delete-announcement.component.html',
  styleUrls: ['./delete-announcement.component.css'],
})
export class DeleteAnnouncementComponent {
  @Input() selectedAnnouncement: any;
  AnnouncementSelected: any;
  AnnouncementId: any;
  @Output() isSucess = new EventEmitter();
  constructor(
    private _announcementService: AnnouncementService,
    private _sweetAlertService: SweetAlertService
  ) {}

  clear() {
    //TODO
  }

  confirmDelete() {
    if (this.selectedAnnouncement) {
      this.AnnouncementId = this.selectedAnnouncement.announcementid;
      this._announcementService
        .AnnouncementDelete(this.AnnouncementId)
        .subscribe(
          (res) => {
            this.isSucess.emit(true);
            this._sweetAlertService.successAlert(
              'center',
              'success',
              'The Application has been Deleted Successfully.',
              false,
              1500
            );
          },
          (_error: HttpErrorResponse) => {
            if (_error.status === 500) {
              this._sweetAlertService.InternalServerError500(
                'Error',
                'ERROR',
                _error.error
              );
            } else {
              this._sweetAlertService.validationError(
                'Error',
                'ERROR',
                _error.error
              );
            }
          }
        );
    }
  }
}
