import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OverTimeMaintanceService } from 'src/app/Services/Applications/OvertimeMaintanence/overtime.maintanence.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-delete-overtimedetail',
  templateUrl: './delete-overtimedetail.component.html',
  styleUrls: ['./delete-overtimedetail.component.css'],
})
export class DeleteOvertimedetailComponent {
  @Input() selectedEmp: any;
  appId: any;
  @Output() isSucess = new EventEmitter();
  constructor(private ot: OverTimeMaintanceService) {}

  confirmDelete() {
    if (this.selectedEmp) {
      let overtimeDelete = { RECORDID: this.selectedEmp.recordid, ATID: null };
      // console.log(overtimeDelete);
      this.ot.overtimeDelete(overtimeDelete).subscribe((retBool) => {
        // console.log("Successfully Deleted" + retBool);
        if (retBool) {
          this.successAlert();
          this.isSucess.emit(true);
        }
      });
    }
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Recored Deleted Succesfully',
      showConfirmButton: false,
      timer: 1500,
    });
  }
}
