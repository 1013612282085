<div
  class="modal fade"
  id="ViewEmployeeMedicalRestriction"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ViewEmployeeMedicalRestrictionLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
    style="max-width: 80%"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ViewEmployeeMedicalRestrictionLabel">
          View Medical Restrictions
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="clear()"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>
      <div>
        <div class="modal-body" style="padding-left: 80px; padding-right: 80px">
          <div class="">
            <div class="">
              <table class="empdescription-table">
                <thead class="empdescription-th">
                  <tr>
                    <th class="emp-atid">Employee Id</th>
                    <th class="">Name</th>
                    <th class="">Department</th>
                    <th class="">Job Code</th>
                    <th class="">Shift</th>
                    <th class="">Plant</th>
                  </tr>
                </thead>
                <tbody class="tab-value">
                  <tr
                    *ngIf="employeeMedicalRestrictions !== undefined"
                    class="table-container"
                  >
                    <td class="empdescription-table-tr">
                      {{ employeeMedicalRestrictions[0].employeeid }}
                    </td>
                    <td class="empdescription-table-tr">
                      {{
                        employeeMedicalRestrictions[0].lasT_NAME
                          ? employeeMedicalRestrictions[0].lasT_NAME
                          : 'N/A'
                      }},
                      {{
                        employeeMedicalRestrictions[0].firsT_NAME
                          ? employeeMedicalRestrictions[0].firsT_NAME
                          : 'N/A'
                      }}&nbsp;{{
                        employeeMedicalRestrictions[0].middle
                          ? employeeMedicalRestrictions[0].middle
                          : ''
                      }}
                    </td>
                    <td class="empdescription-table-tr">
                      {{ employeeMedicalRestrictions[0].dept }}
                    </td>
                    <td class="empdescription-table-tr">
                      {{ employeeMedicalRestrictions[0].jobcodeid }}
                      {{ employeeMedicalRestrictions[0].currenT_JOBCODEDESC }}
                    </td>
                    <td class="empdescription-table-tr">
                      {{
                        employeeMedicalRestrictions[0].currenT_SHIFTDESCRIPTION
                      }}
                    </td>
                    <td class="empdescription-table-tr">
                      {{ employeeMedicalRestrictions[0].currenT_PLANT }} -
                      {{ employeeMedicalRestrictions[0].currenT_PLANTDESC }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="pt-5">
            <div style="font-weight: 700; text-align: center">
              Employee's Medical Restriction(s)
            </div>
            <!-- filer -->
            <div>
              <!--For application-->
              <div>
                <table
                  class="table table-striped"
                  *ngIf="
                    employeeMedicalRestrictions !== null &&
                    employeeMedicalRestrictions.length > 0
                  "
                >
                  <thead class="table-header">
                    <th scope="col">Sr. #</th>
                    <th scope="col">Category</th>
                    <th scope="col">Description</th>
                    <th scope="col">Text</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">End Date</th>
                  </thead>

                  <tbody>
                    <tr
                      *ngFor="
                        let nores of employeeMedicalRestrictions;
                        let i = index
                      "
                      class="table-container"
                    >
                      <td class="tab-value">{{ i + 1 }}</td>
                      <td class="tab-value">
                        {{ nores.medicalrestrictioncatdesc }}({{
                          nores.medicalrestrictioncategorycode
                        }})
                      </td>
                      <td class="tab-value">
                        {{ nores.medicalrestrictiondesc }}({{
                          nores.medicalrestrictioncode
                        }})
                      </td>
                      <td class="tab-value">
                        {{
                          nores.restrictiontext ? nores.restrictiontext : '-'
                        }}
                      </td>
                      <td class="tab-value">
                        {{ nores.restrictionstartdate | date: 'MM/dd/yyyy' }}
                      </td>
                      <td class="tab-value">
                        {{ nores.restrictionenddate | date: 'MM/dd/yyyy' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div style="font-weight: 700; text-align: center">
              Last loaded on
              {{ LastUpdateDate | date: 'MM/dd/yyyy hh:mm:ss' }} by
              {{ LastUpdateBy }}.
            </div>
            <!-- </div>
                    </div>    -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary popupbuttonscancel"
            data-dismiss="modal"
            #closedViewModal
            (click)="clear()"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
