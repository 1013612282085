import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { OverTimeMaintanceService } from 'src/app/Services/Applications/OvertimeMaintanence/overtime.maintanence.service';
import { LocalStorageService } from 'src/app/Services/LocalStorageService/locastorage.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { UserRoles } from 'src/enum/userroles';
import { userData } from 'src/models/userdata';
import { UserInfo } from 'src/models/userinfo';
import { formatDate } from '@angular/common';
import { OverTimeMainSearchGroupPostModel } from '../../SharedForm/search-model/search-model';
import { OverTimeMainSearchIndividualPostModel } from '../../SharedForm/search-model/search-model';
import { RoleMatchService } from 'src/app/Services/role-match.service';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';

@Component({
  selector: 'app-overtime',
  templateUrl: './overtime.component.html',
  styleUrls: ['./overtime.component.css'],
})
export class OvertimeComponent implements OnInit, OnDestroy {
  @Input() isView = false;
  @Input() isAllocationView = false;
  @Input() isOvertimeAnalytics = false;
  @Input() viewName: string = 'OVERTIME';
  @Input() moduleName: string = 'OVERTIME';
  @ViewChild('OvertimeTableExport', { read: ElementRef, static: false })
  OvertimeTableExport!: ElementRef;

  emloyeeData: any = [];
  userData: any = [];
  modalPopTitle = {
    title: '',
    type: '',
  };
  postModel: any;
  employeeForm!: FormGroup;
  selectedEmp: any;
  isEditButton = false;
  isAddButton = false;
  isEdit = false;
  isCancel = false;
  modalTitle: any = '';
  isAdd = false;
  modalId: string | any;

  isUpdate = false;
  isDelete = false;
  isSingleRecord = false;
  updateModalId: string | any;
  typeSelected: any;
  screenType: any = [];
  isApplication = false;
  searchKey: string = '';
  isDeleted = false;
  showFilter = true;
  applicationData: any = [];
  loggedInUserATID: string = '';
  passAddData: any = [];
  passUpdateData: any[] = [];
  modifiedOvertimRecords: any[] = [];
  isOvertimeDataEmpty = false;
  isFilterValidation = false;
  enableAddButton = false;
  enableDetailButton = false;
  enableIndButtons = false;
  isUserDataEmpty = true;

  isSearchHit = false;
  monday: Date | any;
  tuesday: Date | any;
  wednesday: Date | any;
  thursday: Date | any;
  friday: Date | any;
  saturday: Date | any;
  sunday: Date | any;
  DepartmentDefaultVal: string = '';
  iRuleResult = new Subject();
  isRulePassed = false;
  ruleMessage: string = '';
  userInformation!: UserInfo; // Model for UserInfo
  usersData!: userData; // Model for UAW user details
  userRole: UserRoles = UserRoles.SKILLEDUSER; // Enum for userrole
  returnstatus!: boolean; // stores cancelled status fif any
  returnOpenStatus!: boolean; // stores open status if any
  isUser = true;
  isSelectAllEmployee = false;
  checkedEmployeeCount: number = 0;
  DefaultOverTimeTypeOvt: any;
  DefaultAsofDateOvt: any;
  DefaultDeptOvt: any;
  DefaultClassOvt: any;
  GroupType: any;
  DefaultShiftOvt: any;
  SearchType: any;
  SelectedEmp: any;
  IndEmployeeAdd = false;
  MassEmployeeAdd = false;
  overtimeGroupName: string = '';
  AverageAccHours: number = 0.0;

  DefaultIndFirstName: any;
  DefaultIndLastName: any;
  DefaultIndEmployeeId: any;
  asOfDate: Date | any;
  overtimeGroupId: number = 0;

  searchTypes: string[] = ['Overtime Group', 'Individual Employee'];
  searchType: any = 'Overtime Group';

  medicalRestrictionEmployeeId: string = '';
  medicalRestrictionEmpName: string = '';
  views: any[] = [];
  ModalName: any;
  employeePhoneNumber: any;
  TotalApplicationCount: any;
  Applicationpage: number = 1;

  disableSubmit!: boolean | false;

  isDataExportableView = true;
  navigateToAnalytics = false;
  ClassificationType: string = '';
  otGroupData: any;
  public loading = false;
  OvertimeAnalyticsData: any;
  MassOvertimeAdd = false;
  IndOvertimeAdd = false;
  sortDir = 1; //1= 'ASE' -1= DSC
  sortColumn = 'acchours';
  recordAccHours: any;
  checkEmployeeIdByUser: any;
  isSucess = false;
  isSucessDelete = false;
  weeklyOvertimeRecords: any[] = [];

  constructor(
    private fb: FormBuilder,
    public _userInfo: UsergroupService,
    private ot: OverTimeMaintanceService,
    private sweetAlert: SweetAlertService,
    private router: Router,
    public roleService: RoleMatchService,
    private localStorageService: LocalStorageService,
    private _commonApplicationService: CommonService
  ) {
    this.getUserRoleDescreption();
    this.DepartmentDefaultVal = '';
  }

  ngOnDestroy(): void {
    if (
      this.router.url !== '/OvertimeDetail' &&
      this.router.url !== '/OvertimeAllocationSummary'
    ) {
      this.localStorageService.removeItem('searchType');
      this.localStorageService.removeItem('deptId');
      this.localStorageService.removeItem('jobCodeId');
      this.localStorageService.removeItem('shiftId');
      this.localStorageService.removeItem('asOfDate');
      this.localStorageService.removeItem('overTimeTypeId');
      this.localStorageService.removeItem('toggleMainDetail');
      this.localStorageService.removeItem('isView');
      this.localStorageService.removeItem('isAllocationView');
      this.localStorageService.removeItem('isOvertimeAnalytics');
    }
  }

  ngOnInit(): void {
    this.isSearchHit = false;
    let toggleMainDetail = this.localStorageService.getItem('toggleMainDetail');
    this.SearchType = this.localStorageService.getItem('searchType');

    if (toggleMainDetail === 'true') {
      this.isSearchHit = true;
      if (this.SearchType === 'Group') {
        this.searchType = 'Overtime Group';
        this.showFilter = true;
        if (this.localStorageService.getItem('searchType') != 'undefined') {
          this.SearchType = this.localStorageService.getItem('searchType');
        }
        if (this.localStorageService.getItem('deptId') != 'undefined') {
          this.DefaultDeptOvt = this.localStorageService.getItem('deptId');
        }
        if (this.localStorageService.getItem('jobCodeId') != 'undefined') {
          this.DefaultClassOvt = this.localStorageService.getItem('jobCodeId');
          const tempJobCodeId = this.localStorageService.getItem('jobCodeId');
          const tempClassType = tempJobCodeId!
            .charAt(tempJobCodeId!.length - 1)
            .toString();
          if (tempClassType.toLowerCase() === 'b') {
            this.ClassificationType = 'P';
          } else {
            this.ClassificationType = 'S';
          }
        }
        if (this.localStorageService.getItem('shiftId') != 'undefined') {
          this.DefaultShiftOvt = this.localStorageService.getItem('shiftId');
        }
        if (this.localStorageService.getItem('asOfDate') != 'undefined') {
          this.DefaultAsofDateOvt =
            this.localStorageService.getItem('asOfDate');
        }
        if (this.localStorageService.getItem('overTimeTypeId') != 'undefined') {
          this.DefaultOverTimeTypeOvt =
            this.localStorageService.getItem('overTimeTypeId');
        }

        const format = 'yyyy-MM-dd';
        const locale = 'en-US';
        this.DefaultAsofDateOvt = formatDate(
          this.DefaultAsofDateOvt,
          format,
          locale
        );
        let pModel = new OverTimeMainSearchGroupPostModel();
        pModel.SEARCHTYPE = 'Group';
        pModel.DEPTID = this.DefaultDeptOvt;
        pModel.JOBCODEID = this.DefaultClassOvt;
        pModel.SHIFTID = this.DefaultShiftOvt;
        pModel.ASOFDATE = this.DefaultAsofDateOvt;
        pModel.OVERTIMETYPEID = this.DefaultOverTimeTypeOvt;
        this.postModel = pModel;
        this.getWeekDayData();
        if (!this.isAllocationView) {
          this.ot.SearchOverTimeMainByUser(pModel).subscribe((data: any) => {
            this.userData = data;
            this.isOvertimeDataEmpty = this.userData.length > 0 ? false : true;
            this.isUserDataEmpty = this.userData.length > 0 ? false : true;
            this.enableDetailButton = false;
            this.enableAddButton = false;
            this.isSelectAllEmployee = false;
            this.calculateAverageAccHours(this.userData);
            this.Applicationpage = 1;
            this.TotalApplicationCount = data.length;
          });
        } else if (this.isAllocationView) {
          this.ot.SearchOvertimeGroup(this.postModel).subscribe((data: any) => {
            this.otGroupData = data;
            this.isSearchHit = true;
          });
        }
      } else {
        this.searchType = 'Individual Employee';
        this.showFilter = false;

        this.DefaultDeptOvt = '';
        this.DefaultClassOvt = '';
        this.DefaultShiftOvt = '';
        this.DefaultOverTimeTypeOvt = '';
        const format = 'yyyy-MM-dd';
        const locale = 'en-US';
        var dtAsOfDate = new Date();
        this.DefaultAsofDateOvt = formatDate(dtAsOfDate, format, locale);

        if (
          this.localStorageService.getItem('ovtIndFirstName') != 'undefined'
        ) {
          this.DefaultIndFirstName =
            this.localStorageService.getItem('ovtIndFirstName');
        }
        if (this.localStorageService.getItem('ovtIndLastName') != 'undefined') {
          this.DefaultIndLastName =
            this.localStorageService.getItem('ovtIndLastName');
        }
        if (this.localStorageService.getItem('ovtindAtid') != 'undefined') {
          this.DefaultIndEmployeeId =
            this.localStorageService.getItem('ovtindAtid');
        }

        let pModel = new OverTimeMainSearchIndividualPostModel();
        pModel.SEARCHTYPE = 'Individual';
        pModel.FIRSTNAME = this.DefaultIndFirstName;
        pModel.LASTNAME = this.DefaultIndLastName;
        pModel.EMPLOYEEID = this.DefaultIndEmployeeId;
        this.postModel = pModel;
        this.getWeekDayData();
        this.ot.SearchOverTimeMainByUser(pModel).subscribe((data: any) => {
          this.userData = data;
          this.isOvertimeDataEmpty = this.userData.length > 0 ? false : true;
          this.isUserDataEmpty = this.userData.length > 0 ? false : true;
          this.enableIndButtons = false;
          this.Applicationpage = 1;
          this.TotalApplicationCount = data.length;
        });
      }
    } else {
      const format = 'yyyy-MM-dd';
      const locale = 'en-US';
      var dtAsOfDate = new Date();
      this.DefaultAsofDateOvt = formatDate(dtAsOfDate, format, locale);
      this.DefaultShiftOvt = '';
      this.DefaultOverTimeTypeOvt = '';
    }
  }

  addEmployee(idx: number) {
    this.selectUser(idx);
    if (this.selectedEmp) {
      this.isAdd = true;
      this.modalId = '#addEmployee';
    } else {
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }

  //For User
  selectUser(idx: number) {
    this.userData[idx].selected = true;
    for (let index = 0; index < this.userData.length; index++) {
      this.userData[index].selected = false;
    }
    this.userData[idx].selected = true;
    this.selectedEmp = this.userData[idx];
  }


  modelRepopulateHandler(event: any) {
    this.postModel = event.key;
    this.disableSubmit = event.value;
  }

  onAppTypeSelected(val: number) {
    if (val === 2) {
      this.showFilter = false;
    }
    if (val === 1) {
      this.showFilter = true;
    }
    this.userData = null;
    this.isOvertimeDataEmpty = false;
    this.isUserDataEmpty = true;
  }

  onSearchTypeSelected(val: any) {
    this.searchType = val;
    if (this.searchType === 'Overtime Group') {
      this.showFilter = true;
    } else {
      this.showFilter = false;
    }

    this.userData = null;
    this.isOvertimeDataEmpty = false;
    this.isUserDataEmpty = true;
    this.isSearchHit = false;
  }


  submitForm() {
    if (this.showFilter) {
      this.OvertimeAnalyticsData = null;
      this.OvertimeAnalyticsData = this.postModel;
      if (
        this.postModel?.DEPTID === null ||
        this.postModel?.DEPTID === '' ||
        this.postModel?.DEPTID === undefined
      ) {
        this.sweetAlert.validationError(
          'error',
          'Overtime Group',
          'You must enter a valid department.'
        );
      } else if (
        this.postModel?.JOBCODEID === null ||
        this.postModel?.JOBCODEID === '' ||
        this.postModel?.JOBCODEID === undefined
      ) {
        this.sweetAlert.validationError(
          'error',
          'Overtime Group',
          'You must enter a valid classification.'
        );
      } else if (
        this.postModel?.SHIFTID === null ||
        this.postModel?.SHIFTID === '' ||
        this.postModel?.SHIFTID === undefined
      ) {
        this.sweetAlert.validationError(
          'error',
          'Overtime Group',
          'Select a Shift'
        );
      } else if (
        this.postModel?.ASOFDATE === null ||
        this.postModel?.ASOFDATE === '' ||
        this.postModel?.ASOFDATE === undefined
      ) {
        this.sweetAlert.validationError(
          'error',
          'Overtime Group',
          'You must enter a valid as of date'
        );
      } else {
        this.isFilterValidation = true;
        this.loading = true;
      }
    } else if (!this.showFilter) {
      if (this.postModel?.SEARCHTYPE === 'Group') {
        this.sweetAlert.validationError(
          'error',
          'Overtime Individual',
          'First Name or Last Name or EMPLOYEEID to be provided'
        );
      } else if (
        (this.postModel?.FIRSTNAME === null ||
          this.postModel?.FIRSTNAME?.trim() === '' ||
          this.postModel?.FIRSTNAME === undefined) &&
        (this.postModel?.LASTNAME === null ||
          this.postModel?.LASTNAME?.trim() === '' ||
          this.postModel?.LASTNAME === undefined) &&
        (this.postModel?.EMPLOYEEID === null ||
          this.postModel?.EMPLOYEEID?.trim() === '' ||
          this.postModel?.EMPLOYEEID === undefined)
      ) {
        this.sweetAlert.validationError(
          'error',
          'Overtime Individual',
          'First Name or Last Name or EMPLOYEEID to be provided'
        );
      } else {
        this.isFilterValidation = true;
        this.loading = true;
      }
    }

    if (this.isFilterValidation) {
      if (this.showFilter) {
        this.asOfDate = this.postModel?.ASOFDATE;
        this.getWeekDayData();

        if (!this.isAllocationView) {
          this.ot
            .SearchOverTimeMainByUser(this.postModel)
            .subscribe((data: any) => {
              this.userData = data;
              if (data.length === 0) {
                this.loading = false;
              }
              if (data.length > 0) {
                this.ClassificationType = data[0].classificationtype;
                this.calculateAverageAccHours(this.userData);
              }
              this.isSearchHit = true;
              this.enableDetailButton = false;
              this.enableAddButton = false;
              this.isSelectAllEmployee = false;
              this.Applicationpage = 1;
              this.TotalApplicationCount = data.length;
              this.loading = false;
            });
        } else if (this.isAllocationView) {
          this.ot.SearchOvertimeGroup(this.postModel).subscribe((data: any) => {
            this.otGroupData = data;
            this.isSearchHit = true;
            this.loading = false;
          });
        }
      } else {
        this.isRulePassed = true;
        this.getWeekDayData();
        this.ot
          .SearchOverTimeMainByUser(this.postModel)
          .subscribe((data: any) => {
            this.userData = data;
            this.isSearchHit = true;
            this.isOvertimeDataEmpty = this.userData.length > 0 ? false : true;
            this.isUserDataEmpty = this.userData.length > 0 ? false : true;
            this.enableIndButtons = false;
            this.Applicationpage = 1;
            this.TotalApplicationCount = data.length;
            this.loading = false;
          });
      }
    }
  }

  calculateAverageAccHours(userData: any) {
    var SumAccHours = 0;
    var NonZeroEmpCount = 0;
    if (this.userData.length > 0) {
      for (var j = 0; j < this.userData.length; j++) {
        if (this.userData[j].isactive) {
          SumAccHours += this.userData[j].acchours;
          NonZeroEmpCount++;
        }
      }
      this.AverageAccHours = SumAccHours / NonZeroEmpCount;

      if (this.userData.length > 0) {
        this.overtimeGroupName = this.userData[0].overtimegroupname;
        this.overtimeGroupId = this.userData[0].overtimegroupid;
      }
      this.isOvertimeDataEmpty = this.userData.length > 0 ? false : true;
      this.isUserDataEmpty = this.userData.length > 0 ? false : true;
    }
  }

  getUserRoleDescreption() {
    this._userInfo.getUserInfo().subscribe((data: any) => {
      if (data['groupDescription'] === UserRoles.SUPERVISOR) {
        this.userRole = UserRoles.SUPERVISOR;
      } else if (data['groupDescription'] === UserRoles.HRPLUS) {
        this.userRole = UserRoles.HRPLUS;
      } else if (data['groupDescription'] === UserRoles.PRODUCTIONUSER) {
        this.userRole = UserRoles.PRODUCTIONUSER;
      } else {
        this.userRole = UserRoles.SKILLEDUSER;
      }
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        this.moduleName,
        this.viewName
      );
      this.loggedInUserATID = this.userInformation.userName;
    });
  }

  getWeekDayData() {
    var dtAsOfDate = new Date();
    if (this.postModel?.SEARCHTYPE === 'Group') {
      const format = 'MM/dd/yyyy';
      const locale = 'en-US';
      const postModelasofdate = formatDate(
        this.postModel?.ASOFDATE,
        format,
        locale
      );
      dtAsOfDate = new Date(postModelasofdate);
      this.asOfDate = new Date(postModelasofdate);
    } else {
      dtAsOfDate = new Date();
      this.asOfDate = new Date();
    }
    var d = new Date(this.asOfDate);
    var dayName = d.toString().split(' ')[0];

    if (dayName === 'Sun') {
      dtAsOfDate.setDate(dtAsOfDate.getDate() - 1);
    }

    this.monday = new Date(
      dtAsOfDate.setDate(dtAsOfDate.getDate() - dtAsOfDate.getDay() + 1)
    );
    this.tuesday = new Date(
      dtAsOfDate.setDate(dtAsOfDate.getDate() - dtAsOfDate.getDay() + 2)
    );
    this.wednesday = new Date(
      dtAsOfDate.setDate(dtAsOfDate.getDate() - dtAsOfDate.getDay() + 3)
    );
    this.thursday = new Date(
      dtAsOfDate.setDate(dtAsOfDate.getDate() - dtAsOfDate.getDay() + 4)
    );
    this.friday = new Date(
      dtAsOfDate.setDate(dtAsOfDate.getDate() - dtAsOfDate.getDay() + 5)
    );
    this.saturday = new Date(
      dtAsOfDate.setDate(dtAsOfDate.getDate() - dtAsOfDate.getDay() + 6)
    );
    this.sunday = new Date(
      dtAsOfDate.setDate(dtAsOfDate.getDate() - dtAsOfDate.getDay() + 7)
    );
  }

  onEmployeeSelect(record: any) {
    var empCount = 0;
    if (this.userData != null) {
      this.userData.forEach(function (empRowData: any) {
        if (empRowData.ischecked === true) {
          empCount = empCount + 1;
        }
      });
    }
    this.checkedEmployeeCount = empCount;
    if (this.checkedEmployeeCount > 0) {
      this.enableAddButton = true;
    } else {
      this.enableAddButton = false;
    }

    if (this.checkedEmployeeCount === 1) {
      this.checkEmployeeIdByUser = record.employeeid;
      this.recordAccHours = record.acchours;
      this.enableDetailButton = true;
    } else {
      this.enableDetailButton = false;
    }
  }

  onIndEmployeeSelect(data: any, idx: number) {
    this.enableIndButtons = true;
    this.SelectedEmp = data;
    this.checkEmployeeIdByUser = data.employeeid;
    this.recordAccHours = data.acchours;
  }

  onIndOvertimeAdd() {
    if (this.SelectedEmp) {
      this.isAdd = true;
      this.modalId = '#addIndividualEmployee';
    } else {
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }

  selectAllEmployee() {
    if (this.isSelectAllEmployee === true) {
      this.MassEmployeeAdd = true;
      this.IndEmployeeAdd = false;
      this.userData.forEach((val: any) => {
        val.ischecked = true;
      });
    } else {
      this.userData.forEach((val: any) => {
        val.ischecked = false;
      });
    }

    let empCount = 0;
    if (this.userData) {
      this.userData.forEach((empRowData: any) => {
        if (empRowData.ischecked) {
          empCount = empCount + 1;
        }
      });
    }

    this.checkedEmployeeCount = empCount;
    if (this.checkedEmployeeCount > 0) {
      this.enableAddButton = true;
    } else {
      this.enableAddButton = false;
    }
  }

  onAddOvertime() {
    this.passAddData.length = 0;
    var tempPassAddData: any[] = [];
    for (var j = 0; j < this.userData.length; j++) {
      if (this.userData[j].ischecked === true) {
        tempPassAddData.push(this.userData[j]);
      }
    }
    this.passAddData = JSON.parse(JSON.stringify(tempPassAddData));
    if (this.passAddData) {
      this.isAdd = true;
      this.modalId = '#addEmployee';
    } else {
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }


  CheckMassAdd(e: any) {
    this.MassOvertimeAdd = e;
    if (this.MassOvertimeAdd === true) {
      this.userData = this.submitForm();
    }
  }

  CheckIndAdd(e: any) {
    this.IndOvertimeAdd = e;
    if (this.IndOvertimeAdd === true) {
      this.userData = this.submitForm();
    }
  }

  onUpdateOvertime() {
    this.modifiedOvertimRecords.length = 0;
    this.passUpdateData.length = 0;
    var tempPassUpdateData: any[] = [];
    var tempModifiedOvertimRecords: any[] = [];
    for (var j = 0; j < this.userData.length; j++) {
      if (this.userData[j].ischecked === true) {
        tempPassUpdateData.push(this.userData[j]);
      }
    }
    this.passUpdateData = JSON.parse(JSON.stringify(tempPassUpdateData));
    this.modifiedOvertimRecords = JSON.parse(
      JSON.stringify(tempModifiedOvertimRecords)
    );
    if (this.passUpdateData) {
      this.isUpdate = true;
      this.modalId = '#updateEmployee';
    } else {
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }

  onDeleteOvertime() {
    this.modifiedOvertimRecords.length = 0;
    this.passUpdateData.length = 0;
    var tempPassUpdateData: any[] = [];
    var tempModifiedOvertimRecords: any[] = [];
    for (var j = 0; j < this.userData.length; j++) {
      if (this.userData[j].ischecked === true) {
        tempPassUpdateData.push(this.userData[j]);
      }
    }
    this.passUpdateData = JSON.parse(JSON.stringify(tempPassUpdateData));
    this.modifiedOvertimRecords = JSON.parse(
      JSON.stringify(tempModifiedOvertimRecords)
    );
    if (this.passUpdateData) {
      this.isDelete = true;
      this.modalId = '#DeleteOvertimeRecordScreen';
    } else {
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }

  onIndOvertimeDelete() {
    this.passUpdateData.length = 0;
    this.modifiedOvertimRecords.length = 0;
    var tempPassUpdateData: any[] = [];
    var tempModifiedOvertimRecords: any[] = [];
    tempPassUpdateData.push(this.SelectedEmp);
    this.passUpdateData = JSON.parse(JSON.stringify(tempPassUpdateData));
    this.modifiedOvertimRecords = JSON.parse(
      JSON.stringify(tempModifiedOvertimRecords)
    );
    if (this.passUpdateData) {
      this.isUpdate = true;
      this.modalId = '#DeleteOvertimeRecordScreen';
    } else {
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }

  showMedicalRestrictions() {
    this.medicalRestrictionEmployeeId = 'czvapu';
    this.medicalRestrictionEmployeeId = JSON.stringify(
      this.medicalRestrictionEmployeeId
    );
    if (this.medicalRestrictionEmployeeId) {
      this.modalId = '#EmployeeMedicalRestrictions';
    } else {
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }

  showMedicalRestrictions1(
    employeeId: string,
    phoneNumber: string,
    modalName: string,
    firstname: string,
    lastname: string,
    middle: string
  ) {
    this.ModalName = modalName;
    this.medicalRestrictionEmployeeId = employeeId;
    this.medicalRestrictionEmpName = lastname + ', ' + firstname + ' ' + middle;
    this.employeePhoneNumber = phoneNumber;
    if (this.medicalRestrictionEmployeeId) {
      this.modalId = '#EmployeeMedicalRestrictions';
    } else {
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.userData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.userData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }

  changeCheck(record: any) {
    this.checkEmployeeIdByUser = record.employeeid;
    this.recordAccHours = record.acchours;
  }

  DetailNavigate() {
    this.localStorageService.setItem('ovtAtid', this.checkEmployeeIdByUser);
    this.localStorageService.setItem('ovtAtidAccHours', this.recordAccHours);
    this.localStorageService.setItem('searchType', this.postModel?.SEARCHTYPE);
    this.localStorageService.setItem('deptId', this.postModel?.DEPTID);
    this.localStorageService.setItem('jobCodeId', this.postModel?.JOBCODEID);
    this.localStorageService.setItem('shiftId', this.postModel?.SHIFTID);
    this.localStorageService.setItem('asOfDate', this.postModel?.ASOFDATE);
    this.localStorageService.setItem(
      'overTimeTypeId',
      this.postModel?.OVERTIMETYPEID
    );
    this.localStorageService.setItem('toggleMainDetail', 'true');
    if (this.isView) {
      this.localStorageService.setItem('isView', 'Y');
    } else {
      this.localStorageService.setItem('isView', 'N');
    }
    this.router.navigate(['OvertimeDetail']);
  }

  DetailNavigateInd() {
    this.localStorageService.setItem('ovtAtid', this.checkEmployeeIdByUser);
    this.localStorageService.setItem('ovtAtidAccHours', this.recordAccHours);
    this.localStorageService.setItem('searchType', this.postModel?.SEARCHTYPE);
    this.localStorageService.setItem('deptId', this.postModel?.DEPTID);
    this.localStorageService.setItem('jobCodeId', this.postModel?.JOBCODEID);
    this.localStorageService.setItem('shiftId', this.postModel?.SHIFTID);
    this.localStorageService.setItem('asOfDate', this.postModel?.ASOFDATE);
    this.localStorageService.setItem(
      'overTimeTypeId',
      this.postModel?.OVERTIMETYPEID
    );
    this.localStorageService.setItem(
      'ovtIndFirstName',
      this.postModel?.FIRSTNAME
    );
    this.localStorageService.setItem(
      'ovtIndLastName',
      this.postModel?.LASTNAME
    );
    this.localStorageService.setItem('ovtindAtid', this.postModel?.EMPLOYEEID);
    this.localStorageService.setItem('toggleMainDetail', 'true');
    if (this.isView) {
      this.localStorageService.setItem('isView', 'Y');
    } else {
      this.localStorageService.setItem('isView', 'N');
    }
    this.router.navigate(['OvertimeDetail']);
  }

  CheckMassUpdate(e: any) {
    this.isSucess = e;
    if (this.isSucess === true) {
      this.userData = this.submitForm();
    }
  }

  CheckMassDelete(e: any) {
    this.isSucessDelete = e;
    if (this.isSucessDelete === true) {
      this.userData = this.submitForm();
    }
  }

  onIndOvertimeUpdate() {
    this.passUpdateData.length = 0;
    this.modifiedOvertimRecords.length = 0;
    var tempPassUpdateData: any[] = [];
    var tempModifiedOvertimRecords: any[] = [];
    tempPassUpdateData.push(this.SelectedEmp);
    this.passUpdateData = JSON.parse(JSON.stringify(tempPassUpdateData));
    this.modifiedOvertimRecords = JSON.parse(
      JSON.stringify(tempModifiedOvertimRecords)
    );
    if (this.passUpdateData) {
      this.isUpdate = true;
      this.modalId = '#updateEmployee';
    } else {
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }

  onAllocationSummaryClick() {
    if (this.isView) {
      this.localStorageService.setItem('isView', 'Y');
    } else {
      this.localStorageService.setItem('isView', 'N');
    }
    if (this.isAllocationView) {
      this.localStorageService.setItem('isAllocationView', 'Y');
      this.localStorageService.setItem('ovtAtid', this.checkEmployeeIdByUser);
      this.localStorageService.setItem(
        'oasOvertimeGroupId',
        this.otGroupData.overtimegroupid.toString()
      );
      this.localStorageService.setItem('ovtAtidAccHours', this.recordAccHours);
      this.localStorageService.setItem(
        'searchType',
        this.postModel?.SEARCHTYPE
      );
      this.localStorageService.setItem('deptId', this.postModel?.DEPTID);
      this.localStorageService.setItem('jobCodeId', this.postModel?.JOBCODEID);
      this.localStorageService.setItem('shiftId', this.postModel?.SHIFTID);
      this.localStorageService.setItem('asOfDate', this.postModel?.ASOFDATE);
      this.localStorageService.setItem(
        'overTimeTypeId',
        this.postModel?.OVERTIMETYPEID
      );
      this.localStorageService.setItem('toggleMainDetail', 'true');
      this.localStorageService.setItem('oasAsOfDate', this.asOfDate);
      this.localStorageService.setItem(
        'oasOvertimeGroupName',
        this.otGroupData.overtimegroupname.toString()
      );
    } else {
      this.localStorageService.setItem('isAllocationView', 'N');
      this.localStorageService.setItem('ovtAtid', this.checkEmployeeIdByUser);
      this.localStorageService.setItem(
        'oasOvertimeGroupId',
        this.overtimeGroupId.toString()
      );
      this.localStorageService.setItem('ovtAtidAccHours', this.recordAccHours);
      this.localStorageService.setItem(
        'searchType',
        this.postModel?.SEARCHTYPE
      );
      this.localStorageService.setItem('deptId', this.postModel?.DEPTID);
      this.localStorageService.setItem('jobCodeId', this.postModel?.JOBCODEID);
      this.localStorageService.setItem('shiftId', this.postModel?.SHIFTID);
      this.localStorageService.setItem('asOfDate', this.postModel?.ASOFDATE);
      this.localStorageService.setItem(
        'overTimeTypeId',
        this.postModel?.OVERTIMETYPEID
      );
      this.localStorageService.setItem('toggleMainDetail', 'true');
      this.localStorageService.setItem('oasAsOfDate', this.asOfDate);
      this.localStorageService.setItem(
        'oasOvertimeGroupName',
        this.overtimeGroupName
      );
    }

    this.router.navigate(['/OvertimeAllocationSummary']);
  }


  onPrintClick() {
    this.weeklyOvertimeRecords = JSON.parse(JSON.stringify(this.userData));
    this.postModel = JSON.parse(JSON.stringify(this.postModel));
    if (this.weeklyOvertimeRecords) {
      this.modalId = '#printWeeklyReport';
    } else {
      this.sweetAlert.simpleAlert('error', 'Oops', 'No Record to Print!');
    }
  }


  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.OvertimeTableExport,
        `${ApplicationEnum.OVERTIME}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }


  NavigateToAnalytics() {
    this.navigateToAnalytics = true;
    //this.router.navigate(['OvertimeAnalytics']);
    if (this.OvertimeAnalyticsData) {
      this.isAdd = true;
      this.modalId = '#OvertimeAnalyticalChart';
    }
  }
  navigateToOvertime(e: any) {
    this.navigateToAnalytics = e;
  }
}

