import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root',
})
export class SkilledToSkilledService {
  private readonly api_searchApplications =
    '/SkilledToSkilledTransferApplication152SearchApplication';
  private readonly api_searchUsers =
    '/SkilledToSkilledTransferApplication152SearchUser';
  private readonly api_getClassification =
    '/SkilledToSkilled152GetClassifications';
  private readonly api_addApplication =
    '/SkilledToSkilledTransferApplication152Add';
  private readonly api_editApplication = '/SkilledToSkilled152Correct';
  private readonly api_cancelApplication = '/CancelApplication';

  appId = new Subject();
  empsignDate = new Subject();
  atid = new Subject();
  commonUtility = new CommonUtility();
  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  searchFilterApplication(formData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.applicationsURL}` + this.api_searchApplications
      , formData,
      { headers }
    );
  }

  searchFilterApplicationUser(formData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.applicationsURL}` + this.api_searchUsers,
      formData,
      { headers }
    );
  }

  getClassifications(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.applicationsURL}` + this.api_getClassification,
      { headers }
    );
  }

  Edit152Application(app152: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.applicationsURL}` + this.api_editApplication,
      app152,
      { headers }
    );
  }

  Cancel152Application(obj: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.applicationsURL}` + this.api_cancelApplication,
      obj,
      { headers }
    );
  }

  Add152Application(add178: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.applicationsURL}` + this.api_addApplication,
      add178,
      { headers }
    );
  }
}
