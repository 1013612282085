import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { ProductionToSkilledService } from 'src/app/Services/Applications/ProductionToSkilled/production-to-skilled.service';
import { DateService } from 'src/app/Services/Date/date.service';
import { RuleEngineService } from 'src/app/Services/Ruleengine/rule-engine.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { UserInfo } from 'src/models/userinfo';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-production-to-skilled',
  templateUrl: './add-production-to-skilled.component.html',
  styleUrls: ['./add-production-to-skilled.component.css'],
})
export class AddProductionToSkilledComponent implements OnInit {
  employeeForm!: FormGroup;
  empSelected: any;
  selectedPlantId: any;
  atid: any;
  employeeId: any;
  currentDate = new Date();
  usersData: any;
  currentJobClassification: any;
  isAdmin: any;
  classifications: any[] = [
    {
      data: [],
      default: 'Select any classification',
    },
  ];
  maxDate: string = '';
  medicalRestrictionList: string[] = [];

  StatusCode: boolean = true;
  ruleMessages!: any[];
  errorlength: number = 0;

  @Input() loggedInUser: any;
  @Input() selectedEmp: any;
  @Input() isAdd: boolean = false;
  @Input() title: any;
  @Output() clean = new EventEmitter();
  @Output() isSucess = new EventEmitter();
  @Input() AppType: string = '';
  @Input() isLoggedInUserAdmin: any;
  @ViewChild('closeAddModel') closeAddModal!: ElementRef;

  userInformation!: UserInfo;

  constructor(
    private fb: FormBuilder,
    private _sweetAlertService: SweetAlertService,
    private authService: MsalService,
    private _user: UsergroupService,
    private ps: ProductionToSkilledService,
    private _commonApplicationService: CommonService,
    private _ruleEngine: RuleEngineService,
    private _dateService: DateService
  ) {}

  ngOnInit(): void {
    this.getClassifications();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createEmpForm();
    this.loadEmployeeData(changes);
  }

  createEmpForm() {
    this.employeeForm = this.fb.group({
      employeeName: [''],
      firstName: [''],
      lastName: [''],
      ATID: [this.atid],
      EmployeeId: [this.employeeId],
      AppNo: ['0'],
      AppStatusCode: ['OPEN'],
      currentClassification: [''],
      JobCodeId: [
        '',
        [
          Validators.required,
          this.CheckLeaderClassification,
          this.checkApprenticeClassification,
        ],
      ],
      T09OverrideFlag: [null],
      ExpValidateFlag: [false, [Validators.requiredTrue]],
      T04OverrideFlag: [null, []],
      ClassOverrideFlag: [null, []],
      SeniorityOverrideFlag: [null, []],
      Comments: [null, []],
      SpecialSP: [null, []],
      App150PlantGroupId: ['0'],
      AppType: ['178'],
      DateEntered: [this.currentDate],
      EnteredBy: [this.loggedInUser.userName],
      appStatusDate: [this.currentDate],
      empSignDate: ['', [Validators.required]],
      StatusOverrideFlag: [null, []],
    });
    this.isAdd = false;
  }

  loadEmployeeData(changes: SimpleChanges) {
    this.empSelected =
      (changes.selectedEmp && changes.selectedEmp.currentValue) || '';
    if (this.empSelected === '') {
      this.empSelected =
        (changes.selectedEmp && changes.selectedEmp.previousValue) || '';
    }

    this.getRuleEngineValidations(this.empSelected.employeeId);
    this.getMedicalRestrictions(this.empSelected.employeeId);
    this.maxDate = this._commonApplicationService.formatDate(this.currentDate);
    this.employeeForm
      .get('employeeName')
      ?.patchValue(
        this.empSelected.lastName +
          ', ' +
          this.empSelected.firstName +
          ' ' +
          this.empSelected.middleName
      );
    this.employeeForm.get('firstName')?.patchValue(this.empSelected.firstName);
    this.employeeForm.get('lastName')?.patchValue(this.empSelected.lastName);
    this.atid = this.employeeForm
      .get('ATID')
      ?.patchValue(this.empSelected.atid);
    this.employeeId = this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.empSelected.employeeId);
    this.employeeForm
      .get('empSignDate')
      ?.patchValue(this._commonApplicationService.formatDate(new Date()));

    this.ps
      .searchFilterApplicationUser({ employeeId: this.empSelected.employeeId })
      .subscribe((data) => {
        this.usersData = data[0];
        this.currentJobClassification = this.usersData.jobCodeId;
        this.employeeForm
          .get('currentClassification')
          ?.patchValue(
            this.usersData.jobCodeId + '-' + this.usersData.jobCodeDescription
          );
      });
  }

  getMedicalRestrictions(employeeId: string) {
    this._ruleEngine.getMedicalRestrictions(employeeId).subscribe((data) => {
      this.medicalRestrictionList = data;
    });
  }

  getRuleEngineValidations(employeeId: string) {
    this._ruleEngine
      .addPS178ApplicationRule(employeeId)
      .subscribe((data: any) => {
        this.StatusCode = data['status']; //for error: false
        this.ruleMessages = data['errors'];
        this.errorlength = data['errors'].length;
      });
  }

  clear() {
    this.clean.emit('');
    this.closeAddModal.nativeElement.click();
    this.resetFields();
  }

  clearForm() {
    this.employeeForm.reset;
  }

  resetFields() {
    this.employeeForm.get('ExpValidateFlag')?.reset();
    this.employeeForm.get('empSignDate')?.reset();
    this.getClassifications();
    this.employeeForm
      .get('empSignDate')
      ?.patchValue(this._commonApplicationService.formatDate(new Date()));
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
    });
  }

  getClassifications() {
    this.ps.getClassifications().subscribe((data) => {
      this.classifications[this.classifications.length - 1].data = data;
    });
  }

  CheckLeaderClassification(control: FormControl) {
    let jobClassification = control.value;
    let checkChar = jobClassification.charAt(jobClassification.length - 1);
    if (checkChar === 'L') {
      return {
        leaderClassificationError:
          'The selected classification cannot be a leader classification',
      };
    }
    return null;
  }

  checkApprenticeClassification(control: FormControl) {
    let jobClassification = control.value;
    let checkChar = jobClassification.charAt(jobClassification.length - 1);
    if (checkChar === 'A') {
      return {
        ApprenticeClassificationError:
          'The selected classification cannot be a apprentice classification',
      };
    }
    return null;
  }

  CheckExpValidation(control: FormControl) {
    let isExpValidateChecked = control.value;
    if (isExpValidateChecked === false) {
      return {
        expValidateError: 'Validate Experience is required',
      };
    }
    return null;
  }

  //Method to add an employee
  addEmployee() {
    let appliedClassification = this.employeeForm.get('JobCodeId')?.value;
    if (this.currentJobClassification === appliedClassification) {
      this.wrongClassificationError();
    } else {
      if (this.ruleMessages.length === 0) {
        // var dt = this.employeeForm.get('empSignDate')?.value;
        // var empDt = new Date(dt);
        // var empSignDtInUtc = this._dateService.GetUTCDate(empDt);
        // this.employeeForm.patchValue({empSignDate: empSignDtInUtc});
        // console.log(this.employeeForm.value);

        this.ps.Add178Application(this.employeeForm.value).subscribe(
          (data: any) => {
            this.clear();
            this.isSucess.emit(true);
            this._sweetAlertService.successAlert(
              'center',
              'success',
              'The Application has been Added Successfully.',
              false,
              1500
            );
          },
          (_error: HttpErrorResponse) => {
            //this.badRequestAlert();
            if (_error.status === 406) {
              this._sweetAlertService.validationError(
                'error',
                'ERROR',
                `${_error.error
                  .toString()
                  .replace(
                    'Error: ProductionToSkilled178Add - InvalidDataException:',
                    ''
                  )}`
              );
            } else {
              this._sweetAlertService.InternalServerError500(
                'error',
                'ERROR',
                'An Error occured while processing.Please try again.'
              );
            }
          }
        );
      } else {
        this.ruleError();
      }
    }
  }

  ruleError() {
    Swal.fire({
      icon: 'error',
      title: 'Cannot add application for this employee',
      text: 'Employee is not eligible.',
    });
  }

  // successAlert() {
  //   Swal.fire({
  //     position: 'center',
  //     icon: 'success',
  //     title: 'The Application has been Added Successfully.',
  //     showConfirmButton: false,
  //     timer: 1500,
  //   });
  // }
  // badRequestAlert() {
  //   Swal.fire({
  //     icon: 'error',
  //     title: 'ERROR',
  //     text: 'An Error occured while processing.Please try again.',
  //   })
  // }

  wrongClassificationError() {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: 'Cannot apply for current classification',
    });
  }

  // validationError() {
  //   Swal.fire({
  //     title: 'Cannot create application',
  //     text: "User has an OPEN application and/or not eligible to create a new application",
  //     icon: 'warning',
  //     showCancelButton: false,
  //     confirmButtonColor: '#3085d6',
  //     confirmButtonText: 'Ok'
  //   });
  // }
}
