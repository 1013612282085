import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class OvertimeGroupDataService {
  commonUtility = new CommonUtility();
  private readonly api_searchOvertimeGroupData = '/OvertimeGroupSearch';
  private readonly api_addOvertimeGroupData = '/OvertimeGroupAdd';
  private readonly api_updateOvertimeGroupData = '/OvertimeGroupUpdate';
  private readonly api_deleteOvertimeGroupData = '/OvertimeGroupDelete?overtimeGroupId=';
  private readonly api_getClassifications = '/GetAllClassification';
  private readonly api_getDepartments = '/GetAllDepartments';
  private readonly api_getShifts = '/GetShifts';

  constructor(
    private _http: HttpClient, 
    private appCom: MSALserviceService
  ) { }

  searchOvertimeGroupData(searchParams:any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_searchOvertimeGroupData,
      searchParams,
      { headers }
    );
  }
  deleteOvertimeGroupData(overtimeGroupid: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.delete<any>(
      `${environment.tableMaintenanceURL}` + this.api_deleteOvertimeGroupData + overtimeGroupid,
      { headers }
    );
  }
  getShifts(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getShifts,
      { headers }
    );
  }
  getClassifications(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getClassifications, 
      { headers }
    );
  }
  getDepartments(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getDepartments,
      { headers }
    );
  }
  addOvertimeGroupData(overtimeGroupData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_addOvertimeGroupData,
      overtimeGroupData,
      { headers }
    );
  }
  updateOvertimeGroupData(overtimeGroupData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_updateOvertimeGroupData,
      overtimeGroupData,
      { headers }
    );
  }


}
