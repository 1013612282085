import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { ReportsAndChartsService } from 'src/app/Services/ReportsAndCharts/reports-and-charts.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';

@Component({
  selector: 'app-overtime-reassign-group-moves',
  templateUrl: './overtime-reassign-group-moves.component.html',
  styleUrls: ['./overtime-reassign-group-moves.component.css'],
})
export class OvertimeReassignGroupMovesComponent {
  showFilter: boolean = true;
  filterData: any = {};
  userInformation: any;
  views: any[] = [];
  TotalApplicationCount: any;
  UserPage: number = 1;
  Applicationpage: number = 1;
  filterConfig: any = [
    {
      label: 'Employee Id',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      controlName: 'EMPLOYEEID',
      width: '140px',
      runClassification: false,
    },
    {
      label: 'Last Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      controlName: 'LASTNAME',
      width: '140px',
      runClassification: false,
    },
    {
      label: 'First Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      controlName: 'FIRSTNAME',
      width: '140px',
      runClassification: false,
    },
    {
      label: 'From',
      type: 'date',
      data: [],
      default: 'All',
      controlName: 'fromDate',
      width: '140px',
      runClassification: false,
    },
    {
      label: 'To',
      type: 'date',
      data: [],
      default: 'All',
      controlName: 'toDate',
      width: '140px',
      runClassification: false,
    },
  ];

  responseData: any;
  responseDataExists: boolean = false;
  FilterConfigLog: any;
  overtimegroupname: any;

  isLoading: boolean = false;
  OvertimeAcceptedRefusedChart: any = [];
  constructor(
    private _ReportsAndChartsService: ReportsAndChartsService,
    private _commonApplicationService: CommonService,
    private _userInfo: UsergroupService,
    private _sweetAlertService: SweetAlertService
  ) {
    this.getUserInfo();
  }

  getFilterValue(event: any) {
    this.filterData = event;
  }

  getUserInfo() {
    this._userInfo.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'OVERTIME',
        'OVERTIMEREASSIGNHISTORY'
      );
    });
  }

  GetReassignHistory() {
    this.isLoading = true;
    let queryParams = this.filterData;
    this.FilterConfigLog = queryParams;
    Object.keys(queryParams).forEach(
      (k) =>
        !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]
    );
    if (
      !queryParams.FIRSTNAME &&
      !queryParams.EMPLOYEEID &&
      !queryParams.LASTNAME
    ) {
      this.isLoading = false;
      return this._sweetAlertService.SearchFieldMissingAlert(
        'error',
        'Error',
        '<p><b>Please enter one of the Following:</b></p><p><ul><li>First name</li><li>Last name</li><li>Employee ID</li></ul></p>'
      );
    }
    this._ReportsAndChartsService
      .GetOvertimeReassignHistory(queryParams)
      .subscribe(
        (_response: any) => {
          this.isLoading = false;
          this.responseData = _response;
          this.responseData.forEach((element: any) => {
            element.formattedDateleft =
              element.dateleft != '0001-01-01T00:00:00'
                ? element.dateleft
                : null;
            element.differenceinMilliSeconds = Math.abs(
              Number(
                new Date(formatDate(element.datentered, 'yyyy-MM-dd', 'en-US'))
              ) -
                Number(
                  new Date(
                    formatDate(
                      element.formattedDateleft
                        ? element.formattedDateleft
                        : Date.now(),
                      'yyyy-MM-dd',
                      'en-US'
                    )
                  )
                )
            );
            element.differenceBtwDates =
              element.differenceinMilliSeconds / (24 * 60 * 60 * 1000);
          });

          this.responseDataExists =
            this.responseData.length === 0 ? true : false;
          this.Applicationpage = 1;
          this.TotalApplicationCount = _response.length;
        },
        (_error: HttpErrorResponse) => {
          this.isLoading = false;
          if (_error.status === 500) {
            return this._sweetAlertService.validationError(
              'error',
              'Error',
              _error.error.replace(
                'Error: GetOvertimeRepresentationGroupMove - Generic Exception:',
                ''
              )
            );
          } else {
            return this._sweetAlertService.validationError(
              'error',
              'Error',
              'Something Went Wrong'
            );
          }
        }
      );
  }

  getbyenter(e: any) {
    this.isLoading = true;
    let searchData;
    if (this.filterData != null) {
      searchData = { ...e, ...this.filterData };
    }
    if (
      !searchData.FIRSTNAME &&
      !searchData.EMPLOYEEID &&
      !searchData.LASTNAME
    ) {
      this.isLoading = false;
      return this._sweetAlertService.SearchFieldMissingAlert(
        'error',
        'Error',
        '<p><b>Please enter one of the Following:</b></p><p><ul><li>First name</li><li>Last name</li><li>Employee ID</li></ul></p>'
      );
    }
    this._ReportsAndChartsService
      .GetOvertimeReassignHistory(searchData)
      .subscribe((res: any) => {
        this.responseData = res;

        this.responseData.forEach((element: any) => {
          element.differenceinMilliSeconds = Math.abs(
            Number(
              new Date(formatDate(element.datentered, 'yyyy-MM-dd', 'en-US'))
            ) -
              Number(
                new Date(formatDate(element.dateleft, 'yyyy-MM-dd', 'en-US'))
              )
          );
          element.differenceBtwDates =
            element.differenceinMilliSeconds / (24 * 60 * 60 * 1000);
        });
        this.responseDataExists = this.responseData.length === 0 ? true : false;
        this.Applicationpage = 1;
        this.TotalApplicationCount = res.length;
        this.isLoading = false;
      });
  }

  @ViewChild('OvertimeReassignHistoryExportTable', {
    read: ElementRef,
    static: false,
  })
  OvertimeReassignHistoryExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.OvertimeReassignHistoryExportTable,
        `${ApplicationEnum.OVERTIMEREASSIGNHISTORY}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.responseData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.responseData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
