import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { SkilledToSkilledService } from 'src/app/Services/Applications/SkilledToSkilled/skilled-to-skilled.service';
import { DateService } from 'src/app/Services/Date/date.service';
import { RuleEngineService } from 'src/app/Services/Ruleengine/rule-engine.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-edit-skilled-to-skilled',
  templateUrl: './edit-skilled-to-skilled.component.html',
  styleUrls: ['./edit-skilled-to-skilled.component.css'],
})
export class EditSkilledToSkilledComponent implements OnInit, OnChanges {
  @Input() selectedEmp: any;
  @Input() isEdit: boolean = false;
  @Input() title: any;
  @Input() userInformation: any;
  @Output() clean = new EventEmitter();
  @Output() isSucess = new EventEmitter();
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;

  userName: string = '';
  employeeForm!: FormGroup;
  applicationSelected: any;
  usersData: any;
  statusCancelled: boolean = false;
  statusClosed: boolean = false;
  invalidStatus: boolean = false;
  classifications: any;
  currentJobClassification: any;
  currentDate = new Date();
  CalenderBlockedAfterTodayDate: string = '';
  statusCodes: string[] = [];
  medicalRestrictionList: string[] = [];

  constructor(
    private fb: FormBuilder,
    private _user: UsergroupService,
    private ss: SkilledToSkilledService,
    private re: RuleEngineService,
    private _commonApplicationService: CommonService,
    private _dateService: DateService
  ) {}

  ngOnInit(): void {
    this.getUserInfo();
    this.getClassifications();
    this.getEmpStatusCodes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.CreateEditApplicationForm();
    this.LoadApplicationData(changes);
  }

  getEmpStatusCodes() {
    this.re.getEmpStatusCode().subscribe((data) => {
      this.statusCodes = data;
    });
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
    });
  }

  CreateEditApplicationForm() {
    this.employeeForm = this.fb.group(
      {
        employeeName: [''],
        firstName: [''],
        lastName: [''],
        AppId: ['', [Validators.required]],
        ATIN: [''],
        ATID: [''],
        EmployeeId: [''],
        AppType: ['152'],
        currentClassification: [''],
        jobCodeId: [
          '',
          [
            Validators.required,
            this.CheckLeaderClassification,
            this.CheckApprenticeClassification,
          ],
        ],
        EmpSignDate: ['', [Validators.required]],
        ExpValidateFlag: [false, [Validators.requiredTrue]],
        AppStatusCode: ['', [Validators.required]],
        AppStatusDate: ['', [Validators.required]],
        LastUpdatedBy: [
          this.userInformation.userName.toString().replace('@alsn.com', ''),
        ],
      },
      {
        validator: this._commonApplicationService.dateLessThan(
          'EmpSignDate',
          'AppStatusDate'
        ),
      }
    );
    this.isEdit = false;
  }

  CheckLeaderClassification(control: FormControl) {
    let jobClassification = control.value;
    let checkChar = jobClassification.charAt(jobClassification.length - 1);
    if (checkChar === 'L') {
      return {
        leaderClassificationError:
          'The selected classification cannot be a leader classification',
      };
    }
    return null;
  }

  CheckApprenticeClassification(control: FormControl) {
    let jobClassification = control.value;
    let checkChar = jobClassification.charAt(jobClassification.length - 1);
    if (checkChar === 'A') {
      return {
        apprenticeClassificationError:
          'The selected classification cannot be a apprentice classification',
      };
    }
    return null;
  }

  // CheckEmpSignDate(control: FormControl) {
  //   let currentDate = new Date();
  //   let empSignDate = new Date(control.value);
  //   if (empSignDate >= currentDate) {
  //     return {
  //       empSignDateError:
  //         'Employee sign date cannot be greater than today date',
  //     };
  //   }
  //   return null;
  // }

  LoadApplicationData(changes: SimpleChanges) {
    this.applicationSelected =
      (changes.selectedEmp && changes.selectedEmp.currentValue) || '';
    if (this.applicationSelected === '') {
      this.applicationSelected =
        (changes.selectedEmp && changes.selectedEmp.previousValue) || '';
    }
    this.employeeForm
      .get('employeeName')
      ?.patchValue(
        this.applicationSelected.lastName +
          ', ' +
          this.applicationSelected.firstName +
          ' ' +
          this.applicationSelected.middleName
      );
    this.CalenderBlockedAfterTodayDate =
      this._commonApplicationService.formatDate(this.currentDate);

    this.employeeForm.get('AppId')?.patchValue(this.applicationSelected.appId);
    this.employeeForm
      .get('firstName')
      ?.patchValue(this.applicationSelected.firstName);
    this.employeeForm
      .get('lastName')
      ?.patchValue(this.applicationSelected.lastName);
    this.employeeForm.get('aTin')?.patchValue(this.applicationSelected.atin);
    this.employeeForm
      .get('ATID')
      ?.patchValue(
        this._commonApplicationService.stringToUpperCase(
          this.applicationSelected.atid
        )
      );
    this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.applicationSelected.employeeId);
    this.employeeForm
      .get('jobCodeId')
      ?.patchValue(this.applicationSelected.jobCodeId);
    this.employeeForm.get('ExpValidateFlag')?.patchValue(true);
    this.employeeForm
      .get('AppStatusCode')
      ?.patchValue(
        this._commonApplicationService.removeAfterChar(
          this.applicationSelected.appStatusCode
        )
      );
    this.employeeForm
      .get('EmpSignDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.applicationSelected.empSignDate
        )
      );
    this.employeeForm
      .get('AppStatusDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.applicationSelected.appStatusDate
        )
      );
    this.employeeForm.get('AppType')?.patchValue('152');
    this.getMedicalRestrictions(this.applicationSelected.employeeId);
    this.ss
      .searchFilterApplicationUser({
        employeeId: this.applicationSelected.employeeId,
      })
      .subscribe((data) => {
        this.usersData = data[0];
        this.currentJobClassification = this.usersData.jobCodeId;
        this.employeeForm
          .get('currentClassification')
          ?.patchValue(
            this.usersData.jobCodeId + '-' + this.usersData.jobCodeDescription
          );
      });
    this.statusCancelled = false;
    this.statusClosed = false;
    this.invalidStatus = false;
  }

  getMedicalRestrictions(employeeId: string) {
    this.re.getMedicalRestrictions(employeeId).subscribe((data) => {
      this.medicalRestrictionList = data;
    });
  }

  getClassifications() {
    this.ss.getClassifications().subscribe((data) => {
      this.classifications = data;
    });
  }

  clear() {
    this.clean.emit('');
    this.closeModal.nativeElement.click();
    this.LoadApplicationDataAfterReset();
  }

  updateApplication() {
    let prevStatusCode = this._commonApplicationService.removeAfterChar(
      this.applicationSelected.appStatusCode
    );
    let newStatusCode = this.employeeForm.get('AppStatusCode')?.value;
    if (
      prevStatusCode.toLowerCase() === 'cancelled' &&
      newStatusCode.toLowerCase() === 'open'
    ) {
      this.CancelToOpenError();
    } else {
      let appliedClassification = this.employeeForm.get('jobCodeId')?.value;
      // if ((this._commonApplicationService.formatDate(this.applicationSelected.empSignDate) != this._commonApplicationService.formatDate(this.employeeForm.get('EmpSignDate')?.value)) ||
      //   (this._commonApplicationService.formatDate(this.applicationSelected.appStatusDate) != this._commonApplicationService.formatDate(this.employeeForm.get('AppStatusDate')?.value))) {
      //   var dt = this.employeeForm.get('EmpSignDate')?.value;
      //   var empDt = new Date(dt);
      //   var empSignDtInUTC = this._dateService.GetUTCDate(empDt);

      //   var appDt = this.employeeForm.get('AppStatusDate')?.value;
      //   var appStsDt = new Date(appDt);
      //   var appStatusDateInUTC = this._dateService.GetUTCDate(appStsDt);

      //   this.employeeForm.patchValue({ EmpSignDate: empSignDtInUTC });
      //   this.employeeForm.patchValue({ AppStatusDate: appStatusDateInUTC });
      // }
      if (this.currentJobClassification === appliedClassification) {
        this.wrongClassificationError(); // if the employee's application job classification and current job classification is same.
      } else {
        this.ss.Edit152Application(this.employeeForm.value).subscribe(
          (_data) => {
            this.clear();
            this.successAlert();
            this.isSucess.emit(true);
          },
          (_error: HttpErrorResponse) => {
            if (_error.status === 400) {
              this.validationError();
            }
            if (_error.status === 406) {
              this.validationError();
            } else {
              this.badRequestAlert();
            }
          }
        );
      }
    }
  }

  successAlert() {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'The Application has been Updated Successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  wrongClassificationError() {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: 'Cannot apply for current classification.',
    });
  }

  validationError() {
    Swal.fire({
      title: 'Cannot modify the current application',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }

  CancelToOpenError() {
    Swal.fire({
      title: 'Cannot modify the current application',
      text: 'Cannot make CANCELLED application as OPEN.',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }

  badRequestAlert() {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: 'An Error occured while processing. Please try again later.',
    });
  }

  checkStatus(e: any) {
    const statuscode = this.employeeForm.get('AppStatusCode');

    statuscode?.valueChanges.subscribe(() => {
      statuscode?.patchValue(statuscode.value.toUpperCase(), {
        emitEvent: false,
      });
    });

    var statusEntered = e.target.value;
    if (statusEntered.toUpperCase().includes('CANCELLED')) {
      this.statusCancelled = true;
      this.statusClosed = false;
      this.invalidStatus = false;
    } else if (statusEntered.toUpperCase().includes('CLOSED')) {
      this.statusCancelled = false;
      this.statusClosed = true;
      this.invalidStatus = false;
      if (statusEntered.toUpperCase().includes('CLOSED -')) {
        //let appStatusCode = this.employeeForm.get('AppStatusCode')?.value;
        let statusCode = statusEntered.split('-')[2]?.trim();
        for (let i = 0; i < this.statusCodes.length; i++) {
          if (statusCode === this.statusCodes[i]) {
            this.statusClosed = false;
            this.statusCancelled = false;
            this.invalidStatus = false;
            break;
          } else {
            this.statusClosed = true;
          }
        }
      }
    } else if (
      !statusEntered.toUpperCase().includes('CLOSED') ||
      !statusEntered.toUpperCase().includes('OPEN') ||
      !statusEntered.toUpperCase().includes('CANCELLED')
    ) {
      this.statusCancelled = false;
      this.statusClosed = false;
      this.invalidStatus = true;
    }
    if (statusEntered.toUpperCase().includes('OPEN')) {
      this.statusClosed = false;
      this.statusCancelled = false;
      this.invalidStatus = false;
    }
  }

  LoadApplicationDataAfterReset() {
    this.employeeForm
      .get('employeeName')
      ?.patchValue(
        this.applicationSelected.firstName +
          ' ' +
          this.applicationSelected.lastName
      );
    this.employeeForm.get('AppId')?.patchValue(this.applicationSelected.appId);
    this.employeeForm
      .get('firstName')
      ?.patchValue(this.applicationSelected.firstName);
    this.employeeForm
      .get('lastName')
      ?.patchValue(this.applicationSelected.lastName);
    this.employeeForm.get('aTin')?.patchValue(this.applicationSelected.atin);
    this.employeeForm
      .get('ATID')
      ?.patchValue(
        this._commonApplicationService.stringToUpperCase(
          this.applicationSelected.atid
        )
      );
    this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.applicationSelected.employeeId);
    this.employeeForm
      .get('jobCodeId')
      ?.patchValue(this.applicationSelected.jobCodeId);
    this.employeeForm.get('ExpValidateFlag')?.patchValue(true);
    this.employeeForm
      .get('AppStatusCode')
      ?.patchValue(
        this._commonApplicationService.removeAfterChar(
          this.applicationSelected.appStatusCode
        )
      );
    this.employeeForm
      .get('EmpSignDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.applicationSelected.empSignDate
        )
      );
    this.employeeForm
      .get('AppStatusDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.applicationSelected.appStatusDate
        )
      );
    this.employeeForm.get('AppType')?.patchValue('152');

    this.ss
      .searchFilterApplicationUser({
        employeeId: this.applicationSelected.employeeId,
      })
      .subscribe((data) => {
        this.usersData = data[0];
        this.currentJobClassification = this.usersData.jobCodeId;
        this.employeeForm
          .get('currentClassification')
          ?.patchValue(
            this.usersData.jobCodeId + '-' + this.usersData.jobCodeDescription
          );
      });
    this.statusCancelled = false;
    this.statusClosed = false;
    this.invalidStatus = false;
  }
}
