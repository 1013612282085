import { HttpErrorResponse } from '@angular/common/http';
import {
  ElementRef,
  EventEmitter,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { ToolAndDiePlantService } from 'src/app/Services/Applications/ToolAndDie/tool-and-die-plant.service';
import { RuleEngineService } from 'src/app/Services/Ruleengine/rule-engine.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { UserInfo } from 'src/models/userinfo';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
})
export class EditComponent implements OnInit {
  @Input() selectedEmp: any;
  empSelected: any;
  @Input() isEdit: boolean = false;
  @Input() title: any;
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;
  employeeForm!: FormGroup;
  @Output() clean = new EventEmitter();
  @Output() isSucess = new EventEmitter();
  shifts: any[] = [
    {
      data: [],
      default: 'Select a shift',
    },
  ];
  plants: any[] = [
    {
      data: [],
      default: 'Select a plant',
    },
  ];
  deptbyplantid: any[] = [
    {
      data: [],
      default: 'Select a Department',
    },
  ];
  appType: string = '150TD';

  lastupdater: string = '';
  eledit: string = '';
  isValidDate: any;
  error: any = { isError: false, errorMessage: '' };
  statusCancelled: boolean = false;
  statusClosed: boolean = false;
  invalidStatus: boolean = false;
  maxDate: string = '';
  currentDate = new Date();
  statusCodes: string[] = [];

  userInformation!: UserInfo; //tbd

  SelectedPlantId: any;

  constructor(
    private fb: FormBuilder,
    private td: ToolAndDiePlantService,
    private _commonApplicationService: CommonService,
    private _user: UsergroupService,
    private re: RuleEngineService
  ) {}

  ngOnInit() {
    this.getShifts();
    this.getplants();
    this.getDepartments();
    this.getUserInfo();
    this.getEmpStatusCodes();
    this.td.atid.subscribe((atid) => {
      if (atid) {
        this.employeeForm.get('LastUpdatedBy')?.patchValue(atid);
      }
    });
  }

  getEmpStatusCodes() {
    this.re.getEmpStatusCode().subscribe((data) => {
      this.statusCodes = data;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createEmpForm();
    this.loadEmployeeData(changes);
  }

  loadEmployeeData(changes: SimpleChanges) {
    this.empSelected =
      (changes.selectedEmp && changes.selectedEmp.currentValue) || '';
    if (this.empSelected === '') {
      this.empSelected =
        (changes.selectedEmp && changes.selectedEmp.previousValue) || '';
    }
    this.maxDate = this._commonApplicationService.formatDate(this.currentDate);
    this.employeeForm.get('AppId')?.patchValue(this.empSelected.appId);
    this.employeeForm.get('firstName')?.patchValue(this.empSelected.firstName);
    this.employeeForm.get('lastName')?.patchValue(this.empSelected.lastName);
    this.employeeForm
      .get('employeeName')
      ?.patchValue(
        this.empSelected.lastName +
          ', ' +
          this.empSelected.firstName +
          ' ' +
          this.empSelected.middleName
      );
    this.employeeForm.get('aTin')?.patchValue(this.empSelected.atin);
    this.employeeForm
      .get('ATID')
      ?.patchValue(this.stringToUpperCase(this.empSelected.atid));
    this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.empSelected.employeeId);
    this.employeeForm
      .get('classification')
      ?.patchValue(this.empSelected.classification);
    this.employeeForm
      .get('AppStatusCode')
      ?.patchValue(this.removeAfterChar(this.empSelected.appStatusCode));
    this.employeeForm
      .get('PlantId')
      ?.patchValue(this.stringIn2Digits(this.empSelected.plantId));
    this.employeeForm.get('ShiftId')?.patchValue(this.empSelected.shiftId);
    this.employeeForm.get('DepartmentId')?.patchValue(this.empSelected.deptId);
    // this.employeeForm
    //   .get('EmpSignDate')
    //   ?.patchValue(this.removeTimeStampFromDate(this.empSelected.empSignDate));
    // this.employeeForm
    //   .get('AppStatusDate')
    //   ?.patchValue(this.empSelected.appStatusDate);

    // const format = 'yyyy-MM-dd';
    // const locale = 'en-US';
    // var dtAsOfDate = new Date;
    // this.empSelected.empSignDate = formatDate(dtAsOfDate, format, locale);

    this.employeeForm
      .get('EmpSignDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(this.empSelected.empSignDate)
      );
    this.employeeForm
      .get('AppStatusDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.empSelected.appStatusDate
        )
      );

    // this.employeeForm
    //   .get('LastUpdatedDate')
    //   ?.patchValue(this.empSelected.lastUpdateDate);

    //   this._commonApplicationService.GetDepartment(this.appType).subscribe((data) => {
    //   this.deptbyplantid = data;
    // });

    // const DeptObject = {
    //   departmentId: "00",
    //   departmentDescription: 'Any',
    // };
    // this._commonApplicationService.GetDepartment(this.appType).subscribe((res) => {
    //   this.deptbyplantid = res;
    //   this.deptbyplantid.splice(7,0);
    //   this.deptbyplantid.push(DeptObject);
    // });
    this.statusCancelled = false;
    this.statusClosed = false;
    this.invalidStatus = false;
  }

  createEmpForm() {
    this.employeeForm = this.fb.group(
      {
        firstName: [''],
        lastName: [''],
        employeeName: [''],
        AppId: ['', [Validators.required]],
        ATIN: [''],
        ATID: [''],
        EmployeeId: [''],
        classification: [''],
        AppStatusCode: ['', [Validators.required]],
        PlantId: [''],
        ShiftId: [''],
        DepartmentId: [''],
        EmpSignDate: ['', [Validators.required]],
        AppStatusDate: ['', [Validators.required]],
        //LastUpdatedDate: ['', [Validators.required]],
        LastUpdatedBy: [''],
      },
      {
        validator: this._commonApplicationService.dateLessThan(
          'EmpSignDate',
          'AppStatusDate'
        ),
      }
    );
    this.isEdit = false;
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      this.lastupdater = this.userInformation.userName;
    });
  }

  clear() {
    this.clean.emit('');
    this.closeModal.nativeElement.click();
    this.loadApplicationDataAfterReset();
  }

  loadApplicationDataAfterReset() {
    this.maxDate = this._commonApplicationService.formatDate(this.currentDate);
    this.employeeForm.get('AppId')?.patchValue(this.empSelected.appId);
    this.employeeForm.get('firstName')?.patchValue(this.empSelected.firstName);
    this.employeeForm.get('lastName')?.patchValue(this.empSelected.lastName);
    this.employeeForm.get('aTin')?.patchValue(this.empSelected.atin);
    this.employeeForm
      .get('ATID')
      ?.patchValue(this.stringToUpperCase(this.empSelected.atid));
    this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.empSelected.employeeId);
    this.employeeForm
      .get('classification')
      ?.patchValue(this.empSelected.classification);
    this.employeeForm
      .get('AppStatusCode')
      ?.patchValue(this.removeAfterChar(this.empSelected.appStatusCode));
    this.employeeForm
      .get('PlantId')
      ?.patchValue(this.stringIn2Digits(this.empSelected.plantId));
    this.employeeForm.get('ShiftId')?.patchValue(this.empSelected.shiftId);
    this.employeeForm.get('DepartmentId')?.patchValue(this.empSelected.deptId);
    this.employeeForm
      .get('EmpSignDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(this.empSelected.empSignDate)
      );
    this.employeeForm
      .get('AppStatusDate')
      ?.patchValue(
        this._commonApplicationService.formatDate(
          this.empSelected.appStatusDate
        )
      );
    this.statusCancelled = false;
    this.statusClosed = false;
    this.invalidStatus = false;
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from]; // empsigndate -- earlier
      let t = group.controls[to]; //lastupdatedate -- later
      if (new Date(f?.value) > new Date(t?.value)) {
        return {
          dates: "Status date shouldn't be earlier than Employee sign date",
        };
      }
      return {};
    };
  }
  stringIn2Digits(stringNumber: number) {
    let resultString = '';
    if (stringNumber.toString().length === 1) {
      resultString = '0' + stringNumber;
    } else {
      return stringNumber.toString();
    }
    return resultString;
  }
  removeAfterChar(value: string) {
    let resultString = '';
    if (value != null && value != '') {
      resultString = value.split('[')[0];
    }
    return resultString;
  }

  removeTimeStampFromDate(value: string) {
    let resultString = '';
    if (value != null && value != '') {
      resultString = value.split(' ')[0];
    }
    return resultString;
  }

  stringToUpperCase(value: string) {
    let resultString = '';
    if (value != null && value != '') {
      resultString = value.toUpperCase();
    }
    return resultString;
  }

  addEmployee() {
    this.td.Edit150Application(this.employeeForm.value).subscribe(
      (_data) => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 400) {
          this.validationError();
        }
        if (_error.status === 406) {
          this.validationError();
        } else {
          this.badRequestAlert();
        }
      }
    );
  }

  checkStatus(e: any) {
    const statuscode = this.employeeForm.get('AppStatusCode');

    statuscode?.valueChanges.subscribe(() => {
      statuscode?.patchValue(statuscode.value.toUpperCase(), {
        emitEvent: false,
      });
    });

    var statusEntered = e.target.value;
    if (statusEntered.toUpperCase().includes('CANCELLED')) {
      this.statusCancelled = true;
      this.statusClosed = false;
      this.invalidStatus = false;
    } else if (statusEntered.toUpperCase().includes('CLOSED')) {
      this.statusCancelled = false;
      this.statusClosed = true;
      this.invalidStatus = false;
      if (statusEntered.toUpperCase().includes('CLOSED -')) {
        //let appStatusCode = this.employeeForm.get('AppStatusCode')?.value;
        let statusCode = statusEntered.split('-')[2].trim();
        for (let i = 0; i < this.statusCodes.length; i++) {
          if (statusCode === this.statusCodes[i]) {
            this.statusCancelled = false;
            this.statusClosed = false;
            this.invalidStatus = false;
            break;
          } else {
            this.statusClosed = true;
          }
        }
      }
    } else if (
      !statusEntered.toUpperCase().includes('CLOSED') ||
      !statusEntered.toUpperCase().includes('OPEN') ||
      !statusEntered.toUpperCase().includes('CANCELLED')
    ) {
      this.statusClosed = false;
      this.invalidStatus = true;
      this.statusCancelled = false;
    }
    if (statusEntered.toUpperCase().includes('OPEN')) {
      this.statusClosed = false;
      this.invalidStatus = false;
      this.statusCancelled = false;
    }
  }

  edit() {
    if (this.empSelected) {
      this.employeeForm
        .get('firstName')
        ?.patchValue(this.empSelected.firstName);
      this.employeeForm.get('lastName')?.patchValue(this.empSelected.last_Name);
      this.employeeForm.get('atinNo')?.patchValue(this.empSelected.atin);

      this.employeeForm
        .get('atid')
        ?.patchValue(this.empSelected.atid.toUpperCase());
      this.employeeForm
        .get('EmployeeId')
        ?.patchValue(this.empSelected.employeeId);
      this.employeeForm
        .get('classification')
        ?.patchValue(this.empSelected.classification);
      this.employeeForm
        .get('appStatusCode')
        ?.patchValue(this.empSelected.appStatusCode);
      this.employeeForm.get('appPlantID')?.patchValue(this.empSelected.plantId);
      this.employeeForm.get('appShiftID')?.patchValue(this.empSelected.shiftId);
      this.employeeForm.get('appDeptID')?.patchValue(this.empSelected.deptId);
      this.employeeForm
        .get('empSignDate')
        ?.patchValue(this.empSelected.empSignDate);
      this.employeeForm
        .get('appStatusDate')
        ?.patchValue(this.empSelected.appStatusDate);
      this.employeeForm
        .get('lastUpdateDate')
        ?.patchValue(this.empSelected.lastUpdateDate);
    } else {
      alert('No Employee Selected');
    }
  }
  // getShifts() {
  //   this.td.getShiftsList().subscribe((data) => {
  //     this.shifts = data;
  //   });
  // }
  getShifts() {
    this._commonApplicationService.getShiftsList().subscribe((data: any) => {
      this.shifts[0].data = data;
    });
  }
  getplants() {
    this._commonApplicationService.GetPlants(this.appType).subscribe((data) => {
      // this.plants = data;
      this.plants[0].data = data;
    });
  }

  // getdeptListByplantId(event: any) {
  //   if (event) {
  //     this.td.getDeptbyPlantId(event.target.value).subscribe((data) => {
  //       this.deptbyplantid = data;
  //     });
  //   }
  // }

  // getDepartments()
  // {
  //   const DeptObject = {
  //     departmentId: "0000",
  //     departmentDescription: 'Any',
  //   };
  //   this._commonApplicationService.GetDepartment(this.appType).subscribe((res) => {
  //     this.deptbyplantid = res;
  //     this.deptbyplantid.splice(7,0);
  //     this.deptbyplantid.push(DeptObject);
  //   });
  // }

  getDepartments() {
    const DeptObject = {
      departmentId: '0000',
      departmentDescription: 'Any',
    };

    this._commonApplicationService
      .GetDepartment(this.appType)
      .subscribe((res) => {
        this.deptbyplantid[0].data = res;
        this.deptbyplantid[0].data.splice(7, '00');
        this.deptbyplantid[0].data.push(DeptObject);
      });
  }

  successAlert() {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'The Application has been Updated Successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }
  validationError() {
    Swal.fire({
      title: 'Cannot create application',
      text: 'User has an OPEN application and/or not eligible to create a new application',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
  badRequestAlert() {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: 'An Error occured while processing.Please try again.',
    });
  }
}
