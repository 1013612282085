import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MedicalRestrictionsService } from 'src/app/Services/Views/MedicalRestrictionsView/medical-restrictions.service';

@Component({
  selector: 'app-employee-medical-restrictions',
  templateUrl: './employee-medical-restrictions.component.html',
  styleUrls: ['./employee-medical-restrictions.component.css'],
})
export class EmployeeMedicalRestrictionsComponent implements OnChanges {
  @Input() EmployeeIdPassData: any;

  @Input() MedicalRestriction: boolean = false;
  @Input() title: any;
  @Output() clean = new EventEmitter();
  @Output() isClosed = new EventEmitter();
  @Input() AppType: string = '';
  @ViewChild('closedViewModal') closedViewModal!: ElementRef;

  //- {{employeeMedicalRestrictions[0].cuR_DEPTIDESC}}
  empSelected: any;
  employeeMedicalRestrictions: any;

  constructor(private _medicalRestrictions: MedicalRestrictionsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadEmployeeDetails(changes);
  }

  loadEmployeeDetails(changes: SimpleChanges) {
    this.empSelected =
      (changes.EmployeeIdPassData && changes.EmployeeIdPassData.currentValue) ||
      '';
    if (this.empSelected === '') {
      this.empSelected =
        (changes.EmployeeIdPassData &&
          changes.EmployeeIdPassData.previousValue) ||
        '';
    }
    this.getEmployeeMedicalRestriction({ employeeid: this.empSelected });
  }

  clear() {
    this.clean.emit('');
    this.closedViewModal.nativeElement.click();
    this.emitResponse();
  }

  emitResponse() {
    this.isClosed.emit(true);
  }

  LastUpdateBy: any;
  LastUpdateDate: any;
  getEmployeeMedicalRestriction(employeeId: any) {
    this._medicalRestrictions
      .getAlMedicalRestrictions(employeeId)
      .subscribe((data: any) => {
        this.employeeMedicalRestrictions = data;
        this.LastUpdateBy = data[0].lastupdateby;
        this.LastUpdateDate = data[0].lastupdatedate;
      });
  }
}
