import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class RepresentationGroupDataService {

  commonUtility = new CommonUtility();
  private readonly api_getClassifications = '/GetAllClassification';
  private readonly api_getDepartments = '/GetAllDepartments';
  private readonly api_addRepGroup = '/RepresentationGroupAdd'
  private readonly api_deleteRepGroup = '/RepresentationGroupDelete?representationGroupId='
  private readonly api_editRepGroup = '/RepresentationGroupUpdate'
  private readonly api_searchRepGroup = '/RepresentationGroupSearch'
  private readonly api_getZones = '/GetZone';
  private readonly api_getDistrict = '/GetDistrict';
  private readonly api_getShifts = '/GetShifts';

  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  searchRepGroupData(searchData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_searchRepGroup,
      searchData,
      { headers }
    );
  }

  getClassifications(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getClassifications,
      { headers }
    );
  }

  getDepartments(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getDepartments,
      { headers }
    );
  }

  getDistricts(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getDistrict,
      { headers }
    );
  }

  getZones(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getZones,
      { headers }
    );
  }

  editRepGroupData(repGroupData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.tableMaintenanceURL}` + this.api_editRepGroup,
      repGroupData,
      { headers }
    );
  }

  addRepGroupData(classificationData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_addRepGroup,
      classificationData,
      { headers }
    );
  }

  deleteRepGroupData(representationGroupId: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.delete<any>(
      `${environment.tableMaintenanceURL}` + this.api_deleteRepGroup + representationGroupId,
      { headers }
    );
  }

  getShifts(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getShifts,
      { headers }
    );
  }
}
