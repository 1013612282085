import { HttpErrorResponse } from '@angular/common/http';
import { ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NIOGroupsDataService } from 'src/app/Services/TableMaintenance/NIOGroupsData/niogroups-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-niodata',
  templateUrl: './add-niodata.component.html',
  styleUrls: ['./add-niodata.component.css'],
})
export class AddNIODataComponent implements OnInit {
  @ViewChild('closeAddModel') closeAddModal!: ElementRef;
  nioForm!: FormGroup;
  @Input() userName: any;
  @Output() isSucess = new EventEmitter();
  Status: any[] = [
    {
      data: [
        {
          statusType: 'A',
          statusName: 'Active',
        },
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
      ],
      default: 'Select One',
    },
  ];
  constructor(private fb: FormBuilder, private nds: NIOGroupsDataService) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.nioForm = this.fb.group({
      NIO: ['', [Validators.required]],
      nioGroupDescription: ['', Validators.required],
      status: ['', [Validators.required]],
      lastUpdateBy: [this.userName],
    });
  }

  clear() {
    this.closeAddModal.nativeElement.click();
    this.createForm();
  }

  submitNIO() {
    //console.log(this.nioForm.value);
    this.nds.addNIOData(this.nioForm.value).subscribe(
      () => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 500) {
          this.badRequestAlert(_error.error);
        } else {
          this.validationError(_error.error);
        }
      }
    );
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'NIO Groups has been added successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
