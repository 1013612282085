import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class NIOGroupsDataService {
  commonUtility = new CommonUtility();
  private readonly api_addNIOData = '/NIOGroupsDataAdd'
  private readonly api_deleteNIOData = '/NIOGroupsDataDelete?nioId='
  private readonly api_editNIOData = '/NIOGroupsDataUpdate'
  private readonly api_getNIOData = '/NIOGroupsDataGetNIO'
  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  addNIOData(nioData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_addNIOData,
      nioData,
      { headers }
    );
  }

  editNIOData(nioData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.tableMaintenanceURL}` + this.api_editNIOData,
      nioData,
      { headers }
    );
  }

  deleteNIOData(nioId: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.delete<any>(
      `${environment.tableMaintenanceURL}` + this.api_deleteNIOData + nioId,
      { headers }
    );
  }

  getNIOData(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getNIOData,
      { headers }
    );
  }
}
