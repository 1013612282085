import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ShiftDataService } from 'src/app/Services/TableMaintenance/ShiftData/shift-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-delete-shift-data',
  templateUrl: './delete-shift-data.component.html',
  styleUrls: ['./delete-shift-data.component.css'],
})
export class DeleteShiftDataComponent {
  @Input() selectedShift: any;
  shiftid: string = '';
  @Output() isSucess = new EventEmitter();
  constructor(private shiftDataService: ShiftDataService) {}

  clear() {
    //TODO
  }
  confirmDelete() {
    if (this.selectedShift) {
      this.shiftid = this.selectedShift.shiftid;
      this.shiftDataService.deleteShiftData(this.shiftid).subscribe(
        (res) => {
          this.successAlert();
          this.isSucess.emit(true);
        },
        (_error: HttpErrorResponse) => {
          if (_error.status === 500) {
            this.badRequestAlert(_error.error);
          } else {
            this.badRequestAlert(_error.error);
          }
        }
      );
    }
  }
  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'warning',
      title: `${errorMessage}`,
    });
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Record previously selected has been removed successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }
}
