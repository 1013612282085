import { Component, Input, OnInit, SimpleChanges, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { OverTimeMaintanceService } from 'src/app/Services/Applications/OvertimeMaintanence/overtime.maintanence.service';
import { MedicalRestrictionsService } from 'src/app/Services/Views/MedicalRestrictionsView/medical-restrictions.service';

@Component({
  selector: 'app-show-medical-restrictions',
  templateUrl: './show-medical-restrictions.component.html',
  styleUrls: ['./show-medical-restrictions.component.css']
})

export class ShowMedicalRestrictionsComponent implements OnInit {

  constructor(private _overtimeMaintenanceService: OverTimeMaintanceService,private _medicalRestrictions: MedicalRestrictionsService) { }
  @Input() ModalName: any;
  @Input() medicalRestrictionEmployeeId: any;
  @Input() medicalRestrictionEmpName: any;
  @Input() employeePhoneNumber: any;
  @Output() clean = new EventEmitter();
  @Output() isSucess = new EventEmitter();
  @ViewChild('closeUpdateModal') closeUpdateModal!: ElementRef;
  medicalRestrictionList: any = [];

  ngOnInit(): void {
    this.getMedicalRestrictions(this.medicalRestrictionEmployeeId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getMedicalRestrictions(this.medicalRestrictionEmployeeId);
  }

  clear() {
    this.clean.emit('');
    this.closeUpdateModal.nativeElement.click();
  }

  getMedicalRestrictions(medicalRestrictionEmployeeId: string) {
    this._medicalRestrictions.getAlMedicalRestrictions({employeeid: medicalRestrictionEmployeeId}).subscribe((data) => {
      this.medicalRestrictionList = data; 
    })
  }
}
