<div
  class="modal fade"
  id="PreviewScreenModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="PreviewScreenModalLabel"
  aria-hidden="true"
  data-backdrop="false"
>
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
    style="max-width: 80%"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="PreviewScreenModalLabel">
          Preview/Edit Overtime
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>
      <div style="font-weight: 700; text-align: center">
        Overtime Group Name: {{ overtimeGroupName }}
      </div>
      <!-- filer -->
      <div>
        <div>
          <div class="modal-body">
            <h6 class="alert alert-danger" *ngIf="DateCheck">
              You must enter a overtime date that is the same or after the date
              offered.
            </h6>
            <h6 class="alert alert-danger" *ngIf="PaidHoursCheck">
              You must enter the paid hours.
            </h6>
            <h6 class="alert alert-danger" *ngIf="OvertimeTypeIdCheck">
              You must select overtime type.
            </h6>
            <h6 class="alert alert-danger" *ngIf="OvertimeReasonIdCheck">
              You must select overtime reason.
            </h6>
            <h6 class="alert alert-danger" *ngIf="OvertimeCommentCheck">
              You must Enter comment.
            </h6>
            <h6 class="alert alert-danger" *ngIf="DuplicateRecordsFound">
              Duplicate Records found.
            </h6>
            <h6 class="alert alert-danger" *ngIf="isArrayLengthZero">
              Please select values in previous screen
            </h6>
            <h6
              class="alert alert-danger"
              *ngIf="OvertimeDate1MonthEarlierLaterCheck"
            >
              <span
                class="text-danger text-highlight"
                *ngIf="!OverrideIfDateOlderEarlier1Month"
                >*</span
              >
              You have entered a date that is earlier than a month ago or later
              than a month from now. If this was not an error please override.
              <input
                (change)="onChange1MonthDateOverride()"
                type="checkbox"
                name="OvertimeOverride"
              />
            </h6>
            <h6
              class="alert alert-danger"
              *ngIf="OvertimeTypeRequiesValidations"
            >
              <span
                class="text-danger text-highlight"
                *ngIf="!OverrideIfDateSelectedIsWeekedForOvertimeType"
                >*</span
              >
              You have chosen Overtime Type that needs Overtime Date as weekend
              or Holiday but the overtime date is neither a weekend nor a
              holiday. If this is not an error please override.
              <input
                type="checkbox"
                (change)="onChangeWeekendDateForOTOverride()"
                name="OvertimeOverride"
              />
            </h6>
            <h6
              class="alert alert-danger"
              *ngIf="OverrideOTisCallinRefuseCheck"
            >
              <span
                class="text-danger text-highlight"
                *ngIf="!OverrideOTisCallinRefuse"
                >*</span
              >
              You have chosen 'Callin-Refused' and the paid hours is not 0. If
              this is not an error please override.
              <input
                type="checkbox"
                (change)="onChangeIfOTRisCallinRefused()"
                name="OvertimeOverride"
              />
            </h6>
            <h6 class="alert alert-danger" *ngIf="OverrideCheckIfOTisLRCheck">
              <span
                class="text-danger text-highlight"
                *ngIf="!OverrideCheckIfOTisLR"
                >*</span
              >
              You have chosen 'Loan Refused'. If this is not an error please
              override.
              <input
                type="checkbox"
                (change)="onChangIfOTisLR()"
                name="OvertimeOverride"
              />
            </h6>

            <table class="overtime-preview-table table table-striped">
              <thead class="table-header">
                <tr class="overtime-preview-table-tr">
                  <th class="overtime-preview-table-th">No.</th>
                  <th class="overtime-preview-table-th">
                    <input type="checkbox" (change)="selectAllEmployee()" />
                  </th>
                  <th class="overtime-preview-table-th">Emp Id</th>
                  <th class="overtime-preview-table-th">Name</th>
                  <th class="overtime-preview-table-th">Overtime Date</th>
                  <th class="overtime-preview-table-th">Paid Hours</th>
                  <th class="overtime-preview-table-th">Overtime Type</th>
                  <th class="overtime-preview-table-th">Overtime Reason</th>
                  <th class="overtime-preview-table-th">Comment</th>
                  <th class="overtime-preview-table-th">Date Offered</th>
                  <th class="overtime-preview-table-th"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let bin of MassAddOvertimeRecords; let i = index">
                  <td
                    [ngClass]="
                      bin.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                    "
                    class="overtime-preview-table-td"
                  >
                    &nbsp;{{ i + 1 }}
                  </td>
                  <td
                    [ngClass]="
                      bin.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                    "
                    class="overtime-preview-table-td"
                  >
                    <input
                      type="checkbox"
                      (change)="onEmployeeSelect(i)"
                      [(ngModel)]="bin.isselected"
                      name="OvertimeAdd"
                    />
                  </td>
                  <td
                    [ngClass]="
                      bin.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                    "
                    class="overtime-preview-table-td"
                  >
                    <input
                      readonly
                      type="text"
                      [(ngModel)]="bin.employeeid"
                      class="form-control"
                      style="width: 5rem !important"
                    />
                  </td>
                  <td
                    [ngClass]="
                      bin.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                    "
                    rowspan="bin.childCount+1"
                    class="overtime-preview-table-td"
                  >
                    <input
                      *ngIf="bin.isCopied === false"
                      readonly
                      style="width: 12rem !important"
                      type="text"
                      [(ngModel)]="bin.name"
                      class="form-control"
                    />
                  </td>

                  <td
                    [ngClass]="
                      bin.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                    "
                    class="overtime-preview-table-td"
                  >
                    <input
                      type="date"
                      [(ngModel)]="bin.dateofwork"
                      [ngClass]="
                        bin.DowIsGreaterThanDo
                          ? 'isErrorEffected'
                          : 'isNotErrorEffected'
                      "
                      [ngClass]="
                        bin.overtimeRequireValidation
                          ? 'isErrorEffected'
                          : 'isNotErrorEffected'
                      "
                      [ngClass]="
                        bin.DateEarlierOrOlderFlag
                          ? 'isErrorEffected'
                          : 'isNotErrorEffected'
                      "
                      [ngClass]="
                        bin.dateofworkflag ? 'cellChanged' : 'cellNoChanged'
                      "
                      (change)="onChangeDateOfWork(i, $event)"
                      class="form-control"
                    />
                  </td>

                  <td
                    [ngClass]="
                      bin.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                    "
                    class="overtime-preview-table-td"
                  >
                    <input
                      style="width: 5rem !important"
                      type="number"
                      step="0.25"
                      pattern="(^[0-9]{0,2}$)|(^[0-9]{0,2}\.[0-9]{0,5}$)"
                      [ngClass]="
                        bin.paidHoursMissing
                          ? 'isErrorEffected'
                          : 'isNotErrorEffected'
                      "
                      [ngClass]="
                        bin.paidhoursflag ? 'cellChanged' : 'cellNoChanged'
                      "
                      (keyup)="onChangePaidHours(i, $event)"
                      (change)="onChangePaidHours(i, $event)"
                      [(ngModel)]="bin.paidhours"
                      class="form-control"
                    />
                  </td>
                  <td
                    [ngClass]="
                      bin.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                    "
                    class="overtime-preview-table-td"
                  >
                    <select
                      (change)="onChangeOvertimeType(i, $event)"
                      [ngClass]="
                        bin.overtimeTypeMissing
                          ? 'isErrorEffected'
                          : 'isNotErrorEffected'
                      "
                      [ngClass]="
                        bin.overtimeRequireValidation
                          ? 'isErrorEffected'
                          : 'isNotErrorEffected'
                      "
                      [ngClass]="
                        bin.overtimetypeidflag ? 'cellChanged' : 'cellNoChanged'
                      "
                      [(ngModel)]="bin.overtimetypeid"
                      type="text"
                      class="form-control"
                    >
                      <option
                        *ngFor="let overtime of OvertimeType"
                        value="{{ overtime.overtimetypeid }}"
                      >
                        {{ overtime.overtimetypeid }}-{{
                          overtime.overtimetypedescription
                        }}
                      </option>
                    </select>
                  </td>
                  <td
                    [ngClass]="
                      bin.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                    "
                    class="overtime-preview-table-td"
                  >
                    <select
                      (change)="onChangeOvertimeReason(i, $event)"
                      [ngClass]="
                        bin.overtimeReasonMissing
                          ? 'isErrorEffected'
                          : 'isNotErrorEffected'
                      "
                      [ngClass]="
                        bin.overtimereasonidflag
                          ? 'cellChanged'
                          : 'cellNoChanged'
                      "
                      [(ngModel)]="bin.overtimereasonid"
                      class="form-control"
                    >
                      <option
                        disabled
                        *ngIf="OvertimeReason[0].default"
                        selected
                        value=""
                      >
                        {{ OvertimeReason[0].default }}
                      </option>
                      <option
                        *ngFor="
                          let overtime of bin
                            .OvertimeReasonArrayBasedOnOvertimeType[0]
                        "
                        value="{{ overtime.overtimereasonid }}"
                      >
                        {{ overtime.overtimereasondescription }}
                      </option>
                    </select>
                  </td>
                  <td
                    [ngClass]="
                      bin.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                    "
                    class="overtime-preview-table-td"
                  >
                    <input
                      type="email"
                      [ngClass]="
                        bin.isCommentRequired
                          ? 'isErrorEffected'
                          : 'isNotErrorEffected'
                      "
                      [ngClass]="
                        bin.freeformcommentflag
                          ? 'cellChanged'
                          : 'cellNoChanged'
                      "
                      [(ngModel)]="bin.freeformcomment"
                      (keyup)="onChangeOvertimeComment(i, $event)"
                      (change)="onChangeOvertimeComment(i, $event)"
                      class="form-control"
                    />
                  </td>

                  <td
                    [ngClass]="
                      bin.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                    "
                    class="overtime-preview-table-td"
                  >
                    <input
                      type="date"
                      [ngClass]="
                        bin.DowIsGreaterThanDo
                          ? 'isErrorEffected'
                          : 'isNotErrorEffected'
                      "
                      [ngClass]="
                        bin.DateEarlierOrOlderFlag
                          ? 'isErrorEffected'
                          : 'isNotErrorEffected'
                      "
                      [ngClass]="
                        bin.dateofferedflag ? 'cellChanged' : 'cellNoChanged'
                      "
                      [(ngModel)]="bin.dateoffered"
                      (change)="onChangeOvertimeDateOffered(i, $event)"
                      class="form-control"
                    />
                  </td>
                  <td
                    [ngClass]="
                      bin.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                    "
                    style="width: 3.5rem !important"
                    class="overtime-preview-table-td"
                  >
                    <div class="mb-2">
                      <a
                        *ngIf="bin.isCopied === false"
                        class="link"
                        (click)="AddAdditionalOvertime(i)"
                        ><img src="../../../../../assets/add.png"
                      /></a>
                      &nbsp;<a
                        *ngIf="bin.childCount === 0"
                        class="link"
                        (click)="RemoveAdditionalOvertime(i)"
                        ><img src="../../../../../assets/delete.png"
                      /></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-group col-md-12">
            <div class="btn-wrapper">
              <button
                style="height: 40px; width: 150px; border-radius: 10px"
                type="button"
                class="btn btn-secondary cancel-btn mr-3"
                #closeCorrectModel
                (click)="clear()"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                style="height: 40px; width: 150px; border-radius: 10px"
                type="button"
                [appDebounce]="4000"
                class="btn btn-primary save-btn"
                [disabled]="isArrayLengthZero"
                (click)="AddMassOvertimeRecord()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
