import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  ElementRef,
  EventEmitter,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { OverTimeMaintanceService } from 'src/app/Services/Applications/OvertimeMaintanence/overtime.maintanence.service';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { DatePipe, formatDate } from '@angular/common';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { UserRoles } from 'src/enum/userroles';
import { DateService } from 'src/app/Services/Date/date.service';

@Component({
  selector: 'app-mass-update-overtime-maintenance',
  templateUrl: './mass-update-overtime-maintenance.component.html',
  styleUrls: ['./mass-update-overtime-maintenance.component.css'],
})
export class MassUpdateOvertimeMaintenanceComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private _overtimeMaintenanceService: OverTimeMaintanceService,
    private _commonApplicationService: CommonService,
    public _userInfo: UsergroupService,
    private _commonService: CommonService,
    private _sweetAlertService: SweetAlertService,
    private _dateService: DateService
  ) {
    this.getUserRoleDescreption();
  }

  employeeForm!: FormGroup;
  checkAllRecords: boolean = false; //tbd
  listATID: string = '';
  searchDateOfWork: any;
  searchToDateOfWork: any;
  userRole: UserRoles = UserRoles.SKILLEDUSER; // Enum for userrole

  @Input() isUpdate: boolean = false;
  @Input() isSingleRecord: boolean = false;
  @Input() overtimeGroupName: any;
  @Input() title: any;
  @Input() passUpdateData: any[] = [];
  @Input() modifiedOvertimRecords: any[] = [];
  @Output() clean = new EventEmitter();
  @Output() isSucess = new EventEmitter();
  @ViewChild('closeUpdateModal') closeUpdateModal!: ElementRef;
  OvertimeType: any;

  OvertimeReason: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];

  /*Checks validations*/
  DateCheck: boolean = false;
  PaidHoursCheck: boolean = false;
  OvertimeTypeIdCheck: boolean = false;
  OvertimeReasonIdCheck: boolean = false;
  OvertimeCommentCheck: boolean = false;

  DuplicateRecordsFound: boolean = false;

  MultipleRecordSelected: boolean = false;
  IsFromDateEarlierToDate: boolean = false;
  /*Override Flags*/
  OvertimeDate1MonthEarlierLaterCheck: boolean = false;
  OvertimeTypeRequiesValidations: boolean = false;
  OverrideCheckIfOTisLRCheck: boolean = false;
  OverrideOTisCallinRefuseCheck: boolean = false;

  /*Override Check*/
  OverrideIfDateOlderEarlier1Month: boolean = false;
  OverrideIfDateSelectedIsWeekedForOvertimeType: boolean = false;
  OverrideCheckIfOTisLR: boolean = false;
  OverrideOTisCallinRefuse: boolean = false;

  ngOnInit(): void {
    this.getOvertimeType();
    this.getOvertimeReason();
    this.getHolidayList();
    const format = 'yyyy-MM-dd';
    const locale = 'en-US';
    var dtTodayDate = new Date();
    this.searchDateOfWork = formatDate(dtTodayDate, format, locale);
    this.searchToDateOfWork = formatDate(dtTodayDate, format, locale);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.DateCheck = false;
    this.MultipleRecordSelected = false;
    this.IsFromDateEarlierToDate = false;
    this.PaidHoursCheck = false;
    this.OvertimeTypeIdCheck = false;
    this.OvertimeReasonIdCheck = false;
    this.OvertimeCommentCheck = false;
    this.OvertimeDate1MonthEarlierLaterCheck = false;
    this.OvertimeTypeRequiesValidations = false;
    this.DuplicateRecordsFound = false;
    this.OverrideCheckIfOTisLRCheck = false;
    this.OverrideOTisCallinRefuseCheck = false;

    this.OverrideIfDateOlderEarlier1Month = false;
    this.OverrideIfDateSelectedIsWeekedForOvertimeType = false;
    this.OverrideCheckIfOTisLR = false;
    this.OverrideOTisCallinRefuse = false;

    this.ismodifiedOvertimRecordsExists = true;

    //this.getOvertimeType();
    //this.getOvertimeReason();
    if (this.isSingleRecord) {
      //console.log('pd', this.passUpdateData);
      this.GetOvertimeData();
    }

    this.passUpdateData.length > 1
      ? (this.MultipleRecordSelected = true)
      : (this.MultipleRecordSelected = false);
  }

  loggedInUserATID: string = '';

  getUserRoleDescreption() {
    this._userInfo.getUserInfo().subscribe((data: any) => {
      this.userRole =
        data['groupDescription'] === UserRoles.HRPLUS
          ? UserRoles.HRPLUS
          : UserRoles.SKILLEDUSER;
      this.loggedInUserATID = data.userName;
    });
  }

  ismodifiedOvertimRecordsExists: boolean = true;
  GetOvertimeData() {
    this.IsFromDateEarlierToDate = false;
    this.checkAllRecords = false;
    var overtimeGroupId = 0;
    this.listATID === '';
    this.modifiedOvertimRecords.length = 0;
    var listATID = '';
    var recordId = 0;
    for (var j = 0; j < this.passUpdateData.length; j++) {
      overtimeGroupId = this.passUpdateData[j].overtimegroupid;
      if (listATID === '') {
        listATID = this.passUpdateData[j].employeeid;
      } else {
        listATID = listATID + ',' + this.passUpdateData[j].employeeid;
      }
      recordId = this.passUpdateData[j].recordid;
    }

    var searchtype = '';
    if (recordId != null && recordId != undefined) {
      searchtype = 'NORMAL';
      listATID = '';
    } else {
      searchtype = 'MassUpdate';
    }
    var datePipe = new DatePipe('en-US');
    var dateOfWork: any = datePipe.transform(
      this.searchDateOfWork,
      'MM/dd/yyyy'
    );
    var todateOfWork: any = this.MultipleRecordSelected
      ? datePipe.transform(this.searchDateOfWork, 'MM/dd/yyyy')
      : datePipe.transform(this.searchToDateOfWork, 'MM/dd/yyyy');

    if (
      !this.MultipleRecordSelected &&
      new Date(dateOfWork).getTime() > new Date(todateOfWork).getTime()
    ) {
      this.IsFromDateEarlierToDate = true;
    }

    var paramUpdateData = {
      searchtype: searchtype,
      dateofworkfrom: dateOfWork,
      dateofworkto: todateOfWork,
      atidlist: listATID,
      recordid: recordId,
      overtimegroupid: overtimeGroupId,
    };

    this._overtimeMaintenanceService
      .GetOvertimeRecordsForUpdate(paramUpdateData)
      .subscribe((data: any) => {
        this.modifiedOvertimRecords = data;
        this.ismodifiedOvertimRecordsExists =
          this.modifiedOvertimRecords.length > 0 ? true : false;
        //console.log(this.ismodifiedOvertimRecordsExists);
        for (var j = 0; j < this.modifiedOvertimRecords.length; j++) {
          this.modifiedOvertimRecords[j].dateofwork =
            this._commonApplicationService.formatDate(
              new Date(this.modifiedOvertimRecords[j].dateofwork)
            );
          this.modifiedOvertimRecords[j].dateoffered =
            this._commonApplicationService.formatDate(
              new Date(this.modifiedOvertimRecords[j].dateoffered)
            );
          this.modifiedOvertimRecords[
            j
          ].OvertimeReasonArrayBasedOnOvertimeType = this.onChangeGetOvertimeType(
            this.modifiedOvertimRecords[j].overtimetypeid
          );
          this.modifiedOvertimRecords[j].dateofworkflag = false;
          this.modifiedOvertimRecords[j].dateofferedflag = false;
          this.modifiedOvertimRecords[j].overtimegroupidflag = false;
          this.modifiedOvertimRecords[j].overtimereasonidflag = false;
          this.modifiedOvertimRecords[j].paidhoursflag = false;
          this.modifiedOvertimRecords[j].freeformcommentflag = false;
          this.modifiedOvertimRecords[j].isCommentRequired = false;
          this.modifiedOvertimRecords[j].overtimeRequireValidation = false;
          this.modifiedOvertimRecords[j].Override = false;
          this.modifiedOvertimRecords[j].paidHoursMissing = false;
          this.modifiedOvertimRecords[j].overtimeTypeMissing = false;
          this.modifiedOvertimRecords[j].overtimeReasonMissing = false;
          this.modifiedOvertimRecords[j].DowIsGreaterThanDo = false;
          this.modifiedOvertimRecords[j].DateEarlierOrOlderFlag = false;
          this.modifiedOvertimRecords[j].callinRefusedFalg = false;
          this.modifiedOvertimRecords[j].statusFalg = false;
          if (this.modifiedOvertimRecords[j].status === 'A') {
            this.modifiedOvertimRecords[j].boolStatus = true;
          } else {
            this.modifiedOvertimRecords[j].boolStatus = false;
          }
        }
        this.originalOvertimRecords = JSON.parse(
          JSON.stringify(this.modifiedOvertimRecords)
        );
        //console.log('mr', this.modifiedOvertimRecords);
      });
  }

  originalOvertimRecords: any = [];
  getOvertimeType() {
    this._overtimeMaintenanceService
      .getOvertimeType()
      .subscribe((overtimeType: any) => {
        this.OvertimeType = overtimeType;
        //console.log('OT type Called');
      });
  }

  isCommentRequiredArray: any[] = [];
  getOvertimeReason() {
    this._overtimeMaintenanceService
      .getOvertimeReason()
      .subscribe((OvertimeReason: any) => {
        this.OvertimeReason[0].data = OvertimeReason;
        for (var i = 0; i < OvertimeReason.length; i++) {
          if (OvertimeReason[i].commentrequired === '1') {
            this.isCommentRequiredArray.push(OvertimeReason[i]);
          }
        }
      });
  }

  /* Holidays */
  HolidayListArray: any = [];
  getHolidayList() {
    this._overtimeMaintenanceService
      .GetHolidaysList()
      .subscribe((holidayList: any) => {
        for (var i = 0; i < holidayList.length; i++) {
          var HolidayID = holidayList[i].holidayid;
          var HolidayDate = this._commonService.formatDate(
            new Date(holidayList[i].holiday)
          );
          var HolidayDescription = holidayList[i].description;

          var objHoliday = {
            holidayId: HolidayID,
            holidayDate: HolidayDate,
            HolidayDescription: HolidayDescription,
          };
          this.HolidayListArray.push(objHoliday);
        }
      });
  }

  /*check box functions*/
  selectAllEmployee() {
    for (var i = 0; i < this.modifiedOvertimRecords.length; i++) {
      if (this.checkAllRecords === true) {
        this.modifiedOvertimRecords[i].isselected = this.checkAllRecords;
      } else {
        this.modifiedOvertimRecords[i].isselected = this.checkAllRecords;
      }
    }
  }

  onEmployeeSelect(idx: number) {
    this.modifiedOvertimRecords[idx].isSelected =
      !this.modifiedOvertimRecords[idx].isSelected;
  }

  /* on change override flags */
  /*New Functions created to change the override flag value*/
  onChange1MonthDateOverride() {
    this.OverrideIfDateOlderEarlier1Month =
      !this.OverrideIfDateOlderEarlier1Month;
  }

  onChangeWeekendDateForOTOverride() {
    this.OverrideIfDateSelectedIsWeekedForOvertimeType =
      !this.OverrideIfDateSelectedIsWeekedForOvertimeType;
  }

  onChangIfOTisLR() {
    this.OverrideCheckIfOTisLR = !this.OverrideCheckIfOTisLR;
  }

  onChangeIfOTRisCallinRefused() {
    this.OverrideOTisCallinRefuse = !this.OverrideOTisCallinRefuse;
  }

  /*Cascading Dropdown*/
  onChangeGetOvertimeType(value: string) {
    var newOvertimeReasonArray: any = [];
    var tempOvertimeTypeStore: any;
    var tempstoreVar: any;
    newOvertimeReasonArray.length = 0;
    tempOvertimeTypeStore = this.OvertimeType.filter(
      (selectedType: any) => selectedType['overtimetypeid'] === value
    );

    newOvertimeReasonArray.push(
      this.OvertimeReason[0].data.filter(
        (selectedReason: any) =>
          selectedReason['typeassociation'] ===
          tempOvertimeTypeStore[0].reasonassociation
      )
    );

    if (newOvertimeReasonArray.length > 0) {
      tempstoreVar = newOvertimeReasonArray[0].overtimereasonid;
    } else {
      tempstoreVar = '';
    }

    return newOvertimeReasonArray;
  }

  /*Validation Checks*/
  /*Functions will check the validations required for Mass Add*/
  validationsCheckOvertimeType() {
    this.OvertimeTypeIdCheck = false;
    for (var j = 0; j < this.modifiedOvertimRecords.length; j++) {
      this.modifiedOvertimRecords[j].overtimeTypeMissing = false;
      if (
        this.modifiedOvertimRecords[j].overtimetypeid === '' ||
        this.modifiedOvertimRecords[j].overtimetypeid === null ||
        this.modifiedOvertimRecords[j].overtimetypeid === '0'
      ) {
        this.OvertimeTypeIdCheck = true;
        this.modifiedOvertimRecords[j].overtimeTypeMissing = true;
      }
    }
  }

  validationsCheckPaidHours() {
    this.PaidHoursCheck = false;
    for (var j = 0; j < this.modifiedOvertimRecords.length; j++) {
      this.modifiedOvertimRecords[j].paidHoursMissing = false;
      if (
        parseFloat(this.modifiedOvertimRecords[j].paidhours) === NaN ||
        parseFloat(this.modifiedOvertimRecords[j].paidhours) === null ||
        this.modifiedOvertimRecords[j].paidhours === ''
      ) {
        this.PaidHoursCheck = true;
        this.modifiedOvertimRecords[j].paidHoursMissing = true;
      }
    }
  }

  validationsCheckOvertimeReason() {
    this.OvertimeReasonIdCheck = false;
    for (var j = 0; j < this.modifiedOvertimRecords.length; j++) {
      this.modifiedOvertimRecords[j].overtimeReasonMissing = false;
      if (
        this.modifiedOvertimRecords[j].overtimereasonid === '' ||
        this.modifiedOvertimRecords[j].overtimereasonid === null ||
        this.modifiedOvertimRecords[j].overtimereasonid === ''
      ) {
        this.OvertimeReasonIdCheck = true;
        this.modifiedOvertimRecords[j].overtimeReasonMissing = true;
      }
    }
  }

  validationsCheckDate() {
    this.DateCheck = false;
    for (var j = 0; j < this.modifiedOvertimRecords.length; j++) {
      this.modifiedOvertimRecords[j].DowIsGreaterThanDo = false;
      if (
        this.modifiedOvertimRecords[j].dateofwork <
        this.modifiedOvertimRecords[j].dateoffered
      ) {
        this.DateCheck = true;
        this.modifiedOvertimRecords[j].DowIsGreaterThanDo = true;
      }
    }
  }

  validationsCheckComment() {
    this.OvertimeCommentCheck = false;
    for (var j = 0; j < this.modifiedOvertimRecords.length; j++) {
      this.modifiedOvertimRecords[j].isCommentRequired = false;
      for (var i = 0; i < this.isCommentRequiredArray.length; i++) {
        if (
          this.modifiedOvertimRecords[j].overtimereasonid.toString() ===
          this.isCommentRequiredArray[i].overtimereasonid
        ) {
          if (
            this.modifiedOvertimRecords[j].freeformcomment === '' ||
            this.modifiedOvertimRecords[j].freeformcomment === null
          ) {
            this.OvertimeCommentCheck = true;
            this.modifiedOvertimRecords[j].isCommentRequired = true;
          }
        }
      }
    }
  }

  validationCheckEarlierOlderDate() {
    this.OvertimeDate1MonthEarlierLaterCheck = false;
    var Date1MonthFromToday = this._commonService.getDateFromToday(1);
    var Date1MonthEarlierToday = this._commonService.getDateEarlierFromToday(1);
    for (var j = 0; j < this.modifiedOvertimRecords.length; j++) {
      this.modifiedOvertimRecords[j].DateEarlierOrOlderFlag = false;
      if (
        new Date(this.modifiedOvertimRecords[j].dateofwork) >
          new Date(Date1MonthFromToday) ||
        new Date(this.modifiedOvertimRecords[j].dateofwork) <
          new Date(Date1MonthEarlierToday) ||
        new Date(this.modifiedOvertimRecords[j].dateoffered) >
          (new Date(Date1MonthFromToday) ||
            new Date(this.modifiedOvertimRecords[j].dateoffered) <
              new Date(Date1MonthEarlierToday))
      ) {
        this.OvertimeDate1MonthEarlierLaterCheck = true;
        this.modifiedOvertimRecords[j].DateEarlierOrOlderFlag = true;
      }
    }
  }

  OvertimeTypeRequiredValidation = ['CA', 'CR', 'LCA', 'NSC', 'CAB', 'CD'];
  ErrorOvertimeTypes: any[] = [];
  ErrorListOvertimeType: string = '';
  validationsIsDateHolidayReason() {
    this.OvertimeTypeRequiesValidations = false;
    var check: boolean = false;
    this.ErrorOvertimeTypes.length = 0;
    this.ErrorListOvertimeType = '';
    for (var i = 0; i < this.modifiedOvertimRecords.length; i++) {
      this.modifiedOvertimRecords[i].overtimeRequireValidation = false;
      for (var j = 0; j < this.OvertimeTypeRequiredValidation.length; j++) {
        if (
          this.modifiedOvertimRecords[i].overtimetypeid ===
          this.OvertimeTypeRequiredValidation[j]
        ) {
          if (
            !this._dateService.checkIfDateIsWeekend(
              this.modifiedOvertimRecords[i].dateofwork
            )
          ) {
            this.OvertimeTypeRequiesValidations = true;
            this.modifiedOvertimRecords[i].overtimeRequireValidation = true;
          }
          if (
            !this._dateService.checkIfDateIsAHoliday(
              this.modifiedOvertimRecords[i].dateofwork,
              this.HolidayListArray
            )
          ) {
            this.OvertimeTypeRequiesValidations = true;
            this.modifiedOvertimRecords[i].overtimeRequireValidation = true;
          }
          if (
            !this._dateService.checkIfDateIsWeekend(
              this.modifiedOvertimRecords[i].dateofwork
            ) &&
            this._dateService.checkIfDateIsAHoliday(
              this.modifiedOvertimRecords[i].dateofwork,
              this.HolidayListArray
            )
          ) {
            this.OvertimeTypeRequiesValidations = false;
            this.modifiedOvertimRecords[i].overtimeRequireValidation = false;
          }
          if (
            this._dateService.checkIfDateIsWeekend(
              this.modifiedOvertimRecords[i].dateofwork
            ) &&
            !this._dateService.checkIfDateIsAHoliday(
              this.modifiedOvertimRecords[i].dateofwork,
              this.HolidayListArray
            )
          ) {
            this.OvertimeTypeRequiesValidations = false;
            this.modifiedOvertimRecords[i].overtimeRequireValidation = false;
          }
        }
        if (this.modifiedOvertimRecords[i].overtimeRequireValidation === true) {
          check = true;
        }
      }
      this.OvertimeTypeRequiesValidations = check;
    }
  }

  validationCheckForDuplicateRecord() {
    this.DuplicateRecordsFound = false;
    for (var j = 0; j < this.modifiedOvertimRecords.length; j++) {
      this.modifiedOvertimRecords[j].isDuplicated = false;
      for (var i = 0; i < this.modifiedOvertimRecords.length; i++) {
        this.modifiedOvertimRecords[j].isDuplicated = false;
        if (i != j) {
          if (
            this.modifiedOvertimRecords[j].employeeid ===
              this.modifiedOvertimRecords[i].employeeid &&
            this.modifiedOvertimRecords[j].overtimetypeid ===
              this.modifiedOvertimRecords[i].overtimetypeid &&
            this.modifiedOvertimRecords[j].dateofwork ===
              this.modifiedOvertimRecords[i].dateofwork
          ) {
            this.modifiedOvertimRecords[j].isDuplicated = true;
            this.modifiedOvertimRecords[i].isDuplicated = true;
            this.DuplicateRecordsFound = true;
          }
        }
      }
    }
  }

  validateIfOTisLR() {
    this.OverrideCheckIfOTisLRCheck = false;
    for (var j = 0; j < this.modifiedOvertimRecords.length; j++) {
      if (this.modifiedOvertimRecords[j].overtimetypeid === 'LR') {
        this.OverrideCheckIfOTisLRCheck = true;
      }
    }
  }

  validateIfOTRisCallinRefused() {
    // if the OT Reason is callin refused:
    this.OverrideOTisCallinRefuseCheck = false;
    for (var j = 0; j < this.modifiedOvertimRecords.length; j++) {
      this.modifiedOvertimRecords[j].callinRefusedFalg = false;
      if (this.modifiedOvertimRecords[j].overtimereasonid === '25') {
        if (!(parseFloat(this.modifiedOvertimRecords[j].paidhours) === 0)) {
          this.modifiedOvertimRecords[j].callinRefusedFalg = true;
          this.OverrideOTisCallinRefuseCheck = true;
        }
        // break;
      }
    }
  }

  /*Override checks*/
  /*Functions to check if Override is provided*/
  isOverrideCheckedFor1Month() {
    var overrideProvidedForthis = false;
    if (
      this.OvertimeDate1MonthEarlierLaterCheck &&
      this.OverrideIfDateOlderEarlier1Month === true
    ) {
      overrideProvidedForthis = true;
    } else {
      overrideProvidedForthis = false;
    }
    return overrideProvidedForthis;
  }

  isOverrideCheckedForWeekendDateforOT() {
    var overrideProvidedForThis = false;
    if (
      this.OvertimeTypeRequiesValidations &&
      this.OverrideIfDateSelectedIsWeekedForOvertimeType === true
    ) {
      overrideProvidedForThis = true;
    } else {
      overrideProvidedForThis = false;
    }
    return overrideProvidedForThis;
  }

  isOverrideIfOTRisCallinRefused() {
    var overrideProvidedForthis = false;
    if (
      this.OverrideOTisCallinRefuseCheck &&
      this.OverrideOTisCallinRefuse === true
    ) {
      overrideProvidedForthis = true;
    } else {
      overrideProvidedForthis = false;
    }
    return overrideProvidedForthis;
  }

  isOverrideIfOTisLR() {
    var overrideProvidedForThis = false;
    if (
      this.OverrideCheckIfOTisLRCheck &&
      this.OverrideCheckIfOTisLR === true
    ) {
      overrideProvidedForThis = true;
    } else {
      overrideProvidedForThis = false;
    }
    return overrideProvidedForThis;
  }

  clear() {
    this.clean.emit('');
    this.closeUpdateModal.nativeElement.click();
  }

  clearForm() {}

  onChangeDateOfWork(idx: number, e: any) {
    var dateOfWork = e?.target.value;
    this.modifiedOvertimRecords[idx].dateofwork = dateOfWork;
    for (var i = 0; i < this.modifiedOvertimRecords.length; i++) {
      if (this.modifiedOvertimRecords[idx].isselected === true) {
        if (this.modifiedOvertimRecords[i].isselected === true) {
          this.modifiedOvertimRecords[i].dateofwork = dateOfWork;
        }
      }
      if (
        this.modifiedOvertimRecords[i].dateofwork !=
        this.originalOvertimRecords[i].dateofwork
      ) {
        this.modifiedOvertimRecords[i].dateofworkflag = true;
      } else {
        this.modifiedOvertimRecords[i].dateofworkflag = false;
      }
    }
  }

  onChangePaidHours(idx: number, e: any) {
    var paidhrs = e?.target.value;
    this.modifiedOvertimRecords[idx].paidhours = parseFloat(paidhrs);
    for (var i = 0; i < this.modifiedOvertimRecords.length; i++) {
      if (this.modifiedOvertimRecords[idx].isselected === true) {
        if (this.modifiedOvertimRecords[i].isselected === true) {
          this.modifiedOvertimRecords[i].paidhours = parseFloat(paidhrs);
        }
      }
      if (
        this.modifiedOvertimRecords[i].paidhours !=
        this.originalOvertimRecords[i].paidhours
      ) {
        this.modifiedOvertimRecords[i].paidhoursflag = true;
      } else {
        this.modifiedOvertimRecords[i].paidhoursflag = false;
      }
    }
  }

  onChangeOvertimeType(idx: number, e: any) {
    var overtimeType = e?.target.value;
    this.modifiedOvertimRecords[idx].overtimetypeid = overtimeType;
    this.modifiedOvertimRecords[idx].OvertimeReasonArrayBasedOnOvertimeType =
      this.onChangeGetOvertimeType(overtimeType);
    this.modifiedOvertimRecords[idx].overtimereasonid = '';
    for (var i = 0; i < this.modifiedOvertimRecords.length; i++) {
      if (this.modifiedOvertimRecords[idx].isselected === true) {
        if (this.modifiedOvertimRecords[i].isselected === true) {
          this.modifiedOvertimRecords[i].overtimetypeid = overtimeType;
          this.modifiedOvertimRecords[
            i
          ].OvertimeReasonArrayBasedOnOvertimeType =
            this.onChangeGetOvertimeType(overtimeType);
          this.modifiedOvertimRecords[i].overtimereasonid = '';
        }
      }
      if (
        this.modifiedOvertimRecords[i].overtimetypeid !=
        this.originalOvertimRecords[i].overtimetypeid
      ) {
        this.modifiedOvertimRecords[i].overtimetypeidflag = true;
      } else {
        this.modifiedOvertimRecords[i].overtimetypeidflag = false;
      }
    }
  }

  onChangeOvertimeReason(idx: number, e: any) {
    var overtimeReason = e?.target.value;
    this.modifiedOvertimRecords[idx].overtimereasonid = overtimeReason;
    for (var i = 0; i < this.modifiedOvertimRecords.length; i++) {
      if (this.modifiedOvertimRecords[idx].isselected === true) {
        if (this.modifiedOvertimRecords[i].isselected === true) {
          this.modifiedOvertimRecords[i].overtimereasonid = overtimeReason;
        }
      }
      if (
        this.modifiedOvertimRecords[i].overtimereasonid !=
        this.originalOvertimRecords[i].overtimereasonid
      ) {
        this.modifiedOvertimRecords[i].overtimereasonidflag = true;
      } else {
        this.modifiedOvertimRecords[i].overtimereasonidflag = false;
      }
    }
  }

  onChangeOvertimeComment(idx: number, e: any) {
    var overtimeComment = e?.target.value;
    this.modifiedOvertimRecords[idx].freeformcomment = overtimeComment;
    for (var i = 0; i < this.modifiedOvertimRecords.length; i++) {
      if (this.modifiedOvertimRecords[idx].isselected === true) {
        if (this.modifiedOvertimRecords[i].isselected === true) {
          this.modifiedOvertimRecords[i].freeformcomment = overtimeComment;
        }
      }
      if (
        this.modifiedOvertimRecords[i].freeformcomment !=
        this.originalOvertimRecords[i].freeformcomment
      ) {
        this.modifiedOvertimRecords[i].freeformcommentflag = true;
      } else {
        this.modifiedOvertimRecords[i].freeformcommentflag = false;
      }
    }
  }

  onChangeOvertimeDateOffered(idx: number, e: any) {
    var overtimeDateOffered = e?.target.value;
    this.modifiedOvertimRecords[idx].dateoffered = overtimeDateOffered;
    for (var i = 0; i < this.modifiedOvertimRecords.length; i++) {
      if (this.modifiedOvertimRecords[idx].isselected === true) {
        if (this.modifiedOvertimRecords[i].isselected === true) {
          this.modifiedOvertimRecords[i].dateoffered = overtimeDateOffered;
        }
      }
      if (
        this.modifiedOvertimRecords[i].dateoffered !=
        this.originalOvertimRecords[i].dateoffered
      ) {
        this.modifiedOvertimRecords[i].dateofferedflag = true;
      } else {
        this.modifiedOvertimRecords[i].dateofferedflag = false;
      }
    }
  }

  onChangeOvertimeStatus(idx: number, e: any) {
    //var overtimeBoolStatus = e?.target.value;
    var overtimeBoolStatus = this.modifiedOvertimRecords[idx].boolStatus;
    for (var i = 0; i < this.modifiedOvertimRecords.length; i++) {
      if (this.modifiedOvertimRecords[idx].isselected === true) {
        if (this.modifiedOvertimRecords[i].isselected === true) {
          this.modifiedOvertimRecords[i].boolStatus = overtimeBoolStatus;
        }
      }
      if (
        this.modifiedOvertimRecords[i].boolStatus !=
        this.originalOvertimRecords[i].boolStatus
      ) {
        this.modifiedOvertimRecords[i].statusFalg = true;
      } else {
        this.modifiedOvertimRecords[i].statusFalg = false;
      }
    }
  }

  UpdateMassOvertimeRecord() {
    this.validationsCheckDate();
    this.validationsCheckOvertimeReason();
    this.validationsCheckOvertimeType();
    this.validationsCheckPaidHours();
    this.validationsCheckComment();
    this.validationCheckEarlierOlderDate();
    this.validationsIsDateHolidayReason();
    this.validationCheckForDuplicateRecord();
    this.validateIfOTisLR();
    this.validateIfOTRisCallinRefused();
    if (
      this.OvertimeDate1MonthEarlierLaterCheck &&
      this.isOverrideCheckedFor1Month() === false
    ) {
      return;
    }
    if (
      this.OvertimeTypeRequiesValidations &&
      this.isOverrideCheckedForWeekendDateforOT() === false
    ) {
      return;
    }
    if (
      this.OverrideOTisCallinRefuseCheck &&
      this.isOverrideIfOTRisCallinRefused() === false
    ) {
      return;
    }
    if (
      this.OverrideCheckIfOTisLRCheck &&
      this.isOverrideIfOTisLR() === false
    ) {
      return;
    }
    if (this.DuplicateRecordsFound === true) {
      this._sweetAlertService.validationError(
        'error',
        'Duplicate record found',
        'Record with same overtime type found'
      );
    } else if (
      this.OvertimeTypeIdCheck ||
      this.PaidHoursCheck ||
      this.OvertimeReasonIdCheck ||
      this.DateCheck ||
      this.OvertimeCommentCheck
    ) {
      return;
    } else {
      var onlyUpdatedOvertimRecords: any = [];
      for (var i = 0; i < this.modifiedOvertimRecords.length; i++) {
        if (
          this.modifiedOvertimRecords[i].dateofworkflag === true ||
          this.modifiedOvertimRecords[i].paidhoursflag === true ||
          this.modifiedOvertimRecords[i].overtimetypeidflag === true ||
          this.modifiedOvertimRecords[i].overtimereasonidflag === true ||
          this.modifiedOvertimRecords[i].freeformcommentflag === true ||
          this.modifiedOvertimRecords[i].dateofferedflag === true ||
          this.modifiedOvertimRecords[i].statusFalg === true
        ) {
          if (this.modifiedOvertimRecords[i].boolStatus === true) {
            this.modifiedOvertimRecords[i].status = 'A';
          } else {
            this.modifiedOvertimRecords[i].status = 'I';
          }
          this.modifiedOvertimRecords[i].createdby = this.loggedInUserATID;
          onlyUpdatedOvertimRecords.push(this.modifiedOvertimRecords[i]);
        }
      }
      var returnCount;
      //console.log(onlyUpdatedOvertimRecords);

      this._overtimeMaintenanceService
        .MassUpdateOvertimeRecords(JSON.stringify(onlyUpdatedOvertimRecords))
        .subscribe((data: any) => {
          returnCount = data.length;
          if (returnCount > 0) {
            this._sweetAlertService.validationError(
              'error',
              'Duplicate record found',
              'Record with same overtime time code found'
            );
            for (var j = 0; j < this.modifiedOvertimRecords.length; j++) {
              for (var i = 0; i < data.length; i++) {
                if (
                  this.modifiedOvertimRecords[j].employeeid ===
                    data[i].employeeid &&
                  this.modifiedOvertimRecords[j].overtimetypeid ===
                    data[i].overTimeTypeId &&
                  this.modifiedOvertimRecords[j].dateofwork ===
                    this._commonApplicationService.formatDate(
                      data[i].dateOfWork
                    )
                ) {
                  this.modifiedOvertimRecords[j].isDuplicated = true;
                  break;
                } else {
                  this.modifiedOvertimRecords[j].isDuplicated = false;
                }
              }
            }
          } else {
            this.clear();
            this._sweetAlertService.successAlert(
              'top-end',
              'success',
              'Overtime record(s) updated successfully',
              false,
              1500
            );
            this.isSucess.emit(true);
          }
        });
    }
  }
}
