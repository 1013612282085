import { formatDate } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AnnouncementService } from 'src/app/Services/Admin/Announcement/announcement.service';
import { AngularEditorConfig } from '@kolkov/angular-editor/lib/config';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MSALserviceService } from 'src/app/Services/MSALservice/msalservice.service';
@Component({
  selector: 'app-add-announcement',
  templateUrl: './add-announcement.component.html',
  styleUrls: ['./add-announcement.component.css'],
})
export class AddAnnouncementComponent implements OnInit {
  @ViewChild('closeAddModel') closeAddModal!: ElementRef;
  @Input() userName: any;
  @Output() isSucess = new EventEmitter();
  AnnouncementForm!: FormGroup;
  GroupPermissions: any[] = [];
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['strikeThrough', 'subscript', 'superscript'],
      ['customClasses', 'link', 'unlink', 'insertImage', 'insertVideo'],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  constructor(
    private fb: FormBuilder,
    private _AnnouncementService: AnnouncementService,
    private _sweetAlertService: SweetAlertService,
    private _msal: MSALserviceService
  ) {}

  ngOnInit(): void {
    this.getGroups();
    this.createForm();
    this._msal.getUserName();
    this.AnnouncementForm.get('STATUS')?.patchValue('A');
    this.AnnouncementForm.get('CREATEDBY')?.patchValue(
      this._msal.userAtid.replace('@alsn.com', '')
    );
    this.AnnouncementForm.get('STARTTIME')?.patchValue(
      formatDate(
        new Date(new Date().setHours(0, 0, 0, 0)),
        'yyyy-MM-ddThh:mm',
        'en-US'
      )
    );
    this.AnnouncementForm.get('ENDTIME')?.patchValue(
      formatDate(
        new Date(new Date().setHours(191, 59, 0, 0)),
        'yyyy-MM-ddThh:mm',
        'en-US'
      )
    );
  }

  createForm() {
    this.AnnouncementForm = this.fb.group({
      TITLE: ['', [Validators.required]],
      TEXT: ['', [Validators.required]],
      STARTTIME: ['', [Validators.required]],
      ENDTIME: ['', [Validators.required]],
      STATUS: ['', [Validators.required]],
      GROUPID: [''],
      CREATEDBY: ['', [Validators.required]],
    });
  }

  groupsList: any;
  getGroups() {
    this._AnnouncementService.GetGroups().subscribe((res) => {
      this.groupsList = res;
    });
  }

  AddAnnouncement() {
    const START_TIME = new Date(
      this.AnnouncementForm.controls['STARTTIME'].value
    );
    const END_TIME = new Date(this.AnnouncementForm.controls['ENDTIME'].value);
    if (
      this.AnnouncementForm.controls['TITLE'].value.toString().length > 2048
    ) {
      return this._sweetAlertService.invalidSelection(
        'error',
        'ERROR',
        'The announcement Title cannot be longer than 2048 characters.'
      );
    }
    if (this.AnnouncementForm.controls['TEXT'].value.toString().length > 2048) {
      return this._sweetAlertService.invalidSelection(
        'error',
        'ERROR',
        'The announcement text cannot be longer than 2048 characters.'
      );
    }
    if (this.GroupPermissions.length <= 0) {
      return this._sweetAlertService.invalidSelection(
        'error',
        'ERROR',
        'You must choose at least one group.'
      );
    }
    if (!(new Date(END_TIME) >= new Date(START_TIME))) {
      return this._sweetAlertService.invalidSelection(
        'error',
        'ERROR',
        'You must enter an end date that is the same or after the start date.'
      );
    } else if (new Date(END_TIME) === new Date(START_TIME)) {
      if (
        !(
          parseInt((END_TIME.getHours() + END_TIME.getMinutes()).toString()) >=
          parseInt((START_TIME.getHours() + START_TIME.getMinutes()).toString())
        )
      ) {
        return this._sweetAlertService.invalidSelection(
          'error',
          'ERROR',
          'You must enter an end date that is the same or after the start date.'
        );
      }
    }

    const groupPermissionsStringify = this.GroupPermissions.toString();
    this.AnnouncementForm.get('GROUPID')?.patchValue(groupPermissionsStringify);
    this._AnnouncementService
      .AnnouncementAdd(this.AnnouncementForm.value)
      .subscribe(
        (res: any) => {
          this.clear();
          this.isSucess.emit(true);
          this._sweetAlertService.successAlert(
            'center',
            'success',
            'Announcement has been added Successfully.',
            false,
            1500
          );
        },
        (_error: HttpErrorResponse) => {
          if (_error.status === 500) {
            return this._sweetAlertService.InternalServerError500(
              'error',
              'Error',
              _error.error.replace(
                'Error: AnnouncementAdd - Generic Exception: ',
                ''
              )
            );
          } else {
            return this._sweetAlertService.InternalServerError500(
              'error',
              'Error',
              'Something happened. Please try agin later'
            );
          }
        }
      );
  }
  clear() {
    this.AnnouncementForm.reset();
    this.closeAddModal.nativeElement.click();
    this.resetForm();
    this.isSucess.emit(true);
  }

  resetForm() {
    this.groupsList.forEach((ele: any) => {
      ele.allow = false;
    });
    this.AnnouncementForm.get('STATUS')?.patchValue('A');
    this.AnnouncementForm.get('STARTTIME')?.patchValue(
      formatDate(
        new Date(new Date().setHours(0, 0, 0, 0)),
        'yyyy-MM-ddThh:mm',
        'en-US'
      )
    );
    this.AnnouncementForm.get('ENDTIME')?.patchValue(
      formatDate(
        new Date(new Date().setHours(191, 59, 0, 0)),
        'yyyy-MM-ddThh:mm',
        'en-US'
      )
    );
    this.AnnouncementForm.get('CREATEDBY')?.patchValue(
      this._msal.userAtid.replace('@alsn.com', '')
    );
  }

  updateGroupPermission(id: any, allow: any) {
    var selectedGroup: any[] = [];
    this.groupsList.forEach((ele: { allow: boolean; groupid: any }) => {
      if (ele.groupid === id) {
        ele.allow = !allow;
      }
      if (ele.allow === true) {
        selectedGroup.push(ele.groupid);
      }
    });
    this.GroupPermissions = selectedGroup;
  }
}
