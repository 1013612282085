import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
//import { TransferAgreementService } from 'src/app/Services/Applications/TransferAgreementService/transfer-agreement.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { TransferAgreementService } from 'src/app/Services/Views/TransferAgreementView/transfer-agreement.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-transfer-agreement-view',
  templateUrl: './transfer-agreement-view.component.html',
  styleUrls: ['./transfer-agreement-view.component.css'],
})
export class TransferAgreementViewComponent implements OnInit {
  public loading: boolean = false;
  TotalApplicationCount: any;
  UserPage: number = 1;
  Applicationpage: number = 1;
  filterData: any = {};
  transferAgreementViewData: any[] = [];
  userInformation: any;
  views: any[] = [];
  classifications: any;
  plants: any;
  isApplicationDataEmpty: boolean = false;
  filterConfig: any = [
    {
      label: 'Classification Applied For',
      type: 'dropdown',
      data: [],
      //placeholder: 'Search classification',
      default: 'Select One',
      controlName: 'jobCodeId',
      runClassification: false,
    },
    {
      label: 'Plant Applied For',
      type: 'dropdown',
      data: [],
      // placeholder: 'Search plant',
      default: 'Select One',
      controlName: 'plantId',
      runClassification: false,
    },
  ];

  constructor(
    private _user: UsergroupService,
    private _commonApplicationService: CommonService,
    private ta: TransferAgreementService
  ) {}

  ngOnInit(): void {
    this.getClassifications();
    this.getPlants();
    this.getUserInfo();
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'VIEWS',
        'VIEWALLTRANSFERAGREEMENTS'
      );
    });
  }

  getClassifications() {
    this.ta.getProductionClassifications().subscribe((data: any) => {
      this.classifications = data;
      const mapElement = this.classifications.map(
        (element: { jobCodeId: any; jobCodeDescription: any }) => ({
          id: element.jobCodeId,
          description: element.jobCodeDescription,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Classification Applied For') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  getPlants() {
    this.ta.getActivePlants().subscribe((data: any) => {
      this.plants = data;
      const mapElement = this.plants.map(
        (element: { plantid: any; plantdescription: any }) => ({
          id: element.plantid,
          description: element.plantdescription,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Plant Applied For') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  getFilterValue(event: any) {
    this.filterData = event;
  }

  clearFilterData(filterData: any) {
    Object.keys(filterData).forEach((k) => delete filterData[k]);
  }

  searchTransferAgreement() {
    this.loading = true;
    let queryParams = this.filterData;
    Object.keys(queryParams).forEach(
      (k) =>
        !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]
    );
    this.ta.searchTransferAgreement(queryParams).subscribe(
      (res) => {
        //  console.log(res);
        this.transferAgreementViewData = res;

        for (var i = 0; i < this.transferAgreementViewData.length; i++) {
          if (this.transferAgreementViewData[i].empSignDate != null) {
            this.transferAgreementViewData[i].empSignDateSort = new Date(
              this.transferAgreementViewData[i].empSignDate
                .replace(/-/g, '/')
                .replace(/T.+/, '')
            ).toISOString();
          }
          if (this.transferAgreementViewData[i].planT_SEN_DATE != null) {
            this.transferAgreementViewData[i].plantSenDateSort = new Date(
              this.transferAgreementViewData[i].planT_SEN_DATE
                .replace(/-/g, '/')
                .replace(/T.+/, '')
            ).toISOString();
          }
          if (this.transferAgreementViewData[i].gM_SEN_DATE != null) {
            this.transferAgreementViewData[i].GMSeniorityDateSort = new Date(
              this.transferAgreementViewData[i].gM_SEN_DATE
                .replace(/-/g, '/')
                .replace(/T.+/, '')
            ).toISOString();
          }
        }
        this.isApplicationDataEmpty =
          this.transferAgreementViewData.length === 0 ? true : false;
        this.Applicationpage = 1;
        this.TotalApplicationCount = res.length;
        this.loading = false;
      },
      (_error: HttpErrorResponse) => {
        this.loading = false;
        //this.isApplicationDataEmpty = this.transferAgreementViewData.length===0 ? true : false;
        this.badRequestAlert(_error.error);
      }
    );
  }

  getbyenter(e: any) {
    this.loading = true;
    //console.log(e)
    this.ta.searchTransferAgreement(e).subscribe((res: any) => {
      this.transferAgreementViewData = res;

      for (var i = 0; i < this.transferAgreementViewData.length; i++) {
        if (this.transferAgreementViewData[i].empSignDate != null) {
          this.transferAgreementViewData[i].empSignDateSort = new Date(
            this.transferAgreementViewData[i].empSignDate
              .replace(/-/g, '/')
              .replace(/T.+/, '')
          ).toISOString();
        }
        if (this.transferAgreementViewData[i].planT_SEN_DATE != null) {
          this.transferAgreementViewData[i].plantSenDateSort = new Date(
            this.transferAgreementViewData[i].planT_SEN_DATE
              .replace(/-/g, '/')
              .replace(/T.+/, '')
          ).toISOString();
        }
        if (this.transferAgreementViewData[i].gM_SEN_DATE != null) {
          this.transferAgreementViewData[i].GMSeniorityDateSort = new Date(
            this.transferAgreementViewData[i].gM_SEN_DATE
              .replace(/-/g, '/')
              .replace(/T.+/, '')
          ).toISOString();
        }
      }

      // console.log(this.transferAgreementViewData)
      this.isApplicationDataEmpty =
        this.transferAgreementViewData.length === 0 ? true : false;
      this.Applicationpage = 1;
      this.TotalApplicationCount = res.length;
      this.loading = false;
    });
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  @ViewChild('TransferAgreementViewExportTable', {
    read: ElementRef,
    static: false,
  })
  TransferAgreementViewExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.TransferAgreementViewExportTable,
        `${ApplicationEnum.TRANSFERAGREEMENTNVIEW}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    //console.log("ddsssd", this.transferAgreementViewData);
    if (dataType === 'string') {
      this.transferAgreementViewData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.transferAgreementViewData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
