<div class="container-fluid app-box">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Table Maintenance</li>
        <li class="breadcrumb-item brd-li brd-li-child">Zone Data</li>
      </ol>
    </nav>
    <div class="align-self-end ml-auto">
      <button
        *ngIf="views[0]?.allowAdd"
        class="btn btn-success newbtn"
        data-toggle="modal"
        [attr.data-target]="modalId"
        data-backdrop="false"
        (click)="showAddModal()"
      >
        Add Zone Data
      </button>
    </div>

    <div *ngIf="zoneData !== null && zoneData.length > 0">
      <button
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        style="margin-right: 50px"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
    </div>
  </div>

  <div class="row content-container mt-2 mb-2">
    <div class="div-color">
      <div class="pt-5" style="padding-top: 0px !important">
        <div>
          <table
            *ngIf="!isDataExportableView && zoneData"
            class="table table-striped"
            #zonedataTablemaintenanceExportTable
          >
            <thead class="table-header transfer-header">
              <tr>
                <!-- <th scope="col">Zone ID</th>
                                <th scope="col">Representative</th>
                                <th scope="col">Representative Phone</th>
                                <th scope="col">Status</th> -->

                <th scope="col">
                  <span (click)="onSortClick($event, 'zoneid', 'string')"
                    >Zone Id
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'zoneid'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'zoneid'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'zoneid'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="
                      onSortClick($event, 'representativename', 'string')
                    "
                    >Representative
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'representativename'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'representativename'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'representativename'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="
                      onSortClick($event, 'representativephone', 'string')
                    "
                    >Representative Phone
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'representativephone'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'representativephone'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'representativephone'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'status', 'string')"
                    >Status
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'status'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'status'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <!-- <th scope="col">Edit</th>
                                <th scope="col">Delete</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of zoneData; let i = index" class="">
                <td class="tab-value">{{ data.zoneid }}</td>
                <td class="left-tab-value">{{ data.representativename }}</td>
                <td class="left-tab-value">
                  Contact no:
                  {{
                    data.representativephone ? data.representativephone : 'N/A'
                  }}
                </td>
                <td class="tab-value">
                  {{ data.status === 'A' ? 'Active' : 'Inactive' }}
                </td>
                <!-- <td class="tab-value">
                                    <button *ngIf='views[0]?.allowUpdate' data-toggle="modal" [attr.data-target]="modalId"
                                    data-backdrop="false" class="cursor" (click)="showEditModal(data.zoneid)"><img
                                            src="../../../../../assets/edit-regular.svg"></button>
                                </td>
                                <td class="tab-value"> <button *ngIf='views[0]?.allowDelete' data-toggle="modal" [attr.data-target]="modalId"
                                  data-backdrop="false" class="cursor" (click)="showDeleteModal(data.zoneid)"><img
                                            src="../../../../../assets/delete.png"></button></td> -->
              </tr>
            </tbody>
          </table>

          <table class="table table-striped">
            <thead class="table-header transfer-header">
              <tr>
                <!-- <th scope="col">Zone ID</th>
                                <th scope="col">Representative</th>
                                <th scope="col">Representative Phone</th>
                                <th scope="col">Status</th> -->
                <th scope="col">
                  <span (click)="onSortClick($event, 'zoneid', 'string')"
                    >Zone Id
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'zoneid'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'zoneid'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'zoneid'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="
                      onSortClick($event, 'representativename', 'string')
                    "
                    >Representative
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'representativename'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'representativename'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'representativename'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="
                      onSortClick($event, 'representativephone', 'string')
                    "
                    >Representative Phone
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'representativephone'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'representativephone'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'representativephone'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'status', 'string')"
                    >Status
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'status'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'status'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of zoneData; let i = index" class="">
                <td class="tab-value">{{ data.zoneid }}</td>
                <td class="left-tab-value">{{ data.representativename }}</td>
                <td class="left-tab-value">
                  Contact no:
                  {{
                    data.representativephone ? data.representativephone : 'N/A'
                  }}
                </td>
                <td class="tab-value">
                  {{ data.status === 'A' ? 'Active' : 'Inactive' }}
                </td>
                <td class="tab-value">
                  <button
                    *ngIf="views[0]?.allowUpdate"
                    data-toggle="modal"
                    [attr.data-target]="modalId"
                    data-backdrop="false"
                    class="cursor"
                    (click)="showEditModal(data.zoneid)"
                  >
                    <img src="../../../../../assets/edit-regular.svg" />
                  </button>
                </td>
                <td class="tab-value">
                  <button
                    *ngIf="views[0]?.allowDelete"
                    data-toggle="modal"
                    [attr.data-target]="modalId"
                    data-backdrop="false"
                    class="cursor"
                    (click)="showDeleteModal(data.zoneid)"
                  >
                    <img src="../../../../../assets/delete.png" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <app-update-zone-data
    *ngIf="showEdit"
    [userName]="userName"
    [selectedZone]="selectedZone"
    (isSucess)="checkEdit($event)"
  >
  </app-update-zone-data>
  <app-add-zone-data
    *ngIf="showAdd"
    [userName]="this.userInformation.userName"
    (isSucess)="checkAdd($event)"
  >
  </app-add-zone-data>
  <app-delete-zone-data
    [selectedZone]="selectedZone"
    (isSucess)="checkDelete($event)"
  ></app-delete-zone-data>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
