import { Inject, NgZone, Injectable } from '@angular/core';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { formatDate } from '@angular/common';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs';

const MinutesFormat: number = 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class MSALserviceService {
  userName: string = 'User Full Name';
  userAtid: string = '';
  expiryTime: number = 0;
  LoginTime: any;

  loggedIn: boolean = false;
  rolesInactivity = [
    {
      RoleId: 0,
      Role: 'HRPLUS',
      AADRole: 'gg_UAWCAS_HRPLUS',
      isRole: false,
      Time: 30 * MinutesFormat,
    },
    {
      RoleId: 10,
      Role: 'ADMIN',
      AADRole: 'gg_UAWCAS_Admin',
      isRole: false,
      Time: 30 * MinutesFormat,
    },
    {
      RoleId: 20,
      Role: 'SUPERVISOR',
      AADRole: 'gg_UAWCAS_ATI_Mgmt',
      isRole: false,
      Time: 30 * MinutesFormat,
    },
    {
      RoleId: 30,
      Role: 'COMMITEE',
      AADRole: 'gg_UAW_Committee',
      isRole: false,
      Time: 30 * MinutesFormat,
    },
    {
      RoleId: 40,
      Role: 'EMPLOYEE',
      AADRole: 'gg_UAW_Employee',
      isRole: false,
      Time: 2 * MinutesFormat,
    },
    {
      RoleId: 50,
      Role: 'VIEW',
      AADRole: 'gg_UAWCAS_View',
      isRole: false,
      Time: 30 * MinutesFormat,
    },
    {
      RoleId: 25,
      Role: 'SUPERVISOR_RESTRICTIONS',
      AADRole: 'gg_UAWCAS_Committee_Med_Rest',
      isRole: false,
      Time: 30 * MinutesFormat,
    },
    {
      RoleId: 99,
      Role: 'DEFAULT',
      AADRole: '',
      isRole: false,
      Time: 30 * MinutesFormat,
    },
  ];

  timeoutId: any;
  userInactive: Subject<any> = new Subject();
  isActive$!: Observable<any>;
  private userActivity = new Subject<any>();

  decodedToken: any = {};

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private zone: NgZone
  ) {
    this.isActive$ = this.userActivity.asObservable();
  }
  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngOnInit() {
    this.msalBroadcastService.inProgress$.pipe().subscribe(() => {
      this.checkAndSetActiveAccount();
      this.getAuthToken();
      this.login();
    });
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  getAuthToken(): string {
    let storageKey: string = '';
    let accessToken: string = '';

    for (let i = 0; i < localStorage.length; i++) {
      let storageKeyT = localStorage.key(i);
      if (
        storageKeyT &&
        storageKeyT?.indexOf('-login.microsoftonline.us-idtoken-') > 1
      ) {
        storageKey = storageKeyT;
        break;
      }
    }

    let accountInfoString = localStorage.getItem(storageKey);
    if (accountInfoString != null) {
      let lsAccountInfo: {
        clientId: string;
        credentialType: string;
        environment: string;
        homeAccountId: string;
        realm: string;
        secret: string;
      } = JSON.parse(accountInfoString);

      accessToken = lsAccountInfo.secret;
      this.decodedToken = this.getDecodedToken(lsAccountInfo.secret);
    }
    this.loggedIn = true;
    this.CheckRolePriority();
    this.handleSession(accessToken);
    if (localStorage.getItem('SessionStart') === null) {
      localStorage.setItem(
        'SessionStart',
        formatDate(new Date(), 'MM-dd-yyyy hh:mm:ss a', 'en-US')
      );
    }

    return accessToken;
  }

  handleSession(accessToken: any) {
    sessionStorage.setItem('activeSession', 'true');
    localStorage.removeItem('Token');
    sessionStorage.removeItem('Token');
    sessionStorage.setItem('Token', accessToken);
    localStorage.setItem('Token', accessToken);
  }

  CheckRolePriority() {
    const decoded = this.decodedToken;
    const groups = decoded?.groups;

    var GroupsInPriority = [
      { key: 10, value: 'gg_UAWCAS_HRPLUS' },
      { key: 20, value: 'gg_UAWCAS_Admin' },
      { key: 25, value: 'gg_UAWCAS_Committee_Med_Rest' },
      { key: 30, value: 'gg_UAWCAS_ATI_Mgmt' },
      { key: 40, value: 'gg_UAW_Committee' },
      { key: 50, value: 'gg_UAW_Employee' },
      { key: 60, value: 'gg_UAWCAS_View' },
    ];

    var validGroups: any[] = [];

    for (var i = 0; i < groups?.length; i++) {
      if (groups[i]?.startsWith('gg_UAW')) {
        validGroups.push(groups[i]);
      }
    }
    var priorityArray: Number[] = [validGroups.length];

    if (validGroups.length === 1) {
      this.setRoleBool(validGroups[0]);
      return validGroups[0];
    } else {
      var i = 0;
      validGroups.forEach((adGroup) => {
        GroupsInPriority.forEach((adGroupPriority) => {
          if (adGroup === adGroupPriority.value) {
            priorityArray[i] = adGroupPriority.key;
            i++;
          }
        });
      });
    }
    priorityArray.sort();
    var groupName = '';
    GroupsInPriority.forEach((x) => {
      if (x.key === priorityArray[0]) {
        groupName = x.value;
      }
    });

    this.setRoleBool(groupName);
    return groupName;
  }

  setRoleBool(role: string) {
    this.rolesInactivity.forEach((roles) => {
      if (roles.AADRole === role) {
        roles.isRole = true;
      } else {
        roles.isRole = false;
      }
    });
  }

  getUserName() {
    const token = this.getAuthToken();
    const decoded = this.parseJwt(token);
    this.userName = decoded.name;
    this.userAtid = decoded.preferred_username;
  }

  getDecodedToken(token: any): Observable<any> {
    //const token = this.getAuthToken();
    this.decodedToken = null;
    this.decodedToken = this.parseJwt(token);
    return this.parseJwt(token);
  }

  logout() {
    this.authService.logout();
    localStorage.removeItem('SessionStart');
    sessionStorage.removeItem('SessionStart');
    localStorage.removeItem('Token');
    sessionStorage.removeItem('Token');
    localStorage.clear();
    sessionStorage.clear();
    this.loggedIn = false;
  }

  RefreshToken() {
    const token = this.getAuthToken();
    const account = this.authService.instance.getAllAccounts()[0];
    const renewIdTokenRequest = {
      scopes: ['user.read'],
      account: account,
    };
    const expiryInMinutes = new Date(
      new Date(0).setUTCSeconds(this.parseJwt(token).exp)
    );

    if (new Date() > expiryInMinutes) {
      this.userInactive.next('Your Token Session has Timed out.');
      this.logout();
    }

    if (token !== null) {
      this.authService.acquireTokenSilent(renewIdTokenRequest).subscribe(
        function (accessTokenResponse) {
          // Acquire token silent success
          let idToken = accessTokenResponse.idToken; // for user authentication
          let accessToken = accessTokenResponse.accessToken; // for Azure authentication
          //console.log('New Token Acquired silentely (if condition): ', AccessToken)
        },
        (error) => {
          if (error instanceof InteractionRequiredAuthError) {
            this.authService.acquireTokenSilent(renewIdTokenRequest).subscribe(
              function (accessTokenResponse: any) {
                // Acquire token interactive success
                let idtoken = accessTokenResponse.idToken;
                //console.log('Second Retrieval idToken:', idtoken);
              },
              (error: any) => {
                // Acquire token interactive failure
                //console.log('Error Ocurred :', error);
                this.userInactive.next(
                  "Couldn't Acquire Token. Contact Administrator"
                );
                this.logout();
              }
            );
          }
        }
      );
    }
  }

  parseJwt(token: string) {
    try {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    } catch (ex: any) {
      return ex.toString();
    }
  }

  getActivitybool(value: any) {
    var isUserActive: boolean = false;

    this.userActivity.next(value);
    isUserActive = value;

    if (isUserActive === false || isUserActive === undefined) {
      if (
        this.rolesInactivity[0].isRole ||
        this.rolesInactivity[1].isRole ||
        this.rolesInactivity[2].isRole ||
        this.rolesInactivity[3].isRole ||
        this.rolesInactivity[5].isRole ||
        this.rolesInactivity[6].isRole
      ) {
        this.timeoutId = setTimeout(() => {
          this.userInactive.next('Your Session has Timed out.');
          this.logout();
        }, this.rolesInactivity[0].Time);
      } else if (this.rolesInactivity[4].isRole) {
        this.timeoutId = setTimeout(() => {
          this.userInactive.next('Your Session has Timed out.');
          this.logout();
        }, this.rolesInactivity[4].Time);
      } else {
        this.timeoutId = setTimeout(() => {
          this.userInactive.next('Your Session has Timed out.');
          this.logout();
        }, this.rolesInactivity[3].Time);
      }
    } else if (isUserActive === true) {
      clearTimeout(this.timeoutId);
      this.RefreshToken();
    }
  }

  AutoLogout() {
    const deepCopy = JSON.parse(JSON.stringify(this.decodedToken));

    if (
      this.rolesInactivity[0].isRole ||
      this.rolesInactivity[1].isRole ||
      this.rolesInactivity[2].isRole ||
      this.rolesInactivity[3].isRole ||
      this.rolesInactivity[5].isRole ||
      this.rolesInactivity[6].isRole
    ) {
      const exp_date_hrplus = new Date(
        new Date(new Date(0).setUTCSeconds(deepCopy.exp)).getTime() -
          30 * 60 * 1000
      );
      console.log(
        'Time:',
        new Date(
          new Date(new Date(0).setUTCSeconds(deepCopy.exp)).getTime() -
            30 * 60 * 1000
        )
      );
      if (new Date() >= exp_date_hrplus) {
        this.logout();
      }
    } else if (this.rolesInactivity[4].isRole) {
      const exp_date_employee = new Date(
        new Date(new Date(0).setUTCSeconds(deepCopy.exp)).getTime() -
          58 * 60 * 1000
      );
      console.log(
        'Time:',
        new Date(
          new Date(new Date(0).setUTCSeconds(deepCopy.exp)).getTime() -
            58 * 60 * 1000
        )
      );
      if (new Date() >= exp_date_employee) {
        this.logout();
      }
    } else if (
      new Date() >=
      new Date(
        new Date(new Date(0).setUTCSeconds(deepCopy.exp)).getTime() -
          30 * 60 * 1000
      )
    ) {
      this.logout();
    }
  }

  InactivityTimeout() {
    if (
      this.rolesInactivity[0].isRole ||
      this.rolesInactivity[1].isRole ||
      this.rolesInactivity[2].isRole ||
      this.rolesInactivity[3].isRole ||
      this.rolesInactivity[5].isRole ||
      this.rolesInactivity[6].isRole
    ) {
      var onsetTimout: any;
      onsetTimout = setTimeout(() => {
        const _lastCursorTIme = new Date(
          localStorage.getItem('movement') || '{}'
        );
        const _30MinsWindow = new Date(
          new Date(_lastCursorTIme).getTime() + this.rolesInactivity[0].Time
        );
        if (new Date() > _30MinsWindow) {
          this.userInactive.next('Your Session has Timed out.');
          this.logout();
        } else {
          clearTimeout(onsetTimout);
        }
      }, this.rolesInactivity[0].Time);
    } else {
      if (this.rolesInactivity[4].isRole) {
        // console.log('Logged in user is UAW-EMPLOYEE');
        var onsetTimout: any;
        onsetTimout = setTimeout(() => {
          const _lastCursorTIme = new Date(
            localStorage.getItem('movement') || '{}'
          );
          const _2MinsWindow = new Date(
            new Date(_lastCursorTIme).getTime() + this.rolesInactivity[4].Time
          );
          if (new Date() > _2MinsWindow) {
            this.userInactive.next('Your Session has Timed out.');
            this.logout();
          } else {
            clearTimeout(onsetTimout);
          }
        }, this.rolesInactivity[4].Time);
      } else {
        var onsetTimout: any;
        onsetTimout = setTimeout(() => {
          const _lastCursorTIme = new Date(
            localStorage.getItem('movement') || '{}'
          );
          const _30MinsWindow = new Date(
            new Date(_lastCursorTIme).getTime() + this.rolesInactivity[0].Time
          );
          if (new Date() > _30MinsWindow) {
            this.userInactive.next('Your Session has Timed out.');
            this.logout();
          } else {
            clearTimeout(onsetTimout);
          }
        }, this.rolesInactivity[0].Time);
      }
    }
  }

  isToken() {
    if (
      localStorage.getItem('Token') === null ||
      localStorage.getItem('Token') === undefined ||
      localStorage.getItem('Token') === ''
    ) {
      window.location.reload();
    }
  }
}
