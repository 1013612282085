<div class="modal fade" id="addEmployee" tabindex="-1" role="dialog" aria-labelledby="addEmployeeLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content holiday-modal">
            <div class="modal-header">
                <h5 class="modal-title" id="addEmployeeLabel">Add Holiday Data</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="clear()">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="holidayForm">
                    <table class="overtime-preview-table table table-striped" formArrayName="holidays">
                        <thead class="table-header">
                            <tr class="overtime-preview-table-tr">
                                <th class="overtime-preview-table-th">Holiday Date</th>
                                <th class="overtime-preview-table-th">Holiday Description</th>
                                <th class="overtime-preview-table-th"><button type="button" (click)="addHoliday()" class="cursor"><img class="holidayImg"
                                            src="../../../../../assets/add.png"></button></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let holiday of holidays().controls; let i=index" [formGroupName]="i">
                                <td class="overtime-preview-table-td">
                                    <input type="date" formControlName="Holiday" class="form-control">
                                    <div *ngIf="holidayForm.controls['Holiday']?.invalid && holidayForm.controls['Holiday']?.touched">
                                        <small class="text-danger" *ngIf="holidayForm.controls['Holiday']?.errors?.required">
                                            You must enter the date
                                        </small>
                                    </div>
                                </td>
                                <td class="overtime-preview-table-td">
                                    <input type="text" formControlName="Description" class="form-control">
                                    <div *ngIf="holidayForm.controls['Description']?.invalid && holidayForm.controls['Description']?.touched">
                                        <small class="text-danger" *ngIf="holidayForm.controls['Description']?.errors?.required">
                                            You must enter the description
                                        </small>
                                    </div>
                                </td>
                                <td class="overtime-preview-table-td">
                                    <button (click)="removeHoliday(i)" class="cursor"><img class="holidayImg"
                                            src="../../../../../assets/delete.png"></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
                <small *ngIf="isAddError" class="text-danger" style="font-size: 100%;font-weight: 400;">{{errorMessage}}</small>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" #closeAddModel (click)="clear()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]="holidayForm.errors || holidayForm.invalid" (click)="submitHoliday()">Add
                </button>
            </div>
        </div>
    </div>
</div>