<div class="container-fluid app-box">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Table Maintenance</li>
        <li class="breadcrumb-item brd-li brd-li-child">
          Overtime Reason Data
        </li>
      </ol>
    </nav>
    <div class="align-self-end ml-auto">
      <button
        *ngIf="views[0]?.allowAdd"
        class="btn btn-success newbtn"
        data-toggle="modal"
        [attr.data-target]="modalId"
        data-backdrop="false"
        (click)="showAddModal()"
      >
        Add Overtime Reason
      </button>
    </div>

    <div *ngIf="overtimeReasonData !== null && overtimeReasonData.length > 0">
      <button
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        style="margin-right: 50px"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
    </div>
  </div>

  <div class="row content-container mt-2 mb-2">
    <div class="div-color">
      <div class="pt-5" style="padding-top: 0px !important">
        <div>
          <table
            *ngIf="!isDataExportableView && overtimeReasonData"
            class="table table-striped"
            #overtimereasondataTablemaintenanceExportTable
          >
            <thead class="table-header transfer-header">
              <tr>
                <th scope="col">
                  <span
                    (click)="
                      onSortClick($event, 'overtimeReasonDescription', 'string')
                    "
                    >Description
                    <img
                      *ngIf="
                        sortDir === 1 &&
                        sortColumn === 'overtimeReasonDescription'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 &&
                        sortColumn === 'overtimeReasonDescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 &&
                        sortColumn !== 'overtimeReasonDescription'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'typeAssociation', 'string')"
                    >Type Association
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'typeAssociation'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'typeAssociation'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'typeAssociation'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'status', 'string')"
                    >Status
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'status'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'status'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <!-- <th scope="col">Description</th>
                                <th scope="col">Type Association</th>
                                <th scope="col">Status</th> -->
                <!-- <th scope="col">Edit</th>
                                <th scope="col">Delete</th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let data of overtimeReasonData; let i = index"
                class=""
              >
                <td class="left-tab-value">
                  {{ data.overtimeReasonDescription }}
                </td>
                <td *ngIf="data.typeAssociation === 'F'" class="tab-value">
                  Free Form
                </td>
                <td *ngIf="data.typeAssociation === 'A'" class="tab-value">
                  Accepted
                </td>
                <td *ngIf="data.typeAssociation === 'R'" class="tab-value">
                  Refused
                </td>
                <td class="tab-value">
                  {{ data.status === 'A' ? 'Active' : 'Inactive' }}
                </td>
                <!-- <td class="tab-value">
                                    <button *ngIf='views[0]?.allowUpdate' data-toggle="modal" [attr.data-target]="modalId" class="cursor" (click)="showEditModal(data.overtimeReasonId)"><img
                                            src="../../../../../assets/edit-regular.svg"></button>
                                </td>
                                <td class="tab-value"> <button *ngIf='views[0]?.allowDelete' data-toggle="modal" [attr.data-target]="modalId" class="cursor" (click)="showDeleteModal(data.overtimeReasonId)"><img
                                            src="../../../../../assets/delete.png"></button></td> -->
              </tr>
            </tbody>
          </table>
          <table class="table table-striped">
            <thead class="table-header transfer-header">
              <tr>
                <!-- <th scope="col">Description</th>
                                <th scope="col">Type Association</th>
                                <th scope="col">Status</th> -->

                <th scope="col">
                  <span
                    (click)="
                      onSortClick($event, 'overtimeReasonDescription', 'string')
                    "
                    >Description
                    <img
                      *ngIf="
                        sortDir === 1 &&
                        sortColumn === 'overtimeReasonDescription'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 &&
                        sortColumn === 'overtimeReasonDescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 &&
                        sortColumn !== 'overtimeReasonDescription'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'typeAssociation', 'string')"
                    >Type Association
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'typeAssociation'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'typeAssociation'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'typeAssociation'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'status', 'string')"
                    >Status
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'status'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'status'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let data of overtimeReasonData
                    | paginate
                      : {
                          id: 'joblist',
                          itemsPerPage: 20,
                          currentPage: Applicationpage,
                          totalItems: TotalApplicationCount
                        };
                  let i = index
                "
                class=""
              >
                <td class="left-tab-value">
                  {{ data.overtimeReasonDescription }}
                </td>
                <td *ngIf="data.typeAssociation === 'F'" class="tab-value">
                  Free Form
                </td>
                <td *ngIf="data.typeAssociation === 'A'" class="tab-value">
                  Accepted
                </td>
                <td *ngIf="data.typeAssociation === 'R'" class="tab-value">
                  Refused
                </td>
                <td class="tab-value">
                  {{ data.status === 'A' ? 'Active' : 'Inactive' }}
                </td>
                <td class="tab-value">
                  <button
                    *ngIf="views[0]?.allowUpdate"
                    data-toggle="modal"
                    [attr.data-target]="modalId"
                    data-backdrop="false"
                    class="cursor"
                    (click)="showEditModal(data.overtimeReasonId)"
                  >
                    <img src="../../../../../assets/edit-regular.svg" />
                  </button>
                </td>
                <td class="tab-value">
                  <button
                    *ngIf="views[0]?.allowDelete"
                    data-toggle="modal"
                    [attr.data-target]="modalId"
                    data-backdrop="false"
                    class="cursor"
                    (click)="showDeleteModal(data.overtimeReasonId)"
                  >
                    <img src="../../../../../assets/delete.png" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="single-wrap d-flex justify-content-center"
            *ngIf="overtimeReasonData !== null && overtimeReasonData.length > 0"
          >
            <nav aria-label="Page navigation example">
              <pagination-controls
                class="my-pagination"
                id="joblist"
                (pageChange)="Applicationpage = $event"
                previousLabel=""
                nextLabel=""
              >
              </pagination-controls>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-edit-overtime-reason-data
    *ngIf="showEdit"
    [userName]="this.userInformation.userName"
    [selectedOvertime]="selectedOvertime"
    (isSucess)="checkEdit($event)"
  ></app-edit-overtime-reason-data>
  <app-add-overtime-reason-data
    *ngIf="showAdd"
    [userName]="this.userInformation.userName"
    (isSucess)="checkAdd($event)"
  >
  </app-add-overtime-reason-data>
  <app-delete-overtime-reason-data
    [selectedOvertime]="selectedOvertime"
    (isSucess)="checkDelete($event)"
  >
  </app-delete-overtime-reason-data>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
