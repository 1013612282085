import { HttpErrorResponse } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { OvertimeGroupDataService } from 'src/app/Services/TableMaintenance/OvertimeGroupData/overtime-group-data.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-overtime-group-data',
  templateUrl: './overtime-group-data.component.html',
  styleUrls: ['./overtime-group-data.component.css'],
})
export class OvertimeGroupDataComponent implements OnInit {
  public loading = false;
  views: any[] = [];
  showEdit: boolean = false;
  showDelete: boolean = false;
  showAdd: boolean = false;
  TotalApplicationCount: any;
  UserPage: number = 1;
  Applicationpage: number = 1;
  modalId: string = '';
  filterData: any = {};
  overtimeGroupSearchData: any[] = [];
  selectedOvertimeGroup: any;
  showButton: boolean = false;
  userInformation: any;
  shiftsData: any;
  department: any[] = [];
  isApplicationDataEmpty: boolean = false;
  filterConfig: any = [
    {
      label: 'Department',
      type: 'input',
      data: [],
      placeholder: 'Enter Department',
      //default: 'All',
      controlName: 'DEPARTMENT',
      runClassification: false,
    },
    {
      label: 'Classification',
      type: 'input',
      data: [],
      placeholder: 'Enter Classification',
      //default: 'All',
      controlName: 'CLASSIFICATION',
      runClassification: false,
    },
    {
      label: 'Shift',
      type: 'dropdown',
      data: [],
      placeholder: 'Enter Shift',
      default: 'Any',
      controlName: 'SHIFT',
      runClassification: false,
    },
  ];

  constructor(
    private otg: OvertimeGroupDataService,
    private _user: UsergroupService,
    private _commonApplicationService: CommonService
  ) {
    this.getUserInfo();
    this.getDepartment();
  }

  ngOnInit(): void {
    this.getShifts();
  }
  getDepartment() {
    this.otg.getDepartments().subscribe((data) => {
      this.department = data;
    });
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'TABLEMAINTENANCE',
        'OVERTIMEGROUPDATA'
      );
    });
  }

  getShifts() {
    this.otg.getShifts().subscribe((res) => {
      this.shiftsData = res;
      const mapElement = this.shiftsData.map(
        (element: { shiftId: any; shiftDescription: any }) => ({
          id: element.shiftId,
          description: element.shiftDescription,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Shift') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  selectOvertimeGroup(idx: number, overtimegroupid: string) {
    for (let index = 0; index < this.overtimeGroupSearchData.length; index++) {
      if (
        this.overtimeGroupSearchData[index].overtimegroupid === overtimegroupid
      ) {
        this.selectedOvertimeGroup = this.overtimeGroupSearchData[index];
        this.showButton = true;
      }
    }
    this.showEditModal();
  }

  showEditModal() {
    this.showEdit = true;
    this.modalId = '#editGroup';
  }

  showAddModal() {
    this.showAdd = true;
    this.modalId = '#addGroup';
  }

  showDeleteModal(overtimegroupid: string) {
    for (let index = 0; index < this.overtimeGroupSearchData.length; index++) {
      if (
        this.overtimeGroupSearchData[index].overtimegroupid === overtimegroupid
      ) {
        this.selectedOvertimeGroup = this.overtimeGroupSearchData[index];
        this.showButton = true;
      }
    }
    this.showDelete = true;
    this.modalId = '#deleteGroup';
  }

  CheckEdit(e: any) {
    this.searchOvertimeGroups();
    this.showButton = false;
  }

  checkAdd(e: any) {
    this.showButton = false;
  }

  getFilterValue(event: any) {
    this.filterData = event;
  }

  searchOvertimeGroups() {
    this.loading = true;
    let queryParams = this.filterData;
    if (Object.keys(this.filterData).length === 0) {
      queryParams.DEPARTMENT = '';
      queryParams.CLASSIFICATION = '';
      queryParams.SHIFTID = '';
    }
    this.otg.searchOvertimeGroupData(queryParams).subscribe(
      (res) => {
        this.overtimeGroupSearchData = res;
        this.isApplicationDataEmpty =
          this.overtimeGroupSearchData.length === 0 ? true : false;
        this.showButton = false;
        this.Applicationpage = 1;
        this.TotalApplicationCount = res.length;
        this.loading = false;
      },
      (_error: HttpErrorResponse) => {
        //this.badRequestAlert();
        if (_error.status === 500) {
          this.loading = false;
          this.badRequestAlert(_error.error);
        } else {
          this.loading = false;
          this.badRequestAlert(_error.error);
        }
      }
    );
  }

  getbyenter(e: any) {
    this.otg.searchOvertimeGroupData(e).subscribe((res: any) => {
      this.overtimeGroupSearchData = res;
      this.isApplicationDataEmpty =
        this.overtimeGroupSearchData.length === 0 ? true : false;
      this.showButton = false;
      this.Applicationpage = 1;
      this.TotalApplicationCount = res.length;
    });
  }

  checkDelete(e: any) {
    this.searchOvertimeGroups();
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'warning',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  @ViewChild('overtimegroupdataTablemaintenanceExportTable', {
    read: ElementRef,
    static: false,
  })
  overtimegroupdataTablemaintenanceExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.overtimegroupdataTablemaintenanceExportTable,
        `${ApplicationEnum.OVERTIMEGROUPDATATABLEMAINTENANCE}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.overtimeGroupSearchData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.overtimeGroupSearchData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
