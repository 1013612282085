import {
  ElementRef,
  EventEmitter,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { OverTimeMaintanceService } from 'src/app/Services/Applications/OvertimeMaintanence/overtime.maintanence.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { DateService } from 'src/app/Services/Date/date.service';

@Component({
  selector: 'app-add-overtime-maintenance',
  templateUrl: './add-overtime-maintenance.component.html',
  styleUrls: ['./add-overtime-maintenance.component.css'],
})
export class AddOvertimeMaintenanceComponent implements OnInit {
  employeeForm!: FormGroup;
  StatusType: any = [
    {
      status: 'A',
      statusDescription: 'Active',
    },
    {
      status: 'I',
      statusDescription: 'Archived',
    },
  ];

  OvertimeType: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];
  OvertimeReason: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];

  OverTimeAddValue: any; //tbd
  modalId: string | any; //tbd
  wholeSetOfData: any; //tbd
  isEdit: boolean = false; //tbd
  modalTitle: any = ''; //tbd

  empSelected: any;
  currentDate = new Date();
  isExpOverride: boolean = false;
  isExpOverrideClassification: string = '';

  @Input() isAdd: boolean = false;
  @Input() title: any;

  @Input() passAddData: any = [];
  @Input() SelectedEmp: any;
  @Input() loggedInUserATID: string = '';
  @Input() overtimeGroupName: any;
  @Output() clean = new EventEmitter();
  @Output() isSucess = new EventEmitter();
  @Output() isIndAddSucess = new EventEmitter();
  @Output() MassOvertimeSuccess = new EventEmitter();
  @ViewChild('closeMassAddModal') closeMassAddModal!: ElementRef;
  @ViewChild('closeIndAddModal') closeIndAddModal!: ElementRef;

  /*Checks validations*/
  DateCheck: boolean = false;
  PaidHoursCheck: boolean = false;
  OvertimeTypeIdCheck: boolean = false;
  OvertimeReasonIdCheck: boolean = false;
  OvertimeCommentCheck: boolean = false;

  DuplicateRecordsFound: boolean = false;

  /*Override Flags*/
  OvertimeDate1MonthEarlierLaterCheck: boolean = false;
  OvertimeTypeRequiesValidations: boolean = false;
  OverrideCheckIfOTisLRCheck: boolean = false;
  OverrideOTisCallinRefuseCheck: boolean = false;

  /*Override Check*/
  OverrideIfDateOlderEarlier1Month: boolean = false;
  OverrideIfDateSelectedIsWeekedForOvertimeType: boolean = false;
  OverrideCheckIfOTisLR: boolean = false;
  OverrideOTisCallinRefuse: boolean = false;

  constructor(
    private fb: FormBuilder,
    private _overtimeMaintenanceService: OverTimeMaintanceService,
    private _sweetAlertService: SweetAlertService,
    private _commonApplicationService: CommonService,
    private _commonService: CommonService,
    private _dateService: DateService
  ) {}

  ngOnInit(): void {
    this.getOvertimeType();
    this.getOvertimeReason();
    this.getHolidayList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createEmpForm();
    this.loadEmployeeData(changes);
    this.getHolidayList();
  }

  loadEmployeeData(changes: SimpleChanges) {
    this.empSelected =
      (changes.SelectedEmp && changes.SelectedEmp.currentValue) || '';
    if (this.empSelected === '') {
      this.empSelected =
        (changes.SelectedEmp && changes.SelectedEmp.previousValue) || '';
    }
    this.DateCheck = false;
    this.PaidHoursCheck = false;
    this.OvertimeTypeIdCheck = false;
    this.OvertimeReasonIdCheck = false;
    this.OvertimeCommentCheck = false;
    this.OvertimeDate1MonthEarlierLaterCheck = false;
    this.OvertimeTypeRequiesValidations = false;
    this.DuplicateRecordsFound = false;
    this.OverrideCheckIfOTisLRCheck = false;
    this.OverrideOTisCallinRefuseCheck = false;

    this.OverrideIfDateOlderEarlier1Month = false;
    this.OverrideIfDateSelectedIsWeekedForOvertimeType = false;
    this.OverrideCheckIfOTisLR = false;
    this.OverrideOTisCallinRefuse = false;

    if (this.SelectedEmp) {
      this.employeeForm
        .get('name')
        ?.patchValue(
          this.SelectedEmp.lasT_NAME +
            ', ' +
            this.SelectedEmp.firsT_NAME +
            ' ' +
            this.SelectedEmp.middle
        );
      this.employeeForm
        .get('employeeid')
        ?.patchValue(this.SelectedEmp.employeeid);
      this.employeeForm.get('dept')?.patchValue(this.SelectedEmp.dept);
      this.employeeForm.get('acchours')?.patchValue(this.SelectedEmp.acchours);
      this.employeeForm
        .get('overtimegroupid')
        ?.patchValue(this.SelectedEmp.overtimegroupid);
      this.employeeForm
        .get('overtimegroupdetails')
        ?.patchValue(
          this.SelectedEmp.overtimegroupid +
            ' - ' +
            this.SelectedEmp.overtimegroupname
        );
    }
    this.employeeForm.get('status')?.patchValue('A');
    this.employeeForm
      .get('dateofwork')
      ?.patchValue(this._commonApplicationService.formatDate(this.currentDate));
    this.employeeForm
      .get('dateoffered')
      ?.patchValue(this._commonApplicationService.formatDate(this.currentDate));
    this.employeeForm.get('createdby')?.patchValue(this.loggedInUserATID);
  }

  createEmpForm() {
    this.employeeForm = this.fb.group(
      {
        employeeid: [''],
        overtimetypeid: ['', [Validators.required]],
        status: [''], //A
        overtimegroupid: [''], //402
        dateofwork: ['', [Validators.required]],
        paidhours: ['', [Validators.required]],
        overtimereasonid: ['', [Validators.required]],
        dateoffered: ['', [Validators.required]],
        createdby: [''],
        freeformcomment: [''],
        datearchved: [''], //null
        lastupdatedby: [''],
        rcrdid: [''],
        atin: [''],
        name: [''],
        dept: [''],
        acchours: [''],
        overtimegroupdetails: [''],
      },
      {
        validator: this.compareDates('dateofwork', 'dateoffered'),
      }
    );
    this.isAdd = false;
  }

  clear() {
    this.clean.emit('');
    this.closeMassAddModal.nativeElement.click();
    this.closeIndAddModal.nativeElement.click();
    this.formReset();
  }

  formReset() {
    this.isIndAddSucess.emit(true);
  }

  //Method to add an employee
  AddMassOvertimeRecord() {
    //console.log(this.employeeForm.value);
    var tempOverTimeAddValue: any;
    tempOverTimeAddValue = this.employeeForm.value;

    this.OverTimeAddValue = tempOverTimeAddValue;

    //console.log(this.employeeForm.value);
    // this.wholeSetOfData.push(this.empSelected);

    if (this.OverTimeAddValue && this.passAddData) {
      // this.modalTitle = 'Edit Employee';
      this.isEdit = true;
      this.modalId = '#PreviewScreenModal';
      this.clear();
      this.isSucess.emit(true);
    } else {
      this._sweetAlertService.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }

  AddIndividualOvertimeRecord() {
    const EmployeeName = this.employeeForm.get('name')?.value;
    const OvertimeReasonId = this.employeeForm.get('overtimereasonid')?.value;
    const OvertimeTypeId = this.employeeForm.get('overtimetypeid')?.value;
    const FreeFormComment = this.employeeForm.get('freeformcomment')?.value;
    const paidHours = this.employeeForm.get('paidhours')?.value;
    const DateOfWork = this.employeeForm.get('dateofwork')?.value;
    const DateOffered = this.employeeForm.get('dateoffered')?.value;

    this.validationsCheckComment(OvertimeReasonId, FreeFormComment);
    this.validationsIsDateHolidayReason(OvertimeTypeId, DateOfWork);
    this.validateIfOTisLR(OvertimeTypeId);
    this.validateIfOTRisCallinRefused(OvertimeReasonId, paidHours);
    this.validationCheckEarlierOlderDate(DateOfWork, DateOffered);

    if (
      this.OvertimeDate1MonthEarlierLaterCheck &&
      this.isOverrideCheckedFor1Month() === false
    ) {
      return;
    }
    if (
      this.OvertimeTypeRequiesValidations &&
      this.isOverrideCheckedForWeekendDateforOT() === false
    ) {
      return;
    }
    if (
      this.OverrideOTisCallinRefuseCheck &&
      this.isOverrideIfOTRisCallinRefused() === false
    ) {
      return;
    }
    if (
      this.OverrideCheckIfOTisLRCheck &&
      this.isOverrideIfOTisLR() === false
    ) {
      return;
    } else if (this.OvertimeCommentCheck) {
      return;
    } else {
      var returnCount;
      var SuccessMessage =
        'Overtime record(s) added for ' + EmployeeName + '  successfully';
      var IndividualAdd: any = [];
      // console.log("Employee Values for Overtime ", this.employeeForm.value)
      IndividualAdd.push(this.employeeForm.value);
      //console.log("Data but inside an array look : ",IndividualAdd)
      this._overtimeMaintenanceService
        .overtimeMassAdd(JSON.stringify(IndividualAdd))
        .subscribe(
          (data: any) => {
            returnCount = data.length;
            if (returnCount > 0) {
              this._sweetAlertService.validationError(
                'error',
                'Duplicate record found',
                'Record with same overtime time code found'
              );
              this.DuplicateRecordsFound = false;
              for (var i = 0; i < data.length; i++) {
                if (
                  IndividualAdd.employeeid === data[i].employeeid &&
                  IndividualAdd.overtimetypeid === data[i].overTimeTypeId &&
                  IndividualAdd.dateofwork ===
                    this._commonService.formatDate(data[i].dateOfWork)
                ) {
                  this.DuplicateRecordsFound = true;
                }
              }
            } else {
              this.clear();
              this._sweetAlertService.successAlert(
                'top-end',
                'success',
                SuccessMessage,
                false,
                1500
              );
              this.isIndAddSucess.emit(true);
              this.employeeForm.reset();
            }
          },
          (_error: HttpErrorResponse) => {
            // console.error(_error.status);
            this._sweetAlertService.InternalServerError500(
              'error',
              'ERROR',
              'An error occured while processing. Please try again.'
            );
          }
        );
    }
  }

  compareDates(overtimedate: string, dateoffered: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let OvertimeDate = group.controls[overtimedate];
      let DateOffered = group.controls[dateoffered];
      if (new Date(OvertimeDate?.value) < new Date(DateOffered?.value)) {
        // || new Date(OvertimeDate?.value) !== new Date(DateOffered?.value) ) {
        return {
          dates:
            'You must enter a overtime date that is the same or after the date offered.',
        };
      }
      return {};
    };
  }

  SetValue(e: any) {
    this.MassOvertimeSuccess.emit(e);
    this.passAddData.length = 0;
  }

  /*Get Apis */

  getOvertimeType() {
    this._overtimeMaintenanceService
      .getOvertimeType()
      .subscribe((overtimeType: any) => {
        this.OvertimeType[0].data = overtimeType;
      });
  }

  isCommentRequiredArray: any[] = [];
  getOvertimeReason() {
    this._overtimeMaintenanceService
      .getOvertimeReason()
      .subscribe((OvertimeReason: any) => {
        this.OvertimeReason[0].data = OvertimeReason;
        for (var i = 0; i < OvertimeReason.length; i++) {
          if (OvertimeReason[i].commentrequired === '1') {
            this.isCommentRequiredArray.push(OvertimeReason[i]);
          }
        }
      });
    //console.log(this.isCommentRequiredArray)
  }

  /* Holidays */
  HolidayListArray: any = [];
  getHolidayList() {
    this._overtimeMaintenanceService
      .GetHolidaysList()
      .subscribe((holidayList: any) => {
        for (var i = 0; i < holidayList.length; i++) {
          var HolidayID = holidayList[i].holidayid;
          var HolidayDate = this._commonService.formatDate(
            new Date(holidayList[i].holiday)
          );
          var HolidayDescription = holidayList[i].description;

          var objHoliday = {
            holidayId: HolidayID,
            holidayDate: HolidayDate,
            HolidayDescription: HolidayDescription,
          };
          this.HolidayListArray.push(objHoliday);
        }
      });
  }

  newArrayForOvertimeReason: any;
  onChangeGetOvertimeType(e: any) {
    var tempOvertimeTypeStore: any;
    tempOvertimeTypeStore = this.OvertimeType[0].data.filter(
      (selectedType: any) => selectedType['overtimetypeid'] === e.target.value
    );

    this.newArrayForOvertimeReason = this.OvertimeReason[0].data.filter(
      (selectedReason: any) =>
        selectedReason['typeassociation'] ===
        tempOvertimeTypeStore[0].reasonassociation
    );
  }

  /*Validation Checks*/

  validationsCheckPaidHours(e: any) {
    this.PaidHoursCheck = false;
    if (
      parseFloat(e.target.value) === NaN ||
      parseFloat(e.target.value) === null ||
      e.target.value === ''
    ) {
      this.PaidHoursCheck = true;
    }
  }

  validationsCheckComment(OvertimeType: any, freeComment: any) {
    this.OvertimeCommentCheck = false;
    for (var i = 0; i < this.isCommentRequiredArray.length; i++) {
      if (
        OvertimeType.toString() ===
        this.isCommentRequiredArray[i].overtimereasonid
      ) {
        if (freeComment.toString() === '' || freeComment.toString() === null) {
          this.OvertimeCommentCheck = true;
        }
      }
    }
  }

  validationCheckEarlierOlderDate(DateOfWork: any, DateOffered: any) {
    this.OvertimeDate1MonthEarlierLaterCheck = false;
    var Date1MonthFromToday = this._commonService.getDateFromToday(1);
    var Date1MonthEarlierToday = this._commonService.getDateEarlierFromToday(1);

    if (
      new Date(DateOfWork.toString()) > new Date(Date1MonthFromToday) ||
      new Date(DateOfWork.toString()) < new Date(Date1MonthEarlierToday) ||
      new Date(DateOffered.toString()) >
        (new Date(Date1MonthFromToday) ||
          new Date(DateOffered.toString()) < new Date(Date1MonthEarlierToday))
    ) {
      this.OvertimeDate1MonthEarlierLaterCheck = true;
    }
  }

  OvertimeTypeRequiredValidation = ['CA', 'CR', 'LCA', 'NSC', 'CAB', 'CD'];
  ErrorOvertimeType: any;
  validationsIsDateHolidayReason(overtimeType: any, dateofWork: any) {
    this.OvertimeTypeRequiesValidations = false;
    for (var j = 0; j < this.OvertimeTypeRequiredValidation.length; j++) {
      if (overtimeType.toString() === this.OvertimeTypeRequiredValidation[j]) {
        if (!this._dateService.checkIfDateIsWeekend(dateofWork)) {
          this.OvertimeTypeRequiesValidations = true;
        }
        if (
          !this._dateService.checkIfDateIsAHoliday(
            dateofWork,
            this.HolidayListArray
          )
        ) {
          this.OvertimeTypeRequiesValidations = true;
        }
        if (
          !this._dateService.checkIfDateIsWeekend(dateofWork) &&
          this._dateService.checkIfDateIsAHoliday(
            dateofWork,
            this.HolidayListArray
          )
        ) {
          this.OvertimeTypeRequiesValidations = false;
        }
        if (
          this._dateService.checkIfDateIsWeekend(dateofWork) &&
          !this._dateService.checkIfDateIsAHoliday(
            dateofWork,
            this.HolidayListArray
          )
        ) {
          this.OvertimeTypeRequiesValidations = false;
        } else if (
          !this._dateService.checkIfDateIsWeekend(dateofWork) &&
          !this._dateService.checkIfDateIsAHoliday(
            dateofWork,
            this.HolidayListArray
          )
        ) {
          this.OvertimeTypeRequiesValidations = true;
        }
      }
    }
    this.getOvertimeTypeNameForValidationMessage(overtimeType.toString());
  }

  getOvertimeTypeNameForValidationMessage(overtimetype: any) {
    this.ErrorOvertimeType = '';
    var tempOvertimeTypeStore: any;
    tempOvertimeTypeStore = this.OvertimeType[0].data.filter(
      (selectedType: any) => selectedType['overtimetypeid'] === overtimetype
    );
    this.ErrorOvertimeType = Array.from(new Set(tempOvertimeTypeStore));
  }

  validateIfOTisLR(value: any) {
    this.OverrideCheckIfOTisLRCheck = false;
    if (value.toString() === 'LR') {
      this.OverrideCheckIfOTisLRCheck = true;
      // break;
    }
  }

  validateIfOTRisCallinRefused(OvertimeReason: any, paidHours: any) {
    // if the OT Reason is callin refused:

    this.OverrideOTisCallinRefuseCheck = false;
    if (OvertimeReason.toString() === '25') {
      if (!(parseFloat(paidHours) === 0)) {
        this.OverrideOTisCallinRefuseCheck = true;
      }
      // break;
    }
  }

  /*Override checks*/
  /*Functions to check if Override is provided*/
  isOverrideCheckedFor1Month() {
    var overrideProvidedForthis = false;
    if (
      this.OvertimeDate1MonthEarlierLaterCheck &&
      this.OverrideIfDateOlderEarlier1Month === true
    ) {
      overrideProvidedForthis = true;
    } else {
      overrideProvidedForthis = false;
    }
    return overrideProvidedForthis;
  }

  isOverrideCheckedForWeekendDateforOT() {
    var overrideProvidedForThis = false;
    if (
      this.OvertimeTypeRequiesValidations &&
      this.OverrideIfDateSelectedIsWeekedForOvertimeType === true
    ) {
      overrideProvidedForThis = true;
    } else {
      overrideProvidedForThis = false;
    }
    return overrideProvidedForThis;
  }

  isOverrideIfOTRisCallinRefused() {
    var overrideProvidedForthis = false;
    if (
      this.OverrideOTisCallinRefuseCheck &&
      this.OverrideOTisCallinRefuse === true
    ) {
      overrideProvidedForthis = true;
    } else {
      overrideProvidedForthis = false;
    }
    return overrideProvidedForthis;
  }

  isOverrideIfOTisLR() {
    var overrideProvidedForThis = false;
    if (
      this.OverrideCheckIfOTisLRCheck &&
      this.OverrideCheckIfOTisLR === true
    ) {
      overrideProvidedForThis = true;
    } else {
      overrideProvidedForThis = false;
    }
    return overrideProvidedForThis;
  }

  /* on change override flags */
  /*New Functions created to change the override flag value*/
  onChange1MonthDateOverride() {
    this.OverrideIfDateOlderEarlier1Month =
      !this.OverrideIfDateOlderEarlier1Month;
  }

  onChangeWeekendDateForOTOverride() {
    this.OverrideIfDateSelectedIsWeekedForOvertimeType =
      !this.OverrideIfDateSelectedIsWeekedForOvertimeType;
  }

  onChangIfOTisLR() {
    this.OverrideCheckIfOTisLR = !this.OverrideCheckIfOTisLR;
  }

  onChangeIfOTRisCallinRefused() {
    this.OverrideOTisCallinRefuse = !this.OverrideOTisCallinRefuse;
  }
}
