<div
  class="modal fade"
  id="EmployeeMedicalRestrictions"
  tabindex="-1"
  role="dialog"
  aria-labelledby="getEmpMedicaltRestrictions"
  aria-hidden="true"
>
  <div
    *ngIf="ModalName === 'MedicalRestriction'"
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
    style="max-width: 80%"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="getEmpMedicaltRestrictions">
          Employee Medical Restrictions
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>

      <div class="modal-body">
        <div class="empdescription-box">
          <div class="empdescription-table">
            <table>
              <tr>
                <th class="empdescription-th">Name</th>
                <th class="empdescription-th">Telephone No.</th>
              </tr>
              <tr *ngIf="medicalRestrictionList !== undefined">
                <td class="empdescription-table-tr">
                  {{ medicalRestrictionList[0].lasT_NAME }},
                  {{ medicalRestrictionList[0].firsT_NAME }}&nbsp;{{
                    medicalRestrictionList[0].middle
                  }}
                </td>
                <td class="empdescription-table-tr">
                  {{ medicalRestrictionList[0].teL_NUM }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <table class="overtime-preview-table table table-striped">
          <thead class="table-header">
            <tr class="overtime-preview-table-tr">
              <th class="overtime-preview-table-th">No.</th>
              <!-- <th class="overtime-preview-table-th">Name</th> -->
              <th scope="overtime-preview-table-th">Category</th>
              <th scope="overtime-preview-table-th">Description</th>
              <th class="overtime-preview-table-th">Text</th>
              <!-- <th class="overtime-preview-table-th">Telephone No.</th> -->
              <th class="overtime-preview-table-th">From Date</th>
              <th class="overtime-preview-table-th">To Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let list of medicalRestrictionList; let i = index">
              <td class="overtime-preview-table-td">{{ i + 1 }}</td>
              <!-- <td class="overtime-preview-table-td">{{list.lasT_NAME}},{{list.firsT_NAME}}</td> -->
              <td class="overtime-preview-table-td">
                {{ list.medicalrestrictioncatdesc }}({{
                  list.medicalrestrictioncategorycode
                }})
              </td>
              <td class="overtime-preview-table-td">
                {{ list.medicalrestrictiondesc }}({{
                  list.medicalrestrictioncode
                }})
              </td>
              <td class="overtime-preview-table-td">
                {{ list.restrictiontext ? list.restrictiontext : 'N/A' }}
              </td>
              <!-- <td class="overtime-preview-table-td">{{list.teL_NUM}}</td> -->
              <td class="overtime-preview-table-td">
                {{ list.restrictionstartdate | date: 'MM/dd/yyyy' }}
              </td>
              <td class="overtime-preview-table-td">
                {{ list.restrictionenddate | date: 'MM/dd/yyyy' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div
    *ngIf="ModalName === 'PhoneNumber'"
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
    style="max-width: 30%"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="getEmpMedicaltRestrictions">
          Employee Phone Number
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>
      <table class="overtime-preview-table table table-striped">
        <thead class="table-header">
          <tr class="overtime-preview-table-tr">
            <th class="overtime-preview-table-th">Name</th>
            <th class="overtime-preview-table-th">Telephone No.</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="overtime-preview-table-td">
              {{ medicalRestrictionEmpName }}
            </td>
            <td class="overtime-preview-table-td">
              {{ employeePhoneNumber ? employeePhoneNumber : 'N/A' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
