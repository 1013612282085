import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class RuleEngineService {


  private readonly queryParam = '?employeeId='
  /* URL for Rule engine for all the transfer applications*/

  private readonly _addPS178ApplicationRule = '/PSAddApplicationRule' + this.queryParam;
  private readonly _addTAApplicationRule = '/TAAddApplicationRule' + this.queryParam;
  private readonly _addSS152ApplicationRule = '/SSAddApplicationRule' + this.queryParam;
  private readonly _addTD150ApplicationRule = '/TDAddApplicationRule' + this.queryParam;
  private readonly _addFS150ApplicationRule = '/FSAddApplicationRule' + this.queryParam;
  private readonly _addRRApplicationRule = '/RRAddApplicationRule' + this.queryParam;
  private readonly _addP19ApplicationRule = '/P19AddApplicationRule' + this.queryParam;
  private readonly _addSPApplicationRule = '/SPAddApplicationRule' + this.queryParam;
  private readonly api_getEmpStatusCode = '/GetEmpStatusCodeForClosed';
  private readonly api_checkAbsentEmpStatusCode = '/GetAbsentEmpStatusCodeForAdd?employeeId=';
  private readonly api_MedicalRestrictions = '/CheckEmployeeMedicalRestrictions?employeeId=';

  commonUtility = new CommonUtility();
  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  /* Production To Skilled - 178 */
  addPS178ApplicationRule(employeeId: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeader();
    return this._http.get<any>(
      `${environment.rulesEngineURL}` +
      this._addPS178ApplicationRule +
      employeeId,
      { headers }
    );
  }

  /* Transfer Application */
  addTAApplicationRule(employeeId: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeader();
    return this._http.get<any>(
      `${environment.rulesEngineURL}` +
      this._addTAApplicationRule +
      employeeId,
      { headers }
    );
  }


  /* Skilled To Skilled Application - 152 */
  addSS152ApplicationRule(employeeId: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeader();
    return this._http.get<any>(
      `${environment.rulesEngineURL}` +
      this._addSS152ApplicationRule +
      employeeId,
      { headers }
    );
  }


  /* Tool And Die - 150 */
  addTD150ApplicationRule(employeeId: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeader();
    return this._http.get<any>(
      `${environment.rulesEngineURL}` +
      this._addTD150ApplicationRule +
      employeeId,
      { headers }
    );
  }


  /* Facilities Service - 150 */
  addFS150ApplicationRule(employeeId: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeader();
    return this._http.get<any>(
      `${environment.rulesEngineURL}` +
      this._addFS150ApplicationRule +
      employeeId,
      { headers }
    );
  }

  /* Recall Rights Application - RR */
  addRecallRightRRApplicationRule(employeeId: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeader();
    return this._http.get<any>(
      `${environment.rulesEngineURL}` +
      this._addRRApplicationRule + employeeId,
      { headers }
    );
  }

  /* Paragraph 19 Application- P19 */
  addParagraph19ApplicationRule(employeeId: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeader();
    return this._http.get<any>(
      `${environment.rulesEngineURL}` +
      this._addP19ApplicationRule + employeeId,
      { headers }
    );
  }

  /* Shift Preference Application- SP*/
  addShiftPreferenceApplicationRule(employeeId: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeader();
    return this._http.get<any>(
      `${environment.rulesEngineURL}` +
      this._addSPApplicationRule + employeeId,
      { headers }
    );
  }

  getEmpStatusCode(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.rulesEngineURL}` + this.api_getEmpStatusCode,
      { headers }
    );
  }

  checkAbsentEmpStatusForAdd(employeeId: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.rulesEngineURL}` + this.api_checkAbsentEmpStatusCode + employeeId,
      { headers }
    );
  }

  getMedicalRestrictions(employeeId: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.rulesEngineURL}` + this.api_MedicalRestrictions + employeeId,
      { headers }
    );
  }

}
