<div class="modal fade" id="addEmployee" tabindex="-1" role="dialog" aria-labelledby="addEmployeeLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addEmployeeLabel">Add NIO Group</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="clear()">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="nioForm">

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">NIO Group*</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="NIO" class="form-control">
                            <div *ngIf="nioForm.controls['NIO'].invalid && nioForm.controls['NIO'].touched">
                                <small class="text-danger" *ngIf="nioForm.controls['NIO'].errors?.required">
                                    You must specify the NIO group to be entered
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Description*</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="nioGroupDescription" class="form-control">
                            <div *ngIf="nioForm.controls['nioGroupDescription'].invalid && nioForm.controls['nioGroupDescription'].touched">
                                <small class="text-danger" *ngIf="nioForm.controls['nioGroupDescription'].errors?.required">
                                    You must specify the NIO group description
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Status*</label>
                        <div class="col-sm-8">
                            <select formControlName="status" class="form-control">
                                <option *ngIf="Status[Status.length-1].default" selected value="">
                                    {{Status[Status.length-1].default}}</option>
                                <option *ngFor="let data of Status[Status.length-1].data" value={{data.statusType}}>
                                    {{data.statusName}}
                                </option>
                            </select>
                            <div *ngIf="nioForm.controls['status'].invalid && nioForm.controls['status'].touched">
                                <small class="text-danger" *ngIf="nioForm.controls['status'].errors?.required">
                                    You must specify a valid status
                                </small>
                            </div>
                        </div>
                    </div>

                </form>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" #closeAddModel (click)="clear()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]="nioForm.errors || nioForm.invalid" (click)="submitNIO()">Add
                </button>
            </div>
        </div>
    </div>
</div>