import { HttpErrorResponse } from '@angular/common/http';
import { ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OvertimeTypeDataService } from 'src/app/Services/TableMaintenance/OvertimeTypeData/overtime-type-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-overtime-type-data',
  templateUrl: './add-overtime-type-data.component.html',
  styleUrls: ['./add-overtime-type-data.component.css'],
})
export class AddOvertimeTypeDataComponent implements OnInit {
  @ViewChild('closeAddModel') closeAddModal!: ElementRef;
  overtimeForm!: FormGroup;
  @Input() userName: any;
  @Output() isSucess = new EventEmitter();
  Status: any[] = [
    {
      data: [
        {
          statusType: 'A',
          statusName: 'Active',
        },
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
      ],
      default: 'Select One',
    },
  ];
  ReasonAssociation: any[] = [
    {
      data: [
        {
          Type: 'A',
          Name: 'Accepted',
        },
        {
          Type: 'R',
          Name: 'Refused',
        },
        {
          Type: 'F',
          Name: 'Free Form',
        },
      ],
      default: 'Select One',
    },
  ];
  SkilledTiming: any[] = [
    {
      data: [
        {
          Type: 'C',
          Name: 'Current',
        },
        {
          Type: 'P',
          Name: 'Previous',
        },
        {
          Type: 'NI',
          Name: 'Not Included',
        },
      ],
      default: 'Select One',
    },
  ];
  ProdIncluded: any[] = [
    {
      data: [
        {
          Type: 'I',
          Name: 'Included',
        },
        {
          Type: 'NI',
          Name: 'Not Included',
        },
      ],
      default: 'Select One',
    },
  ];
  constructor(private fb: FormBuilder, private ods: OvertimeTypeDataService) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.overtimeForm = this.fb.group({
      OvertimeTypeId: ['', [Validators.required]],
      OvertimeTypeDescription: ['', Validators.required],
      ReasonAssociation: ['', Validators.required],
      SkilledTiming: ['', Validators.required],
      ProdIncluded: ['', Validators.required],
      Status: ['', [Validators.required]],
      CreatedBy: [this.userName],
    });
  }

  clear() {
    this.closeAddModal.nativeElement.click();
    this.createForm();
  }

  submitOvertime() {
    this.ods.addOvertimeTypeData(this.overtimeForm.value).subscribe(
      () => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 500) {
          this.badRequestAlert(_error.error);
        } else {
          this.validationError(_error.error);
        }
      }
    );
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Overtime type has been added successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
