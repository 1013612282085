import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { AppComponent } from 'src/app/app.component';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { Paragraph19Service } from 'src/app/Services/Applications/Paragraph19/paragraph19.service';
import { RuleEngineService } from 'src/app/Services/Ruleengine/rule-engine.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { UserInfo } from 'src/models/userinfo';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-paragraph19',
  templateUrl: './add-paragraph19.component.html',
  styleUrls: ['./add-paragraph19.component.css'],
})
export class AddParagraph19Component implements OnInit {
  employeeForm!: FormGroup;
  empSelected: any;
  selectedPlantId: any;
  atid: any;
  employeeId: any;
  currentDate = new Date();
  usersData: any;
  currentJobClassification: any;
  isAdmin: any;
  classifications: any[] = [
    {
      data: [],
      default: 'Select any classification',
    },
  ];
  maxDate: string = '';
  StatusCode: boolean = true;
  ruleMessages!: any[];
  errorlength: number = 0;
  absentEmpStatus: boolean = false;

  userInformation!: UserInfo;

  @Input() loggedInUser: any;
  @Input() selectedEmp: any;
  @Input() isAdd: boolean = false;
  @Input() title: any;
  @Output() clean = new EventEmitter();
  @Output() isSucess = new EventEmitter();
  @Input() AppType: string = '';
  @Input() isLoggedInUserAdmin: any;
  @ViewChild('closeAddModel') closeAddModal!: ElementRef;

  constructor(
    private fb: FormBuilder,
    private appCom: AppComponent,
    private authService: MsalService,
    private _user: UsergroupService,
    private p19: Paragraph19Service,
    private _commonApplicationService: CommonService,
    private _ruleEngine: RuleEngineService,
    private _sweetAlert: SweetAlertService
  ) {}

  ngOnInit(): void {
    this.getClassifications();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.createEmpForm();
    this.loadEmployeeData(changes);
  }

  createEmpForm() {
    this.employeeForm = this.fb.group({
      employeeName: [''],
      firstName: [''],
      lastName: [''],
      ATID: [this.atid],
      EmployeeId: [this.employeeId],
      AppNo: ['0'],
      AppStatusCode: ['OPEN'],
      currentClassification: [''],
      currentDepartment: [''],
      currentShift: [''],
      JobCodeId: ['', [Validators.required, this.CheckLeaderClassification]],
      App150PlantGroupId: ['0'],
      AppType: ['P19'],
      DateEntered: [this.currentDate],
      EnteredBy: [this.loggedInUser.userName],
      appStatusDate: [this.currentDate],
      empSignDate: [''],
      AbsentEmpStatusOverride: [false, [Validators.requiredTrue]],
    });
    this.isAdd = false;
  }

  loadEmployeeData(changes: SimpleChanges) {
    this.empSelected =
      (changes.selectedEmp && changes.selectedEmp.currentValue) || '';
    if (this.empSelected === '') {
      this.empSelected =
        (changes.selectedEmp && changes.selectedEmp.previousValue) || '';
    }
    this.getEmpAbsentStatus(this.empSelected.employeeId);
    this.getRuleEngineValidations(this.empSelected.employeeId);

    this.maxDate = this._commonApplicationService.formatDate(this.currentDate);
    this.employeeForm
      .get('employeeName')
      ?.patchValue(
        this.empSelected.lastName +
          ', ' +
          this.empSelected.firstName +
          ' ' +
          this.empSelected.middleName
      );
    this.employeeForm.get('firstName')?.patchValue(this.empSelected.firstName);
    this.employeeForm.get('lastName')?.patchValue(this.empSelected.lastName);
    this.atid = this.employeeForm
      .get('ATID')
      ?.patchValue(this.empSelected.atid);
    this.employeeId = this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.empSelected.employeeId);
    this.employeeForm
      .get('empSignDate')
      ?.patchValue(this._commonApplicationService.formatDate(new Date()));

    this.p19
      .searchFilterApplicationUser({ employeeId: this.empSelected.employeeId })
      .subscribe((data) => {
        this.usersData = data[0];
        this.currentJobClassification = this.usersData.jobCodeId;
        this.employeeForm
          .get('currentClassification')
          ?.patchValue(
            this.usersData.jobCodeId + '-' + this.usersData.jobCodeDescription
          );
        this.employeeForm
          .get('currentDepartment')
          ?.patchValue(this.usersData.deptId);
        this.employeeForm
          .get('currentShift')
          ?.patchValue(this.usersData.shiftDescription);
      });
  }

  getEmpAbsentStatus(employeeId: string) {
    this._ruleEngine
      .checkAbsentEmpStatusForAdd(employeeId)
      .subscribe((data: any) => {
        this.absentEmpStatus = data;
        this.setValue(this.absentEmpStatus);
      });
  }

  getRuleEngineValidations(employeeId: string) {
    this._ruleEngine
      .addParagraph19ApplicationRule(employeeId)
      .subscribe((data: any) => {
        this.StatusCode = data['status']; //for error: false
        this.ruleMessages = data['errors'];
        this.errorlength = data['errors'].length;
      });
  }

  setValue(status: boolean) {
    if (!status) {
      this.employeeForm.get('AbsentEmpStatusOverride')?.patchValue(true);
    }
  }

  clear() {
    this.clean.emit('');
    this.closeAddModal.nativeElement.click();
    this.resetFields();
  }

  clearForm() {
    this.employeeForm.reset;
  }

  resetFields() {
    this.employeeForm.get('empSignDate')?.reset();
    this.employeeForm.get('JobCodeId')?.setValue('');
    this.employeeForm
      .get('empSignDate')
      ?.patchValue(this._commonApplicationService.formatDate(new Date()));
    this.getClassifications();
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
    });
  }

  getClassifications() {
    this.p19.getClassificationList().subscribe((data) => {
      this.classifications[this.classifications.length - 1].data = data;
    });
  }

  CheckLeaderClassification(control: FormControl) {
    let jobClassification = control.value;
    let checkChar = jobClassification.charAt(jobClassification.length - 1);
    if (checkChar === 'L') {
      return {
        leaderClassificationError:
          'The selected classification cannot be a leader classification',
      };
    }
    return null;
  }

  //Method to add an employee
  addApplicationP19() {
    let appliedClassification = this.employeeForm.get('JobCodeId')?.value;
    if (this.currentJobClassification === appliedClassification) {
      this._sweetAlert.validationError(
        'error',
        'ERROR',
        'Cannot apply for current classification'
      );
    } else {
      if (this.ruleMessages.length === 0) {
        //var dt = this.employeeForm.get('empSignDate')?.value;
        // var empDt = new Date(dt);
        // var empSignDtInUtc = this._dateService.GetUTCDate(empDt);
        // this.employeeForm.patchValue({ empSignDate: empSignDtInUtc });

        this.p19.AddP19Application(this.employeeForm.value).subscribe(
          (data: any) => {
            this.clear();
            this.isSucess.emit(true);
            this._sweetAlert.successAlert(
              'center',
              'success',
              'The Application has been Added Successfully.',
              false,
              1500
            );
          },
          (_error: HttpErrorResponse) => {
            //this.badRequestAlert();
            if (_error.status === 500) {
              this._sweetAlert.validationError(
                'error',
                'Error',
                'An Error occured while processing.Please try again.'
              );
            } else {
              this.validationError();
            }
          }
        );
      } else {
        this._sweetAlert.validationError(
          'error',
          'Cannot add application for this employee',
          'Employee is not eligible.'
        );
      }
    }
  }

  validationError() {
    Swal.fire({
      title: 'Cannot create application',
      text: 'Cannot duplicate an open application.',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
