<div class="container-fluid app-box" style="background-color: #ffffff">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Overtime</li>
        <li class="breadcrumb-item brd-li brd-li-child">
          Overtime Reassign History
        </li>
      </ol>
    </nav>
    <div
      style="float: right; position: absolute; right: 276px"
      *ngIf="responseData !== null && responseData?.length > 0"
    >
      <button
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        style="margin-left: 15px"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
    </div>
  </div>
  <div>
    <div class="row content-container mt-2 mb-2">
      <div class="pt-2 div-color">
        <div class="search-divider-div filtercss">
          <p class="search-divder-p">
            <!-- <hr class="search-divider-hr" /> -->
          </p>

          <div class="jumbotron jumb-new jumb-newcontrols">
            <div
              class="row"
              display="float"
              style="top: 100px; position: sticky"
            >
              <div class="col">
                <app-filter
                  [filterConfig]="filterConfig"
                  (filterValue)="getFilterValue($event)"
                  (formcontrolobj)="getbyenter($event)"
                >
                </app-filter>
              </div>
              <div class="col-md-1 mr-5">
                <a
                  class="btn btn-lg search-button position-btn"
                  (click)="GetReassignHistory()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- filter ends -->
        <div class="container-divider-div"></div>
        <div>
          <table
            class="table table-striped"
            *ngIf="
              !isDataExportableView &&
              responseData !== null &&
              responseData?.length > 0
            "
            #OvertimeReassignHistoryExportTable
          >
            <thead class="table-header position">
              <tr>
                <th scope="col">
                  <span (click)="onSortClick($event, 'lastname', 'string')"
                    >Name
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'lastname'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'lastname'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'lastname'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'employeeid', 'string')"
                    >Emp Id
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'employeeid'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'employeeid'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'employeeid'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>

                <th scope="col">
                  <span (click)="onSortClick($event, 'jobcodeid', 'string')"
                    >Class
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'jobcodeid'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'jobcodeid'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'jobcodeid'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'deptid', 'string')"
                    >Dept
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'deptid'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'deptid'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'deptid'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'shiftdescription', 'string')"
                    >Shift
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'shiftdescription'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'shiftdescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'shiftdescription'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'overtimegroupname', 'string')"
                    >Overtime Group Name
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'overtimegroupname'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'overtimegroupname'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'overtimegroupname'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'datentered', 'date')"
                    >Date Entered
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'datentered'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'datentered'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'datentered'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>

                <th scope="col">
                  <span (click)="onSortClick($event, 'dateleft', 'date')"
                    >Date Left
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'dateleft'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'dateleft'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'dateleft'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="
                      onSortClick($event, 'differenceBtwDates', 'string')
                    "
                    >Difference
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'differenceBtwDates'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'differenceBtwDates'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'differenceBtwDates'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="let data of responseData; let i = index"
                class="table-container"
              >
                <td class="tab-value">
                  {{ data.lastname ? data.lastname : 'N/A' }},
                  {{ data.firstname ? data.firstname : 'N/A' }}&nbsp;{{
                    data.middle ? data.middle : ''
                  }}
                </td>
                <td class="tab-value">{{ data.employeeid | uppercase }}</td>
                <td class="tab-value">
                  {{ data.jobcodeid ? data.jobcodeid : '-' }}
                </td>
                <td class="tab-value">{{ data.deptid ? data.deptid : '-' }}</td>
                <td class="tab-value">{{ data.shiftdescription }}</td>
                <td class="tab-value">
                  {{ data.overtimegroupname ? data.overtimegroupname : '-' }}
                </td>
                <td class="tab-value">
                  {{ data.datentered | date: 'MM/dd/yyyy' }}
                </td>
                <td class="tab-value">
                  {{ data.dateleft | date: 'MM/dd/yyyy' }}
                </td>
                <td class="tab-value">{{ data.differenceBtwDates }}</td>
              </tr>
            </tbody>
          </table>

          <table
            class="table table-striped"
            *ngIf="responseData !== null && responseData?.length > 0"
          >
            <thead class="table-header position">
              <tr>
                <th scope="col">
                  <span (click)="onSortClick($event, 'employeeid', 'string')"
                    >Emp Id
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'employeeid'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'employeeid'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'employeeid'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'lastname', 'string')"
                    >Name
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'lastname'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'lastname'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'lastname'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'jobcodeid', 'string')"
                    >Class
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'jobcodeid'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'jobcodeid'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'jobcodeid'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'deptid', 'string')"
                    >Dept
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'deptid'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'deptid'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'deptid'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'shiftdescription', 'string')"
                    >Shift
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'shiftdescription'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'shiftdescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'shiftdescription'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'overtimegroupname', 'string')"
                    >Overtime Group
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'overtimegroupname'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'overtimegroupname'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'overtimegroupname'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'datentered', 'date')"
                    >Date Entered
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'datentered'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'datentered'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'datentered'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>

                <th scope="col">
                  <span (click)="onSortClick($event, 'dateleft', 'date')"
                    >Date Left
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'dateleft'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'dateleft'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'dateleft'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="
                      onSortClick($event, 'differenceBtwDates', 'number')
                    "
                    >Duration (Days)
                    <img
                      *ngIf="
                        sortDir === 1 && sortColumn === 'differenceBtwDates'
                      "
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'differenceBtwDates'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir !== 0 && sortColumn !== 'differenceBtwDates'
                      "
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="
                  let data of responseData
                    | paginate
                      : {
                          id: 'joblist',
                          itemsPerPage: 20,
                          currentPage: Applicationpage,
                          totalItems: TotalApplicationCount
                        };
                  let i = index
                "
                class="table-container"
              >
                <td class="tab-value">{{ data.employeeid | uppercase }}</td>
                <td class="tab-value">
                  {{ data.lastname ? data.lastname : 'N/A' }},
                  {{ data.firstname ? data.firstname : 'N/A' }}&nbsp;{{
                    data.middle ? data.middle : ''
                  }}
                </td>
                <td class="tab-value">
                  {{ data.jobcodeid ? data.jobcodeid : '-' }}
                </td>
                <td class="tab-value">{{ data.deptid ? data.deptid : '-' }}</td>
                <td class="tab-value">{{ data.shiftdescription }}</td>
                <td class="tab-value">
                  {{ data.overtimegroupname ? data.overtimegroupname : '-' }}
                </td>
                <td class="tab-value">
                  {{
                    data.datentered
                      ? (data.datentered | date: 'MM/dd/yyyy')
                      : '-'
                  }}
                </td>
                <td class="tab-value">
                  {{
                    data.dateleft !== '0001-01-01T00:00:00'
                      ? (data.dateleft | date: 'MM/dd/yyyy')
                      : 'N/A'
                  }}
                </td>
                <td class="tab-value">{{ data.differenceBtwDates }}</td>
              </tr>
            </tbody>
          </table>
          <div
            class="single-wrap d-flex justify-content-center"
            *ngIf="responseData !== null && responseData?.length > 0"
          >
            <nav aria-label="Page navigation example">
              <pagination-controls
                class="my-pagination"
                id="joblist"
                (pageChange)="Applicationpage = $event"
                previousLabel=""
                nextLabel=""
              >
              </pagination-controls>
            </nav>
          </div>
          <div *ngIf="responseDataExists">
            <p *ngIf="responseDataExists"></p>
            <h6>No existing records Found</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-loading [show]="isLoading" [config]="{ backdropBorderRadius: '0' }">
    <!--[show]="loading"  -->
  </ngx-loading>
</div>
