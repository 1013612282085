<div
  class="modal fade"
  id="archiveOvertimeModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="archiveApplicationModalLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
    style="max-width: 80%"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="archiveApplicationModalLabel">
          Archive All
        </h5>
        <button
          type="button"
          class="close backbutton"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>
      <br />

      <nav class="navbar navbar-expand-lg navbar-light bg-light applicationcss">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto"></ul>
          <form class="form-inline my-2 my-lg-0">
            <label class="select-label" for="1">Overtime Group </label>

            <div class="text-right">
              <input
                type="button"
                class="backbutton"
                value="Back To Main Search"
                (click)="MainNavigate()"
              />&nbsp;
              <input
                type="button"
                class="backbutton"
                value="Back To Detail Search"
                (click)="clear()"
              />&nbsp;
            </div>
          </form>
        </div>
        <br />
      </nav>

      <div class="div-color">
        <div style="width: 100%">
          <table class="table" style="width: 100%">
            <th><b>Name</b></th>
            <th><b>Employee Id</b></th>
            <th><b>Department</b></th>
            <th><b>Classification</b></th>
            <th><b>Shift</b></th>
            <th><b>AccHours</b></th>
            <th><b>OT Group</b></th>
            <th><b>Rep Group</b></th>
            <tr>
              <td>
                {{
                  demographicData?.lasT_NAME
                    ? demographicData?.lasT_NAME
                    : 'N/A'
                }},&nbsp;{{
                  demographicData?.firsT_NAME
                    ? demographicData?.firsT_NAME
                    : 'N/A'
                }}&nbsp;{{
                  demographicData?.middle ? demographicData?.middle : ''
                }}
              </td>
              <td>{{ demographicData?.employeeid }}</td>
              <td>{{ demographicData?.dptdescription }}</td>
              <td>{{ demographicData?.jobcodedescription }}</td>
              <td>{{ demographicData?.shiftdescription }}</td>
              <td>{{ accHours | number: '1.2-2' }}</td>
              <td>{{ demographicData?.overtimegrpname }}</td>
              <td>{{ demographicData?.representationgrpname }}</td>
            </tr>
          </table>
        </div>
      </div>

      <!-- filer -->
      <div>
        <div>
          <div class="modal-body">
            <h6
              class="alert alert-danger"
              *ngIf="dateArchived1MonthAgoLaterCheck"
            >
              You have entered an archived date that is earlier than a month ago
              or a later than a month from now. If this was not an error please
              override.
            </h6>
            <h6
              class="alert alert-danger"
              *ngIf="isDateOfferedLessThanDateArchived"
            >
              A record that you're trying to archive for overtime group has a
              date entered that is after the archived date that you entered. You
              must enter a date archived that is same or after the date offered.
            </h6>
            <h6 class="alert alert-danger" *ngIf="!isOvertimeGroupNameChecked">
              You must choose all the Overtime Group from which to archive
              records.
            </h6>
            <h6 class="alert alert-info" *ngIf="userData.length === 0">
              This employee does not have any active records for any overtime
              groups at this time.
            </h6>

            <table
              class="overtime-preview-table"
              *ngIf="userData !== null && userData.length > 0"
            >
              <thead>
                <tr class="overtime-preview-table-tr">
                  <th
                    class="overtime-preview-table-th"
                    style="font-weight: bold"
                  >
                    Overtime Group Name
                  </th>
                  <th
                    class="overtime-preview-table-th"
                    style="font-weight: bold"
                  >
                    Overtime Records
                  </th>
                  <!-- <th class="overtime-preview-table-th" style="width: 10px;">Paid Hours</th>
                                    <th class="overtime-preview-table-th" style="width: 15px;">Overtime Type</th>
                                    <th class="overtime-preview-table-th" style="width: 15px;">Overtime Reason</th>
                                    <th class="overtime-preview-table-th" style="width: 10px;">Overtime Status</th>
                                    <th class="overtime-preview-table-th" style="width: 15px;">Date Offered</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let val of overtimeGroupList; let i = index">
                  <td
                    class="overtime-preview-table-td"
                    style="vertical-align: top !important"
                  >
                    <input
                      type="checkbox"
                      (change)="checkthisGroupRecords(val)"
                    />
                    {{ val }}
                  </td>
                  <td>
                    <table
                      class="overtime-preview-table"
                      *ngIf="userData !== null && userData.length > 0"
                    >
                      <thead>
                        <tr class="overtime-preview-table-tr">
                          <th class="overtime-preview-table-th">
                            Overtime Date
                          </th>
                          <th class="overtime-preview-table-th">Paid Hours</th>
                          <th class="overtime-preview-table-th">
                            Overtime Type
                          </th>
                          <th class="overtime-preview-table-th">
                            Overtime Reason
                          </th>
                          <th class="overtime-preview-table-th">
                            Overtime Status
                          </th>
                          <th class="overtime-preview-table-th">
                            Date Offered
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let item of filterOvertimeRecordsByGroup(val)"
                        >
                          <td class="overtime-preview-table-td">
                            {{ item.dateofwork ? item.dateofwork : 'N/A' }}
                          </td>
                          <td class="overtime-preview-table-td">
                            {{ item.paidhours | number: '1.2-2' }}
                          </td>
                          <td class="overtime-preview-table-td">
                            {{
                              item.overtimetypedescription
                                ? item.overtimetypedescription
                                : 'N/A'
                            }}
                          </td>
                          <td class="overtime-preview-table-td">
                            {{
                              item.overtimereasondescription
                                ? item.overtimereasondescription
                                : 'N/A'
                            }}
                          </td>
                          <td class="overtime-preview-table-td">
                            {{ item.status ? item.status : 'N/A' }}
                          </td>
                          <td class="overtime-preview-table-td">
                            {{ item.dateoffered ? item.dateoffered : 'N/A' }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- <table class="overtime-preview-table" *ngIf="userData!==null && userData.length > 0">
                                <thead>
                                    <tr class="overtime-preview-table-tr">
                                        <th class="overtime-preview-table-th"><input type="checkbox" [(ngModel)]="checkAllRecords"
                                            (change)="selectAllEmployee()">Overtime Group
                                        </th>
                                        <th class="overtime-preview-table-th">Overtime Date</th>
                                        <th class="overtime-preview-table-th">Paid Hours</th>
                                        <th class="overtime-preview-table-th">OverTime Type</th>
                                        <th class="overtime-preview-table-th">OverTime Reason</th>
                                        <th class="overtime-preview-table-th">OverTime Status</th>
                                        <th class="overtime-preview-table-th">DateOffered</th>
                                    </tr>
                                </thead>
                                <tbody>                                   
                                    <tr *ngFor="let val of userData, let i = index">
                                        <td class="overtime-preview-table-td"> <input type="checkbox" [(ngModel)]="val.isselected">
                                            {{val.overtimegroupname}}
                                        </td>
                                        <td class="overtime-preview-table-td">{{val.dateofwork?val.dateofwork: 'N/A'}}</td>
                                        <td class="overtime-preview-table-td">{{val.paidhours?val.paidhours: 'N/A'}}</td>
                                        
                                        <td class="overtime-preview-table-td">{{val.overtimetypedescription?val.overtimetypedescription:'N/A'}}</td>
                                        <td class="overtime-preview-table-td">{{val.overtimereasondescription?val.overtimereasondescription:'N/A'}}</td>
                                        <td class="overtime-preview-table-td">{{val.status?val.status: 'N/A'}}</td>
                                        <td class="overtime-preview-table-td">{{val.dateoffered?val.dateoffered: 'N/A'}}</td>
                                                                               
                                    </tr> 
                                </tbody>
                            </table> -->
            <br />

            <div class="row" *ngIf="userData !== null && userData.length > 0">
              <label class="col-sm-2 col-form-label">Date Archived *:</label>
              <div class="col-sm-4">
                <input
                  type="date"
                  class="form-control"
                  [(ngModel)]="datearchived"
                  (change)="onChangeArchiveDate($event)"
                />
              </div>
            </div>

            <div *ngIf="dateArchived1MonthAgoLaterCheck" class="form-group row">
              <label class="col-sm-2 col-form-label">Date Override *:</label>
              <div class="col-sm-4">
                <h6 class="alert alert-danger">
                  You have entered an archived date that is earlier than a month
                  ago or a later than a month from now. If this was not an error
                  please override.
                </h6>
                <input
                  type="checkbox"
                  class="form-control"
                  [(ngModel)]="checkboxOverride"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            style="height: 40px; width: 150px; border-radius: 10px"
            type="button"
            class="btn btn-secondary popupbuttonscancel cancel-btn"
            #closeCorrectModel
            data-dismiss="modal"
            #closeArchiveModal
            (click)="clear()"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary" (click)="archiveAll()">
            Archive
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
