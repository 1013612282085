import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToolAndDiePlantService } from 'src/app/Services/Applications/ToolAndDie/tool-and-die-plant.service';
import { ViewChild, ElementRef } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { MSALserviceService } from 'src/app/Services/MSALservice/msalservice.service';
import { FacilitiesServicePlantService } from 'src/app/Services/Applications/FacilitiesAndService/facilities-service-plant.service';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { UserRoles } from 'src/enum/userroles';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';

@Component({
  selector: 'app-cancel-facilites-service',
  templateUrl: './cancel-facilites-service.component.html',
  styleUrls: ['./cancel-facilites-service.component.css']
})
export class CancelFacilitesServiceComponent implements OnInit {
  @Input() selectedEmp: any;
  @Input() employeeForm: any;
  @Input() userInformation: any;
  @ViewChild('closeCancelModal') closeModal!: ElementRef;
  empSelected: any;
  firstName: string = '';
  lastName: string = '';
  dateForm!: FormGroup;
  editorName: any;
  empAppId: any;
  lastupdater: string = '';
  empSignDate: any;
  modalId: string | any;
  empsign: any;
  applicationSelected: any;

  //currentDate = new Date();
  maxCancelDate: string = '';
  userRole: UserRoles = UserRoles.SKILLEDUSER; // Enum for userrole

  @Output() isSucess = new EventEmitter();
  @Input() isLoggedInUserAdmin: boolean = false;
  currentDate = new Date();
  constructor(private fb: FormBuilder,
    private td: ToolAndDiePlantService,
    private fs: FacilitiesServicePlantService,
    private appCom: MSALserviceService,
    private authService: MsalService,
    private _commonApplicationService: CommonService,
    public _userInfo: UsergroupService,
    private _sweetAlert: SweetAlertService
  ) {  
    this.getUserInfo();
  }
  ngOnInit(): void {
    let loggedInUser = this.parseJwt(this.appCom.getAuthToken())
    this.editorName = loggedInUser.name;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.CreateCancelForm();
    this.LoadApplicationData(changes);
  }

  CreateCancelForm() {
    this.dateForm = this.fb.group(
      {
        appId: [''],
        AppStatusDate: ['', Validators.required],
        appType: ['150'],
        LastUpdatedBy: [''],
      },
    );
  }

  LoadApplicationData(changes: SimpleChanges) {
    this.applicationSelected =
      (changes.selectedEmp && changes.selectedEmp.currentValue) || '';
    if (this.applicationSelected === '') {
      this.applicationSelected =
        (changes.selectedEmp && changes.selectedEmp.previousValue) || '';
    }
    this.empSignDate = this.applicationSelected.empSignDate;
    this.removeAfterChar(this.applicationSelected.appStatusCode);

    this.maxCancelDate = this._commonApplicationService.formatDate(this.currentDate);
    this.dateForm.get('appId')?.patchValue(this.applicationSelected.appId);
    this.dateForm.get('AppStatusCode')?.patchValue(this.applicationSelected.appStatusCode);
    this.dateForm.get('LastUpdatedBy')?.patchValue(this.userInformation?.userName);
    // this.getCurrentClassification(this.applicationSelected.atid);
    if (this.isLoggedInUserAdmin === false) {
      this.dateForm.get('AppStatusDate')?.patchValue(this.currentDate);
    }
    else {
      this.dateForm.get('AppStatusDate')?.patchValue(this._commonApplicationService.formatDate(this.currentDate));
    }
  }

  clear() {
    //this.dateForm.reset();
    this.closeModal.nativeElement.click();
  }
  confirmCancel() {
    //this.confirm();
    this.fs.Cancel150Application(this.dateForm.value).subscribe(
      (data: any) => {
        this.clear();
        this.isSucess.emit(true);
        this._sweetAlert.successAlert('center','success','The Application has been Cancelled Successfully.',false,1500);
      },
      (error) => {
      }

    );
  }

  parseJwt(token: any) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let empsigndate = group.controls[from];
      let appstatusdate = group.controls[to];
      if (new Date(empsigndate?.value) > new Date(appstatusdate?.value)) {
        return {
          dates: "Status date shouldn't be earlier than Employee sign date",
        };
      }
      return {};
    };
  }

  removeAfterChar(value: string) {
    let resultString = '';
    if (value != null && value != '') {
      let tempString = value.split('[')[0];
      resultString = tempString.trim();
    }
    if (resultString.indexOf('CLOSED') !== -1) {
      let tempString = value.split(' -')[0];
      resultString = tempString.trim();
    }
  }
  getUserInfo(): void {
    let currentAccount = this.authService.instance.getActiveAccount();
    this.lastupdater = currentAccount?.username
      ? currentAccount?.username
      : this.lastupdater;
  }

}
