<div *ngIf="selectedEmp" class="modal fade" id="cancelApplicationModal" tabindex="-1" role="dialog" aria-labelledby="cancelApplicationModalLabel" aria-hidden="true">
    <div class="modal-dialog add-form-alignment" role="document">
        <div class="modal-content" style="position: fixed; width: 945px">
            <div class="modal-header">
                <h5 class="modal-title" id="cancelApplicationModalLabel">Cancel Application</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body" style="padding-left: 80px; padding-right: 80px">
                <form [formGroup]="dateForm">
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Employee Name:</label>
                        <div class="col-sm-8">
                            {{ selectedEmp.lastName ? selectedEmp.lastName : "N/A" }},&nbsp;{{ selectedEmp.firstName ? selectedEmp.firstName : "N/A" }}&nbsp;{{ selectedEmp.middleName ? selectedEmp.middleName : "" }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Employee Id:</label>
                        <div class="col-sm-8">
                            {{selectedEmp.employeeId?selectedEmp.employeeId: 'N/A'}}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Current Classification:</label>
                        <div class="col-sm-8">
                            {{usersData?.jobCodeId?usersData?.jobCodeId: 'N/A' }} - {{usersData?.jobCodeDescription?usersData.jobCodeDescription : 'N/A'}}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Current Department:</label>
                        <div class="col-sm-8">
                            {{selectedEmp.deptId?selectedEmp.deptId: 'N/A' }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Current Shift:</label>
                        <div class="col-sm-8">
                            {{selectedEmp.shiftDescription?selectedEmp.shiftDescription: 'N/A' }}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Classification Applied For:</label>
                        <div class="col-sm-8">
                            {{selectedEmp.jobCodeId?selectedEmp.jobCodeId: 'N/A'}} - {{selectedEmp.jobCodeDescription}}
                        </div>
                    </div>
                    <div *ngIf="isLoggedInUserAdmin && !showCancelButton">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Date Of Cancel:</label>
                            <div class="col-sm-8">
                                <input hidden readonly readonly type="email" formControlName="appId" class="form-control">
                                <input type="date" class="form-control" formControlName="AppStatusDate" value="{{currentDate | date:'yyyy-MM-dd'}}">
                                <!-- <label>{{currentDate | date}}</label> -->
                            </div>
                        </div>

                    </div>
                    <div *ngIf="isLoggedInUserAdmin && !showCancelButton">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Comment:</label>
                            <div class="col-sm-8">
                                <input hidden readonly readonly type="email" formControlName="AppStatusDate" class="form-control">
                                <textarea formControlName="Comments" class="form-control"></textarea>
                            </div>
                        </div>

                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label"> Status:</label>
                        <div class="col-sm-8">
                            {{selectedEmp.appStatusCode? selectedEmp.appStatusCode : 'N/A'}}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Status Date:</label>
                        <div class="col-sm-8">
                            {{selectedEmp.appStatusDate? (selectedEmp.appStatusDate | date:'MM/dd/yyyy'): 'N/A'}}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Entered By:</label>
                        <div class="col-sm-8">
                            {{editorName}}
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Date Entered:</label>
                        <div class="col-sm-8">
                            {{selectedEmp.empSignDate? (selectedEmp.empSignDate | date:'MM/dd/yyyy'): 'N/A'}}
                        </div>
                    </div>
                    <div *ngIf="!isLoggedInUserAdmin">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label">Date Of Cancel:</label>
                            <div class="col-sm-8">
                                <input hidden readonly readonly type="email" formControlName="appId" class="form-control">
                                <input hidden type="date" class="form-control" formControlName="AppStatusDate" value="{{currentDate | date:'yyyy-MM-dd'}}">
                                <label>{{currentDate | date: "MM/dd/yyyy"}}</label>
                            </div>
                        </div>
                    </div>
                    <!-- <table class="table table-borderless mt-2 mb-2 info-table">

                        <tbody>
                            <tr>
                                <td class='tab-label'>Employee Name:</td>
                                <td class='tab-value'>{{selectedEmp.firstName?selectedEmp.firstName: 'N/A'}}, {{selectedEmp.lastName?selectedEmp.lastName: 'N/A'}}</td>
                            </tr>
                            <tr>
                                <td class='tab-label'>ATID:</td>
                                <td class='tab-value'>{{selectedEmp.atid?selectedEmp.atid: 'N/A'}}</td>
                            </tr>
                            <tr>
                                <td class='tab-label'>Current Classification:</td>
                                <td class='tab-value'>{{usersData?.jobCodeId?usersData?.jobCodeId: 'N/A' }} - {{usersData?.jobCodeDescription?usersData.jobCodeDescription : 'N/A'}}</td>
                            </tr>
                            <tr>
                                <td class='tab-label'>Current Department:</td>
                                <td class='tab-value'>{{selectedEmp.deptId?selectedEmp.deptId: 'N/A' }} - {{selectedEmp.deptDescription?selectedEmp.deptDescription : 'N/A'}}</td>
                            </tr>
                            <tr>
                                <td class='tab-label'>Current Shift:</td>
                                <td class='tab-value'>{{selectedEmp.shiftDescription?selectedEmp.shiftDescription: 'N/A' }}</td>
                            </tr>
                            <tr>
                                <td class='tab-label'>Classification Applied For:</td>
                                <td class='tab-value'>{{selectedEmp.jobCodeId?selectedEmp.jobCodeId: 'N/A'}} - {{selectedEmp.jobCodeDescription}}</td>
                            </tr>
                            <tr *ngIf="isLoggedInUserAdmin && !showCancelButton">
                                <td class='tab-label'>Date Of Cancel:</td>
                                <td class='tab-value'>
                                    <input hidden readonly readonly type="email" formControlName="AppStatusDate" class="form-control">
                                    
                                    <input type="date"  class="form-control" formControlName="AppStatusDate" max = {{CalenderBlockedAfterTodayDate}}>
                                    <div *ngIf="dateForm.controls['AppStatusDate'].invalid && dateForm.controls['AppStatusDate'].touched">
                                        <small class="text-danger" *ngIf="dateForm.controls['AppStatusDate'].errors?.required">
                                            Cancel date is required.
                                        </small>
                                    </div>
                                    <label class="alert alert-danger" *ngIf="dateForm.errors">{{
                                        dateForm.errors?.dates}}</label>                                   
                                </td>                               
                            </tr>
                            <tr *ngIf="isLoggedInUserAdmin && !showCancelButton">
                                <td class='tab-label'>Comment:</td>
                                <td class='tab-value'>
                                    <input hidden readonly readonly type="email" formControlName="AppStatusDate" class="form-control">                        
                                    <textarea formControlName="Comments"  class="form-control"></textarea>                                                          
                                </td>                               
                            </tr>
                            <tr>
                                <td class='tab-label'>Status:</td>
                                <td class='tab-value'>{{selectedEmp.appStatusCode? selectedEmp.appStatusCode : 'N/A'}}
                                </td>
                            </tr>
                            <tr>
                                <td class='tab-label'>Status Date:</td>
                                <td class='tab-value'>{{selectedEmp.appStatusDate? (selectedEmp.appStatusDate | date:'MM/dd/yyyy'): 'N/A'}}
                                </td>

                            </tr>
                            <tr>
                                <td class='tab-label'>Entered By:</td>
                                <td class='tab-value'>{{editorName}}</td>

                            </tr>
                            <tr>
                                <td class='tab-label'>Date Entered:</td>
                                <td class='tab-value'>{{selectedEmp.empSignDate? (selectedEmp.empSignDate | date:'MM/dd/yyyy'): 'N/A'}}</td>

                            </tr>
                             <tr>
                                <td class='tab-label'>Last Updated By:</td>
                                <td class='tab-value'>{{selectedEmp.lastUpdateBy?selectedEmp.lastUpdateBy: 'N/A'}}</td>

                            </tr>

                            <tr>
                                <td class='tab-label'>Date of Last Update:</td>
                                <td class='tab-value'>{{selectedEmp.lastUpdateDate? (selectedEmp.lastUpdateDate | date) : 'N/A'}}
                                </td>

                            </tr> 
                            <tr *ngIf="!isLoggedInUserAdmin">
                                <td class='tab-label'>Date Of Cancel:</td>
                                <td class='tab-value'>
                                    <input hidden readonly readonly type="email" formControlName="appId"
                                        class="form-control">
                                    <input hidden type="date" class="form-control" formControlName="AppStatusDate"
                                        value="{{currentDate | date:'yyyy-MM-dd'}}">
                                        <label>{{currentDate | date}}</label>
                                </td>
                            </tr>                       
                        </tbody>
                    </table> -->
                </form>
                <div *ngIf="showCancelButton">
                    <small class="text-danger">The application is no longer "Open", it cannot be cancelled.</small>
                </div>
            </div>

            <div class="modal-footer" style="padding-right: 80px">
                <button style="
                height: 40px;
                width: 150px;
                border-radius: 10px;
                
              " type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" (click)="clear()" #closeCancelModal> Cancel </button>
                <button style="
                height: 40px;
                width: 150px;
                border-radius: 10px;
                
              " *ngIf="!showCancelButton" type="button" class="btn btn-primary" (click)="confirmCancel()">Cancel
                    Application</button>
            </div>

            <!-- <div class="modal-footer" style="padding-right: 80px">
                <button style="
                height: 40px;
                width: 150px;
                border-radius: 10px;
                
              " type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" (click)="clear()" #closeCancelModal> Cancel </button>
                <button style="
                height: 40px;
                width: 150px;
                border-radius: 10px;
                
              " *ngIf="!showCancelButton" type="button" class="btn btn-primary" [disabled]="dateForm.errors || dateForm.invalid" (click)="confirmCancel()">Cancel
                    Application</button>
            </div> -->
        </div>
    </div>
</div>