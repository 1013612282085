import { HttpErrorResponse } from '@angular/common/http';
import {
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepartmentDataService } from 'src/app/Services/TableMaintenance/DepartmentData/department-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-department-data',
  templateUrl: './edit-department-data.component.html',
  styleUrls: ['./edit-department-data.component.css'],
})
export class EditDepartmentDataComponent implements OnInit, OnChanges {
  @ViewChild('closeCorrectModel') closeModal!: ElementRef;
  @Input() selectedDepartment: any;
  @Output() isSucess = new EventEmitter();
  departmentSelected: any;
  deptForm!: FormGroup;
  @Input() userName: any;
  Regions: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];
  Plants: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];
  Status: any[] = [
    {
      data: [
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
        {
          statusType: 'A',
          statusName: 'Active',
        },
      ],
      default: 'Select One',
    },
  ];
  Nio: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];
  constructor(private fb: FormBuilder, private cds: DepartmentDataService) {}

  ngOnInit(): void {
    this.getRegions();
    this.getPlants();
  }

  getRegions() {
    this.cds.getRegions().subscribe((res) => {
      this.Regions[this.Regions.length - 1].data = res;
    });
  }

  getPlants() {
    this.cds.getPlants('A').subscribe((res) => {
      this.Plants[this.Plants.length - 1].data = res;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.createForm();
    this.loadData(changes);
  }

  createForm() {
    this.deptForm = this.fb.group({
      DeptId: [],
      DeptDescription: ['', [Validators.required]],
      RegionId: ['', [Validators.required]],
      Status: ['', [Validators.required]],
      SpeedCode: ['', [Validators.required]],
      PlantId: ['', [Validators.required]],
      LastUpdateBy: [this.userName],
    });
  }

  loadData(changes: SimpleChanges) {
    this.departmentSelected =
      (changes.selectedDepartment && changes.selectedDepartment.currentValue) ||
      '';
    if (this.departmentSelected === '') {
      this.departmentSelected =
        (changes.selectedDepartment &&
          changes.selectedDepartment.previousValue) ||
        '';
    }
    this.deptForm.get('DeptId')?.patchValue(this.departmentSelected.deptId);
    this.deptForm
      .get('DeptDescription')
      ?.patchValue(this.departmentSelected.deptDescription);
    this.deptForm.get('RegionId')?.patchValue(this.departmentSelected.regionId);
    this.deptForm
      .get('SpeedCode')
      ?.patchValue(this.departmentSelected.speedCode);
    this.deptForm.get('Status')?.patchValue(this.departmentSelected.status);
    this.deptForm.get('PlantId')?.patchValue(this.departmentSelected.plantId);
  }

  clear() {
    this.closeModal.nativeElement.click();
    this.createForm();
    this.loadCancelledData();
  }

  submitDepartment() {
    this.cds.editDepartment(this.deptForm.value).subscribe(
      () => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 500) {
          this.badRequestAlert(_error.error);
        } else {
          this.validationError(_error.error);
        }
      }
    );
  }

  loadCancelledData() {
    this.deptForm.get('DeptId')?.patchValue(this.departmentSelected.deptId);
    this.deptForm
      .get('DeptDescription')
      ?.patchValue(this.departmentSelected.deptDescription);
    this.deptForm.get('RegionId')?.patchValue(this.departmentSelected.regionId);
    this.deptForm
      .get('SpeedCode')
      ?.patchValue(this.departmentSelected.speedCode);
    this.deptForm.get('Status')?.patchValue(this.departmentSelected.status);
    this.deptForm.get('PlantId')?.patchValue(this.departmentSelected.plantId);
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Department has been updated successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
