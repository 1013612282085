
<div class="modal fade" id="editEmp" tabindex="-1" role="dialog" aria-labelledby="addEmployeeLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addEmployeeLabel">Edit Zone Data</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="clear()">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="ZoneDataForm">

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Zone ID</label>
                        <div class="col-sm-8">
                                {{zoneSelected.zoneid}}
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Description</label>
                        <div class="col-sm-8">
                            <textarea type="text" formControlName="ZONEDESCRIPTION" class="form-control"></textarea>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Employee Id of Representative:*</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="REPRESENTATIVE_EMPLOYEEID" class="form-control">
                            <div *ngIf="ZoneDataForm.controls['REPRESENTATIVE_EMPLOYEEID'].invalid && ZoneDataForm.controls['REPRESENTATIVE_EMPLOYEEID'].touched">
                                <small class="text-danger" *ngIf="ZoneDataForm.controls['REPRESENTATIVE_EMPLOYEEID'].errors?.required">
                                    You must specify the EMPLOYEEID of representative
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Representative Contact no</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="REPRESENTATIVEPHONE" class="form-control">
                            <small class = "text-danger" *ngIf="ZoneDataForm.controls['REPRESENTATIVEPHONE'].errors?.representativePhoneFormatError">
                                You must enter the entire representative's phone number in the format "xyz-abcd" or leave it blank.
                            </small>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Status*</label>
                        <div class="col-sm-8">
                            <select formControlName="STATUS" class="form-control">
                                <option *ngIf="Status[Status.length-1].default" selected value="">
                                    {{Status[Status.length-1].default}}</option>
                                <option *ngFor="let data of Status[Status.length-1].data" value={{data.statusType}}>
                                    {{data.statusName}}
                                </option>
                            </select>
                            <div *ngIf="ZoneDataForm.controls['STATUS'].invalid && ZoneDataForm.controls['STATUS'].touched">
                                <small class="text-danger" *ngIf="ZoneDataForm.controls['STATUS'].errors?.required">
                                    You must specify a valid status
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Entered By: </label>
                        <div class="col-sm-8">
                            {{zoneSelected.createdby}}
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Date Entered: </label>
                        <div class="col-sm-8">
                            {{zoneSelected.createdate}}
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Last Updated By</label>
                        <div class="col-sm-8">
                            {{zoneSelected.lastupdateby}}
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Date of Last Update</label>
                        <div class="col-sm-8">
                            {{zoneSelected.lastupdatedate | date:'short'}}
                        </div>
                    </div>

                </form>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" #closeCorrectModel (click)="clear()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]="ZoneDataForm.errors || ZoneDataForm.invalid" (click)="submitZoneData()">Update</button>
            </div>
        </div>
    </div>
</div>
