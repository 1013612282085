<div class="container-fluid app-box" style="background-color: white">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol *ngIf="!isRRView" class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Transfer Applications</li>
        <li class="breadcrumb-item brd-li brd-li-child">Recall Rights</li>
      </ol>
      <ol *ngIf="isRRView" class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Views</li>
        <li class="breadcrumb-item brd-li brd-li-child">
          View All Recall Rights
        </li>
      </ol>
    </nav>
    <div
      style="float: right; position: absolute; right: 276px"
      *ngIf="applicationData !== null && applicationData.length > 0"
    >
      <button
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        style="height: 34px; margin-left: 15px"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
    </div>
  </div>

  <div *ngIf="isAdmin() || isRRView">
    <div class="row content-container mt-2 mb-2">
      <nav
        *ngIf="!isRRView"
        class="navbar navbar-expand-lg navbar-light bg-light applicationcss"
      >
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto"></ul>
          <form class="form-inline my-2 my-lg-0">
            <input
              class="select-radio"
              checked="checked"
              name="tool-and-die"
              type="radio"
              Id="1"
              (change)="onAppTypeSelected(1)"
            />
            <label class="select-label" for="1">Existing Application</label>
            <input
              *ngIf="views[0].allowAdd"
              class="select-radio"
              name="tool-and-die"
              type="radio"
              Id="2"
              (change)="onAppTypeSelected(2)"
            />
            <label *ngIf="views[0].allowAdd" class="select-label" for="2"
              >Add Application</label
            >
          </form>
        </div>
        <br />
      </nav>

      <!-- global search filter starts -->
      <div class="pt-2 div-color">
        <div class="search-divider-div filtercss">
          <p class="search-divder-p"></p>
          <hr class="search-divider-hr" />
          <!-- filer -->
          <div
            class="jumbotron jumb-new jumb-newcontrols"
            style="margin-bottom: 0px !important; padding: 1rem 2rem"
            *ngIf="masterSearchFilter"
          >
            <div class="row" display="float">
              <div class="col-md-10">
                <app-filter
                  [filterConfig]="filterConfig"
                  (formcontrolobj)="getbyenter($event)"
                  (filterValue)="getFilterValue($event)"
                >
                </app-filter>
              </div>
              <div class="col-md-1 mr-5" style="padding-right: 80px">
                <a
                  class="btn btn-lg search-button"
                  (click)="searchApplication()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <div
            class="jumbotron jumb-new"
            style="margin-bottom: 0px !important; padding: 1rem 2rem"
            *ngIf="!masterSearchFilter"
          >
            <div class="row" display="float">
              <div class="col">
                <app-filter
                  [filterConfigAdd]="filterConfigAdd"
                  (formcontrolobj)="getbyenterforuser($event)"
                  (filterValue)="getFilterDataUser($event)"
                >
                </app-filter>
              </div>
              <div class="col-md-1 mr-5">
                <a
                  class="btn btn-lg search-button"
                  role="button"
                  (click)="searchUser()"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <!-- filter ends -->
        </div>
        <div class="container-divider-div"></div>
        <div class="" style="margin-top: 5px">
          <!--For application-->

          <div *ngIf="isApplicationData">
            <!-- <div style="margin-top: 0px; margin-bottom: 1px; position: sticky; top: 94px;
                        height: 50px; background-color: white; padding-top: 10px; padding-bottom: 10px;"
                         *ngIf="applicationData !==null && applicationData   .length > 0">
                            <button style="margin-right: 0.5rem;border-color: #003AA0;" class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0" (click)="exportElmToExcel()">Export to Excel</button>
                        </div> -->
            <!-- <div *ngIf="!isRRView" class='info-label   divcrudbtns'>
                            <p style="text-align: left;" class="p-margin">
                                <div *ngIf='views[0].allowUpdate'>
                                    <button [disabled]="disablededit" [ngClass]="disablededit ? 'disablededitcolors' : ''" *ngIf="isApplicationSelected() && !EditButtonDisabled || (!EditButtonDisabled1)" data-toggle="modal" (click)="edit()" [attr.data-target]="modalId" style="margin-right: 0.5rem"
                                        class="btn buttoncolours crudbuttons  pull-right my-2 my-sm-0">Edit
                                </button>
                                </div>
                                <div *ngIf='views[0].allowDelete'>
                                    <button [disabled]="disableddelete" [ngClass]="disableddelete ? 'disablededitcolors' : ''" *ngIf="isApplicationSelected() || !DeleteButtonDisabled1" data-toggle="modal" (click)="deleteApplication()" [attr.data-target]="modalId" style="margin-right: 0.5rem"
                                        class="btn buttoncolours crudbuttons  pull-right my-2 my-sm-0">Delete
                                </button>
                                </div>
                                <div *ngIf='views[0].allowCancel'>
                                    <button [disabled]="disabledcancel" [ngClass]="disabledcancel ? 'disablededitcolors' : ''" *ngIf="!CancelButtonDisabled && !returnCancelledstatus && isApplicationSelected()  || (!CancelButtonDisabled1)" data-toggle="modal" (click)="cancelApplication()"
                                        [attr.data-target]="modalId" style="margin-right: 0.5rem" class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0">Cancel
                                </button>
                                </div>

                        </div> -->
            <!-- <div *ngIf="isRRView" style="height: 10px;
          background: white;
          position: sticky;
          top: 95px;"></div> -->
            <!-- this is export to excel -->
            <table
              class="table table-striped"
              *ngIf="
                !isDataExportableView &&
                applicationData !== null &&
                applicationData.length > 0
              "
              #ViewRecallRightsExportTable
            >
              <thead *ngIf="!isRRView" class="table-header">
                <!-- <tr>
                    <th *ngIf="isRRView" class="table-height" style="border-right: 2px solid #f9f9f9;" colspan="6" scope="col">Employee Information</th>
                    <th *ngIf="!isRRView" class="table-height" style="border-right: 2px solid #f9f9f9;" colspan="5" scope="col">Employee Information</th>
                    <th class="table-height" colspan="3" scope="col">Application Information</th>
                </tr> -->
                <tr>
                  <!-- <th *ngIf="!isRRView" scope="col">Select</th> -->
                  <th scope="col">Name 111</th>
                  <th scope="col">Employee Id</th>
                  <th scope="col">Plant Seniority Date</th>
                  <th *ngIf="isRRView" scope="col">GM Seniority Date</th>
                  <th *ngIf="!isRRView" scope="col">GM Seniority Date</th>
                  <th *ngIf="isRRView" scope="col">Plant Skill Date</th>
                  <th *ngIf="isRRView" scope="col">GM Skill Date</th>
                  <th scope="col">Classification</th>
                  <th scope="col">Date Signed</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <thead *ngIf="isRRView" class="table-headerRRview">
                <!-- <tr>
                    <th *ngIf="isRRView" class="table-height" style="border-right: 2px solid #f9f9f9;" colspan="6" scope="col">Employee Information</th>
                    <th *ngIf="!isRRView" class="table-height" style="border-right: 2px solid #f9f9f9;" colspan="5" scope="col">Employee Information</th>
                    <th class="table-height" colspan="3" scope="col">Application Information</th>
                </tr> -->
                <tr>
                  <!-- <th *ngIf="!isRRView" scope="col">Select</th> -->
                  <th scope="col">Name 121</th>
                  <th scope="col">Employee Id</th>
                  <th scope="col">Plant Seniority Date</th>
                  <th *ngIf="isRRView" scope="col">GM Seniority Date</th>
                  <th *ngIf="!isRRView" scope="col">GM Seniority Date</th>
                  <th *ngIf="isRRView" scope="col">Plant Skill Date</th>
                  <th *ngIf="isRRView" scope="col">GM Skill Date</th>
                  <th scope="col">Classification</th>
                  <th scope="col">Date Signed</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <!-- <thead style="position:sticky;top:330px;" class="table-header">

            </thead> -->
              <tbody>
                <tr
                  *ngFor="
                    let emp of applicationData
                      | paginate
                        : {
                            id: 'joblist',
                            itemsPerPage: 20,
                            currentPage: Applicationpage,
                            totalItems: TotalApplicationCount
                          };
                    let i = index
                  "
                  class="table-container"
                >
                  <td *ngIf="!isRRView" style="text-align: center">
                    <input
                      type="radio"
                      id="select"
                      name="application"
                      (click)="selectEmp(i, emp.employeeId, emp.appId)"
                    />
                  </td>

                  <td class="left-tab-value">
                    {{ emp.lastName ? emp.lastName : '-' }},&nbsp;{{
                      emp.firstName ? emp.firstName : '-'
                    }}&nbsp;{{ emp.middleName ? emp.middleName : '' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.employeeId ? emp.employeeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{
                      emp.plantSeniorityDate
                        ? (emp.plantSeniorityDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      emp.seniorityDate
                        ? (emp.seniorityDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td *ngIf="isRRView" class="tab-value">
                    {{
                      emp.plantSkillDate
                        ? (emp.plantSkillDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td *ngIf="isRRView" class="tab-value">
                    {{
                      emp.skillDate ? (emp.skillDate | date: 'MM/dd/yyyy') : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{ emp.jobCodeId ? emp.jobCodeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.empSignDate | date: 'MM/dd/yyyy' }}
                  </td>
                  <td class="left-tab-value">
                    {{ emp.appStatusCode ? emp.appStatusCode : '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- this is the main table -->
            <table
              class="table table-striped"
              *ngIf="applicationData !== null && applicationData.length > 0"
            >
              <thead *ngIf="!isRRView" class="table-header">
                <tr>
                  <th
                    *ngIf="isRRView"
                    class="table-height"
                    style="border-right: 2px solid #f9f9f9"
                    colspan="6"
                    scope="col"
                  >
                    Employee Information
                  </th>
                  <th
                    *ngIf="!isRRView"
                    class="table-height"
                    style="border-right: 2px solid #f9f9f9"
                    colspan="5"
                    scope="col"
                  >
                    Employee Information
                  </th>
                  <th class="table-height" colspan="3" scope="col">
                    Application Information
                  </th>
                </tr>
                <tr>
                  <th *ngIf="!isRRView" scope="col">Select</th>
                  <th scope="col">Name 131</th>
                  <th scope="col">Employee Id</th>
                  <th scope="col">Plant Seniority Date</th>
                  <th *ngIf="isRRView" scope="col">GM Seniority Date</th>
                  <th *ngIf="!isRRView" scope="col">GM Seniority Date</th>
                  <th *ngIf="isRRView" scope="col">Plant Skill Date</th>
                  <th *ngIf="isRRView" scope="col">GM Skill Date</th>
                  <th scope="col">Classification</th>
                  <th scope="col">Date Signed</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <thead *ngIf="isRRView" class="table-headerRRview">
                <!-- <tr>
                                    <th *ngIf="isRRView" class="table-height" style="border-right: 2px solid #f9f9f9;" colspan="6" scope="col">Employee Information</th>
                                    <th *ngIf="!isRRView" class="table-height" style="border-right: 2px solid #f9f9f9;" colspan="5" scope="col">Employee Information</th>
                                    <th class="table-height" colspan="3" scope="col">Application Information</th>
                                </tr> -->
                <tr>
                  <th *ngIf="!isRRView" scope="col">Select</th>
                  <!--
                                    <th scope="col">Name 11</th>
                                    <th scope="col">Employee Id</th>
                                    <th scope="col">Plant Seniority Date</th>
                                    <th *ngIf="isRRView" scope="col">GM Seniority Date</th>
                                    <th *ngIf="!isRRView" scope="col">GM Seniority Date</th>
                                    <th *ngIf="isRRView" scope="col">Plant Skill Date</th>
                                    <th *ngIf="isRRView" scope="col">GM Skill Date</th>
                                    <th scope="col">Classification</th>
                                    <th scope="col">Date Signed</th>
                                    <th scope="col">Status</th> -->
                  <th scope="col">
                    <span (click)="onSortClick($event, 'lastName', 'string')"
                      >Name
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'lastName'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'lastName'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'lastName'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'employeeId', 'string')"
                      >Emp Id
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'employeeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'employeeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'employeeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" *ngIf="isRRView">
                    <span
                      (click)="
                        onSortClick(
                          $event,
                          'plantSeniorityDateSort',
                          'datetime'
                        )
                      "
                      >Plant Sen Date
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'plantSeniorityDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'seniorityDateSort', 'datetime')
                      "
                      >GM Sen Date
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'seniorityDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'seniorityDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'seniorityDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" *ngIf="isRRView">
                    <span
                      (click)="
                        onSortClick($event, 'plantSkillDateSort', 'datetime')
                      "
                      >Plant Skill Date
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'plantSkillDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'plantSkillDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'plantSkillDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" *ngIf="isRRView">
                    <span
                      (click)="onSortClick($event, 'skillDateSort', 'datetime')"
                      >GM Skill Date
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'skillDateSort'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'skillDateSort'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'skillDateSort'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'jobCodeId', 'string')"
                      >Class
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'jobCodeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'jobCodeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'jobCodeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'empSignDateSort', 'string')"
                      >Date Signed
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'empSignDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'appStatusCode', 'string')"
                      >Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'appStatusCode'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'appStatusCode'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'appStatusCode'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <!-- <thead style="position:sticky;top:330px;" class="table-header">

                            </thead> -->
              <tbody>
                <tr
                  *ngFor="
                    let emp of applicationData
                      | paginate
                        : {
                            id: 'joblist',
                            itemsPerPage: 20,
                            currentPage: Applicationpage,
                            totalItems: TotalApplicationCount
                          };
                    let i = index
                  "
                  class="table-container"
                >
                  <td *ngIf="!isRRView" style="text-align: center">
                    <input
                      type="radio"
                      id="select"
                      name="application"
                      (click)="selectEmp(i, emp.employeeId, emp.appId)"
                    />
                  </td>

                  <td class="left-tab-value">
                    {{ emp.lastName ? emp.lastName : '-' }},&nbsp;{{
                      emp.firstName ? emp.firstName : '-'
                    }}&nbsp;{{ emp.middleName ? emp.middleName : '' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.employeeId ? emp.employeeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{
                      emp.plantSeniorityDate
                        ? (emp.plantSeniorityDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      emp.seniorityDate
                        ? (emp.seniorityDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td *ngIf="isRRView" class="tab-value">
                    {{
                      emp.plantSkillDate
                        ? (emp.plantSkillDate | date: 'MM/dd/yyyy')
                        : '-'
                    }}
                  </td>
                  <td *ngIf="isRRView" class="tab-value">
                    {{
                      emp.skillDate ? (emp.skillDate | date: 'MM/dd/yyyy') : '-'
                    }}
                  </td>
                  <td class="tab-value">
                    {{ emp.jobCodeId ? emp.jobCodeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.empSignDate | date: 'MM/dd/yyyy' }}
                  </td>
                  <td class="left-tab-value">
                    {{ emp.appStatusCode ? emp.appStatusCode : '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="single-wrap d-flex justify-content-center"
              *ngIf="applicationData !== null && applicationData.length > 0"
            >
              <nav aria-label="Page navigation example">
                <pagination-controls
                  class="my-pagination"
                  id="joblist"
                  (pageChange)="Applicationpage = $event"
                  previousLabel=""
                  nextLabel=""
                >
                </pagination-controls>
              </nav>
            </div>
            <div *ngIf="isApplicationData">
              <p *ngIf="isApplicationDataEmpty">No existing records Found</p>
            </div>
          </div>
          <!--For user-->
          <div
            style="
              height: 15px;
              background: white;
              position: sticky;
              top: 145px;
            "
          ></div>
          <div *ngIf="!isApplicationData">
            <table
              class="table table-striped"
              *ngIf="userData !== null && userData.length > 0"
            >
              <thead
                class="table-header"
                style="position: sticky; top: 159px !important"
              >
                <th scope="col">Employee Name</th>
                <th scope="col">Employee Id</th>
                <th scope="col">Current Classification</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let emp of userData
                      | paginate
                        : {
                            id: 'userlist',
                            itemsPerPage: 20,
                            currentPage: Userpage,
                            totalItems: TotalUserCount
                          };
                    let i = index
                  "
                  class="table-container"
                >
                  <td class="left-tab-value">
                    <button
                      class="btn my-2 my-sm-0 click-add"
                      type="submit"
                      data-toggle="modal"
                      [attr.data-target]="modalId"
                      data-backdrop="false"
                      (click)="addApplication(i, emp.employeeId)"
                    >
                      {{ emp.lastName ? emp.lastName : '-' }},&nbsp;{{
                        emp.firstName ? emp.firstName : '-'
                      }}&nbsp;{{ emp.middleName ? emp.middleName : '' }}
                    </button>
                  </td>
                  <td class="tab-value">
                    {{ emp.employeeId ? emp.employeeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.jobCodeId ? emp.jobCodeId : '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="single-wrap d-flex justify-content-center"
              *ngIf="userData !== null && userData.length > 0"
            >
              <nav aria-label="Page navigation example">
                <pagination-controls
                  class="my-pagination"
                  id="userlist"
                  (pageChange)="Userpage = $event"
                  previousLabel=""
                  nextLabel=""
                ></pagination-controls>
              </nav>
            </div>
          </div>
          <div *ngIf="!isApplicationData">
            <p *ngIf="userData !== null && userData.length === 0">
              No User Found
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isAdmin() && !isRRView">
    <div
      *ngIf="userInformation !== undefined && userInformation !== null"
      class="row content-container mt-2 mb-2"
    >
      <!-- global search filter starts -->
      <div class="pt-2 div-color">
        <div class="search-divider-div">
          <p class="search-divder-p"></p>

          <div
            class="jumbotron jumb-new jumb-newcontrols"
            style="margin-bottom: 0px"
          >
            <div class="row userinfo-header-div" display="float">
              <div class="col">
                <h1 class="h1-label">
                  Welcome! {{ userInformation.firstName }}
                </h1>
              </div>
            </div>
          </div>
          <div class="empdescription-box">
            <div class="empdescription-table">
              <table>
                <thead class="user-table-header">
                  <th class="empdescription-th">Employee Id:</th>
                  <th class="empdescription-th">Current Classification:</th>
                  <th class="empdescription-th">Current Department:</th>
                  <th class="empdescription-th">Current Shift:</th>
                </thead>
                <tr *ngIf="usersData !== undefined">
                  <td class="empdescription-table-tr tab-value">
                    {{ usersData.employeeId }}
                  </td>
                  <td class="empdescription-table-tr tab-value">
                    {{ usersData.jobCodeId }} {{ usersData.jobCodeDescription }}
                  </td>
                  <td class="empdescription-table-tr tab-value">
                    {{ usersData.deptId }}
                  </td>
                  <td class="empdescription-table-tr tab-value">
                    {{ usersData.shiftDescription }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- filter ends -->
        </div>
        <div class="pt-5">
          <!--For application-->
          <div>
            <div class="info-label mb-2 divcrudbtns">
              <p style="text-align: left" class="p-margin">
                <button
                  *ngIf="
                    isApplicationSelected() &&
                    !returnCancelledstatus &&
                    applicationUawData !== undefined &&
                    applicationUawData.length > 0
                  "
                  class="btn btn-success buttoncolours pull-right my-2 my-sm-0"
                  data-toggle="modal"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                  (click)="cancelApplication()"
                >
                  Cancel
                </button>
              </p>
            </div>
            <table
              class="table table-striped"
              *ngIf="
                applicationUawData !== null && applicationUawData.length > 0
              "
            >
              <thead class="user-table-header">
                <th scope="col">#</th>
                <th scope="col">Applied for Classification</th>
                <th scope="col">Status</th>
                <th scope="col">Date Signed</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="let emp of applicationUawData; let i = index"
                  class="table-container"
                >
                  <td class="text-center">
                    <input
                      type="radio"
                      id="select"
                      name="application"
                      (click)="selectUserEmp(i)"
                    />
                  </td>
                  <td class="tab-value">
                    {{ emp.jobCodeId ? emp.jobCodeId : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.appStatusCode ? emp.appStatusCode : '-' }}
                  </td>
                  <td class="tab-value">
                    {{ emp.empSignDate | date: 'MM/dd/yyyy' }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="isApplicationData">
              <p
                *ngIf="
                  applicationUawData !== null && applicationUawData.length === 0
                "
              >
                Your requested application will display here. <br />
                Note: You can not edit your application.
              </p>
              <p
                class="text-danger"
                *ngIf="
                  applicationUawData !== null && applicationUawData.length === 0
                "
              >
                <b>You do not have any open application at this time.</b> <br />
                <small *ngIf="userRole === 'UAW Skilled'" class="text-success">
                  You must contact Employee Relations to apply for any skilled
                  classifications.
                </small>
                <small
                  *ngIf="userRole === 'UAW Production'"
                  class="text-success"
                >
                  You must contact Employee Relations to apply for any
                  production classifications.
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
