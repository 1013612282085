<div
  class="modal fade"
  id="addEmployee"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addEmployeeLabel"
  aria-hidden="true"
>
  <div class="modal-dialog add-form-alignment" role="document">
    <div class="modal-content" style="position: fixed; width: 945px">
      <div class="modal-header">
        <h5 class="modal-title" id="addEmployeeLabel">Add Application</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>

      <form [formGroup]="employeeForm">
        <div
          class="modal-body"
          style="padding-left: 80px; padding-right: 80px; height: 450px"
        >
          <div class="text-danger" *ngIf="errorlength > 0">
            <h6 *ngFor="let error of ruleMessages">*{{ error }}</h6>
          </div>
          <br />
          <div class="form-group row">
            <label for="inputPassword" class="col-sm-4 col-form-label"
              >Employee Name:</label
            >
            <div class="col-sm-8">
              <input
                readonly
                type="text"
                formControlName="employeeName"
                placeholder="Employee Name"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Employee ID:</label>
            <div class="col-sm-8">
              <input
                readonly
                formControlName="EmployeeId"
                type="text"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Current Classification *:</label
            >
            <div class="col-sm-8">
              <input
                readonly
                formControlName="currentClassification"
                type="text"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Current Department *:</label>
            <div class="col-sm-8">
              <input
                readonly
                formControlName="currentDepartment"
                type="text"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Current Shift *:</label>
            <div class="col-sm-8">
              <input
                readonly
                formControlName="currentShift"
                type="text"
                class="form-control"
              />
            </div>
          </div>

          <div *ngIf="UserRole === 'SKILLED'" class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Classification Applying For *:</label
            >
            <div class="col-sm-8">
              <select formControlName="JobCodeId" class="form-control">
                <option
                  *ngIf="
                    skilledClassification[skilledClassification.length - 1]
                      .default
                  "
                  selected
                  value=""
                >
                  {{
                    skilledClassification[skilledClassification.length - 1]
                      .default
                  }}
                </option>
                <option
                  *ngFor="
                    let class of skilledClassification[
                      skilledClassification.length - 1
                    ].data
                  "
                  value="{{ class.jobCodeId }}"
                >
                  {{ class.jobCodeId }} - {{ class.jobCodeDescription }}
                </option>
              </select>
              <small
                *ngIf="
                  employeeForm.controls['JobCodeId'].errors
                    ?.leaderClassificationError
                "
                class="text-danger"
                >The applied for classification cannot be a leader
                classification</small
              >
              <small
                *ngIf="
                  employeeForm.controls['JobCodeId'].errors
                    ?.apprenticeClassificationError
                "
                class="text-danger"
                >The applied for classification cannot be an apprentice
                classification</small
              >
              <div
                *ngIf="
                  employeeForm.controls['JobCodeId'].invalid &&
                  employeeForm.controls['JobCodeId'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['JobCodeId'].errors?.required"
                >
                  You must specify a valid classification.
                </small>
              </div>
            </div>
          </div>

          <div *ngIf="UserRole === 'PRODUCTION'" class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Classification Applying For *:</label
            >
            <div class="col-sm-8">
              <select formControlName="JobCodeId" class="form-control">
                <option
                  *ngIf="
                    prodClassifications[prodClassifications.length - 1].default
                  "
                  selected
                  value=""
                >
                  {{
                    prodClassifications[prodClassifications.length - 1].default
                  }}
                </option>
                <option
                  *ngFor="
                    let class of prodClassifications[
                      prodClassifications.length - 1
                    ].data
                  "
                  value="{{ class.jobCodeId }}"
                >
                  {{ class.jobCodeId }} - {{ class.jobCodeDescription }}
                </option>
              </select>
              <small
                *ngIf="
                  employeeForm.controls['JobCodeId'].errors
                    ?.leaderClassificationError
                "
                class="text-danger"
                >The applied for classification cannot be a leader
                classification</small
              >
              <small
                *ngIf="
                  employeeForm.controls['JobCodeId'].errors
                    ?.apprenticeClassificationError
                "
                class="text-danger"
                >The applied for classification cannot be an apprentice
                classification</small
              >
              <small
                *ngIf="
                  employeeForm.controls['JobCodeId'].errors
                    ?.appliedClassificationError
                "
                class="text-danger"
                >Cannot apply for current classification</small
              >
              <div
                *ngIf="
                  employeeForm.controls['JobCodeId'].invalid &&
                  employeeForm.controls['JobCodeId'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['JobCodeId'].errors?.required"
                >
                  You must specify a valid classification.
                </small>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Effective Date *:</label>
            <div class="col-sm-8">
              <input
                formControlName="empSignDate"
                type="date"
                class="form-control"
                max="{{ CalenderBlockedAfterTodayDate }}"
              />
            </div>
            <small
              *ngIf="
                employeeForm.controls['empSignDate'].errors?.empSignDateError
              "
              class="text-danger"
              >Employee sign date cannot be greater than today's date</small
            >
            <div
              *ngIf="
                employeeForm.controls['empSignDate'].invalid &&
                employeeForm.controls['empSignDate'].touched
              "
            >
              <small
                class="text-danger"
                *ngIf="employeeForm.controls['empSignDate'].errors?.required"
              >
                You must specify a valid effective date.
              </small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Comments:</label>
            <div class="col-sm-8">
              <textarea
                formControlName="Comments"
                class="form-control"
              ></textarea>
            </div>
          </div>
          <div class="text-danger" *ngIf="medicalRestrictionList.length > 0">
            <h6>WARNING: This employee has a current medical restriction</h6>
            <ul>
              <li *ngFor="let medicalRestriction of medicalRestrictionList">
                {{ medicalRestriction }}
              </li>
            </ul>
          </div>
        </div>
        <div class="form-group col-md-12 pr-0">
          <div class="modal-footer">
            <button
              style="height: 40px; width: 150px; border-radius: 10px"
              type="button"
              class="btn btn-secondary popupbuttonscancel cancel-btn mr-3"
              data-dismiss="modal"
              #closeAddModel
              (click)="clear()"
            >
              Cancel
            </button>
            <button
              style="height: 40px; width: 150px; border-radius: 10px"
              type="button"
              class="btn btn-primary add-btn"
              [disabled]="employeeForm.invalid || !StatusCode"
              (click)="addEmployee()"
            >
              Add
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
