import { Component, HostListener, OnDestroy } from '@angular/core';
//import { userInfoModel } from '../../Models/Users/User';
import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserInfo } from 'src/models/userinfo';
import { MSALserviceService } from './Services/MSALservice/msalservice.service';
import { RoleMatchService } from './Services/role-match.service';
import { UsergroupService } from './Services/usergroup.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnDestroy {
  userInformation!: UserInfo; //tbd

  moduleIcons = [
    {
      index: 0,
      modulecode: 'ADMIN',
      logo: '../assets/ModuleIcons/admin.svg',
      isClicked: false,
    },
    {
      index: 1,
      modulecode: 'APPLICATIONS',
      logo: '../assets/ModuleIcons/Application.svg',
      isClicked: false,
    },
    {
      index: 2,
      modulecode: 'OVERTIME',
      logo: '../assets/ModuleIcons/Overtime.svg',
      isClicked: false,
    },
    {
      index: 3,
      modulecode: 'TABLEMAINTENANCE',
      logo: '../assets/ModuleIcons/tablemaintanence.svg',
      isClicked: false,
    },
    {
      index: 4,
      modulecode: 'VIEWS',
      logo: '../assets/ModuleIcons/View.svg',
      isClicked: false,
    },
    {
      index: 5,
      modulecode: 'REPORTS',
      logo: '../assets/ModuleIcons/tablemaintanence.svg',
      isClicked: false,
    },
  ];

  applicationSubNav = [
    {
      title: 'Tool and Die Maker Plant Transfers (150)',
      routerLink: '/tool-die-plant',
      logo: '../assets/Tool and die.svg',
      isSelected: false,
    },
    {
      title: 'Facility Service Plant Transfers (150)',
      routerLink: '/facilities-service-transfer-plant',
      logo: '../assets/Facilities Plant transfer.svg',
      isSelected: false,
    },
    {
      title: 'Production to Skilled Transfer (178)',
      routerLink: '/production-to-skilled',
      logo: '../assets/Production to Skill.svg',
      isSelected: false,
    },
    {
      title: 'Transfer Agreement (Production Only)',
      routerLink: '/transfer-agreement',
      logo: '../assets/Transfer agreement.svg',
      isSelected: false,
    },
    {
      title: 'Skilled to Skilled Transfer (152)',
      routerLink: '/skilled-to-skilled',
      logo: '../assets/Skill transfer.svg',
      isSelected: false,
    },
    {
      title: 'Shift Preference',
      routerLink: '/shift-preference',
      logo: '../assets/Shift preference.svg',
      isSelected: false,
    },
    {
      title: 'Recall Rights',
      routerLink: '/recall-rights',
      logo: '../assets/Recall rights.svg',
      isSelected: false,
    },
    {
      title: 'Paragraph 19',
      routerLink: '/paragraph19',
      logo: '../assets/Paragraph 19.svg',
      isSelected: false,
    },
  ];

  OvertiimeSubNav = [
    {
      title: 'Overtime',
      routerLink: '/Overtime',
      logo: '../assets/Overtime/Overtime.svg',
      isSelected: false,
    },
    {
      title: 'Overtime Reassign History',
      routerLink: '/OvertimeReassignGroupMoves',
      logo: '../assets/Overtime/Overtime.svg',
      isSelected: false,
    },
  ];

  TableMaintenanceSubNav = [
    {
      title: 'Representation Group Data',
      routerLink: '/RepresentationGroupData',
      logo: '../assets/TableMaintainance/Representation Data.svg',
      isSelected: false,
    },
    {
      title: 'Application Types Data',
      routerLink: '/ApplicationType',
      logo: '../assets/TableMaintainance/Application Type Data.svg',
      isSelected: false,
    },
    {
      title: 'Classification Data',
      routerLink: '/ClassificationData',
      logo: '../assets/TableMaintainance/Classification Data.svg',
      isSelected: false,
    },
    {
      title: 'Demographic Data',
      routerLink: '/DemographicData',
      logo: '../assets/TableMaintainance/Demographic Data.svg',
      isSelected: false,
    },
    {
      title: 'NIO Groups Data',
      routerLink: '/NIOGroupsData',
      logo: '../assets/TableMaintainance/NIO Group Data.svg',
      isSelected: false,
    },
    {
      title: 'Plant Data',
      routerLink: '/PlantData',
      logo: '../assets/TableMaintainance/Plant Data.svg',
      isSelected: false,
    },
    {
      title: 'Shift Data',
      routerLink: '/ShiftData',
      logo: '../assets/TableMaintainance/Shift Data.svg',
      isSelected: false,
    },
    {
      title: 'Regions Data',
      routerLink: '/RegionsData',
      logo: '../assets/TableMaintainance/Region Data.svg',
      isSelected: false,
    },
    {
      title: 'Shift Preference Data',
      routerLink: '/ShiftPreferenceData',
      logo: '../assets/TableMaintainance/Shift Preference Data.svg',
      isSelected: false,
    },
    {
      title: 'District Data',
      routerLink: '/DistrictData',
      logo: '../assets/TableMaintainance/District Data.svg',
      isSelected: false,
    },
    {
      title: 'Overtime Type Data',
      routerLink: '/OvertimeTypeData',
      logo: '../assets/TableMaintainance/Overtime Type Data.svg',
      isSelected: false,
    },
    {
      title: 'Zone Data',
      routerLink: '/ZoneData',
      logo: '../assets/TableMaintainance/Zone Data.svg',
      isSelected: false,
    },
    {
      title: 'Department Data',
      routerLink: '/DepartmentData',
      logo: '../assets/TableMaintainance/Department Data.svg',
      isSelected: false,
    },
    {
      title: 'Overtime Group Data',
      routerLink: '/OvertimeGroupData',
      logo: '../assets/TableMaintainance/Overtime Group Data.svg',
      isSelected: false,
    },
    {
      title: 'Overtime Reason Data',
      routerLink: '/OvertimeReasonData',
      logo: '../assets/TableMaintainance/Overtime Reason Data.svg',
      isSelected: false,
    },
    {
      title: 'Holiday Data',
      routerLink: '/HolidayData',
      logo: '../assets/Overtime/Overtime.svg',
      isSelected: false,
    },
  ];

  ViewsSubNav = [
    {
      title: 'Demographic View',
      routerLink: '/DemographicView',
      logo: '../assets/Views/Demographic view.svg',
      isSelected: false,
    },
    {
      title: 'View Seniority',
      routerLink: '/ViewSeniority',
      logo: '../assets/Views/View seniority.svg',
      isSelected: false,
    },
    {
      title: 'Overtime View',
      routerLink: '/OvertimeView',
      logo: '../assets/Views/Overtime view.svg',
      isSelected: false,
    },
    {
      title: 'Medical Restrictions View',
      titleCode: 'MEDICALRESTRICTIONSVIEW',
      routerLink: '/MedicalRestrictions',
      logo: '../assets/Views/Medical restriction.svg',
      isSelected: false,
    },
    {
      title: 'View All Transfer Agreements',
      titleCode: 'VIEWALLTRANSFERAGREEMENTS',
      routerLink: '/TransferAgreementView',
      logo: '../assets/Views/Transfer agreement.svg',
      isSelected: false,
    },
    {
      title: 'View All Shift Preferences',
      titleCode: 'VIEWALLSHIFTPREFERENCES',
      routerLink: '/ShiftPreferenceView',
      logo: '../assets/Views/Shift preferences.svg',
      isSelected: false,
    },
    {
      title: 'Overtime Allocation Summary',
      titleCode: 'OVERTIMEALLOCATIONSUMMARY',
      routerLink: '/ViewOvertimeAllocationSummary',
      logo: '../assets/Views/Over time allocation.svg',
      isSelected: false,
    },
    {
      title: 'View All 150 Applications',
      titleCode: 'VIEWALL150APPLICATIONS',
      routerLink: '/ViewAll150Applications',
      logo: '../assets/Views/150 application.svg',
      isSelected: false,
    },
    {
      title: 'View All Recall Rights',
      titleCode: 'ViewAllRecallRights',
      routerLink: '/ViewAllRecallRights',
      logo: '../assets/Views/View recall rights.svg',
      isSelected: false,
    },
    {
      title: 'Transfer Applications View',
      titleCode: 'VIEWALLTRANSFERAPPLICATIONS',
      routerLink: '/TransferApplicationsView',
      logo: '../assets/Views/Transfer application.svg',
      isSelected: false,
    },
    {
      title: 'View All My Applications',
      titleCode: 'VIEWALLMYAPPLICATIONS',
      routerLink: '/ViewAllMyApplications',
      logo: '../assets/Views/Transfer application.svg',
      isSelected: false,
    },
  ];

  AdminSubNav = [
    {
      title: 'Committee Permissions',
      titleCode: 'ADMINISTERGROUPS',
      routerLink: '/AdministerGroupsCommitee',
      logo: '../assets/ModuleIcons/Adminitrator.svg',
      isSelected: false,
    },

    {
      title: 'Committee with Medical Restrictions Permissions ',
      titleCode: 'ADMINISTERGROUPSWITHMEDICALRES',
      routerLink: '/AdministerGroupscommitteeMedRes',
      logo: '../assets/ModuleIcons/Adminitrator.svg',
      isSelected: false,
    },
    {
      title: 'Announcements',
      titleCode: 'ANNOUNCEMENTS',
      routerLink: '/Announcements',
      logo: '../assets/TableMaintainance/Region Data.svg',
      isSelected: false,
    },
  ];

  ReportsSubNav = [
    {
      title: 'Accepted vs Rejected',
      titleCode: 'OVERTIMEACCEPTEDREJECTED',
      routerLink: '/OvertimeAnalytics',
      logo: '../assets/ModuleIcons/Adminitrator.svg',
      isSelected: false,
    },
    {
      title: 'Action Code Changes',
      titleCode: 'ACTIONCODECHANGE',
      routerLink: '/ActionCodeChanges',
      logo: '../assets/ModuleIcons/Adminitrator.svg',
      isSelected: false,
    },
  ];

  selectedUser: any;
  isSelected: any;
  title = 'm-ATI-UAWCAS-WEB';
  userName: string = 'User Full Name';
  role: string = 'User Role';
  showUserName: boolean = false;

  constructor(
    public router: Router,
    public _rs: RoleMatchService,
    public _userInfo: UsergroupService,
    public _msal: MSALserviceService
  ) {
    setTimeout(() => {
      this._msal.isToken();
      this._msal.AutoLogout();
      this.getUserInfo();
    }, 3000);
    this._msal.userInactive.subscribe((message) => {
      alert(message);
    });
  }

  ngOnDestroy(): void {
    localStorage.removeItem('Modules');
  }

  isModuleSelected(index: any) {
    for (var i = 0; i < this.newDataSet.length; i++) {
      this.newDataSet[i].isClicked = false;
      if (this.newDataSet[i].index === index) {
        this.newDataSet[i].isClicked = true;
      } else {
        this.newDataSet[i].isClicked = false;
      }
    }
  }

  /*Screen Listener for Session Authentication*/
  subscribeThis: boolean = false;
  @HostListener('click') checkIfCursorClicked() {
    this.subscribeThis = true;
    this._msal.getActivitybool(this.subscribeThis);
  }

  @HostListener('window:keyup') checkIfKeyDown() {
    this.subscribeThis = true;
    this._msal.getActivitybool(this.subscribeThis);
  }

  @HostListener('mousemove') mouseMove() {
    localStorage.removeItem('movement');
    localStorage.setItem(
      'movement',
      JSON.stringify(formatDate(new Date(), 'MM-dd-yyyy hh:mm:ss a', 'en-US'))
    );
    this._msal.InactivityTimeout();
    this.subscribeThis = true;
    this._msal.getActivitybool(this.subscribeThis);
  }

  @HostListener('mouseleave') checkIfCursorLeaves() {
    this.subscribeThis = false;
    this._msal.getActivitybool(this.subscribeThis);
  }

  /*Side Nav based on user login cred*/
  newDataSet: any = [];
  getUserInfo() {
    this._userInfo.getUserInfo().subscribe(
      (data: any) => {
        this.userInformation = data;
        this.userName = data['fullName'];
        if (this.userName) {
          this.showUserName = true;
        }
        this.role = data['groupDescription'];
        this.newDataSet = data['moduels'];
        localStorage.setItem('Modules', JSON.stringify(data['moduels']));
        this.setLastLogin(data['userName'], data['employeeId']);
        for (var N = 0; N < this.newDataSet.length; N++) {
          for (var icons = 0; icons < this.moduleIcons.length; icons++) {
            if (
              this.newDataSet[N].moduelCode ===
              this.moduleIcons[icons].modulecode
            ) {
              this.newDataSet[N].moduleLogo = this.moduleIcons[icons].logo;
              this.newDataSet[N].isClicked = this.moduleIcons[icons].isClicked;
              this.newDataSet[N].index = this.moduleIcons[icons].index;
            }
          }
          for (var k = 0; k < this.newDataSet[N].views.length; k++) {
            for (var j = 0; j < this.applicationSubNav.length; j++) {
              if (
                this.applicationSubNav[j].title ===
                this.newDataSet[N].views[k].viewName
              ) {
                this.newDataSet[N].views[k].viewRouterName =
                  this.applicationSubNav[j].routerLink;
                this.newDataSet[N].views[k].viewLogo =
                  this.applicationSubNav[j].logo;
              }
            }

            for (var l = 0; l < this.OvertiimeSubNav.length; l++) {
              if (
                this.OvertiimeSubNav[l].title ===
                this.newDataSet[N].views[k].viewName
              ) {
                this.newDataSet[N].views[k].viewRouterName =
                  this.OvertiimeSubNav[l].routerLink;
                this.newDataSet[N].views[k].viewLogo =
                  this.OvertiimeSubNav[l].logo;
              }
            }

            for (var M = 0; M < this.TableMaintenanceSubNav.length; M++) {
              if (
                this.TableMaintenanceSubNav[M].title ===
                this.newDataSet[N].views[k].viewName
              ) {
                this.newDataSet[N].views[k].viewRouterName =
                  this.TableMaintenanceSubNav[M].routerLink;
                this.newDataSet[N].views[k].viewLogo =
                  this.TableMaintenanceSubNav[M].logo;
              }
            }

            for (var Y = 0; Y < this.ViewsSubNav.length; Y++) {
              if (
                this.ViewsSubNav[Y].title ===
                this.newDataSet[N].views[k].viewName
              ) {
                this.newDataSet[N].views[k].viewRouterName =
                  this.ViewsSubNav[Y].routerLink;
                this.newDataSet[N].views[k].viewLogo = this.ViewsSubNav[Y].logo;
              }
            }

            for (var z = 0; z < this.AdminSubNav.length; z++) {
              if (
                this.AdminSubNav[z].titleCode ===
                this.newDataSet[N].views[k].viewCode
              ) {
                this.newDataSet[N].views[k].viewRouterName =
                  this.AdminSubNav[z].routerLink;
                this.newDataSet[N].views[k].viewLogo = this.AdminSubNav[z].logo;
                this.newDataSet[N].views[k].viewName =
                  this.AdminSubNav[z].title;
              }
            }

            for (var p = 0; p < this.ReportsSubNav.length; p++) {
              if (
                this.ReportsSubNav[p].titleCode ===
                this.newDataSet[N].views[k].viewCode
              ) {
                this.newDataSet[N].views[k].viewRouterName =
                  this.ReportsSubNav[p].routerLink;
                this.newDataSet[N].views[k].viewLogo =
                  this.ReportsSubNav[p].logo;
                this.newDataSet[N].views[k].viewName =
                  this.ReportsSubNav[p].title;
              }
            }
          }
        }
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 500) {
          this.logout();
        }
      }
    );
  }

  /*Sets User's Last login to the table */
  setLastLogin(LoggedInUserAtid: any, EmployeeId?: any) {
    const Last_Login_time = new Date(
      JSON.stringify(localStorage.getItem('SessionStart'))
    );
    const lastloginObj = {
      ATID: LoggedInUserAtid,
      PERSONNEL_NUM: EmployeeId ? EmployeeId : 0,
      LAST_LOGIN_DATE: Last_Login_time,
    };
    this._userInfo.SetLastLogin(lastloginObj).subscribe();
  }

  logout() {
    localStorage.removeItem('Modules');
    this._msal.logout();
  }
}
