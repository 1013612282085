<div class="container-fluid app-box">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Views</li>
        <li class="breadcrumb-item brd-li brd-li-child">Demographic View</li>
      </ol>
    </nav>

    <div
      style="float: right; position: absolute; right: 276px"
      *ngIf="
        demographicViewSearchData !== null &&
        demographicViewSearchData.length > 0
      "
    >
      <button
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        style="margin-left: 15px"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
    </div>
  </div>

  <div>
    <div class="row content-container mt-2 mb-2">
      <div class="pt-2 div-color">
        <div class="search-divider-div filtercss">
          <p class="search-divder-p">
            <!-- <hr class="search-divider-hr" /> -->
          </p>

          <div class="jumbotron jumb-new jumb-newcontrols">
            <div
              class="row"
              display="float"
              style="top: 100px; position: sticky"
            >
              <div class="col">
                <app-filter
                  [filterConfig]="filterConfig"
                  (filterValue)="getFilterValue($event)"
                  (formcontrolobj)="getbyenter($event)"
                >
                </app-filter>
              </div>
              <div class="col-md-1 mr-5">
                <a
                  class="btn btn-lg search-button position-btn"
                  (click)="searchDemographic()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <!-- Button Start-->
          <!-- <div style="margin-top: 8px;margin-bottom: 0px; background-color: white; padding-top: 10px;
                        padding-bottom: 5px; height: 47px;" *ngIf="demographicViewSearchData !==null && demographicViewSearchData   .length > 0">
                            <button style="margin-right: 0.5rem;border-color: #003AA0;" class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0" (click)="exportElmToExcel()">Export to Excel</button>
                        </div> -->
          <!-- Button End-->
        </div>
        <div class="container-divider-div"></div>
        <div>
          <table
            class="table table-striped"
            *ngIf="
              !isDataExportableView &&
              demographicViewSearchData !== null &&
              demographicViewSearchData.length > 0
            "
            #DemographicViewExportTable
          >
            <thead class="table-header position">
              <tr>
                <th scope="col" style="width: 160px">
                  <span (click)="onSortClick($event, 'lastName', 'string')"
                    >Name
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'lastName'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'lastName'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'lastName'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'employeeid', 'string')"
                    >Emp Id
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'employeeid'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'employeeid'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'employeeid'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col" style="width: 110px">
                  <span (click)="onSortClick($event, 'atin', 'string')"
                    >ATIN
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'atin'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'atin'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'atin'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>

                <th scope="col">
                  <span (click)="onSortClick($event, 'atid', 'string')"
                    >ZID
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'atid'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'atid'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'atid'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>

                <th scope="col" style="width: 80px">
                  <span (click)="onSortClick($event, 'emp_Status', 'string')"
                    >Action
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'emp_Status'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'emp_Status'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'emp_Status'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>

                <th scope="col">
                  <span (click)="onSortClick($event, 'jobCodeId', 'string')"
                    >Class
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'jobCodeId'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'jobCodeId'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'jobCodeId'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'deptId', 'string')"
                    >Dept
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'deptId'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'deptId'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'deptId'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'shiftDescription', 'string')"
                    >Shift
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'shiftDescription'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'shiftDescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'shiftDescription'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'rate', 'string')"
                    >Rate
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'rate'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'rate'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'rate'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col" style="width: 140px">
                  <span (click)="onSortClick($event, 'teL_NUM', 'string')"
                    >Home Phone
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'teL_NUM'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'teL_NUM'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'teL_NUM'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>

                <!-- <th scope="col" style="width: 160px;">Name</th> -->
                <!-- <th scope="col">Emp. Id</th>
                                <th scope="col"style="width: 110px;" >ATIN</th>
                                <th scope="col">ZID</th>
                                <th scope="col" style="width: 70px;">Action</th>
                                <th scope="col">Classification</th>
                                <th scope="col">Department</th>
                                <th scope="col">Shift</th>
                                <th scope="col">Rate</th>
                                <th scope="col" style="width: 140px;">Home Phone #</th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let data of demographicViewSearchData; let i = index"
                class="table-container"
              >
                <td class="left-tab-value" style="width: 160px">
                  <p
                    class="btn my-2 my-sm-0 click-add text-danger"
                    *ngIf="data.flag === '1'"
                    data-toggle="modal"
                    [attr.data-target]="modalId"
                    data-backdrop="false"
                    (click)="selectRecord(data.employeeid)"
                  >
                    {{ data.lastName ? data.lastName : 'N/A' }},
                    {{ data.firstName ? data.firstName : 'N/A' }}&nbsp;{{
                      data.middle ? data.middle : ''
                    }}*
                  </p>
                  <p
                    class="btn my-2 my-sm-0 click-add"
                    *ngIf="data.flag === '0'"
                    data-toggle="modal"
                    [attr.data-target]="modalId"
                    data-backdrop="false"
                    (click)="selectRecord(data.employeeid)"
                  >
                    {{ data.lastName ? data.lastName : 'N/A' }},
                    {{ data.firstName ? data.firstName : 'N/A' }}&nbsp;{{
                      data.middle ? data.middle : ''
                    }}
                  </p>
                </td>
                <td class="tab-value">{{ data.employeeid | uppercase }}</td>
                <td class="tab-value" style="width: 110px">{{ data.atin }}</td>
                <td class="tab-value">{{ data.atid ? data.atid : '-' }}</td>
                <td class="tab-value" style="width: 70px">
                  {{ data.emp_Status }}
                </td>
                <td class="tab-value">
                  {{ data.jobCodeId ? data.jobCodeId : '-' }}
                </td>
                <td class="tab-value">{{ data.deptId ? data.deptId : '-' }}</td>
                <td class="tab-value">{{ data.shiftDescription }}</td>
                <td class="tab-value">${{ data.rate | number: '.2-2' }}</td>
                <td class="tab-value custon-padding" style="width: 140px">
                  {{ data.teL_NUM ? data.teL_NUM : '-' }}
                </td>
              </tr>
            </tbody>
          </table>

          <table
            class="table table-striped"
            *ngIf="
              demographicViewSearchData !== null &&
              demographicViewSearchData.length > 0
            "
          >
            <thead class="table-header position">
              <tr>
                <th scope="col" style="width: 160px">
                  <span (click)="onSortClick($event, 'lastName', 'string')"
                    >Name
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'lastName'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'lastName'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'lastName'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'employeeid', 'string')"
                    >Emp Id
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'employeeid'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'employeeid'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'employeeid'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col" style="width: 110px">
                  <span (click)="onSortClick($event, 'atin', 'string')"
                    >ATIN
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'atin'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'atin'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'atin'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>

                <th scope="col">
                  <span (click)="onSortClick($event, 'atid', 'string')"
                    >ZID
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'atid'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'atid'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'atid'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>

                <th scope="col" style="width: 80px">
                  <span (click)="onSortClick($event, 'emp_Status', 'string')"
                    >Action
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'emp_Status'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'emp_Status'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'emp_Status'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>

                <th scope="col">
                  <span (click)="onSortClick($event, 'jobCodeId', 'string')"
                    >Class
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'jobCodeId'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'jobCodeId'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'jobCodeId'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'deptId', 'string')"
                    >Dept
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'deptId'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'deptId'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'deptId'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span
                    (click)="onSortClick($event, 'shiftDescription', 'string')"
                    >Shift
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'shiftDescription'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'shiftDescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'shiftDescription'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col">
                  <span (click)="onSortClick($event, 'rate', 'string')"
                    >Rate
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'rate'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'rate'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'rate'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th scope="col" style="width: 140px">
                  <span (click)="onSortClick($event, 'teL_NUM', 'string')"
                    >Home Phone
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'teL_NUM'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'teL_NUM'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'teL_NUM'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>

                <!-- <th scope="col" style="width: 160px;">Name</th> -->
                <!-- <th scope="col">Emp. Id</th>
                                <th scope="col"style="width: 110px;" >ATIN</th>
                                <th scope="col">ZID</th>
                                <th scope="col" style="width: 70px;">Action</th>
                                <th scope="col">Classification</th>
                                <th scope="col">Department</th>
                                <th scope="col">Shift</th>
                                <th scope="col">Rate</th>
                                <th scope="col" style="width: 140px;">Home Phone #</th> -->
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="
                  let data of demographicViewSearchData
                    | paginate
                      : {
                          id: 'joblist',
                          itemsPerPage: 20,
                          currentPage: Applicationpage,
                          totalItems: TotalApplicationCount
                        };
                  let i = index
                "
                class="table-container"
              >
                <td class="left-tab-value" style="width: 160px">
                  <p
                    class="btn my-2 my-sm-0 click-add text-danger"
                    *ngIf="data.flag === '1'"
                    data-toggle="modal"
                    [attr.data-target]="modalId"
                    data-backdrop="false"
                    (click)="selectRecord(data.employeeid)"
                  >
                    {{ data.lastName ? data.lastName : 'N/A' }},
                    {{ data.firstName ? data.firstName : 'N/A' }}&nbsp;{{
                      data.middle ? data.middle : ''
                    }}*
                  </p>
                  <p
                    class="btn my-2 my-sm-0 click-add"
                    *ngIf="data.flag === '0'"
                    data-toggle="modal"
                    [attr.data-target]="modalId"
                    data-backdrop="false"
                    (click)="selectRecord(data.employeeid)"
                  >
                    {{ data.lastName ? data.lastName : 'N/A' }},
                    {{ data.firstName ? data.firstName : 'N/A' }}&nbsp;{{
                      data.middle ? data.middle : ''
                    }}
                  </p>
                </td>
                <td class="tab-value">{{ data.employeeid | uppercase }}</td>
                <td class="tab-value" style="width: 110px">{{ data.atin }}</td>
                <td class="tab-value">{{ data.atid ? data.atid : '-' }}</td>
                <td class="tab-value" style="width: 70px">
                  {{ data.emp_Status }}
                </td>
                <td class="tab-value">
                  {{ data.jobCodeId ? data.jobCodeId : '-' }}
                </td>
                <td class="tab-value">{{ data.deptId ? data.deptId : '-' }}</td>
                <td class="tab-value">{{ data.shiftDescription }}</td>
                <td class="tab-value">${{ data.rate | number: '.2-2' }}</td>
                <td class="tab-value custon-padding" style="width: 140px">
                  {{ data.teL_NUM ? data.teL_NUM : '-' }}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="single-wrap d-flex justify-content-center"
            *ngIf="
              demographicViewSearchData !== null &&
              demographicViewSearchData.length > 0
            "
          >
            <nav aria-label="Page navigation example">
              <pagination-controls
                class="my-pagination"
                id="joblist"
                (pageChange)="Applicationpage = $event"
                previousLabel=""
                nextLabel=""
              >
              </pagination-controls>
            </nav>
          </div>
          <div *ngIf="isApplicationDataEmpty">
            <p *ngIf="isApplicationDataEmpty">No existing records Found</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <app-demographic-detailed [selectedRecord]="selectedRecord" [showDetails]="showDetails" (clean)="cleanEmp($event)"
        (isSucess)="CheckDetails($event)" *ngIf="showDetails">
    </app-demographic-detailed> -->
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
