import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { OvertimeGroupDataService } from 'src/app/Services/TableMaintenance/OvertimeGroupData/overtime-group-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-overtime-group-data',
  templateUrl: './add-overtime-group-data.component.html',
  styleUrls: ['./add-overtime-group-data.component.css'],
})
export class AddOvertimeGroupDataComponent implements OnInit {
  @ViewChildren('checkboxes') checkboxes!: QueryList<ElementRef>;
  @ViewChild('closeAddModel') closeAddModal!: ElementRef;
  @Input() userName: any;
  @Output() isSucess = new EventEmitter();
  overtimeGroupForm!: FormGroup;
  enableOverride: boolean = false;
  disableAdd: boolean = false;
  classification: any[] = [];
  department: any[] = [];

  shifts: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];
  Status: any[] = [
    {
      data: [
        {
          statusType: 'A',
          statusName: 'Active',
        },
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
      ],
      default: 'Select One',
    },
  ];
  constructor(
    private fb: FormBuilder,
    private otg: OvertimeGroupDataService,
    private _commonApplicationService: CommonService
  ) {}

  ngOnInit(): void {
    this.createEmpForm();
    this.getClassification();
    this.getDepartment();
    this.getShifts();
  }
  createEmpForm() {
    this.overtimeGroupForm = this.fb.group({
      OvertimeGroupName: ['', [Validators.required]],
      OvertimeGroupDescription: ['', [Validators.required]],
      ShiftId: ['', [Validators.required]],
      Spread: ['', [Validators.required]],
      Status: ['', [Validators.required]],
      CreatedBy: [this.userName],
      DepartmentArray: this.fb.array([]),
      ClassificationArray: this.fb.array([]),
    });
  }
  getClassification() {
    this.otg.getClassifications().subscribe((data) => {
      this.classification = data;
    });
  }
  getDepartment() {
    this.otg.getDepartments().subscribe((data) => {
      this.department = data;
    });
  }
  getShifts() {
    this.otg.getShifts().subscribe((data) => {
      this.shifts[0].data = data;
    });
  }

  clear() {
    this.closeAddModal.nativeElement.click();
    this.createEmpForm();
    this.enableOverride = false;
    this.uncheckAll();
  }
  uncheckAll() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
  }

  submitOvertimeGroupData() {
    this.enableOverride = !this.enableOverride;
    this.otg.addOvertimeGroupData(this.overtimeGroupForm.value).subscribe(
      () => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 500) {
          this.badRequestAlert(_error.error);
        } else if (_error.status === 406) {
          this.validationError(_error.error);
        } else {
          this.badRequestAlert(_error.error);
        }
      }
    );
  }
  onClassCheckboxChange(e: any) {
    const ClassificationArray: FormArray = this.overtimeGroupForm.get(
      'ClassificationArray'
    ) as FormArray;
    if (e.target.checked) {
      ClassificationArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      ClassificationArray.controls.forEach((item: any) => {
        if (item.value === e.target.value) {
          ClassificationArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  onDeptCheckboxChange(e: any) {
    const DepartmentArray: FormArray = this.overtimeGroupForm.get(
      'DepartmentArray'
    ) as FormArray;
    if (e.target.checked) {
      DepartmentArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      DepartmentArray.controls.forEach((item: any) => {
        if (item.value === e.target.value) {
          DepartmentArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'The overtime group has been added successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }
  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }
  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
