import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { ShiftPreferenceService } from 'src/app/Services/Applications/ShiftPreference/shift-preference.service';
import { DateService } from 'src/app/Services/Date/date.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';

@Component({
  selector: 'app-shift-preference-view',
  templateUrl: './shift-preference-view.component.html',
  styleUrls: ['./shift-preference-view.component.css'],
})
export class ShiftPreferenceViewComponent implements OnInit {
  public loading = false;
  effectiveDate: any;
  ApplicationData: any = [];
  TotalApplicationCount: any;
  UserPage: number = 1;
  Applicationpage: number = 1;
  filterData: any = {};
  userInformation: any;
  views: any[] = [];
  isApplicationDataEmpty: boolean = false;
  filterConfig: any = [
    {
      label: 'Select Date',
      type: 'date',
      data: ['option 1'],
      default: 'All',
      controlName: 'toDate',
      width: '145px',
      runClassification: false,
    },
    {
      label: 'Classification',
      type: 'dropdown',
      data: [],
      placeholder: 'Classification',
      default: 'All',
      controlName: 'jobCodeId',
      width: '207px',
      runClassification: true,
    },
    {
      label: 'Shift Preference Group',
      type: 'dropdown',
      data: [],
      placeholder: 'Shift Preference Group:',
      default: 'All',
      controlName: 'plantid',
      width: '207px',
      runClassification: true,
    },
  ];

  constructor(
    private _user: UsergroupService,
    private _shiftApplicationPreference: ShiftPreferenceService,
    private _dateService: DateService,
    private _commonApplicationService: CommonService
  ) {}

  ngOnInit(): void {
    this.effectiveDate =
      this._dateService.getDateOfFirstMondayOfMonth(Date.now(), 0) <
      new Date(new Date().setHours(0, 0, 0, 0))
        ? this._dateService.getDateOfFirstMondayOfMonth(Date.now(), 1)
        : this._dateService.getDateOfFirstMondayOfMonth(Date.now(), 0);
    this.getPlantGroups();
    this.getClassifications();
    this.getUserInfo();
  }

  getPlantGroups() {
    this._commonApplicationService.GetPlantGroups().subscribe((res) => {
      const elements = res || [];
      const mapElement = elements.map(
        (element: { plantGroupId: any; plantGroupDescription: any }) => ({
          id: element.plantGroupId,
          description: element.plantGroupDescription,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Shift Preference Group') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  getClassifications() {
    this._commonApplicationService.GetClassifications().subscribe((res) => {
      const elements = res || [];
      const mapElement = elements.map(
        (element: {
          jobCodeId: any;
          jobCodeDescription: any;
          classificationType: string;
        }) => ({
          id: element.jobCodeId,
          description: element.jobCodeDescription,
          classificationType: element.classificationType,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Classification') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'VIEWS',
        'VIEWALLSHIFTPREFERENCES'
      );
    });
  }

  getFilterValue(event: any) {
    this.filterData = event;
  }

  EligibleSPApplications: any = [];
  searchShiftPreferenceApplications() {
    this.loading = true;
    let queryParams = this.filterData;
    Object.keys(queryParams).forEach(
      (k) =>
        !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]
    );
    queryParams.appType = 'SP';
    if (queryParams.toDate === null) {
      queryParams.toDate = this.effectiveDate;
    }
    queryParams.effectiveDate = this._dateService.getDateOfFirstMondayOfMonth(
      queryParams.toDate,
      0
    );
    this.effectiveDate = queryParams.effectiveDate;
    this.EligibleSPApplications.splice(0, this.EligibleSPApplications.length);
    this._shiftApplicationPreference
      .ShiftPreferenceSPSearchApplications(queryParams)
      .subscribe((data) => {
        this.ApplicationData = data;
        for (var i = 0; i < this.ApplicationData.length; i++) {
          const effectiveDate = this._dateService.calculateEffectiveDate(
            this.ApplicationData[i].empSignDate,
            this.ApplicationData[i].specialSP
          );
          this.ApplicationData[i].appStatusCodeHasOpen =
            this.ApplicationData[i].appStatusCode.includes('OPEN');
          this.ApplicationData[i].EffectiveDate = effectiveDate;
          /* if Regular application and empsigndate and effective date matches application effective date*/
          if (
            this.ApplicationData[i].specialSP.includes('0') &&
            this.ApplicationData[i].EffectiveDate.toString() ===
              new Date(queryParams.effectiveDate).toString()
          ) {
            this.EligibleSPApplications.push(this.ApplicationData[i]);
          }
          /* if special application and application is still open */
          if (
            this.ApplicationData[i].specialSP.includes('1') &&
            this.ApplicationData[i].appStatusCodeHasOpen
          ) {
            this.EligibleSPApplications.push(this.ApplicationData[i]);
          }
        }
        for (var i = 0; i < this.EligibleSPApplications.length; i++) {
          if (this.EligibleSPApplications[i].empSignDate != null) {
            this.EligibleSPApplications[i].empSignDateSort = new Date(
              this.EligibleSPApplications[i].empSignDate
                .replace(/-/g, '/')
                .replace(/T.+/, '')
            ).toISOString();
          }
          if (this.EligibleSPApplications[i].plantSeniorityDate != null) {
            this.EligibleSPApplications[i].plantSeniorityDateSort = new Date(
              this.EligibleSPApplications[i].plantSeniorityDate
                .replace(/-/g, '/')
                .replace(/T.+/, '')
            ).toISOString();
          }
          if (this.EligibleSPApplications[i].seniorityDate != null) {
            this.EligibleSPApplications[i].seniorityDateSort = new Date(
              this.EligibleSPApplications[i].seniorityDate
                .replace(/-/g, '/')
                .replace(/T.+/, '')
            ).toISOString();
          }
          if (this.EligibleSPApplications[i].plantSkillDate != null) {
            this.EligibleSPApplications[i].plantSkillDateSort = new Date(
              this.EligibleSPApplications[i].plantSkillDate
                .replace(/-/g, '/')
                .replace(/T.+/, '')
            ).toISOString();
          }
          if (this.EligibleSPApplications[i].skillDate != null) {
            this.EligibleSPApplications[i].skillDateSort = new Date(
              this.EligibleSPApplications[i].skillDate
                .replace(/-/g, '/')
                .replace(/T.+/, '')
            ).toISOString();
          }
          if (this.EligibleSPApplications[i].EffectiveDate != null) {
            this.EligibleSPApplications[i].EffectiveDateSort = new Date(
              this.EligibleSPApplications[i].EffectiveDate
            ).toISOString();
          }
        }

        this.isApplicationDataEmpty =
          this.EligibleSPApplications.length === 0 ? true : false;
        this.Applicationpage = 1;
        this.TotalApplicationCount = this.EligibleSPApplications.length;
        this.loading = false;
      });
  }

  @ViewChild('ShiftPreferenceViewExportTable', {
    read: ElementRef,
    static: false,
  })
  ShiftPreferenceViewExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.ShiftPreferenceViewExportTable,
        `${ApplicationEnum.SHIFTPREFERENCEVIEW}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    //console.log("this.EligibleSPApplications", this.EligibleSPApplications);
    if (dataType === 'string') {
      this.EligibleSPApplications.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.EligibleSPApplications.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
