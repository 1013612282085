import { HttpErrorResponse } from '@angular/common/http';
import {
  ElementRef,
  ViewChild,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClassificationDataService } from 'src/app/Services/TableMaintenance/ClassificationData/classification-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-classification',
  templateUrl: './add-classification.component.html',
  styleUrls: ['./add-classification.component.css'],
})
export class AddClassificationComponent implements OnInit {
  @ViewChild('closeAddModel') closeAddModal!: ElementRef;
  @Input() selectedClassification: any;
  @Input() userName: any;
  @Output() isSucess = new EventEmitter();
  classificationSelected: any;
  classificationForm!: FormGroup;

  Types: any[] = [
    {
      data: [
        {
          classificationType: 'P',
          classificationName: 'Production',
        },
        {
          classificationType: 'S',
          classificationName: 'Skilled',
        },
      ],
      default: 'Select One',
    },
  ];
  Status: any[] = [
    {
      data: [
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
        {
          statusType: 'A',
          statusName: 'Active',
        },
      ],
      default: 'Select One',
    },
  ];
  Nio: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];
  constructor(
    private fb: FormBuilder,
    private cds: ClassificationDataService
  ) {}

  ngOnInit(): void {
    this.createEmpForm();
    this.getNIOGroups();
  }

  createEmpForm() {
    this.classificationForm = this.fb.group({
      JOBCODEID: ['', [Validators.required]],
      JOBCODEDESCRIPTION: ['', [Validators.required]],
      CLASSIFICATIONTYPE: ['', [Validators.required]],
      STATUS: ['', [Validators.required]],
      MAXRATE: ['', [Validators.required]],
      NIO: ['', [Validators.required]],
      //BOX: [''],
      LASTUPDATEBY: [this.userName],
    });
  }

  getNIOGroups() {
    this.cds.getNIOGroups().subscribe((res) => {
      this.Nio[this.Nio.length - 1].data = res;
    });
  }

  clear() {
    this.closeAddModal.nativeElement.click();
    this.createEmpForm();
  }

  submitClassification() {
    this.cds.addClassification(this.classificationForm.value).subscribe(
      () => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        //this.badRequestAlert();
        if (_error.status === 500) {
          this.badRequestAlert(_error.error);
        } else {
          this.badRequestAlert(_error.error);
        }
      }
    );
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Classification has been added successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
