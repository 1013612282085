// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  applicationsURL: '#{applications-service-url}#',
  userMgmtURL: '#{usermanagement-service-url}#',
  rulesEngineURL: '#{rulesengine-service-url}#',
  overTimeUrl: '#{overtime-service-url}#',
  tableMaintenanceURL: '#{tablemaintenance-service-url}#',
  adminURL: '#{admin-service-url}#',
  reportandchartsURL: '#{reportsandcharts-service-url}#',
  clientId: '#{azure-ad-clientid}#',
  authority: '#{azure-ad-authority}#',
  redirectUrl: '#{azure-ad-redirect-url}#',
  logoutRedirectUrl: '#{logout-redirect-url}#',
  apimKeyText: '#{azure-apim-key-text}#',
  apimKeyValue: '#{azure-apim-key-value}#',
};

// export const environment = {
//   production: true,
//   applicationsURL: 'https://apim-uawcas-dev.azure-api.net/api/app',
//   userMgmtURL: 'https://apim-uawcas-dev.azure-api.net/api/umm',
//   rulesEngineURL: 'https://apim-uawcas-dev.azure-api.net/rules',
//   overTimeUrl: 'https://apim-uawcas-dev.azure-api.net/api/ovt',
//   tableMaintenanceURL: 'https://apim-uawcas-dev.azure-api.net/tm',
//   reportandchartsURL:
//     'https://apim-uawcas-dev.azure-api.net/api/reportsandcharts',
//   adminURL: 'https://apim-uawcas-dev.azure-api.net/admin',
//   clientId: 'f4ac412f-0b20-463d-b9d1-a79a5515f452',
//   authority:
//     'https://login.microsoftonline.us/6495c0bc-c8c1-4f74-914f-eeeedc1d07df',
//   redirectUrl: 'http://localhost:4200',
//   logoutRedirectUrl: 'http://localhost:4200',
//   apimKeyText: 'Ocp-Apim-Subscription-Key',
//   apimKeyValue: 'f13c794ac25947068dbe0bba8d7db974',
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *ng
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
