import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root',
})
export class RecallRightsService {
  private readonly api_getClassification = '/RecallRightsRRGetClassifications';
  private readonly api_searchUsers = '/SearchUsers';
  private readonly api_addApplication = '/RecallRightsRRAdd';
  private readonly api_searchApplications = '/RecallRightsRRSearchApplications';
  private readonly api_cancelApplication = '/RecallRightsRRCancel';
  private readonly api_editApplication = '/RecallRightsRRCorrect';

  private readonly searchRRForViews = '/SearchRecallRightsForViews';
  private readonly getRRClassifications = '/GetRRClassfications';


  appId = new Subject();
  empsignDate = new Subject();
  atid = new Subject();
  commonUtility = new CommonUtility();

  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  getClassifications(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.applicationsURL}` + this.api_getClassification,
      { headers }
    );
  }

  searchFilterApplication(formData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.applicationsURL}` + this.api_searchApplications,
      formData,
      { headers }
    );
  }

  searchFilterApplicationUser(formData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.applicationsURL}` + this.api_searchUsers,
      formData,
      { headers }
    );
  }

  Add152Application(add178: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.applicationsURL}` + this.api_addApplication,
      add178,
      { headers }
    );
  }

  Cancel152Application(obj: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.applicationsURL}` + this.api_cancelApplication,
      obj,
      { headers }
    );
  }

  Edit152Application(app152: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.applicationsURL}` + this.api_editApplication,
      app152,
      { headers }
    );
  }
  getRRClassificationsForView(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.getRRClassifications,
      { headers }
    );
  }
  searchRRForView(formData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
     `${environment.tableMaintenanceURL}` + this.searchRRForViews,
      formData,
      { headers }
    );
  }
}
