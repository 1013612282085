import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToolAndDiePlantService } from 'src/app/Services/Applications/ToolAndDie/tool-and-die-plant.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-delete-skilled-to-skilled',
  templateUrl: './delete-skilled-to-skilled.component.html',
  styleUrls: ['./delete-skilled-to-skilled.component.css'],
})
export class DeleteSkilledToSkilledComponent {
  @Input() selectedEmp: any;
  appId: any;
  @Output() isSucess = new EventEmitter();
  constructor(private td: ToolAndDiePlantService) {}

  confirmDelete() {
    if (this.selectedEmp) {
      this.appId = this.selectedEmp.appId;
      this.td.Delete150Application(this.appId).subscribe((res) => {
        this.successAlert();
        this.isSucess.emit(true);
      });
    }
  }

  clear() {
    //TODO
  }

  successAlert() {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'The Application has been Deleted Successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }
}
