import { formatDate } from '@angular/common';
import { Component, ElementRef, EventEmitter, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { HolidayDataService } from 'src/app/Services/TableMaintenance/HolidayData/holiday-data.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';

@Component({
  selector: 'app-holiday-data',
  templateUrl: './holiday-data.component.html',
  styleUrls: ['./holiday-data.component.css'],
})
export class HolidayDataComponent {
  public loading = false;
  holidayData = new EventEmitter();
  showAdd: boolean = false;
  showEdit: boolean = false;
  showDelete: boolean = false;
  TotalApplicationCount: any;
  UserPage: number = 1;
  Applicationpage: number = 1;
  modalId: string = '';
  filterData: any = {};
  holidaySearchData: any[] = [];
  selectedHoliday: any[] = [];
  showButton: boolean = false;
  userInformation: any;
  isApplicationDataEmpty: boolean = false;
  views: any[] = [];
  currentDate = new Date();
  isSelectAllHoliday: boolean = false;
  currentYear = this.currentDate.getFullYear();
  prevYear = this.currentDate.getFullYear() - 1;
  nextYear = this.currentDate.getFullYear() + 1;
  filterConfig: any = [
    {
      label: 'Month',
      type: 'dropdown',
      data: [
        {
          id: '00',
          description: 'All',
          runClassification: false,
        },
        {
          id: '01',
          description: 'January',
          runClassification: false,
        },
        {
          id: '02',
          description: 'February',
          runClassification: false,
        },
        {
          id: '03',
          description: 'March',
          runClassification: false,
        },
        {
          id: '04',
          description: 'April',
          runClassification: false,
        },
        {
          id: '05',
          description: 'May',
          runClassification: false,
        },
        {
          id: '06',
          description: 'June',
          runClassification: false,
        },
        {
          id: '07',
          description: 'July',
          runClassification: false,
        },
        {
          id: '08',
          description: 'August',
          runClassification: false,
        },
        {
          id: '09',
          description: 'September',
          runClassification: false,
        },
        {
          id: '10',
          description: 'October',
          runClassification: false,
        },
        {
          id: '11',
          description: 'November',
          runClassification: false,
        },
        {
          id: '12',
          description: 'December',
          runClassification: false,
        },
      ],
      controlName: 'month',
      width: '150px',
    },
    {
      label: 'Year',
      type: 'dropdown',
      data: [
        {
          id: this.currentYear,
          description: this.currentYear,
        },
        {
          id: this.prevYear,
          description: this.prevYear,
        },
        {
          id: this.nextYear,
          description: this.nextYear,
        },
      ],
      controlName: 'year',
      width: '150px',
    },
  ];

  constructor(
    private cds: HolidayDataService,
    private _user: UsergroupService,
    private _commonApplicationService: CommonService
  ) {
    this.getUserInfo();
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'TABLEMAINTENANCE',
        'HOLIDAYDATA'
      );
    });
  }

  selectAllHoliday() {
    this.isSelectAllHoliday = !this.isSelectAllHoliday;
    if (this.isSelectAllHoliday) {
      this.showButton = true;
      this.holidaySearchData.forEach((val: any) => {
        val.ischecked = true;
      });
      this.selectedHoliday = this.holidaySearchData;
    } else {
      this.showButton = false;
      this.holidaySearchData.forEach((val: any) => {
        val.ischecked = false;
      });
      this.selectedHoliday = [];
    }
  }

  onChange(holidayData: any, check: any) {
    let isChecked = check.target!.checked;
    if (isChecked) {
      this.showButton = true;
      this.selectedHoliday.push(holidayData);
    } else {
      const index = this.selectedHoliday.findIndex(
        (x) => x.holidayId === holidayData.holidayId
      );
      this.selectedHoliday.splice(index, 1);
    }
  }

  showAddModal() {
    this.showAdd = true;
    this.modalId = '#addEmployee';
  }

  showEditModal() {
    this.showEdit = true;
    this.modalId = '#editEmp';
  }

  showDeleteModal() {
    this.showDelete = true;
    this.modalId = '#deleteApplicationModal';
  }

  CheckAdd(e: any) {
    this.searchHolidays('NOTSEARCH');
  }

  CheckEdit(e: any) {
    if (e === true) {
      this.searchHolidays('NOTSEARCH');
    } else {
      this.showEdit = false;
    }
  }

  CheckDelete(e: any) {
    this.searchHolidays('NOTSEARCH');
    this.showButton = false;
  }

  getFilterValue(event: any) {
    this.filterData = event;
  }

  searchHolidays(isSearch: string) {
    this.showEdit = false;
    if (isSearch === 'SEARCH') {
      this.loading = true;
    }
    let queryParams = this.filterData;

    if (Object.keys(this.filterData).length === 0) {
      queryParams.year = this.currentYear;
      queryParams.month = '00';
    } else if (Object.keys(this.filterData).length === 2) {
      if (this.filterData.year === '') {
        queryParams.year = this.currentYear.toString();
      }
      if (this.filterData.month === '') {
        queryParams.month = '00';
      }
    }
    //Object.keys(queryParams).forEach((k) => !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]);
    this.cds
      .getHolidayData(queryParams.month, queryParams.year)
      .subscribe((res) => {
        this.holidaySearchData = res;
        this.selectedHoliday = [];
        this.isApplicationDataEmpty =
          this.holidaySearchData.length === 0 ? true : false;
        this.showButton = false;
        this.Applicationpage = 1;
        this.TotalApplicationCount = res.length;
        this.loading = false;
        this.isSelectAllHoliday = false;
      });
  }

  @ViewChild('holidaydataTablemaintenanceExportTable', {
    read: ElementRef,
    static: false,
  })
  holidaydataTablemaintenanceExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.holidaydataTablemaintenanceExportTable,
        `${ApplicationEnum.HOLIDAYDATATABLEMAINTENANCE}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.holidaySearchData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.holidaySearchData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
