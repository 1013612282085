import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OverTimeMaintanceService } from 'src/app/Services/Applications/OvertimeMaintanence/overtime.maintanence.service';
import { LocalStorageService } from 'src/app/Services/LocalStorageService/locastorage.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { MSALserviceService } from 'src/app/Services/MSALservice/msalservice.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-reassign-representation-group-overtimedetail',
  templateUrl: './reassign-representation-group-overtimedetail.component.html',
  styleUrls: ['./reassign-representation-group-overtimedetail.component.css']
})
export class ReassignRepresentationGroupOvertimedetailComponent implements OnInit {

  @Input() isReassignRepresentationOvertimeGroup: boolean = false;
  @Input() title: any;
  @Output() clean = new EventEmitter();
  @Output() isSuccess = new EventEmitter();
  @ViewChild('closeReassignRepresntationOvertimeGroupModal') closeReassignRepresntationOvertimeGroupModal!: ElementRef;


  userData: any = [];
  EmployeeReassignRepresentationGroupForm!: FormGroup;
  searchKey: string = '';
  isDeleted: boolean = false;
  demographData: any;
  DepartmentDefaultVal: string = "0330";

  isSearchRepHit: boolean = false;;
  ;
  accHours: any;
  enableIndButtons: boolean = false;
  recordId: any;
  DefaultOverTimeTypeOvt: any;
  DefaultOverTimeStatusOvt: any;
  DefaultStartDateOvt: any;
  DefaultEndDateOvt: any;
  loggedInUserATID: string = '';
  isArchive: boolean = false;
  isOvertimeDataEmpty: boolean = true;
  isSearchHit: boolean = false;

  postModel: any;

  constructor(private ot: OverTimeMaintanceService,
    private _sweetAlertService: SweetAlertService,
    private router: Router,
    private fb: FormBuilder,
    private _commonService: CommonService,
    private localStorageService: LocalStorageService,
    private _msalService: MSALserviceService,
    private _overtimeService: OverTimeMaintanceService) {
    let employeeid = this.localStorageService.getItem('ovtAtid');
    this.accHours = this.localStorageService.getItem('ovtAtidAccHours');
    this.ot.NavigateToDetailByUser(employeeid).subscribe((data) => {
      this.demographData = data;
      this.EmployeeReassignRepresentationGroupForm.get('EMPLOYEEID')?.patchValue(employeeid);
      this.EmployeeReassignRepresentationGroupForm.get('shiftId')?.patchValue(this.demographData.shift);
      this.EmployeeReassignRepresentationGroupForm.get('jobCodeId')?.patchValue(this.demographData.jobcodeid === null ? 'GLIS' : this.demographData.jobcodeid);
      this.EmployeeReassignRepresentationGroupForm.get('departmentId')?.patchValue(this.demographData.dept === null ? 'GLIS' : this.demographData.dept);
      this.EmployeeReassignRepresentationGroupForm.get('CreatedBy')?.patchValue(this.GetCreatedBy(this._msalService.userAtid));
    });
  }


  ngOnInit(): void {
    this.getClassifications();
    this.getShifts();
    this.getDepartments();
    this.EmployeeReassignRepresentationGroup();
    this._msalService.getUserName();
  }


  EmployeeReassignRepresentationGroup() {
    this.EmployeeReassignRepresentationGroupForm = this.fb.group({
      EMPLOYEEID: ['', [Validators.required]],
      shiftId: ['', [Validators.required]],
      jobCodeId: ['', [Validators.required]],
      departmentId: ['', [Validators.required]],
      CreatedBy: ['', [Validators.required]]

    });
  }

  cleanEmp(event: any) { }

  MainNavigate() {
    this.closeReassignRepresntationOvertimeGroupModal.nativeElement.click();
    this.router.navigate(['Overtime']);
  }

  OvertimeDetailNavigate() {
    this.closeReassignRepresntationOvertimeGroupModal.nativeElement.click();
    this.router.navigate(['OvertimeDetail']);
  }


  clear() {
    this.clean.emit('');
    this.closeReassignRepresntationOvertimeGroupModal.nativeElement.click();
    this.isSuccess.emit(true);
  }

  SubmitData() {

    this.isSearchRepHit = true;
    // console.log('Values before inserting', this.EmployeeReassignRepresentationGroupForm.value)
    this._overtimeService.AddOvertimeReassignRepGroup(this.EmployeeReassignRepresentationGroupForm.value).subscribe((data: any) => {
      this._sweetAlertService.successAlert('top-end', 'success', 'This employee has been reassigned to a new Overtime Representation Group successfully.', false, 1500);
      this.clear();
    }, (error: HttpErrorResponse) => {
      if (error.status === 406) {
        let errorMsg = error.error.replace('Error: GetOvertimeReassignRepresentationGroup - InvalidDataException:', '');
        this._sweetAlertService.validationError('warning', 'ERROR', `${errorMsg}`)
      }
      else {
        this._sweetAlertService.InternalServerError500('error', 'ERROR', `Something went wrong please try again.`)
      }
    });
  }


  GetCreatedBy(value: string) {
    let resultString = '';
    if (value != null && value != '') {
      let tempString = value.replace('@alsn.com', '');
      resultString = tempString.trim();
    }
    return resultString;
  }

  /*Get Function Variables*/
  ShiftsList: any[] = [
    {
      data: [],
      default: 'Select any Shift'
    },
  ];
  ClassificationList: any[] = [
    {
      data: [],
      default: 'Select any Classification'
    },
  ];;
  DepartmentList: any[] = [
    {
      data: [],
      default: 'Select any Department'
    },
  ];;
  /*Get Functions*/

  getShifts() {
    this._commonService.getShiftsList().subscribe((shifts: any) => {
      this.ShiftsList[0].data = shifts
      this.ShiftsList[0].data.splice(3, 1)
    });
  }

  getClassifications() {
    this._commonService.getClassificationList().subscribe((classifications: any) => {
      this.ClassificationList[0].data = classifications;
    });
  }

  getDepartments() {
    this._commonService.getDepartmentList().subscribe((departments: any) => {
      this.DepartmentList[0].data = departments;
    });
  }


}
