import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';
import { CommonUtility } from 'src/common/commonUtility'

@Injectable({
  providedIn: 'root',
})
export class FacilitiesServicePlantService {
  private readonly getShifts = '/GetShifts';
  private readonly api_Delete150_Path = '/DeleteApplication/';
  private readonly api_cancelApplication = '/CancelApplication';
  private readonly getPlants = '/FacilitiesService150GetPlants';
  private readonly api_Add150_Path = '/FacilitiesService150Add';
  private readonly AddApplicationRule = '/AddApplicationRule?employeeId=';
  private readonly getAreas = '/GetAreas';
  private readonly getClassifications = '/FacilitiesService150GetClassifications';
  private readonly Edit150 = '/FacilitiesService150Correct';

  private readonly api_searchApplications = '/SearchApplications';
  private readonly api_searchUsers = '/SearchUsers';
  private readonly api_SearchApplicationByUser = '/FacilitiesService150SearchApplicationByUser';

  private readonly api_search150ApplicationsForViews = '/Search150ApplicationsForView';

  appId = new Subject();
  empsignDate = new Subject();
  atid = new Subject();
  commonUtility = new CommonUtility();
  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  Delete150Application(app150Id: number): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.delete<any>(
      `${environment.applicationsURL}` + this.api_Delete150_Path + app150Id,
      { headers }
    );
  }

  Cancel150Application(obj: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.put<any>(
      `${environment.applicationsURL}` + this.api_cancelApplication,
      obj,
      { headers }
    );
  }


  getPlantsList(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.applicationsURL}` + this.getPlants,
      { headers }
    );
  }

  Add150Application(addTD: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` + this.api_Add150_Path,
      addTD,
      { headers }
    );
  }

  getAreasList(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.applicationsURL}` + this.getAreas,
      { headers }
    );
  }

  getClassificationList(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.applicationsURL}` + this.getClassifications,
      { headers }
    );
  }

  Edit150Application(app150: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.put<any>(
      `${environment.applicationsURL}` + this.Edit150,
      app150,
      { headers }
    );
  }

  searchFilterApplication(formData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` + this.api_searchApplications,
      formData,
      { headers }
    );
  }

  SearchApplicationByUser(searchKey: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.applicationsURL}` +
      this.api_SearchApplicationByUser +
      searchKey,
      { headers }
    );
  }

  getRulesEngine(employeeId: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.rulesEngineURL}` +
      this.AddApplicationRule + employeeId,
      { headers }
    );
  }


  SearchUser(searchKey: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.get<any>(
      `${environment.applicationsURL}` +
      this.api_searchUsers + searchKey,
      { headers }
    );
  }

  searchFilterApplicationUser(formData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
      `${environment.applicationsURL}` + this.api_searchUsers,
      formData,
      { headers }
    );
  }
  search150AppForView(formData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.post<any>(
     `${environment.tableMaintenanceURL}` + this.api_search150ApplicationsForViews,
      formData,
      { headers }
    );
  }

}
