<div
  class="modal fade"
  id="updateEmployee"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteApplicationModalLabel"
  aria-hidden="true"
  data-backdrop="false"
>
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
    style="max-width: 80%"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteApplicationModalLabel">
          Update Overtime
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>
      <br />
      <!-- filer -->
      <div
        class="jumbotron jumb-new jumb-newcontrols"
        style="margin-left: 80px; margin-right: 80px"
      >
        <br />
        <div class="row" display="float">
          <!-- <div class="col">
                        <div class="form-group row">
                            <div class="col-sm-3 col-form-label"></div>
                            <div class="col-sm-2 col-form-label">
                                <label>Overtime Date</label>
                            </div>
                            <div class="col-sm-3">
                                <input style="width: auto;" [(ngModel)]="searchDateOfWork" type="date" class="form-control">
                            </div>
                            <div class="col-sm-2">
                                <button class="btn btn-lg search-button" type="submit" (click)="GetOvertimeData()">
                                    Search
                                    <img src="./assets/search-solid.svg" class="img-fluid user-icon ml-1 search-icon">
                                </button>
                            </div>
                            <div class="col-sm-2 col-form-label"></div>
                        </div>
                    </div>
                </div> -->
          <div class="jumbotron jumb-new jumb-newcontrols">
            <div class="row" display="float">
              <div class="col">
                <div>
                  <div class="form-row">
                    <div
                      class="form-group col-md-3"
                      style="margin-bottom: 0.3rem !important"
                    >
                      <label
                        class="filter-label"
                        style="color: white !important"
                        >From Date
                      </label>
                      <input
                        type="date"
                        [(ngModel)]="searchDateOfWork"
                        placeholder="From Date"
                        class="form-control"
                      />
                    </div>
                    <div
                      *ngIf="!MultipleRecordSelected"
                      class="form-group col-md-3"
                      style="margin-bottom: 0.3rem !important"
                      style="margin-bottom: 0.3rem !important; left: 55px"
                    >
                      <label
                        class="filter-label"
                        style="color: white !important"
                        >To Date</label
                      >
                      <input
                        type="date"
                        [(ngModel)]="searchToDateOfWork"
                        placeholder="To Date"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <!--
                                <app-filter [filterConfig]="filterConfig"
                                    (formcontrolobj)="getbyenter($event)" (filterValue)="getFilterValue($event)" >
                                </app-filter> -->
              </div>
              <div class="col-md-1 mr-5">
                <label for="" style="color: white">&nbsp;</label>
                <a
                  class="btn btn-lg search-button"
                  (click)="GetOvertimeData()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="font-weight: 700; text-align: center">
        Overtime Group Name: {{ overtimeGroupName }}
      </div>
      <!-- filer -->
      <div style="padding-left: 80px; padding-right: 80px">
        <h6 class="alert alert-danger" *ngIf="MultipleRecordSelected">
          <span class="text-danger text-highlight">*</span>
          To date disabled due to selection of more than 1 employees.
        </h6>
      </div>
      <div style="padding-left: 80px; padding-right: 80px">
        <h6 class="alert alert-danger" *ngIf="IsFromDateEarlierToDate">
          <span class="text-danger text-highlight">*</span>
          Please select 'From' Date later than 'To' Date.
        </h6>
      </div>
      <div
        *ngIf="
          modifiedOvertimRecords !== null && modifiedOvertimRecords.length > 0
        "
      >
        <div class="modal-body" style="padding-left: 80px; padding-right: 80px">
          <h6 class="alert alert-danger" *ngIf="DateCheck">
            You must enter a overtime date that is the same or after the date
            offered.
          </h6>
          <h6 class="alert alert-danger" *ngIf="PaidHoursCheck">
            You must enter the paid hours.
          </h6>
          <h6 class="alert alert-danger" *ngIf="OvertimeTypeIdCheck">
            You must select overtime type.
          </h6>
          <h6 class="alert alert-danger" *ngIf="OvertimeReasonIdCheck">
            You must select overtime reason.
          </h6>
          <h6 class="alert alert-danger" *ngIf="OvertimeCommentCheck">
            You must Enter comment.
          </h6>
          <h6 class="alert alert-danger" *ngIf="DuplicateRecordsFound">
            Duplicate Records found.
          </h6>
          <h6
            class="alert alert-danger"
            *ngIf="OvertimeDate1MonthEarlierLaterCheck"
          >
            <span
              class="text-danger text-highlight"
              *ngIf="!OverrideIfDateOlderEarlier1Month"
              >*</span
            >
            You have entered a date that is earlier than a month ago or later
            than a month from now. If this was not an error please override.
            <input
              (change)="onChange1MonthDateOverride()"
              type="checkbox"
              name="OvertimeOverride"
            />
          </h6>
          <h6 class="alert alert-danger" *ngIf="OvertimeTypeRequiesValidations">
            <span
              class="text-danger text-highlight"
              *ngIf="!OverrideIfDateSelectedIsWeekedForOvertimeType"
              >*</span
            >
            You have chosen Overtime Type that needs Overtime Date as weekend or
            Holiday but the overtime date is neither a weekend nor a holiday. If
            this is not an error please override.
            <input
              type="checkbox"
              (change)="onChangeWeekendDateForOTOverride()"
              name="OvertimeOverride"
            />
          </h6>
          <h6 class="alert alert-danger" *ngIf="OverrideOTisCallinRefuseCheck">
            <span
              class="text-danger text-highlight"
              *ngIf="!OverrideOTisCallinRefuse"
              >*</span
            >
            You have chosen 'Callin-Refused' and the paid hours is not 0. If
            this is not an error please override.
            <input
              type="checkbox"
              (change)="onChangeIfOTRisCallinRefused()"
              name="OvertimeOverride"
            />
          </h6>
          <h6 class="alert alert-danger" *ngIf="OverrideCheckIfOTisLRCheck">
            <span
              class="text-danger text-highlight"
              *ngIf="!OverrideCheckIfOTisLR"
              >*</span
            >
            You have chosen 'Loan Refused'. If this is not an error please
            override.
            <input
              type="checkbox"
              (change)="onChangIfOTisLR()"
              name="OvertimeOverride"
            />
          </h6>

          <table class="overtime-preview-table table table-striped">
            <thead class="table-header">
              <tr class="overtime-preview-table-tr">
                <th class="overtime-preview-table-th">No.</th>
                <th class="overtime-preview-table-th">
                  <input
                    type="checkbox"
                    [(ngModel)]="checkAllRecords"
                    (change)="selectAllEmployee()"
                  />
                </th>
                <th class="overtime-preview-table-th">Emp Id</th>
                <th class="overtime-preview-table-th">Name</th>
                <th class="overtime-preview-table-th">Overtime Date</th>
                <th class="overtime-preview-table-th" style="width: 30px">
                  Paid Hours
                </th>
                <th class="overtime-preview-table-th">Overtime Type</th>
                <th class="overtime-preview-table-th">Overtime Reason</th>
                <th class="overtime-preview-table-th">Comment</th>
                <th class="overtime-preview-table-th">Date Offered</th>
                <th class="overtime-preview-table-th">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let val of modifiedOvertimRecords; let i = index">
                <td
                  [ngClass]="
                    val.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                  "
                  class="overtime-preview-table-td"
                >
                  &nbsp;{{ i + 1 }}
                </td>
                <td
                  [ngClass]="
                    val.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                  "
                  class="overtime-preview-table-td"
                >
                  <input
                    type="checkbox"
                    (change)="onEmployeeSelect(i)"
                    [(ngModel)]="val.isselected"
                    name="OvertimeAdd"
                  />
                </td>
                <td
                  [ngClass]="
                    val.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                  "
                  class="overtime-preview-table-td"
                >
                  {{ val.employeeid ? val.employeeid : 'N/A' }}
                </td>
                <td
                  [ngClass]="
                    val.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                  "
                  class="overtime-preview-table-td nowrapcell"
                >
                  {{ val.lasT_NAME ? val.lasT_NAME : 'N/A' }},&nbsp;{{
                    val.firsT_NAME ? val.firsT_NAME : 'N/A'
                  }}&nbsp;{{ val.middle ? val.middle : '' }}
                </td>
                <td
                  [ngClass]="
                    val.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                  "
                  class="overtime-preview-table-td"
                >
                  <input
                    type="date"
                    [(ngModel)]="val.dateofwork"
                    [ngClass]="
                      val.DowIsGreaterThanDo
                        ? 'isErrorEffected'
                        : 'isNotErrorEffected'
                    "
                    [ngClass]="
                      val.overtimeRequireValidation
                        ? 'isErrorEffected'
                        : 'isNotErrorEffected'
                    "
                    [ngClass]="
                      val.DateEarlierOrOlderFlag
                        ? 'isErrorEffected'
                        : 'isNotErrorEffected'
                    "
                    [ngClass]="
                      val.dateofworkflag ? 'cellChanged' : 'cellNoChanged'
                    "
                    (change)="onChangeDateOfWork(i, $event)"
                    class="form-control"
                  />
                </td>
                <td
                  [ngClass]="
                    val.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                  "
                  class="overtime-preview-table-td"
                >
                  <input
                    type="number"
                    step="0.25"
                    pattern="(^[0-9]{0,2}$)|(^[0-9]{0,2}\.[0-9]{0,5}$)"
                    [ngClass]="
                      val.paidHoursMissing
                        ? 'isErrorEffected'
                        : 'isNotErrorEffected'
                    "
                    [ngClass]="
                      val.paidhoursflag ? 'cellChanged' : 'cellNoChanged'
                    "
                    (keyup)="onChangePaidHours(i, $event)"
                    (change)="onChangePaidHours(i, $event)"
                    [(ngModel)]="val.paidhours"
                    class="form-control"
                  />
                </td>
                <td
                  [ngClass]="
                    val.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                  "
                  class="overtime-preview-table-td"
                >
                  <select
                    [ngClass]="
                      val.overtimeRequireValidation
                        ? 'isErrorEffected'
                        : 'isNotErrorEffected'
                    "
                    [ngClass]="
                      val.overtimeTypeMissing
                        ? 'isErrorEffected'
                        : 'isNotErrorEffected'
                    "
                    (change)="onChangeOvertimeType(i, $event)"
                    [(ngModel)]="val.overtimetypeid"
                    [ngClass]="
                      val.overtimetypeidflag ? 'cellChanged' : 'cellNoChanged'
                    "
                    type="text"
                    class="form-control"
                  >
                    <option
                      *ngFor="let overtime of OvertimeType"
                      value="{{ overtime.overtimetypeid }}"
                    >
                      {{ overtime.overtimetypeid }}-{{
                        overtime.overtimetypedescription
                      }}
                    </option>
                  </select>
                </td>
                <td
                  [ngClass]="
                    val.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                  "
                  class="overtime-preview-table-td"
                >
                  <select
                    [ngClass]="
                      val.overtimeReasonMissing
                        ? 'isErrorEffected'
                        : 'isNotErrorEffected'
                    "
                    [ngClass]="
                      val.callinRefusedFalg
                        ? 'isErrorEffected'
                        : 'isNotErrorEffected'
                    "
                    (change)="onChangeOvertimeReason(i, $event)"
                    [(ngModel)]="val.overtimereasonid"
                    [ngClass]="
                      val.overtimereasonidflag ? 'cellChanged' : 'cellNoChanged'
                    "
                    class="form-control"
                  >
                    <option
                      disabled
                      *ngIf="OvertimeReason[0].default"
                      selected
                      value=""
                    >
                      {{ OvertimeReason[0].default }}
                    </option>
                    <option
                      *ngFor="
                        let overtime of val
                          .OvertimeReasonArrayBasedOnOvertimeType[0]
                      "
                      value="{{ overtime.overtimereasonid }}"
                    >
                      {{ overtime.overtimereasondescription }}
                    </option>
                  </select>
                </td>
                <td
                  [ngClass]="
                    val.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                  "
                  class="overtime-preview-table-td"
                >
                  <input
                    [ngClass]="
                      val.isCommentRequired
                        ? 'isErrorEffected'
                        : 'isNotErrorEffected'
                    "
                    type="email"
                    [(ngModel)]="val.freeformcomment"
                    (keyup)="onChangeOvertimeComment(i, $event)"
                    [ngClass]="
                      val.freeformcommentflag ? 'cellChanged' : 'cellNoChanged'
                    "
                    class="form-control"
                  />
                </td>
                <td
                  [ngClass]="
                    val.isDuplicated ? 'isDuplicateRow' : 'isNotDuplicateRow'
                  "
                  class="overtime-preview-table-td"
                >
                  <input
                    [ngClass]="
                      val.DowIsGreaterThanDo
                        ? 'isErrorEffected'
                        : 'isNotErrorEffected'
                    "
                    [ngClass]="
                      val.DateEarlierOrOlderFlag
                        ? 'isErrorEffected'
                        : 'isNotErrorEffected'
                    "
                    type="date"
                    [(ngModel)]="val.dateoffered"
                    [ngClass]="
                      val.dateofferedflag ? 'cellChanged' : 'cellNoChanged'
                    "
                    (change)="onChangeOvertimeDateOffered(i, $event)"
                    class="form-control"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    [(ngModel)]="val.boolStatus"
                    (change)="onChangeOvertimeStatus(i, $event)"
                    name="OvertimeStatus"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-group col-md-12">
          <div class="btn-wrapper" style="padding-right: 80px">
            <button
              style="height: 40px; width: 150px; border-radius: 10px"
              type="button"
              class="btn btn-secondary cancel-btn"
              #closeUpdateModal
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="height: 40px; width: 150px; border-radius: 10px"
              type="button"
              class="btn btn-primary save-btn"
              (click)="UpdateMassOvertimeRecord()"
            >
              Update
            </button>
          </div>
        </div>
      </div>
      <div
        *ngIf="!ismodifiedOvertimRecordsExists"
        style="text-align: center; padding-top: 2rem"
      >
        <p>No records found.</p>
      </div>
    </div>
  </div>
</div>
