import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HolidayDataService } from 'src/app/Services/TableMaintenance/HolidayData/holiday-data.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-delete-holiday-data',
  templateUrl: './delete-holiday-data.component.html',
  styleUrls: ['./delete-holiday-data.component.css'],
})
export class DeleteHolidayDataComponent {
  @Input() selectedHoliday: any;
  holidayIds: any[] = [];
  @Output() isSucess = new EventEmitter();
  constructor(private hds: HolidayDataService) {}

  clear() {
    //TODO
  }

  confirmDelete() {
    if (this.selectedHoliday) {
      this.hds.deleteHolidayData(this.selectedHoliday).subscribe(
        (res) => {
          this.successAlert();
          this.isSucess.emit(true);
        },
        (_error: HttpErrorResponse) => {
          if (_error.status === 500) {
            this.badRequestAlert(_error.error);
          } else {
            this.badRequestAlert(_error.error);
          }
        }
      );
    }
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'warning',
      title: `${errorMessage}`,
    });
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'The record has been Deleted Successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }
}
