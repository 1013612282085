import { ElementRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';
import { CommonUtility } from 'src/common/commonUtility';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
//import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private readonly getShifts = '/GetShifts';
  private readonly getPlants = '/GetPlants';
  private readonly getAreas = '/GetAreas';
  private readonly getDeptById = '/GetDepartmentsByPlantId/';
  private readonly _getDepartments = '/GetDepartments';
  private readonly _getPlantGroups = '/GetPlantGroups';
  private readonly _getClassifications = '/GetClassifications';
  /* Gets the list of both active/inactive */
  private readonly _getClassificationList = '/GetClassificationsList';
  private readonly _getDepartmentList = '/GetDepartmentsList';

  private readonly getPlantsByApptype = '/GetPlants?apptype=';
  private readonly getDeptByApptype = '/GetDepartments?apptype=';

  private readonly _searchUsers = '/SearchUsers';
  private readonly _deleteApplication = '/DeleteApplication/';

  commonUtility = new CommonUtility();
  constructor(private _http: HttpClient, private appCom: MSALserviceService) {}

  /* Get List*/
  getShiftsList(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.applicationsURL}` + this.getShifts,
      { headers }
    );
  }

  getPlantsList(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.applicationsURL}` + this.getPlants,
      { headers }
    );
  }

  getAreasList(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.applicationsURL}` + this.getAreas,
      { headers }
    );
  }

  getDeptbyPlantId(plantId: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.applicationsURL}` + this.getDeptById + plantId,
      { headers }
    );
  }

  getDepartments(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.applicationsURL}` + this._getDepartments,
      { headers }
    );
  }

  /*Get the full list of Department list (active/inactive)*/
  getDepartmentList(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.applicationsURL}` + this._getDepartmentList,
      { headers }
    );
  }

  /*Get the full list of Classification list (active/inactive)*/
  getClassificationList(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.applicationsURL}` + this._getClassificationList,
      { headers }
    );
  }
  /* Get List by AppType*/

  GetPlants(appType: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.applicationsURL}` + this.getPlantsByApptype + appType,
      { headers }
    );
  }

  GetDepartment(appType: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.applicationsURL}` + this.getDeptByApptype + appType,
      { headers }
    );
  }

  GetPlantGroups(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.applicationsURL}` + this._getPlantGroups,
      { headers }
    );
  }

  GetClassifications(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.applicationsURL}` + this._getClassifications,
      { headers }
    );
  }

  /* Common Crud apis */

  //Delete TA
  DeleteApplication(AppId: number): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.delete<any>(
      `${environment.applicationsURL}` + this._deleteApplication + AppId,
      { headers }
    );
  }

  /*Common functions used through out the code*/

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (new Date(f?.value) > new Date(t?.value)) {
        return {
          dates: "Status date shouldn't be earlier than Employee sign date",
        };
      }
      return {};
    };
  }
  stringIn2Digits(stringNumber: number) {
    let resultString = '';
    if (stringNumber.toString().length === 1) {
      resultString = '0' + stringNumber;
    } else {
      return stringNumber.toString();
    }
    return resultString;
  }
  removeAfterChar(value: string) {
    let resultString = '';
    if (value != null && value != '') {
      let tempString = value.split('[')[0];
      resultString = tempString.trim();
    }
    return resultString;
  }
  stringToUpperCase(value: string) {
    let resultString = '';
    if (value != null && value != '') {
      resultString = value.toUpperCase();
    }
    return resultString;
  }

  removeTimeStampFromDate(value: string) {
    let resultString = '';
    if (value != null && value != '') {
      resultString = value.split(' ')[0];
    }
    return resultString;
  }

  formatDate(date: any) {
    const empSignDate = new Date(date);
    let month = '' + (empSignDate.getMonth() + 1);
    let day = '' + empSignDate.getDate();
    const year = empSignDate.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  removePendingFromEdit(value: string) {
    let resultString = '';
    if (value != null && value != '') {
      let tempString = value.replace('PENDING ', '');
      resultString = tempString.trim();
    }
    return resultString;
  }

  getDateFromToday(noOfMonths: number) {
    var date = new Date();
    date.setMonth(date.getMonth() + noOfMonths);

    return date;
  }

  getDateEarlierFromToday(noOfMonths: number) {
    var date = new Date();
    date.setMonth(date.getMonth() - noOfMonths);

    return date;
  }

  /*Common functions for setting up CRUD button permission*/

  crudPermissionSetup(
    module: any[],
    moduleCodeName: string,
    viewCodeName: string
  ): any {
    let views: any[] = [];
    for (var i = 0; i < module.length; i++) {
      if (module[i].moduelCode === moduleCodeName) {
        for (var j = 0; j < module[i].views.length; j++) {
          if (module[i].views[j].viewCode === viewCodeName) {
            views.push(module[i].views[j]);
          }
        }
      }
    }
    return views;
  }

  /*Export to .xlsx service*/
  public exportTableElmToExcel(element: ElementRef, fileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element.nativeElement);
    // generate workbook and add the worksheet
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
    // save to file
    XLSX.writeFile(workbook, `${fileName}${EXCEL_EXTENSION}`);
  }

  // sortDir = 1; //1= 'ASE' -1= DSC
  // sortColumn = "acchours";
  callSortService(
    dataSource: any = [],
    event: any,
    fieldName: string,
    dataType: string,
    sortDir: any,
    sortColumn: string
  ) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      sortDir = 1;
    }
    sortColumn = fieldName;
    this.sortArr(fieldName, dataType, dataSource, sortDir, sortColumn);
  }

  sortArr(
    colName: any,
    dataType: string,
    dataSource: any = [],
    sortDir: any,
    sortColumn: string
  ) {
    if (dataType === 'string') {
      dataSource.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * sortDir;
      });
    } else {
      dataSource.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * sortDir;
        } else {
          return 1 * sortDir;
        }
      });
    }
  }
}
