<div
  class="modal fade"
  id="DeleteOvertimeRecordScreen"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteApplicationModalLabel"
  aria-hidden="true"
  data-backdrop="false"
>
  <div
    class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
    style="max-width: 80%"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteApplicationModalLabel">
          Delete Overtime
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>
      <br />
      <!-- filer -->
      <div
        class="jumbotron jumb-new jumb-newcontrols"
        style="margin-left: 80px; margin-right: 80px"
      >
        <br />
        <div class="row" display="float">
          <div class="col">
            <div class="form-group row">
              <div class="col-sm-3 col-form-label"></div>
              <div class="col-sm-2 col-form-label">
                <label>Overtime Date</label>
              </div>
              <div class="col-sm-3">
                <input
                  style="width: auto"
                  [(ngModel)]="searchDateOfWork"
                  type="date"
                  class="form-control"
                />
              </div>
              <div class="col-sm-2">
                <button
                  class="btn btn-lg search-button"
                  type="submit"
                  (click)="GetOvertimeData()"
                >
                  Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </button>
              </div>
              <div class="col-sm-2 col-form-label"></div>
            </div>
          </div>
        </div>
      </div>
      <div style="font-weight: 700; text-align: center">
        Overtime Group Name: {{ overtimeGroupName }}
      </div>
      <!-- filer -->
      <div
        *ngIf="
          modifiedOvertimRecords !== null && modifiedOvertimRecords.length > 0
        "
      >
        <div class="modal-body" style="padding-left: 80px; padding-right: 80px">
          <table
            class="overtime-preview-table table table-striped"
            style="position: sticky"
          >
            <thead class="table-header" style="top: -18px; position: sticky">
              <tr class="overtime-preview-table-tr">
                <th
                  class="overtime-preview-table-th"
                  style="padding-left: 10px"
                >
                  No.
                </th>
                <th
                  class="overtime-preview-table-th"
                  style="width: 70px; text-align: center"
                >
                  <input
                    type="checkbox"
                    [(ngModel)]="checkAllRecords"
                    (change)="selectAllEmployee()"
                  />
                </th>
                <th class="overtime-preview-table-th">Employee Id</th>
                <th class="overtime-preview-table-th">Name</th>

                <th class="overtime-preview-table-th">Overtime Date</th>
                <th class="overtime-preview-table-th">Paid Hours</th>
                <th class="overtime-preview-table-th">Overtime Type</th>
                <th class="overtime-preview-table-th">Overtime Reason</th>
                <th class="overtime-preview-table-th">Comment</th>
                <th class="overtime-preview-table-th">Date Offered</th>
                <th class="overtime-preview-table-th">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let val of modifiedOvertimRecords; let i = index">
                <td
                  class="overtime-preview-table-td"
                  style="padding-left: 10px"
                >
                  {{ i + 1 }}
                </td>
                <td
                  class="overtime-preview-table-td"
                  style="width: 70px; text-align: center"
                >
                  <input
                    type="checkbox"
                    (change)="onEmployeeSelect(i)"
                    [(ngModel)]="val.isselected"
                    name="OvertimeAdd"
                  />
                </td>
                <td class="overtime-preview-table-td">
                  {{ val.employeeid ? val.employeeid : 'N/A' }}
                </td>
                <td class="overtime-preview-table-td nowrapcell">
                  {{ val.lasT_NAME ? val.lasT_NAME : 'N/A' }},&nbsp;{{
                    val.firsT_NAME ? val.firsT_NAME : 'N/A'
                  }}&nbsp;{{ val.middle ? val.middle : '' }}
                </td>
                <td class="overtime-preview-table-td">
                  {{ val.dateofwork | date: 'MM/dd/yyyy' }}
                </td>
                <td class="overtime-preview-table-td">{{ val.paidhours }}</td>
                <td class="overtime-preview-table-td">
                  {{ val.overtimetypeid }}
                </td>
                <td class="overtime-preview-table-td">
                  {{ val.overtimereasoname }}
                </td>
                <td class="overtime-preview-table-td">
                  {{ val.freeformcomment }}
                </td>
                <td class="overtime-preview-table-td">
                  {{ val.dateoffered | date: 'MM/dd/yyyy' }}
                </td>
                <td class="overtime-preview-table-td">
                  {{ val.boolStatus ? 'Active' : 'Archived' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-group col-md-12">
          <div class="btn-wrapper" style="padding-right: 80px">
            <button
              style="height: 40px; width: 150px; border-radius: 10px"
              type="button"
              class="btn btn-secondary cancel-btn"
              #closeDeleteModal
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="height: 40px; width: 150px; border-radius: 10px"
              type="button"
              class="btn btn-primary save-btn"
              [disabled]="buttonDisabled"
              (click)="DeleteMassOvertimeRecord()"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <div
        *ngIf="!ismodifiedOvertimRecordsExists"
        style="text-align: center; padding-top: 2rem"
      >
        <p>No records found.</p>
      </div>
    </div>
  </div>
</div>
