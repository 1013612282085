import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { PlantDataService } from 'src/app/Services/TableMaintenance/PlantData/plant-data.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';

@Component({
  selector: 'app-plant-data',
  templateUrl: './plant-data.component.html',
  styleUrls: ['./plant-data.component.css'],
})
export class PlantDataComponent implements OnInit {
  public loading = false;
  plantsData: any[] = [];
  modalId: string = '';
  showEdit: boolean = false;
  showAdd: boolean = false;
  showDelete: boolean = false;
  selectedPlant: any;
  userInformation: any;
  views: any[] = [];
  constructor(
    private pds: PlantDataService,
    private _user: UsergroupService,
    private _commonApplicationService: CommonService
  ) {}

  ngOnInit(): void {
    this.getPlantData();
    this.getUserInfo();
  }

  getPlantData() {
    this.loading = true;
    this.pds.getPlantData().subscribe((res) => {
      this.plantsData = res;
      this.loading = false;
    });
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'TABLEMAINTENANCE',
        'PLANTDATA'
      );
    });
  }

  showAddModal() {
    this.showAdd = true;
    this.modalId = '#addEmployee';
  }

  showEditModal(plantid: any) {
    this.showEdit = true;
    this.modalId = '#editEmp';
    for (let index = 0; index < this.plantsData.length; index++) {
      if (this.plantsData[index].plantid === plantid) {
        this.selectedPlant = this.plantsData[index];
      }
    }
  }

  showDeleteModal(plantid: any) {
    this.showDelete = true;
    this.modalId = '#deleteApplicationModal';
    for (let index = 0; index < this.plantsData.length; index++) {
      if (this.plantsData[index].plantid === plantid) {
        this.selectedPlant = this.plantsData[index];
      }
    }
  }

  checkAdd(e: any) {
    this.getPlantData();
  }

  checkEdit(e: any) {
    this.getPlantData();
  }

  checkDelete(e: any) {
    this.getPlantData();
  }

  @ViewChild('plantdataTablemaintenanceExportTable', {
    read: ElementRef,
    static: false,
  })
  plantdataTablemaintenanceExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.plantdataTablemaintenanceExportTable,
        `${ApplicationEnum.PLANTDATATABLEMAINTENANCE}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.plantsData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.plantsData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
