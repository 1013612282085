export class SearchModel {

}

export class OverTimeMainSearchGroupPostModel{
    constructor(){
    }
    SEARCHTYPE : string | undefined;
    DEPTID : string | undefined;
    JOBCODEID : string | undefined;
    SHIFTID : string | undefined;
    OVERTIMETYPEID :string | undefined;
    ASOFDATE : string | undefined;

    EMPLOYEEID : string | undefined;
    STATUS : string | undefined;
    DATEOFWORKSTART : string | undefined;
    DATEOFWORKEND : string | undefined;
    
}

export class OverTimeMainSearchIndividualPostModel{
    OVERTIMETYPEID :null | undefined;
    SEARCHTYPE : string | undefined;
    EMPLOYEEID : string | undefined;
    FIRSTNAME : string | undefined;
    LASTNAME : string | undefined;
    CUT_OFF_DATE : string | undefined;
    
   
}


export class OverTimeDetailSearchPostModel{
    ATID : string | undefined;
    OVERTIMETYPEID :null | undefined;
    STATUS : string | undefined;
    DATEOFWORKSTART : string | undefined;
    DATEOFWORKEND : string | undefined;
}

export class OverTimeMainRuleEnginePostModel{    
    ATID : string | undefined;
    DEPTID : string | undefined;
    JOBCODEID : string | undefined;
    SHIFTID : string | undefined;
}