import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from './MSALservice/msalservice.service';
import { CommonUtility } from 'src/common/commonUtility'

@Injectable({
  providedIn: 'root',
})
export class UsergroupService {
  private readonly api_Get_UsersGroup_Path = '/UsergroupfromazureAD';
  private readonly UserInfo = '/userInfo';
  private readonly setLastLogin = '/SetLastLogin';

  commonUtility = new CommonUtility();
  constructor(private http: HttpClient, private msalcom: MSALserviceService) { }

  GetUserGroup(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.msalcom.getAuthToken());
    return this.http.get<any[]>(
      `${environment.userMgmtURL}` + this.api_Get_UsersGroup_Path,
      {
        headers,
      }
    );
  }
  getUserInfo(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.msalcom.getAuthToken());
    return this.http.get<any>(
      `${environment.userMgmtURL}` + this.UserInfo,
      {
        headers
      }
    );
  }

  SetLastLogin(userInfo : any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.msalcom.getAuthToken());
    return this.http.post<any>(
      `${environment.userMgmtURL}` + this.setLastLogin,
      userInfo,
      {
        headers
      }
    );
  }
}
