<div
  class="modal fade"
  id="addEmployee"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addEmployeeLabel"
  aria-hidden="true"
  data-backdrop="false"
>
  <div class="modal-dialog add-form-alignment" role="document">
    <div class="modal-content" style="position: fixed; width: 845px">
      <div class="modal-header">
        <h5 class="modal-title" id="addEmployeeLabel">Add Overtime</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>

      <form [formGroup]="employeeForm">
        <div class="modal-body" style="padding-left: 80px; padding-right: 80px">
          <div class="form-group row mb-2">
            <label class="col-sm-3 col-form-label pr-0"
              >Overtime Date<span class="text-danger text-highlight">*</span> :
            </label>
            <div class="col-sm-4 pl-0">
              <input
                formControlName="dateofwork"
                type="date"
                class="form-control"
              />
              <div
                *ngIf="
                  employeeForm.controls['dateofwork'].invalid &&
                  employeeForm.controls['dateofwork'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['dateofwork'].errors?.required"
                >
                  Overtime Date is required.
                </small>
              </div>
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-sm-3 col-form-label pr-0">
              Paid Hours<span class="text-danger text-highlight">*</span>
              :</label
            >
            <div class="col-sm-8 pl-0">
              <input
                formControlName="paidhours"
                type="number"
                step="0.25"
                pattern="(^[0-9]{0,2}$)|(^[0-9]{0,2}\.[0-9]{0,5}$)"
                class="form-control"
              />
              <div
                *ngIf="
                  employeeForm.controls['paidhours'].invalid &&
                  employeeForm.controls['paidhours'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['paidhours'].errors?.required"
                >
                  Paid Hours is required.
                </small>
              </div>
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-sm-3 col-form-label pr-0">
              Overtime Type<span class="text-danger text-highlight">*</span>:
            </label>
            <div class="col-sm-8 pl-0">
              <select
                (change)="onChangeGetOvertimeType($event)"
                formControlName="overtimetypeid"
                type="text"
                class="form-control"
              >
                <option
                  disabled
                  *ngIf="OvertimeType[0].default"
                  selected
                  value=""
                >
                  {{ OvertimeType[0].default }}
                </option>
                <option
                  *ngFor="let overtime of OvertimeType[0].data"
                  value="{{ overtime.overtimetypeid }}"
                >
                  {{ overtime.overtimetypeid }}-{{
                    overtime.overtimetypedescription
                  }}
                </option>
              </select>
              <div
                *ngIf="
                  employeeForm.controls['overtimetypeid'].invalid &&
                  employeeForm.controls['overtimetypeid'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="
                    employeeForm.controls['overtimetypeid'].errors?.required
                  "
                >
                  Overtime Type is required.
                </small>
              </div>
            </div>
          </div>
          <div class="form-group row mb-2">
            <label class="col-sm-3 col-form-label pr-0">
              Overtime Reason<span class="text-danger text-highlight">*</span
              >:</label
            >
            <div class="col-sm-8 pl-0">
              <select
                formControlName="overtimereasonid"
                class="form-control"
                id="exampleFormControlSelect1"
              >
                <option
                  disabled
                  *ngIf="OvertimeReason[0].default"
                  selected
                  value=""
                >
                  {{ OvertimeReason[0].default }}
                </option>
                <option
                  *ngFor="let overtime of newArrayForOvertimeReason"
                  value="{{ overtime.overtimereasonid }}"
                >
                  {{ overtime.overtimereasondescription }}
                </option>
              </select>
              <div
                *ngIf="
                  employeeForm.controls['overtimereasonid'].invalid &&
                  employeeForm.controls['overtimereasonid'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="
                    employeeForm.controls['overtimereasonid'].errors?.required
                  "
                >
                  Overtime Reason is required.
                </small>
              </div>
            </div>
          </div>

          <div class="form-group row mb-2">
            <label class="col-sm-3 col-form-label pr-0"> Comment :</label>
            <div class="col-sm-8 pl-0">
              <textarea
                name="text"
                rows="8"
                cols="10"
                wrap="soft"
                formControlName="freeformcomment"
                type="text"
                class="form-control"
              >
              </textarea>
              <div
                *ngIf="
                  employeeForm.controls['freeformcomment'].invalid &&
                  employeeForm.controls['freeformcomment'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="
                    employeeForm.controls['freeformcomment'].errors?.required
                  "
                >
                  Free Form Comment is required.
                </small>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label pr-0"
              >Date Offered<span class="text-danger text-highlight">*</span> :
            </label>
            <div class="col-sm-8 pl-0">
              <input
                formControlName="dateoffered"
                type="date"
                class="form-control"
              />
              <div
                *ngIf="
                  employeeForm.controls['dateofwork'].invalid &&
                  employeeForm.controls['dateofwork'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['dateofwork'].errors?.required"
                >
                  Overtime Date Offered is required.
                </small>
              </div>
            </div>
            <h6 class="alert alert-danger" *ngIf="employeeForm.errors">
              {{ employeeForm.errors.dates }}
            </h6>
          </div>
        </div>
        <div class="form-group col-md-12">
          <div class="modal-footer" style="padding-right: 80px">
            <button
              style="height: 40px; width: 150px; border-radius: 10px"
              type="button"
              class="btn popupbuttonscancel cancel-btn mr-3"
              data-dismiss="modal"
              #closeMassAddModal
              (click)="clear()"
            >
              Cancel
            </button>
            <button
              style="height: 40px; width: 150px; border-radius: 10px"
              type="button"
              class="btn btn-primary save-btn"
              data-toggle="modal"
              [attr.data-target]="modalId"
              data-backdrop="false"
              (click)="AddMassOvertimeRecord()"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="addIndividualEmployee"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addEmployeeLabel"
  aria-hidden="true"
  data-backdrop="false"
>
  <div class="modal-dialog add-form-alignment" role="document">
    <div class="modal-content" style="width: 945px">
      <div class="modal-header">
        <h5 class="modal-title" id="addEmployeeLabel">Add Employee</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>

      <!-- <h6 class="alert alert-danger" *ngIf="DateCheck">You must enter a overtime date that is the same or after the date offered.</h6>

            <h6 class="alert alert-danger" *ngIf="OvertimeTypeIdCheck">You must select overtime type.</h6>
            <h6 class="alert alert-danger" *ngIf="OvertimeReasonIdCheck">You must select overtime reason.</h6>

            <h6 class="alert alert-danger" *ngIf="DuplicateRecordsFound">Duplicate Records found.</h6> -->

      <div class="modal-body" style="padding-left: 80px; padding-right: 80px">
        <form [formGroup]="employeeForm">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Name </label>
            <div class="col-sm-8">
              <input
                formControlName="name"
                readonly
                type="email"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Employee Id </label>
            <div class="col-sm-8">
              <input
                formControlName="employeeid"
                readonly
                type="email"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Dept </label>
            <div class="col-sm-8">
              <input
                formControlName="dept"
                readonly
                type="email"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Acc Hours </label>
            <div class="col-sm-8">
              <input
                formControlName="acchours"
                readonly
                type="email"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Overtime Group </label>
            <div class="col-sm-8">
              <input
                formControlName="overtimegroupdetails"
                readonly
                type="email"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Overtime Date* : </label>
            <div class="col-sm-8">
              <input
                formControlName="dateofwork"
                type="date"
                class="form-control"
              />
              <div
                *ngIf="
                  employeeForm.controls['dateofwork'].invalid &&
                  employeeForm.controls['dateofwork'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['dateofwork'].errors?.required"
                >
                  Overtime Date is required.
                </small>
              </div>
            </div>
          </div>

          <h6
            class="alert alert-danger"
            *ngIf="OvertimeDate1MonthEarlierLaterCheck"
          >
            <span
              class="text-danger text-highlight"
              *ngIf="!OverrideIfDateOlderEarlier1Month"
              >*</span
            >
            You have entered a date that is earlier than a month ago or later
            than a month from now. If this was not an error please override.
            <input
              (change)="onChange1MonthDateOverride()"
              type="checkbox"
              name="OvertimeOverride"
            />
          </h6>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label"> Paid Hours* :</label>
            <div class="col-sm-8">
              <input
                (change)="validationsCheckPaidHours($event)"
                (keyup)="validationsCheckPaidHours($event)"
                formControlName="paidhours"
                type="number"
                step="0.25"
                pattern="(^[0-9]{0,2}$)|(^[0-9]{0,2}\.[0-9]{0,5}$)"
                class="form-control"
              />
              <div
                *ngIf="
                  employeeForm.controls['paidhours'].invalid &&
                  employeeForm.controls['paidhours'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['paidhours'].errors?.required"
                >
                  Paid Hours is required.
                </small>
              </div>
            </div>
          </div>
          <h6 class="alert alert-danger" *ngIf="PaidHoursCheck">
            You must enter the paid hours.
          </h6>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label"> Overtime Type *: </label>
            <div class="col-sm-8">
              <select
                (change)="onChangeGetOvertimeType($event)"
                formControlName="overtimetypeid"
                type="text"
                class="form-control"
              >
                <option
                  disabled
                  *ngIf="OvertimeType[0].default"
                  selected
                  value=""
                >
                  {{ OvertimeType[0].default }}
                </option>
                <option
                  *ngFor="let overtime of OvertimeType[0].data"
                  value="{{ overtime.overtimetypeid }}"
                >
                  {{ overtime.overtimetypeid }}-{{
                    overtime.overtimetypedescription
                  }}
                </option>
              </select>
              <div
                *ngIf="
                  employeeForm.controls['overtimetypeid'].invalid &&
                  employeeForm.controls['overtimetypeid'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="
                    employeeForm.controls['overtimetypeid'].errors?.required
                  "
                >
                  Overtime Type is required.
                </small>
              </div>
            </div>
          </div>

          <h6 class="alert alert-danger" *ngIf="OvertimeTypeRequiesValidations">
            <span
              class="text-danger text-highlight"
              *ngIf="!OverrideIfDateSelectedIsWeekedForOvertimeType"
              >*</span
            >
            You have chosen Overtime Type:
            {{ ErrorOvertimeType[0].overtimetypedescription }} that needs
            Overtime Date as weekend or Holiday. If this is not an error please
            override.
            <input
              type="checkbox"
              (change)="onChangeWeekendDateForOTOverride()"
              name="OvertimeOverride"
            />
          </h6>
          <h6 class="alert alert-danger" *ngIf="OverrideCheckIfOTisLRCheck">
            <span
              class="text-danger text-highlight"
              *ngIf="!OverrideCheckIfOTisLR"
              >*</span
            >
            You have chosen 'Loan Refused'. If this is not an error please
            override.
            <input
              type="checkbox"
              (change)="onChangIfOTisLR()"
              name="OvertimeOverride"
            />
          </h6>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label"> Reason *::</label>
            <div class="col-sm-8">
              <select
                formControlName="overtimereasonid"
                class="form-control"
                id="exampleFormControlSelect1"
              >
                <option
                  disabled
                  *ngIf="OvertimeReason[0].default"
                  selected
                  value=""
                >
                  {{ OvertimeReason[0].default }}
                </option>
                <option
                  *ngFor="let overtime of newArrayForOvertimeReason"
                  value="{{ overtime.overtimereasonid }}"
                >
                  {{ overtime.overtimereasondescription }}
                </option>
              </select>
              <div
                *ngIf="
                  employeeForm.controls['overtimereasonid'].invalid &&
                  employeeForm.controls['overtimereasonid'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="
                    employeeForm.controls['overtimereasonid'].errors?.required
                  "
                >
                  Overtime Reason is required.
                </small>
              </div>
            </div>
          </div>

          <h6 class="alert alert-danger" *ngIf="OverrideOTisCallinRefuseCheck">
            <span
              class="text-danger text-highlight"
              *ngIf="!OverrideOTisCallinRefuse"
              >*</span
            >
            You have chosen 'Callin-Refused' and the paid hours is not 0. If
            this is not an error please override.
            <input
              type="checkbox"
              (change)="onChangeIfOTRisCallinRefused()"
              name="OvertimeOverride"
            />
          </h6>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label"> Comment:</label>
            <div class="col-sm-8">
              <textarea
                name="text"
                rows="5"
                cols="10"
                wrap="soft"
                formControlName="freeformcomment"
                type="text"
                class="form-control"
              >
              </textarea>
              <div
                *ngIf="
                  employeeForm.controls['freeformcomment'].invalid &&
                  employeeForm.controls['freeformcomment'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="
                    employeeForm.controls['freeformcomment'].errors?.required
                  "
                >
                  Free Form Comment is required.
                </small>
              </div>
            </div>
          </div>
          <h6 class="alert alert-danger" *ngIf="OvertimeCommentCheck">
            You must Enter comment.
          </h6>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Date Offered * : </label>
            <div class="col-sm-8">
              <input
                formControlName="dateoffered"
                type="date"
                class="form-control"
              />
              <div
                *ngIf="
                  employeeForm.controls['dateoffered'].invalid &&
                  employeeForm.controls['dateoffered'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['dateoffered'].errors?.required"
                >
                  Overtime Date Offered is required.
                </small>
              </div>
            </div>
            <h6 class="alert alert-danger" *ngIf="employeeForm.errors">
              {{ employeeForm.errors.dates }}
            </h6>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Status * : </label>
            <div class="col-sm-8">
              <select formControlName="status" type="date" class="form-control">
                <option
                  *ngFor="let status of StatusType"
                  value="{{ status.status }}"
                >
                  {{ status.statusDescription }}
                </option>
              </select>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          style="height: 40px; width: 150px; border-radius: 10px"
          type="button"
          class="btn btn-secondary popupbuttonscancel"
          data-dismiss="modal"
          #closeIndAddModal
          (click)="clear()"
        >
          Cancel
        </button>
        <button
          style="height: 40px; width: 150px; border-radius: 10px"
          type="button"
          [disabled]="employeeForm.invalid || PaidHoursCheck"
          class="btn btn-primary"
          (click)="AddIndividualOvertimeRecord()"
        >
          Add
        </button>
      </div>
    </div>
  </div>
</div>

<!-- edit+selecteduserdata -->
<app-edit-overtime-maintenance
  [passAddData]="passAddData"
  [overtimeGroupName]="overtimeGroupName"
  (isMassAddSuccess)="SetValue($event)"
  [OverTimeAddValue]="OverTimeAddValue"
  [isEdit]="isEdit"
>
</app-edit-overtime-maintenance>
