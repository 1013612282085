<div class="container-fluid app-box" style="background-color: #ffffff">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Transfer Applications</li>
        <li class="breadcrumb-item brd-li brd-li-child">Transfer Agreement</li>
      </ol>
    </nav>
  </div>
  <div *ngIf="isAdmin()">
    <div class="row content-container mt-2 mb-2">
      <nav class="navbar navbar-expand-lg navbar-light bg-light applicationcss">
        <!-- <ul class="navbar-nav mr-auto bg-light navbar-light"> -->
        <!-- <li class="nav-item ">
                      <a *ngIf="!isUser" [ngStyle]="{'font-weight': filterText}" class="nav-link" style="color:white" (click)="showFilters();">Filter</a>
                  </li> -->
        <!-- </ul> -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto"></ul>
          <form class="form-inline my-2 my-lg-0">
            <!-- <select name="buttontype" class="form-control mr-sm-2" [(ngModel)]="typeSelected"
                          (ngModelChange)='onAppTypeSelected($event)'>
                          <option *ngFor="let app of screenType" [value]="app.Id">
                              {{app.Name}}
                          </option>
                      </select> -->
            <input
              class="select-radio"
              checked="checked"
              name="tool-and-die"
              type="radio"
              Id="1"
              (change)="onAppTypeSelected(1)"
            />
            <label class="select-label" for="1">Existing Application</label>
            <input
              *ngIf="views[0].allowAdd"
              class="select-radio"
              name="tool-and-die"
              type="radio"
              Id="2"
              (change)="onAppTypeSelected(2)"
            />
            <label *ngIf="views[0].allowAdd" class="select-label" for="2"
              >Add Application</label
            >
          </form>
          <!-- <button *ngIf="isUser" class="btn btn-outline-success my-2 my-sm-0" type="submit" data-toggle="modal"
            data-backdrop="false" [attr.data-target]="modalId"
            data-backdrop="false" (click)="addEmployee()">Add
                      </button> -->
          <!-- <form class="form-inline my-2 my-lg-0">
                      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"
                          (keyup.enter)="SearchByUser($event)">
                  </form> -->
        </div>
        <br />
      </nav>
      <!-- global search filter starts -->
      <div class="search-divider-div filtercss">
        <p class="search-divder-p"></p>
        <!-- filer -->
        <div
          class="jumbotron jumb-new jumb-newcontrols"
          style="margin-bottom: 0px !important"
          *ngIf="showFilter"
        >
          <div class="row" display="float">
            <div style="padding-left: 10px" class="col">
              <app-filter
                [filterConfig]="filterConfig"
                (formcontrolobj)="getbyenter($event)"
                (filterValue)="getFilterValue($event)"
              >
              </app-filter>
            </div>
            <div class="col-md-1 mr-5">
              <a
                class="btn btn-lg search-button"
                (click)="search()"
                role="button"
                >Search
                <img
                  src="./assets/search-solid.svg"
                  class="img-fluid user-icon ml-1 search-icon"
                />
              </a>
            </div>
            <!-- </div> -->
          </div>
        </div>
        <div
          class="jumbotron jumb-new"
          style="margin-bottom: 0px !important; padding: 2rem 2rem"
          *ngIf="!showFilter"
        >
          <div class="row">
            <div class="col">
              <app-filter
                (formcontrolobj)="getbyenterforuser($event)"
                [filterConfigAdd]="filterConfigAdd"
                (filterValue)="getFilterDataUser($event)"
              >
              </app-filter>
              <!-- <hr class="my-4"> -->
            </div>

            <div class="col-md-1 mr-5">
              <a
                class="btn btn-lg search-button btn-align"
                (click)="searchbyUser()"
                role="button"
                >Search
                <img
                  src="./assets/search-solid.svg"
                  class="img-fluid user-icon ml-1 search-icon"
                />
              </a>
            </div>
          </div>
        </div>
        <!-- filter ends -->
      </div>
      <div class="div-color">
        <div class="pt-5" style="padding-top: 0px !important">
          <!--For application-->
          <div class="info-label divcrudbtns" *ngIf="!isApplication">
            <p
              style="text-align: left"
              *ngIf="!isExistingRecordsDatalength"
              class="p-margin"
            ></p>
            <div *ngIf="views[0].allowUpdate">
              <button
                [disabled]="disablededit"
                [ngClass]="disablededit ? 'disablededitcolors' : ''"
                *ngIf="
                  (!EditButtonDisabled && isApplicationSelected()) ||
                  !EditButtonDisabled1
                "
                (click)="edit()"
                data-toggle="modal"
                data-backdrop="false"
                [attr.data-target]="modalId"
                data-backdrop="false"
                class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
              >
                Edit
              </button>
            </div>
            <div *ngIf="views[0].allowDelete">
              <button
                [disabled]="disableddelete"
                [ngClass]="disableddelete ? 'disablededitcolors' : ''"
                *ngIf="isApplicationSelected() || !DeleteButtonDisabled1"
                style="margin: 0.5rem"
                class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
                data-toggle="modal"
                data-backdrop="false"
                [attr.data-target]="modalId"
                data-backdrop="false"
                (click)="deleteApplication()"
              >
                Delete
              </button>
            </div>
            <div *ngIf="views[0].allowCancel">
              <!-- *ngIf="!CancelButtonDisabled && isApplicationSelected()" -->
              <button
                [disabled]="disabledcancel"
                [ngClass]="disabledcancel ? 'disablededitcolors' : ''"
                *ngIf="
                  (!CancelButtonDisabled && isApplicationSelected()) ||
                  !CancelButtonDisabled1
                "
                class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
                data-toggle="modal"
                data-backdrop="false"
                [attr.data-target]="modalId"
                data-backdrop="false"
                (click)="cancelApplication()"
              >
                Cancel
              </button>
            </div>
            <div
              *ngIf="emloyeeData !== null && emloyeeData.length > 0"
              style="padding-left: 10px"
            >
              <button
                class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
                (click)="exportElmToExcel()"
              >
                Export to Excel
              </button>
            </div>
          </div>
          <div *ngIf="!isApplication">
            <!-- This is Export to document Section -->
            <table
              class="table table-striped"
              *ngIf="
                !isDataExportableView &&
                emloyeeData !== null &&
                emloyeeData.length > 0
              "
              #TransferAgreementExportTable
            >
              <thead class="table-header">
                <tr>
                  <!-- <th class="th-height" scope="col">Select</th> -->
                  <!-- <th class="th-height" scope="col">Name</th>
                                    <th class="th-height" scope="col">Plant Seniority Date</th>
                                    <th class="th-height" scope="col">GM Seniority Date</th>
                                    <th class="th-height" style="border-right: 2px solid grey" scope="col">Employee Id</th>
                                    <th class="th-height" scope="col">App.#</th>
                                    <th class="th-height" style="width: 140px" scope="col">Date Signed</th>
                                    <th class="th-height" scope="col">Shift</th>
                                    <th class="th-height" scope="col">Classification</th>
                                    <th class="th-height" scope="col">Plant</th>
                                    <th class="th-height" scope="col">Department</th>
                                    <th class="th-height" scope="col">Status</th> -->
                  <th class="th-height" scope="col">
                    <span (click)="onSortClick($event, 'lastName', 'string')"
                      >Name
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'lastName'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'lastName'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'lastName'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'plantSeniorityDateSort', 'string')
                      "
                      >Plant Sen Date
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'plantSeniorityDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'seniorityDateSort', 'string')
                      "
                      >GM Sen Date
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'seniorityDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'seniorityDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'seniorityDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th
                    class="th-height"
                    scope="col"
                    style="border-right: 2px solid grey"
                  >
                    <span (click)="onSortClick($event, 'employeeId', 'string')"
                      >Emp Id
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'employeeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'employeeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'employeeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span (click)="onSortClick($event, 'appNo', 'string')"
                      >App No
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'appNo'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'appNo'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'appNo'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span
                      (click)="onSortClick($event, 'empSignDateSort', 'string')"
                      >Date Signed
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'empSignDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'shiftDescription', 'string')
                      "
                      >Shift
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'shiftDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span (click)="onSortClick($event, 'jobCodeId', 'string')"
                      >class
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'jobCodeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'jobCodeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'jobCodeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'plantDescription', 'string')
                      "
                      >Plant
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'plantDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'plantDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'plantDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span (click)="onSortClick($event, 'deptId', 'string')"
                      >Dept
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'deptId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'deptId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'deptId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span
                      (click)="onSortClick($event, 'appStatusCode', 'string')"
                      >Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'appStatusCode'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'appStatusCode'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'appStatusCode'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let emp of emloyeeData; let i = index"
                  class="table-container"
                >
                  <!-- <td style="text-align: center">
                                        <input type="radio" id="select" name="application" (click)="selectEmp(i,emp.employeeId, emp.isAppTimely,emp.appNo)" />
                                    </td> -->
                  <td class="left-tab-value">
                    {{ emp.lastName ? emp.lastName : "-" }},&nbsp;{{
                      emp.firstName ? emp.firstName : "-"
                    }}&nbsp;{{ emp.middleName ? emp.middleName : "" }}
                    <!-- class='tab-value'> -->
                  </td>

                  <td class="tab-value">
                    {{
                      emp.plantSeniorityDate
                        ? (emp.plantSeniorityDate | date : "MM/dd/yyyy")
                        : "-"
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      emp.seniorityDate
                        ? (emp.seniorityDate | date : "MM/dd/yyyy")
                        : "-"
                    }}
                  </td>
                  <td class="tab-value">
                    {{ emp.employeeId ? emp.employeeId : "-" }}
                  </td>
                  <td class="tab-value">{{ emp.appNo ? emp.appNo : "-" }}</td>
                  <td class="tab-value">
                    {{ emp.empSignDate | date : "MM/dd/yyyy" }}
                  </td>
                  <td class="tab-value">
                    {{ emp.shiftDescription ? emp.shiftDescription : "-" }}
                  </td>
                  <td class="tab-value">
                    {{ emp.jobCodeId ? emp.jobCodeId : "-" }} -
                    {{ emp.jobCodeDescription ? emp.jobCodeDescription : "-" }}
                  </td>
                  <td class="tab-value">
                    {{
                      emp.plantId === "00" ? emp.plantDescription : emp.plantId
                    }}
                  </td>
                  <td *ngIf="emp.deptId !== null" class="tab-value">
                    {{
                      emp.deptId === "0000" ? emp.deptDescription : emp.deptId
                    }}
                  </td>
                  <td *ngIf="emp.deptId === null" class="tab-value">
                    {{ emp.deptDescription }}
                  </td>
                  <td class="left-tab-value">
                    {{ emp.appStatusCode ? emp.appStatusCode : "-" }}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- This is Main Table Section -->
            <table
              class="table table-striped"
              *ngIf="emloyeeData !== null && emloyeeData.length > 0"
            >
              <thead class="table-header">
                <tr>
                  <th
                    class="table-height"
                    style="border-right: 2px solid grey"
                    colspan="5"
                    scope="col"
                  >
                    Employee Information
                  </th>
                  <th class="table-height" colspan="7" scope="col">
                    Application Information
                  </th>
                  <!-- <th class="th-height" scope="col"></th>
                              <th class="th-height" scope="col"></th> -->
                </tr>
                <!-- </thead> -->
                <!-- <thead  style="position:sticky;top:423px;" class="table-header"> -->
                <tr>
                  <th class="th-height" scope="col">Select</th>
                  <!-- <th class="th-height" scope="col">Name</th>
                                    <th class="th-height" scope="col">Plant Seniority Date</th>
                                    <th class="th-height" scope="col">GM Seniority Date</th>
                                    <th class="th-height" style="border-right: 2px solid grey" scope="col">
                                        Employee Id
                                    </th>
                                    <th class="th-height" scope="col">App.#</th>
                                    <th class="th-height" style="width: 140px" scope="col">
                                        Date Signed
                                    </th>
                                    <th class="th-height" scope="col">Shift</th>
                                    <th class="th-height" scope="col">Classification</th>
                                    <th class="th-height" scope="col">Plant</th>
                                    <th class="th-height" scope="col">Department</th>
                                    <th class="th-height" scope="col">Status</th> -->

                  <th class="th-height" scope="col">
                    <span (click)="onSortClick($event, 'lastName', 'string')"
                      >Name
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'lastName'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'lastName'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'lastName'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'plantSeniorityDateSort', 'string')
                      "
                      >Plant Sen Date
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'plantSeniorityDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'plantSeniorityDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'seniorityDateSort', 'string')
                      "
                      >GM Sen Date
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'seniorityDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'seniorityDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'seniorityDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th
                    class="th-height"
                    scope="col"
                    style="border-right: 2px solid grey"
                  >
                    <span (click)="onSortClick($event, 'employeeId', 'string')"
                      >Emp Id
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'employeeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'employeeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'employeeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span (click)="onSortClick($event, 'appNo', 'string')"
                      >App No
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'appNo'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'appNo'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'appNo'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span
                      (click)="onSortClick($event, 'empSignDateSort', 'string')"
                      >Date Signed
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'empSignDateSort'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'empSignDateSort'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'shiftDescription', 'string')
                      "
                      >Shift
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'shiftDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'shiftDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span (click)="onSortClick($event, 'jobCodeId', 'string')"
                      >class
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'jobCodeId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'jobCodeId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'jobCodeId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'plantDescription', 'string')
                      "
                      >Plant
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'plantDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'plantDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'plantDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span (click)="onSortClick($event, 'deptId', 'string')"
                      >Dept
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'deptId'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'deptId'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'deptId'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th class="th-height" scope="col">
                    <span
                      (click)="onSortClick($event, 'appStatusCode', 'string')"
                      >Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'appStatusCode'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'appStatusCode'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'appStatusCode'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let emp of emloyeeData
                      | paginate
                        : {
                            id: 'joblist',
                            itemsPerPage: 20,
                            currentPage: Applicationpage,
                            totalItems: TotalApplicationCount
                          };
                    let i = index
                  "
                  class="table-container"
                >
                  <td style="text-align: center">
                    <input
                      type="radio"
                      id="select"
                      name="application"
                      (click)="
                        selectEmp(i, emp.employeeId, emp.isAppTimely, emp.appNo)
                      "
                    />
                  </td>
                  <td class="left-tab-value">
                    {{ emp.lastName ? emp.lastName : "-" }},&nbsp;{{
                      emp.firstName ? emp.firstName : "-"
                    }}&nbsp;{{ emp.middleName ? emp.middleName : "" }}
                    <!-- class='tab-value'> -->
                  </td>

                  <td class="tab-value">
                    {{
                      emp.plantSeniorityDate
                        ? (emp.plantSeniorityDate | date : "MM/dd/yyyy")
                        : "-"
                    }}
                  </td>
                  <td class="tab-value">
                    {{
                      emp.seniorityDate
                        ? (emp.seniorityDate | date : "MM/dd/yyyy")
                        : "-"
                    }}
                  </td>
                  <td class="tab-value">
                    {{ emp.employeeId ? emp.employeeId : "-" }}
                  </td>
                  <td class="tab-value">{{ emp.appNo ? emp.appNo : "-" }}</td>
                  <td class="tab-value">
                    {{ emp.empSignDate | date : "MM/dd/yyyy" }}
                  </td>
                  <td class="tab-value">
                    {{ emp.shiftDescription ? emp.shiftDescription : "-" }}
                  </td>
                  <td class="tab-value">
                    {{ emp.jobCodeId ? emp.jobCodeId : "-" }} -
                    {{ emp.jobCodeDescription ? emp.jobCodeDescription : "-" }}
                  </td>
                  <td class="tab-value">
                    {{
                      emp.plantId === "00" ? emp.plantDescription : emp.plantId
                    }}
                  </td>
                  <td
                    *ngIf="emp.deptId !== null && emp.deptDescription !== null"
                    class="tab-value"
                  >
                    {{ emp.deptId }} - {{ emp.deptDescription }}
                  </td>
                  <td *ngIf="emp.deptId === null" class="tab-value">
                    {{ emp.deptDescription }}
                  </td>
                  <td class="left-tab-value">
                    {{ emp.appStatusCode ? emp.appStatusCode : "-" }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="single-wrap d-flex justify-content-center"
              *ngIf="emloyeeData !== null && emloyeeData.length > 0"
            >
              <nav aria-label="Page navigation example">
                <pagination-controls
                  class="my-pagination"
                  id="joblist"
                  (pageChange)="Applicationpage = $event"
                  previousLabel=""
                  nextLabel=""
                >
                </pagination-controls>
              </nav>
            </div>

            <div *ngIf="!isApplication">
              <p *ngIf="isExistingRecordsDatalength">
                No existing records Found
              </p>
            </div>
          </div>
          <!--For user-->
          <div
            style="
              height: 15px;
              background: white;
              position: sticky;
              top: 146px;
            "
          ></div>
          <div *ngIf="isApplication">
            <!-- <div class="add-application-table-top"></div> -->
            <table
              class="table table-striped"
              *ngIf="userData !== null && userData.length > 0"
            >
              <thead
                class="table-header"
                style="position: sticky; top: 160px !important"
              >
                <th style="padding-left: 20px" scope="col">Name</th>
                <th scope="col">Employee Id</th>
                <th scope="col">Classification</th>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let emp of userData
                      | paginate
                        : {
                            id: 'userlist',
                            itemsPerPage: 20,
                            currentPage: Userpage,
                            totalItems: TotalUserCount
                          };
                    let i = index
                  "
                  class="table-container"
                >
                  <td class="left-tab-value">
                    <p
                      class="btn my-2 my-sm-0 click-add"
                      type="submit"
                      data-toggle="modal"
                      data-backdrop="false"
                      [attr.data-target]="modalId"
                      data-backdrop="false"
                      (click)="addEmployee(i, emp.employeeId)"
                    >
                      {{ emp.lastName ? emp.lastName : "-" }},&nbsp;{{
                        emp.firstName ? emp.firstName : "-"
                      }}&nbsp;{{ emp.middleName ? emp.middleName : "" }}
                    </p>
                  </td>
                  <td class="tab-value">
                    {{ emp.employeeId ? emp.employeeId : "-" }}
                  </td>
                  <td class="tab-value">
                    {{ emp.jobCodeId ? emp.jobCodeId : "-" }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="single-wrap d-flex justify-content-center"
              *ngIf="userData !== null && userData.length > 0"
            >
              <nav aria-label="Page navigation example">
                <pagination-controls
                  class="my-pagination"
                  id="userlist"
                  (pageChange)="Userpage = $event"
                  previousLabel=""
                  nextLabel=""
                >
                </pagination-controls>
              </nav>
            </div>
          </div>
          <div *ngIf="isApplication">
            <p *ngIf="isUserListDataEmpty">No User Found</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isAdmin()">
    <div
      *ngIf="userInformation !== undefined && userInformation !== null"
      class="row content-container mt-2 mb-2"
    >
      <!-- global search filter starts -->
      <div class="pt-2 div-color">
        <div class="search-divider-div">
          <p class="search-divder-p"></p>

          <div
            class="jumbotron jumb-new jumb-newcontrols"
            style="margin-bottom: -2px"
          >
            <div class="row userinfo-header-div" display="float">
              <div class="col">
                <h1 class="h1-label">
                  Welcome! {{ userInformation.firstName }}
                </h1>
              </div>
              <div
                *ngIf="isAddButtonEnabled"
                class="col-md-1 mr-5 add-button-div"
              >
                <button
                  class="btn btn-lg search-button add-button-a button-align"
                  type="submit"
                  data-toggle="modal"
                  data-backdrop="false"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                  (click)="addApplication()"
                  role="button"
                >
                  Add Application
                </button>
              </div>
            </div>
          </div>
          <div class="empdescription-box">
            <div class="empdescription-table">
              <table>
                <thead class="user-table-header">
                  <th class="empdescription-th emp-atid">EmployeeId:</th>
                  <th class="empdescription-th">Current Classification:</th>
                  <th class="empdescription-th">Current Department:</th>
                  <th class="empdescription-th">Current Shift:</th>
                </thead>
                <tr *ngIf="usersData !== undefined">
                  <td class="empdescription-table-tr tab-value">
                    {{ usersData.employeeId }}
                  </td>
                  <td class="empdescription-table-tr tab-value">
                    {{ usersData.jobCodeId }} {{ usersData.jobCodeDescription }}
                  </td>
                  <td class="empdescription-table-tr tab-value">
                    {{ usersData.deptId }}
                  </td>
                  <td class="empdescription-table-tr tab-value">
                    {{ usersData.shiftDescription }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- filter ends -->
        </div>
        <div class="pt-5">
          <!--For application-->
          <div>
            <div class="info-label button-label">
              <p style="text-align: left; display: inline-block">
                <button
                  *ngIf="
                    isApplicationSelected() &&
                    !CancelButtonDisabled &&
                    applicationData !== undefined &&
                    applicationData.length > 0
                  "
                  class="btn btn-success buttoncolours pull-right my-2 my-sm-0"
                  data-toggle="modal"
                  data-backdrop="false"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                  (click)="cancelApplication()"
                >
                  Cancel
                </button>
              </p>
              <p style="text-align: right; display: inline-block">
                <button
                  disabled
                  *ngIf="
                    applicationData !== undefined && applicationData.length > 0
                  "
                  class="btn btn-success buttoncolours pull-right my-2 my-sm-0 application-count"
                >
                  Remaining Applications: {{ noOfOpenApplications }}
                </button>
              </p>
            </div>

            <div *ngIf="ruleEngineValidation === false">
              <div class="text-danger">
                <h3 *ngFor="let error of ruleEngineMessage">*{{ error }}</h3>
              </div>
            </div>
            <hr />
            <table
              class="table table-striped"
              *ngIf="applicationData !== null && applicationData.length > 0"
            >
              <thead class="user-table-header">
                <th scope="col">#</th>
                <!-- <th scope="col">AppNo</th>
                                <th scope="col">Date Signed</th>
                                <th scope="col">Classification</th>
                                <th scope="col">Plant</th>
                                <th scope="col">Department</th>
                                <th scope="col">Shift</th>
                                <th scope="col">Status</th> -->
                <th class="th-height" scope="col">
                  <span (click)="onSortClickEmp($event, 'appNo', 'string')"
                    >AppNo
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'appNo'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'appNo'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'appNo'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th class="th-height" scope="col">
                  <span
                    (click)="
                      onSortClickEmp($event, 'empSignDateSort', 'string')
                    "
                    >Date Signed
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'empSignDateSort'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'empSignDateSort'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'empSignDateSort'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th class="th-height" scope="col">
                  <span (click)="onSortClickEmp($event, 'jobCodeId', 'string')"
                    >Classification
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'jobCodeId'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'jobCodeId'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'jobCodeId'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th class="th-height" scope="col">
                  <span
                    (click)="
                      onSortClickEmp($event, 'plantDescription', 'string')
                    "
                    >Plant
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'plantDescription'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'plantDescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'plantDescription'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th class="th-height" scope="col">
                  <span
                    (click)="
                      onSortClickEmp($event, 'deptDescription', 'string')
                    "
                    >Department
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'deptDescription'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'deptDescription'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'deptDescription'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th class="th-height" scope="col">
                  <span
                    (click)="
                      onSortClickEmp($event, 'shiftDescription', 'string')
                    "
                    >Shift
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'shiftDescription'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="
                        sortDir === -1 && sortColumn === 'shiftDescription'
                      "
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'shiftDescription'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
                <th class="th-height" scope="col">
                  <span
                    (click)="onSortClickEmp($event, 'appStatusCode', 'string')"
                    >Status
                    <img
                      *ngIf="sortDir === 1 && sortColumn === 'appStatusCode'"
                      src="./assets/downarrow.png"
                    />
                    <img
                      *ngIf="sortDir === -1 && sortColumn === 'appStatusCode'"
                      src="./assets/uparrow.png"
                    />
                    <img
                      *ngIf="sortDir !== 0 && sortColumn !== 'appStatusCode'"
                      src="./assets/nosort.png"
                    />
                  </span>
                </th>
              </thead>
              <tbody>
                <tr
                  *ngFor="let emp of applicationData; let i = index"
                  class="table-container"
                >
                  <td>
                    <input
                      type="radio"
                      id="select"
                      name="application"
                      (click)="selectUserEmp(i)"
                    />
                  </td>

                  <td class="tab-value">{{ emp.appNo ? emp.appNo : "-" }}</td>
                  <td class="tab-value">
                    {{ emp.empSignDate | date : "MM/dd/yyyy" }}
                  </td>
                  <td class="left-tab-value">
                    {{ emp.jobCodeId ? emp.jobCodeId : "-" }} -
                    {{ emp.jobCodeDescription ? emp.jobCodeDescription : "" }}
                  </td>
                  <td class="left-tab-value">
                    {{
                      emp.plantId === "00" ? emp.plantDescription : emp.plantId
                    }}
                    - {{ emp.plantDescription ? emp.plantDescription : "" }}
                  </td>
                  <td *ngIf="emp.deptId !== null" class="left-tab-value">
                    {{
                      emp.deptId === "0000" ? emp.deptDescription : emp.deptId
                    }}
                    - {{ emp.deptDescription ? emp.deptDescription : "" }}
                  </td>
                  <td *ngIf="emp.deptId === null" class="left-tab-value">
                    {{ emp.deptDescription }}
                  </td>
                  <td class="tab-value">
                    {{ emp.shiftDescription ? emp.shiftDescription : "-" }}
                  </td>
                  <td class="left-tab-value">
                    {{ emp.appStatusCode ? emp.appStatusCode : "-" }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="!isApplication">
              <p
                *ngIf="applicationData !== null && applicationData.length === 0"
              >
                Your requested application will display here. <br />
                Note: You can not edit your application.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Add / Edit Modal -->

  <app-edit-transfer-agreement
    [selectedEmp]="selectedEmp"
    [isLoggedInUserAdmin]="isLoggedInUserAdmin"
    [userInformation]="userInformation"
    [isEdit]="isEdit"
    (clean)="cleanEmp($event)"
    (isSucess)="CheckEdit($event)"
    [title]="modalTitle"
    *ngIf="isEdit"
  >
  </app-edit-transfer-agreement>

  <app-add-transfer-agreement
    [selectedEmp]="selectedEmp"
    [AdminAtid]="AdminAtid"
    [isLoggedInUserAdmin]="isLoggedInUserAdmin"
    [isAdd]="isAdd"
    (clean)="cleanEmp($event)"
    (isSucess)="CheckAdd($event)"
    *ngIf="isAdd"
  ></app-add-transfer-agreement>

  <!-- Delete Modal -->

  <app-delete-transfer-agreement
    [selectedEmp]="selectedEmp"
    [employeeForm]="employeeForm"
    (isSucess)="CheckDelete($event)"
  ></app-delete-transfer-agreement>

  <!-- Cancel Modal -->
  <app-cancel-transfer-agreement
    [selectedEmp]="selectedEmp"
    [userInformation]="userInformation"
    [isLoggedInUserAdmin]="isLoggedInUserAdmin"
    (isSucess)="CheckCancel($event)"
    [employeeForm]="employeeForm"
  >
  </app-cancel-transfer-agreement>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
