import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class ClassificationDataService {
  commonUtility = new CommonUtility();
  private readonly api_getApplicationTypes = '/ClassificationSearch';
  private readonly api_getNIOGroups = '/ClassificationDataGetNIOGroups';
  private readonly api_addClassification = '/ClassificationDataAdd'
  private readonly api_deleteClassification = '/ClassificationDataDelete?jobcodeid='
  private readonly api_editClassification = '/ClassificationDataUpdate'

  constructor(private _http: HttpClient, private appCom: MSALserviceService) { }

  getClassificationData(searchData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_getApplicationTypes,
      searchData,
      { headers }
    );
  }

  getNIOGroups(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getNIOGroups,
      { headers }
    );
  }

  editClassification(classificationData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.tableMaintenanceURL}` + this.api_editClassification,
      classificationData,
      { headers }
    );
  }

  addClassification(classificationData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
      `${environment.tableMaintenanceURL}` + this.api_addClassification,
      classificationData,
      { headers }
    );
  }

  deleteClassification(jobcodeId: string): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.appCom.getAuthToken());
    return this._http.delete<any>(
      `${environment.tableMaintenanceURL}` + this.api_deleteClassification + jobcodeId,
      { headers }
    );
  }
}
