import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class MedicalRestrictionsService {

  private readonly getMedicalRestrictionsData = '/MedicalRestrictionsViewsSearch';

  constructor(private http: HttpClient, private msalcom: MSALserviceService) { }
  
  commonUtility = new CommonUtility();

  getAlMedicalRestrictions(medicalRestrictiondata : any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(this.msalcom.getAuthToken());
    return this.http.post<any[]>(
      `${environment.tableMaintenanceURL}` + this.getMedicalRestrictionsData,
      medicalRestrictiondata,
      {
        headers,
      }
    );
  }
}
