import { HttpErrorResponse } from '@angular/common/http';
import {
  ElementRef,
  ViewChild,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { RepresentationGroupDataService } from 'src/app/Services/TableMaintenance/RepresentationGroupData/representation-group-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-representation-group-data',
  templateUrl: './add-representation-group-data.component.html',
  styleUrls: ['./add-representation-group-data.component.css'],
})
export class AddRepresentationGroupDataComponent implements OnInit {
  @ViewChildren('checkboxes') checkboxes!: QueryList<ElementRef>;
  @ViewChild('closeAddModel') closeAddModal!: ElementRef;
  @Input() userName: any;
  @Output() isSucess = new EventEmitter();
  repGroupForm!: FormGroup;
  enableOverride: boolean = false;
  disableAdd: boolean = false;
  classification: any[] = [];
  department: any[] = [];
  zones: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];
  districts: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];
  shifts: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];
  Status: any[] = [
    {
      data: [
        {
          statusType: 'A',
          statusName: 'Active',
        },
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
      ],
      default: 'Select One',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private rds: RepresentationGroupDataService,
    private _commonApplicationService: CommonService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.getClassification();
    this.getDepartment();
    this.getDistricts();
    this.getZones();
    this.getShifts();
  }

  createForm() {
    this.repGroupForm = this.fb.group({
      RepresentationGroupName: ['', [Validators.required]],
      RepresentationGroupDescription: ['', [Validators.required]],
      ShiftId: ['', [Validators.required]],
      ZoneId: ['', [Validators.required]],
      DistrictId: ['', [Validators.required]],
      Status: ['', [Validators.required]],
      CreatedBy: [this.userName],
      DepartmentArray: this.fb.array([]),
      ClassificationArray: this.fb.array([]),
      MixedClassOverride: [false],
    });
  }

  getClassification() {
    this.rds.getClassifications().subscribe((data) => {
      this.classification = data;
    });
  }

  getDepartment() {
    this.rds.getDepartments().subscribe((data) => {
      this.department = data;
    });
  }

  getDistricts() {
    this.rds.getDistricts().subscribe((data) => {
      this.districts[0].data = data;
    });
  }

  getZones() {
    this.rds.getZones().subscribe((data) => {
      this.zones[0].data = data;
    });
  }

  getShifts() {
    this._commonApplicationService.getShiftsList().subscribe((data) => {
      this.shifts[0].data = data;
    });
  }

  clear() {
    this.closeAddModal.nativeElement.click();
    this.createForm();
    this.enableOverride = false;
    this.uncheckAll();
  }

  uncheckAll() {
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
  }

  submitRepGroupData() {
    this.enableOverride = false;
    this.rds.addRepGroupData(this.repGroupForm.value).subscribe(
      () => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        if (_error.status === 500) {
          this.badRequestAlert(_error.error);
        } else if (_error.status === 406) {
          this.setValue(this.enableOverride);
        } else {
          this.badRequestAlert(_error.error);
        }
      }
    );
  }

  onClassCheckboxChange(e: any) {
    const ClassificationArray: FormArray = this.repGroupForm.get(
      'ClassificationArray'
    ) as FormArray;
    if (e.target.checked) {
      ClassificationArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      ClassificationArray.controls.forEach((item: any) => {
        if (item.value === e.target.value) {
          ClassificationArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  onDeptCheckboxChange(e: any) {
    const DepartmentArray: FormArray = this.repGroupForm.get(
      'DepartmentArray'
    ) as FormArray;
    if (e.target.checked) {
      DepartmentArray.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      DepartmentArray.controls.forEach((item: any) => {
        if (item.value === e.target.value) {
          DepartmentArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  setValue(status: boolean) {
    if (!status) {
      this.enableOverride = !this.enableOverride;
    }
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'The representation group has been added successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
