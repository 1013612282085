import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { FacilitiesServicePlantService } from 'src/app/Services/Applications/FacilitiesAndService/facilities-service-plant.service';
import { Paragraph19Service } from 'src/app/Services/Applications/Paragraph19/paragraph19.service';
import { ProductionToSkilledService } from 'src/app/Services/Applications/ProductionToSkilled/production-to-skilled.service';
import { ShiftPreferenceService } from 'src/app/Services/Applications/ShiftPreference/shift-preference.service';
import { SkilledToSkilledService } from 'src/app/Services/Applications/SkilledToSkilled/skilled-to-skilled.service';
import { TransferAgreementService } from 'src/app/Services/Applications/TransferAgreementService/transfer-agreement.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { UserRoles } from 'src/enum/userroles';
import { userData } from 'src/models/userdata';
import { UserInfo } from 'src/models/userinfo';

@Component({
  selector: 'app-view-all-my-appliactions',
  templateUrl: './view-all-my-appliactions.component.html',
  styleUrls: ['./view-all-my-appliactions.component.css'],
})
export class ViewAllMyAppliactionsComponent implements OnInit {
  userRole: UserRoles = UserRoles.SKILLEDUSER; // Enum for user role
  userInformation!: UserInfo; // Model for UserInfo
  usersData!: userData; // Model for UAW user details

  filterData: any = {};
  prodToSkillData: any = [];
  skillToSkillData: any = [];
  recallRightsData: any = [];
  shiftPrefData: any = [];
  TAData: any = [];
  p19Data: any = [];
  Skill150Data: any = [];
  allAppData: any = [];

  shiftPrefRecods: boolean = false;
  skillToSkillRec: boolean = false;
  prodToSkillRec: boolean = false;
  recallRightsRec: boolean = false;
  transferAgreeRec: boolean = false;
  P19Rec: boolean = false;
  Skill150Rec: boolean = false;
  allAppDataRec: boolean = false;

  shiftPrefView: boolean = false;
  skillToSkillView: boolean = false;
  prodToSkillView: boolean = false;
  recallRightsView: boolean = false;
  transferAgreeView: boolean = false;
  P19View: boolean = false;
  Skill150View: boolean = false;
  allAppView: boolean = false;

  FSType: boolean = false;
  TDType: boolean = false;

  filterConfig: any = [
    {
      label: 'Application Type',
      type: 'dropdown',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'appTYpe',
      width: '207px',
      runClassification: false,
    },
    {
      label: 'Status',
      type: 'dropdown',
      data: ['option 1', 'option 2'],
      default: 'All',
      controlName: 'appStatusCode',
      width: '91px',
      runClassification: false,
    },
  ];

  constructor(
    public _userInfo: UsergroupService,
    private _commonApplicationService: CommonService,
    private ps: ProductionToSkilledService,
    private ss: SkilledToSkilledService,
    private _shiftApplicationPreference: ShiftPreferenceService,
    private _transferAgreementService: TransferAgreementService,
    private p19: Paragraph19Service,
    private fs: FacilitiesServicePlantService
  ) {}

  ngOnInit(): void {
    this.getUserInfo();
    this.getStatus();
    this.getUserRoleDescreption();
  }

  getStatus() {
    const elements: any = [
      {
        statusId: 'OPEN',
        statusDescription: 'OPEN',
      },
      {
        statusId: 'CLOSED',
        statusDescription: 'CLOSED',
      },
      {
        statusId: 'CANCELLED',
        statusDescription: 'CANCELLED',
      },
    ];
    const mapElement = elements.map(
      (element: { statusId: string; statusDescription: string }) => ({
        id: element.statusId,
        description: element.statusDescription,
      })
    );
    for (let index = 0; index < this.filterConfig.length; index++) {
      if (this.filterConfig[index].label === 'Status') {
        this.filterConfig[index].data = mapElement;
      }
    }
  }

  getFilterValue(event: any) {
    this.filterData = event;
  }

  views: any;
  userData: any;
  jobClass: any;
  getUserRoleDescreption() {
    this._userInfo.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      //console.log(this.userInformation.firstName);
      let module_Applications = data['moduels'];
      this.jobClass = data['jobClassification'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'VIEWS',
        'VIEWALLMYAPPLICATIONS'
      );
      this.ps
        .searchFilterApplicationUser({
          employeeId: this.userInformation.employeeId,
        })
        .subscribe((res) => {
          this.userData = res;
          this.usersData = res[0];
        });
    });
  }

  role: string = '';
  newDataSet: any = [];
  viewArray: any = [];
  applicationArray: any = [];
  aType: any;
  appName: any;
  isSkill150: boolean = false;
  getUserInfo() {
    this._userInfo.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      this.role = data['groupDescription'];
      localStorage.setItem('role', JSON.stringify(data['groupDescription']));
      this.newDataSet = data['moduels'];
      this.viewArray = this.newDataSet[0].views;

      for (var i = 0; i < this.viewArray.length; i++) {
        this.isSkill150 = false;
        if (
          (this.viewArray[i].viewCode === 'TOOLSANDDIEMAKER' ||
            this.viewArray[i].viewCode === 'FACILITYSERVICE') &&
          this.applicationArray.length === 0
        ) {
          this.aType = '150';
          this.appName = 'Skilled 150 Transfer';
        }
        if (
          (this.viewArray[i].viewCode === 'TOOLSANDDIEMAKER' ||
            this.viewArray[i].viewCode === 'FACILITYSERVICE') &&
          this.applicationArray.length != 0
        ) {
          for (var j = 0; j < this.applicationArray.length; j++) {
            if (this.applicationArray[j].appType === '150') {
              this.isSkill150 = true;
            } else {
              this.aType = '150';
              this.appName = 'Skilled 150 Transfer';
            }
          }
        }

        if (this.viewArray[i].viewCode === 'SKILLEDTOSKILEED') {
          this.aType = '152';
          this.appName = this.viewArray[i].viewName;
        }
        if (this.viewArray[i].viewCode === 'SHIFTPREFERENCE') {
          this.aType = 'SP';
          this.appName = this.viewArray[i].viewName;
        }
        if (this.viewArray[i].viewCode === 'PRODUCTIONTOSKILEED') {
          this.aType = '178';
          this.appName = this.viewArray[i].viewName;
        }
        if (this.viewArray[i].viewCode === 'TRANSFERAGREEMENT') {
          this.aType = 'TA';
          this.appName = this.viewArray[i].viewName;
        }
        if (this.viewArray[i].viewCode === 'PARAGRAPH19') {
          this.aType = 'P19';
          this.appName = this.viewArray[i].viewName;
        }
        if (this.viewArray[i].viewCode != 'RECALLRIGHTS' && !this.isSkill150) {
          var obj = {
            appType: this.aType,
            appDesc: this.appName,
          };
          this.applicationArray.push(obj);
        }
      }
      //console.log(this.applicationArray);
      const mapElement = this.applicationArray.map(
        (element: { appType: string; appDesc: string }) => ({
          id: element.appType,
          description: element.appDesc,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Application Type') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  searchApplications() {
    let queryParams = this.filterData;
    Object.keys(queryParams).forEach(
      (k) =>
        !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]
    );
    queryParams.employeeId = this.userInformation.employeeId;

    if (queryParams.appTYpe === '150') {
      this.Skill150View = true;
      this.skillToSkillView = false;
      this.prodToSkillView = false;
      this.P19View = false;
      this.recallRightsView = false;
      this.transferAgreeView = false;
      this.shiftPrefView = false;

      this.fs.searchFilterApplication(queryParams).subscribe((data) => {
        this.Skill150Data = data;
        this.Skill150Rec = this.Skill150Data.length === 0 ? true : false;
        if (this.Skill150Data.areaDescription === null) {
          this.TDType = true;
          this.FSType = false;
        }
        if (this.Skill150Data.deptDescription === null) {
          this.FSType = true;
          this.TDType = false;
        }
      });
    }

    if (queryParams.appTYpe === '152') {
      this.skillToSkillView = true;
      this.prodToSkillView = false;
      this.P19View = false;
      this.recallRightsView = false;
      this.transferAgreeView = false;
      this.shiftPrefView = false;
      this.Skill150View = false;
      this.ss.searchFilterApplication(queryParams).subscribe((data) => {
        this.skillToSkillData = data;
        this.skillToSkillRec =
          this.skillToSkillData.length === 0 ? true : false;
      });
    }
    if (queryParams.appTYpe === '178') {
      this.prodToSkillView = true;
      this.skillToSkillView = false;
      this.P19View = false;
      this.recallRightsView = false;
      this.transferAgreeView = false;
      this.shiftPrefView = false;
      this.Skill150View = false;
      this.ps.searchFilterApplication(queryParams).subscribe((data) => {
        this.prodToSkillData = data;
        this.prodToSkillRec = this.prodToSkillData.length === 0 ? true : false;
      });
    }
    if (queryParams.appTYpe === 'P19') {
      this.P19View = true;
      this.prodToSkillView = false;
      this.skillToSkillView = false;
      this.recallRightsView = false;
      this.transferAgreeView = false;
      this.shiftPrefView = false;
      this.Skill150View = false;
      this.p19.searchFilterApplication(queryParams).subscribe((data) => {
        this.p19Data = data;
        this.P19Rec = this.p19Data.length === 0 ? true : false;
      });
    }

    if (queryParams.appTYpe === 'TA') {
      this.transferAgreeView = true;
      this.prodToSkillView = false;
      this.skillToSkillView = false;
      this.P19View = false;
      this.recallRightsView = false;
      this.shiftPrefView = false;
      this.Skill150View = false;
      this._transferAgreementService
        .searchFilterApplication(queryParams)
        .subscribe((data) => {
          this.TAData = data;
          this.transferAgreeRec = this.TAData.length === 0 ? true : false;
        });
    }
    if (queryParams.appTYpe === 'SP') {
      this.shiftPrefView = true;
      this.prodToSkillView = false;
      this.skillToSkillView = false;
      this.P19View = false;
      this.recallRightsView = false;
      this.transferAgreeView = false;
      this.Skill150View = false;
      this._shiftApplicationPreference
        .ShiftPreferenceSPSearchApplications(queryParams)
        .subscribe((data) => {
          this.shiftPrefData = data;
          this.shiftPrefRecods = this.shiftPrefData.length === 0 ? true : false;
        });
    }
    if (queryParams.appTYpe === null) {
      this.shiftPrefView = false;
      this.prodToSkillView = false;
      this.skillToSkillView = false;
      this.P19View = false;
      this.recallRightsView = false;
      this.transferAgreeView = false;
      this.Skill150View = false;

      this.Skill150Data = [];
      this.skillToSkillData = [];
      this.prodToSkillData = [];
      this.TAData = [];
      this.recallRightsData = [];
      this.p19Data = [];
      this.shiftPrefData = [];

      if (this.jobClass === 'S') {
        queryParams.appTYpe = '150';
        this.fs.searchFilterApplication(queryParams).subscribe((data) => {
          this.Skill150Data = data;
          this.Skill150View = this.Skill150Data.length === 0 ? false : true;

          this.allAppDataRec = this.Skill150Data.length === 0 ? true : false;
          if (this.Skill150Data.areaDescription === null) {
            this.TDType = true;
            this.FSType = false;
          }
          if (this.Skill150Data.deptDescription === null) {
            this.FSType = true;
            this.TDType = false;
          }
        });
      }

      if (this.jobClass === 'S') {
        queryParams.appTYpe = '152';
        this.ss.searchFilterApplication(queryParams).subscribe((data) => {
          this.skillToSkillData = data;
          this.skillToSkillView =
            this.skillToSkillData.length === 0 ? false : true;
          this.allAppDataRec =
            this.skillToSkillData.length === 0 ? true : false;
          //console.log(this.skillToSkillData);
        });
      }

      if (this.jobClass === 'P') {
        this.ps.searchFilterApplication(queryParams).subscribe((data) => {
          this.prodToSkillData = data;
          this.prodToSkillView =
            this.prodToSkillData.length === 0 ? false : true;
          this.allAppDataRec = this.prodToSkillData.length === 0 ? true : false;
        });
      }

      if (this.jobClass === 'P') {
        this.p19.searchFilterApplication(queryParams).subscribe((data) => {
          this.p19Data = data;
          this.P19View = this.p19Data.length === 0 ? false : true;
          this.allAppDataRec = this.p19Data.length === 0 ? true : false;
        });
      }

      if (this.jobClass === 'P') {
        queryParams.appTYpe = 'TA';
        this._transferAgreementService
          .searchFilterApplication(queryParams)
          .subscribe((data) => {
            this.TAData = data;
            this.transferAgreeView = this.TAData.length === 0 ? false : true;
            this.allAppDataRec = this.TAData.length === 0 ? true : false;
          });
      }

      queryParams.appTYpe = 'SP';
      this._shiftApplicationPreference
        .ShiftPreferenceSPSearchApplications(queryParams)
        .subscribe((data) => {
          this.shiftPrefData = data;
          this.shiftPrefView = this.shiftPrefData.length === 0 ? false : true;
          this.allAppDataRec = this.shiftPrefData.length === 0 ? true : false;
        });
    }
  }
  PrintAllApplication() {
    var allLinks = $('head').clone().find('script').remove().end().html();
    var keepColors =
      '<style>body {-webkit-print-color-adjust: exact !important; }</style>';
    var innerContents = document.getElementById('content')?.innerHTML;
    var popupWinindow = window.open('Print', '_blank');
    popupWinindow?.document.open();
    popupWinindow?.document.write(
      '<html><head>' +
        keepColors +
        allLinks +
        '</head><body onload="window.print();window.close();">' +
        innerContents +
        '</html>'
    );
    popupWinindow?.document.close();
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.TAData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.TAData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
