<div class="container-fluid app-box">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Table Maintenance</li>
        <li class="breadcrumb-item brd-li brd-li-child">Demographic Data</li>
      </ol>
    </nav>
    <div
      style="float: right; position: absolute; right: 48px"
      *ngIf="demographicSearchData !== null && demographicSearchData.length > 0"
    >
      <button
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
    </div>
  </div>

  <div>
    <div class="row content-container mt-2 mb-2">
      <div class="pt-2 div-color">
        <div class="search-divider-div filtercss">
          <p class="search-divder-p"></p>
          <hr class="search-divider-hr" />
          <div class="jumbotron jumb-new jumb-newcontrols">
            <div class="row" display="float">
              <div class="col">
                <app-filter
                  [filterConfig]="filterConfig"
                  (filterValue)="getFilterValue($event)"
                  (formcontrolobj)="getbyenter($event)"
                >
                </app-filter>
              </div>
              <div class="col-md-1 mr-5">
                <label for="" style="color: white">&nbsp;</label>
                <a
                  class="btn btn-lg search-button"
                  (click)="searchClassifications()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          style="height: 9px; background: white; position: sticky; top: 103px"
        ></div>
        <div>
          <div>
            <table
              class="table table-striped"
              *ngIf="
                !isDataExportableView &&
                demographicSearchData !== null &&
                demographicSearchData.length > 0
              "
              #demographicdataTablemaintenanceExportTable
            >
              <thead class="table-header">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Employee Id</th>
                  <th scope="col">Department</th>
                  <!-- <th scope="col">Edit</th> -->
                  <!-- <th scope="col">Delete</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  class=""
                  *ngFor="let data of demographicSearchData; let i = index"
                >
                  <td class="left-tab-value">
                    {{ data.last_Name ? data.last_Name : '' }},
                    {{ data.first_Name ? data.first_Name : '' }}
                    {{ data.middle ? data.middle : '' }}
                  </td>
                  <td class="tab-value">{{ data.employeeid | uppercase }}</td>
                  <td
                    class="left-tab-value"
                    *ngIf="
                      data.deptId === null && data.deptDescription === null
                    "
                  >
                    -
                  </td>
                  <td
                    class="left-tab-value"
                    *ngIf="data.deptId && data.deptDescription"
                  >
                    {{ data.deptId }}-{{ data.deptDescription }}
                  </td>
                  <!-- <td class="tab-value"><button class="cursor" *ngIf='views[0]?.allowUpdate' data-toggle="modal" [attr.data-target]="modalId"
                    data-backdrop="false" class="cursor" (click)="selectDemographic(i, data.employeeid)"><img class="img2"
                                                src="../../../../../assets/edit-regular.svg"></button></td>
                                    <td class="tab-value"><button class="cursor" *ngIf='views[0]?.allowDelete' data-toggle="modal" [attr.data-target]="modalId"
                                      data-backdrop="false" class="cursor" (click)="selectDeleteDemographic(i, data.employeeid)"><img class="img2"
                                        src="../../../../../assets/delete.png"></button></td> -->
                </tr>
              </tbody>
            </table>
            <table
              class="table table-striped"
              *ngIf="
                demographicSearchData !== null &&
                demographicSearchData.length > 0
              "
            >
              <thead class="table-header">
                <tr>
                  <!-- <th scope="col">Name</th>
                                    <th scope="col">Employee Id</th>
                                    <th scope="col">Department</th>
                                     -->
                  <th scope="col">
                    <span (click)="onSortClick($event, 'last_Name', 'string')"
                      >Name
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'last_Name'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'last_Name'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'last_Name'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'employeeid', 'string')"
                      >Employee Id
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'employeeid'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'employeeid'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'employeeid'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'deptDescription', 'string')"
                      >Department
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'deptDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'deptDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'deptDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">Edit</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class=""
                  *ngFor="
                    let data of demographicSearchData
                      | paginate
                        : {
                            id: 'joblist',
                            itemsPerPage: 20,
                            currentPage: Applicationpage,
                            totalItems: TotalApplicationCount
                          };
                    let i = index
                  "
                >
                  <td class="left-tab-value">
                    {{ data.last_Name ? data.last_Name : '' }},
                    {{ data.first_Name ? data.first_Name : '' }}
                    {{ data.middle ? data.middle : '' }}
                  </td>
                  <td class="tab-value">{{ data.employeeid | uppercase }}</td>
                  <td
                    class="left-tab-value"
                    *ngIf="
                      data.deptId === null && data.deptDescription === null
                    "
                  >
                    -
                  </td>
                  <td
                    class="left-tab-value"
                    *ngIf="data.deptId && data.deptDescription"
                  >
                    {{ data.deptId }}-{{ data.deptDescription }}
                  </td>
                  <td class="tab-value">
                    <button
                      class="cursor"
                      *ngIf="views[0]?.allowUpdate"
                      data-toggle="modal"
                      [attr.data-target]="modalId"
                      data-backdrop="false"
                      class="cursor"
                      (click)="selectDemographic(i, data.employeeid)"
                    >
                      <img
                        class="img2"
                        src="../../../../../assets/edit-regular.svg"
                      />
                    </button>
                  </td>
                  <td class="tab-value">
                    <button
                      class="cursor"
                      *ngIf="views[0]?.allowDelete"
                      data-toggle="modal"
                      [attr.data-target]="modalId"
                      data-backdrop="false"
                      class="cursor"
                      (click)="selectDeleteDemographic(i, data.employeeid)"
                    >
                      <img
                        class="img2"
                        src="../../../../../assets/delete.png"
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="single-wrap d-flex justify-content-center"
              *ngIf="
                demographicSearchData !== null &&
                demographicSearchData.length > 0
              "
            >
              <nav aria-label="Page navigation example">
                <pagination-controls
                  class="my-pagination"
                  id="joblist"
                  (pageChange)="Applicationpage = $event"
                  previousLabel=""
                  nextLabel=""
                >
                </pagination-controls>
              </nav>
            </div>
            <div *ngIf="isApplicationDataEmpty">
              <p *ngIf="isApplicationDataEmpty">No existing records Found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Edit Modal -->
  <app-edit-demographic-data
    *ngIf="showEdit"
    [userName]="this.userInformation.userName"
    [selectedDemographic]="selectedDemographic"
    (isSucess)="CheckEdit($event)"
  >
  </app-edit-demographic-data>
  <app-delete-demographic-data
    *ngIf="showDelete"
    [selectedDemographic]="selectedDemographic"
    (isSucess)="CheckDelete($event)"
  ></app-delete-demographic-data>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
