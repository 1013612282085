import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class DemographicViewService {

  private readonly api_searchDemographiViewData = '/DemographicViewSearch';
  private readonly api_getDepartmentData = '/GetDepartments';
  private readonly api_searchDemoDetails = '/DemographicViewDetailedSearch?employeeId=';

  constructor(
    private _http: HttpClient, 
    private appCom: MSALserviceService
  ) { }

  commonUtility = new CommonUtility();

  searchDemographicViewData(demographicData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
     `${environment.tableMaintenanceURL}` + this.api_searchDemographiViewData,
      demographicData,
      { headers }
    );
  }
  getAllDepartments(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
     `${environment.tableMaintenanceURL}` + this.api_getDepartmentData,
      { headers }
    );
  }
  searchDemographicDetails(employeeId: any):  Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
    `${environment.tableMaintenanceURL}` + this.api_searchDemoDetails + employeeId,
    null,
    { headers }
    );
  }

}
