import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { LocalStorageService } from 'src/app/Services/LocalStorageService/locastorage.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { DemographicViewService } from 'src/app/Services/TableMaintenance/DemographicView/demographic-view.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';

@Component({
  selector: 'app-demographic-view',
  templateUrl: './demographic-view.component.html',
  styleUrls: ['./demographic-view.component.css'],
})
export class DemographicViewComponent implements OnInit {
  public loading: boolean = false;
  TotalApplicationCount: any;
  UserPage: number = 1;
  Applicationpage: number = 1;
  modalId: string = '';
  filterData: any = {};
  demographicViewSearchData: any[] = [];
  userInformation: any;
  views: any[] = [];
  departments: any;
  selectedRecord: any;
  showDetails: boolean = false;
  isApplicationDataEmpty: boolean = false;
  filterConfig: any = [
    {
      label: 'Last Name',
      type: 'input',
      data: [],
      placeholder: 'Enter last name',
      default: 'All',
      controlName: 'Last_Name',
      runClassification: false,
    },
    {
      label: 'First Name',
      type: 'input',
      data: [],
      placeholder: 'Enter first name',
      default: 'All',
      controlName: 'First_Name',
      runClassification: false,
    },
    {
      label: 'Employee Id',
      type: 'input',
      data: [],
      placeholder: 'Enter Employee Id',
      default: 'All',
      controlName: 'EMPLOYEEID',
      runClassification: false,
    },
    {
      label: 'Department',
      type: 'dropdown',
      data: [],
      placeholder: 'Search department here',
      default: 'All',
      controlName: 'Dept',
      runClassification: false,
      width: '200px',
    },
  ];
  constructor(
    private dv: DemographicViewService,
    private _user: UsergroupService,
    private _commonApplicationService: CommonService,
    private sweetAlert: SweetAlertService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getUserInfo();
    this.getAllDepartments();
    this.searchDemographic();
  }
  getAllDepartments() {
    this.dv.getAllDepartments().subscribe((data: any) => {
      this.departments = data;
      const mapElement = this.departments.map(
        (element: { deptId: any; deptDescription: any }) => ({
          id: element.deptId,
          description: element.deptDescription,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Department') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'VIEWS',
        'DEMOGRAPHICVIEW'
      );
    });
  }

  getFilterValue(event: any) {
    this.filterData = event;
  }

  searchDemographic() {
    this.loading = true;
    let queryParams = this.filterData;
    Object.keys(queryParams).forEach(
      (k) =>
        !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]
    );
    this.dv.searchDemographicViewData(queryParams).subscribe((res) => {
      this.demographicViewSearchData = res;
      this.isApplicationDataEmpty =
        this.demographicViewSearchData.length === 0 ? true : false;
      this.Applicationpage = 1;
      this.TotalApplicationCount = res.length;
      this.loading = false;
    });
  }

  selectRecord(employeeId: string) {
    // this.searchDemographicDetail(employeeId);
    //   this.showDetails = true;
    //   this.modalId = '#demoDetails';
    this.localStorageService.setItem('employeeid', employeeId);
    this.router.navigate(['DemographicDetailsView']);
  }
  searchDemographicDetail(employeeId: string) {
    this.dv.searchDemographicDetails(employeeId).subscribe((res) => {
      this.selectedRecord = res;
      //this.isApplicationDataEmpty = this.demographicViewSearchData.length===0 ? true : false;
    });
  }
  CheckDetails(e: any) {
    this.showDetails = e;
    this.searchDemographic();
  }

  cleanEmp(event: any) {}

  getbyenter(e: any) {
    this.loading = true;
    let searchData;
    if (this.filterData != null) {
      searchData = { ...e, ...this.filterData };
    }
    this.dv.searchDemographicViewData(searchData).subscribe((res: any) => {
      this.demographicViewSearchData = res;
      this.isApplicationDataEmpty =
        this.demographicViewSearchData.length === 0 ? true : false;
      this.Applicationpage = 1;
      this.TotalApplicationCount = res.length;
      this.loading = false;
    });
  }

  @ViewChild('DemographicViewExportTable', { read: ElementRef, static: false })
  DemographicViewExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.DemographicViewExportTable,
        `${ApplicationEnum.DEMOGRAPHICSVIEW}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.demographicViewSearchData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.demographicViewSearchData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
