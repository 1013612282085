import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShiftPreferenceDataService } from 'src/app/Services/TableMaintenance/ShiftPreferenceData/shift-preference-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-shift-preference-data',
  templateUrl: './add-shift-preference-data.component.html',
  styleUrls: ['./add-shift-preference-data.component.css'],
})
export class AddShiftPreferenceDataComponent implements OnInit {
  @ViewChild('closeAddModel') closeAddModal!: ElementRef;
  plantGroupDataForm!: FormGroup;
  @Input() userName: any;
  @Output() isSucess = new EventEmitter();

  Status: any[] = [
    {
      data: [
        {
          statusType: 'A',
          statusName: 'Active',
        },
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
      ],
      default: 'Select One',
    },
  ];
  constructor(
    private fb: FormBuilder,
    private shiftPreferenceDataService: ShiftPreferenceDataService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }
  createForm() {
    this.plantGroupDataForm = this.fb.group({
      PLANTGROUPID: ['', [Validators.required]],
      PLANTGROUPDESCRIPTION: ['', Validators.required],
      STATUS: ['', [Validators.required]],
      LASTUPDATEBY: [this.userName],
    });
  }
  clear() {
    this.closeAddModal.nativeElement.click();
    this.createForm();
  }
  submitShift() {
    this.shiftPreferenceDataService
      .addPlantGroupData(this.plantGroupDataForm.value)
      .subscribe(
        () => {
          this.clear();
          this.successAlert();
          this.isSucess.emit(true);
        },
        (_error: HttpErrorResponse) => {
          if (_error.status === 500) {
            this.badRequestAlert(_error.error);
          } else {
            this.validationError(_error.error);
          }
        }
      );
  }
  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Shift Preference data has been added successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
