import { HttpErrorResponse } from '@angular/common/http';
import {
  ElementRef,
  ViewChild,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepartmentDataService } from 'src/app/Services/TableMaintenance/DepartmentData/department-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-department-data',
  templateUrl: './add-department-data.component.html',
  styleUrls: ['./add-department-data.component.css'],
})
export class AddDepartmentDataComponent implements OnInit {
  @ViewChild('closeAddModel') closeAddModal!: ElementRef;
  @Input() userName: any;
  @Output() isSucess = new EventEmitter();
  deptForm!: FormGroup;

  Regions: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];
  Plants: any[] = [
    {
      data: [],
      default: 'Select One',
    },
  ];
  Status: any[] = [
    {
      data: [
        {
          statusType: 'I',
          statusName: 'Inactive',
        },
        {
          statusType: 'A',
          statusName: 'Active',
        },
      ],
      default: 'Select One',
    },
  ];

  constructor(private fb: FormBuilder, private cds: DepartmentDataService) {}

  ngOnInit(): void {
    this.createForm();
    this.getRegions();
    this.getPlants();
  }

  createForm() {
    this.deptForm = this.fb.group({
      DeptId: ['', [Validators.required]],
      DeptDescription: ['', [Validators.required]],
      RegionId: ['', [Validators.required]],
      Status: ['', [Validators.required]],
      SpeedCode: ['', [Validators.required]],
      PlantId: ['', [Validators.required]],
      LastUpdateBy: [this.userName],
    });
  }

  getRegions() {
    this.cds.getRegions().subscribe((res) => {
      this.Regions[this.Regions.length - 1].data = res;
    });
  }

  getPlants() {
    this.cds.getPlants('A').subscribe((res) => {
      this.Plants[this.Plants.length - 1].data = res;
    });
  }

  clear() {
    this.closeAddModal.nativeElement.click();
    this.createForm();
  }

  submitDepartment() {
    this.cds.addDepartment(this.deptForm.value).subscribe(
      () => {
        this.clear();
        this.successAlert();
        this.isSucess.emit(true);
      },
      (_error: HttpErrorResponse) => {
        //this.badRequestAlert();
        if (_error.status === 500) {
          this.badRequestAlert(_error.error);
        } else {
          this.badRequestAlert(_error.error);
        }
      }
    );
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Department has been added successfully.',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  badRequestAlert(errorMessage: string) {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: `${errorMessage}`,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
