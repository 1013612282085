import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Modules } from 'src/app/Models/Admin/Modules';
import { AdminService } from 'src/app/Services/Admin/admin.service';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import Swal from 'sweetalert2';

const GROUPIDFORCOMMITTEEWITHMEDRES = 20
@Component({
  selector: 'app-administer-users',
  templateUrl: './administer-users.component.html',
  styleUrls: ['./administer-users.component.css']
})
export class AdministerUsersComponent implements OnInit {
  public loading = false;
  adminModules: Modules[] = [];
  applicationModules: Modules[] = [];
  overtimeModules: Modules[] = [];
  tableMaintenanceModules: Modules[] = [];
  viewModules: Modules[] = [];
  userInformation: any;
  views: any[] = [];

  constructor(private admin: AdminService,
    private _user: UsergroupService,
    private _commonApplicationService: CommonService) { }

  ngOnInit(): void {
    this.loading = true;
    this.getGroupViews();
    this.getUserInfo();
  }

  getGroupViews() {
    this.admin.getModules({GROUPID: GROUPIDFORCOMMITTEEWITHMEDRES.toString()}).subscribe((res) => {
      this.adminModules = res['admin'];
      this.applicationModules = res['applications'];
      this.overtimeModules = res['overtime'];
      this.tableMaintenanceModules = res['tableMaintenance'];
      this.viewModules = res['view'];
      this.loading = false;
    });
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      let module_Applications = data['moduels']
      this.views = this._commonApplicationService.crudPermissionSetup(module_Applications, 'ADMIN', 'ADMINISTERGROUPS');
    });
  }

  sendModifedModules(): void {
    const modifyModule = {
      Admin: this.adminModules,
      Applications: this.applicationModules,
      Overtime: this.overtimeModules,
      TableMaintenance: this.tableMaintenanceModules,
      View: this.viewModules,
      CreatedBy: this.userInformation.userName,
      GROUPID: "20"
    }
    this.admin.modifyPermission(modifyModule).subscribe(res => {
      this.successAlert();
      this.getGroupViews()
    },
      (_error: HttpErrorResponse) => {
        this.validationError(_error.error);
      });
  }

  successAlert() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Group "UAW Committee with Med Res" modified successfully',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  validationError(errorMessage: string) {
    Swal.fire({
      title: `${errorMessage}`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Ok',
    });
  }
}
