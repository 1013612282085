import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let modules = JSON.parse(localStorage.getItem('Modules') || '{}') as Array<any>;
    const isAuthroized = this.authService.CheckAuthorization(modules, route.data['moduleCode'], route.data['viewCode']);
    if (isAuthroized) {
      return true;
    }
    this.router.navigate(['/']);
    return false;
  }
}
