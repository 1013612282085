import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AnnouncementService } from 'src/app/Services/Admin/Announcement/announcement.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  constructor(private http: HttpClient,
    private _announcementService : AnnouncementService,
    private _userInfo : UsergroupService) {
  }

  ngOnInit(): void {
    this.getUserInfo();
  }

  Announcements : any;
  AnnouncementsExists : boolean= false;
  getUserInfo(){
    this._userInfo.getUserInfo().subscribe((data:any)=>{
      //console.log(data);
      this._announcementService.getCurrentGroupAnnouncement({GROUPID:data['groupId'.toString()]}).subscribe((data:any)=>{
        
        this.Announcements = data;
        this.AnnouncementsExists =  false;
        if(this.Announcements.length > 0){
          this.AnnouncementsExists = true;
        }
      })
    })
  }
}
