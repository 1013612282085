<div
  *ngIf="selectedEmp"
  class="modal fade"
  id="cancelApplicationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="cancelApplicationModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog add-form-alignment" role="document">
    <div class="modal-content" style="position: fixed; width: 945px">
      <div class="modal-header">
        <h5 class="modal-title" id="cancelApplicationModalLabel">
          Cancel Application
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>
      <div class="modal-body" style="padding-left: 80px; padding-right: 80px">
        <form [formGroup]="dateForm">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Employee Name:</label>
            <div class="col-sm-8">
              <div>
                {{
                  selectedEmp.lastName ? selectedEmp.lastName : 'N/A'
                }},&nbsp;{{
                  selectedEmp.firstName ? selectedEmp.firstName : 'N/A'
                }}&nbsp;{{
                  selectedEmp.middleName ? selectedEmp.middleName : ''
                }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Current Plant:</label>
            <div class="col-sm-8">
              <div>
                {{
                  selectedEmp.plantDescription
                    ? selectedEmp.plantDescription
                    : 'N/A'
                }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Current Shift:</label>
            <div class="col-sm-8">
              <div>
                {{
                  selectedEmp.shiftDescription
                    ? selectedEmp.shiftDescription
                    : 'N/A'
                }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Employee Signature Date:</label
            >
            <div class="col-sm-8">
              <div>
                {{
                  selectedEmp.empSignDate
                    ? (selectedEmp.empSignDate | date: 'MM/dd/yyyy')
                    : 'N/A'
                }}
              </div>
            </div>
          </div>
          <div *ngIf="isLoggedInUserAdmin">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Date of Cancel *:</label>
              <div class="col-sm-8">
                <div>
                  <input
                    hidden
                    readonly
                    readonly
                    type="email"
                    formControlName="AppStatusDate"
                    class="form-control"
                  />

                  <input
                    type="date"
                    class="form-control"
                    formControlName="AppStatusDate"
                    max="{{ maxCancelDate }}"
                  />
                  <div
                    *ngIf="
                      dateForm.controls['AppStatusDate'].invalid &&
                      dateForm.controls['AppStatusDate'].touched
                    "
                  >
                    <small
                      class="text-danger"
                      *ngIf="
                        dateForm.controls['AppStatusDate'].errors?.required
                      "
                    >
                      Cancel date is required.
                    </small>
                  </div>
                  <label class="alert alert-danger" *ngIf="dateForm.errors"
                    >{{ dateForm.errors.dates }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!isLoggedInUserAdmin">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label"> Date of Cancel:</label>
              <div class="col-sm-8">
                <div>
                  <input
                    hidden
                    type="date"
                    class="form-control"
                    formControlName="AppStatusDate"
                    value="{{ currentDate | date: 'yyyy-MM-dd' }}"
                  />
                  <label>{{ currentDate | date: 'MM/dd/yyyy' }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Status:</label>
            <div class="col-sm-8">
              <div>
                {{
                  selectedEmp.appStatusCode ? selectedEmp.appStatusCode : 'N/A'
                }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Status Date:</label>
            <div class="col-sm-8">
              <div>
                {{
                  selectedEmp.appStatusDate
                    ? (selectedEmp.appStatusDate | date: 'MM/dd/yyyy')
                    : 'N/A'
                }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Date Entered:</label>
            <div class="col-sm-8">
              <div>
                {{
                  selectedEmp.empSignDate
                    ? (selectedEmp.empSignDate | date: 'MM/dd/yyyy')
                    : 'N/A'
                }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Last Update By:</label>
            <div class="col-sm-8">
              <div>
                {{
                  selectedEmp.lastUpdateBy ? selectedEmp.lastUpdateBy : 'N/A'
                }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Date of Last Update:</label>
            <div class="col-sm-8">
              <div>
                {{
                  selectedEmp.lastUpdateDate
                    ? (selectedEmp.lastUpdateDate | date: 'MM/dd/yyyy')
                    : 'N/A'
                }}
              </div>
            </div>
          </div>
          <!-- <table class="table table-borderless mt-2 mb-2 info-table">

                        <tbody> -->
          <!-- <tr>
                                <td class='tab-label'>Employee Name:</td>
                                <td class='tab-value'>{{selectedEmp.firstName?selectedEmp.firstName: 'N/A'}}, {{selectedEmp.lastName?selectedEmp.lastName: 'N/A'}}</td>

                            </tr> -->
          <!-- <tr>
                                <td class='tab-label'>Current Plant:</td>
                                <td class='tab-value'>{{selectedEmp.plantDescription?selectedEmp.plantDescription: 'N/A'}}</td>

                            </tr> -->
          <!-- <tr>
                                <td class='tab-label'>Current Shift:</td>
                                <td class='tab-value'>{{selectedEmp.shiftDescription?selectedEmp.shiftDescription: 'N/A'}}</td>

                            </tr> -->
          <!-- <tr>
                                <td class='tab-label'>Employee Signature Date:</td>
                                <td class='tab-value'>{{selectedEmp.empSignDate? (selectedEmp.empSignDate | date:'MM/dd/yyyy'): 'N/A'}}</td>

                            </tr> -->
          <!-- <tr *ngIf="isLoggedInUserAdmin"> -->
          <!-- <tr *ngIf="isLoggedInUserAdmin">
                                <td class='tab-label'>Date of Cancel:</td>
                                <td class='tab-value'>
                                    <input hidden readonly readonly type="email" formControlName="AppStatusDate" class="form-control">

                                    <input type="date"  class="form-control" formControlName="AppStatusDate" max= {{maxCancelDate}} >
                                    <div *ngIf="dateForm.controls['AppStatusDate'].invalid && dateForm.controls['AppStatusDate'].touched">
                                        <small class="text-danger" *ngIf="dateForm.controls['AppStatusDate'].errors?.required">
                                            Cancel date is required.
                                        </small>
                                    </div>
                                    <label class="alert alert-danger" *ngIf="dateForm.errors">{{
                                        dateForm.errors?.dates}}
                                    </label>

                                </td>
                            </tr> -->

          <!-- <tr *ngIf="!isLoggedInUserAdmin"> -->
          <!-- <tr *ngIf="!isLoggedInUserAdmin">
                                <td class='tab-label'> Date of Cancel:</td>
                                <td class='tab-value'>
                                    <input hidden type="date" class="form-control" formControlName="AppStatusDate"
                                        value="{{currentDate | date:'yyyy-MM-dd'}}">
                                        <label>{{currentDate | date:'MM-dd-yyyy'}}</label>
                                </td>
                            </tr> -->

          <!-- <tr>
                                <td class='tab-label'>Status:</td>
                                <td class='tab-value'>{{selectedEmp.appStatusCode?selectedEmp.appStatusCode: 'N/A'}}</td>
                            </tr> -->
          <!-- <tr>
                                <td class='tab-label'>Status Date:</td>
                                <td class='tab-value'>{{selectedEmp.appStatusDate? (selectedEmp.appStatusDate | date:'MM/dd/yyyy'): 'N/A'}}</td>

                            </tr> -->
          <!-- <tr>
                                <td class='tab-label'>Entered By:</td>
                                <td class='tab-value'>{{editorName}}</td>

                            </tr>                             -->
          <!-- <tr>
                                <td class='tab-label'>Date Entered:</td>
                                <td class='tab-value'>{{selectedEmp.empSignDate? (selectedEmp.empSignDate | date:'MM/dd/yyyy'): 'N/A'}}</td>
                            </tr> -->
          <!-- <tr>
                                <td class='tab-label'>Last Update By:</td>
                                <td class='tab-value'>{{selectedEmp.lastUpdateBy?selectedEmp.lastUpdateBy: 'N/A'}}</td>

                            </tr>

                            <tr>
                                <td class='tab-label'>Date of Last Update:</td>
                                <td class='tab-value'>{{selectedEmp.lastUpdateDate? (selectedEmp.lastUpdateDate | date:'MM/dd/yyyy'): 'N/A'}}</td>
                            </tr> -->
          <!-- </tbody>
                    </table> -->
        </form>
      </div>
      <div class="modal-footer" style="padding-right: 80px">
        <button
          type="button"
          style="height: 40px; width: 150px; border-radius: 10px"
          class="btn btn-secondary popupbuttonscancel"
          data-dismiss="modal"
          (click)="clear()"
          #closeCancelModal
        >
          Cancel
        </button>
        <button
          type="button"
          style="height: 40px; width: 150px; border-radius: 10px"
          class="btn btn-primary"
          [disabled]="dateForm.invalid"
          (click)="confirmCancel()"
        >
          Cancel Application
        </button>
      </div>
    </div>
  </div>
</div>
