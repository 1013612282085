import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor/lib/config';
import { AnnouncementService } from 'src/app/Services/Admin/Announcement/announcement.service';
import { MSALserviceService } from 'src/app/Services/MSALservice/msalservice.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';

@Component({
  selector: 'app-edit-announcement',
  templateUrl: './edit-announcement.component.html',
  styleUrls: ['./edit-announcement.component.css'],
})
export class EditAnnouncementComponent {
  @Output() isSucess = new EventEmitter();
  @Input() selectedAnnouncement: any;
  @Input() userName: any;
  @ViewChild('closeEditModal') closeEditModal!: ElementRef;

  GroupPermissions: any[] = [];
  AnnouncementForm!: FormGroup;
  announcementSelected: any;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['strikeThrough', 'subscript', 'superscript'],
      ['customClasses', 'link', 'unlink', 'insertImage', 'insertVideo'],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  constructor(
    private fb: FormBuilder,
    private _AnnouncementService: AnnouncementService,
    private _sweetAlertService: SweetAlertService,
    private _msal: MSALserviceService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.createForm();
    this._msal.getUserName();
    this.loadData(changes);
  }
  createForm() {
    this.AnnouncementForm = this.fb.group({
      ANNOUNCEMENTID: [''],
      TITLE: ['', [Validators.required]],
      TEXT: ['', [Validators.required]],
      STARTTIME: ['', [Validators.required]],
      ENDTIME: ['', [Validators.required]],
      STATUS: ['', [Validators.required]],
      GROUPID: [''],
      LASTUPDATEBY: ['', [Validators.required]],
    });
  }

  groupsList: any;
  getGroups(groups: any) {
    this._AnnouncementService.GetGroups().subscribe((res) => {
      this.groupsList = res;
      var groupArray = groups.split(',').map(String);
      for (let i = 0; i < this.groupsList.length; i++) {
        this.groupsList[i].allow = false;
        for (let j = 0; j < groupArray.length; j++) {
          if (this.groupsList[i].groupid === groupArray[j]) {
            this.groupsList[i].allow = true;
          }
        }
      }
    });
  }

  loadData(changes: SimpleChanges) {
    this.announcementSelected =
      (changes.selectedAnnouncement &&
        changes.selectedAnnouncement.currentValue) ||
      '';
    if (this.announcementSelected === '') {
      this.announcementSelected =
        (changes.selectedAnnouncement &&
          changes.selectedAnnouncement.previousValue) ||
        '';
    }

    this.GroupPermissions.length = 0;

    this._AnnouncementService
      .AnnouncementSearch({
        ANNOUNCEMENTID: this.announcementSelected.announcementid,
      })
      .subscribe((data: any) => {
        var data = data[0];
        this.GroupPermissions = data.groupid.split(',').map(String);
        this.getGroups(data.groupid);
        this.AnnouncementForm.get('LASTUPDATEBY')?.patchValue(
          this._msal.userAtid.replace('@alsn.com', '')
        );
        this.AnnouncementForm.get('ANNOUNCEMENTID')?.patchValue(
          data.announcementid
        );
        this.AnnouncementForm.get('TITLE')?.patchValue(data.title);
        this.AnnouncementForm.get('TEXT')?.patchValue(data.text);
        this.AnnouncementForm.get('STATUS')?.patchValue(data.status);
        this.AnnouncementForm.get('ENDTIME')?.patchValue(
          formatDate(data.endtime, 'yyyy-MM-ddTHH:mm', 'en-US')
        );
        this.AnnouncementForm.get('STARTTIME')?.patchValue(
          formatDate(data.starttime, 'yyyy-MM-ddTHH:mm', 'en-US')
        );
      });
  }

  EditAnnouncement() {
    const START_TIME = new Date(
      this.AnnouncementForm.controls['STARTTIME'].value
    );
    const END_TIME = new Date(this.AnnouncementForm.controls['ENDTIME'].value);
    if (
      this.AnnouncementForm.controls['TITLE'].value.toString().length > 2048
    ) {
      return this._sweetAlertService.invalidSelection(
        'error',
        'ERROR',
        'The announcement Title cannot be longer than 2048 characters.'
      );
    }
    if (this.AnnouncementForm.controls['TEXT'].value.toString().length > 2048) {
      return this._sweetAlertService.invalidSelection(
        'error',
        'ERROR',
        'The announcement text cannot be longer than 2048 characters.'
      );
    }
    if (this.GroupPermissions.length <= 0) {
      return this._sweetAlertService.invalidSelection(
        'error',
        'ERROR',
        'You must choose at least one group.'
      );
    }

    if (!(new Date(END_TIME) >= new Date(START_TIME))) {
      return this._sweetAlertService.invalidSelection(
        'error',
        'ERROR',
        'You must enter an end date that is the same or after the start date.'
      );
    } else if (new Date(END_TIME) === new Date(START_TIME)) {
      if (
        !(
          parseInt((END_TIME.getHours() + END_TIME.getMinutes()).toString()) >=
          parseInt((START_TIME.getHours() + START_TIME.getMinutes()).toString())
        )
      ) {
        return this._sweetAlertService.invalidSelection(
          'error',
          'ERROR',
          'You must enter an end date that is the same or after the start date.'
        );
      }
    }

    const groupPermissionsStringify = this.GroupPermissions.toString();
    this.AnnouncementForm.get('GROUPID')?.patchValue(groupPermissionsStringify);
    this._AnnouncementService
      .AnnouncementCorrect(this.AnnouncementForm.value)
      .subscribe(
        (res: any) => {
          this.clear();
          this.isSucess.emit(true);
          this._sweetAlertService.successAlert(
            'center',
            'success',
            'Announcement has been updated Successfully.',
            false,
            1500
          );
        },
        (_error: HttpErrorResponse) => {
          if (_error.status === 500) {
            return this._sweetAlertService.InternalServerError500(
              'error',
              'Error',
              _error.error.replace(
                'Error: AnnouncementCorrect - Generic Exception: ',
                ''
              )
            );
          } else {
            return this._sweetAlertService.InternalServerError500(
              'error',
              'Error',
              'Something happened. Please try agin later'
            );
          }
        }
      );
  }
  clear() {
    this.closeEditModal.nativeElement.click();
    this.isSucess.emit(true);
  }

  updateGroupPermission(id: any, allow: any) {
    var selectedGroup: any[] = [];
    this.groupsList.forEach((ele: { allow: boolean; groupid: any }) => {
      if (ele.groupid === id) {
        ele.allow = !allow;
      }
      if (ele.allow === true) {
        selectedGroup.push(ele.groupid);
      }
    });
    this.GroupPermissions = selectedGroup;
  }
}
