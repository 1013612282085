<div class="container-fluid app-box">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Table Maintenance</li>
        <li class="breadcrumb-item brd-li brd-li-child">
          Application Types Data
        </li>
      </ol>
    </nav>
    <div
      style="float: right; position: absolute; right: 48px"
      *ngIf="applicationTypeData !== null && applicationTypeData.length > 0"
    >
      <button
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
    </div>
  </div>

  <div class="row content-container mt-2 mb-2">
    <div class="div-color">
      <div class="pt-5" style="padding-top: 0px !important">
        <div>
          <form [formGroup]="applicationForm">
            <table
              class="table table-striped"
              #absencereasondataTablemaintenanceExportTable
            >
              <thead class="table-header transfer-header">
                <tr>
                  <!-- <th scope="col">Application Type</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Status</th>
                                     -->
                  <th scope="col">
                    <span (click)="onSortClick($event, 'appType', 'string')"
                      >Application Type
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'appType'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'appType'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'appType'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'appTypeDescription', 'string')
                      "
                      >Description
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'appTypeDescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'appTypeDescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'appTypeDescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'status', 'string')"
                      >Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'status'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'status'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th *ngIf="showEditHeader" scope="col">Edit</th>
                  <th *ngIf="!showEditHeader" scope="col">Save</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let data of applicationTypeData; let i = index"
                  class=""
                >
                  <td class="tab-value">{{ data.appType }}</td>
                  <td *ngIf="selectedIndex !== i" class="left-tab-value">
                    {{ data.appTypeDescription }}
                  </td>
                  <td *ngIf="selectedIndex === i" class="tab-value">
                    <input
                      formControlName="appTypeDescription"
                      type="text"
                      class="form-control"
                    />
                    <div
                      *ngIf="
                        applicationForm.controls['appTypeDescription']
                          .invalid &&
                        applicationForm.controls['appTypeDescription'].touched
                      "
                    >
                      <small
                        class="text-danger"
                        *ngIf="
                          applicationForm.controls['appTypeDescription'].errors
                            ?.required
                        "
                      >
                        You must specify an application type description.
                      </small>
                    </div>
                  </td>
                  <td *ngIf="selectedIndex !== i" class="tab-value">
                    {{ data.status === 'A' ? 'Active' : 'Inactive' }}
                  </td>
                  <td *ngIf="selectedIndex === i" class="tab-value">
                    <select formControlName="status" class="form-control">
                      <option
                        *ngFor="let class of status"
                        value="{{ class.Id }}"
                      >
                        {{ class.statusDescription }}
                      </option>
                    </select>
                  </td>
                  <td class="tab-value">
                    <div *ngIf="views[0]?.allowUpdate">
                      <button
                        class="cursor"
                        (click)="onIconChangeToSave(data, i)"
                        *ngIf="selectedIndex !== i"
                      >
                        <img src="../../../../../assets/edit-regular.svg" />
                      </button>
                      <button
                        *ngIf="selectedIndex === i"
                        class="cursor"
                        [disabled]="applicationForm.invalid"
                        (click)="onIconChangeToEdit()"
                      >
                        <img src="../../../../../assets/save-regular.svg" />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
