<div class="modal fade" id="addEmployee" tabindex="-1" role="dialog" aria-labelledby="addEmployeeLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addEmployeeLabel">Add Classification</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="classificationForm">
                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label" style="font-size: 14px!important;">Classification Code*</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="JOBCODEID" class="form-control">
                            <div *ngIf="classificationForm.controls['JOBCODEID'].invalid && classificationForm.controls['JOBCODEID'].touched">
                                <small class="text-danger" *ngIf="classificationForm.controls['JOBCODEID'].errors?.required">
                                    You must specify classification code to be entered
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label" style="font-size: 14px!important;">Classification
                            Description*</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="JOBCODEDESCRIPTION" class="form-control">
                            <div *ngIf="classificationForm.controls['JOBCODEDESCRIPTION'].invalid && classificationForm.controls['JOBCODEDESCRIPTION'].touched">
                                <small class="text-danger" *ngIf="classificationForm.controls['JOBCODEDESCRIPTION'].errors?.required">
                                    You must specify classification code description
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Type*</label>
                        <div class="col-sm-8">
                            <select formControlName="CLASSIFICATIONTYPE" class="form-control">
                                <option *ngIf="Types[Types.length-1].default" selected value="">
                                    {{Types[Types.length-1].default}}</option>
                                <option *ngFor="let class of Types[Types.length-1].data"
                                    value={{class.classificationType}}>
                                    {{class.classificationName}}
                                </option>
                            </select>
                            <div *ngIf="classificationForm.controls['CLASSIFICATIONTYPE'].invalid && classificationForm.controls['CLASSIFICATIONTYPE'].touched">
                                <small class="text-danger" *ngIf="classificationForm.controls['CLASSIFICATIONTYPE'].errors?.required">
                                    You must choose the classification code type
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Status*</label>
                        <div class="col-sm-8">
                            <select formControlName="STATUS" class="form-control">
                                <option *ngIf="Status[Status.length-1].default" selected value="">
                                    {{Status[Status.length-1].default}}</option>
                                <option *ngFor="let class of Status[Status.length-1].data" value={{class.statusType}}>
                                    {{class.statusName}}
                                </option>
                            </select>
                            <div *ngIf="classificationForm.controls['STATUS'].invalid && classificationForm.controls['STATUS'].touched">
                                <small class="text-danger" *ngIf="classificationForm.controls['STATUS'].errors?.required">
                                    You must choose the status
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Maximum Rate*</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="MAXRATE" class="form-control">
                            <div *ngIf="classificationForm.controls['MAXRATE'].invalid && classificationForm.controls['MAXRATE'].touched">
                                <small class="text-danger" *ngIf="classificationForm.controls['MAXRATE'].errors?.required">
                                    You must specify maximum rate
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">NIO*</label>
                        <div class="col-sm-8">
                            <select formControlName="NIO" class="form-control">
                                <option *ngIf="Nio[Nio.length-1].default" selected value="">
                                    {{Nio[Nio.length-1].default}}</option>
                                <option *ngFor="let class of Nio[Nio.length-1].data" value={{class.nio}}>
                                    {{class.niogroupdescription}}
                                </option>
                            </select>
                            <div *ngIf="classificationForm.controls['NIO'].invalid && classificationForm.controls['NIO'].touched">
                                <small class="text-danger" *ngIf="classificationForm.controls['NIO'].errors?.required">
                                    You must specify NIO for classification code to be entered
                                </small>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Box</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="BOX" class="form-control">
                        </div>
                    </div> -->
                </form>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" #closeAddModel (click)="clear()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]="classificationForm.errors || classificationForm.invalid" (click)="submitClassification()">Add </button>
            </div>
        </div>
    </div>
</div>