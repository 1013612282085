import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonUtility } from 'src/common/commonUtility';
import { environment } from 'src/environments/environment';
import { MSALserviceService } from '../../MSALservice/msalservice.service';

@Injectable({
  providedIn: 'root'
})
export class ZoneDataService {
  commonUtility = new CommonUtility();
  private readonly api_addZoneData = '/ZoneDataAdd';
  private readonly api_deleteZoneData = '/ZoneDataDelete?zoneid=';
  private readonly api_updateZoneData = '/ZoneDataUpdate';
  private readonly api_getZoneData = '/ZoneDataGetAllData';
  private readonly api_getSpecificZoneData = '/ZoneDataGetSpecificData?zoneid=';

  constructor(
    private _http: HttpClient, 
    private appCom: MSALserviceService
  ) { }
  getZoneData(): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
     `${environment.tableMaintenanceURL}` + this.api_getZoneData,
      { headers }
    );
  }
  getSpecificZoneData(zoneId: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.get<any>(
      `${environment.tableMaintenanceURL}` + this.api_getSpecificZoneData +zoneId,
      { headers }
    );
  }
  addZoneData(zoneData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.post<any>(
     `${environment.tableMaintenanceURL}` + this.api_addZoneData,
      zoneData,
      { headers }
    );
  }
  editZoneData(zoneData: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.put<any>(
      `${environment.tableMaintenanceURL}` + this.api_updateZoneData,
      zoneData,
      { headers }
    );
  }
  deleteZoneData(zoneId: any): Observable<any> {
    const headers = this.commonUtility.getHttpHeaderWithBearerToken(
      this.appCom.getAuthToken()
    );
    return this._http.delete<any>(
    `${environment.tableMaintenanceURL}` + this.api_deleteZoneData+zoneId,
      { headers }
    );
  }
}
