
<div class="modal fade" id="editEmp" tabindex="-1" role="dialog" aria-labelledby="addEmployeeLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addEmployeeLabel">Edit Plant Group Data</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="clear()">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="plantGroupDataForm">

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Shift Preference Group ID*</label>
                        <div class="col-sm-8">
                            <input type="text" readonly formControlName="PLANTGROUPID" class="form-control">
                            <!-- <div *ngIf="plantForm.controls['PLANTID'].invalid && plantForm.controls['PLANTID'].touched">
                                <small class="text-danger" *ngIf="plantForm.controls['PLANTID'].errors?.required">
                                    You must specify the NIO group to be entered
                                </small>
                            </div> -->
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Shift Preference Group Description*</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="PLANTGROUPDESCRIPTION" class="form-control">
                            <div *ngIf="plantGroupDataForm.controls['PLANTGROUPDESCRIPTION'].invalid && plantGroupDataForm.controls['PLANTGROUPDESCRIPTION'].touched">
                                <small class="text-danger" *ngIf="plantGroupDataForm.controls['PLANTGROUPDESCRIPTION'].errors?.required">
                                    You must specify shift preference description
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Status*</label>
                        <div class="col-sm-8">
                            <select formControlName="STATUS" class="form-control">
                                <option *ngIf="Status[Status.length-1].default" selected value="">
                                    {{Status[Status.length-1].default}}</option>
                                <option *ngFor="let data of Status[Status.length-1].data" value={{data.statusType}}>
                                    {{data.statusName}}
                                </option>
                            </select>
                            <div *ngIf="plantGroupDataForm.controls['STATUS'].invalid && plantGroupDataForm.controls['STATUS'].touched">
                                <small class="text-danger" *ngIf="plantGroupDataForm.controls['STATUS'].errors?.required">
                                    You must specify a valid status
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Last Updated By</label>
                        <div class="col-sm-8">
                            {{plantGroupDataSelected.lastupdateby}}
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Date of Last Update</label>
                        <div class="col-sm-8">
                            {{plantGroupDataSelected.lastupdatedate | date:'short'}}
                        </div>
                    </div>

                </form>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" #closeCorrectModel (click)="clear()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]="plantGroupDataForm.errors || plantGroupDataForm.invalid" (click)="submitPlantGroupData()">Edit</button>
            </div>
        </div>
    </div>
</div>
