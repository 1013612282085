import { Component } from '@angular/core';

@Component({
  selector: 'app-view-all-recall-rights',
  templateUrl: './view-all-recall-rights.component.html',
  styleUrls: ['./view-all-recall-rights.component.css'],
})
export class ViewAllREcallRightsComponent {
  isRRView: boolean = true;
  moduleName: string = 'VIEWS';
  viewName: string = 'ViewAllRecallRights';
  constructor() {}
}
