<div class="container-fluid app-box">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Table Maintenance</li>
        <li class="breadcrumb-item brd-li brd-li-child">Overtime Group Data</li>
      </ol>
    </nav>
    <div class="align-self-end ml-auto">
      <button
        *ngIf="views[0]?.allowAdd"
        class="btn btn-success newbtn"
        data-toggle="modal"
        [attr.data-target]="modalId"
        data-backdrop="false"
        (click)="showAddModal()"
      >
        Add Overtime Group
      </button>
    </div>
    <div
      *ngIf="
        overtimeGroupSearchData !== null && overtimeGroupSearchData.length > 0
      "
    >
      <button
        class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
        style="margin-right: 50px"
        (click)="exportElmToExcel()"
      >
        Export to Excel
      </button>
    </div>
  </div>

  <div>
    <div class="row content-container mt-2 mb-2">
      <div class="pt-2 div-color">
        <div class="search-divider-div filtercss">
          <p class="search-divder-p"></p>
          <hr class="search-divider-hr" />
          <div class="jumbotron jumb-new jumb-newcontrols">
            <div class="row" display="float">
              <div class="col">
                <app-filter
                  [filterConfig]="filterConfig"
                  (filterValue)="getFilterValue($event)"
                  (formcontrolobj)="getbyenter($event)"
                >
                </app-filter>
              </div>
              <div class="col-md-1 mr-5">
                <label for="" style="color: white">&nbsp;</label>
                <a
                  class="btn btn-lg search-button"
                  (click)="searchOvertimeGroups()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          style="height: 10px; background: white; position: sticky; top: 95px"
        ></div>
        <div>
          <div>
            <table
              class="table table-striped"
              *ngIf="
                !isDataExportableView &&
                overtimeGroupSearchData !== null &&
                overtimeGroupSearchData.length > 0
              "
              #overtimegroupdataTablemaintenanceExportTable
            >
              <thead class="table-header">
                <tr>
                  <!-- <th scope="col">Name</th>
                                    <th scope="col">Department(s)</th>
                                    <th scope="col">Classification(s)</th>
                                    <th scope="col">Shift</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Status</th> -->

                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'overtimegroupname', 'string')
                      "
                      >Name
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'overtimegroupname'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'overtimegroupname'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'overtimegroupname'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'department', 'string')"
                      >Department
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'department'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'department'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'department'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="onSortClick($event, 'classification', 'string')"
                      >Classification
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'classification'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'classification'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'classification'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'shiftdescription', 'string')
                      "
                      >Shift
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'shiftdescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'shiftdescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'shiftdescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick(
                          $event,
                          'overtimegroupdescription',
                          'string'
                        )
                      "
                      >Description
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'overtimegroupdescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'overtimegroupdescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'overtimegroupdescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'status', 'string')"
                      >Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'status'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'status'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <!-- <th scope="col">Edit</th>
                                    <th scope="col">Delete</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  class=""
                  *ngFor="let data of overtimeGroupSearchData; let i = index"
                >
                  <td class="left-tab-value">{{ data.overtimegroupname }}</td>
                  <td class="left-tab-value">{{ data.department }}</td>
                  <td class="left-tab-value">{{ data.classification }}</td>
                  <td class="tab-value">{{ data.shiftdescription }}</td>
                  <td class="left-tab-value">
                    {{ data.overtimegroupdescription }}
                  </td>
                  <td class="tab-value">
                    {{ data.status === 'A' ? 'Active' : 'Inactive' }}
                  </td>
                  <!-- <td class="tab-value"><button *ngIf='views[0]?.allowUpdate' data-toggle="modal" [attr.data-target]="modalId" class="cursor" (click)="selectOvertimeGroup(i, data.overtimegroupid)"><img class="img2"
                                                src="../../../../../assets/edit-regular.svg"></button></td>
                                    <td class="tab-value"> <button *ngIf='views[0]?.allowDelete' data-toggle="modal" [attr.data-target]="modalId" class="cursor" (click)="showDeleteModal(data.overtimegroupid)"><img
                                                src="../../../../../assets/delete.png"></button></td> -->
                </tr>
              </tbody>
            </table>

            <table
              class="table table-striped"
              *ngIf="
                overtimeGroupSearchData !== null &&
                overtimeGroupSearchData.length > 0
              "
            >
              <thead class="table-header">
                <tr>
                  <!-- <th scope="col">Name</th>
                                    <th scope="col">Department(s)</th>
                                    <th scope="col">Classification(s)</th>
                                    <th scope="col">Shift</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Status</th> -->
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'overtimegroupname', 'string')
                      "
                      >Name
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'overtimegroupname'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'overtimegroupname'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'overtimegroupname'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'department', 'string')"
                      >Department(s)
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'department'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'department'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'department'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col" style="width: 250px">
                    <span
                      (click)="onSortClick($event, 'classification', 'string')"
                      >Classification(s)
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'classification'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'classification'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'classification'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick($event, 'shiftdescription', 'string')
                      "
                      >Shift
                      <img
                        *ngIf="
                          sortDir === 1 && sortColumn === 'shiftdescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 && sortColumn === 'shiftdescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 && sortColumn !== 'shiftdescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span
                      (click)="
                        onSortClick(
                          $event,
                          'overtimegroupdescription',
                          'string'
                        )
                      "
                      >Description
                      <img
                        *ngIf="
                          sortDir === 1 &&
                          sortColumn === 'overtimegroupdescription'
                        "
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir === -1 &&
                          sortColumn === 'overtimegroupdescription'
                        "
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="
                          sortDir !== 0 &&
                          sortColumn !== 'overtimegroupdescription'
                        "
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'status', 'string')"
                      >Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'status'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'status'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">Edit</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class=""
                  *ngFor="
                    let data of overtimeGroupSearchData
                      | paginate
                        : {
                            id: 'joblist',
                            itemsPerPage: 20,
                            currentPage: Applicationpage,
                            totalItems: TotalApplicationCount
                          };
                    let i = index
                  "
                >
                  <td class="left-tab-value">{{ data.overtimegroupname }}</td>
                  <td class="left-tab-value">{{ data.department }}</td>
                  <td class="left-tab-value" style="width: 250px">
                    {{ data.classification }}
                  </td>
                  <td class="tab-value">{{ data.shiftdescription }}</td>
                  <td class="left-tab-value">
                    {{ data.overtimegroupdescription }}
                  </td>
                  <td class="tab-value">
                    {{ data.status === 'A' ? 'Active' : 'Inactive' }}
                  </td>
                  <td class="tab-value">
                    <button
                      *ngIf="views[0]?.allowUpdate"
                      data-toggle="modal"
                      [attr.data-target]="modalId"
                      data-backdrop="false"
                      class="cursor"
                      (click)="selectOvertimeGroup(i, data.overtimegroupid)"
                    >
                      <img
                        class="img2"
                        src="../../../../../assets/edit-regular.svg"
                      />
                    </button>
                  </td>
                  <td class="tab-value">
                    <button
                      *ngIf="views[0]?.allowDelete"
                      data-toggle="modal"
                      [attr.data-target]="modalId"
                      data-backdrop="false"
                      class="cursor"
                      (click)="showDeleteModal(data.overtimegroupid)"
                    >
                      <img src="../../../../../assets/delete.png" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="single-wrap d-flex justify-content-center"
              *ngIf="
                overtimeGroupSearchData !== null &&
                overtimeGroupSearchData.length > 0
              "
            >
              <nav aria-label="Page navigation example">
                <pagination-controls
                  class="my-pagination"
                  id="joblist"
                  (pageChange)="Applicationpage = $event"
                  previousLabel=""
                  nextLabel=""
                >
                </pagination-controls>
              </nav>
            </div>
            <div *ngIf="isApplicationDataEmpty">
              <p *ngIf="isApplicationDataEmpty">No existing records Found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Edit Modal -->
  <app-update-overtime-group-data
    *ngIf="showEdit"
    [userName]="this.userInformation.userName"
    [selectedOvertimeGroup]="selectedOvertimeGroup"
    (isSucess)="CheckEdit($event)"
  >
  </app-update-overtime-group-data>
  <app-add-overtime-group-data
    *ngIf="showAdd"
    [userName]="this.userInformation.userName"
    (isSucess)="checkAdd($event)"
  >
  </app-add-overtime-group-data>
  <app-delete-overtime-group-data
    [selectedOvertimeGroup]="selectedOvertimeGroup"
    (isSucess)="checkDelete($event)"
  >
  </app-delete-overtime-group-data>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
