import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { ToolAndDiePlantService } from 'src/app/Services/Applications/ToolAndDie/tool-and-die-plant.service';
import { TransferAgreementService } from 'src/app/Services/Applications/TransferAgreementService/transfer-agreement.service';
import { RuleEngineService } from 'src/app/Services/Ruleengine/rule-engine.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';
import { UserRoles } from 'src/enum/userroles';
import { userData } from 'src/models/userdata';
import { UserInfo } from 'src/models/userinfo';

@Component({
  selector: 'app-transfer-agreement',
  templateUrl: './transfer-agreement.component.html',
  styleUrls: ['./transfer-agreement.component.css'],
})
export class TransferAgreementComponent implements OnInit {
  public loading = false;
  emloyeeData: any = [];
  userData: any = [];
  modalPopTitle = {
    title: '',
    type: '',
  };
  employeeForm!: FormGroup;
  selectedEmp: any;
  isEditButton: boolean = false;
  isAddButton: boolean = false;
  isEdit: boolean = false;
  isCancel: boolean = false;
  modalTitle: any = '';
  isAdd: boolean = false;
  AppType: string = 'TA';
  modalId: string | any;
  typeSelected: any;
  screenType: any = [];
  isApplication: boolean = false;
  searchKey: string = '';
  isDeleted: boolean = false;
  showFilter: boolean = true;
  applicationData: any = [];
  disablededit = false;
  disabledcancel = false;
  disableddelete = false;

  userInformation!: UserInfo; // Model for UserInfo
  usersData!: userData; // Model for UAW user details

  userRole: UserRoles = UserRoles.PRODUCTIONUSER; // Enum for userrole
  isUserListDataEmpty: boolean = false;
  isExistingRecordsDatalength: boolean = false;

  EditButtonDisabled!: boolean; // checks if status is Cancelled
  CancelButtonDisabled!: boolean; // checks if status is Cancelled

  noOfOpenApplications!: number; /* will show how many applications user has open*/

  isLoggedInUserAdmin: boolean = false; /* stores the bool value if admin*/
  AdminAtid: string = '';

  TotalApplicationCount!: number;
  Applicationpage: number = 1;

  TotalUserCount!: number;
  Userpage: number = 1;

  isAddButtonEnabled: boolean = true;
  ruleEngineValidation: boolean = false;
  ruleEngineMessage: any;

  filterText: string = 'normal';
  filterConfig: any = [
    // {
    //   label: 'Application number',
    //   type: 'input',
    //   data: [],
    //   placeholder: 'Enter application no. ',
    //   default: 'All',
    //   controlName: 'AppNo',
    //   width:"200px",
    //   icon:'<img src="./assets/search-solid.svg" />'

    // },
    {
      label: 'Last Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'lastName',
      width: '120px',
      runClassification: false,
    },
    {
      label: 'First Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'firstName',
      width: '120px',
      runClassification: false,
    },
    {
      label: 'Employee Id',
      type: 'input',
      data: [],
      placeholder: 'Employee Id',
      default: 'All',
      controlName: 'EmployeeId',
      width: '120px',
      runClassification: false,
    },
    {
      label: 'Status',
      type: 'dropdown',
      data: ['option 1', 'option 2'],
      default: 'All',
      controlName: 'appStatusCode',
      width: '120px',
      runClassification: false,
    },
    {
      label: 'From',
      type: 'date',
      data: ['option 1', 'option 2'],
      default: 'All',
      controlName: 'fromDate',
      width: '145px',
      runClassification: false,
    },
    {
      label: 'To',
      type: 'date',
      data: ['option 1', 'option 2'],
      default: 'All',
      controlName: 'toDate',
      width: '145px',
      runClassification: false,
    },
  ];
  filterConfigAdd: any = [
    {
      label: 'Last Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'lastName',
      width: '120px',
    },
    {
      label: 'First Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'firstName',
      width: '120px',
    },
    {
      label: 'Employee Id',
      type: 'input',
      data: [],
      placeholder: 'Employee Id',
      default: 'All',
      controlName: 'EmployeeId',
      width: '120px',
    },
  ];
  filterData: any = {};
  filterDataUser: any = {};
  EditButtonDisabled1!: boolean;
  CancelButtonDisabled1!: boolean;
  DeleteButtonDisabled1!: boolean;
  views: any[] = [];
  constructor(
    private fb: FormBuilder,
    private td: ToolAndDiePlantService,
    private _transferAgreementService: TransferAgreementService,
    private sweetAlert: SweetAlertService,
    public _userInfo: UsergroupService,
    private _ruleEngine: RuleEngineService,
    private _commonApplicationService: CommonService
  ) {}

  ngOnInit(): void {
    this.EditButtonDisabled1 = true;
    this.CancelButtonDisabled1 = true;
    this.DeleteButtonDisabled1 = true;
    this.screenType = [
      { Id: 1, Name: 'Application' },
      { Id: 2, Name: 'User' },
    ];
    this.typeSelected = 1;
    this.getShifts();
    this.getStatus();
    this.getUserRoleDescription();
  }

  //apis

  //Function to get logged in user's role and details
  getUserRoleDescription() {
    this._userInfo.getUserInfo().subscribe((data: any) => {
      // this.userRole = data["groupDescription"] === UserRoles.HRPLUS ? UserRoles.HRPLUS : UserRoles.PRODUCTIONUSER;
      if (
        data['groupDescription'] === UserRoles.HRPLUS ||
        data['groupDescription'] === UserRoles.COMMITTEE
      ) {
        this.userRole = UserRoles.HRPLUS;
      } else {
        this.userRole = UserRoles.PRODUCTIONUSER;
      }
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'APPLICATIONS',
        'TRANSFERAGREEMENT'
      );
      this._transferAgreementService.atid.next(this.userInformation.userName);
      this.AdminAtid = this.userInformation.userName;
      if (this.userRole === UserRoles.PRODUCTIONUSER) {
        this.searchApplication();
        this._transferAgreementService
          .searchFilterApplicationUser({
            employeeId: this.userInformation.employeeId,
            AppType: 'TA',
          })
          .subscribe((res) => {
            this.userData = res;
            this.usersData = res[0];
            if (this.userData != undefined && this.userData.length > 0) {
              this.selectedEmp = this.userData[0];
              this.selectedEmp.selected = true;
            }
          });
      }
    });
  }

  //Checks if logged in user is Admin
  isAdmin(): boolean {
    if (this.userRole === UserRoles.HRPLUS) {
      this.isLoggedInUserAdmin = true;
    } else {
      this.isLoggedInUserAdmin = false;
    }
    return this.userRole === UserRoles.HRPLUS;
  }
  getStatus() {
    const elements: any = [
      {
        statusId: 'OPEN',
        statusDescription: 'OPEN',
      },
      {
        statusId: 'CLOSED',
        statusDescription: 'CLOSED',
      },
      {
        statusId: 'CANCELLED',
        statusDescription: 'CANCELLED',
      },
    ];
    const mapElement = elements.map(
      (element: { statusId: string; statusDescription: string }) => ({
        id: element.statusId,
        description: element.statusDescription,
      })
    );
    for (let index = 0; index < this.filterConfig.length; index++) {
      if (this.filterConfig[index].label === 'Status') {
        this.filterConfig[index].data = mapElement;
      }
    }
  }

  getShifts() {
    this.td.getShiftsList().subscribe((res) => {
      const elements = res || [];
      const mapElement = elements.map(
        (element: { shiftId: any; shiftDescription: any }) => ({
          id: element.shiftId,
          description: element.shiftDescription,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Shift') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  //For application - HRPLUS
  selectEmp(idx: number, employeeId: any, isAppTimely: any, appNo: string) {
    this.disablededit = false;
    this.disabledcancel = false;
    this.disableddelete = false;
    if (!isAppTimely) {
      this.disablededit = true;
    }
    // this._transferAgreementService.searchFilterApplication({ appNo: appNo }).subscribe((data: any) => {
    for (let index = 0; index < this.emloyeeData.length; index++) {
      if (
        this.emloyeeData[index].employeeId === employeeId &&
        this.emloyeeData[index].appNo === appNo
      ) {
        this.selectedEmp = this.emloyeeData[index];
      }
    }

    //checking if the appstatus of the selected record is cancelled
    if (this.selectedEmp['appStatusCode'].includes('CLOSED')) {
      this.CancelButtonDisabled = true;
      this.disablededit = false;
      this.disableddelete = false;
      this.disabledcancel = true;
    } else if (
      this.selectedEmp['appStatusCode'].includes('PENDING CANCELLED')
    ) {
      this.CancelButtonDisabled = true;
      this.disablededit = false;
      this.disableddelete = false;
      this.disabledcancel = true;
    } else if (this.selectedEmp['appStatusCode'].includes('CANCELLED')) {
      this.CancelButtonDisabled = true;
      this.EditButtonDisabled = false;
      this.disabledcancel = true;
      this.disablededit = false;
    } else {
      this.disablededit = false;
      this.disabledcancel = false;
      this.disableddelete = false;
    }
    // });
  }
  //For application - UAW WORKER
  selectUserEmp(idx: number) {
    this.applicationData[idx].selected = true;
    // for (let index = 0; index < this.applicationData.length; index++) {
    //   this.applicationData[index].selected = false;
    // }
    this.applicationData[idx].selected = true;
    this.selectedEmp = this.applicationData[idx];

    //checking if the appstatus of the selected record is cancelled
    if (this.selectedEmp['appStatusCode'].includes('PENDING CANCELLED')) {
      this.CancelButtonDisabled = true;
    } else if (this.selectedEmp['appStatusCode'].includes('CANCELLED')) {
      this.CancelButtonDisabled = true;
    } else if (this.selectedEmp['appStatusCode'].includes('CLOSED')) {
      this.CancelButtonDisabled = true;
    } else {
      this.CancelButtonDisabled = false;
    }
  }

  //For User - UAW
  selectUser(idx: number, employeeId: string) {
    // this.userData[idx].selected = true;
    // for (let index = 0; index < this.userData.length; index++) {
    //   this.userData[index].selected = false;
    // }
    // this.userData[idx].selected = true;
    // this.selectedEmp = this.userData[idx];
    for (let index = 0; index < this.userData.length; index++) {
      if (this.userData[index].employeeId === employeeId) {
        this.selectedEmp = this.userData[index];
      }
    }
  }
  //Function searches users
  SearchByUser(e: any) {
    if (e && e.target && e.target.value) {
      this.searchKey = (e && e.target && e.target.value) || '';
    } else {
      this.searchKey = e;
    }
    if (this.isApplication) {
      this._transferAgreementService
        .SearchUser(this.searchKey)
        .subscribe((res) => {
          this.userData = res;
          this.isAddButton = true;
          if (this.userData.length === 0) {
            this.isAddButton = false;
            this.sweetAlert.simpleAlert(
              'error',
              'Oops',
              'No result found for this search.'
            );
          }
        });
    } else {
      this._transferAgreementService
        .SearchApplicationByUser(this.searchKey)
        .subscribe((res) => {
          this.emloyeeData = res;
          //this.recordStatus = res[]
          this.isEditButton = true;
          if (this.emloyeeData.length === 0) {
            this.isEditButton = false;
            this.sweetAlert.simpleAlert(
              'error',
              'Oops',
              'No result found for this search.'
            );
          }
        });
    }
  }
  // Function to add employee - HRPLUS
  addEmployee(idx: number, employeeId: string) {
    this.selectUser(idx, employeeId);
    if (this.selectedEmp) {
      this.isAdd = true;
      this.modalId = '#addEmployee';
    } else {
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }
  //Resets the form
  clear() {
    this.employeeForm.reset();
  }
  //Function to correct the application - HRPLUS
  edit() {
    if (this.selectedEmp && this.userInformation) {
      this.modalTitle = 'Edit Employee';
      this.isEdit = true;
      this.modalId = '#editEmp';
    } else {
      this.modalId = '';
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'Please select employee to edit'
      );
    }
  }

  //Function to delete the application - HRPLUS
  deleteApplication() {
    if (this.selectedEmp) {
      this.modalId = '#deleteApplicationModal';
    } else {
      this.modalId = '';
      alert('No Employee Selected');
    }
  }

  //Resets the form after deletion of application - HRPLUS
  CheckDelete(e: any) {
    this.isDeleted = e;
    if (this.isDeleted === true && this.searchKey) {
      this.emloyeeData = this.SearchByUser(this.searchKey);
    } else {
      this.search();
    }
  }
  //Resets the form after correction of application - HRPLUS
  CheckEdit(e: any) {
    this.isEdit = e;
    if (this.isEdit === true && this.searchKey) {
      this.emloyeeData = this.SearchByUser(this.searchKey);
    } else {
      this.search();
    }
  }
  //Resets the form after cancellation of application - HRPLUS
  CheckCancel(e: any) {
    this.isCancel = e;
    if (this.isCancel === true && this.searchKey) {
      this.emloyeeData = this.SearchByUser(this.searchKey);
    } else {
      this.search();
    }
  }
  //Function to cancel and application application - HRPLUS
  cancelApplication() {
    if (this.selectedEmp && this.userInformation) {
      if (this.selectedEmp && this.selectedEmp.appId) {
        // this._transferAgreementService.Appid.next(this.selectedEmp.appId);
        // this._transferAgreementService.EmployeeSignDate.next(this.selectedEmp.empSignDate);
        // this._transferAgreementService.applicationAtid.next(this.selectedEmp.employeeId)
      } else {
        this.sweetAlert.simpleAlert('errror', 'App Id MIssing', '');
      }
      this.modalId = '#cancelApplicationModal';
    } else {
      this.modalId = '';
      alert('No Employee Selected');
    }
  }
  //Search-By grid - HRPLUS
  onAppTypeSelected(val: number) {
    if (val === 2) {
      this.EditButtonDisabled1 = true;
      this.DeleteButtonDisabled1 = true;
      this.CancelButtonDisabled1 = true;
      this.isApplication = true;
      this.showFilter = false;
      this.emloyeeData = null;
      this.filterData = {};
      this.userData = null;
      this.isExistingRecordsDatalength = false;
    }
    if (val === 1) {
      this.isApplication = !this.isApplication;
      this.showFilter = true;
      this.userData = null;
      this.filterDataUser = {};
      this.isUserListDataEmpty = false;
    }
  }

  //Function to check if  application is selected - HRPLUS
  isApplicationSelected() {
    return $('input[name=application]:checked').length > 0;
  }

  cleanEmp(event: any) {}

  //Filter - HRPLUS
  showFilters() {
    if (this.showFilter) {
      this.showFilter = false;
      this.filterText = 'normal';
    } else {
      this.showFilter = true;
      this.filterText = 'bold';
    }
  }
  //Filter - HRPLUS
  getFilterValue(event: any) {
    this.filterData = event;
  }
  //Filter - HRPLUS
  getFilterDataUser(event: any) {
    this.filterDataUser = event;
  }
  //Search by Application - HRPLUS/UAW
  search() {
    if (this.userRole === UserRoles.PRODUCTIONUSER) {
      this.searchApplication();
      return;
    }
    this.loading = true;
    let queryParams = this.filterData;
    Object.keys(queryParams).forEach(
      (k) =>
        !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]
    );
    queryParams.appType = this.AppType;
    this._transferAgreementService
      .searchFilterApplication(queryParams)
      .subscribe((data) => {
        this.emloyeeData = data;
        for (var i = 0; i < this.emloyeeData.length; i++) {
          this.emloyeeData[i].isAppTimely = true;
          if (
            (this.emloyeeData[i].appStatusCode.includes('OPEN') ||
              this.emloyeeData[i].appStatusCode.includes('CANCELLED')) &&
            (!this.emloyeeData[i].statustimely ||
              !this.emloyeeData[i].emptimely)
          ) {
            this.emloyeeData[i].appStatusCode =
              'PENDING ' + this.emloyeeData[i].appStatusCode;
            this.emloyeeData[i].isAppTimely = false;
          }

          if (this.emloyeeData[i].empSignDate != null) {
            this.emloyeeData[i].empSignDateSort = new Date(
              this.emloyeeData[i].empSignDate
                .replace(/-/g, '/')
                .replace(/T.+/, '')
            ).toISOString();
          }

          if (this.emloyeeData[i].plantSeniorityDate != null) {
            this.emloyeeData[i].plantSeniorityDateSort = new Date(
              this.emloyeeData[i].plantSeniorityDate
                .replace(/-/g, '/')
                .replace(/T.+/, '')
            ).toISOString();
          }

          if (this.emloyeeData[i].seniorityDate != null) {
            this.emloyeeData[i].seniorityDateSort = new Date(
              this.emloyeeData[i].seniorityDate
                .replace(/-/g, '/')
                .replace(/T.+/, '')
            ).toISOString();
          }
        }
        this.isExistingRecordsDatalength =
          this.emloyeeData.length === 0 ? true : false;
        this.TotalApplicationCount = data.length;
        this.EditButtonDisabled1 = false;
        this.DeleteButtonDisabled1 = false;
        this.disablededit = true;
        this.disabledcancel = true;
        this.disableddelete = true;
        this.CancelButtonDisabled1 = false;
        this.Applicationpage = 1;
        this.loading = false;
      });
  }
  searchbyUser() {
    this.loading = true;
    let queryParams = this.filterDataUser;
    Object.keys(queryParams).forEach(
      (k) =>
        !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]
    );
    queryParams.AppType = 'TA';
    this._transferAgreementService
      .searchFilterApplicationUser(queryParams)
      .subscribe((data) => {
        this.userData = data;
        this.TotalUserCount = data.length;
        this.isUserListDataEmpty = this.userData.length === 0 ? true : false;
        this.Userpage = 1;
        this.loading = false;
      });
  }

  //Function search for UAW user's applications
  searchApplication() {
    this.loading = true;
    let queryParams = {
      employeeId: this.userInformation.employeeId,
      appStatusCode: '',
      AppType: 'TA',
    };
    let queryParamsStatusOpem = {
      employeeId: this.userInformation.employeeId,
      appStatusCode: 'OPEN',
      AppType: 'TA',
    };
    let queryParamsStatusPendingCancelled = {
      employeeId: this.userInformation.employeeId,
      appStatusCode: 'PENDING CANCELLED',
      AppType: 'TA',
    };

    this._transferAgreementService
      .searchFilterApplication(queryParams)
      .subscribe((data) => {
        this.applicationData = data;
        for (var i = 0; i < this.applicationData.length; i++) {
          this.applicationData[i].isAppTimely = true;
          if (
            (this.applicationData[i].appStatusCode.includes('OPEN') ||
              this.applicationData[i].appStatusCode.includes('CANCELLED')) &&
            (!this.applicationData[i].statustimely ||
              !this.applicationData[i].emptimely)
          ) {
            this.applicationData[i].appStatusCode =
              'PENDING ' + this.applicationData[i].appStatusCode;
            this.applicationData[i].isAppTimely = false;
          }
          if (this.applicationData[i].empSignDate != null) {
            this.applicationData[i].empSignDateSort = new Date(
              this.applicationData[i].empSignDate
                .replace(/-/g, '/')
                .replace(/T.+/, '')
            ).toISOString();
          }

          if (this.applicationData[i].plantSeniorityDate != null) {
            this.applicationData[i].plantSeniorityDateSort = new Date(
              this.applicationData[i].plantSeniorityDate
                .replace(/-/g, '/')
                .replace(/T.+/, '')
            ).toISOString();
          }

          if (this.applicationData[i].seniorityDate != null) {
            this.applicationData[i].seniorityDateSort = new Date(
              this.applicationData[i].seniorityDate
                .replace(/-/g, '/')
                .replace(/T.+/, '')
            ).toISOString();
          }
        }

        this.loading = false;
        this._ruleEngine
          .addTAApplicationRule(this.userInformation.employeeId)
          .subscribe((data) => {
            this.ruleEngineValidation = data['status'];
            this.ruleEngineMessage = data['errors'];
            if (this.ruleEngineValidation === false) {
              this.isAddButtonEnabled = false;
            }
          });
      });

    this._transferAgreementService
      .searchFilterApplication(queryParamsStatusOpem)
      .subscribe((OpenApplicationCount) => {
        this._transferAgreementService
          .searchFilterApplication(queryParamsStatusPendingCancelled)
          .subscribe((PendingCancelledCount) => {
            this.noOfOpenApplications =
              20 - (OpenApplicationCount.length + PendingCancelledCount.length);
            if (this.noOfOpenApplications === 0) {
              this.isAddButtonEnabled = false;
            }
          });
      });
  }

  //Resets the list of user applications
  CheckAdd(e: any) {
    this.isAdd = e;
    if (this.isAdd === false) {
      this.applicationData = this.searchApplication();
      this.userData = this.searchbyUser();
    } else {
      if (this.userRole === UserRoles.PRODUCTIONUSER) {
        this.searchApplication();
        return;
      }
      this.searchbyUser();
    }
  }
  // UAW user Add
  addApplication() {
    if (this.userInformation) {
      this.isAdd = true;
      this.modalId = '#addEmployee';
    } else {
      this.sweetAlert.simpleAlert(
        'error',
        'Oops',
        'You must select an employee to perform this action.'
      );
    }
  }

  getbyenter(e: any) {
    this.loading = true;
    let searchData;
    e.appType = this.AppType;
    if (this.filterData != null) {
      searchData = { ...e, ...this.filterData };
    }
    this._transferAgreementService
      .searchFilterApplication(searchData)
      .subscribe((data) => {
        this.emloyeeData = data;
        for (var i = 0; i < this.emloyeeData.length; i++) {
          this.emloyeeData[i].isAppTimely = true;
          if (
            (this.emloyeeData[i].appStatusCode.includes('OPEN') ||
              this.emloyeeData[i].appStatusCode.includes('CANCELLED')) &&
            (!this.emloyeeData[i].statustimely ||
              !this.emloyeeData[i].emptimely)
          ) {
            this.emloyeeData[i].appStatusCode =
              'PENDING ' + this.emloyeeData[i].appStatusCode;
            this.emloyeeData[i].isAppTimely = false;
          }

          if (this.emloyeeData[i].empSignDate != null) {
            this.emloyeeData[i].empSignDateSort = new Date(
              this.emloyeeData[i].empSignDate
                .replace(/-/g, '/')
                .replace(/T.+/, '')
            ).toISOString();
          }
          if (this.emloyeeData[i].seniorityDate != null) {
            this.emloyeeData[i].seniorityDateSort = new Date(
              this.emloyeeData[i].seniorityDate
                .replace(/-/g, '/')
                .replace(/T.+/, '')
            ).toISOString();
          }
        }
        this.Applicationpage = 1;
        this.EditButtonDisabled1 = false;
        this.DeleteButtonDisabled1 = false;
        this.disablededit = true;
        this.disabledcancel = true;
        this.disableddelete = true;
        this.CancelButtonDisabled1 = false;
        this.isExistingRecordsDatalength =
          this.emloyeeData.length === 0 ? true : false;
        this.TotalApplicationCount = data.length;
        this.loading = false;
      });
  }

  getbyenterforuser(e: any) {
    this.loading = true;
    e.appType = this.AppType;
    this._transferAgreementService
      .searchFilterApplicationUser(e)
      .subscribe((data) => {
        this.userData = data;
        this.isUserListDataEmpty = this.userData.length === 0 ? true : false;
        this.Userpage = 1;
        this.loading = false;
      });
  }

  @ViewChild('TransferAgreementExportTable', {
    read: ElementRef,
    static: false,
  })
  TransferAgreementExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.TransferAgreementExportTable,
        `${ApplicationEnum.TRANSFERAGREEMENT}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }

  sortDir = 1; // 1 = 'ASE' -1 = DSC
  sortColumn = '';

  onSortClick(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArr(fieldName, dataType);
  }

  sortArr(colName: any, dataType: string) {
    if (dataType === 'string') {
      this.emloyeeData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.emloyeeData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }

  onSortClickEmp(event: any, fieldName: string, dataType: string) {
    let target = event.currentTarget,
      classList = target.classList;
    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    this.sortColumn = fieldName;
    this.sortArrEmp(fieldName, dataType);
  }

  sortArrEmp(colName: any, dataType: string) {
    //console.log("",  this.applicationData);
    if (dataType === 'string') {
      this.applicationData.sort((a: any, b: any) => {
        if (a[colName] != null) {
          a = a[colName].toString().toLowerCase();
        }
        if (b[colName] != null) {
          b = b[colName].toString().toLowerCase();
        }
        return a.toString().localeCompare(b) * this.sortDir;
      });
    } else {
      this.applicationData.sort((a: any, b: any) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        } else {
          return 1 * this.sortDir;
        }
      });
    }
  }
}
