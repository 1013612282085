import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { SkilledToSkilledService } from 'src/app/Services/Applications/SkilledToSkilled/skilled-to-skilled.service';
import { DateService } from 'src/app/Services/Date/date.service';
import { RuleEngineService } from 'src/app/Services/Ruleengine/rule-engine.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { UserInfo } from 'src/models/userinfo';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-skilled-to-skilled',
  templateUrl: './add-skilled-to-skilled.component.html',
  styleUrls: ['./add-skilled-to-skilled.component.css'],
})
export class AddSkilledToSkilledComponent
  implements OnInit, OnChanges, OnDestroy
{
  employeeForm!: FormGroup;
  empSelected: any;
  selectedPlantId: any;
  employeeId: any;
  atid: any;
  currentDate = new Date();
  usersData: any;
  currentJobClassification: any;
  StatusCode: boolean = true;
  ruleMessages!: any[];
  errorlength: number = 0;
  //classifications: any;
  medicalRestrictionList: string[] = [];
  CalenderBlockedAfterTodayDate: string = '';
  classifications: any[] = [
    {
      data: [],
      default: 'Select any classification',
    },
  ];

  @Input() loggedInUser: any;
  @Input() selectedEmp: any;
  @Input() isAdd: boolean = false;
  @Input() title: any;
  @Output() clean = new EventEmitter();
  @Output() isSucess = new EventEmitter();
  @Input() AppType: string = '';
  @ViewChild('closeAddModel') closeAddModal!: ElementRef;

  userInformation!: UserInfo;

  constructor(
    private fb: FormBuilder,
    private _sweetAlertService: SweetAlertService,
    private authService: MsalService,
    private _user: UsergroupService,
    private ss: SkilledToSkilledService,
    private _ruleEngine: RuleEngineService,
    private _commonApplicationService: CommonService,
    private _dateService: DateService
  ) {}

  ngOnDestroy(): void {
    localStorage.removeItem('CURRENT_CLASS_DESC_KEY');
    localStorage.removeItem('CURRENT_CLASS_KEY');
    localStorage.removeItem('CLASS_KEY');
  }

  ngOnInit(): void {
    this.getClassifications();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createEmpForm();
    this.loadEmployeeData(changes);
  }

  createEmpForm() {
    this.employeeForm = this.fb.group({
      employeeName: [''],
      firstName: [''],
      lastName: [''],
      EmployeeId: [this.employeeId],
      ATID: [this.atid],
      AppNo: ['0'],
      AppStatusCode: ['OPEN'],
      currentClassification: [''],
      JobCodeId: [
        '',
        [
          Validators.required,
          this.CheckLeaderClassification,
          this.CheckApprenticeClassification,
        ],
      ],
      T09OverrideFlag: [null],
      ExpValidateFlag: ['', [Validators.required, this.CheckExpValidation]],
      T04OverrideFlag: [null, []],
      ClassOverrideFlag: [null, []],
      SeniorityOverrideFlag: [null, []],
      Comments: [null, []],
      SpecialSP: [null, []],
      App150PlantGroupId: ['0'],
      AppType: ['152'],
      DateEntered: [this.currentDate],
      EnteredBy: [this.loggedInUser.userName],
      appStatusDate: [this.currentDate],
      empSignDate: ['', [Validators.required]],
      StatusOverrideFlag: [null, []],
    });
    this.isAdd = false;
  }

  loadEmployeeData(changes: SimpleChanges) {
    this.empSelected =
      (changes.selectedEmp && changes.selectedEmp.currentValue) || '';
    if (this.empSelected === '') {
      this.empSelected =
        (changes.selectedEmp && changes.selectedEmp.previousValue) || '';
    }

    this.getMedicalRestrictions(this.empSelected.employeeId);
    this.getRuleEngineValidations(this.empSelected.employeeId);
    this.CalenderBlockedAfterTodayDate =
      this._commonApplicationService.formatDate(this.currentDate);
    this.employeeForm
      .get('employeeName')
      ?.patchValue(
        this.empSelected.lastName +
          ', ' +
          this.empSelected.firstName +
          ' ' +
          this.empSelected.middleName
      );
    this.employeeForm.get('firstName')?.patchValue(this.empSelected.firstName);
    this.employeeForm.get('lastName')?.patchValue(this.empSelected.lastName);
    this.employeeId = this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.empSelected.employeeId);
    this.atid = this.employeeForm
      .get('ATID')
      ?.patchValue(this.empSelected.atid);
    this.employeeForm
      .get('empSignDate')
      ?.patchValue(this._commonApplicationService.formatDate(new Date()));
    this.ss
      .searchFilterApplicationUser({ employeeId: this.empSelected.employeeId })
      .subscribe((data) => {
        this.usersData = data[0];
        this.currentJobClassification = this.usersData.jobCodeId;
        localStorage.setItem('CURRENT_CLASS_KEY', this.usersData.jobCodeId);
        localStorage.setItem(
          'CURRENT_CLASS_DESC_KEY',
          this.usersData.jobCodeDescription
        );
        this.employeeForm
          .get('currentClassification')
          ?.patchValue(
            this.usersData.jobCodeId + '-' + this.usersData.jobCodeDescription
          );
      });
  }

  getRuleEngineValidations(employeeId: string) {
    this._ruleEngine
      .addSS152ApplicationRule(employeeId)
      .subscribe((data: any) => {
        this.StatusCode = data['status']; //for error: false
        this.ruleMessages = data['errors'];
        this.errorlength = data['errors'].length;
      });
  }

  getMedicalRestrictions(employeeId: string) {
    this._ruleEngine.getMedicalRestrictions(employeeId).subscribe((data) => {
      this.medicalRestrictionList = data;
    });
  }

  CheckLeaderClassification(control: FormControl) {
    let jobClassification = control.value;
    let checkChar = jobClassification.charAt(jobClassification.length - 1);
    if (checkChar === 'L') {
      return {
        leaderClassificationError:
          'The selected classification cannot be a leader classification',
      };
    }
    return null;
  }

  CheckApprenticeClassification(control: FormControl) {
    let jobClassification = control.value;
    let checkChar = jobClassification.charAt(jobClassification.length - 1);
    if (checkChar === 'A') {
      return {
        apprenticeClassificationError:
          'The selected classification cannot be a apprentice classification',
      };
    }
    return null;
  }

  CheckExpValidation(control: FormControl) {
    let isExpValidateChecked = control.value;
    if (isExpValidateChecked === false) {
      return {
        expValidateError: 'Validate Experience is required',
      };
    }
    return null;
  }

  // CheckEmpSignDate(control: FormControl) {
  //   let currentDate = new Date();
  //   let newCurrentDate = currentDate
  //   let empSignDate = new Date(control.value).toDateString();
  //   if (empSignDate > currentDate) {
  //     return {
  //       empSignDateError:
  //         'Employee sign date cannot be greater than today date',
  //     };
  //   }
  //   return null;
  // }

  clear() {
    this.clean.emit('');
    this.closeAddModal.nativeElement.click();
    this.ResetEmployeeForm();
  }

  ResetEmployeeForm() {
    this.createEmpForm();
    this.ReLoadData();
  }

  ReLoadData() {
    this.getMedicalRestrictions(this.empSelected.employeeId);
    this.CalenderBlockedAfterTodayDate =
      this._commonApplicationService.formatDate(this.currentDate);
    this.employeeForm
      .get('employeeName')
      ?.patchValue(
        this.empSelected.firstName + ' ' + this.empSelected.lastName
      );
    this.employeeForm.get('firstName')?.patchValue(this.empSelected.firstName);
    this.employeeForm.get('lastName')?.patchValue(this.empSelected.lastName);
    this.employeeId = this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.empSelected.employeeId);
    this.atid = this.employeeForm
      .get('ATID')
      ?.patchValue(this.empSelected.atid);
    this.employeeForm
      .get('empSignDate')
      ?.patchValue(this._commonApplicationService.formatDate(new Date()));
    this.currentJobClassification = localStorage.getItem('CURRENT_CLASS_KEY');
    this.employeeForm
      .get('currentClassification')
      ?.patchValue(
        localStorage.getItem('CURRENT_CLASS_KEY') +
          '-' +
          localStorage.getItem('CURRENT_CLASS_DESC_KEY')
      );
    this.getRuleEngineValidations(this.empSelected.employeeId);
    this.classifications[this.classifications.length - 1].data = JSON.parse(
      localStorage.getItem('CLASS_KEY') || '{}'
    );
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
    });
  }

  getClassifications() {
    this.ss.getClassifications().subscribe((data) => {
      this.classifications[this.classifications.length - 1].data = data;
      localStorage.setItem('CLASS_KEY', JSON.stringify(data));
    });
  }

  //Method to add an application for an employee
  addEmployee() {
    let appliedClassification = this.employeeForm.get('JobCodeId')?.value;
    if (this.currentJobClassification === appliedClassification) {
      this.wrongClassificationError(); // if the employee's application job classification and current job classification is same.
    } else {
      if (this.ruleMessages.length === 0) {
        // var dt = this.employeeForm.get('empSignDate')?.value;
        // var empDt = new Date(dt);
        // var empSignDtInUtc = this._dateService.GetUTCDate(empDt);
        // this.employeeForm.patchValue({empSignDate: empSignDtInUtc});

        this.ss.Add152Application(this.employeeForm.value).subscribe(
          (data: any) => {
            this.clear();
            this.isSucess.emit(true);
            this._sweetAlertService.successAlert(
              'center',
              'success',
              'The Application has been Added Successfully.',
              false,
              1500
            );
          },
          (_error: HttpErrorResponse) => {
            //this.badRequestAlert();
            if (_error.status === 406) {
              this._sweetAlertService.validationError(
                'error',
                'ERROR',
                `${_error.error
                  .toString()
                  .replace(
                    'Error: SkilledToSkilledTransferApplication152Add - InvalidDataException:',
                    ''
                  )}`
              );
            } else {
              this._sweetAlertService.InternalServerError500(
                'error',
                'ERROR',
                'An Error occured while processing.Please try again.'
              );
            }
          }
        );
      } else {
        this.RuleEngineError(); // if employee has failed to pass the eligibilty criteria.
      }
    }
  }

  RuleEngineError() {
    Swal.fire({
      icon: 'error',
      title: 'Cannot add application for this employee',
      text: 'Employee is not eligible.',
    });
  }

  // successAlert() {
  //   Swal.fire({
  //     position: 'center',
  //     icon: 'success',
  //     title: 'The Application has been Added Successfully.',
  //     showConfirmButton: false,
  //     timer: 1500,
  //   });
  // }

  // badRequestAlert() {
  //   Swal.fire({
  //     icon: 'error',
  //     title: 'ERROR',
  //     text: 'An Error occured while processing.Please try again.',
  //   });
  // }

  wrongClassificationError() {
    Swal.fire({
      icon: 'error',
      title: 'ERROR',
      text: 'Cannot apply for current classification.',
    });
  }

  // validationError() {
  //   Swal.fire({
  //     title: 'Cannot create application',
  //     text: 'User has an OPEN application and/or not eligible to create a new application',
  //     icon: 'warning',
  //     showCancelButton: false,
  //     confirmButtonColor: '#3085d6',
  //     confirmButtonText: 'Ok',
  //   });
  // }
}
