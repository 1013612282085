<div class="row" [formGroup]="filterForm">
  <div
    style="display: flex; flex-direction: column;margin-right:14px;justify-content: space-between;width:{{
      filter.width
    }}"
    *ngFor="let filter of filterConfig"
  >
    <ng-container *ngIf="filter.type === 'input' || filter.type === 'date'">
      <label class="filter-label">{{ filter.label }} </label>

      <input
        [formControlName]="filter.controlName"
        [type]="filter.type"
        (keyup.enter)="getuserentry($event, filter.controlName)"
        class="form-control"
        style="padding: 3px; font-size: 14px; height: 35px !important"
        [placeholder]="filter.placeholder"
      />
    </ng-container>

    <ng-container
      *ngIf="
        filter.type === 'dropdown' &&
        (filter.controlName === 'Dept' || filter.controlName === 'departmentId')
      "
    >
      <label class="filter-label">{{ filter.label }}</label>
      <select
        (change)="patchValue($event, filter.controlName)"
        class="form-control"
        id="exampleFormControlSelect1"
      >
        <option *ngIf="filter.default" selected value="">
          {{ filter.default }}
        </option>
        <option *ngFor="let data of filter.data" [value]="data.id">
          {{ data.id }}
        </option>
      </select>
    </ng-container>
    <ng-container
      *ngIf="
        filter.type === 'dropdown' &&
        !(
          filter.controlName === 'plantId' ||
          filter.controlName === 'jobCodeId' ||
          filter.controlName === 'departmentId' ||
          filter.controlName === 'ClassGroup' ||
          filter.controlName === 'plantApplyingForID' ||
          filter.controlName === 'jobCodeApplyingFor' ||
          filter.controlName === 'Dept'
        )
      "
    >
      <label class="filter-label">{{ filter.label }}</label>
      <select
        (change)="patchValue($event, filter.controlName)"
        class="form-control"
        id="exampleFormControlSelect1"
        style="padding: 3px; font-size: 14px; height: 35px !important"
      >
        <option *ngIf="filter.default" selected value="">
          {{ filter.default }}
        </option>

        <ng-container
          *ngIf="
            filter.runClassification &&
            (filter.controlName === 'SPgroup_Search' ||
              filter.controlName === 'plantid')
          "
        >
          <option *ngFor="let data of filteredGroups" [value]="data.id">
            {{
              data.description === null
                ? data.plantGroupDescription
                : data.description
            }}
          </option>
        </ng-container>

        <ng-container
          *ngIf="
            filter.runClassification && filter.controlName === 'Shift_Search'
          "
        >
          <option *ngFor="let data of filter.data" [value]="data.id">
            {{ data.description }}
          </option>
        </ng-container>

        <ng-container *ngIf="!filter.runClassification">
          <option *ngFor="let data of filter.data" [value]="data.id">
            {{ data.description }}
          </option>
        </ng-container>
      </select>
    </ng-container>
    <ng-container
      *ngIf="
        filter.type === 'dropdown' &&
        (filter.controlName === 'plantId' ||
          filter.controlName === 'jobCodeId' ||
          filter.controlName === 'ClassGroup' ||
          filter.controlName === 'plantApplyingForID' ||
          filter.controlName === 'jobCodeApplyingFor')
      "
    >
      <label class="filter-label">{{ filter.label }}</label>
      <select
        (change)="patchValue($event, filter.controlName)"
        class="form-control"
        style="padding: 3px; font-size: 14px; height: 35px !important"
        id="exampleFormControlSelect1"
      >
        <option *ngIf="filter.default" selected value="">
          {{ filter.default }}
        </option>
        <option *ngFor="let data of filter.data" [value]="data.id">
          {{ data.id }} - {{ data.description }}
        </option>
      </select>
    </ng-container>
  </div>
  <div
    style="display: flex; flex-direction: column;margin-right:14px;justify-content: space-between;width:{{
      filter.width
    }}"
    *ngFor="let filter of filterConfigAdd"
  >
    <ng-container *ngIf="filter.type === 'input' || filter.type === 'date'">
      <label class="filter-label">{{ filter.label }}</label>
      <input
        (keyup.enter)="getuserentry($event, filter.controlName)"
        [formControlName]="filter.controlName"
        [type]="filter.type"
        class="form-control"
        style="padding: 3px; font-size: 14px; height: 35px !important"
        [placeholder]="filter.placeholder"
      />
    </ng-container>
    <!-- <ng-container *ngIf="filter.type === 'button'">
            <a class={{filter.class}} (click)="search()" role="button">Search</a>
        </ng-container> -->
    <ng-container *ngIf="filter.type === 'dropdown'">
      <label class="filter-label">{{ filter.label }}</label>
      <select
        (change)="patchValue($event, filter.controlName)"
        class="form-control"
        id="exampleFormControlSelect1"
      >
        <option *ngIf="filter.default" selected value="">
          {{ filter.default }}
        </option>
        <option *ngFor="let data of filter.data" [value]="data.id">
          {{ data.description }}
        </option>
      </select>
    </ng-container>
  </div>
</div>
