import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';
import { TransferApplicationsViewService } from 'src/app/Services/Views/TransferApplicationsView/transfer-applications-view.service';
import { ApplicationEnum } from 'src/enum/ApplicationEnum';

@Component({
  selector: 'app-transfer-applications',
  templateUrl: './transfer-applications.component.html',
  styleUrls: ['./transfer-applications.component.css'],
})
export class TransferApplicationsComponent implements OnInit {
  TotalApplicationCount: any;
  UserPage: number = 1;
  Applicationpage: number = 1;
  filterData: any = {};
  transferApplicationsViewData: any[] = [];
  userInformation: any;
  views: any[] = [];
  classifications: any;
  plants: any;
  isApplicationDataEmpty: boolean = false;
  filterConfig: any = [
    {
      label: 'Last Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'last_Name',
      width: '109px',
      runClassification: false,
    },
    {
      label: 'First Name',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      default: 'All',
      controlName: 'first_Name',
      width: '109px',
      runClassification: false,
    },
    {
      label: 'Employee Id',
      type: 'input',
      data: [],
      placeholder: 'Employee Id',
      default: 'All',
      controlName: 'EmployeeId',
      width: '160px',
      runClassification: false,
    },
    {
      label: 'Classification Applied For',
      type: 'dropdown',
      data: [],
      //placeholder: 'Search classification',
      default: 'Select One',
      controlName: 'jobCodeApplyingFor',
    },
    {
      label: 'Plant Applied For',
      type: 'dropdown',
      data: [],
      // placeholder: 'Search plant',
      default: 'Select One',
      controlName: 'plantApplyingForID',
    },
  ];

  constructor(
    private _user: UsergroupService,
    private _commonApplicationService: CommonService,
    private _ta: TransferApplicationsViewService,
    private _sweetAlert: SweetAlertService
  ) {}

  ngOnInit(): void {
    this.getClassifications();
    this.getPlants();
    this.getUserInfo();
    //this.searchTransferApplications();
  }

  getUserInfo() {
    this._user.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'VIEWS',
        'TRANSFERAPPLICATIONSVIEW'
      );
    });
  }

  getClassifications() {
    this._ta.getProductionClassifications().subscribe((data: any) => {
      this.classifications = data;
      const mapElement = this.classifications.map(
        (element: { jobCodeId: any; jobCodeDescription: any }) => ({
          id: element.jobCodeId,
          description: element.jobCodeDescription,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Classification Applied For') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  getPlants() {
    this._ta.getActivePlants().subscribe((data: any) => {
      this.plants = data;
      const mapElement = this.plants.map(
        (element: { plantid: any; plantdescription: any }) => ({
          id: element.plantid,
          description: element.plantdescription,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Plant Applied For') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  getFilterValue(event: any) {
    this.filterData = event;
  }

  clearFilterData(filterData: any) {
    Object.keys(filterData).forEach((k) => delete filterData[k]);
  }

  searchTransferApplications() {
    let queryParams = this.filterData;
    Object.keys(queryParams).forEach(
      (k) =>
        !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]
    );
    this._ta.getTransferApplications(queryParams).subscribe(
      (res) => {
        this.transferApplicationsViewData = res;
        this.isApplicationDataEmpty =
          this.transferApplicationsViewData.length === 0 ? true : false;
        this.Applicationpage = 1;
      },
      (_error: HttpErrorResponse) => {
        this.isApplicationDataEmpty =
          this.transferApplicationsViewData.length === 0 ? true : false;
        this._sweetAlert.invalidSelection('error', 'ERROR', _error.error);
      }
    );
  }

  getbyenter(e: any) {
    this._ta.getTransferApplications(e).subscribe((res: any) => {
      this.transferApplicationsViewData = res;
      this.isApplicationDataEmpty =
        this.transferApplicationsViewData.length === 0 ? true : false;
      this.Applicationpage = 1;
    });
  }

  @ViewChild('TransferApplicationsViewExportTable', {
    read: ElementRef,
    static: false,
  })
  TransferApplicationsViewExportTable!: ElementRef;
  isDataExportableView: boolean = true;
  exportElmToExcel(): void {
    this.isDataExportableView = !this.isDataExportableView;
    setTimeout(() => {
      this._commonApplicationService.exportTableElmToExcel(
        this.TransferApplicationsViewExportTable,
        `${ApplicationEnum.TRANSFERAPPLICATIONVIEW}${formatDate(
          new Date(),
          'MM-dd-yyyy',
          'en-US'
        )}`
      );
      this.isDataExportableView = !this.isDataExportableView;
    }, 10);
  }
}
