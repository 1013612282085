<div class="modal fade" id="addEmployee" tabindex="-1" role="dialog" aria-labelledby="addEmployeeLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addEmployeeLabel">Add overtime Reason</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="clear()">
                    <span aria-hidden="true">
                        <img src="../../../../../assets/closeiconnew.svg" alt="close">
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="overtimeForm">
                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Description*</label>
                        <div class="col-sm-8">
                            <input type="text" formControlName="OvertimeReasonDescription" class="form-control">
                            <div *ngIf="overtimeForm.controls['OvertimeReasonDescription'].invalid && overtimeForm.controls['OvertimeReasonDescription'].touched">
                                <small class="text-danger" *ngIf="overtimeForm.controls['OvertimeReasonDescription'].errors?.required">
                                    You must enter the overtime reason description
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Type Association*</label>
                        <div class="col-sm-8">
                            <select formControlName="TypeAssociation" class="form-control">
                                <option *ngIf="ReasonAssociation[ReasonAssociation.length-1].default" selected value="">
                                    {{ReasonAssociation[ReasonAssociation.length-1].default}}</option>
                                <option *ngFor="let data of ReasonAssociation[ReasonAssociation.length-1].data"
                                    value={{data.Type}}>
                                    {{data.Name}}
                                </option>
                            </select>
                            <div *ngIf="overtimeForm.controls['TypeAssociation'].invalid && overtimeForm.controls['TypeAssociation'].touched">
                                <small class="text-danger" *ngIf="overtimeForm.controls['TypeAssociation'].errors?.required">
                                    You must select the reason association of the overtime reason
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Comment Required*</label>
                        <div class="col-sm-8">
                            <div>
                                <input class='radiocomment' id="male" type="radio" value="1" name="commentRequired" formControlName="CommentRequired">
                                <label for="male">Yes</label>

                                <input class='radiocomment' id="male" type="radio" value="0" name="commentRequired" formControlName="CommentRequired">
                                <label for="male">No</label>
                            </div>
                            <div *ngIf="overtimeForm.controls['CommentRequired'].invalid && overtimeForm.controls['CommentRequired'].touched">
                                <small class="text-danger" *ngIf="overtimeForm.controls['CommentRequired'].errors?.required">
                                    You must choose 'Yes' or 'No'
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Status*</label>
                        <div class="col-sm-8">
                            <select formControlName="Status" class="form-control">
                                <option *ngIf="Status[Status.length-1].default" selected value="">
                                    {{Status[Status.length-1].default}}</option>
                                <option *ngFor="let data of Status[Status.length-1].data" value={{data.statusType}}>
                                    {{data.statusName}}
                                </option>
                            </select>
                            <div *ngIf="overtimeForm.controls['Status'].invalid && overtimeForm.controls['Status'].touched">
                                <small class="text-danger" *ngIf="overtimeForm.controls['Status'].errors?.required">
                                    You must specify a valid status
                                </small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary popupbuttonscancel" data-dismiss="modal" #closeAddModel (click)="clear()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]="overtimeForm.errors || overtimeForm.invalid" (click)="submitOvertime()">Add
                </button>
            </div>
        </div>
    </div>
</div>