<div
  class="modal fade"
  id="addEmployee"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addEmployeeLabel"
  aria-hidden="true"
>
  <div class="modal-dialog add-form-alignment" role="document">
    <div class="modal-content" style="position: fixed; width: 945px">
      <div class="modal-header">
        <h5 class="modal-title" id="addEmployeeLabel">Add application</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <img src="../../../../../assets/closeiconnew.svg" alt="close" />
          </span>
        </button>
      </div>

      <form [formGroup]="employeeForm">
        <div
          style="padding-left: 80px; padding-right: 80px; height: 450px"
          class="modal-body"
        >
          <div class="text-danger" *ngIf="errorlength > 0">
            <h6 *ngFor="let error of ruleMessages">*{{ error }}</h6>
          </div>
          <br />
          <div class="form-group row">
            <label for="inputPassword" class="col-sm-4 col-form-label"
              >Employee Name:</label
            >
            <div class="col-sm-8">
              <input
                readonly
                type="text"
                formControlName="fullname"
                placeholder="First Name"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Current Plant:</label>
            <div class="col-sm-8">
              <input
                readonly
                formControlName="currentPlant"
                type="text"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Current Department:</label>
            <div class="col-sm-8">
              <input
                readonly
                formControlName="currentDept"
                type="text"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Current Shift:</label>
            <div class="col-sm-8">
              <input
                readonly
                formControlName="currentShift"
                type="text"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Current Classification:</label
            >
            <div class="col-sm-8">
              <input
                readonly
                formControlName="classification"
                type="text"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Classification Applying For *:</label
            >
            <div class="col-sm-8">
              <select
                formControlName="JobCodeId"
                class="form-control"
                id="exampleFormControlSelect1"
              >
                <option
                  disabled
                  *ngIf="classifications[0].default"
                  selected
                  value=""
                >
                  {{ classifications[0].default }}
                </option>
                <option
                  *ngFor="let classification of classifications[0].data"
                  value="{{ classification.jobCodeId }}"
                >
                  {{ classification.jobCodeId }}-{{
                    classification.jobCodeDescription
                  }}
                </option>
              </select>
              <div
                *ngIf="
                  employeeForm.controls['JobCodeId'].invalid &&
                  employeeForm.controls['JobCodeId'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['JobCodeId'].errors?.required"
                >
                  Classification is required.
                </small>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Plant Applying For *:</label>
            <div class="col-sm-8">
              <select
                formControlName="plantId"
                class="form-control"
                (change)="getDeptListByPlantId($event)"
              >
                <option disabled *ngIf="plants[0].default" selected value="">
                  {{ plants[0].default }}
                </option>
                <option
                  *ngFor="let plant of plants[0].data"
                  value="{{ plant.plantId }}"
                >
                  {{ plant.plantId }}-{{ plant.plantDescription }}
                </option>
              </select>
              <div
                *ngIf="
                  employeeForm.controls['plantId'].invalid &&
                  employeeForm.controls['plantId'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['plantId'].errors?.required"
                >
                  Plant is required.
                </small>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label"
              >Department Applying For *:</label
            >
            <div class="col-sm-8">
              <select formControlName="departmentId" class="form-control">
                <option
                  disabled
                  *ngIf="deptbyplantid[0].default"
                  selected
                  value=""
                >
                  {{ deptbyplantid[0].default }}
                </option>
                <option *ngIf="deptbyplantid[0].selection" value="0">
                  {{ deptbyplantid[0].selection }} - Plant - {{ plantId }}
                </option>
                <option
                  *ngFor="let dept of deptbyplantid[0].data"
                  value="{{ dept.departmentId }} -- {{
                    dept.departmentDescription
                  }}"
                >
                  {{ dept.departmentId }} - {{ dept.departmentDescription }}
                </option>
              </select>
              <div
                *ngIf="
                  employeeForm.controls['departmentId'].invalid &&
                  employeeForm.controls['departmentId'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['departmentId'].errors?.required"
                >
                  Department is required.
                </small>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Shift Applying For *:</label>
            <div class="col-sm-8">
              <select formControlName="shiftId" class="form-control">
                <option disabled *ngIf="shifts[0].default" selected value="">
                  {{ shifts[0].default }}
                </option>
                <option
                  *ngFor="let shift of shifts[0].data"
                  value="{{ shift.shiftId }}"
                >
                  {{ shift.shiftDescription }}
                </option>
              </select>
              <div
                *ngIf="
                  employeeForm.controls['shiftId'].invalid &&
                  employeeForm.controls['shiftId'].touched
                "
              >
                <small
                  class="text-danger"
                  *ngIf="employeeForm.controls['shiftId'].errors?.required"
                >
                  Shift is required.
                </small>
              </div>
            </div>
          </div>

          <div *ngIf="isLoggedInUserAdmin" class="form-group row">
            <label for="datmax" class="col-sm-4 col-form-label"
              >Employee Signature Date *:</label
            >
            <div class="col-sm-8">
              <input
                formControlName="empSignDate"
                id="datmax"
                name="datmax"
                type="date"
                class="form-control"
                max="{{ CalenderBlockedAfterTodayDate }}"
              />
            </div>
          </div>

          <div *ngIf="!isLoggedInUserAdmin" class="form-group row">
            <label class="col-sm-4 col-form-label">Employee Sign Date *:</label>
            <div class="col-sm-8">
              <input
                readonly
                type="date"
                class="form-control"
                value="{{ currentDate | date : 'yyyy-MM-dd' }}"
              />
            </div>
          </div>

          <div
            class="text-danger"
            *ngIf="isLoggedInUserAdmin && medicalRestrictionList.length > 0"
          >
            <h6>WARNING: This employee has a current medical restriction</h6>
            <ul>
              <li *ngFor="let medicalRestriction of medicalRestrictionList">
                {{ medicalRestriction }}
              </li>
            </ul>
          </div>

          <div
            *ngIf="isLoggedInUserAdmin && isExpOverride"
            class="form-group row"
          >
            <label class="col-sm-4 col-form-label">Validate</label>
            <div class="col-sm-8">
              <input
                formControlName="ExpValidateFlag"
                type="checkbox"
                class="form-control checkbox-label"
              />
            </div>
            <div
              *ngIf="
                employeeForm.controls['ExpValidateFlag'].invalid &&
                employeeForm.controls['ExpValidateFlag'].touched
              "
            >
              <small
                class="text-danger"
                *ngIf="
                  employeeForm.controls['ExpValidateFlag'].errors?.required
                "
              >
                You must provide an override due to prerequisite  required for
                the classification "{{ isExpOverrideClassification }}".
              </small>
            </div>
          </div>
        </div>
        <div class="form-group col-md-12 text-right pl-0">
          <div class="modal-footer">
            <button
              type="button"
              style="width: 150px; border-radius: 10px; height: 40px"
              class="btn btn-secondary popupbuttonscancel"
              data-dismiss="modal"
              #closeAddModel
              (click)="clear()"
            >
              Cancel
            </button>
            <button
              type="button"
              style="
                background-color: #0032a0 !important;
                border-radius: 10px;
                width: 150px;
                height: 40px;
                color: white;
              "
              class="btn"
              [disabled]="employeeForm.invalid || !StatusCode"
              (click)="AddApplication()"
            >
              Add
            </button>
          </div>
        </div>
      </form>

      <!-- <div style="padding-right: 80px;" class="modal-footer">

      </div> -->
    </div>
  </div>
</div>
