import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { OverTimeMaintanceService } from 'src/app/Services/Applications/OvertimeMaintanence/overtime.maintanence.service';
import { ToolAndDiePlantService } from 'src/app/Services/Applications/ToolAndDie/tool-and-die-plant.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { UsergroupService } from 'src/app/Services/usergroup.service';

@Component({
  selector: 'app-overtime-analytics',
  templateUrl: './overtime-analytics.component.html',
  styleUrls: ['./overtime-analytics.component.css'],
})
export class OvertimeAnalyticsComponent implements OnInit {
  showFilter: boolean = true;

  Labels = ['Accepted', 'Rejected', 'Others'];

  filterData: any = {};
  userInformation: any;
  views: any[] = [];
  filterConfig: any = [
    {
      label: 'Department',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      controlName: 'DEPTID',
      width: '140px',
      runClassification: false,
    },
    {
      label: 'Classification',
      type: 'input',
      data: [],
      placeholder: 'Search here',
      controlName: 'JOBCODEID',
      width: '140px',
      runClassification: false,
    },
    {
      label: 'Shift',
      type: 'dropdown',
      data: ['option 1', 'option 2'],
      default: 'All',
      controlName: 'SHIFTID',
      width: '140px',
      runClassification: false,
    },
    {
      label: 'From',
      type: 'date',
      data: [],
      default: 'All',
      controlName: 'fromDate',
      width: '140px',
      runClassification: false,
    },
    {
      label: 'To',
      type: 'date',
      data: [],
      default: 'All',
      controlName: 'toDate',
      width: '140px',
      runClassification: false,
    },
  ];

  responseData: any;
  responseDataExists: boolean = false;
  FilterConfigLog: any;
  overtimegroupname: any;

  isLoading: boolean = false;
  OvertimeAcceptedRefusedChart: any = [];
  constructor(
    private _OverTimeMaintanceService: OverTimeMaintanceService,
    private _commonApplicationService: CommonService,
    private _userInfo: UsergroupService,
    private td: ToolAndDiePlantService,
    private _sweetAlertService: SweetAlertService
  ) {
    this.getUserInfo();
  }

  ngOnInit(): void {
    if (this.OvertimeAcceptedRefusedChart instanceof Chart) {
      this.OvertimeAcceptedRefusedChart.destroy();
    }
    this.getShifts();
    this.filterConfig['fromDate'] = this._commonApplicationService.formatDate(
      new Date(new Date().setHours(-720, 0, 0, 0))
    );
    this.filterConfig['toDate'] = this._commonApplicationService.formatDate(
      new Date(new Date().setHours(0, 0, 0, 0))
    );
  }

  getFilterValue(event: any) {
    this.filterData = event;
  }

  getShifts() {
    this.td.getShiftsList().subscribe((res) => {
      const elements = res || [];
      const mapElement = elements.map(
        (element: { shiftId: any; shiftDescription: any }) => ({
          id: element.shiftId,
          description: element.shiftDescription,
        })
      );
      for (let index = 0; index < this.filterConfig.length; index++) {
        if (this.filterConfig[index].label === 'Shift') {
          this.filterConfig[index].data = mapElement;
        }
      }
    });
  }

  getUserInfo() {
    this._userInfo.getUserInfo().subscribe((data: any) => {
      this.userInformation = data;
      let module_Applications = data['moduels'];
      this.views = this._commonApplicationService.crudPermissionSetup(
        module_Applications,
        'REPORTS',
        'OVERTIMEACCEPTEDREJECTED'
      );
    });
  }

  //tableData:any;
  Generate() {
    var RefusedCount,
      AccepetedCount,
      OthersCount = 0;
    var RefusedPaidHourTotal,
      AcceptedPaidHoursTotal,
      OtherPaidHoursTotal = 0;
    var countArray: any = [];
    this.isLoading = true;
    let queryParams = this.filterData;
    this.FilterConfigLog = queryParams;
    Object.keys(queryParams).forEach(
      (k) =>
        !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]
    );
    if (queryParams.fromDate === null) {
      queryParams.fromDate = this._commonApplicationService.formatDate(
        new Date(new Date().setHours(-720, 0, 0, 0))
      );
    }
    if (queryParams.toDate === null) {
      queryParams.toDate = this._commonApplicationService.formatDate(
        new Date(new Date().setHours(0, 0, 0, 0))
      );
    }
    if (queryParams.DEPTID === null) {
      this.overtimegroupname = null;
    }
    this._OverTimeMaintanceService.GenerateReport(queryParams).subscribe(
      (_data) => {
        this.responseData = _data;

        this.responseData.forEach((val: any) => {
          val.paidHoursFormattedInt = parseInt(val.paidhours);
          val.paidHoursFormattedFloat = parseFloat(val.paidhours);
        });

        this.isLoading = false;
        this.responseDataExists = this.responseData.length === 0 ? true : false;
        if (queryParams.DEPTID != null) {
          this.overtimegroupname = null;
          this.overtimegroupname = this.responseData[0].overtimegroupname;
        }

        var GetRefusedRecords = this.responseData.filter(
          (x: { searchtype: string }) => {
            return x.searchtype === 'REFUSED';
          }
        );
        var GetAcceptedRecords = this.responseData.filter(
          (x: { searchtype: string }) => {
            return x.searchtype === 'ACCEPTED';
          }
        );
        var GetOtherRecords = this.responseData.filter(
          (x: { searchtype: string }) => {
            return x.searchtype === 'OTHERS';
          }
        );

        RefusedCount = GetRefusedRecords.length;
        AccepetedCount = GetAcceptedRecords.length;
        OthersCount = GetOtherRecords.length;

        RefusedPaidHourTotal = GetRefusedRecords.reduce(
          (n: any, { paidHoursFormattedFloat }: any) =>
            n + paidHoursFormattedFloat,
          0
        );
        AcceptedPaidHoursTotal = GetAcceptedRecords.reduce(
          (n: any, { paidHoursFormattedFloat }: any) =>
            n + paidHoursFormattedFloat,
          0
        );
        OtherPaidHoursTotal = GetOtherRecords.reduce(
          (n: any, { paidHoursFormattedFloat }: any) =>
            n + paidHoursFormattedFloat,
          0
        );

        if (this.OvertimeAcceptedRefusedChart instanceof Chart) {
          this.OvertimeAcceptedRefusedChart.destroy();
        }
        this.OvertimeAcceptedRefusedChart = new Chart('canvas', {
          type: 'doughnut',
          data: {
            datasets: [
              {
                data: [
                  AcceptedPaidHoursTotal,
                  RefusedPaidHourTotal,
                  OtherPaidHoursTotal,
                ],
                backgroundColor: [
                  'rgba(69, 215, 111)',
                  'rgba(236, 112, 99)',
                  'rgba(82, 179, 217, 1)',
                ],
              },
            ],
            labels: this.Labels,
          },
        });
        this.FilterConfigLog.fromDate = queryParams.fromDate;
        this.FilterConfigLog.toDate = queryParams.toDate;
      },
      (_error: HttpErrorResponse) => {
        this.overtimegroupname = null;
        this.isLoading = false;
        this.filterConfig[0].fromDate = '';
        this.filterConfig[0].toDate = '';
        this.responseDataExists = true;
        if (this.OvertimeAcceptedRefusedChart instanceof Chart) {
          this.OvertimeAcceptedRefusedChart.destroy();
        }
        if (_error.status === 500) {
          //this._sweetAlertService.invalidSelection('error','Error',_error.message.replace('Error: Get Report Generation Field -',''));
          this._sweetAlertService.InternalServerError500(
            'error',
            'Error',
            'Unable to find overtime group'
          );
        } else {
          this._sweetAlertService.InternalServerError500(
            'error',
            'Error',
            'Invalid Selection'
          );
        }
      }
    );
  }

  onDetails(e: any) {
    //console.log("event triggerd: ", e);
  }
}
