import { HttpErrorResponse } from '@angular/common/http';
import {
  ElementRef,
  EventEmitter,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TransferAgreementService } from 'src/app/Services/Applications/TransferAgreementService/transfer-agreement.service';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';
import { CommonService } from 'src/app/Services/Applications/Common/common.service';
import { RuleEngineService } from 'src/app/Services/Ruleengine/rule-engine.service';
import { DateService } from 'src/app/Services/Date/date.service';

@Component({
  selector: 'app-add-transfer-agreement',
  templateUrl: './add-transfer-agreement.component.html',
  styleUrls: ['./add-transfer-agreement.component.css'],
})
export class AddTransferAgreementComponent implements OnInit {
  employeeForm!: FormGroup;

  shifts: any[] = [
    {
      data: [],
      default: 'Select a shift',
    },
  ];
  plants: any[] = [
    {
      data: [],
      default: 'Select a plant',
    },
  ];
  deptbyplantid: any[] = [
    {
      data: [],
      default: 'Select a department',
      selection: 'Any Department whithin ',
    },
  ];
  classifications: any[] = [
    {
      data: [],
      default: 'Select a classification',
    },
  ];

  empSelected: any;
  empInformation: any;
  selectedPlantId: any;
  ClassificationList: any;
  atid: any;
  employeeId: any;
  currentDate = new Date();

  isExpOverride: boolean = false;
  isExpOverrideClassification: string = '';
  CalenderBlockedAfterTodayDate: string = '';

  @Input() selectedEmp: any;
  @Input() isAdd: boolean = false;
  @Input() title: any;
  @Input() isLoggedInUserAdmin = false;
  @Input() AdminAtid = '';
  @Input() AppType: string = '';
  @Output() clean = new EventEmitter();
  @Output() isSucess = new EventEmitter();
  @ViewChild('closeAddModel') closeAddModal!: ElementRef;

  EnteredBy: string = '';
  plantId: string = '';

  /* Rule engine variables */
  StatusCode: boolean = true;
  ruleMessages!: any[];
  medicalRestrictionList: string[] = [];
  errorlength: number = 0;

  constructor(
    private fb: FormBuilder,
    private _transferAgreementService: TransferAgreementService,
    private _sweetAlertService: SweetAlertService,
    private _commonApplicationService: CommonService,
    private _dateService: DateService,
    private _ruleEngine: RuleEngineService
  ) {}

  ngOnInit(): void {
    this.getShifts();
    this.getplants();
    this.getTAClassifications();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createEmpForm();
    this.loadEmployeeData(changes);
  }

  loadEmployeeData(changes: SimpleChanges) {
    this.empSelected =
      (changes.selectedEmp && changes.selectedEmp.currentValue) || '';
    if (this.empSelected === '') {
      this.empSelected =
        (changes.selectedEmp && changes.selectedEmp.previousValue) || '';
    }
    this.validateClassification();
    this.getRuleEngineValidations(this.empSelected.employeeId);
    this.patchDate();
    this.checkUserRole();
    this.isExpOverride = false;
    this.CalenderBlockedAfterTodayDate =
      this._commonApplicationService.formatDate(this.currentDate);
    this.employeeForm.get('firstName')?.patchValue(this.empSelected.firstName);
    this.employeeForm.get('lastName')?.patchValue(this.empSelected.lastName);
    this.employeeForm
      .get('fullname')
      ?.patchValue(
        this.empSelected.lastName +
          ', ' +
          this.empSelected.firstName +
          ' ' +
          this.empSelected.middleName
      );
    this.employeeForm
      .get('classification')
      ?.patchValue(
        this.empSelected.jobCodeId + '-' + this.empSelected.jobCodeDescription
      );
    this.employeeForm
      .get('currentPlant')
      ?.patchValue(
        this.empSelected.plantId + '-' + this.empSelected.plantDescription
      );
    this.employeeForm
      .get('empSignDate')
      ?.patchValue(this._commonApplicationService.formatDate(new Date()));
    this.employeeForm
      .get('currentShift')
      ?.patchValue(this.empSelected.shiftDescription);
    this.employeeForm.get('currentDept')?.patchValue(this.empSelected.deptId);
    this.employeeForm.get('ATID')?.patchValue(this.empSelected.atid);
    this.employeeForm
      .get('EmployeeId')
      ?.patchValue(this.empSelected.employeeId);
    this.employeeForm.get('EnteredBy')?.patchValue(this.EnteredBy);
    this.GetMedicalRestrictions(this.empSelected.employeeId);
  }

  checkUserRole() {
    if (this.isLoggedInUserAdmin) {
      this.EnteredBy = this.AdminAtid;
    } else {
      this.EnteredBy = 'USER';
    }
    return this.EnteredBy;
  }

  getRuleEngineValidations(employeeId: string) {
    this._ruleEngine.addTAApplicationRule(employeeId).subscribe((data: any) => {
      this.StatusCode = data['status']; //for error: false
      this.ruleMessages = data['errors'];
      this.errorlength = data['errors'].length;
    });
  }

  patchDate() {
    if (this.isLoggedInUserAdmin === false) {
      this.employeeForm.get('AppStatusDate')?.patchValue(this.currentDate);
    }
  }

  createEmpForm() {
    this.employeeForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      fullname: [''],
      classification: [''],
      currentPlant: [''],
      currentShift: [''],
      currentDept: [''],
      ATID: [''],
      EmployeeId: [''],
      AppNo: ['0'],
      AppStatusCode: ['OPEN'],
      JobCodeId: ['', [Validators.required]],
      T09OverrideFlag: [null],
      T04OverrideFlag: [null, []],
      ClassOverrideFlag: [null, []],
      SeniorityOverrideFlag: [null, []],
      Comments: [null, []],
      SpecialSP: [null, []],
      App150PlantGroupId: ['0'],
      AppType: ['TA'],
      DateEntered: [this.currentDate],
      EnteredBy: [''],
      ExpValidateFlag: [false],
      appStatusDate: [this.currentDate],
      empSignDate: ['', [Validators.required]],
      plantId: ['', [Validators.required]],
      shiftId: ['', [Validators.required]],
      departmentId: ['', [Validators.required]],
      StatusOverrideFlag: [null, []],
      AppAreaId: [null],
    });
    this.isAdd = false;
  }

  clear() {
    this.clean.emit('');
    this.closeAddModal.nativeElement.click();
    this.emitResponse();
  }

  emitResponse() {
    this.isSucess.emit(true);
  }

  //Method to add an employee
  AddApplication() {
    const jobCodes: string = this.employeeForm.controls['JobCodeId'].value;
    const ExpValidate = this.employeeForm.controls['ExpValidateFlag'].value;
    if (
      this.JobCodeValidationCheck.includes(jobCodes) &&
      !this.isLoggedInUserAdmin
    ) {
      this._sweetAlertService.invalidSelection(
        'warning',
        'Validation Required',
        'See Employee Relations regarding Transfer Agreement applications'
      );
      return;
    } else if (
      this.JobCodeValidationCheck.includes(jobCodes) &&
      this.isLoggedInUserAdmin &&
      !ExpValidate
    ) {
      this._sweetAlertService.invalidSelection(
        'warning',
        'Validation Required',
        ' Please select the Validate due to prerequisite required for the classification "' +
          jobCodes +
          '".'
      );
      return;
    } else {
      const departmentId = this.employeeForm.controls['departmentId'].value;
      const departmentIdCode = departmentId.split('-')[0];
      this.employeeForm.controls['departmentId'].setValue(departmentIdCode);

      this._transferAgreementService
        .TransferAgreementTAAdd(this.employeeForm.value)
        .subscribe(
          (data: any) => {
            this.clear();
            this.isSucess.emit(true);
            this._sweetAlertService.successAlert(
              'center',
              'success',
              'The Application has been Added Successfully.',
              false,
              1500
            );
          },
          (_error: HttpErrorResponse) => {
            if (_error.status === 500) {
              this._sweetAlertService.InternalServerError500(
                'error',
                'ERROR',
                'An Error occured while processing.Please try again.'
              );
            } else {
              this._sweetAlertService.validationError(
                'error',
                'Cannot create application',
                'You cannot duplicate an open application'
              );
            }
          }
        );
    }
  }

  GetMedicalRestrictions(employeeId: string) {
    this._ruleEngine.getMedicalRestrictions(employeeId).subscribe((data) => {
      this.medicalRestrictionList = data;
    });
  }

  //method to get shifts
  getShifts() {
    this._commonApplicationService.getShiftsList().subscribe((data: any) => {
      this.shifts[0].data = data;
    });
  }

  //Method to get plants
  getplants() {
    this._commonApplicationService.GetPlants('TA').subscribe((data) => {
      this.plants[0].data = data;
      this.plants[0].data.splice(0, 1);
    });
  }

  //Method to get department by plant id
  getDeptListByPlantId(event: any) {
    if (event != null) {
      this._commonApplicationService
        .getDeptbyPlantId(event.target.value)
        .subscribe((data) => {
          this.plantId = event.target.value;
          this.deptbyplantid[0].data = data;
          this.employeeForm.get('departmentId')?.patchValue('0');
        });
    }
  }

  JobCodeValidationCheck: any[] = [];
  /* Method to get Classifications for TA*/
  getTAClassifications() {
    this._transferAgreementService.GetClassificationsTA().subscribe((data) => {
      this.classifications[0].data = data;
      for (var i = 0; i < data.length; i++) {
        if (this.classifications[0].data[i].override === true) {
          this.JobCodeValidationCheck.push(
            this.classifications[0].data[i].jobCodeId
          );
        }
      }
    });
  }

  /*check if classification need override check*/
  validateClassification() {
    this.employeeForm
      .get('JobCodeId')
      ?.valueChanges.subscribe((inputValue: any) => {
        for (var i = 0; i < this.classifications[0].data.length; i++) {
          if (
            this.classifications[0].data[i].jobCodeId === inputValue &&
            this.classifications[0].data[i].override === true
          ) {
            this.isExpOverride = true;
            this.isExpOverrideClassification = inputValue;
            break;
          } else {
            this.isExpOverride = false;
          }
        }
      });
  }
}
