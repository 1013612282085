import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

export interface DropDownSearchModel {
  dropDownValue: string;
}
@Component({
  selector: 'gyansys-dropdown',
  templateUrl: './drop-down.component.html',
  // styleUrls: ['./department-search.component.css'],
})
export class DropDownComponent implements OnChanges {
  @Input() Parent: FormGroup | any;
  // @Input() DropDownSearchForm: FormGroup | any;
  @Input() SectionName: any;
  @Output() EventDropDown = new EventEmitter();
  searchParams: DropDownSearchModel | any;
  @Input() SourceData: any | undefined;

  @Input() selectedDevice: any;
  Items: any[] = [
    {
      data: [],
      default: 'Select',
    },
  ];

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.SourceData != undefined) {
      const mapElement = this.SourceData.map(
        (element: { Id: string; Description: string }) => ({
          id: element.Id,
          description: element.Description,
        })
      );
      this.Items[this.Items.length - 1].data = mapElement;
    }

    this.EventDropDown.emit(this.selectedDevice);
  }

  getDropDownData() {
    if (this.SourceData != undefined) {
      // alert("this.SourceData"+this.SourceData)
      const mapElement = this.SourceData.map(
        (element: { Id: string; Description: string }) => ({
          id: element.Id,
          description: element.Description,
        })
      );
      this.Items[this.Items.length - 1].data = mapElement;
    }
  }

  onChange(newValue: any) {
    this.selectedDevice = newValue;
    this.EventDropDown.emit(this.selectedDevice);
  }
}
