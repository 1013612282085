<div class="container-fluid app-box" style="background-color: white">
  <div class="row breadcrumb-container breadcrumbcss">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb brd-ol">
        <li class="breadcrumb-item brd-li">Admin</li>
        <li class="breadcrumb-item brd-li brd-li-child">Announcement</li>
      </ol>
    </nav>
  </div>

  <div>
    <div class="row content-container mt-2 mb-2">
      <div class="pt-2 div-color">
        <div class="search-divider-div filtercss">
          <p class="search-divder-p"></p>
          <hr class="search-divider-hr" />
          <div class="jumbotron jumb-new jumb-newcontrols">
            <div class="row" display="float">
              <div class="col">
                <app-filter
                  [filterConfig]="filterConfig"
                  (filterValue)="getFilterValue($event)"
                  (formcontrolobj)="getbyenter($event)"
                >
                </app-filter>
              </div>
              <div class="col-md-1 mr-5">
                <label for="" style="color: white">&nbsp;</label>
                <a
                  class="btn btn-lg search-button"
                  (click)="SearchAnnouncements()"
                  role="button"
                  >Search
                  <img
                    src="./assets/search-solid.svg"
                    class="img-fluid user-icon ml-1 search-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="info-label divcrudbtns">
              <p style="text-align: left" class="p-margin"></p>
              <div *ngIf="views[0]?.allowAdd">
                <button
                  data-toggle="modal"
                  class="btn btn-successclass buttoncolours crudbuttons pull-right my-2 my-sm-0"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                  (click)="AddAnnouncement()"
                >
                  Add
                </button>
              </div>
              <div *ngIf="views[0]?.allowUpdate">
                <button
                  data-toggle="modal"
                  *ngIf="showButton"
                  class="btn btn-successclass buttoncolours crudbuttons pull-right my-2 my-sm-0"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                  (click)="CorrectAnnouncement()"
                >
                  Edit
                </button>
              </div>
              <div *ngIf="views[0]?.allowDelete">
                <button
                  data-toggle="modal"
                  *ngIf="showButton"
                  class="btn btn-successclass buttoncolours crudbuttons pull-right my-2 my-sm-0"
                  [attr.data-target]="modalId"
                  data-backdrop="false"
                  (click)="DeleteAnnouncement()"
                >
                  Delete
                </button>
              </div>
              <div
                *ngIf="applicationData !== null && applicationData.length > 0"
              >
                <button
                  style="margin-right: 0.5rem"
                  class="btn buttoncolours crudbuttons pull-right my-2 my-sm-0"
                  (click)="exportElmToExcel()"
                >
                  Export to Excel
                </button>
              </div>
            </div>
            <!-- This is export to Excel render table -->
            <div *ngIf="!isApplicationDataExists">
              <table
                class="table table-striped"
                *ngIf="
                  !isDataExportableView &&
                  applicationData !== null &&
                  applicationData.length > 0
                "
                #AnnouncementExportTable
              >
                <thead class="table-header">
                  <!-- <th scope="col">Select</th> -->
                  <tr>
                    <!-- <th scope="col">Title</th>
                                        <th scope="col">Start Date</th>
                                        <th scope="col">End Date</th>
                                        <th scope="col">Status</th> -->
                    <th scope="col">
                      <span (click)="onSortClick($event, 'title', 'string')"
                        >Title
                        <img
                          *ngIf="sortDir === 1 && sortColumn === 'title'"
                          src="./assets/downarrow.png"
                        />
                        <img
                          *ngIf="sortDir === -1 && sortColumn === 'title'"
                          src="./assets/uparrow.png"
                        />
                        <img
                          *ngIf="sortDir !== 0 && sortColumn !== 'title'"
                          src="./assets/nosort.png"
                        />
                      </span>
                    </th>
                    <th scope="col">
                      <span (click)="onSortClick($event, 'starttime', 'string')"
                        >Start Date
                        <img
                          *ngIf="sortDir === 1 && sortColumn === 'starttime'"
                          src="./assets/downarrow.png"
                        />
                        <img
                          *ngIf="sortDir === -1 && sortColumn === 'starttime'"
                          src="./assets/uparrow.png"
                        />
                        <img
                          *ngIf="sortDir !== 0 && sortColumn !== 'starttime'"
                          src="./assets/nosort.png"
                        />
                      </span>
                    </th>
                    <th scope="col">
                      <span (click)="onSortClick($event, 'endtime', 'string')"
                        >End Date
                        <img
                          *ngIf="sortDir === 1 && sortColumn === 'endtime'"
                          src="./assets/downarrow.png"
                        />
                        <img
                          *ngIf="sortDir === -1 && sortColumn === 'endtime'"
                          src="./assets/uparrow.png"
                        />
                        <img
                          *ngIf="sortDir !== 0 && sortColumn !== 'endtime'"
                          src="./assets/nosort.png"
                        />
                      </span>
                    </th>
                    <th scope="col">
                      <span (click)="onSortClick($event, 'status', 'string')"
                        >Status
                        <img
                          *ngIf="sortDir === 1 && sortColumn === 'status'"
                          src="./assets/downarrow.png"
                        />
                        <img
                          *ngIf="sortDir === -1 && sortColumn === 'status'"
                          src="./assets/uparrow.png"
                        />
                        <img
                          *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                          src="./assets/nosort.png"
                        />
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class=""
                    *ngFor="let data of applicationData; let i = index"
                  >
                    <!-- <td class="tab-value"><input type="radio" id="select" name="application"
                                                (click)="SelectAnnouncement(i, data)"></td> -->
                    <td class="left-tab-value">{{ data.title }}</td>
                    <td class="tab-value">
                      {{
                        data.starttime
                          ? (data.starttime | date: 'MM/dd/yyyy HH:mm')
                          : '-'
                      }}
                    </td>
                    <td class="tab-value">
                      {{
                        data.endtime
                          ? (data.endtime | date: 'MM/dd/yyyy HH:mm')
                          : '-'
                      }}
                    </td>
                    <td class="tab-value">
                      {{ data.status === 'A' ? 'Active' : 'Inactive' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- This is Main Table render -->
            <div *ngIf="!isApplicationDataExists">
              <table
                class="table table-striped"
                *ngIf="applicationData !== null && applicationData.length > 0"
              >
                <thead class="table-header">
                  <th scope="col">Select</th>
                  <!-- <th scope="col">Title</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">End Date</th>
                                    <th scope="col">Status</th> -->

                  <th scope="col">
                    <span (click)="onSortClick($event, 'title', 'string')"
                      >Title
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'title'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'title'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'title'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'starttime', 'string')"
                      >Start Date
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'starttime'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'starttime'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'starttime'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'endtime', 'string')"
                      >End Date
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'endtime'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'endtime'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'endtime'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span (click)="onSortClick($event, 'status', 'string')"
                      >Status
                      <img
                        *ngIf="sortDir === 1 && sortColumn === 'status'"
                        src="./assets/downarrow.png"
                      />
                      <img
                        *ngIf="sortDir === -1 && sortColumn === 'status'"
                        src="./assets/uparrow.png"
                      />
                      <img
                        *ngIf="sortDir !== 0 && sortColumn !== 'status'"
                        src="./assets/nosort.png"
                      />
                    </span>
                  </th>
                </thead>
                <tbody>
                  <tr
                    class=""
                    *ngFor="
                      let data of applicationData
                        | paginate
                          : {
                              id: 'joblist',
                              itemsPerPage: 20,
                              currentPage: Applicationpage,
                              totalItems: TotalApplicationCount
                            };
                      let i = index
                    "
                  >
                    <td class="tab-value">
                      <input
                        type="radio"
                        id="select"
                        name="application"
                        (click)="SelectAnnouncement(i, data)"
                      />
                    </td>
                    <td class="left-tab-value">{{ data.title }}</td>
                    <td class="tab-value">
                      {{
                        data.starttime
                          ? (data.starttime | date: 'MM/dd/yyyy HH:mm')
                          : '-'
                      }}
                    </td>
                    <td class="tab-value">
                      {{
                        data.endtime
                          ? (data.endtime | date: 'MM/dd/yyyy HH:mm')
                          : '-'
                      }}
                    </td>
                    <td class="tab-value">
                      {{ data.status === 'A' ? 'Active' : 'Inactive' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="single-wrap d-flex justify-content-center"
              *ngIf="!isApplicationDataExists"
            >
              <nav aria-label="Page navigation example">
                <pagination-controls
                  class="my-pagination"
                  id="joblist"
                  (pageChange)="Applicationpage = $event"
                  previousLabel=""
                  nextLabel=""
                >
                </pagination-controls>
              </nav>
            </div>
            <div *ngIf="isApplicationDataExists">
              <p *ngIf="isApplicationDataExists">
                No records exist in the database at this time that match the
                search criteria.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Edit Modal -->
  <app-edit-announcement
    (isSucess)="CheckEdit($event)"
    [userName]="this.userInformation.userName"
    [selectedAnnouncement]="selectedAnnouncement"
    *ngIf="showEdit"
  >
  </app-edit-announcement>

  <!--Add  Modal-->
  <app-add-announcement
    (clean)="cleanEmp($event)"
    [userName]="this.userInformation.userName"
    (isSucess)="CheckAdd($event)"
    *ngIf="showAdd"
  >
  </app-add-announcement>

  <!--Delete  Modal-->
  <app-delete-announcement
    *ngIf="showDelete"
    [selectedAnnouncement]="selectedAnnouncement"
    (isSucess)="CheckDelete($event)"
  >
  </app-delete-announcement>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0' }">
  <!--[show]="loading"  -->
</ngx-loading>
