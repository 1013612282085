import { Component, Input, OnInit } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { SweetAlertService } from 'src/app/Services/sweet-alert.service';

@Component({
  selector: 'app-overtime-weekly-report',
  templateUrl: './overtime-weekly-report.component.html',
  styleUrls: ['./overtime-weekly-report.component.css'],
})
export class OvertimeWeeklyReportComponent implements OnInit {
  constructor(private _sweetalert: SweetAlertService) {}

  @Input() weeklyOvertimeRecords: any[] = [];
  @Input() postModel: any;
  @Input() monday: Date | any;
  @Input() tuesday: Date | any;
  @Input() wednesday: Date | any;
  @Input() thursday: Date | any;
  @Input() friday: Date | any;
  @Input() saturday: Date | any;
  @Input() sunday: Date | any;
  @Input() asOfDate: Date | any;
  @Input() overtimeGroupName: string | any;
  @Input() AverageAccHours: number | any;
  @Input() showFilter: boolean | any;
  @Input() ClassificationType: boolean | any;
  @ViewChild('content') content!: ElementRef;

  todayDate: any;

  ngOnInit(): void {
    this.bindOvertimeReport();
    this.todayDate = new Date();
  }

  bindOvertimeReport() {}

  filterItemsBySpread(isSpread: boolean) {
    return this.weeklyOvertimeRecords.filter((x) => x.isinspread === isSpread);
  }
  DownloadWeeklyReport() {
    var allLinks = $('head').clone().find('script').remove().end().html();
    var keepColors =
      '<style>body {-webkit-print-color-adjust: exact !important; }</style>';
    var innerContents = document.getElementById('content')?.innerHTML;
    var popupWinindow = window.open('Print', '_blank');
    popupWinindow?.document.open();
    popupWinindow?.document.write(
      '<html><head>' +
        keepColors +
        allLinks +
        '</head><body onload="window.print();window.close();">' +
        innerContents +
        '</html>'
    );
    popupWinindow?.document.close();
  }
}
